import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'


export default function GenderAccordionSection(props) {
  const getLabels = async () => {
    return [
      {
        name: 'Women',
        _id: 'women',
        active: true
      },
      {
        name: 'Men',
        _id: 'men',
        active: true
      },
      {
        name: 'Unisex',
        _id: 'unisex',
        active: true
      }
    ]
  }

  return (
    <AbstractAccordionSection
      title={'Gender'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}
