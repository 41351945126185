import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import settings from 'config/setting-values.js'
import { Spinner } from 'react-bootstrap'
import api from 'api/api'

const style = them => ({
  root: {
    display: 'inline-block',
    marginLeft: '38px'
  },
  searchContainer: {
    display: 'none !important',
    marginBottom: '20px'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  searchContainerText: {
    display: 'inline-block',
    height: '24px',
    width: '57px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  autocompleteBar: {
    display: 'inline-flex',
    marginLeft: '19px',
    width: '610px'
  },
  similarityContainer: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  garmentTitle: {
    textAlign: 'center',
    marginBottom: '10px'
  },
  garmentName: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    width: '260px',
    margin: 'auto'
  },
  garmentBrand: {
    height: '15px',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px',
    textAlign: 'center',
    marginTop: '16px'
  },
  mainGarment: {
    display: 'border-box',
    height: '280px',
    width: '280px',
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  img: {
    display: 'block',
    height: '160px',
    margin: 'auto',
    marginTop: '15%'
  },
  similarities: {
    height: 'inherit'
  }
})

class SimilarityDemo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: [],
      loading: true,
      garments: []
    }
    this.handleRequestGarments = this.handleRequestGarments.bind(this)
  }

  async componentWillMount () {
    const categories = await api.getCategories()
    this.setState({ categories: categories })
    this.handleRequestGarments([], [])
  }

  async handleRequestGarments (e, value) {
    this.setState({ loading: true })
    // if (value.length === 0) {
    //   this.setState({ loading: false })
    //   return
    // }
    const categoryRestrictions = value.map(value => value._id)

    let queryRestrictions = {
      user_ids_restriction: '5d8b964aad911a2894b48f86'
    }

    if (categoryRestrictions.length > 0) {
      queryRestrictions.category_ids_restriction = categoryRestrictions
    }

    const body = {
      similar_restrictions: {
        approved: 1
      },
      return_garments: true,
      query_restrictions: queryRestrictions,
      n_queries: 50,
      cached: true
    }
    const results = (await api.getSimilars(body)).data
    if (results.garments === undefined || results.garments.length === 0) {
      this.setState({ garments: [], loading: false })
    } else {
      this.setState({ garments: results.garments, loading: false })
    }
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        {this.state.loading
          ? <div className='outfit-loading-spinner'>
            <Spinner animation='border' role='status' style={{ padding: '50px' }}>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          </div> : this.state.garments.map((value, key) => {
            return (
              <div className={classes.similarityContainer} key={key}>
                <Grid container spacing={3} wrap='nowrap'>
                  <Grid item>
                    <div className={classes.mainGarment}>
                      <img alt='mainImage' className={classes.img} src={value.garment_images[0].s3_url_resized || settings.s3url.concat('resized/' + value.garment_images[0].server_image_filename.split('/').slice(-1)[0])} />
                      <div className={classes.garmentTitle}>
                        <div className={classes.garmentBrand} />
                        <div className={classes.garmentName}>
                          {value.remote_product_title.split(' ').slice(0, 11).join(' ')}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item className={classes.similarities}>
                    <Grid container wrap='nowrap' spacing={1}>
                      {value.similars[0].garments.map((value, key) => {
                        return (
                          <Grid item key={key}>
                            <div className={classes.mainGarment}>
                              <img alt='similarImage' className={classes.img} src={settings.s3url.concat('resized/' + value.garment_images[0].server_image_filename.split('/').slice(-1)[0])} />
                              <div className={classes.garmentTitle}>
                                <div className={classes.garmentBrand} />
                                <div className={classes.garmentName}>
                                  {value.remote_product_title.split(' ').slice(0, 11).join(' ')}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )
          })}
      </div>
    )
  }
}

export default withStyles(style)(SimilarityDemo)
