import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const borderColour = '#d3d3d3'

const useStyle = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  searchBarContainer: {
    width: '100%',
    height: '40px',
    marginTop: '48px',
  },
  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    margin: 'auto',
    '& input[id="searchBar"]': {
      height: '100%',
      width: '100%',
      minHeight: '100%',
      paddingLeft: '8%',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: borderColour,
      boxShadow: `0 0 10pt 0.2pt ${borderColour}`,
      border: '1px solid #6236FF',
      borderTopLeftRadius: '24px',
      borderBottomLeftRadius: '24px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    '& input[id="searchBar"]:focus': {
      outlineColor: 'black !important',
      outlineRadius: '5px',
      borderWidth: '2px !important',
    },
    '& i': {
      fontSize: '1.1em !important',
      opacity: '0.6',
      position: 'absolute',
      marginTop: '0.1em',
      marginLeft: '0.1em',
      padding: 'calc((3vh - 1.1em)/2)',
    },
    '& input[id="searchButton"]': {
      fontSize: '0.8rem !important',
      fontFamily: 'Roboto',
      fontWeight: 800,
      cursor: 'pointer',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '24px',
      borderBottomRightRadius: '24px',
      border: '1px solid #E8004C',
      //marginLeft: '-70px',
      //marginTop: 'auto',
      backgroundColor: '#E8004C',
      color: 'white',
      lineHeight: 'normal',
      '&:hover': {
        transition: '0.1s linear',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
      }
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  buttonHover: {
    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  }
})

function SearchBar (props) {
  const classes = useStyle()

  const onChange = (event) => {
    props.onChange(event.currentTarget.value)
  }

  const onSubmit = (event) => {
    event.preventDefault()
    props.onSubmit()
  }

  return (
    <div className={`${classes.searchBarContainer}`}>
      <form className={`${classes.form}`} onSubmit={onSubmit}>
        <div className={`${classes.searchBar} ${props.show !== true ? classes.hidden : ''}`}>
          <input
            id="searchBar"
            type="text"
            placeholder="Try 'Black Dress'"
            autoComplete="off"
            name="searchInput"
            value={props.searchInput}
            onChange={onChange}
          />
          <input id="searchButton" type="submit" value="Search"/>
        </div>
      </form>
    </div>
  )
}

export default SearchBar
