import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AutocompleteDropdown from '../../components/GarmentNavigatorV2/AutocompleteDropdown'
import SlideSwitch from '../../components/SlideSwitch/SlideSwitch'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    background: 'white',
    width: 'calc(100% + 40px)',
    borderLeft: '1px solid #DAE0EA',
    borderRight: '1px solid #DAE0EA',
    borderBottom: '1px solid #DAE0EA',
    maxWidth: '1370px',
    padding: '15px 32px'
  },
  titleText: {
    display: 'inline-block',
    // width: '135px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '5px',
    marginTop: '5px',
    textTransform: 'uppercase'
  },
  numberSelectContainer: {
    marginRight: '5px'
  },
  itemsPerPageInput: {
    width: '50px',
    height: '50px',
    marginRight: '5px',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px'
  },
  show: {
    height: 'auto'
  },
  hide: {
    height: '0px',
    display: 'none'
  },
  qualityContainer: {
    width: '150px',
    marginRight: '5px'
  },
  brandContainer: {
    width: '300px',
    marginRight: '5px'
  },
  outfitTypeContainer: {
    width: '200px',
    marginRight: '5px'
  }
}))

export default function OutfitEditorAdvancedFilterBar (props) {
  const classes = useStyles()

  const [qualities, setQualities] = useState([
    {name: 'Good', _id: 2},
    {name: 'Medium', _id: 1},
    {name: 'Bad', _id: 0}
  ])

  const handleQualityChange = async (e, value) => {
    if (value === null) {
      await props.setQuality({ name: '', _id: '' })
    } else {
      await props.setQuality(value)
    }
  }

  const handleOutfitsPerPageChange = async (event) => {
    event.preventDefault()
    let outfitsPerPage = 1
    if (event.target.value !== '') {
      outfitsPerPage = parseInt(event.target.value)
    }
    if (!isNaN(outfitsPerPage) && outfitsPerPage !== 0) {
      if (outfitsPerPage !== props.outfitsPerPage) {
        await props.setOutfitsPerPage(outfitsPerPage)
      }
    }
  }

  return (
    <div className={`${classes.root} ${props.show ? classes.show : classes.hide}`}>
      <div className={classes.qualityContainer}>
        <AutocompleteDropdown
          title={'Quality'}
          options={qualities}
          onChange={handleQualityChange}
          placeholder={'Select'}
          value={props.quality}
        />
      </div>
      <div className={classes.numberSelectContainer}>
        <p className={classes.titleText}>Outfits Per Page</p>
        <form
          onKeyPress={e => {
            if (e.key === 'Enter') e.preventDefault()
          }}
          autoComplete='off'
        >
          <input
            type='string'
            step={1}
            className={classes.itemsPerPageInput}
            id='paginationInput'
            name='quantity'
            value={props.outfitsPerPage}
            min={1}
            max={100}
            onChange={handleOutfitsPerPageChange}
          />
        </form>
      </div>
      <div className={classes.outfitTypeContainer}>
        <AutocompleteDropdown
          title={'Outfit Type'}
          options={props.outfitTypes}
          onChange={props.handleOutfitTypeChange}
          placeholder={'Select Outfit Type'}
          value={props.outfitType}
        />
      </div>
      {
        props.enableShowApprovedControls &&
        <div>
          <SlideSwitch
            title={'Approved Only'}
            handleChange={props.handleShowApprovedOnlyChange}
          />
        </div>
      }
      {
        props.enableShowApprovedControls &&
        <div>
          <SlideSwitch
            title={'Unapproved Only'}
            handleChange={props.handleShowUnapprovedOnlyChange}
          />
        </div>
      }
      <div>
        <SlideSwitch
          title={'Priority Only'}
          handleChange={props.handlePriorityOnlyChange}
        />
      </div>
    </div>
  )
}
