import React, {useState} from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AttributeSubgroup from './AttributeSubgroup'

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    padding: '12px 8px 12px 8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: 'none',
    borderRadius: '8px',
    justifyContent: 'space-between'
  },
  singleAttribute: {
    padding: '12px 8px 12px 8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: 'none',
    borderRadius: '8px',
    justifyContent: 'space-between',
    backgroundColor: '#EEEEEEEE',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    marginLeft: 8,
    backgroundColor: '#6236FF80',
    padding: '6px 8px 6px 8px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '15.23px'
  },
  accordion: {
    border: '1px solid #ECE7FF',
    borderRadius: '8px',
    marginBottom: '16px',
    boxShadow: 'none',
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    }
  },
  accordionSummary: {
    borderRadius: '24px',
    padding: '13px 0px 13px 8px',
  },
  selectedCategoryAccordionSummary: {
    border: 'none',
    borderRadius: '8px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '28.13px',
    color: '#161616',
  },
  accordionDetails: {
    flexDirection: 'column',
  },
  inlineDropdown: {
    flex: '1 1 auto',
  },
  groupName: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '18.75px',
    color: '#161616',
    marginBottom: '16px',
  },
  attributeSelector: {
    border: '1px solid #6236FF',
    backgroundColor: '#fff',
    borderRadius: '24px',
    minHeight: '24px',
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:after': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
}))

const AttributeGroup = ({group, garment, handleSelectAttribute, isCategorySelected}) => {
  const classes = useStyles()
  const hasSubgroups = group.children.some(child => child.label_type === 'attribute_subgroup')
  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded ? group._id : false)
  }

  const selectedAttributes = garment.labels.attribute.attributes
    .filter(attr => group.children.some(child => child._id === attr._id))
    .map(attr => attr._id)

  const isExclusive = group.children_are_mutually_exclusive

  if (hasSubgroups) {
    return (
      <>
        <Accordion className={classes.accordion} expanded={expanded === group._id} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon/>}
            className={classes.selectedCategoryAccordionSummary}>
            <Typography>{group.name}</Typography>
          </AccordionSummary>
          {
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.groupContainer}>
                {group.children.filter(child => child.label_type === 'attribute_subgroup' && child.name !== 'N/A').map(subgroup => (
                  <AttributeSubgroup
                    key={subgroup._id}
                    subgroup={subgroup}
                    garment={garment}
                    hasSubgroups={hasSubgroups}
                    handleSelectAttribute={handleSelectAttribute}
                  />
                ))}
              </div>
            </AccordionDetails>
          }
        </Accordion>
      </>
    )
  } else {
    let selectionOptions = group.children.filter(attr => attr.label_type === 'attribute' && attr.name !== 'N/A')
    return (
      <div className={classes.singleAttribute}>
        {
          <FormControl className={`${classes.formControl} ${classes.inlineDropdown}`} fullWidth>
            <div className={classes.groupName}>{group.name}</div>
            <Select
              className={classes.attributeSelector}
              multiple={!isExclusive}
              value={selectedAttributes}
              displayEmpty
              onChange={(event) => handleSelectAttribute(event.target.value, isExclusive, selectionOptions)}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map(value => {
                    return (<Chip key={value} label={group.children.find(attr => attr._id === value).name} className={classes.chip}/>)
                  })}
                </div>
              )}
            >
              {selectionOptions.map(attr => (
                <MenuItem key={attr._id} value={attr._id}>
                  {attr.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </div>
    )
  }
}

export default AttributeGroup
