import React from 'react'

import '../../../assets/css/WearThatSearch/Size.scss'

import BraSizes from '../Filters/BraSizes'

const Size = ({
  size_restriction,
  handleCheckboxChange,
  selectSizeCategory,
  sizeSelected,
  onChange
}) => {
  const newFilterOptions = {
    'UK Sizes': [
      '4',
      '6',
      '8',
      '10',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '26'
    ],
    'Generic Sizes': ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
    Bra: {
      Cup: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
      Band: ['30', '32', '34', '36', '38', '40', '42', '44', '46']
    },
    Trousers: [
      '4',
      '6',
      '8',
      '10',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '26'
    ],
    Dress: [
      '4',
      '6',
      '8',
      '10',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '26'
    ],
    Skirt: [
      '4',
      '6',
      '8',
      '10',
      '12',
      '14',
      '16',
      '18',
      '20',
      '22',
      '24',
      '26'
    ],
    Top: ['4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26'],
    Jeans: ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32'],
    Shoes: [
      '35',
      '35.5',
      '36',
      '36.5',
      '37',
      '37.5',
      '38',
      '38.5',
      '39',
      '39.5',
      '40',
      '40.5',
      '41',
      '41.5'
    ],
    'One Size': ['OS', 'One Size']
  }

  const options = []
  let index = 0
  for (const key of Object.keys(newFilterOptions)) {
    options.push(
      <div
        key={index + 1}
        className={sizeSelected.includes(key) ? 'submenu-selected' : 'size-category'}
        index={index}
      >
        <h6
          key={index + 1}
          onClick={selectSizeCategory}
          value={key}
          index={index}
        >
          <i key={index} className={sizeSelected.includes(key) ? 'fa fa-minus submenu-selected' : 'fa fa-plus'} />
          {key} {size_restriction[key].length && size_restriction[key].length !== 0 ? '(' + size_restriction[key].length + ')' : ''}
        </h6>

        {sizeSelected.includes(key) &&
          key !== 'Bra' &&
          newFilterOptions[key].map((el, i) => (
            <label
              key={i}
              className='fancy-checkbox'
            >
              <input
                type='checkbox'
                onChange={e => handleCheckboxChange(e)}
                name='size_restriction'
                sizetype={key}
                value={el}
                className='checkbox'
                checked={size_restriction[key].includes(el)}
                key={i}
              />
              <i className='fa unchecked' />
              <i className='fa fa-check checked' />
              <div className='some-label'>
                {el}
              </div>
            </label>
          ))}

        {sizeSelected.includes(key) && key === 'Bra' && (
          <BraSizes
            selectedCup={size_restriction.Bra.Cup}
            selectedBand={size_restriction.Bra.Band}
            name={key}
            cups={newFilterOptions.Bra.Cup}
            bands={newFilterOptions.Bra.Band}
            onChange={onChange}
          />
        )}
      </div>
    )
    index++
  }

  return (
    <div className='size-wrapper'>
      {options}
      {/* <label className="uppercase">
        <input
          type="checkbox"
          onChange={e => handleCheckboxChange(e)}
          sizeType="One Size"
          name="size_restriction"
          value="One Size"
          className="checkbox"
          checked={size_restriction.oneSize.includes("One Size")}
        />
        "One Size"
        <br />
      </label> */}
    </div>
  )
}

export default Size
