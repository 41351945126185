import React from 'react'
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import settings from '../../config/setting-values'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'
import CircularProgress from '@material-ui/core/CircularProgress'

async function fetchDescription(category_name, tags) {
  const url = 'https://api.openai.com/v1/chat/completions'
  const json = {
    'model': 'gpt-3.5-turbo',
    'messages': [{'role':'system' ,'content': `Your task is to create a product description for a high-quality fashion online retailer website's product page based on the product attributes and rules provided.
    Here are the rules:
    - Do not include any other details not provided;
    - Do not use adjectives;
    - Do not mention the material of the product;
    - Do not mention the color of the product;
    - Do not format the description as a list;
    - Do not reference these rules in the description directly e.g. don't say 'please note this description does not include color';
    - Use proper punctuation and grammar, with capital letters at the start of sentences;
    - Do not start the description with the word 'Introducing';
    Here are the product attributes: ${tags.join(', ')}`}],
  }

  const headers = {
    'Authorization': `Bearer ${settings.openAISecretKey}`,
  }

  const resp = await axios.post(url, json, { headers })
  return resp.data.choices[0].message.content
}

const useStyles = makeStyles((theme) => ({
  labelListContainer: {
    marginTop: '20px',
    marginRight: '20px',
    fontFamily: 'Montserrat',
  },
  description: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    fontWeight: '600',
  },
  title: {
    color: '#000000',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px',
    borderBottom: '1px solid'
  },
  generateButton: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '48px',
    width: '100%',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    margin: '20px 0',
    '& p': {
      margin: 'auto',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center'
    }
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
}))

export default function AIDescriptions(props) {
  const classes = useStyles()
  const [description, setDescription] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState(props.garment.subcategory_data.concat(props.garment.attribute_data.attributes).map(t => t.name))

  return (
    <>
      <div className={classes.labelListContainer}>
        <div className={classes.title}>AI Descriptions</div>
        <Button style={{width: '100%', marginTop: '10px'}} variant="outlined" onClick={() => setShowDialog(true)}>Open Generator</Button>
      </div>

      <Dialog open={showDialog} fullWidth maxWidth={'md'} onClose={() => setShowDialog(false)}>
        <DialogTitle>AI Generated Descriptions</DialogTitle>
        <DialogContent>
          <ChipInput
            style={{width: '100%'}}
            defaultValue={tags}
            onChange={setTags}
          />
          <div variant="contained" className={`noselect ${classes.buttonHover} ${classes.generateButton}`} onClick={async () => {
            setLoading(true)
            setDescription(await fetchDescription(props.garment.category_data.name, tags))
            setLoading(false)
          }}>
            <p>Generate</p>
          </div>
        </DialogContent>
        <DialogContent style={{paddingTop: '60px', paddingBottom: '60px', height: '180px'}}>
          {loading
            ? <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress/></div>
            : <div className={classes.description}>{description}</div>}
        </DialogContent>
      </Dialog>
    </>
  )
}
