import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import api from '../../../api/api'
import Chip from '@material-ui/core/Chip'

class AudienceRow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phoneNr: this.props.phoneNr
    }
  }
  handleDelete = async (tag) => {
    const phoneNumber = this.state.phoneNr
    phoneNumber.tags = phoneNumber.tags.filter(e => e !== tag)
    await api.chatbotUpdateTags({ _id: phoneNumber._id, tags: phoneNumber.tags })
    this.setState({phoneNr: phoneNumber})
  }

  handleAddTag = async (tag) => {
    const phoneNumber = this.state.phoneNr
    phoneNumber.tags.push(tag)
    await api.chatbotUpdateTags({ _id: phoneNumber._id, tags: phoneNumber.tags })
    this.setState({phoneNr: phoneNumber})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    e.persist()
    this.handleAddTag(e.target[0].value)
    e.target[0].value = ''
  }

  render () {
    return (
      <TableRow key={this.state.phoneNr._id}>
        <TableCell align='center'>{this.state.phoneNr.firstname + ' ' + this.state.phoneNr.lastname}</TableCell>
        <TableCell align='center'>{this.state.phoneNr.phoneNr}</TableCell>
        <TableCell align='center'>
          {
            this.state.phoneNr.tags.map((tag) => {
              return (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => this.handleDelete(tag)}
                />)
            })
          }
          <form noValidate autoComplete='off' onSubmit={(e) => this.handleSubmit(e)}>
            <TextField id='standard-basic' label='' type='input' name='tagInput'/>
          </form>
        </TableCell>
      </TableRow>
    )
  }
}

export default AudienceRow
