import React, { Component } from 'react'
import { defaultProps } from 'recompose'
import ImageCarouselZoom from '../ImageCarouselZoom/ImageCarouselZoom'
import '../../index.css'
import './Garment.css'
import settings from '../../config/setting-values'
import editIcon from '../../assets/img/editIcon.png'
import deleteIcon from '../../assets/img/deleteIcon.png'
import inUseIcon from '../../assets/img/inUseIcon.png'

import { withStyles } from '@material-ui/core/styles'

const style = () => ({
  garmentContainer: {
    margin: '5px',
    position: 'relative',
    display: 'inline-block',
    overflowX: 'hidden'
  },
  garmentInGarmentNavigatorContainer: {
    position: 'relative',
    width: '280px !important',
    marginBottom: '10px',
    display: 'flex'
  },
  productTitle: {
    height: '36px',
    width: 'auto',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  controlIconsContainer: {
    position: 'absolute',
    display: 'inline-block',
    top: '0px',
    right: '0px',
    zIndex: '100',
  },
  controlIconsRow: {
    marginTop: '7px',
    marginRight: '7px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'align-right'
  },
  icon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '2px',
    color: '#86A8E0'
  },
  dragIcon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    marginLeft: '2px',
    color: '#86A8E0'
  },
  dragIconGrabbed: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grabbing',
    marginLeft: '2px',
    color: '#86A8E0'
  },
  inUseIcon: {
    position: 'absolute',
    top: '7px',
    marginLeft: '7px',
    padding: '6.5px',
    border: '1px solid #DAE0EA',
    borderRadius: '5px',
    background: 'white',
    display: 'flex',
    zIndex: '500'
  },
  details: {
    height: 'auto',
    width: '100%',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px'
  },
  brand: {
    height: '15px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'inline-block'
  },
  cardBody: {
    WebkitBoxFlex: '1',
    MsFlex: '1 1 auto',
    flex: '1 1 auto',
    minHeight: '1px',
    paddingTop: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    background: '#fdfdfd'
  },
  bottomMargin: {
    marginBottom: '10px'
  },
  originalPrice: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '12px',
    margin: '10px 0',
    textDecoration: 'line-through'
  },
  price: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px'
  },
  idPriceContainer: {
    display: 'block',
    justifyContent: 'space-between'
  },
  categoryText: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999'
  },
  productId: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999',
    '& b': {
      fontFamily: 'Montserrat'
    }
  },
  stock: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999'
  },
  notAvailable: {
    color: '#FF6008',
    display: 'block'
  },
  singleImage: {
    boxSizing: 'content-box',
    width: '100%',
    height: '280px',
    display: 'flex',
    borderBottom: '1px solid #DAE0EA',
    paddingBottom: '6px',
    '& img': {
      maxWidth: '100%',
      margin: 'auto',
      maxHeight: '277px'
    }
  }
})

class Garment extends Component {
  constructor (props) {
    super(props)

    this.state = {
      imageHeight: props.imageHeight,
      height: props.height,
      width: props.width,
      editable: props.editable,
      selectable: props.selectable,
      indicators: props.indicators,
      margin: '5px'
    }

    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleEditClick () {
    this.props.editOnClick(this.props.garmentIndex, this.props.garment)
  }

  handleDeleteClick (e) {
    e.nativeEvent.stopImmediatePropagation()
    this.props.deleteOnClick(this.props.garmentIndex, this.props.garment)
  }

  handleSelectClick () {
    if (this.state.selectable) {
      this.props.onClick(this.props.garment)
    }
  }

  getStock () {
    let stock
    let marketId = this.props.marketId
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(this.props.garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stock = this.props.garment.variant_data
        .filter(variant => variant.market_id === marketId)
        .some(variant => variant.stock_level > 0)
    } else {
      stock = ![null, undefined].includes(this.props.garment.stock_level) ? this.props.garment.stock_level > 0 : this.props.garment.stock > 0
    }
    return stock
  }

  getVariantData () {
    let marketId = this.props.marketId
    let variantData = this.props.garment.variant_data
    if ([null, undefined].includes(variantData)) {
      return null
    }

    if (![null, undefined].includes(marketId)) {
      variantData = this.props.garment.variant_data.filter(variant => variant.market_id === marketId)[0]
    }

    // If we have any in stock variants then return only those
    let inStockVariants = Array.isArray(variantData) ? variantData.filter(variant => variant.stock_level > 0) : []
    if (inStockVariants.length > 0) {
      variantData = inStockVariants
    }

    return variantData
  }

  getStockLevel () {
    let stockLevel
    let marketId = this.props.marketId
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(this.props.garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stockLevel = this.props.garment.variant_data
        .filter(variant => variant.market_id === marketId)
      if (stockLevel.length > 0) {
        stockLevel = stockLevel
          .map(variant => variant.stock_level)
          .reduce(((a,b)=>a+b))
      } else {
        stockLevel = 0
      }
    } else if (![null, undefined].includes(this.props.garment.variant_data)) {
      let validVariants = this.props.garment.variant_data.filter(variant => variant.stock_level > 0)
      if (validVariants.length === 0) {
        validVariants = this.props.garment.variant_data
      }
      stockLevel = validVariants
        .map(variant => variant.stock_level)
        .reduce(((a,b)=>a+b))

    } else {
      stockLevel = this.props.garment.stock_level
    }
    return parseInt(stockLevel)
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.setGarmentInEditor && this.props.garment._id !== prevProps.garment._id) {
      await this.props.setGarmentInEditor(prevProps.garment._id, false)
    }
  }

  async cleanUp () {
    if (this.props.setGarmentInEditor) {
      await this.props.setGarmentInEditor(this.props.garment._id, false)
    }
  }

  async componentDidMount () {
    if (this.props.setGarmentInEditor) {
      window.addEventListener('beforeunload', this.cleanUp)
    }
  }

  async componentWillUnmount () {
    if (this.props.setGarmentInEditor) {
      await this.cleanUp()
      window.removeEventListener('beforeunload', this.cleanUp)
    }
  }

  render () {
    const images = this.props.garment.garment_images.map(garmentImage => {
      if (this.props.useRemoteImage) {
        return garmentImage.remote_image_url
      }

      if (garmentImage.s3_url_resized) {
        return garmentImage.s3_url_resized
      }

      if (this.props.resized) {
        return `${settings.s3url}resized/${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
      } else {
        return `${settings.s3url}${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
      }
    })

    const { classes } = this.props
    let variantData = this.getVariantData()
    let inStock = this.getStock()
    let stockLevel = this.getStockLevel()
    if (isNaN(stockLevel)) {
      stockLevel = 'N/A'
    }
    return (this.props.garment != null &&
      <div
        className={this.props.inGarmentNavigator ? `${classes.garmentInGarmentNavigatorContainer} ${classes.bottomMargin} ${this.props.className}` : `${classes.garmentContainer} ${this.props.className}`}
        style={{ width: this.props.width, height: 'auto', marginLeft: this.state.margin, marginRight: this.state.margin, border: this.props.middleLine ? 0 : 'auto' }}
        onClick={() => {
          if (this.props.onClick && this.props.searchPage) {
            this.props.onClick(this.props.garment)
          }
        }}
      >
        {this.props.imageCarousel &&
          <ImageCarouselZoom
            images={this.props.garment.garment_images}
            outOfStock={!inStock}
            imageHeight={this.props.imageHeight}
          />}
        {this.props.singleImage &&
          <div className={classes.singleImage}>
            <img alt='garmentImage' src={images[0]} />
          </div>}
        {
          this.props.enableUsedProductIds !== false && this.props.usedProductIds && !this.props.usedProductIds.includes(this.props.garment.remote_product_id) &&
            <span
              className={classes.inUseIcon}
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation()
                if (this.props.onClick) {
                  this.props.onClick(this.props.garment)
                }
              }}
            >
              <img alt='inUse' src={inUseIcon} />
            </span>
        }
        {
          <div className={classes.controlIconsContainer}>
            <div className={classes.controlIconsRow}>
              {
                this.state.editable &&
                  <span className={classes.icon} onClick={() => { this.handleEditClick() }}>
                    <img alt='edit' src={editIcon} />
                  </span>
              }
              {
                this.state.editable &&
                  <span className={classes.icon} onClick={this.handleDeleteClick}>
                    <img
                      alt='delete'
                      src={deleteIcon}
                    />
                  </span>
              }
            </div>
          </div>
        }
        <div className={`${classes.cardBody}`}
        >
          {
            this.props.showBrand &&
              <p
                onClick={() => {
                  if (this.props.onClick) {
                    this.props.onClick(this.props.garment)
                  }
                }}
                className={classes.brand}
              >{this.props.garment.remote_brand}
              </p>
          }
          <a
            href={this.props.productUrl && this.props.garment.product_url}
            style={{ cursor: 'pointer' }}
            target='_blank' rel='noopener noreferrer'
          >
            <div className={classes.productTitle}>
              {this.props.garment.remote_product_title}
            </div>
          </a>
          <div
            className='d-flex flex-row'
            ref={element => { if (element) { element.style.setProperty('flex-direction', 'column', 'important') } }}
            onClick={() => { this.handleSelectClick() }}
          >
            {
              this.props.showProductCategories &&
                <p className={classes.details}>{this.props.garment.remote_product_categories}</p>
            }
            {
              this.props.showId &&
                <p className={classes.details}>{this.props.garment._id}</p>
            }
            {
              this.props.garment.category_data &&
              <div className={classes.categoryText}><b>{this.props.garment.category_data.name}</b></div>
            }
            {
              this.props.showPrice && variantData && variantData.length > 0 &&
              <>
                {
                  ![null, undefined].includes(variantData[0].discount) && variantData[0].discount > 0 &&
                  <div className={classes.originalPrice}>
                    { `${variantData[0].currency_code} ${(variantData[0].price / (1.0 - variantData[0].discount)).toFixed(2)}` }
                  </div>
                }
                <p className={classes.price}>{`${variantData[0].currency_code} ${variantData[0].price}`}</p>
              </>
            }

            <div className={classes.idPriceContainer}>
              {
                this.props.showProductId &&
                  <p className={classes.productId}><b>{this.props.garment.remote_product_id}</b></p>
              }
              {
                this.props.showStockLevel &&
                  <p className={stockLevel ? `${classes.stock}` : `${classes.notAvailable}`}>In stock: {stockLevel ? <b>{stockLevel}</b> : `${stockLevel}`}</p>
              }
            </div>
            {
              this.props.showUserFriendlyId && this.props.garment.chatbot_id !== undefined && this.props.garment.chatbot_id !== null &&
                <React.Fragment>
                  <p className={classes.details}>{'User friendly id:  ' + this.props.garment.chatbot_id}</p>
                </React.Fragment>
            }
            {
              this.props.showSizes && this.props.garment.remote_product_size !== undefined && this.props.garment.remote_product_size !== null &&
                <React.Fragment>
                  <p className={classes.details}>{'Size:  ' + this.props.garment.remote_product_size.join(' ')}</p>
                </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }
}

// Add default props to garment
const withDefaultProps = defaultProps({
  imageHeight: 200,
  height: 300,
  width: 280,
  editable: false,
  selectable: false,
  indicators: false,
  useRemoteImage: false
})

Garment = withStyles(style)(Garment)

export default withDefaultProps(Garment)
