const searchBarStyle = {
  form: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchBarContainer: {
    height: '48px',
    width: '100%'
  },
  searchBar: {
    display: 'flex',
    width: '100%',
    '& input[id="searchBar"]': {
      width: '100%',
      height: '48px',
      minHeight: '35px',
      paddingLeft: '43px',
      borderRadius: '3px',
      border: '1px solid #ECEEF5',
      fontFamily: 'Montserrat'
    },
    '& input[id="searchBar"]:focus': {
      outlineColor: 'black !important',
      outlineRadius: '8px',
      borderWidth: '2px !important'
    },
    '& i': {
      fontSize: '1.1em !important',
      opacity: '0.6',
      marginTop: '2.5px',
      marginLeft: '0.1em',
      padding: '12px'
    },
    '& input[id="searchButton"]': {
      width: '113px',
      marginLeft: 'auto',
      cursor: 'pointer',
      borderRadius: '5px',
      border: '1px solid #86A8E0',
      // marginLeft: '-70px',
      // marginTop: '5px',
      padding: '10px 11px',
      backgroundColor: props => props.invert ? 'white' : '#86A8E0',
      color: props => props.invert ? '#86A8E0' : 'white',
      height: '48px',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '19px'
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  uploadImageContainer: {
    display: 'flex',
    marginLeft: '-160px',
    padding: '0px',
    cursor: 'pointer'
  },
  buttonHover: {
    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  sortForm: {
    padding: '1.5em 0',
    select: {
      // padding:5px;
      // border-radius: 8px;
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none'
    },
    visibility: 'hidden'
  },
  uploadImageBoxHidden: {
    visibility: 'hidden',
    transition: '0.3s ease',
    height: '0px',
    width: '490px',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#d3d3d3',
    boxShadow: '0 0 10pt 0.2pt #d3d3d3',
    backgroundColor: 'white',
    padding: 0,
    margin: 0
  },
  uploadImageBox: {
    transition: '0.3s ease',
    height: '250px',
    width: '490px',
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#d3d3d3',
    boxShadow: '0 0 10pt 0.2pt #d3d3d3',
    backgroundColor: 'white',
    padding: 0,
    margin: 'auto',
    marginBottom: '30p'
  },
  searchIcon: {
    marginRight: '-32.5px',
    zIndex: '100',
    marginTop: '14px',
    height: '20px',
    width: '20px'
  },
  cameraIcon: {
    marginTop: '14px',
    marginBottom: '13px',
    marginRight: '30px',
    height: '21px',

    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  uploadIcon: {
    marginTop: '13px',
    marginBottom: '13px',
    marginRight: '80px',
    height: '23px',

    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  }
}

export default searchBarStyle
