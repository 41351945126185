import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  priceFilterCheckbox: {
    margin: 'auto 90px',
    display: 'flex',
    flexDirection: 'row',
    height: '30px',
    '@media only screen and (max-width: 1366px)': {
      margin: 'auto'
    }
  },
  priceCheckbox: {
    // width: '40px',
    height: '30px',
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px'
  },
  textContainer: {
    color: '#000000',
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '11px',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 15px'
  }
}))


const RelativePriceFilter = (props) => {
  const classes = useStyles()
  const [checked, setChecked] = React.useState(false)

  return (
    <div
      className={classes.priceFilterCheckbox}
    >
      <div className={classes.textContainer}>
         Filter By Relative Price
      </div>
      <input
        type='checkbox'
        className={classes.priceCheckbox}
        id='checkbox'
        value={checked}
        checked={checked}
        onChange={event => {
          setChecked(!checked)
          props.onRelativePriceFilterChange(!checked)
        }}
      />
    </div>
  )
}

export default RelativePriceFilter