import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import imgg from '../../assets/img/manIcon.png'
import FormControl from '@material-ui/core/FormControl'
import Select, { SelectChangeEvent } from '@material-ui/core/Select'
import Tick from '../../assets/img/tick-red.png'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  imageContainer: {
    gap: '20px',
    marginTop: '10px',
    display: 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  },
  imageInner: {
    width: '100%',
    height: '459px',
    position: 'relative',
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '16px',
    border: '1px solid transparent',
  },
  imageInnerChecked: {
    border: '1px solid #E8004C',
  },
  customCheckboxLabel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#333',
    gap: '8px',
    width: '100%',
    height: '100%',
  },
  customCheckboxHover: {
    '&:hover .customCheckboxCheckmark': {
      backgroundColor: '#555',
    },
  },
  customCheckboxInput: {
    display: 'none',
  },
  checkedPattern: {
    backgroundImage:
      ' linear-gradient(45deg, #e5e5e5 25%, transparent 25%), linear-gradient(-45deg, #e5e5e5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #e5e5e5 75%), linear-gradient(-45deg, transparent 75%, #e5e5e5 75%)',
    backgroundSize: '21px 21px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
  },
  customCheckboxCheckmark: {
    display: 'inline-block',
    width: '24px',
    height: '24px',
    borderRadius: '8px',
    verticalAlign: 'middle',
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #E8004C',
    right: '43px',
    top: '43px',
  },
  customCheckboxChecked: {
    '& + .customCheckboxCheckmark': {
      backgroundColor: '#007bff',
      borderColor: '#007bff',
    },
  },
  checkmarkIcon: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Tick})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'none',
  },
  iconVisible: {
    display: 'block',
  },
  customGallery: {
    display: 'block',
  },
  imageImg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    objectFit: 'contain',
  },
  imageMask: {
    opacity: '0.65',
  },
  customGallery: {
    '& + .customCheckboxCheckmark': {
      backgroundColor: 'red',
      borderColor: 'red',
    },
  },
  containerImageHeader: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'end',
    padding: '20px',
    gap: '10px',
    overflow: 'hidden',
    position: 'fixed',
    top: '71px',
    width: '100%',
    zIndex: '99',
    right: '0',
    borderTop: '1px solid #d1d1d1',
  },
  outlineBtn: {
    backgroundColor: 'transparent',
    border: '1px solid #777',
    fontSize: '18px',
    maxWidth: '200px',
    width: '1000%',
    color: '#777777',
    borderRadius: '24px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#E8004C',
      color: '#fff',
    },
  },
  outlineBtnColor: {
    border: '1px solid #6236FF',
    color: '#6236FF',
    textTransform: 'uppercase',
  },
  bgBack: {
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  dropBtn: {
    height: '500px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
  },
  dropBtnImg: {
    height: '104px',
    width: '104px',
  },
}))

const ImagesContainer = ({
  toggleNav,
  screenShrink,
  images,
  pagination,
  selectCategory,
  searchQuery,
  selectedRetailerName,
  checkboxStates,
  currentPage,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [checkedImg, setCheckedImg] = useState([])
  const [showCheckImg, setShowCheckImg] = useState({})
  const time = new Date().getTime()

  const handleCheckboxChange = (img) => {
    setCheckedImg((prev) => {
      const idExists =
        prev.filter((id, i) => {
          if (id._id === img._id && id.selected === true) {
            // id.selected = !id.selected;
            prev.splice(i, 1)
          }
          return id._id === img._id
        }).length > 0

      if (idExists) return [...prev]
      else return [...prev, { ...img, selected: true }]
    })

    setShowCheckImg((prev) => {
      const prevVal = prev[img._id] || false
      return {
        ...prev,
        [img._id]: !prevVal,
      }
    })
  }

  const selectAll = () => {
    let idObj = {}
    for (let i = 0; i <= images.garments.length; i++) {
      if (images.garments[i]?._id) {
        idObj = {
          ...idObj,
          [images.garments[i]._id]: true,
        }
      }
    }
    setCheckedImg(images.garments)
    setShowCheckImg(idObj)
  }

  const openEditor = () => {
    if (
      !(Object.keys(showCheckImg).length <= 0 || images.garments.length <= 0)
    ) {
      history.push('/admin/bg-edit', {
        checkedImg: checkedImg,
        allImages: images.garments,
        selectCategory,
        searchQuery,
        selectedRetailerName,
        checkboxStates,
        currentPage,
      })
    }
  }

  return (
    <div>
      <div className={`${classes.containerImageHeader}`}>
        <button
          className={`${classes.bgBack}`}
          style={{ visibility: screenShrink ? '' : 'hidden' }}
          onClick={toggleNav}
        >
          Filter
        </button>
        <button onClick={selectAll} className={`${classes.outlineBtn}`}>
          Select All
        </button>
        <button
          onClick={openEditor}
          className={`${classes.outlineBtn} ${classes.outlineBtnColor}`}
        >
          Start
        </button>
      </div>
      {/* pagination component*/}
      {pagination}

      <div className={`${classes.containerImage}`}>
        <div className={`${classes.imageContainer}`}>
          {images.garments &&
            images.garments.map((garment) => {
              return (
                <div
                  className={`${classes.imageInner} ${
                    showCheckImg[garment._id] ? classes.imageInnerChecked : ''
                  }`}
                  id={garment._id}
                >
                  <label
                    className={`${classes.customCheckboxLabel} ${classes.customCheckboxHover} ${classes.customGallery}`}
                  >
                    <img
                      src={
                        (garment.garment_images[0].s3_url_removed_bg ||
                        garment.garment_images[0].s3_url) + '?t=' + time
                      }
                      className={`${classes.imageImg} ${
                        garment.garment_images[0].s3_url_removed_bg
                          ? classes.checkedPattern
                          : ''
                      }`}
                    />
                    {/* <img
                      src={
                        garment.garment_images[0].s3_url_background_mask
                      }
                      className={`${classes.imageImg} ${classes.imageMask}`}
                    /> */}
                    <input
                      type="checkbox"
                      className={classes.customCheckboxInput}
                      checked={showCheckImg}
                      // name={garment._id}
                      onChange={() => handleCheckboxChange(garment)}
                    />
                    <span
                      className={`${classes.customCheckboxCheckmark} ${
                        showCheckImg[garment._id]
                          ? classes.customCheckboxChecked
                          : ''
                      }`}
                    >
                      <span
                        className={`${classes.checkmarkIcon} ${
                          showCheckImg[garment._id] ? classes.iconVisible : ''
                        }`}
                      ></span>
                    </span>
                  </label>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ImagesContainer
