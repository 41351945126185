import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// import outfit1 from './example-outfits/outfit1.jpg'
import outfit2 from './example-outfits/outfit2.jpg'
import outfit3 from './example-outfits/outfit3.jpg'
import outfit4 from './example-outfits/outfit4.jpg'
// import outfit5 from './example-outfits/outfit5.jpg'
// import outfit6 from './example-outfits/outfit6.jpg'
import outfit7 from './example-outfits/outfit7.jpg'
import outfit8 from './example-outfits/outfit8.jpg'
//import outfit9 from './example-outfits/outfit9.jpg'
import outfit10 from './example-outfits/outfit10.jpg'
import outfit11 from './example-outfits/outfit11.jpg'
import outfit13 from './example-outfits/outfit13.jpg'
import Masonry from 'react-masonry-css'

const demoOutfits = [
  { img: outfit2, gender: 'men' }, 
  // { img: outfit1, gender: 'women' }, 
  { img: outfit8, gender: 'women' }, 
  { img: outfit13, gender: 'women' }, 
  { img: outfit3, gender: 'men' }, 
  { img: outfit4, gender: 'men' }, 
  { img: outfit7, gender: 'men' }, 
  { img: outfit10, gender: 'men' }, 
  // { img: outfit6, gender: 'women' },
  { img: outfit11, gender: 'men' }, 
]

const useStyles = makeStyles(() => ({
  demoUploadContainer: {
    padding: '20px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '600px',
  },
  demoUploadTitle: {
    color: '#656565',
    fontFamily: 'Montserrat',
    fontSize: '1.4em',
    textTransform: 'uppercase',
    marginBottom: '30px',
    height: '40px',
    padding: '5px'
  },
  garmentContainer: {
    display: 'inline-block',
    '&:hover': {
      borderRadius: '4px',
      // border: '1px solid #',
      border: '1px solid #E0E0E0',
      '&imageOverlayContainer': {
        opacity: 1
      },
      '&imageCarousel': {
        opacity: 0.3
      }
    }
  },
  imageOverlayContainer: {
    transition: '.5s ease',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  imageCarousel: {
    opacity: 1,
    display: 'block',
    width: '100%',
    height: 'auto',
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    '&:hover': {
      opacity: '0.3'
    }
  },
  ImageCarouselContainer: {
    width: '100%',
    height: '100%'

  },
  img: {
    borderRadius: '3px',
    width: '100%',
    margin: 'auto',
    display: 'block',
    verticalAlign: 'center'
  },
  masonryContainer: {
    flexGrow: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(218, 183, 135, 0.8)',
      outline: '1px solid rgba(218, 183, 135, 0.6)',
    }

  },
  demoMasonryGrid: {
    display: 'flex',
    width: 'auto',
    paddingRight: '10px',
  },
  demoMasonryGridColumn: {
    backgroundClip: 'padding-box',
    paddingLeft: '10px',
  }
}))

export default function DemoOutfits(props) {
  const classes = useStyles()

  return (
    <div className={classes.demoUploadContainer}>
      <div className={classes.demoUploadTitle}>Demo Outfits</div>
      <div className={classes.masonryContainer}>
        <Masonry
          breakpointCols={4}
          className={classes.demoMasonryGrid}
          columnClassName={classes.demoMasonryGridColumn}>

          {demoOutfits.map((outfit) => (
            <div
              className={classes.garmentContainer}
              key={outfit.img}
              onClick={() => props.onSubmit(outfit.img, true, outfit.gender)}
            >
              <img className={classes.img} src={outfit.img} alt='Demo outfit' />
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  )
}
