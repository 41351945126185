import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Modal from 'react-bootstrap/Modal'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Table from '@material-ui/core/Table'
import ReactPaginate from 'react-paginate'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import plusIcon from '../../../assets/img/plusIcon.png'
import api from '../../../api/api'
import AudienceBody from './AudienceBody'

const style = theme => ({
  root: {},
  table: {
    // margin: '20px',
    overflow: 'hidden',
    maxWidth: '1200px',
    tableLayout: 'auto'
  },
  addItemContainer: {
    boxSizing: 'border-box',
    height: '33px',
    width: '100px',
    border: '1px solid #86A8E0',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    marginRight: '18.5px',
    marginTop: '8.5px',
    '& img': {
      marginTop: '10px',
      marginLeft: '10px',
      display: 'inline-block',
      height: '13px',
      width: '13px'
    },
    '& p': {
      height: '18px',
      width: '60px',
      color: '#86A8E0',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '7.5px',
      marginLeft: '1px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  buttonHover: {
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  addItemCell: {
    width: '200px'
  },
  addUserCard: {
    width: '400px',
    // height: '400px',
    margin: 'auto',
    boxShadow: 'none'
  },
  addUserButton: {
    marginTop: '20px'
  },
  genderSelect: {
    width: '120px',
    marginRight: 'auto',
    marginTop: '16px'
  },
  paginationContainer: {
    maxWidth: '1200px'
  }
})

class ChatbotAudience extends React.Component {
  constructor (props) {
    super(props)
    this.labelRef = React.createRef()
    this.state = {
      userId: JSON.parse(localStorage.getItem('user'))._id,
      addUserModalShow: false,
      username: '',
      phoneNumber: '',
      firstname: '',
      lastname: '',
      gender: '',
      helperText: '',
      shouldRefresh: false,
      phoneNumbers: [],
      data: [],
      offset: 0,
      perPage: 50,
      pageCount: 0
    }
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
  }

  async handleSubmitForm (e) {
    e.preventDefault()
    e.persist()
    if (!this.state.helperText && this.state.firstname && this.state.gender) {
      console.log(this.state)

      const body = {
        username: this.state.username || this.state.firstname + this.state.lastname + '@tempemail.com',
        phoneNr: this.state.phoneNumber,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        gender: this.state.gender,
        chatbotCorporateUser: this.state.userId
      }
      await api.addUser(body)
      await this.setState({ name: '', phoneNr: '', username: '', gender: '', email: '', shouldRefresh: true })
      this.handleClose()
      await this.getPhoneNumbers()
    }
  }

  handleClose = () => {
    this.setState({ addUserModalShow: false,
      username: '',
      phoneNumber: '',
      firstname: '',
      lastname: '',
      gender: '',
      helperText: '',
      shouldRefresh: false
    })
  }

  addOnClick = () => {
    this.setState({ addUserModalShow: true })
  }

  async getPhoneNumbers () {
    const phoneNrs = (await api.getPhoneNumbers({ userId: this.state.userId })).data
    await this.setState({ phoneNumbers: phoneNrs,
      data: phoneNrs.slice(this.state.offset, this.state.offset + this.state.perPage),
      pageCount: Math.ceil(phoneNrs.length / this.state.perPage)
    })
  }

  async componentDidMount () {
    await this.getPhoneNumbers()
  }

  handlePageClick = (value) => {
    let selected = value.selected
    let offset = Math.ceil(selected * this.state.perPage)
    let data = this.state.phoneNumbers.slice(offset, offset + this.state.perPage)
    this.setState({ offset: offset, data: data })
  }

  handleChange = (e) => {
    e.persist()
    const { name, value } = e.target
    this.setState({ [name]: value })
    if (name === 'phoneNumber') {
      if (value.length !== 13 || !value.match(/\+[0-9]+/)) {
        this.setState({ helperText: 'The number should be 12 digits long and contain the country code' })
      } else {
        this.setState({ helperText: '' })
      }
    }
  }

  render () {
    const { classes } = this.props
    const labelRef = this.labelRef
    return (
      <div className={classes.root}>
        <TableContainer className={classes.table} component={Paper}>
          <Table size='medium' aria-label='chatbot phone numbers'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Name</TableCell>
                <TableCell align='center'>Phone Number</TableCell>
                <TableCell align='center'>Tags</TableCell>
                <TableCell align='center' className={classes.addItemCell}>
                  <div
                    className={`${classes.addItemContainer} ${classes.buttonHover}`}
                    onClick={(e) => {
                      this.addOnClick()
                      e.stopPropagation()
                    }}
                  >
                    <img alt='plusIcon' src={plusIcon} />

                    <p>ADD</p>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <AudienceBody data={this.state.data} shouldRefresh={this.state.shouldRefresh}/>
          </Table>
        </TableContainer>
        <div className={classes.paginationContainer}>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'pagination-active'}
          />
        </div>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          dialogClassName='modal-container'
          centered
          show={this.state.addUserModalShow} onHide={this.handleClose}
        >
          <Modal.Body>
            <Card className={classes.addUserCard}>
              <CardContent>
                <div>
                  <Typography component='h1' variant='h5' align='center'>
                    Add User
                  </Typography>
                  <form name='form' onSubmit={this.handleSubmitForm} autoComplete='off'>
                    <TextField
                      value={this.state.firstname}
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='name'
                      label='First Name'
                      name='firstname'
                      type='string'
                      autoFocus
                      onChange={this.handleChange}
                    />
                    <TextField
                      value={this.state.lastname}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='name'
                      label='Last Name'
                      name='lastname'
                      type='string'
                      autoFocus
                      onChange={this.handleChange}
                    />
                    <TextField
                      value={this.state.username}
                      variant='outlined'
                      margin='normal'
                      fullWidth
                      id='email'
                      label='Email Address'
                      name='username'
                      type='email'
                      autoFocus
                      onChange={this.handleChange}
                    />
                    <TextField
                      value={this.state.phoneNumber}
                      error={!!this.state.helperText}
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='phoneNumber'
                      label='Phone Number'
                      name='phoneNumber'
                      type='string'
                      onChange={this.handleChange}
                      helperText={this.state.helperText}
                    />
                    <FormControl variant='outlined' required className={classes.genderSelect}>
                      <InputLabel ref={labelRef} htmlFor='genderSelect' id="gender-select-label">Gender</InputLabel>
                      <Select
                        labelId="gender-select-label"
                        id="genderSelect"
                        value={this.state.gender}
                        onChange={this.handleChange}
                        name='gender'
                        type='text'
                        native
                        input={
                          <OutlinedInput
                            name="gender"
                            label='Gender'
                            id="gender-select-simple"
                          />
                        }
                      >
                        <option disabled hidden></option>
                        <option value={'M'}>M</option>
                        <option value={'F'}>F</option>
                      </Select>
                    </FormControl>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.addUserButton}
                    >
                      Add
                    </Button>
                  </form>
                </div>
              </CardContent>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default withStyles(style)(ChatbotAudience)
