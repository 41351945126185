import React, {useEffect, useState} from 'react'
import api from '../../api/api'
import Button from '@material-ui/core/Button'


const VTOSagemakerButtons = () => {

  const [endpointStatus, setEndpointStatus] = useState('OutOfService')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // Initial check
    checkEndpointStatus()

    // Set interval to check every 10 seconds
    const interval = setInterval(checkEndpointStatus, 30000)

    // Clear interval on component unmount
    return () => clearInterval(interval)
  }, [])

  const checkEndpointStatus = async () => {
    try {
      const endpointStatusResponse = await api.checkSagemakerEndpointStatus()
      if (endpointStatusResponse === 'Creating' || endpointStatusResponse === 'Deleting') {
        setLoading(true)
      } else {
        setLoading(false)
      }
      console.log(endpointStatusResponse)
      setEndpointStatus(endpointStatusResponse)
    } catch (error) {
      console.error(error)
    }
  }

  const turnOnVTO = async () =>{
    try {
      setLoading(true)
      await api.deploySagemakerEndpoint()
      const endpointStatusResponse = await api.checkSagemakerEndpointStatus()
      setEndpointStatus(endpointStatusResponse)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const deleteVTOEndpoint = async () =>{
    try {
      setLoading(true)
      await api.deleteSagemakerEndpoint()
      const endpointStatusResponse = await api.checkSagemakerEndpointStatus()
      setEndpointStatus(endpointStatusResponse)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (<>
    <h3>VTO Pipeline</h3>
    <Button
      variant="outlined"
      disabled={endpointStatus === 'InService' || loading}
      onClick={() => {
        turnOnVTO()
      }}
      style={{marginRight: '1rem'}}
    >
      {loading ? 'Loading...' : 'Turn on VTO Pipeline'}
    </Button>
    <Button
      variant="outlined"
      disabled={endpointStatus !== 'InService' || loading}
      onClick={() => {
        deleteVTOEndpoint()
      }}
    >
      {loading ? 'Loading...' : 'Delete VTO Endpoint'}
    </Button>
  </>)
}

export default VTOSagemakerButtons