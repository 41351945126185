import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import demoPersona from '../../assets/img/demoPersona.png'
import demoOccasion from '../../assets/img/demoOccasion.png'
import demoBodyType from '../../assets/img/demoBodyType.png'
import mensStyling from '../../assets/img/mensStyling.png'
import highStreet from '../../assets/img/highStreet.png'
import DemoOutfits from 'views/OutfitDemo/DemoOutfits'
const pics = [demoPersona, demoOccasion, demoBodyType, mensStyling, highStreet]

const useStyles = makeStyles(theme => ({
  card: {
    width: '25vw'
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  outfitLandingPage: {
    paddingTop: '10px',
    paddingLeft: '40px'
  },
  mask: {
    height: '381px',
    width: '381px',
    borderRadius: '5px'
  },
  bottomRow: {
  }
}))

export default function OutfitLandingPage () {
  const demoRoutes = [
    {
      path: '/demoOutfits/personaDemo',
      name: 'PERSONA',
      component: DemoOutfits,
      layout: '/admin'
    },
    {
      path: '/demoOutfits/occasionDemo',
      name: 'OCCASION',
      component: DemoOutfits,
      layout: '/admin'
    },
    {
      path: '/demoOutfits/bodyTypeDemo',
      name: 'BODYTYPE',
      component: DemoOutfits,
      layout: '/admin'
    },
    // {
    //   path: '/demoOutfits/mensStyling',
    //   name: "MEN'S STYLING",
    //   component: DemoOutfits,
    //   layout: '/admin'
    // },
    {
      path: '/demoOutfits/highStreet',
      name: 'HIGH STREET',
      component: DemoOutfits,
      layout: '/admin'
    }
  ]
  const spacing = 4
  const classes = useStyles()

  return (
    <div className={classes.outfitLandingPage}>
      <Grid container className={classes.root} spacing={spacing}>
        <Grid item xs={12}>
          <Grid container spacing={spacing} wrap='nowrap'>
            {[0, 1, 2].map((value, key) => {
              return (
                <Grid key={key} item>
                  <Card>
                    <NavLink
                      to={demoRoutes[value].layout + demoRoutes[value].path}
                      className={classes.item}
                      activeClassName='active'
                    >
                      <img alt='introImage' className={classes.mask} src={pics[value]} />
                    </NavLink>

                  </Card>
                </Grid>
              )
            }

            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.bottomRow}>
            <Grid container spacing={spacing}>
              {[0].map((value, key) => (
                <Grid key={key} item>
                  <Paper>
                    <NavLink
                      to={demoRoutes[2 + value].layout + demoRoutes[3 + value].path}
                      className={classes.item}
                      activeClassName='active'
                    >
                      <img alt='introImage' className={classes.mask} src={pics[4 + value]} />
                    </NavLink>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
