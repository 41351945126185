import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDrop } from 'react-dnd'
import Garment from './Garment'

const useStyles = makeStyles((theme) => ({
  garmentGridContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left'
  },
  col: {
    flexGrow: 0,
    padding: 0
  },
  row: {
    padding: '10px',
    justifyContent: 'space-between'
  },
  garmentClass: {
    margin: 'auto',
    border: '1px solid #DAE0EA',
    borderRadius: '5px'
  },
  imageCarouselClassName: {
    cursor: 'pointer',
    borderBottom: '1px solid #DAE0EA'
  },
}))

export const ItemTypes = {
  GARMENT: 'garment'
}

export default function GarmentGrid(props) {
  const classes = useStyles()

  const [, drop] = useDrop({accept: ItemTypes.GARMENT})

  let garmentComponents = props.garments.map((garment, index) => {
    return (
      <Garment
        key={garment._id}
        id={`${garment._id}`}
        index={props.index}
        editable={false}
        draggable={props.replacementOrderDisplay}
        garment={garment}
        imageHeight='180px'
        inGarmentNavigator
        imageCarousel
        imageCarouselClassName={classes.imageCarouselClassName}
        className={classes.garmentClass}
        productUrl
        resized
        showProductId
        showBrand
        showPrice
        showStockLevel
        selectable
        onClick={
          props.addGarmentToOutfit
            ? () => props.handleAddGarmentClick(garment)
            : () => props.handleSelectGarmentClick(garment)
        }
        usedProductIds={props.usedProductIds}
        moveGarment={props.moveGarment}
        findGarment={props.findGarment}
        handleAddToReplacementOrderClick={
          !props.addGarmentToOutfit && !props.replacementOrderDisplay && props.editMode === 'shop_the_look' ? () => props.handleAddGarmentToReplacementOrderClick(garment) : null
        }
        handleRemoveFromReplacementOrderClick={
          !props.addGarmentToOutfit && props.replacementOrderDisplay && props.editMode === 'shop_the_look' ? () => props.handleRemoveGarmentFromReplacementOrderClick(garment) : null
        }
        number={props.replacementOrderDisplay ? index + 1 : null}
        marketId={props.marketId}
      />
    )
  })

  return (

    <div className={classes.garmentGridContainer} ref={drop}>
      {garmentComponents}
    </div>

  )
}
