import React from 'react'
import Outfit from '../../components/Outfit/Outfit'
import { withStyles } from '@material-ui/core/styles'

const style = theme => ({
  outfitContainer: {
    boxSizing: 'border-box',
    // "height": "490px",
    '@media only screen and (min-width: 700px)': {
      width: 'auto',
      marginRight:'20px'
    },
    '@media only screen and (min-width: 1365px)': {
      width: 'auto',
      marginRight:'20px'
    },
    '@media only screen and (min-width: 1439px)': {
      width: '1000px',
      marginRight:'20px'
    },
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginBottom: '20px',
    marginLeft: '20px'
  },
  imageCarouselClassName: {
    borderBottom: '1px solid #DAE0EA'
  },
  outfitGarmentClassName: {
    border: '1px solid #DAE0EA',
    borderRadius: '5px'
  },
})

class OutfitArea extends React.PureComponent {
  render () {
    const { classes } = this.props
    return (
      <div>
        {this.props.campaignMode === 'create' && !this.props.preview ?
          this.props.availableOutfits.length !== 0 &&
            this.props.availableOutfits.slice((this.props.page - 1)  * this.props.chunk, this.props.page * this.props.chunk).map((outfit, index) => (
              <div className={classes.outfitContainer} key={index}>
                <Outfit
                  editable={false}
                  key={index}
                  id={index}
                  outfit={outfit}
                  outfitNumber={this.props.chunk * (this.props.page - 1) + index + 1}
                  outfitGarmentClassName={classes.outfitGarmentClassName}
                  imageCarouselClassName={classes.imageCarouselClassName}
                  checked={this.props.outfitSelected[this.props.chunk * (this.props.page - 1) + index]}
                  checkboxOnClick={() => { this.props.handleSelectOutfitClick(this.props.chunk * (this.props.page - 1) + index)}}
                />
              </div>
            )) :
          this.props.campaignOutfits.length !== 0 &&
              this.props.campaignOutfits.slice((this.props.page - 1)  * this.props.chunk, this.props.page * this.props.chunk).map((outfit, index) => (
                <div className={classes.outfitContainer} key={index}>
                  <Outfit
                    editable={false}
                    key={index}
                    id={index}
                    outfit={outfit}
                    outfitNumber={index + 1}
                    outfitGarmentClassName={classes.outfitGarmentClassName}
                    imageCarouselClassName={classes.imageCarouselClassName}
                    checkboxOnClick={() => {}}
                  />
                </div>
              ))
        }
      </div>
    )
  }
}

export default withStyles(style)(OutfitArea)
