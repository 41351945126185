import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'

export default function MarketIdAccordionSection(props) {
  const getLabels = async () => {
    let newLabels = await api.getMarketIds({url: props.url})

    try {
      newLabels = newLabels
        .map(marketId => {
          return {
            name: marketId,
            _id: marketId,
            full_name: marketId,
            active: true
          }
        })
      return newLabels
    } catch {
      console.error('Failed to get market ids')
      return []
    }


  }

  return (
    <AbstractAccordionSection
      title={'Market ID'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}