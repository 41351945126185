import settings from '../config/setting-values'
import api from '../api/api'

class Auth {
  constructor () {
    this.getUser = this.getUser.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signOut = this.signOut.bind(this)
    this.setEndpoint = this.setEndpoint.bind(this)
    this.getEndpoint = this.getEndpoint.bind(this)
    this.setEndpointUsers = this.setEndpointUsers.bind(this)
    this.getEndpointUsers = this.getEndpointUsers.bind(this)
    this.authenticated = false

    // Set default endpoint if not already set
    const endpoint = this.getEndpoint()
    if (!endpoint) {
      this.endpoint = settings.endpoint
      localStorage.setItem('endpoint', JSON.stringify(this.endpoint))
    }

    const endpointUsers = this.getEndpointUsers()
    if (!endpointUsers) {
      this.endpointUsers = null
      localStorage.setItem('endpointUsers', JSON.stringify(this.endpointUsers))
    }
  }

  setEndpoint (endpoint) {
    this.endpoint = endpoint
    localStorage.setItem('endpoint', JSON.stringify(endpoint))
  }

  setEndpointUsers (endpointUsers) {
    this.endpointUsers = endpointUsers
    localStorage.setItem('endpointUsers', JSON.stringify(endpointUsers))
  }

  getEndpoint () {
    return JSON.parse(localStorage.getItem('endpoint'))
  }

  getEndpointUsers () {
    return JSON.parse(localStorage.getItem('endpointUsers'))
  }

  getUser () {
    return JSON.parse(localStorage.getItem('user'))
  }

  getIdToken () {
    const idToken = localStorage.getItem('idToken')
    if (idToken) {
      return idToken
    } else {
      return ''
    }
  }

  async checkIsAuthenticated () {
    try {
      const res = await api.whoami()
      localStorage.setItem('user', JSON.stringify(res.data))
      return true
    } catch (e) {
      localStorage.setItem('user', null)
      return false
    }
  }

  isAuthenticated () {
    return !!localStorage.getItem('user')
  }

  setAuthenticated () {
    localStorage.setItem('isAuthenticated', JSON.stringify(true))
  }

  async signOut () {
    localStorage.setItem('user', null)
    localStorage.setItem('authToken', null)
    window.location.href = '/login'
  }
}

const authClient = new Auth()

export default authClient
