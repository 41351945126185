import React, { createContext, useState } from 'react'

export const MouseContext = createContext({
  cursorType: '',
  cursorChangeHandler: () => {},
})

const MouseContextProvider = (props) => {
  const [cursorSize, setCursorSize] = useState({
    height: '40px',
    width: '40px'
  })
  
  const cursorSizeHandler = (size) => {
    setCursorSize(size)
  }

  return (
    <MouseContext.Provider
      value={{
        cursorSize: cursorSize,
        cursorSizeHandler: cursorSizeHandler
      }}
    >
      {props.children}
    </MouseContext.Provider>
  )
}

export default MouseContextProvider
