import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    marginTop: '0.5em',
    padding: '12px 22px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #A4A4A4',
    borderRadius: '15px'
  },
  selectContainer: {
    flexGrow: '1',
  },
  garmentCategorySelector: {
    width: '11em',
    display: 'inline-block',
    backgroundColor: '#F7F7F7',
    fontWeight: '600',
    border: 0,
    color: '#1F1F1F',
    fontSize: '13px',
  },
  confirmButton: {
    float: 'right',
    color: '#fff',
    backgroundColor: '#1f1f1f',
    padding: '6px 25px 6px 25px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    fontWeight: 'bold',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: '#1f1f1f',
    fontSize: '13px',
  }
}))

export default function GarmentCategorySelector(props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.selectContainer}>
        <select className={classes.garmentCategorySelector} value={props.selectedCategory} onChange={e => props.setSelectedCategory(parseInt(e.target.value))}>
          {
            props.categories.map(category => {
              return <option value={category._id} key={category._id}>{category.name.toUpperCase()}</option>
            })
          }
        </select>
      </div>

      <button
        className={classes.confirmButton}
        onClick={() => {props.onConfirm && props.onConfirm()}}>
          CONFIRM
      </button>
    </div >
  )
}