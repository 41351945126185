import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { MouseContext } from '../../context/mouse-context'

const useStyles = makeStyles(() => ({
  dot: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    width: '8px',
    height: '8px',
    transform: 'translate(-50%, -50%)',
    zIndex: '999',
    pointerEvents: 'none',
    borderRadius: '50%',
    backgroundClip: 'content-box',
    border: '2px solid blue',
  },
}))

const CursorPreview = ({ x, y }) => {
  const classes = useStyles()
  const { cursorSize } = useContext(MouseContext)

  return (
    <div
      className={classes.dot}
      style={{
        height: cursorSize?.height,
        width: cursorSize?.width,
        left: `${x ? x : 0}px`,
        top: `${y ? y : 0}px`,
      }}
    ></div>
  )
}

export default CursorPreview
