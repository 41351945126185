import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Select, MenuItem, FormControl, InputLabel} from '@material-ui/core'
import AccordionSection from './AccordionSection'
import GenderAccordionSection from './AccordionSections/GenderAccordionSection'
import SourceAccordionSection from './AccordionSections/SourceAccordionSection'
import CategoryAccordionSection from './AccordionSections/CategoryAccordionSection'
import CategoryNewTaxonomyAccordionSection from './AccordionSections/CategoryNewTaxonomyAccordionSection'
import AttributeNewTaxonomyAccordionSection from './AccordionSections/AttributeNewTaxonomyAccordionSection'
import SlideSwitch from './SlideSwitch'
import SubcategoryAccordionSection from './AccordionSections/SubcategoryAccordionSection'
import AttributeAccordionSection from './AccordionSections/AttributeAccordionSection'
import BoundingBoxLocationAccordionSection from './AccordionSections/BoundingBoxLocationAccordionSection'
import ProductCategoryAccordionSection from './AccordionSections/ProductCategoryAccordionSection'
import ProductCategoryExcludeAccordionSection from './AccordionSections/ProductCategoryExcludeAccordionSection'
import OccasionAccordionSection from './AccordionSections/OccasionAccordionSection'
import WeatherAccordionSection from './AccordionSections/WeatherAccordionSection'
import PersonaAccordionSection from './AccordionSections/PersonaAccordionSection'
import BrandAccordionSection from './AccordionSections/BrandAccordionSection'
import MarketIdAccordionSection from './AccordionSections/MarketIdAccordionSection'
import api from 'api/api'

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    height: '100%',
  },
  filtersContainer: {
    flex: 1,
    paddingBottom: '10px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    width: '60%',
  },
  numberInput: {
    width: '30%',
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ddd',
  },
  formControl: {
    width: '100%',
    marginBottom: '10px',
    marginTop: '10px'
  },
  selectLabel: {
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    marginBottom: '5px',
  },
  select: {
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
  },
  slideSwitchContainer: {
    padding: '10px 0',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderTop: '1px solid #ddd',
    backgroundColor: '#f4f4f4',
    position: 'sticky',
    bottom: 0,
  },
  button: {
    flex: 1,
    margin: '0 5px',
    padding: '10px 0',
    fontSize: '1em',
    fontWeight: '600',
    textTransform: 'uppercase',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  pasteButton: {
    backgroundColor: '#86A8E0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#7391c1',
    },
  },
  approveButton: {
    backgroundColor: '#86A8E0',
    color: 'white',
    '&:hover': {
      backgroundColor: '#7391c1',
    },
  },
  runJobButton: {
    backgroundColor: (props) => (props.enabledRunButton ? '#FF9800' : '#ddd'),
    color: (props) => (props.enabledRunButton ? 'white' : '#888'),
    '&:hover': {
      backgroundColor: (props) => (props.enabledRunButton ? '#fb8c00' : '#ddd'),
    },
  },
}))

const NumberInput = ({label, value, onChange}) => {
  const classes = useStyles()
  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>{label}</div>
      <input
        className={classes.numberInput}
        type="number"
        step={1}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

export default function Sidebar(props) {
  const classes = useStyles(props.enabledRunButton)
  const [enabledRunButton, setEnabledRunButton] = useState(false)
  const [vettingJobs, setVettingJobs] = useState([])
  const {url, selectedSource} = props

  useEffect(() => {
    api.getVettingJobs({url}).then((data) => setVettingJobs(data))
  }, [selectedSource])

  useEffect(() => {
    let enable = false
    if (vettingJobs && selectedSource && selectedSource.length === 1) {
      const source = selectedSource[0].toLowerCase()
      enable =
        vettingJobs.includes(source) ||
        (source.includes('gameday') && vettingJobs.includes('gamedaycouture'))
    }
    setEnabledRunButton(enable)
  }, [vettingJobs, selectedSource])

  const handleInputChange = (callback) => (event) => {
    const value = event.currentTarget.value;
    if (value === "") {
      callback("");
    } else {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        callback(parsedValue);
      }
    }
  };


  return (
    <div className={classes.sidebar}>
      <div className={classes.filtersContainer}>
        {props.onApprovalLevelChange && (
          <NumberInput
            label="Approval Level To Save"
            value={props.approvalLevel}
            onChange={handleInputChange(props.onApprovalLevelChange)}
          />
        )}
        {props.onApprovalLevelShowChange && (
          <NumberInput
            label="Approval Level To Show"
            value={props.approvalLevelShow}
            onChange={handleInputChange(props.onApprovalLevelShowChange)}
          />
        )}
        {props.onCategoryMinApprovalLevelShowChange && (
          <NumberInput
            label="Min Category Approval Level To Show"
            value={props.categoryMinApprovalLevelShow}
            onChange={handleInputChange(props.onCategoryMinApprovalLevelShowChange)}
          />
        )}
        {props.onSubcategoryMinApprovalLevelShowChange && (
          <NumberInput
            label="Min Subcategory Approval Level To Show"
            value={props.subcategoryMinApprovalLevelShow}
            onChange={handleInputChange(props.onSubcategoryMinApprovalLevelShowChange)}
          />
        )}
        {props.onLimitChange && (
          <NumberInput
            label="Items Per Page"
            value={props.limit}
            onChange={handleInputChange(props.onLimitChange)}
          />
        )}
        {props.onMinSegmentationQualityChange && (
          <NumberInput
            label="Min Segmentation Quality"
            value={props.minSegmentationQuality}
            onChange={handleInputChange(props.onMinSegmentationQualityChange)}
          />
        )}
        {props.onMaxSegmentationQualityChange && (
          <NumberInput
            label="Max Segmentation Quality"
            value={props.maxSegmentationQuality}
            onChange={handleInputChange(props.onMaxSegmentationQualityChange)}
          />
        )}
        {props.onCreatedAfterChange && (
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>Created After</div>
            <input
              className={classes.numberInput}
              type="text"
              onChange={props.onCreatedAfterChange}
              value={props.createdAfter}
            />
          </div>
        )}
        {props.onInStockChange && (
          <div className={classes.slideSwitchContainer}>
            <SlideSwitch
              title="In Stock"
              checked={props.inStock}
              handleChange={props.onInStockChange}
            />
          </div>
        )}
        {props.taxonomies && props.selectedTaxonomy && (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.selectLabel}>Select Taxonomy</InputLabel>
            <Select
              value={props.selectedTaxonomy}
              onChange={(event) => props.setSelectedTaxonomy(event.target.value)}
              className={classes.select}
              label="Select Taxonomy"
            >
              {props.taxonomies.map((taxonomy) => (
                <MenuItem key={taxonomy._id} value={taxonomy._id}>
                  {taxonomy.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div className={classes.accordionSectionContainer}>
          <GenderAccordionSection setLabels={props.setGenders}/>
          <SourceAccordionSection url={url} setLabels={props.setUsers}/>
          {props.setBoundingBoxLocations && (
            <BoundingBoxLocationAccordionSection url={url} setLabels={props.setBoundingBoxLocations}/>
          )}
          {props.setCategories && (
            <CategoryAccordionSection url={url} setLabels={props.setCategories}/>
          )}
          {props.setCategoriesNewTaxonomy && (
            <CategoryNewTaxonomyAccordionSection
              url={url}
              setLabels={props.setCategoriesNewTaxonomy}
              taxonomy={props.taxonomy}
            />
          )}
          {props.setAttributesNewTaxonomy && (
            <AttributeNewTaxonomyAccordionSection
              url={url}
              setLabels={props.setAttributesNewTaxonomy}
              taxonomy={props.taxonomy}
            />
          )}
          {props.setSubcategories && (
            <SubcategoryAccordionSection url={url} setLabels={props.setSubcategories}/>
          )}
          {props.setAttributes && (
            <AttributeAccordionSection url={url} setLabels={props.setAttributes}/>
          )}
          {props.setOccasions && (
            <OccasionAccordionSection url={url} setLabels={props.setOccasions}/>
          )}
          {props.setWeathers && (
            <WeatherAccordionSection url={url} setLabels={props.setWeathers}/>
          )}
          {props.setPersonas && (
            <PersonaAccordionSection url={url} setLabels={props.setPersonas}/>
          )}
          {props.setProductCategories && (
            <ProductCategoryAccordionSection url={url} setLabels={props.setProductCategories}/>
          )}
          {props.setProductCategoriesExclude && (
            <ProductCategoryExcludeAccordionSection url={url} setLabels={props.setProductCategoriesExclude}/>
          )}
          {props.setBrands && (
            <BrandAccordionSection url={url} setLabels={props.setBrands}/>
          )}
          {props.setMarketIds && (
            <MarketIdAccordionSection url={url} setLabels={props.setMarketIds}/>
          )}
          {props.onStatsClick && (
            <AccordionSection
              headerText="Stats"
              onHeaderClick={props.onStatsClick}
              options={props.stats}
              disableSelection
            />
          )}
        </div>
      </div>
      <div className={classes.buttonsContainer}>
        {props.onPasteAllClick && (
          <button className={`${classes.button} ${classes.pasteButton}`} onClick={props.onPasteAllClick}>
            Paste All
          </button>
        )}
        {props.updateGarments && props.onUpdateGarmentsClick && (
          <button className={`${classes.button} ${classes.approveButton}`} onClick={props.onUpdateGarmentsClick}>
            Update Garments
          </button>
        )}
        {props.onApproveClick && (
          <button className={`${classes.button} ${classes.approveButton}`} onClick={props.onApproveClick}>
            Approve
          </button>
        )}
        {props.onRunJobClick && (
          <button className={`${classes.button} ${classes.runJobButton}`} onClick={props.onRunJobClick}>
            Run Job
          </button>
        )}
      </div>
    </div>
  )
}
