import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import settings from '../../config/setting-values'
import ImageCarouselZoom from '../ImageCarouselZoom/ImageCarouselZoom'

const useStyles = makeStyles((props) => ({
  primaryImageContainer: {
    gridRowStart: '1',
    gridRowEnd: '4',
  },
  primaryImage: {
    paddingTop: '24px',
    width: '200px',
    display: 'block',
    margin: 'auto auto'
  },
  smallImagesRowContainerSafari: {
    display: '-webkit-flex'
  },
  smallImagesRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '85px',
    padding: '5px'
  },
  smallImageContainer: props => ({
    maxHeight: '100%',
    flex: '1 1 auto'
  }),
  smallImage: {
    maxHeight: '100%',
    display: 'block',
    margin: 'auto'
  }
}))

export default function OutfitPreview (props) {
  const classes = useStyles(props)

  const [garmentIndex, setGarmentIndex] = useState(0)
  const [garments, setGarments] = useState(null)

  useEffect(() => {
    if (garments === null && props.queryGarment && props.queryOutfit) {
      setGarments([props.queryGarment, ...props.queryOutfit.garments])
    }
  }, [props.queryGarment, props.queryOutfit, garments])

  useEffect(() => {
    setGarmentIndex(0)
  }, [props.queryGarment])

  return (
    garments && (
      <div className={classes.primaryImageContainer}>
        <div className={classes.primaryImage}>
          <ImageCarouselZoom
            images={garments[garmentIndex].garment_images}
            imageHeight={250}
            outOfStock={false}
          />
        </div>
        <div className={`${classes.smallImagesRowContainer} ${classes.smallImagesRowContainerSafari}`}>
          {garments && (
            garments.map((g, index) => {
              let images = g.garment_images
              if (images.length === 0) return null

              return (
                <div
                  className={classes.smallImageContainer}
                  onClick={() => {setGarmentIndex(index)}}
                >
                  <img
                    className={classes.smallImage}
                    alt="alternative garment"
                    key={g._id}
                    src={images[0].s3_url_resized || settings.s3url.concat('resized/' +
                      images[0].server_image_filename.split('/').slice(-1)[0]
                    )}
                  />
                </div>
              )
            })
          )}
        </div>
      </div>
    )
  )
}
