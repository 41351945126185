import React, { useEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch, Redirect } from 'react-router-dom'

// core components
import Admin from './layouts/Admin.jsx'
import LoginPage from './layouts/Login.jsx'
import './assets/css/material-dashboard-react.css?v=1.7.0'
import NotFound from './views/NotFound.jsx'
import useToken from './services/useToken'
import api from './api/api'

const hist = createBrowserHistory()

export default function App(props) {
  const { token, setToken } = useToken()

  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [isFetchingUser, setIsFetchingUser] = useState(true)

  // Fetch latest user data
  useEffect(() => {
    (async () => {
      try {
        const user = await api.whoami()
        localStorage.setItem('user', JSON.stringify(user.data))
        setIsAuthenticated(true)
      } catch (e) {
        setIsAuthenticated(false)
      } finally {
        setIsFetchingUser(false)
      }
    })()
  }, [token])

  if (isAuthenticated === false && window.location.href.split('/').indexOf('login') === -1) {
    return <Redirect to='/login' />
  }

  if (isFetchingUser) {
    return <div></div>
  }

  return (
    <Router history={hist}>
      <Switch>
        <Route path='/login'>
          <LoginPage setToken={setToken} isAuthenticated={isAuthenticated} />
        </Route>

        <Route path='/admin' component={Admin} setIsAuthenticated={setIsAuthenticated}/>
        <Route path='/notFound'>
          <NotFound />
        </Route>

        <Redirect from='/' to='/login' />
      </Switch>
    </Router>
  )
}
