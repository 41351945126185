import React, { useState, useRef, useEffect, useContext } from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import back from '../../assets/img/arrow-back.png'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import Tick from '../../assets/img/tick-red.png'
import Bgremover from '../../assets/img/bg-remover.jpg'
import { ReactComponent as Undo } from '../../assets/img/undo.svg'
import { ReactComponent as Erase } from '../../assets/img/erase.svg'
import { ReactComponent as Brush } from '../../assets/img/brush.svg'
import { ReactComponent as Zoomout } from '../../assets/img/zoomout.svg'
import { ReactComponent as Zoomin } from '../../assets/img/zoomin.svg'
import convert from 'color-convert'
import UPNG from 'upng-js'
import { useHistory } from 'react-router-dom'
import api from 'api/api'
import CustomCursor from '../../components/CustomCursor/CustomCursor'
import { MouseContext } from '../../context/mouse-context'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from 'components/Spinner/Spinner'
// import CursorPreview from "components/CustomCursor/CursorPreview";

const useStyles = makeStyles(() => ({
  filterBg: {
    position: 'fixed',
    top: '0px',
    left: '81px',
    backgroundColor: 'white',
    height: '100vh',
    maxWidth: '235px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    zIndex: '1200',
    '@media only screen and (max-width: 960px)': {
      left: '0px',
    },
  },
  filterSelect: {
    maxWidth: '198px',
    width: '100%',
  },
  headingBack: {
    marginBottom: '32px',
    cursor: 'pointer',
  },
  containerBg: {
    marginLeft: '208px',
    padding: '0 0 0 25px',
    '@media only screen and (max-width: 960px)': {
      marginLeft: '0px',
      padding: '0 ',
    },
  },
  headingFlex: {
    display: 'flex',
    marginBottom: '0px',
    alignItems: 'center',
    gap: '6px',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  customButton: {
    borderRadius: '24px',
    background: '#E8004C',
    display: 'flex',
    height: '38px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    border: 'none',
    fontSize: '17px',
    color: 'white',
    textTransform: 'uppercase',
    '&:hover .customButton': {
      background: '#555',
    },
  },
  disable: {
    background: 'grey',
  },
  customRadio: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '10px',
    flexDirection: 'column',
  },
  customRadioImage: {
    width: '60px',
    height: '60px',
    border: '2px solid #6236FF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
  },
  selectedRadioImage: {
    backgroundColor: '#6236FF',
  },
  customBrush: {
    '& svg': {
      fill: '#6236FF',
    },
  },
  selectedBrush: {
    '& svg': {
      fill: '#ffffff',
    },
  },

  customErase: {
    '& svg path': {
      stroke: '#6236FF',
    },
  },
  selectedErase: {
    '& svg': {
      fill: '#6236FF',
    },
  },

  customRadioLabel: {
    marginLeft: '10px',
  },
  radioInput: {
    display: 'none',
  },
  brushContent: {
    display: 'flex',
    gap: '10px',
    marginTop: '16px',
  },
  sliderTrack: {
    backgroundColor: 'red',
    height: '8px',
    borderRadius: '4px',
  },
  sliderRail: {
    height: '8px',
    borderRadius: '4px',
    backgroundColor: 'lightgray',
  },
  sliderThumb: {
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    border: '3px solid white',
  },
  customSlider: {
    height: '10px',
    borderRadius: '5px',
  },
  brushHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '26px',
  },
  customCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#333',
    gap: '8px',
    width: '100%',
    height: '100%',
    marginBottom: '0',
  },
  customCheckboxHover: {
    '&:hover .customCheckboxCheckmark': {
      backgroundColor: '#555',
    },
  },
  customCheckboxInput: {
    display: 'none',
  },
  customCheckboxCheckmark: {
    display: 'inline-block',
    width: '24px',
    height: '24px',
    borderRadius: '8px',
    verticalAlign: 'middle',
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid #E8004C',
  },
  customCheckboxChecked: {
    '& + .customCheckboxCheckmark': {
      backgroundColor: '#007bff',
      borderColor: '#007bff',
    },
  },
  checkmarkIcon: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Tick})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'none',
  },
  iconVisible: {
    display: 'block',
  },
  bgremoverHeader: {
    background: 'white',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '50px',
    position: 'relative',
    '@media only screen and (max-width: 960px)': {
      gap: '4px',
    },
  },
  bgremoverBack: {
    border: '2px solid #6236FF',
    borderRadius: '10px',
    padding: '7px 10px',
    fontSize: '16px',
    color: '#6236FF',
    fontWeight: 400,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      '@media only screen and (max-width: 960px)': {
        width: '15px',
        height: '15px',
      },
    },
    '&:hover': {
      backgroundColor: '#fafafa',
      border: '2px solid red',
    },
    '@media only screen and (max-width: 960px)': {
      padding: '7px',
      fontSize: '12px',
    },
  },
  bgremoverPage: {
    color: '#777777',
    fontSize: '20px',
    '@media only screen and (max-width: 960px)': {
      fontSize: '17px',
    },
  },
  bgremoverNext: {
    border: '2px solid #6236FF',
    borderRadius: '10px',
    padding: '7px 10px',
    fontSize: '16px',
    color: '#6236FF',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    cursor: 'pointer',
    '@media only screen and (max-width: 960px)': {
      padding: '7px',
      fontSize: '12px',
    },
    '& img': {
      transform: 'rotate(180deg)',
      '@media only screen and (max-width: 960px)': {
        width: '15px',
        height: '15px',
      },
    },
    '&:hover': {
      backgroundColor: '#fafafa',
      border: '2px solid red',
    },
  },
  bgremoverContent: {
    background: 'rgba(238, 238, 238, 0.93)',
    padding: '20px',
  },
  bgremoverContentImg: {
    textAlign: 'center',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  bgremoverFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px',
    alignItems: 'center',
    '@media only screen and (max-width: 960px)': {
      flexDirection: 'column',
      gap: '20px',
    },
  },
  footerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  footerLeftinner: {
    display: 'flex',
    padding: '10px 16px',
    gap: '8px',
    backgroundColor: 'white',
    borderRadius: '20px',
    fontSize: '17px',
    cursor: 'pointer',
    alignItems: 'flex-start',
  },
  footerRightinner: {
    display: 'flex',
    padding: '10px 16px',
    gap: '8px',
    backgroundColor: 'white',
    borderRadius: '20px',
    fontSize: '17px',
    alignItems: 'flex-start',
    color: '#6236FF',
    cursor: 'pointer',
    '& svg': {
      transform: 'scaleX(-1)',
    },
    '& svg path': {
      stroke: '#6236FF',
    },
  },
  footerRight: {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '40px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  bgBack: {
    left: '10px',
    border: 'none',
    position: 'absolute',
    backgroundColor: 'transparent',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  marginTop: {
    marginTop: '24px',
  },
  brushHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
    '& label': {
      display: 'flex',
      padding: '4px 16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      borderRadius: '4px',
      border: '1px solid #B5B5B5',
    },
  },
  canvasContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  maskCanvas: {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  gradientIndicator: {
    height: '10px',
    background: 'linear-gradient(to right, #FF0000, #FFFF00, #00FF00, #00FFFF, #0000FF, #FF00FF, #FF0000)',
  },
}))

const Editor = ({ toggleNav, screenShrink, hideSideBar }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  if (!history.location.state?.checkedImg) {
    // history.push("/admin/bg-remover");
    window.location.href = '/admin/bg-remover'
  }

  const { cursorSizeHandler } = useContext(MouseContext)

  const checkedImg = history.location.state?.checkedImg
  const selectCategory = history.location.state?.selectCategory
  const searchQuery = history.location.state?.searchQuery
  const selectedRetailerName = history.location.state?.selectedRetailerName
  const checkboxStates = history.location.state?.checkboxStates
  const currentPage = history.location.state?.currentPage

  //photo editor
  const mainCanvasRef = useRef(null)
  const maskCanvasRef = useRef(null)

  const [brushSize, setBrushSize] = useState(40)
  const [canvasOpacity, setCanvasOpacity] = useState(0.65)
  const [maskHue, setMaskHue] = useState(128)
  const [brushType, setBrushType] = useState('eraser')
  const [disableZoom, setDisableZoom] = useState(false)
  const [showCursor, setShowCursor] = useState(false)
  const [showCursorPreview, setShowCursorPreview] = useState(false)
  const [bgImage, setBgImage] = useState(checkedImg[0])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [imageSize, setImageSize] = useState({
    width: '800',
    height: '1000',
  })

  const maskSaturation = 128
  const maskValue = 128

  useEffect(() => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    let isPress = false
    let old = null
    const maskCanvas = maskCanvasRef.current
    const maskCtx = maskCanvas.getContext('2d')

    // Step 1: Fetch existing image data from the canvas
    const imageData = maskCtx.getImageData(0, 0, maskCanvas.width, maskCanvas.height)
    const rgbaData = imageData.data

    // Step 2: Modify RGB values based on new hue
    const [r, g, b] = convert.hsv.rgb([maskHue, maskSaturation, maskValue])

    for (let i = 0; i < rgbaData.length; i += 4) {
      rgbaData[i] = r
      rgbaData[i + 1] = g
      rgbaData[i + 2] = b
      // Note: rgbaData[i + 3] remains unchanged to preserve the alpha channel
    }

    // Step 3: Put modified data back on canvas
    maskCtx.putImageData(imageData, 0, 0)

    function handleMouseDown(e) {
      isPress = true
      old = { x: e.offsetX, y: e.offsetY }
    }

    function handleMouseMove(e) {
      if (isPress) {
        var x = e.offsetX
        var y = e.offsetY

        if (brushType === 'paint') {
          maskCtx.globalCompositeOperation = 'source-over'
        } else if (brushType === 'eraser') {
          maskCtx.globalCompositeOperation = 'destination-out'
        }
        if (brushType) {
          maskCtx.beginPath()

          const [r, g, b] = convert.hsv.rgb([maskHue, maskSaturation, maskValue])
          maskCtx.strokeStyle = `rgba(${r}, ${g}, ${b}, 1)`
          maskCtx.lineWidth = brushSize
          maskCtx.lineJoin = 'round'
          maskCtx.moveTo(old.x, old.y)
          maskCtx.lineTo(x, y)
          maskCtx.closePath()
          maskCtx.stroke()
          old = { x: x, y: y }
        }
      }
    }

    function handleMouseUp() {
      isPress = false
    }

    maskCanvas.addEventListener('mousedown', handleMouseDown)
    maskCanvas.addEventListener('mousemove', handleMouseMove)
    maskCanvas.addEventListener('mouseup', handleMouseUp)
    return () => {
      // Clean up event listeners on unmount
      maskCanvas.removeEventListener('mousedown', handleMouseDown)
      maskCanvas.removeEventListener('mousemove', handleMouseMove)
      maskCanvas.removeEventListener('mouseup', handleMouseUp)
    }
  }, [brushType, brushSize, maskHue])

  const MAX_WIDTH = 1000
  const MAX_HEIGHT = 1000

  const calculateResolution = (w, h) => {
    let width = w
    let height = h

    if (width > height) {
      if (width > MAX_WIDTH) {
        height = height * (MAX_WIDTH / width)
        width = MAX_WIDTH
      }
    } else {
      if (height > MAX_HEIGHT) {
        width = width * (MAX_HEIGHT / height)
        height = MAX_HEIGHT
      }
    }
    return { width: width, height: height }
  }

  useEffect(() => {
    const mainCanvas = mainCanvasRef.current
    const mainCtx = mainCanvas.getContext('2d')
    const maskCanvas = maskCanvasRef.current
    const maskCtx = maskCanvas.getContext('2d')

    // mainCtx.clearRect(0, 0, mainCtx.width, mainCtx.height);
    const img = new Image()
    img.src =
      // bgImage.garment_images[0].s3_url_removed_bg ||
      bgImage.garment_images[0].s3_url
    img.onload = function() {
      mainCtx.reset(true)
      maskCtx.reset(true)
      // mainCtx.clearRect(0, 0, mainCtx.width, mainCtx.height);
      // maskCtx.clearRect(0, 0, maskCtx.width, maskCtx.height);

      const scaledRes = calculateResolution(
        img.naturalWidth,
        img.naturalHeight
      )
      setImageSize({
        width: scaledRes.width,
        height: scaledRes.height,
      })

      mainCtx.width = scaledRes.width
      mainCtx.height = scaledRes.height
      mainCtx.drawImage(img, 0, 0, scaledRes.width, scaledRes.height)

      if (bgImage.garment_images[0].s3_url_background_mask) {
        const img2 = new Image()
        img2.onload = function() {
          const scaledRes = calculateResolution(
            img2.naturalWidth,
            img2.naturalHeight
          )
          

          // maskCtx.fillStyle = "green";
          // maskCtx.fillRect(0, 0, scaledRes.width, scaledRes.height);
          // maskCtx.globalCompositeOperation = "destination-out";

          maskCtx.width = scaledRes.width
          maskCtx.height = scaledRes.height
          maskCtx.drawImage(img2, 0, 0, scaledRes.width, scaledRes.height)

          // Extract greyscale mask data
          const greyscaleData = maskCtx.getImageData(0, 0, img.width, img.height).data

          // Create a new empty RGBA array
          const rgbaData = new Uint8ClampedArray(img.width * img.height * 4)

          const [r, g, b] = convert.hsv.rgb([maskHue, maskSaturation, maskValue])
          const color = {r, g, b}

          for (let i = 0, j = 0; i < greyscaleData.length; i += 4, j += 4) {
            // Set RGB color channels
            rgbaData[j] = color.r
            rgbaData[j + 1] = color.g
            rgbaData[j + 2] = color.b

            // Set Alpha channel from greyscale image
            rgbaData[j + 3] = greyscaleData[i]
          }

          // Create new ImageData and draw it to the canvas
          const imageData = new ImageData(rgbaData, img.width, img.height)
          maskCtx.putImageData(imageData, 0, 0)
        }
        const time = new Date().getTime()

        img2.crossOrigin = 'anonymous'
        img2.alt = 'mask img'
        img2.src = bgImage.garment_images[0].s3_url_background_mask + '?t=' + time
      } else {
        const [r, g, b] = convert.hsv.rgb([maskHue, maskSaturation, maskValue])
        maskCtx.fillStyle = `rgba(${r}, ${g}, ${b}, 1)`
        maskCtx.fillRect(0, 0, scaledRes.width, scaledRes.height)
      }
    }
  }, [bgImage])

  // const clearCanvas = () => {
  //   const mainCanvas = mainCanvasRef.current;
  //   const maskCanvas = maskCanvasRef.current;
  //   const mainCtx = mainCanvas.getContext("2d");
  //   const maskCtx = maskCanvas.getContext("2d");

  //   // mainCtx.clearRect(0, 0, mainCtx.width, mainCtx.height);
  //   // maskCtx.clearRect(0, 0, imageSize.width, imageSize.height);
  // };

  const handleRadioChange = (event) => {
    setBrushType(event.target.value)
    setCanvasOpacity(0.65)
    setIsChecked(false)
  }

  const [isChecked, setIsChecked] = useState(false)
  const handleCheckboxChange = () => {
    setBrushType(null)
    setIsChecked((prev) => {
      return !prev
    })
    setCanvasOpacity((prev) => {
      return prev === 0 ? 0.65 : 0
    })
  }

  const applyChanges = () => {
    setIsLoading(true)
    const maskCanvas = maskCanvasRef.current
    maskCanvas.toBlob(function(blob) {
      // var url = URL.createObjectURL(blob);
      // var a = document.createElement("a");
      // a.href = url;
      // a.download = "masked-image.png";
      // a.click();

      const fileName = bgImage.garment_images[0].server_image_filename
      let data = new FormData()
      data.append('mask', blob, fileName)
      api
        .saveMaskImage(data, bgImage._id, fileName.replace(/\.jpg$/, ''))
        .then(() => {
          toast.success('Mask saved successfully!')
          setIsLoading(false)
        })
        .catch(() => {
          toast.error('An error occured')
        })
    })
  }

  return (
    <div>
      {!screenShrink || hideSideBar ? (
        <div className={`${classes.filterBg}`}>
          <div className={`${classes.filterHeading}`}>
            <ToastContainer />
            {showCursor ? <CustomCursor /> : ''}
            {/* {showCursorPreview ? <CursorPreview /> : ""} */}

            {isLoading ? <Spinner /> : ''}
            <div
              onClick={() => {
                history.push('/admin/bg-remover', {
                  selectCategory,
                  searchQuery,
                  selectedRetailerName,
                  checkboxStates,
                  currentPage,
                })
                cursorSizeHandler({
                  height: '40px',
                  width: '40px',
                })
              }}
              className={classes.headingBack}
            >
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                <img src={back} /> Back
              </Typography>
            </div>
            <div className={`${classes.filterHeading}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {' '}
                Select a Brush
              </Typography>

              <div className={`${classes.brushContent}`}>
                <label className={classes.customRadio}>
                  <input
                    type="radio"
                    className={classes.radioInput}
                    value="paint"
                    checked={brushType === 'paint'}
                    onChange={handleRadioChange}
                  />
                  <div
                    className={`${classes.customRadioImage} ${
                      brushType === 'paint' ? classes.selectedRadioImage : ''
                    }`}
                  >
                    <span
                      className={` ${
                        brushType === 'paint'
                          ? classes.selectedBrush
                          : classes.customBrush
                      }`}
                    >
                      <Brush />
                    </span>
                  </div>
                  <span className={classes.customRadioLabel}>Restore</span>
                </label>

                <label className={classes.customRadio}>
                  <input
                    type="radio"
                    className={classes.radioInput}
                    value="eraser"
                    checked={brushType === 'eraser'}
                    onChange={handleRadioChange}
                  />
                  <div
                    className={`${classes.customRadioImage} ${
                      brushType === 'eraser' ? classes.selectedRadioImage : ''
                    }`}
                  >
                    <span
                      className={` ${
                        brushType === 'eraser'
                          ? classes.selectedErase
                          : classes.customErase
                      }`}
                    >
                      {' '}
                      <Erase />
                    </span>
                  </div>
                  <span className={classes.customRadioLabel}>Erase</span>
                </label>
              </div>
            </div>
            <div>
              <div className={`${classes.brushHeading}`}>
                <Typography
                  className={classes.headingFlex}
                  variant="body1"
                  component="h2"
                >
                  {' '}
                  Brush size
                </Typography>

                <label className={classes.input} aria-labelledby="input-slider">
                  {brushSize}
                </label>
              </div>
              <div
              // onMouseDown={() => setShowCursorPreview(true)}
              // onMouseUp={() => setShowCursorPreview(false)}
              // onBlur={() => setShowCursorPreview(false)}
              >
                <Slider
                  onChange={(e, v) => {
                    setBrushSize(v)
                    cursorSizeHandler({
                      height: v,
                      width: v,
                    })
                  }}
                  value={typeof brushSize === 'number' ? brushSize : 0}
                  // onChange={(a, event) => setBrushSize(event)}
                  // min="2"
                  // max="20"
                  max={200}
                  aria-labelledby="input-slider"
                  classes={{
                    rail: classes.sliderRail,
                    root: classes.customSlider,
                    track: classes.sliderTrack,
                    thumb: classes.sliderThumb,
                  }}
                />
              </div>

              <div>
                <div className={`${classes.brushHeading}`}>
                  <Typography
                    className={classes.headingFlex}
                    variant="body1"
                    component="h2"
                  >
                    {' '}
                    Mask opacity
                  </Typography>

                  <label
                    className={classes.input}
                    aria-labelledby="input-slider"
                  >
                    {Math.round(canvasOpacity * 10)}
                  </label>
                </div>
                <div>
                  <Slider
                    onChange={(e, v) => setCanvasOpacity(v)}
                    value={
                      typeof canvasOpacity === 'number' ? canvasOpacity : 0
                    }
                    min={0.0}
                    max={1.0}
                    step={0.01}
                    aria-labelledby="input-slider"
                    classes={{
                      rail: classes.sliderRail,
                      root: classes.customSlider,
                      track: classes.sliderTrack,
                      thumb: classes.sliderThumb,
                    }}
                  />
                </div>
              </div>
              <div>
                <div className={`${classes.brushHeading}`}>
                  <Typography
                    className={classes.headingFlex}
                    variant="body1"
                    component="h2"
                  >
                    Mask Hue
                  </Typography>
                  <label className={classes.input} aria-labelledby="input-slider">
                    {Math.round(maskHue)}
                  </label>
                </div>
                <div>
                  <Slider
                    onChange={(e, v) => setMaskHue(v)}
                    value={typeof maskHue === 'number' ? maskHue : 0}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="input-slider"
                    classes={{
                      rail: classes.sliderRail,
                      root: classes.customSlider,
                      track: classes.sliderTrack,
                      thumb: classes.sliderThumb,
                    }}
                  />
                  {/* Color indicator */}
                  <div className={classes.gradientIndicator} />
                </div>
              </div>
              <div className={`${classes.headingFlex} ${classes.marginTop}`}>
                <div className={`${classes.checkFlex}`}>
                  <label
                    className={`${classes.customCheckboxLabel} ${classes.customCheckboxHover} ${classes.customGallery}`}
                  >
                    <input
                      type="checkbox"
                      className={classes.customCheckboxInput}
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <span
                      className={`${classes.customCheckboxCheckmark} ${
                        isChecked ? classes.customCheckboxChecked : ''
                      }`}
                    >
                      <span
                        className={`${classes.checkmarkIcon} ${
                          isChecked ? classes.iconVisible : ''
                        }`}
                      ></span>
                    </span>
                  </label>
                </div>
                Show original image
              </div>
            </div>
          </div>
          <div className={`${classes.filterFooter}`}>
            <button
              onClick={applyChanges}
              className={`${classes.customButton}  ${
                isLoading ? classes.disable : ''
              }  `}
              disabled={isLoading ? true : false}
            >
              Save
            </button>
          </div>
        </div>
      ) : null}
      <div className={`${classes.containerBg}`}>
        <div className={`${classes.bgremoverHeader}`}>
          <button
            className={`${classes.bgBack}`}
            style={{ visibility: screenShrink ? '' : 'hidden' }}
            onClick={toggleNav}
          >
            Filter
          </button>
          <span
            className={`${classes.bgremoverBack}`}
            onClick={() => {
              if (currentImageIndex > 0) {
                setCurrentImageIndex((prev) => {
                  setBgImage(checkedImg[prev - 1])
                  return prev - 1
                })
              }
            }}
          >
            {' '}
            <img src={back} /> Back{' '}
          </span>
          <span className={`${classes.bgremoverPage}`}>
            {' '}
            {currentImageIndex + 1} / {checkedImg.length}{' '}
          </span>
          <span
            className={`${classes.bgremoverNext}`}
            onClick={() => {
              if (currentImageIndex + 1 < checkedImg.length) {
                setCurrentImageIndex((prev) => {
                  setBgImage(checkedImg[prev + 1])
                  return prev + 1
                })
              }
            }}
          >
            {' '}
            Next <img src={back} />
          </span>
        </div>
        <div className={`${classes.bgremoverContent}`}>
          {/* <img src={bgImage.garment_images[0].s3_url_removed_bg} /> */}
          <div
            // style={{width: "100px", height: "100px"}}
            className={`${classes.bgremoverContentImg} ${classes.canvasContainer}`}
            onMouseEnter={() => setShowCursor(true)}
            onMouseLeave={() => setShowCursor(false)}
            style={{ cursor: 'none' }}
          >
            <canvas
              ref={mainCanvasRef}
              width={imageSize.width}
              height={imageSize.height}
            ></canvas>
            <canvas
              ref={maskCanvasRef}
              style={{ opacity: canvasOpacity }}
              className={classes.maskCanvas}
              width={imageSize.width}
              height={imageSize.height}
            ></canvas>
          </div>

          {/* <div className={`${classes.bgremoverFooter}`}>
            <div className={`${classes.footerLeft}`}>
              <div className={`${classes.footerLeftinner}`}>
                Undo
                <Undo />
              </div>
              <div className={`${classes.footerRightinner}`}>
                <Undo /> Redo
              </div>
            </div>
            <div className={`${classes.footerRight}`}>
              <Zoomout /> 100% <Zoomin />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Editor
