export let priceRules = {
  '[1, 2, 3]': [{
    'outfit_item': [11, 12, 13, 17, 18, 31],
    'min_price': 2.5,
    'max_price': 5
  },{
    'outfit_item': [4, 5, 6, 7, 8, 9, 10],
    'min_price': 1.5,
    'max_price': 2.5
  },{
    'outfit_item': [14, 15, 16],
    'min_price': 2,
    'max_price': 5
  },{
    'outfit_item': [1, 2, 3],
    'min_price': 0.9,
    'max_price': 1.1
  }
  ],
  '[14, 15, 16]': [{
    'outfit_item': [1, 2, 3],
    'min_price': 1/3,
    'max_price': 0.5
  },
  {
    'outfit_item': [4, 5, 6, 7, 8, 9, 10],
    'min_price': 0.5,
    'max_price': 1
  },
  {
    'outfit_item': [11, 12, 13, 17, 18, 31],
    'min_price': 1.5,
    'max_price': 2.5
  },
  {
    'outfit_item': [14, 15, 16],
    'min_price': 0.9,
    'max_price': 1.1
  }
  ],
  '[11, 12, 13, 17, 18, 31]': [{
    'outfit_item': [1, 2, 3],
    'min_price': 1/3,
    'max_price': 0.5
  },
  {
    'outfit_item': [14, 15, 16],
    'min_price': 1,
    'max_price': 1.5
  },
  {
    'outfit_item': [4, 5, 6, 7, 8, 9, 10],
    'min_price': 0.5,
    'max_price': 1
  },
  {
    'outfit_item': [11, 12, 13, 17, 18, 31],
    'min_price': 0.9,
    'max_price': 1.1
  }
  ],
  '[4, 5, 6, 7, 8, 9, 10]': [{
    'outfit_item': [1, 2, 3],
    'min_price': 0.5,
    'max_price': 1
  },
  {
    'outfit_item': [14, 15, 16],
    'min_price': 1.5,
    'max_price': 2.5
  },
  {
    'outfit_item': [11, 12, 13, 17, 18, 31],
    'min_price': 2,
    'max_price': 3
  },
  {
    'outfit_item': [4, 5, 6, 7, 8, 9, 10],
    'min_price': 0.9,
    'max_price': 1.1
  }
  ]
}
