import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function CategoryNewTaxonomyAccordionSection(props) {
  const getLabels = async () => {
    let newLabels = await api.getTaxonomyLabels({
      url: props.url,
      params: {
        restrictions: {
          taxonomy: props.taxonomy,
          label_type: 'category'
        },
        projection: {
          clip_descriptions: 0,
          performance_data: 0
        },
        populate_children: false,
        populate_parents: false
      }
    })

    // sort new labels by name
    newLabels = newLabels.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    try {
      newLabels.forEach(label => {
        label.active = false
      })
      return newLabels
    } catch {
      console.error('Failed to get categories')
      return []
    }


  }

  return (
    <AbstractAccordionSection
      title={'Category'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}