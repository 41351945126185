import React, { useCallback, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import debounce from 'lodash/debounce'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const useStyles = makeStyles(() => ({
  paginationContainer: {
    width: '100px',
    marginLeft: 'auto',
    marginTop: '86px',
    marginBottom: '25px',
    marginRight: '6%',
  },
  pagination: {
    height: '30px',
    margin: 'auto 10px !important',
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    width: '30px',
    border: '1px solid #ECEEF5',
    cursor: 'pointer',
    height: '30px',
    margin: 'auto',
    minHeight: '30px',
    borderRadius: '2px',
    backgroundColor: '#f7f7f7',
  },
  label: {
    margin: 'auto 10px',
    display: 'flex',
    flexDirection: 'row',
  },
  label1: {
    color: '#000000',
    margin: 'auto 3px',
    opacity: '0.5',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  label2: {
    color: '#86A8E0',
    margin: 'auto 3px',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  button2: {
    width: '30px',
    border: '1px solid #ECEEF5',
    cursor: 'pointer',
    height: '30px',
    margin: 'auto',
    minHeight: '30px',
    borderRadius: '2px',
    backgroundColor: '#f7f7f7',
  },
  inputBox: {
    width: '40px',
    height: '30px',
    margin: '0',
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Montserrat',
  },
  leftArrow: {
    height: '28px',
    width: '28px',
    margin: '1px',
    color: '#a0a0a0',
    '&:hover': {
      color: '#86A8E0',
    },
  },
  rightArrow: {
    height: '28px',
    width: '28px',
    margin: '1px',
    color: '#a0a0a0',
    '&:hover': {
      color: '#86A8E0',
    },
  },
}))

const Pagination = ({ currentPage, setCurrentPage, totalPages, submit }) => {
  const classes = useStyles()

  const changePage = (action) => {
    if (action === 'prev')
      setCurrentPage((prev) => {
        if (prev > 1) {
          submit(prev - 1)
          return prev - 1
        }
        return prev
      })
    else
      setCurrentPage((prev) => {
        if (prev < totalPages) {
          submit(prev + 1)
          return prev + 1
        }
        return prev
      })
  }

  // const debouncedSearch = useRef(
  //   debounce(async () => {
  //     submit();
  //   }, 300)
  // ).current;

  // useEffect(() => {
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [debouncedSearch]);

  // const handleInputChange = (e) => {
  //   setCurrentPage(Number(e.target.value));
  //   debouncedSearch();
  // };

  return (
    <div className={classes.paginationContainer}>
      <div className={classes.pagination}>
        <div onClick={() => changePage('prev')} className={classes.button}>
          <ArrowLeftIcon className={classes.leftArrow} />
        </div>
        <form autoComplete="off">
          <input
            type="number"
            step="1"
            className={classes.inputBox}
            // id="paginationInput"
            // name="quantity"
            min="1"
            max="0"
            onChange={(e) => {
              submit(Number(e.target.value))
              setCurrentPage(Number(e.target.value))
            }}
            value={currentPage}
          />
        </form>
        <div className={classes.label}>
          <div className={classes.label1}>of</div>
          <div className={classes.label2}>{totalPages}</div>
        </div>
        <div onClick={() => changePage('next')} className={classes.button2}>
          <ArrowRightIcon className={classes.rightArrow} />
        </div>
      </div>
    </div>
  )
}

export default Pagination
