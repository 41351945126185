import axios from 'axios'
import settings from '../config/setting-values'
const qs = require('qs')


const backendApi = (url) => {
  const axiosService = axios.create({
    withCredentials: false,
    baseURL: url || settings.endpoint
  })

  // Add auth token
  axiosService.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('authToken')
    return config
  })

  return axiosService
}

const service = {
  get: async (a, b) => {
    const api = b ? backendApi(b.url) : backendApi()
    const params = b && b.params ? b.params : null
    return api.get(a, { params })
  },

  post: async (a, b) => {
    const api = b ? backendApi(b.url) : backendApi()
    b && delete b.url
    return api.post(a, b)
  }
}

const errHandler = err => {
  console.error(err)
  if (err.response && err.response.data) {
    if (err.response.status === 401) {
      localStorage.setItem('user', null)
      localStorage.setItem('isAuthenticated', false)
    }
    console.error('API response: ', err.response.data)
    throw err.response.data.message
  }
  throw err
}

export default {
  service: service,

  authenticate(username, password) {
    return service.post('/v4/authenticate', {username, password})
  },

  whoami() {
    return service.get('/v4/whoami')
  },

  sendCampaign(body) {
    return service.post('/v4/sendCampaign', body).catch(errHandler)
  },

  createCampaign(body) {
    return service.post('/v4/createCampaign', body)
  },

  updateCampaign(body) {
    return service.post('/v4/updateCampaign', body).catch(errHandler)
  },

  deleteCampaign(body) {
    return service.post('/v4/deleteCampaign', body).catch(errHandler)
  },

  getAllCampaigns(body) {
    return service.post('/v4/getAllCampaigns', body).catch(errHandler)
  },

  addUser(body) {
    return service.post('/v4/addUser', body).catch(errHandler)
  },

  getTwilioMessages(body) {
    return service.post('/v4/getTwilioMessages', body).catch(errHandler)
  },

  getTwilioNumbers(body) {
    return service.post('/v4/getTwilioNumbers', body).catch(errHandler)
  },

  updateReservationStatus(body) {
    return service.post('/reservation/updateReservationStatus', body).catch(errHandler)
  },

  deleteReservation(body) {
    return service.post('/reservation/deleteReservationByProductId', body).catch(errHandler)
  },

  getChatbotTags(body) {
    return service.post('/v4/getChatbotTags', body).catch(errHandler)
  },

  chatbotUpdateTags(body) {
    return service.post('/v4/updateTags', body).catch(errHandler)
  },

  findAllReservationsByUser(body) {
    return service.post('/reservation/findAllByUser', body).catch(errHandler)
  },

  getPhoneNumbers(body) {
    return service.post('/v4/getPhoneNumbers', body).catch(errHandler)
  },

  getProductIdsInOutfits(body) {
    return service.post('/v4/getProductIdsInOutfits', body).catch(errHandler)
  },

  getOutfits(body) {
    return service.post('/v4/getOutfits', body).catch(errHandler)
  },

  listOutfits(body) {
    return service.post('/v4/outfits/list', body).then(res => res.data).catch(errHandler)
  },

  outfitsCount(body) {
    return service.post('/v4/outfits/count', body).then(res => res.data).catch(errHandler)
  },

  outfitsSetInEditor(body) {
    return service.post('/v4/outfits/update/in_editor', body).catch(errHandler)
  },

  outfitsApprove(body) {
    return service.post('/v4/outfits/approve', body).catch(errHandler)
  },

  outfitapprovaleventsCount(body) {
    return service.post('/v4/outfitapprovalevents/count', body).then(res => res.data).catch(errHandler)
  },

  getSimilars(body) {
    return service.post('/v4/getSimilars', body).catch(errHandler)
  },

  searchGarments(body) {
    return service.post('/v4/searchGarments', body).catch(errHandler)
  },

  approveOutfits(body) {
    return service.post('/v4/approveOutfits', body).catch(errHandler)
  },

  getOutfitVettingCount(body) {
    return service.post('/v4/getOutfitVettingCount', body).catch(errHandler)
  },

  getBrandGroups(body) {
    return service.get('/v4/getBrandGroups', body).catch(errHandler)
  },

  getUnapprovedOutfits(body) {
    return service.post('/v4/getUnapprovedOutfits', body).catch(errHandler)
  },

  getCategoryVettingCount(approved, category_ids_restriction) {
    const data = {
      approved: approved,
      category_ids_restriction: category_ids_restriction
    }

    return service
      .post('/v4/getCategoryVettingCount', data)
      .then(response => response.data.garments)
      .catch(errHandler)
  },

  setGarmentInEditor(_id, in_editor, model, body) {
    const data = {
      _id: _id,
      in_editor: in_editor,
      model: model,
      url: body.url
    }

    return service
      .post('/v4/setInEditor', data)
      .then()
      .catch(errHandler)
  },

  getGarmentsForCategoryApproval(
    approved,
    user_ids_restriction,
    category_ids_restriction,
    gender,
    limit
  ) {
    // Retrieve garments for category approval based on settings
    const data = {
      approved: approved,
      user_ids_restriction: user_ids_restriction,
      category_ids_restriction: category_ids_restriction,
      gender: gender,
      metadata: {
        limit: limit,
        offset: 0
      }
    }

    return service
      .post('/v4/getGarmentsForCategoryApproval', data)
      .then(response => response.data.garments)
      .catch(errHandler)
  },

  getCategories(body) {
    return service
      .get('/v4/getAllCategories', body)
      .then(response => {
        const categories = response.data
        // Update category objects with active attribute
        categories.filter(c => !!c).forEach(category => (category.active = true))

        return categories
      })
      .catch(errHandler)
  },

  getAllBoundingBoxLocations(body) {
    return service
      .get('/v4/getAllBoundingBoxLocations', body)
      .then(response => {
        const boundingBoxLocations = response.data
        // Update boundingBoxLocation objects with active attribute
        boundingBoxLocations.forEach(boundingBoxLocation => (boundingBoxLocation.active = true))

        return boundingBoxLocations
      })
      .catch(errHandler)
  },

  getUsers(body) {
    return service
      .get('/v4/getAllBrands', body)
      .then(response => {
        const users = response.data
        return users
      })
      .catch(errHandler)
  },

  getAllBrandsForUser(body) {
    return service
      .post('/v4/getAllBrandsForUser', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  submitValuesForProductRanking(body) {
    return service
      .post('/v4/updateProductRanking', body)
      .then(response => response)
      .catch(errHandler)
  },

  getAttributes(body) {
    return service
      .get('/v4/getAllAttributes', body)
      .then(response => response)
      .catch(errHandler)
  },

  getAttributeCategories(body) {
    return service
      .get('/v4/getAllAttributeCategories', body)
      .then(response => response)
      .catch(errHandler)
  },

  getAttributeCategoryAttributeMap(body) {
    return service
      .get('/v4/getAttributeCategoryAttributeMap', body)
      .then(response => response)
      .catch(errHandler)
  },

  getAllSubcategories(body) {
    return service
      .get('/v4/getAllSubcategories', body)
      .then(response => response)
      .catch(errHandler)
  },

  retrieveAnalytics(body) {
    return service
      .post('/v4/getAnalyticsFormatted', body)
      .then(response => response)
      .catch(errHandler)
  },

  saveChatbotProductIds(body) {
    return service
      .post('/v4/saveChatbotProductIds', body)
      .then(response => response)
      .catch(errHandler)
  },

  getChatbotProducts(body) {
    return service
      .post('/v4/getChatbotProducts', body)
      .then(response => response)
      .catch(errHandler)
  },

  getChatbotProductImages(body) {
    return service
      .post('/v4/getChatbotProductImages', body)
      .then(response => response)
      .catch(errHandler)
  },

  twilioInitiateFlow(body) {
    return service
      .post('/v4/twilioInitiateFlow', body)
      .then(response => response)
      .catch(errHandler)
  },

  addGarment(formData) {
    return service
      .post('/v4/addGarmentV2', formData, formData.headers)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllBodytypes(body) {
    return service
      .get('/v4/getAllBodytypes', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllOccasions(body) {
    return service
      .get('/v4/getAllOccasions', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllWeathers(body) {
    return service
      .get('/v4/getAllWeathers', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllPersonas(body) {
    return service
      .get('/v4/getAllPersonas?group=all', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllVettingUsers() {
    return service
      .get('/v4/getAllVettingUsernames')
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllStylingFilters(body) {
    return service
      .get('/v4/getAllStylingFilters', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAllAgeRanges(body) {
    return service
      .get('/v4/getAllAgeRanges', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getBoundingBoxLocationCategoryMap(body) {
    return service
      .get('/v4/getBoundingBoxLocationCategoryMap', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  listGarmentsV3(body) {
    return service
      .post('/v4/listGarmentsV3', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getAvailableCategories(body) {
    return service
      .post('/v4/getAvailableCategories', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getDescription(body) {
    return service
      .post('/v4/getDescription', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  likedOutfitEvent(body) {
    return service
      .post('/v4/likedOutfitEvent', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  approveBoundingBoxes(body) {
    return service
      .post('/v4/approveBoundingBoxes', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  updateGarments(body) {
    return service
      .post('/v4/updateGarments', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getProductCategories(body) {
    return service
      .post('/v4/getProductCategories', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getMarketIds(body) {
    return service
      .post('/v4/getMarketIds', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  saveMaskImage(body, garmentId, fileName) {
    return service
      .post(`/v4/garments/${garmentId}/images/${fileName}/background_removal/save`, body)
      .then(response => response.data)
      .catch(errHandler)
  },

  checkSagemakerEndpointStatus () {
    return service
      .post('/v4/check_endpoint_status')
      .then(response => response.data)
      .catch(errHandler)
  },

  deploySagemakerEndpoint() {
    return service
      .post('/v4/deploy_endpoint', null, { timeout: 0 })
      .then(response => response.data)
      .catch(errHandler)
  },

  deleteSagemakerEndpoint () {
    return service
      .post('/v4/delete_endpoint')
      .then(response => response.data)
      .catch(errHandler)
  },

  // For Attribute Editor

  getVettingJobs(body) {
    return service
      .get('/v4/getVettingJobs', body)
      .then(response => response.data['vetting_jobs'])
      .catch(errHandler)
  },

  getSubcategoryAttributeMap(body) {
    return service
      .get('/v4/getSubcategoryAttributeMap', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getSubcategories(body) {
    return service
      .get('/v4/getAllSubcategories', body)
      .then(response => {
        let subcategories = response.data
        // Update subcategory objects with active subcategory
        subcategories.forEach((subcategory) => {
          subcategory['active'] = false
        })

        // Set only the first subcategory as active by default
        subcategories[1].active = true

        return subcategories
      })
      .catch(errHandler)
  },

  getAttributesEditor(body) {
    return service
      .get('/v4/getAllAttributes', body)
      .then(response => {
        let attributes = response.data
        // Update attribute objects with active property
        attributes.forEach((attribute) => {
          attribute['active'] = true
        })
        return attributes.sort((a, b) => a.name.localeCompare(b.name))
      })
      .catch(errHandler)
  },

  getPersonalModelVettingStats(body) {
    return service
      .post(
        '/v4/getPersonalModelVettingStats',
        body
      )
      .then(response => response.data)
      .catch(errHandler)
  },

  countGarments(body) {
    return service
      .post(
        '/v4/countGarments',
        body
      )
      .then(response => response.data)
      .catch(errHandler)
  },

  approveAttributes(body) {
    return service
      .post(
        '/v4/approveAttributes',
        body,
      )
      .catch(errHandler)
  },

  approveSubcategories(body) {
    return service
      .post(
        '/v4/approveSubcategories',
        body,
      )
      .catch(errHandler)
  },

  approveCategories(body) {
    return service
      .post(
        '/v4/approveCategories',
        body,
      )
      .catch(errHandler)
  },

  runVettingJob(body) {
    return service
      .post(
        '/v4/runVettingJob',
        body,
      )
      .then(response => response)
      .catch(errHandler)
  },

  approveGarmentLabelCategory(garmentId, body) {
    return service
      .post(
        `/v4/garments/${garmentId}/labels/category/approve`,
        body
      )
      .then(response => response.data)
      .catch(errHandler)
  },

  approveGarmentLabelAttributes(garmentId, body) {
    return service
      .post(
        `/v4/garments/${garmentId}/labels/attribute/approve`,
        body
      )
      .then(response => response.data)
      .catch(errHandler)
  },

  getTaxonomies(body) {
    return service
      .get('/v4/taxonomies', body)
      .then(response => response.data)
      .catch(errHandler)
  },

  getTaxonomyLabels(body) {
    return service
      .get('/v4/taxonomy_labels', body)
      .then(response => response.data)
      .catch(errHandler)
  }
}
