import React from 'react'
import { defaultProps } from 'recompose'
import AttributeGarment from 'components/LabelEditor/Garment/AttributeGarment'
import { makeStyles } from '@material-ui/core/styles'
import AttributeGroup from './AttributeGroup'
import { FormControl, InputLabel, Select, MenuItem, Button, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    paddingTop: '24px',
    backgroundColor: '#EEEEEE',
    borderRadius: '16px',
  },
  garmentSection: {
    flexBasis: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  attributesSection: {
    flexBasis: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: '#EEEEEE',
    height: '140px',
  },
  button: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#E8004C',
    color: '#fff',
    borderRadius: '24px',
    textTransform: 'none',
    fontSize: '12px',
  },
  attributesContent: {
    padding: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: '#EEEEEE',
  },
  attributeDropdownContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  singleAttributeDropdownContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '16px',
    marginTop: '24px',
  },
  categoriesSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  categoriesSelector: {
    border: '1px solid #6236FF',
    borderRadius: '24px',
    paddingLeft: '10px',
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:after': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
  categoriesTitle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28.13px',
    color: '#161616',
    marginLeft: '8px',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '32px'
  },
  garmentContainer: {
    backgroundColor: '#EEEEEE',
    marginBottom: '24px',
    borderRadius: '24px',
    boxShadow: 'none',
  }
}))

const Attributes = (props) => {
  const classes = useStyles()

  const getCategoryAttributes = (categoryId, taxonomyLabels) => {
    const category = taxonomyLabels.find(label => label._id === categoryId)

    if (!category) {
      return []
    }

    const attributeGroups = category.children.filter(child => (child.label_type === 'attribute_group'))

    return attributeGroups.map(group => {
      group.children = group.children.filter(child => child.parents.includes(category._id))
      const subgroups = group.children.filter(child => (child.label_type === 'attribute_subgroup')).map(subgroup => {
        subgroup.children = subgroup.children.filter(child => child.parents.includes(category._id))
        const attributes = subgroup.children.filter(child => (child.label_type === 'attribute'))
        return {
          ...subgroup,
          attributes,
        }
      })
      const attributes = group.children.filter(child => (child.label_type === 'attribute'))

      return {
        ...group,
        subgroups,
        attributes,
      }
    })
  }

  const hasSubgroups = (group) => group.children.some(child => child.label_type === 'attribute_subgroup')

  const selectedCategoryId = props.garment.labels.category._id || ''
  let categoryAttributes = getCategoryAttributes(selectedCategoryId, props.taxonomyLabels)

  const categoryAttributesWithSubgroups = categoryAttributes.filter(hasSubgroups)
  const categoryAttributesWithoutSubgroups = categoryAttributes.filter(group => !hasSubgroups(group))


  return (
    props.garment != null && (
      <Paper className={classes.garmentContainer}>
        <div className={classes.mainContainer}>
          <div className={classes.garmentSection}>
            <AttributeGarment
              key={`${props.id}`}
              garmentIndex={1}
              garment={props.garment}
              imageHeight={400}  // Adjust height as needed
              editable={false}
              showId={true}
              showProductId={false}
              showProductCategories={true}
              showCreatedAt={true}
              showDescription={true}
              setGarmentInEditor={props.setGarmentInEditor}
            />
          </div>
          <div className={classes.attributesSection}>
            <div className={classes.topBar}>
              <div className={classes.categoriesSection}>
                <InputLabel
                  id="category-select-label"
                  className={classes.categoriesTitle}>
                  Category
                </InputLabel>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategoryId}
                    className={classes.categoriesSelector}
                    onChange={(event) => props.onCategoryChange(event.target.value)}
                  >
                    {props.taxonomyLabels
                      .map(category => (
                        <MenuItem key={category._id} value={category._id}>
                          {category.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

              </div>

              <div className={classes.buttonsContainer}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => props.onCopyClick(props.id)}
                >
                  Copy
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={() => props.onPasteClick(props.id)}
                >
                  Paste
                </Button>
              </div>
            </div>
            {
              <div className={classes.attributesContent}>
                <div className={classes.attributeDropdownContainer}>
                  {
                    categoryAttributesWithSubgroups.map((group, index) => {
                      if (group.parents.includes(selectedCategoryId)) {
                        return <AttributeGroup
                          key={`${props.id}-${index}`}
                          group={group}
                          garment={props.garment}
                          handleSelectAttribute={props.onAttributeChange}
                          isCategorySelected={props.isCategorySelected}
                        />
                      }
                    })
                  }
                </div>
                <div className={classes.singleAttributeDropdownContainer}>
                  {
                    categoryAttributesWithoutSubgroups.map((group, index) => {
                      if (group.parents.includes(selectedCategoryId)) {
                        return <AttributeGroup
                          key={`${props.id}-${index}`}
                          group={group}
                          garment={props.garment}
                          handleSelectAttribute={props.onAttributeChange}
                          isCategorySelected={props.isCategorySelected}
                        />
                      }
                    })
                  }
                </div>
              </div>
            }

          </div>
        </div>
      </Paper>
    )
  )
}

// Add default props to Attributes
const withDefaultProps = defaultProps({
  imageHeight: 400,
  height: 300,
  width: 300,
  editable: false,
  selectable: false,
  indicators: false,
})

export default withDefaultProps(Attributes)
