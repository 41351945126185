import React from 'react'

import '../../../assets/css/WearThatSearch/Occasion.scss'

const Occasion = ({ occasion_restriction, handleCheckboxChange }) => {
  const filterOptions = {
    0: 'Birthday Party F',
    1: 'Breakfast Lunch Date F',
    2: 'Capsule Wardrobe F',
    3: 'Casual Brunch F',
    4: 'Casual Office F',
    5: 'City Holiday F',
    6: 'Cold Holiday F',
    7: 'Date Night F',
    8: 'Day to Night F',
    9: 'Formal Office F',
    10: 'Glam Brunch F',
    11: 'Going Out-Out F',
    12: 'Lounge Day F',
    13: 'School Run F',
    14: 'Tropical Holiday F',
    15: 'Wedding F',
    16: 'Casual Weekend M',
    17: 'Date Night M',
    18: 'Lounge Day M',
    19: 'Summer Holiday M',
    20: 'Wedding Guest M',
    21: 'Gym/Athleisure M',
    22: 'Gym/Athleisure F'
  }

  const options = []
  for (const key of Object.keys(filterOptions)) {
    options.push(
      <label key={key} className='fancy-checkbox'>
        <input
          type='checkbox'
          onChange={e => handleCheckboxChange(e)}
          name='occasion_restriction'
          value={key}
          key={key}
          className='checkbox'
          checked={occasion_restriction.includes(key)}
        />
        <i className='fa unchecked' />
        <i className='fa fa-check checked' />
        <div className='some-label'>
          {filterOptions[key]}
        </div>
      </label>
    )
  }

  return <div className='occasion-wrapper'>{options}</div>
}

export default Occasion
