import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  ImageCarouselContainer: {
    width: '100%',
    height: '100%'
  },
  img: {
    borderRadius: '3px',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    display: 'block',
    verticalAlign: 'center'
  }
}))

export default function ImageCarousel (props) {
  const classes = useStyles()

  // TODO - turn into proper carousel rather than just displaying first image

  return (
    <div className={classes.ImageCarouselContainer}>
      <img className={classes.img} src={props.images[0]} alt='missing' />
    </div>
  )
}
