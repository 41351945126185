import React from 'react'
import fashionableClothes from '../../assets/img/shutterstock_1315659653.png'
import { withStyles } from '@material-ui/core/styles'
import Outfit from '../../components/OutfitV2/Outfit'
import GarmentNavigator from '../../components/GarmentNavigatorV2/GarmentNavigator'
import { Spinner } from 'react-bootstrap'
import api from 'api/api'
import OutfitEditorSearchBar from './OutfitEditorSearchBar'
const Mousetrap = require('mousetrap')

const OutfitEditorStyle = require('./OutfitEditorStyle')

const style = theme => (OutfitEditorStyle)

class OutfitEditorV2 extends React.Component {
  constructor (props) {
    super(props)

    const user = JSON.parse(localStorage.getItem('user'))
    let approvalLevel = 2
    let unapprovalLevel  // Value will be set once approvalLevel is finalised given user settings
    let unusedItemsOutfitApprovalLevel  // Value will be set once approvalLevel is finalised given user settings
    let maxApprovalLevelShow = 1
    let minApprovalLevelShow = 0
    let numOutfitsToShow = 10000 // Want every outfit that is available
    let productCategoryDropdownMain = false
    let searchPreselectProductCategory = false
    let enableGarmentNavigatorSettings = false
    let enableGarmentNavigatorShortcuts = false
    let enableUsedProductIds = false
    let sortBy = null  //{createdAt: -1}
    let mappingUserIds = null
    let showApprovalUser = false
    let selectionDefault = false
    if (user.access === 'stylist') {
      showApprovalUser = true
      enableGarmentNavigatorShortcuts = true
    } else if (user.access === 'trainee_stylist') {
      approvalLevel = 0
      unapprovalLevel = -2
      showApprovalUser = true
      enableGarmentNavigatorShortcuts = true
    } else if (user.access === 'client') {
      minApprovalLevelShow = 2
      maxApprovalLevelShow = 2
      enableUsedProductIds = false
    } else if (user.access === 'campaigneditor') {
      minApprovalLevelShow = 2
      maxApprovalLevelShow = 2
      approvalLevel = 3
      numOutfitsToShow = 1
    }

    // Set any settings by outfit editor settings saved for current user
    if (user.feSettings.outfitEditorSettings !== undefined) {
      // Approval level
      if (user.feSettings.outfitEditorSettings.approvalLevel !== undefined) {
        approvalLevel = user.feSettings.outfitEditorSettings.approvalLevel
      }

      // Max approval level show
      if (user.feSettings.outfitEditorSettings.maxApprovalLevelShow !== undefined) {
        maxApprovalLevelShow = user.feSettings.outfitEditorSettings.maxApprovalLevelShow
      }

      // Min approval level show
      if (user.feSettings.outfitEditorSettings.minApprovalLevelShow !== undefined) {
        minApprovalLevelShow = user.feSettings.outfitEditorSettings.minApprovalLevelShow
      }

      // Product category dropdown main
      // Product category dropdown (otherwise will display the brand group dropdown)
      if (user.feSettings.outfitEditorSettings.productCategoryDropdownMain !== undefined) {
        productCategoryDropdownMain = user.feSettings.outfitEditorSettings.productCategoryDropdownMain
      }

      // Preselect product category based on edited garment in garment navigator
      if (user.feSettings.outfitEditorSettings.searchPreselectProductCategory !== undefined) {
        searchPreselectProductCategory = user.feSettings.outfitEditorSettings.searchPreselectProductCategory
      }

      // Enable settings in garment navigator
      if (user.feSettings.outfitEditorSettings.enableGarmentNavigatorSettings !== undefined) {
        enableGarmentNavigatorSettings = user.feSettings.outfitEditorSettings.enableGarmentNavigatorSettings
      }

      // Enable shortcuts in garment navigator
      if (user.feSettings.outfitEditorSettings.enableGarmentNavigatorShortcuts !== undefined) {
        enableGarmentNavigatorShortcuts = user.feSettings.outfitEditorSettings.enableGarmentNavigatorShortcuts
      }

      // Set "sort_by" option for outfit retrieval
      if (user.feSettings.outfitEditorSettings.sortBy !== undefined) {
        sortBy = user.feSettings.outfitEditorSettings.sortBy
      }

      // Set unapprovalLevel
      if (user.feSettings.outfitEditorSettings.unapprovalLevel !== undefined) {
        unapprovalLevel = user.feSettings.outfitEditorSettings.unapprovalLevel
      } else if (unapprovalLevel === undefined) {
        unapprovalLevel = -approvalLevel
      }

      // Set unusedItemsOutfitApprovalLevel
      if (user.feSettings.outfitEditorSettings.unusedItemsOutfitApprovalLevel !== undefined) {
        unusedItemsOutfitApprovalLevel = user.feSettings.outfitEditorSettings.unusedItemsOutfitApprovalLevel
      } else {
        unusedItemsOutfitApprovalLevel = approvalLevel
      }

      // Set enableUsedProductIds - controls whether the used product ids feature is available at all
      if (user.feSettings.outfitEditorSettings.enableUsedProductIds !== undefined) {
        enableUsedProductIds = false  //user.feSettings.outfitEditorSettings.enableUsedProductIds
      }

      // Set mappingUserIds - controls whether we are mapping approved outfits to all items within the outfit.
      //                      if not null and the user id of the query garment is found in this array then mapping will
      //                      be enabled
      if (user.feSettings.outfitEditorSettings.mappingUserIds !== undefined) {
        mappingUserIds = user.feSettings.outfitEditorSettings.mappingUserIds
      }

      if (user.feSettings.outfitEditorSettings.showApprovalUser !== undefined) {
        showApprovalUser = user.feSettings.outfitEditorSettings.showApprovalUser
      }

      if (user.feSettings.outfitEditorSettings.selectionDefault !== undefined) {
        selectionDefault = user.feSettings.outfitEditorSettings.selectionDefault
      }
    }

    // Make sure variables without values are assigned a value
    if (unapprovalLevel === undefined) {
      unapprovalLevel = -approvalLevel
    }

    if (unusedItemsOutfitApprovalLevel === undefined) {
      unusedItemsOutfitApprovalLevel = approvalLevel
    }


    this.state = {
      outfits: [],
      outfitSelected: [],
      page: 1,
      activeOutfitIndex: null,  // outfit being edited in garment navigator
      activeGarmentIndex: null,  // garment edited in garment navigator
      brandGroups: [],
      allUsers: [],
      allCategories: [],
      allOutfitTypes: [
        {
          _id: 'complete_the_look',
          name: 'Complete The Look'
        },
        {
          _id: 'shop_the_look',
          name: 'Shop The Look'
        }
      ],
      approvalLevel: approvalLevel,
      unapprovalLevel: unapprovalLevel,
      unusedItemsOutfitApprovalLevel: unusedItemsOutfitApprovalLevel,
      approvalLevelShow: null,
      gender: null,
      historySize: 10,
      historyIndex: 0,
      itemsPerPageDefault: 15,
      itemsPerPageMax: 100,
      loading: false,
      maxApprovalLevelShow: maxApprovalLevelShow,
      minApprovalLevelShow: minApprovalLevelShow,
      subcategories: [],
      attributes: [],
      attributeCategories: [],
      attributeCategoryAttributeMap: null,
      showGarmentNavigator: false,
      source: null,
      outfitsHistory: [],
      usedProductIds: [],
      user_ids: null,
      url: this.props.database,
      garmentNavigatorBrandRestriction: null,
      productCategories: [],
      productCategoryDropdownMain: productCategoryDropdownMain,
      searchPreselectProductCategory: searchPreselectProductCategory,
      enableGarmentNavigatorSettings: enableGarmentNavigatorSettings,
      enableGarmentNavigatorShortcuts: enableGarmentNavigatorShortcuts,
      enableUsedProductIds: enableUsedProductIds,
      sortBy: sortBy,
      allOccasions: [],
      allPersonas: [],
      mappingUserIds: mappingUserIds,
      numOutfitsToShow,
      showApprovalUser: showApprovalUser,
      selectionDefault,
      priorityOnly: false,
    }
  }

  setOutfits = async (outfits, outfitSelected) => {
    await this.clearHistory()
    await this.setState({
      outfits,
      outfitSelected,
    })
    if (outfits && outfits.length > 0) {
      await api.outfitsSetInEditor({
        _id: outfits.map(outfit => outfit._id),
        in_editor: true,
        url: this.state.url
      })
    }
    await this.updateHistory()
  }

  clearHistory = async () => {
    await this.setState({
      outfitsHistory: [],
      historyIndex: 0
    })
  }

  updateHistory = async () => {
    let historyIndex = this.state.historyIndex
    let outfitsHistory = this.state.outfitsHistory.slice(historyIndex)
    historyIndex = 0

    // Do a deep copy of the outfits array
    const outfits = JSON.parse(JSON.stringify(this.state.outfits))

    outfitsHistory.unshift(outfits)
    if (outfitsHistory.length > this.state.historySize) {
      outfitsHistory = outfitsHistory.slice(0, this.state.historySize)
    }

    await this.setState({
      outfitsHistory,
      historyIndex
    })
  }

  async getBrandGroups () {
    let allBrandGroups = (await api.getBrandGroups({ url: this.state.url })).data.brand_groups
    const brandGroups = []
    for (const gender in allBrandGroups) {
      const genderBrandGroups = allBrandGroups[gender]
      Object.entries(genderBrandGroups).forEach(([key, value]) => {
        value._id = key
        value.active = false
        value.gender = gender
        brandGroups.push(value)
      })
    }

    // Sort brand groups in alphabetical order
    brandGroups.sort((a, b) => (a.name.localeCompare(b.name)))

    this.setState({
      brandGroups
    })
  }

  getAllOccasions = async () => {
    let allOccasions = await api.getAllOccasions({url: this.state.url})
    await this.setState({
      allOccasions
    })
  }

  getAllPersonas = async () => {
    let allPersonas = await api.getAllPersonas({url: this.state.url})
    await this.setState({
      allPersonas
    })
  }

  handleCloseGarmentNavigator () {
    this.setState({
      activeOutfitIndex: null,
      activeGarmentIndex: null,
      showGarmentNavigator: false
    })
  }

  handleOpenGarmentNavigator = async (outfitIndex, garmentIndex) => {
    await this.setState({
      activeOutfitIndex: outfitIndex,
      activeGarmentIndex: garmentIndex,
      showGarmentNavigator: true
    })
  }

  setCategories = async (categories) => {
    await this.setState({allCategories: categories})
  }

  setProductCategories = async (productCategories) => {
    await this.setState({productCategories})
  }

  handleUpdateOutfit = async (outfitIndex, garmentIndex, garment) => {
    // Update the outfit
    await this.setState(prevState => {
      if (garmentIndex === null) {
        // Adding garment
        prevState.outfits[outfitIndex].garments.push(garment)
      } else {
        // Replacing garment
        prevState.outfits[outfitIndex].garments[garmentIndex] = garment
      }
      return {
        outfits: prevState.outfits
      }
    })

    // Update the history
    await this.updateHistory()
  }

  async handleDeleteGarmentClick (garmentIndex) {
    const outfits = this.state.outfits.slice()
    // iterate over two dimensional outfits and remove garment?
    const outfitsRow = outfits[garmentIndex[0]]
    outfitsRow.garments.splice(garmentIndex[1], 1)
    outfits[garmentIndex[0]] = outfitsRow
    this.setState({
      outfits
    })
    this.updateHistory()
  }

  handleSelectOutfitClick (index) {
    const outfitSelected = this.state.outfitSelected.slice()
    outfitSelected[index] = !outfitSelected[index]
    this.setState({
      outfitSelected: outfitSelected
    })
  }

  async getAllSubcategories () {
    const subcategories = (await api.getAllSubcategories({ url: this.state.url })).data

    const subcategoriesNames = subcategories.map(subcat => {
      return { _id: subcat._id, name: subcat.name }
    })
    // subcategoriesNames.unshift({ _id: -1, name: 'None' })

    // Update subcategory objects with active subcategory
    this.setState({
      subcategories: subcategoriesNames
    })
  }

  async getAttributes () {
    const attributes = (await api.getAttributes({url: this.state.url})).data
    this.setState({
      attributes
    })
  }

  async getAttributeCategories () {
    const attributeCategories = (await api.getAttributeCategories({url: this.state.url})).data
    this.setState({
      attributeCategories
    })
  }

  setLoading = async (loading) => {
    await this.setState({loading})
  }

  async getAttributeCategoryAttributeMap () {
    const attributeCategoryAttributeMap = (await api.getAttributeCategoryAttributeMap({url: this.state.url})).data
    this.setState({
      attributeCategoryAttributeMap
    })
  }

  undo = async () => {
    if (this.state.outfitsHistory.length > 0 && this.state.outfits) {
      const historyIndex = this.state.historyIndex + 1
      if ((historyIndex >= 0) && (historyIndex <= this.state.outfitsHistory.length - 1)) {
        // Take outfits from outfitsHistory with the current historyIndex
        const outfits = JSON.parse(JSON.stringify(this.state.outfitsHistory[historyIndex]))
        await this.setState({
          outfits,
          historyIndex
        })
      }
    }
  }


  redo = async () => {
    if (this.state.outfitsHistory.length > 0 && this.state.outfits) {
      const historyIndex = this.state.historyIndex - 1
      if ((historyIndex >= 0) && (historyIndex <= this.state.outfitsHistory.length - 1)) {
        const outfits = JSON.parse(JSON.stringify(this.state.outfitsHistory[historyIndex]))
        await this.setState({
          outfits,
          historyIndex
        })
      }
    }
  }

  async componentDidMount() {
    if (this.state.url === null) {
      this.props.history.push('/admin/IntroOutfitEditorV2')
      return
    }

    await Promise.all([
      this.getAllSubcategories(),
      this.getBrandGroups(),
      this.getAttributes(),
      this.getAttributeCategories(),
      this.getAttributeCategoryAttributeMap(),
      this.getAllOccasions(),
      this.getAllPersonas()
    ])

    // Set up mousetrap keyboard shortcuts. If changing these remember to unbind
    // them in componentWillUnmount()
    Mousetrap.bind('ctrl+z', this.undo.bind(this))
    Mousetrap.bind('ctrl+y', this.redo.bind(this))
    Mousetrap.bind('ctrl+enter', this.handleApproveClick)
  }

  async componentWillUnmount() {
    Mousetrap.unbind('ctrl+z')
    Mousetrap.unbind('ctrl+y')
    Mousetrap.unbind('ctrl+enter')
    Mousetrap.unbind('ctrl+right')
  }

  render () {
    const { classes } = this.props
    return (
      <div className={this.state.showGarmentNavigator ? classes.rootBlurred : classes.root}>
        <GarmentNavigator
          show={this.state.showGarmentNavigator}
          onClose={this.handleCloseGarmentNavigator.bind(this)}
          activeOutfit={this.state.outfits[this.state.activeOutfitIndex]}
          activeGarment={this.state.activeGarmentIndex !== null ? this.state.outfits[this.state.activeOutfitIndex].garments[this.state.activeGarmentIndex] : null}
          onSelectGarmentClick={(garment) => this.handleUpdateOutfit(this.state.activeOutfitIndex, this.state.activeGarmentIndex, garment)}
          url={this.state.url}

          // Labels - may be able to get these from inside the garment navigator instead
          categories={this.state.allCategories}
          productCategories={this.state.productCategories}
          subcategories={this.state.subcategories}
          attributes={this.state.attributes}
          attributeCategories={this.state.attributeCategories}
          attributeCategoryAttributeMap={this.state.attributeCategoryAttributeMap}
          brandGroups={this.state.brandGroups}
        />
        <OutfitEditorSearchBar
          styling={classes}
          url={this.props.database}
          setOutfits={this.setOutfits}
          setLoading={this.setLoading}
          setCategories={this.setCategories}
          setProductCategories={this.setProductCategories}

          actionsEnabled={this.state.outfits && !this.state.loading}
          occasions={this.state.allOccasions}
          personas={this.state.allPersonas}
          outfits={this.state.outfits}
          outfitSelected={this.state.outfitSelected}
          undo={this.undo}
          redo={this.redo}
          handleApproveClick={this.handleApproveClick}
        />
        {
          this.state.loading
            ? <div className='outfit-loading-spinner'>
              <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div> :
            <div className={classes.contentContainer}>
              {
                this.state.outfits ?
                  <div className={classes.mainContent}>
                    <div className='outfit-editor-outfits-container'>
                      <div className='card-column outfit-editor-cards'>
                        {
                          this.state.loading ? <Spinner />
                            : this.state.outfits.map((outfit, outfitIndex) => (
                              <div className={classes.outfitContainer} key={outfitIndex}>
                                <Outfit
                                  editable
                                  key={outfitIndex}
                                  id={outfitIndex}
                                  outfit={outfit}
                                  outfitNumber={outfitIndex + 1}
                                  outfitGarmentClassName={classes.outfitGarmentClassName}
                                  imageCarouselClassName={classes.imageCarouselClassName}
                                  checked={this.state.outfitSelected[outfitIndex]}
                                  checkboxOnClick={() => (this.handleSelectOutfitClick(outfitIndex))}
                                  addOnClick={() => (this.handleOpenGarmentNavigator(outfitIndex, null))}
                                  garmentEditOnClick={(garmentIndex) => this.handleOpenGarmentNavigator(outfitIndex, garmentIndex)}
                                  garmentDeleteOnClick={this.handleDeleteGarmentClick.bind(this)}
                                  brandGroup={this.state.brandGroups.filter((brandGroup) => (brandGroup.gender === outfit.gender && brandGroup._id === outfit.brand_group))}
                                  enableUsedProductIds={this.state.enableUsedProductIds}
                                  usedProductIds={this.state.usedProductIds}
                                  allOccasions={this.state.allOccasions}
                                  allPersonas={this.state.allPersonas}
                                  showApprovalUser={this.state.showApprovalUser}
                                />
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div> :
                  <div className={classes.placeHolder}>
                    <div className={classes.noOutfitsText}>To start creating the outfits please fill in the filters and tap SEARCH.</div>
                    <img alt='placeholderPic' className={classes.placeholderPic} src={fashionableClothes} />
                  </div>
              }
            </div>

        }
      </div>
    )
  }
}

export default withStyles(style)(OutfitEditorV2)
