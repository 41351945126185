import React from 'react'
import Garment from '../Garment/Garment'
import { defaultProps } from 'recompose'
import plusIcon from '../../assets/img/plusIcon.png'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyle = makeStyles({
  addItemContainer: {
    boxSizing: 'border-box',
    height: '33px',
    width: '140px',
    border: '1px solid #86A8E0',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    margin: 'auto 5px',
    '& img': {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '10px',
      display: 'inline-block',
      height: '13px',
      width: '13px'
    },
    '& p': {
      height: '18px',
      width: '105px',
      color: '#86A8E0',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '18px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '7.5px',
      marginLeft: '1px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  topLine: {
    marginBottom: '15px',
  },
  occasionDropdownTitle: {
    margin: 'auto 5px',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '18px',
  },
  occasionDropdown: {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    lineHeight: '18px',
    width: '150px',
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  approvalUserTextContainer: {
    height: '18px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    textAlign: 'center',
    display: 'inline-block',
    margin: 'auto 5px'
  },
  outfitTypeText: {
    height: '18px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    textAlign: 'center',
    display: 'inline-block',
    margin: 'auto 5px'
  },
  brandGroupText: {
    height: '18px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '18px',
    textAlign: 'center',
    display: 'inline-block',
    margin: 'auto 5px'
  },
  pointerCursor: {
    cursor: 'pointer'
  }
})

function Outfit (props) {
  const [selectedOccasion, setSelectedOccasion] = React.useState(props.outfit.occasion.length > 0 ? props.outfit.occasion[0] : 0)
  const outfitStyle = {
    // height: '400px',
    padding: '0px'
  }

  const classes = useStyle()

  let occasionText = undefined
  if (props.allOccasions) {
    if (props.outfit.occasion && props.outfit.occasion.length !== 0) {
      let occasionName = props.allOccasions.filter(occasion => occasion._id === props.outfit.occasion)
      if (occasionName.length > 0) {
        occasionName = occasionName[0].name
        occasionText = `Occasion: ${occasionName}`
      }
    }
  }

  let personaText = undefined
  if (props.allPersonas) {
    if (props.outfit.persona && props.outfit.persona.length !== 0) {
      let personaName = props.allPersonas.filter(persona => persona._id === props.outfit.persona[0])
      if (personaName.length > 0) {
        personaName = personaName[0].name
        personaText = `Persona: ${personaName}`
      }
    }
  }

  let brandGroupText = undefined
  if (props.brandGroup && props.brandGroup.length > 0) {
    if (props.brandGroup[0]['name'] !== 'undefined') {
      brandGroupText = `Brand Group: ${props.brandGroup[0].name}`
    }
  }

  let typeText = undefined
  if (props.outfit.type === 'shop_the_look') {
    typeText = 'Type: Shop The Look'
  } else if (props.outfit.type === 'complete_the_look') {
    typeText = 'Type: Complete The Look'
  }

  let marketId
  if (![null, undefined].includes(props.outfit.market_id) && props.outfit.market_id.length > 0) {
    marketId = props.outfit.market_id[0]
  }

  const handleOccasionChange = (event) => {
    event.stopPropagation() 
    event.preventDefault()
    if (props.outfit.occasion.length > 0) {
      props.outfit.occasion.pop()
      props.outfit.occasion.push(event.target.value)
      setSelectedOccasion(event.target.value)
    } else {
      props.outfit.occasion.push(event.target.value)
      setSelectedOccasion(event.target.value)
    }
  }

  return (
    <div className={`card ${!props.checked ? '' : 'outfit-selected'}`} style={outfitStyle}>
      <div className={`${!props.checked ? 'outfit-header' : 'outfit-header-selected'} ${classes.pointerCursor}  bg-muted`} onClick={() => props.checkboxOnClick(props.key)}>
        <div className={`d-flex flex-row justify-content-between ${classes.topLine}`}>
          <p>
            {`OUTFIT ${props.outfitNumber}`}
          </p>
          { props.outfit._user === '62c439a7e9a38c26d99f878e' &&
            <div className='d-flex flex-row'>
              <div className={classes.occasionDropdownTitle}>Occasion:</div>
              <Select
                value={selectedOccasion}
                onChange={handleOccasionChange}
                className={classes.occasionDropdown}
              >
                {
                  props.allOccasions.map((occasion) => {
                    return <MenuItem 
                      key={occasion._id}
                      value={occasion._id}
                    >
                      {occasion.name}
                    </MenuItem>
                  })
                }
              </Select>
            </div>
          }
        </div>
        
        <div className='d-flex flex-row justify-content-between'>
          
          {
            (props.showApprovalUser && ![null, undefined].includes(props.outfit.approval_user)) &&
            <div className={classes.approvalUserTextContainer}>
              {props.outfit.approval_user.username}
            </div>
          }
          <p>
            {!!props.outfit.bodytype && props.outfit.bodytype.length !== 0 &&
                        `Bodytype: ${props.outfit.bodytype}`}
          </p>
          <p>
            {props.outfit.occasion && props.outfit.occasion.length !== 0 && occasionText}
          </p>
          <div className={classes.brandGroupText}>
            {brandGroupText}
          </div>
          <div className={classes.outfitTypeText}>
            {props.outfit.persona && props.outfit.persona.length !== 0 && personaText}
          </div>
          <div className={classes.outfitTypeText}>
            {props.outfit.market_id && props.outfit.market_id.length > 0 && `Market ID: ${props.outfit.market_id}`}
          </div>
          <div className={classes.outfitTypeText}>
            {props.outfit.type && typeText}
          </div>
          {
            props.addOnClick &&
              <div
                className={`${classes.addItemContainer} ${classes.buttonHover}`}
                onClick={(e) => {
                  props.addOnClick()
                  e.stopPropagation()
                }}
              >
                <img alt='plusIcon' src={plusIcon} />
                <p>ADD ITEM</p>
              </div>
          }
        </div>
      </div>
      <div className='card-body card-body-outfit'>
        <div className='d-flex flex-row outfit-editor-garments-wrap'>
          {
            props.outfit.garments.map((garment, index) => (
              <Garment
                deleteOnClick={props.garmentDeleteOnClick}
                editable={props.editable}
                editOnClick={(garmentIndex, garment) => {
                  props.garmentEditOnClick(props.outfitNumber - 1, garmentIndex, garment)}
                }
                garmentIndex={[props.id, index]}
                garment={garment}
                imageHeight={200}
                imageCarousel
                imageCarouselClassName={props.imageCarouselClassName}
                className={props.outfitGarmentClassName}
                indicateStock
                key={`${props.id}-${index}`}
                productUrl
                showId={false}
                showProductId
                showBrand
                showPrice
                showStockLevel
                showProductCategories={false}
                useRemoteImage={false}
                resized={props.resized === undefined ? false : props.resized}
                enableUsedProductIds={props.enableUsedProductIds}
                usedProductIds={props.usedProductIds}
                marketId={marketId}
              />
            ))
          }
        </div>
      </div>
    </div>
  )
}

// Add default props to outfit
const withDefaultProps = defaultProps({
  outfitNumber: ''
})

export default  withDefaultProps(Outfit)
