import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
    width: '100%'
  },
  label: {
    width: '30%',
    margin: 'auto 10px',
    color: '#86A8E0',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center'
  },
  sliderContainer: {
    width: '70%'
  }
}))


export default function ContinuousSlider(props) {
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    props.setValue(newValue)
  }

  return (
    <div className={classes.groupContainer}>
      <div className={classes.label}>
        {props.label}
      </div>
      <div className={classes.sliderContainer}>
        <Slider
          value={props.value}
          min={props.min}
          step={props.step}
          max={props.max}
          scale={props.scale}
          marks={[
            {
              value: 1,
              label: '1'
            }
          ]}
          valueLabelFormat={props.valueLabelFormat}
          onChange={handleChange}
          valueLabelDisplay="auto"
        />
      </div>
    </div>
  )
}