import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function PersonaAccordionSection(props) {
  const getLabels = async () => {
    let newLabels = await api.getPersonas({url: props.url})

    try {
      newLabels.forEach(label => {
        label.active = false
      })
      return newLabels
    } catch {
      console.error('Failed to get personas')
      return []
    }


  }

  return (
    <AbstractAccordionSection
      title={'Persona'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}