import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SelectAndFilterImage from './SelectAndFilterImage'

const useStyles = makeStyles(() => ({
  containerBg: {
    marginLeft: '208px',
    padding: '0 0 0 25px',
    '@media only screen and (max-width: 960px)': {
      marginLeft: '0px',
      padding: '0 ',
    },
  },
  dropBtn: {
    height: '500px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
  },
  dropBtnImg: {
    height: '104px',
    width: '104px',
  },
}))

const BackgroundRemover = () => {
  const classes = useStyles()
  const [screenShrink, setScreenShrink] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(false)

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    const changeWidth = () => {
      setScreenShrink(window.innerWidth < 960 ? true : false)
    }

    window.addEventListener('resize', changeWidth)

    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <>
      <div className={`${classes.containerBg}`}>
        <SelectAndFilterImage
          screenShrink={screenShrink}
          toggleNav={toggleNav}
          hideSideBar={toggleMenu}
        />
      </div>
    </>
  )
}

export default BackgroundRemover
