import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AutocompleteDropdown from '../../components/GarmentNavigator/AutocompleteDropdown'
import SlideSwitch from '../../components/SlideSwitch/SlideSwitch'

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    width: 'calc(100% + 40px)',
    borderLeft: '1px solid #DAE0EA',
    borderRight: '1px solid #DAE0EA',
    borderBottom: '1px solid #DAE0EA',
    maxWidth: '1370px',
    padding: '15px 32px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  show: {
    height: 'auto'
  },
  hide: {
    height: '0px',
    display: 'none'
  },
  marketIdContainer: {
    width: '200px',
    marginRight: '5px'
  },
  brandContainer: {
    width: '300px',
    marginRight: '5px'
  },
  brandGroupContainer: {
    width: '300px',
    marginRight: '5px'
  },
  outfitTypeContainer: {
    width: '200px',
    marginRight: '5px'
  },
  userContainer: {
    width: '300px',
    marginRight: '5px'
  },
  titleText: {
    display: 'inline-block',
    // width: '135px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '5px',
    marginTop: '5px',
    textTransform: 'uppercase'
  },
  numberSelectContainer: {
    marginRight: '5px'
  },
  numberInput: {
    width: '50px',
    height: '50px',
    marginRight: '5px',
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px'
  },
}))

export default function OutfitEditorAdvancedFilterBar (props) {
  const classes = useStyles()

  const handleNumberChange = async (event, prevValue, onChange) => {
    event.preventDefault()
    let value = 0
    if (event.target.value !== '') {
      value = parseInt(event.target.value)
    }
    if (!isNaN(value)) {
      if (value !== prevValue) {
        await onChange(value)
      }
    }
  }

  const handleMinApprovedChange = async (event) => {
    await handleNumberChange(event, props.minApprovalLevelShow, props.handleMinApprovalLevelShowChange)
  }

  const handleMaxApprovedChange = async (event) => {
    await handleNumberChange(event, props.maxApprovalLevelShow, props.handleMaxApprovalLevelShowChange)
  }

  return (
    <div className={`${classes.root} ${props.show ? classes.show : classes.hide}`}>
      <div className={classes.row}>
        <div className={classes.marketIdContainer}>
          <AutocompleteDropdown
            title={'Market ID'}
            options={props.marketIds}
            onChange={props.handleMarketIdChange}
            placeholder={'Select Market ID'}
            value={props.marketId}
          />
        </div>
        <div className={classes.brandContainer}>
          <AutocompleteDropdown
            title={'Brand'}
            options={props.brands}
            onChange={props.handleBrandChange}
            placeholder={'Select Brand'}
            value={props.brand}
          />
        </div>
        { props.brandGroups ?
          <div className={classes.brandGroupContainer}>
            <AutocompleteDropdown
              title={'Brand Group'}
              options={props.brandGroups}
              onChange={props.handleBrandGroupChange}
              placeholder={'Select Brand Group'}
              value={props.brandGroup}
            />
          </div> : <></>
        }
        <div className={classes.outfitTypeContainer}>
          <AutocompleteDropdown
            title={'Outfit Type'}
            options={props.outfitTypes}
            onChange={props.handleOutfitTypeChange}
            placeholder={'Select Outfit Type'}
            value={props.outfitType}
          />
        </div>
        {
          props.enableShowApprovedControls &&
          <div>
            <SlideSwitch
              title={'Approved Only'}
              checked={props.approvedOnly}
              handleChange={props.handleShowApprovedOnlyChange}
            />
          </div>
        }
        {
          props.enableShowApprovedControls &&
          <div>
            <SlideSwitch
              title={'Unapproved Only'}
              checked={props.unapprovedOnly}
              handleChange={props.handleShowUnapprovedOnlyChange}
            />
          </div>
        }
        <div>
          <SlideSwitch
            title={'Selection Default'}
            checked={props.selectionDefault}
            handleChange={props.handleSelectionDefaultChange}
          />
        </div>
        <div>
          <SlideSwitch
            title={'Priority Only'}
            checked={props.priorityOnly}
            handleChange={props.handlePriorityOnlyChange}
          />
        </div>
      </div>
      <div className={classes.row}>
        {
          props.enableShowApprovedControls &&
          <div className={classes.numberSelectContainer}>
            <p className={classes.titleText}>Min Approved</p>
            <form
              onKeyPress={e => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              autoComplete='off'
            >
              <input
                type='string'
                step={1}
                className={classes.numberInput}
                id='paginationInput'
                name='quantity'
                value={props.minApprovalLevelShow}
                min={-10}
                max={10}
                onChange={handleMinApprovedChange}
              />
            </form>
          </div>
        }
        {
          props.enableShowApprovedControls &&
          <div className={classes.numberSelectContainer}>
            <p className={classes.titleText}>Max Approved</p>
            <form
              onKeyPress={e => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              autoComplete='off'
            >
              <input
                type='string'
                step={1}
                className={classes.numberInput}
                id='paginationInput'
                name='quantity'
                value={props.maxApprovalLevelShow}
                min={-10}
                max={10}
                onChange={handleMaxApprovedChange}
              />
            </form>
          </div>
        }
        <div className={classes.userContainer}>
          <AutocompleteDropdown
            title={'Username'}
            options={props.allVettingUsers}
            onChange={props.handleSelectedUserChange}
            placeholder={'Select Username'}
            value={props.selectedUser}
          />
        </div>
      </div>
    </div>
  )
}
