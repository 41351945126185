import React, { useState, useEffect } from 'react'
import api from '../../api/api'
import LikeButton from './LikeButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  outfitContainer: {
    margin: '20px 0'
  },
  garmentsContainer: {
    width: '100%',
    height: '310px',
    overflowX: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px'
  },
  garmentContainer: {
    height: '300px',
    width: '150px',
    minHeight: '300px',
    maxHeight: '300px',
    minWidth: '150px',
    maxWidth: '150px'
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    height: '30px'
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  likeButtonContainer: {
    marginLeft: 'auto',
    width: '40px',
    position: 'relative',
    top: '80px'

  },
  loadingSpinner: {
    height: '40px',
    width: '100%',
    textAlign: 'center',
    margin: 'auto'
  }
}))

export default function Outfit (props) {
  const user = JSON.parse(localStorage.getItem('user'))
  const outfitDisplay = new global.Outfit(null, props.garment._id, null, props.occasions, props.personas)
  const classes = useStyles()
  const [outfit, setOutfit] = useState(null)
  const [displayOutfit, setDisplayOutfit] = useState(true)

  let title = props.title || 'How To Style It'

  useEffect(() => {
    async function fetchData () {
      if (outfit === null) {
        // Set outfit to dummy outfit so we don't re-render and fetch data twice while waiting for getOutfit
        setOutfit({ garments: [] })
        const outfit = await getOutfit()
        try {
          setOutfit(outfit)
          outfitDisplay.setOutfit(outfit)
          if (props.source === 'Zalando' || props.source === 'lcw') {
            outfitDisplay.settings.occasionSelect = false
          }
          if (user.feSettings.productPageOutfitApprovalLevel && user.feSettings.productPageOutfitApprovalLevel === 2) {
            outfitDisplay.settings.occasionSelect = false
          }
          if (user.feSettings.language) {
            outfitDisplay.settings.language = user.feSettings.language
          }
          if (!props.occasionSelect) {
            outfitDisplay.settings.occasionSelect = false
          }

          if (props.personaSelect) {
            outfitDisplay.settings.personaSelect = true
          }

          outfitDisplay.settings.clickable = false
          outfitDisplay.getOutfit = getOutfit // This is where the function that retrieves the outfits is defined
          outfitDisplay.settings.targetElement = props.source
          outfitDisplay.settings.headerEn = title
          outfitDisplay.settings.headerTr = title
          outfitDisplay.settings.imagePrefix = ''
          await outfitDisplay.setOutfitLayoutAndControls()
          outfitDisplay.appendOutfit()
        } catch (error) {
          setOutfit({ garments: [] })
          setDisplayOutfit(false)
          console.log(error)
        }
      }
    }
    fetchData()
  })

  const getOutfit = async (server, garmentId, authToken, bodytype, occasion, persona) => {
    const body = {
      query_id: props.garment._id,
      cached: props.cached,
      ranked: true,
      outfit_generation_settings: {
        allow_cross_user_outfits: props.allowCrossUserOutfits
      },
      show_intelistyle_outfits: props.showIntelistyleOutfits,
      outfit_restrictions: {
        occasion_restriction: parseInt(occasion),
        persona_restriction: parseInt(persona)
      },
      extended_images: true,
    }

    if (props.outfitRestrictions !== null) {
      body.outfit_restrictions = props.outfitRestrictions
      body.outfit_restrictions.occasion_restriction = parseInt(occasion)
      body.outfit_restrictions.persona_restriction = parseInt(persona)
    }

    if (props.groupRestrictions !== null && props.groupRestrictions !== undefined) {
      body.group_restrictions = props.groupRestrictions
    }

    if (props.outfitRules !== null && props.outfitRules !== undefined) {
      body.outfit_rules = props.outfitRules
    }

    if (props.locationContainGroups !== null && props.locationContainGroups !== undefined) {
      body.location_contain_groups = props.locationContainGroups
    }

    const outfits = await api.getOutfits(body)

    if (outfits.data.outfits === undefined) {
      return []
    } else if (outfits.data.outfits.length !== 0) {
      return outfits.data.outfits[0]
    } else {
      return outfits.data.outfits
    }
  }

  const likeButtonOnClick = () => {
    const likedOutfit = JSON.parse(JSON.stringify(outfit))
    likedOutfit.source = props.source

    // Call props.onClick with outfit
    props.likeButtonOnClick(likedOutfit)
  }

  return (
    outfit !== undefined &&
    displayOutfit && (
      <div>

        <div className={classes.outfitContainer}>
          {props.likeButton && (
            <div className={classes.likeButtonContainer}>
              <LikeButton
                pressed={props.likeButtonPressed}
                onClick={likeButtonOnClick}
              />
            </div>
          )}
          <div className={props.source} />
          {
          //   <div className={classes.titleBar}>
          //   <div className={classes.title}>{title}</div>
          // </div>
          // <div className={classes.garmentsContainer}>
          //   {outfit === null ? (
          //     <div className={classes.loadingSpinner}>
          //       <CircularProgress />
          //       {/* Loading */}
          //     </div>
          //   ) : (
          //     outfit.garments.map((garment, index) => (
          //       <div className={classes.garmentContainer} key={index}>
          //         <Garment key={index} garment={garment} />
          //       </div>
          //     ))
          //   )}
          // </div>
          }
        </div>
      </div>
    )
  )
}
