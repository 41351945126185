import React from 'react'
import api from '../../api/api'
import MessagingNumbersBar from '../../components/MessagingNumbersBar/MessagingNumbersBar'
import MessagesArea from '../../components/MessagesArea/MessagesArea'
import Grid from '@material-ui/core/Grid'
class CHatbotMessaging extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      twilioNumbers: [],
      activeNumber: '',
      audienceNumbers: [],
      userId: JSON.parse(localStorage.getItem('user'))._id,
      userPhoneNumber: 'whatsapp:+447782678259'
    }
    this.setActivePhoneNumber = this.setActivePhoneNumber.bind(this)
  }

  async componentDidMount () {
    await this.getPhoneNumbers()
  }

  async getPhoneNumbers () {
    const phoneNrs = (await api.getPhoneNumbers({ userId: this.state.userId })).data
    await this.setState({ audienceNumbers: phoneNrs })
  }

  async setActivePhoneNumber (phoneNumber) {
    const phoneNumbers = this.state.audienceNumbers
    phoneNumbers.forEach(e => {
      if (e.phoneNr === phoneNumber) {
        e.active = true
      } else {
        e.active = false
      }
    })
    await this.setState({ audienceNumbers: phoneNumbers })
    await this.setState({ activeNumber: phoneNumber })
  }

  render () {
    return (
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} lg={3}>
            <MessagingNumbersBar phoneNumbers={this.state.audienceNumbers} setActivePhoneNumber={this.setActivePhoneNumber} />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <MessagesArea userPhoneNumber={this.state.userPhoneNumber} activeNumber={this.state.activeNumber} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default CHatbotMessaging
