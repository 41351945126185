const endpoint = (() => {
  let url
  if (process.env.IMAGE_SERVICE) {
    return process.env.IMAGE_SERVICE
  }
  url = new URL(window.location.origin)
  // Replace port of dashboard with 8082
  url.port = 8082
  url = url.toString()
  return url
})()

const settings = {
  endpoint,
  // endpoint: 'https://zadig.intelistyle.co.uk:8082',
  analyticsEndpoint: 'https://lanecrawford.intelistyle.co.uk:8081',

  openAISecretKey: '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',

  s3url: 'https://s3-eu-west-1.amazonaws.com/stylr-ai-engine-srv-data/srv/data/serving/',
  pages: {
    productRanking: true,
    similarResults: true,
    outfitResults: true,
    search: true,
    chatbotProductSelection: true
  },
  chatbotProductSelectionUserId: ''
}

export default settings
