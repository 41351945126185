import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  itemsPerPage: {
    margin: 'auto 50px',
    display: 'flex',
    flexDirection: 'row',
    height: '30px',
    '@media only screen and (max-width: 1366px)': {
      margin: 'auto'
    }
  },
  itemsPerPageSelectInput: {
    width: '40px',
    height: '30px',
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px'
  },
  textContainer: {
    color: '#000000',
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '11px',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 15px'
  }
}))


const ItemsPerPage = (props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.itemsPerPage}
    >
      <div className={classes.textContainer}>
      Items Per Page
      </div>
      <form
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        autoComplete='off'
      >
        <input
          type='string'
          step={1}
          className={classes.itemsPerPageSelectInput}
          id='itemsPerPageInput'
          name='quantity'
          min={1}
          max={100}
          value={props.itemsPerPage}
          onChange={event => {
            props.onItemsPerPageChange(event)
          }}
        />
      </form>
    </div>
  )
}

export default ItemsPerPage
