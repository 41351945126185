import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import inUseIcon from '../../assets/img/inUseIcon.png'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import editIcon from '../../assets/img/editIcon.png'
import deleteIcon from '../../assets/img/deleteIcon.png'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    top: '0px',
    right: '0px',
    zIndex: '100',
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    pointerEvents: 'none'
  },
  rowContainer: {
    display: 'inline-block'
  },
  statusIconsRow: {
    marginTop: '7px',
    marginLeft: '7px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'align-left'
  },
  controlIconsRow: {
    marginTop: '7px',
    marginRight: '7px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'align-right'
  },
  icon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '2px',
    color: '#86A8E0',
    pointerEvents: 'all'
  },
  indicatorIcon: {
    height: '31px',
    width: '31px',
    border: '1px solid #DAE0EA',
    borderRadius: '5px',
    background: 'white',
    display: 'flex',
    zIndex: '500',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 600,
    color: '#86A8E0',
    marginRight: '2px'
  },
  inUseIcon: {
    padding: '6.5px',
    border: '1px solid #DAE0EA',
    borderRadius: '5px',
    background: 'white',
    display: 'flex',
    zIndex: '500',
    marginRight: '2px'
  },
  dragIcon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    marginLeft: '2px',
    color: '#86A8E0',
    pointerEvents: 'all'
  },
  dragIconGrabbed: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grabbing',
    marginLeft: '2px',
    color: '#86A8E0',
    pointerEvents: 'all'
  },
  centerTextContainer: {
    margin: 'auto'
  }
}))

export default function GarmentControls (props) {
  const classes = useStyles()
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null)

  const handleMoreClick = (event) => {
    setMoreMenuAnchorEl(event.currentTarget)
  }

  const handleMoreClose = () => {
    setMoreMenuAnchorEl(null)
  }

  const handleClick = (event) => {
    if (event.target.id === 'root') {
      props.onClick()
    }
  }

  const handleRemoveFromReplacementOrderClick = () => {
    props.handleRemoveFromReplacementOrderClick()
    handleMoreClose()
  }

  const handleAddToReplacementOrderClick = () => {
    props.handleAddToReplacementOrderClick()
    handleMoreClose()
  }

  // Build up menu items here as list based on available onClick props so that we can exclude menu if there are no menu items
  let menuItems = []
  if (props.handleRemoveFromReplacementOrderClick) {
    menuItems.push((
      <MenuItem id="remove-control" key="remove-control" onClick={handleRemoveFromReplacementOrderClick}>
        Remove from replacement order
      </MenuItem>
    ))
  }
  if (props.handleAddToReplacementOrderClick) {
    menuItems.push((
      <MenuItem id="add-control" key="add-control" onClick={handleAddToReplacementOrderClick}>
        Add to replacement order
      </MenuItem>
    ))
  }

  return (
    <div className={classes.root} onClick={handleClick} id={'root'}>
      <div className={classes.rowContainer}>
        <div className={classes.statusIconsRow}>
          {
            props.inUse &&
            <span
              className={classes.inUseIcon}
              onClick={() => {
                if (props.onClick) {
                  props.onClick()
                }
              }}
            >
              <img alt='inUse' src={inUseIcon}/>
            </span>
          }
          {
            props.number &&
            <span
              className={classes.indicatorIcon}
              onClick={() => {
                if (props.onClick) {
                  props.onClick()
                }
              }}
            >
              <div className={classes.centerTextContainer}>
                {props.number}
              </div>
            </span>
          }
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.controlIconsRow}>
          {
            menuItems.length > 0 &&
            <div>
              <span className={classes.icon} onClick={handleMoreClick}>
                <MoreHorizIcon/>
              </span>
              <Menu
                id={'more-menu'}
                anchorEl={moreMenuAnchorEl}
                keepMounted
                open={Boolean(moreMenuAnchorEl)}
                onClose={handleMoreClose}
              >
                {menuItems}
              </Menu>
            </div>
          }

          {
            props.editable &&
            <span className={classes.icon} onClick={props.handleEditClick}>
              <img alt='edit' src={editIcon}/>
            </span>
          }
          {
            props.editable &&
            <span className={classes.icon} onClick={props.handleDeleteClick}>
              <img
                alt='delete'
                src={deleteIcon}
              />
            </span>
          }
          {
            props.draggable &&
            <span
              className={props.isDragging ? classes.dragIconGrabbed : classes.dragIcon}
              ref={props.dragRef}
            >
              <DragIndicatorIcon/>
            </span>
          }
        </div>
      </div>
    </div>
  )
}
