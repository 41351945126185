import React from 'react'
import '../../../assets/css/WearThatSearch/AgeRanges.scss'
import { capitalize } from '@material-ui/core'


export default function AgeRange(props) {
  const options = []
  for (const key of Object.keys(props.filterOptions)) {
    options.push(
      <label key={key} className='fancy-checkbox'>
        <input
          type='checkbox'
          name='age_range'
          onChange={e => props.handleCheckboxChange(e)}
          checked={props.age_range.includes(key)}
          value={key}
          key={key}
          className='checkbox'
        />
        <i className='fa   unchecked' />
        <i className='fa fa-check checked' />
        <div className='some-label'>
          {typeof props.filterOptions[key] == 'string' ? capitalize(props.filterOptions[key]) : 'null'}
        </div>
        <br />
      </label>
    )
  }

  return <div className='age-ranges-wrapper'>{options}</div>
}
