import React from 'react'
import {defaultProps} from 'recompose'
import Garment from 'components/LabelEditor/Garment/Garment'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1), minWidth: 120, maxWidth: 300,
  }, chips: {
    display: 'flex', flexWrap: 'wrap',
  }, chip: {
    margin: 2,
  }, noLabel: {
    marginTop: theme.spacing(3),
  }, attributesRow: {
    display: 'flex', flexDirection: 'row'
  }, attributeDropdownContainer: {}, garmentContainer: {}
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250,
    },
  },
}

function getStyles(name, attributeName, theme) {
  return {
    fontWeight: attributeName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

function getSelectedAttribute(props, value, attributeCategory, deletedAttributeName = null) {
  let currentlySelectedAttributes = props.garment.attribute_data.attributes
      .filter(attribute => props.attributeCategoryAttributeMap[attributeCategory._id].includes(attribute._id))
      .map(attribute => attribute.name)

  let updatedAttribute = currentlySelectedAttributes
      .filter(attributeName => !value.includes(attributeName))
      .concat(value
          .filter(attributeName => !currentlySelectedAttributes.includes(attributeName)))[0]

  if (deletedAttributeName) {
    updatedAttribute = currentlySelectedAttributes
        .filter(attributeName => attributeName == deletedAttributeName)
        .concat(value.filter(attributeName => !currentlySelectedAttributes.includes(attributeName)))[0]
  }

  let selectedAttribute = props.allAttributes
      .filter(attribute => attribute.name === updatedAttribute)[0]

  return selectedAttribute
}


function Attributes(props) {
  const classes = useStyles()
  const theme = useTheme()

  const handleSelectAttribute = (event, attributeCategory) => {
    let selectedAttribute = getSelectedAttribute(props, event.target.value, attributeCategory)
    props.handleSelectAttributeClick(props.id, selectedAttribute._id)
  }

  const handleDeleteAttribute = (deletedAttributeName, attributeNames, attributeCategory) => {
    return () => {
      let attribute = getSelectedAttribute(props, attributeNames, attributeCategory, deletedAttributeName)
      props.handleSelectAttributeClick(props.id, attribute._id)
    }
  }

  return (props.garment != null && <div className="card rounded border border-dark">
    <div className="card-body">
      <div className={classes.attributesRow}>
        <h6>{props.id + 1}</h6>
        <div className={classes.garmentContainer}>
          <Garment
              key={`${props.id}`}
              garmentIndex={1}
              garment={props.garment}
              imageHeight={200}
              editable={false}
              showId={true}
              showProductId={false}
              showProductCategories={true}
              showCreatedAt={true}
              setGarmentInEditor={props.setGarmentInEditor}
          />
        </div>
        <div className={classes.attributeDropdownContainer}>
          <p>{`Subcategory: ${props.garment.subcategory_data[0].name}`}</p>
          {// Define attribute group dropdowns
            props.allAttributeCategories.map((attributeCategory, index) => {
              // Get existing garment attribute names which fit in this attribute group
              let attributeNames = props.garment.attribute_data.attributes
                  .filter(garmentAttribute => props.attributeCategoryAttributeMap[attributeCategory._id]
                      .includes(garmentAttribute._id))
                  .map(garmentAttribute => garmentAttribute.name)

              return (props.attributeCategoryAttributeMap[attributeCategory._id]
                  .filter(attribute_id => props.allAttributes
                      .map(attribute => attribute._id)
                      .includes(attribute_id)).length > 0 && <FormControl
                  key={`${props.id}-${index}`}
                  className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">{attributeCategory.name}</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={attributeNames}
                    onChange={(event) => handleSelectAttribute(event, attributeCategory)}
                    input={<Input id="select-multiple-chip"/>}
                    renderValue={attributeNames => (<div className={classes.chips}>
                      {
                        attributeNames.map(attributeName => (
                            <Chip
                                key={`${props.id}-${attributeName}-chip`}
                                label={attributeName}
                                className={classes.chip}
                                onDelete={handleDeleteAttribute(attributeName, attributeNames, attributeCategory)}
                                onMouseDown={(event) => event.stopPropagation()}
                            />)
                        )
                      }
                    </div>)}
                    MenuProps={MenuProps}
                >
                  {
                    props.allAttributes
                        .filter(attribute => props.attributeCategoryAttributeMap[attributeCategory._id].includes(attribute._id))
                        .map(attribute => (
                            <MenuItem
                                key={`${props.id}-${attribute.name}`}
                                value={attribute.name}
                                style={getStyles(attribute.name, attributeNames, theme)}
                            >
                              {attribute.name}
                            </MenuItem>))
                  }
                </Select>
              </FormControl>)
            })}
        </div>
        <button
            type="button"
            key={`button-${props.id}`}
            className={`btn btn-outline-info${props.selected ? ' active' : ''}`}
            data-toggle="button"
            aria-pressed={props.selected}
            onClick={() => (props.checkboxOnClick(props.id))}
        >
          Wrong Subcategory
        </button>
        <button
            className="btn btn-info my-2 my-sm-0"
            disabled={false}
            type="button"
            onClick={() => (props.onCopyClick(props.id))}
            style={{height: '80%', 'fontSize': '10px'}}
        >
          Copy
        </button>
        <button
            className="btn btn-info my-2 my-sm-0"
            disabled={false}
            type="button"
            onClick={() => (props.onPasteClick(props.id))}
            style={{height: '80%', 'fontSize': '10px'}}
        >
          Paste
        </button>
      </div>
    </div>
  </div>)
}

// Add default props to garment
const withDefaultProps = defaultProps({
  imageHeight: 200, height: 300, width: 300, editable: false, selectable: false, indicators: false,
})

Attributes = withDefaultProps(Attributes)

export default Attributes
