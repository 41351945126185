import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  labelListContainer: {
    marginTop: '20px',
    marginRight: '20px'
  },
  labelListValues: {
    marginTop: '2px',
    padding: '2px',
    // display: 'inline',
    listStyle: 'none'
  },
  li: {
    display: 'block',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    fontWeight: '600',
    marginBottom: '0px'
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px',
    borderBottom: '1px solid'
  }
}))

export default function LabelList (props) {
  const classes = useStyles()
  return (
    <div className={classes.labelListContainer}>
      <div className={classes.title}>{props.title}</div>
      <ul className={classes.labelListValues}>
        {
          props.labels.map((label, index) => {
            if (index === props.labels.length - 1) {
              return (<li className={classes.li} key={`labelList-${index}`}>{label}</li>)
            } else {
              return (<li className={classes.li} key={`labelList-${index}`}>{label}</li>)
            }
          })
        }
      </ul>
    </div>
  )
}
