import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  productDescription: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    marginTop: '10px',
    marginBottom: '0px'
  }
}))

export default function Description (props) {
  const classes = useStyles()
  const [description, setDescription] = useState(null)

  const getDescription = async () => {
    let newDescription = await api.getDescription({
      garment_id: props.garment._id
    })
    setDescription(newDescription)
  }

  useEffect(() => {
    if (description === null && props.garment !== null) {
      getDescription()
    }
  // eslint-disable-next-line
  }, [description, props.garment])

  return (
    <div className={classes.productDescription}>
      {description}
    </div>
  )
}
