import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, Chip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  subgroupContainer: {
    backgroundColor: '#EEEEEEEE',
    padding: '12px 8px 12px 8px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    border: 'none',
    borderRadius: '8px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    marginLeft: 8,
    backgroundColor: '#6236FF80',
    padding: '6px 8px 6px 8px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '15.23px'
  },
  inlineDropdown: {
    flex: '1 1 auto',
  },
  subgroupName: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '18.75px',
    color: '#161616',
    marginBottom: '16px',
  },
  attributeSelector: {
    border: '1px solid #6236FF',
    backgroundColor: '#fff',
    borderRadius: '24px',
    minHeight: '24px',
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:after': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
  singleAttribute: {
    backgroundColor: '#EEEEEEEE',
  },
}))

const AttributeSubgroup = ({ subgroup, garment, handleSelectAttribute, hasSubgroups }) => {
  const classes = useStyles()

  const selectedAttributes = garment.labels.attribute.attributes
    .filter((attr) => subgroup.children.some((child) => child._id === attr._id))
    .map((attr) => attr._id)

  const isExclusive = subgroup.children_are_mutually_exclusive

  const selectionOptions = subgroup.children.filter((attr) => attr.label_type === 'attribute' && attr.name !== 'N/A')

  return (
    <div className={hasSubgroups ? `${classes.subgroupContainer}` : `${classes.subgroupContainer} ${classes.singleAttribute}`}>
      <FormControl className={`${classes.formControl} ${classes.inlineDropdown}`} fullWidth>
        <div className={classes.subgroupName}>{subgroup.name}</div>
        <Select
          className={classes.attributeSelector}
          multiple={!isExclusive}
          value={selectedAttributes}
          displayEmpty
          onChange={(event) =>
            handleSelectAttribute(event.target.value, isExclusive, selectionOptions)
          }
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => {
                return (
                  <Chip
                    key={value}
                    label={subgroup.children.find((attr) => attr._id === value)?.name}
                    className={classes.chip}
                  />
                )
              })}
            </div>
          )}
        >
          {selectionOptions.map((attr) => (
            <MenuItem key={attr._id} value={attr._id}>
              {attr.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default AttributeSubgroup
