/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Collapse from '@material-ui/core/Collapse';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';

// core components

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import intelistyleLogo from "../../assets/img/intelistyle_cropped_logo.png"

const Sidebar = ({ ...props }) => {
  // State for expandable list
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const closeMenu = () => {
    setOpen(false)
  }
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.split('/').indexOf(routeName) > -1 ? true : false;
  }
  function getActiveRoute() {
    const url = window.location.href.split('/')
    return "/" + url[3] + '/' + url[4];
  }
  const { classes, color, image, logoText, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.subRoutes) {
          var listItemClasses;
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          })
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });
          return (
              <ListItem button className={classes.itemLink + listItemClasses} onClick={handleClick} key={key}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses, {
                      [classes.itemIconRTL]: props.rtlActive
                    })}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    />
                  )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive
                  })}
                  disableTypography={true}
                />
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {prop.subRoutes.map((prop,keyS) => {
                      return (
                        <NavLink
                          to={prop.layout + prop.path}
                          className={classes.item}
                          activeClassName="active"
                          key={key+keyS+routes.length}
                        >
                          <ListItem button className={classes.nested}>
                            <ListItemText
                              primary={prop.name}
                              className={classNames(classes.itemText, whiteFontClasses, {
                                [classes.itemTextRTL]: props.rtlActive
                              })}
                              disableTypography={true}/>
                          </ListItem>
                        </NavLink>
                      )
                    })}
                  </List>
                </Collapse>
              </ListItem>
          );
        } else {
          var listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });
          return (
            <NavLink
              to={prop.layout + prop.path}
              className={classes.item}
              activeClassName="active"
              key={key}
            >
              <ListItem button className={getActiveRoute() === (prop.layout + prop.path) ? classes.activeItemLink : classes.itemLink} onClick={closeMenu} key={key}>
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={getActiveRoute() === (prop.layout + prop.path) ? classes.activeItemIcon : classes.itemIcon}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                    <prop.icon
                      className={getActiveRoute() === (prop.layout + prop.path) ? classes.activeItemIcon : classes.itemIcon}
                    />
                  )}
                <ListItemText
                  primary={props.rtlActive ? prop.rtlName : prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive
                  })}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        }

      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://www.intelistyle.com/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={intelistyleLogo} alt="logo" className={classes.img} />
        </div>
        <div className={classes.logoText}>
          {
          //  Intelistyle
          }
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

export default withStyles(sidebarStyle)(Sidebar);
