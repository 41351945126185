module.exports =
    [
      { _id: -1, full_name: 'None', colours: [] },

      { _id: 1, full_name: 'Beige', colours: ['moccasin', 'navajowhite', 'linen', 'antiquewhite', 'cornsilk', 'blanchedalmond', 'bisque', 'wheat'] },

      { _id: 2, full_name: 'Golden/Yellow', colours: ['gold', 'darkgoldenrod', 'peru', 'goldenrod', 'chocolate'] },

      { _id: 3, full_name: 'Red', colours: ['tomato', 'orangeRed', 'crimson', 'red', 'firebrick', 'darkred'] },

      { _id: 4, full_name: 'Orange', colours: ['orange'] },

      { _id: 5, full_name: 'Turquoise', colours: ['darkturquoise', 'darkcyan', 'cadetblue', 'mediumaquamarine', 'mediumturquoise', 'turquoise'] },

      { _id: 6, full_name: 'Coral', colours: ['salmon', 'tomato', 'coral', 'lightcoral'] },

      { _id: 7, full_name: 'Light Blue', colours: ['skyblue', 'paleturquoise', 'lightskyblue', 'lightsteelblue', 'lightblue', 'aliceblue', 'powderblue'] },

      { _id: 8, full_name: 'Light Purple/Plum', colours: ['violet', 'mediumpurple', 'plum'] },

      { _id: 9, full_name: 'Rosy/Pale Pink', colours: ['mistyrose', 'lightpink', 'palevioletred', 'pink'] },

      { _id: 10, full_name: 'Blue', colours: ['darkblue', 'mediumblue', 'darkslateblue', 'cornflowerblue', 'blue'] },

      { _id: 11, full_name: 'Olive/Green', colours: ['darkgreen', 'forestgreen', 'olive', 'olivedrab', 'darkolivegreen'] },

      { _id: 12, full_name: 'Brown', colours: ['brown', 'saddlebrown', 'sienna'] },

      { _id: 13, full_name: 'Dark Purple', colours: ['darkorchid', 'darkmagenta', 'blueviolet', 'purple'] },

      { _id: 14, full_name: 'Strong Pink/Magenta', colours: ['deeppink', 'fuchsia', 'magenta', 'violet', 'pink', 'hotpink'] },

      { _id: 15, full_name: 'Black', colours: ['black'] },

      { _id: 16, full_name: 'White', colours: ['white'] },

      { _id: 17, full_name: 'Grey', colours: ['thistle', 'lavender'] }
    ]
