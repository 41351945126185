import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  accordionContainer: {
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    boxShadow: 'none',
  },
  accordionHeader: {
    color: '#333',
  },
  accordionSummary: {
    backgroundColor: '#F9F9F9',
    padding: '0'
  },
  accordionOption: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  },
  accordionDetails: {
    flexDirection: 'column',
    maxHeight: '200px', // Adjust the maximum height as needed
    overflowY: 'auto',
    backgroundColor: '#F9F9F9',
    padding: '0',
    paddingBottom: '1rem',
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
  formControlLabel: {
    margin: '0',
    fontFamily: 'Roboto, sans-serif',
  },
  checkbox: {
    padding: '0 8px',
    color: '#777777', // Use the accent color for checkboxes
    '&.Mui-checked': {
      color: '#777777', // Use the accent color for checked state
    },
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    lineHeight: '18.75px',
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    border: '1px solid #777777',
    borderRadius: 3,
    width: 16,
    height: 16,
    backgroundColor: '#fff',

    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'url("/checked_icon.png")',
      backgroundSize: '10px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      content: '""',
    },
  },
}))

function StyledCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  )
}

export default function AccordionSection(props) {
  const classes = useStyles()
  const [active, setActive] = useState(false)

  const onHeaderClick = () => {
    if (props.onHeaderClick !== undefined && !active) {
      props.onHeaderClick()
    }
    setActive(!active)
  }

  const onOptionClick = (option) => {
    props.onOptionClick(option)
  }

  const onSelectAllClick = () => {
    props.onSelectAllClick()
  }

  return (
    <Accordion className={classes.accordionContainer} expanded={active} onChange={onHeaderClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls={`${props.headerText}-content`}
        id={`${props.headerText}-header`}
        className={classes.accordionSummary}
      >
        <div className={classes.headerText}>{props.headerText}</div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {props.selectAllOption !== false && !props.disableSelection && (
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={props.allSelected}
                onChange={onSelectAllClick}
                name="selectAll"
                color="primary"
                className={classes.checkbox}
              />
            }
            label="Select All"
            className={classes.formControlLabel}
          />
        )}
        {props.options !== undefined &&
          props.options !== null &&
          props.options.map((option, index) => (
            <div
              key={`${props.headerText}-accordionOption-${index}`}
              className={classes.accordionOption}
              onClick={!props.disableSelection ? () => onOptionClick(option) : null}
            >
              {!props.disableSelection && (
                <StyledCheckbox
                  checked={option.active}
                  onChange={() => onOptionClick(option)}
                  name={option.name}
                  color="primary"
                  className={classes.checkbox}
                />
              )}
              <Typography>{option.name}</Typography>
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  )
}
