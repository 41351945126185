import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  labelListContainer: {
    marginTop: '20px',
    marginRight: '20px'
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px',
    borderBottom: '1px solid'
  },
  attributeGroup: {
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: '#000000',
    fontSize: '13px'
  },
  labelDictTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    wordBreak: 'break-all',
    fontSize: '13px'
  },
  attribute: {
    display: 'block',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '13px'
  }
}))

export default function LabelDict (props) {
  const classes = useStyles()
  return (
    <div className={classes.labelListContainer}>
      <div className={classes.title}>{props.title}</div>
      {
        Object.entries(props.labels).map(([attributeGroup, attributes]) => {
          return <div className={classes.labelDictTitle}>
            <span className={classes.attributeGroup}>{attributeGroup}:</span>
            {
              attributes.map(attribute => <span className={classes.attribute}>{attribute}</span>)
            }
          </div>
        })
      }
    </div>
  )
}
