import CanvasJSReact from '../../assets/jss/canvasjs.react.js'
import React, {useState, useEffect, useRef} from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import $ from 'jquery'
import datepickerFactory from 'jquery-datepicker'
import ChartistGraph from 'react-chartist'
import withStyles from '@material-ui/core/styles/withStyles'
import AccessTime from '@material-ui/icons/AccessTime'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from '@material-ui/core/Button'
import { Spinner } from 'react-bootstrap'
import VTOSagemakerButtons from '../../components/VTOSagemakerButtons/VTOSagemakerButtons'
import axios from 'axios'
import headers from '../../config/token.js'
import settings from '../../config/setting-values'
import styles from './dashboard.module.css'
import '../../assets/css/Dashboard/Dashboard.scss'
import '../../assets/css/Dashboard/jquery-ui.scss'
import { ReactComponent as AnalyticsYellow } from '../../assets/img/AnalyticsYellow.svg'
import { ReactComponent as AnalyticsBlue } from '../../assets/img/AnalyticsBlue.svg'
import { ReactComponent as AnalyticsGreen } from '../../assets/img/AnalyticsGreen.svg'
import { ReactComponent as AnalyticsPink } from '../../assets/img/AnalyticsPink.svg'
import { ReactComponent as AnalyticsRed } from '../../assets/img/AnalyticsRed.svg'
import { ReactComponent as AnalyticsPurple } from '../../assets/img/AnalyticsPurple.svg'
import authClient from 'services/Auth.js'

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  CTRConversionChart
} from 'variables/charts.jsx'

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx'
import api from '../../api/api'
import {FormControl, MenuItem, Select} from '@material-ui/core'

axios.defaults.withCredentials = false
datepickerFactory($)

const CanvasJSChart = CanvasJSReact.CanvasJSChart

const Dashboard = (props) => {
  const chart1 = useRef(null)
  const chart2 = useRef(null)
  const chart3 = useRef(null)
  const chart4 = useRef(null)
  const chart5 = useRef(null)
  const chart6 = useRef(null)
  const chart7 = useRef(null)
  const chart8 = useRef(null)
  const currentDate = new Date()
  const date = new Date()
  date.setDate(date.getDate() - 7)

  const convertDate = (date) => {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
    )
  }

  const [state, setState] = useState({
    currentAnalytics: null,
    analyticsEnd: convertDate(currentDate),
    analyticsStart: convertDate(date),
    showTables: false,
    showGraphs: false,
    graphData: [],
    currentAnalyticsloading: false,
    graphLoading: false,
    notebookInstanceLoading: false,
    allUsers: [],
    selectedUser: (JSON.parse(localStorage.getItem('user')))._id
  })

  const handleDatetimeChange = async (event) => {
    const dateVal = event.target.value
    setState((prevState) => ({ ...prevState, [event.target.name]: dateVal }))
  }

  const fetchAnalytics = async (startDate, endDate) => {
    axios.defaults.withCredentials = false
    const service = axios.create({
      baseURL: settings.endpoint,
      withCredentials: false,
      headers: headers
    })
    return await service
      .post('/v4/getAnalyticsFormatted', {
        start_date: startDate.split('-').reverse().join('-'),
        end_date: endDate.split('-').reverse().join('-'),
        user_id: state.selectedUser
      })
      .catch(err => console.log(err))
  }

  const calculateGraphs = async () => {
    const dataPoints = []
    const startDate = new Date(state.analyticsStart)
    const endDate = new Date(state.analyticsEnd)
    let startTime = startDate.getTime()
    let controlTime = startTime + 86400000
    let cancelled = false

    while (startTime < endDate.getTime()) {
      if (window.location.pathname !== '/admin/dashboard') {
        cancelled = true
        break
      }
      const startDateUnix = new Date(startTime)
      const controlDateUnix = new Date(controlTime)
      const startDateMm = convertDate(startDateUnix)
      const endDateMm = convertDate(controlDateUnix)

      const results = await fetchAnalytics(startDateMm, endDateMm)
      startTime += 86400000
      controlTime += 86400000
      if (results !== undefined) {
        dataPoints.push(results.data)
      }
    }
    if (!cancelled) {
      setState((prevState) => ({
        ...prevState,
        graphData: dataPoints,
        graphLoading: false,
        showGraphs: true
      }))

    }
  }

  const getAnalytics = async () => {
    const startAndEndDateGiven = state.analyticsEnd !== null && state.analyticsStart !== null
    const startDateSmallerThanEndDate = new Date(state.analyticsStart) <= new Date(state.analyticsEnd)

    if (startAndEndDateGiven && startDateSmallerThanEndDate) {
      setState((prevState) => ({
        ...prevState,
        currentAnalyticsloading: true,
        graphLoading: true
      }))
      const service = axios.create({
        baseURL: settings.endpoint,
        withCredentials: false,
        headers: headers
      })
      const response = await service
        .post('/v4/getAnalyticsFormatted', {
          start_date: state.analyticsStart.split('-').reverse().join('-'),
          end_date: state.analyticsEnd.split('-').reverse().join('-'),
          user_id: state.selectedUser
        })
        .catch(err => console.log(err))
      if (response !== undefined) {
        setState((prevState) => ({
          ...prevState,
          currentAnalytics: response.data,
          currentAnalyticsloading: false
        }))
        calculateGraphs()
      }
    }
  }

  const handleGetDataClick = async (event) => {
    event.preventDefault()
    await getAnalytics()
  }

  const getDatesBetween = (startDate, endDate) => {
    const dates = []
    let currentDate = new Date(startDate)
    const lastDate = new Date(endDate)

    while (currentDate <= lastDate) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  const transformData = (data, arg) => {
    const dates = getDatesBetween(state.analyticsStart, state.analyticsEnd)
    return data.map((element, index) => ({
      x: dates[index], // Use the generated dates for x points
      y: element[arg]
    }))
  }

  const fetchAllUsers = async () => {
    const signedInUser = JSON.parse(localStorage.getItem('user'))
    const users = await api.getUsers({url: props.url})
    if (signedInUser.access === 'stylist' || signedInUser.access === 'trainee_stylist') {
      const filteredUsers = users.filter((user) => {
        return user._id === signedInUser._id || signedInUser.corporate === true
      })
      setState((prevState) => ({
        ...prevState,
        allUsers: filteredUsers,
      }))
    } else if (signedInUser.corporate === true) {
      setState((prevState) => ({
        ...prevState,
        allUsers: [signedInUser],
      }))
    } else if (signedInUser.corporate === false) {
      const filteredUsers = users.filter((user) => {
        return signedInUser.feSettings.availableUsers.includes(user._id)
      })
      setState((prevState) => ({
        ...prevState,
        allUsers: filteredUsers,
        selectedUser: filteredUsers[0]._id,
      }))
    }
  }

  useEffect(() => {
    const datepicker = document.getElementsByClassName('datepicker')
    const jQuery = $
    for (let i = 0; i < 2; i++) {
      if (datepicker[i].type !== 'date') {
        jQuery(($) => {
          $('#' + datepicker[i].id).datepicker({
            dateFormat: 'yy-mm-dd',
            onSelect: (date) => {
              handleDatetimeChange({
                target: {
                  value: date,
                  name: datepicker[i].name
                }
              })
            }
          })
        })
      }
    }
    jQuery(function ($) {
      $('.ui-helper-clearfix').css('display', 'none')
    })
    fetchAllUsers()
    getAnalytics()
  }, [])
  
  const options1 = {
    axisY: {
      title: 'Outfit Impressions',
      includeZero: false,
      suffix: '',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      stripLines: transformData(
        state.graphData,
        'Outfit Impressions'
      ).map((dataPoint) => ({
        value: dataPoint.x + 0.5,
        lineDashType: 'solid',
        color: '#EEEEEE',
        thickness: 1
      })),
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        lineColor: '#6236FF',
        toolTipContent: 'Date {x}: {y}',
        markerColor: '#6236FF',
        dataPoints: transformData(
          state.graphData,
          'Outfit Impressions'
        )
      }
    ]
  }


  const options2 = {
    axisY: {
      title: 'Outfit CTR',
      includeZero: true,
      suffix: '%',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        toolTipContent: 'Day {x}: {y}%',
        dataPoints: transformData(state.graphData, 'Outfit CTR')
      }
    ]
  }
  const options3 = {
    axisY: {
      title: 'Similarity Impressions',
      includeZero: true,
      suffix: '',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      stripLines: transformData(
        state.graphData,
        'Similarity Impressions'
      ).map((dataPoint) => ({
        value: dataPoint.x + 0.5,
        lineDashType: 'solid',
        color: '#EEEEEE',
        thickness: 1
      })),
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        lineColor: '#6236FF',
        toolTipContent: 'Day {x}: {y}',
        markerColor: '#6236FF',
        dataPoints: transformData(
          state.graphData,
          'Similarity Impressions'
        )
      }
    ]
  }
  const options4 = {
    axisY: {
      title: 'Similarity CTR',
      includeZero: true,
      suffix: '%',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        toolTipContent: 'Day {x}: {y}%',
        dataPoints: transformData(state.graphData, 'Similarity CTR')
      }
    ]
  }
  const options7 = {
    axisY: {
      title: 'Personalisation Impressions',
      includeZero: true,
      suffix: '',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      stripLines: transformData(
        state.graphData,
        'Personalisation Impressions'
      ).map((dataPoint) => ({
        value: dataPoint.x + 0.5,
        lineDashType: 'solid',
        color: '#EEEEEE',
        thickness: 1
      })),        titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        lineColor: '#6236FF',
        markerColor: '#6236FF',
        toolTipContent: 'Day {x}: {y}',
        dataPoints: transformData(
          state.graphData,
          'Personalisation Impressions'
        )
      }
    ]
  }
  const options8 = {
    axisY: {
      title: 'Personalisation CTR',
      includeZero: true,
      suffix: '%',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'column',
        toolTipContent: 'Day {x}: {y}%',
        color: '#E8E7FF',
        mouseOverColor: '#ff0000',
        dataPoints: transformData(state.graphData, 'Personalisation CTR')
      }
    ]
  }

  const options5 = {
    axisY: {
      title: 'Total Amount of Sales',
      includeZero: true,
      suffix: '$',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      stripLines: transformData(
        state.graphData,
        'Total Amount of Sales'
      ).map((dataPoint) => ({
        value: dataPoint.x + 0.5,
        lineDashType: 'solid',
        color: '#EEEEEE',
        thickness: 1
      })),
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'line',
        lineColor: '#6236FF',
        toolTipContent: 'Day {x}: {y}$',
        markerColor: '#6236FF',
        dataPoints: transformData(
          state.graphData,
          'Total Amount of Sales'
        )
      }
    ]
  }

  const options6 = {
    axisY: {
      title: 'Total Number of Sales',
      includeZero: true,
      suffix: '',
      gridColor: '#EEEEEE',
      gridThickness: 1,
      gridDashType: 'solid',
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    axisX: {
      title: 'Day of period',
      interval: 1,
      stripLines: transformData(
        state.graphData,
        'Total Number of Sales'
      ).map((dataPoint) => ({
        value: dataPoint.x + 0.5,
        lineDashType: 'solid',
        color: '#EEEEEE',
        thickness: 1
      })),
      titleFontColor: '#B5B5B5',
      titleFontFamily: 'Roboto, sans-serif',
      titleFontSize: 20,
      lineColor: '#EEEEEE',
    },
    data: [
      {
        type: 'column',
        toolTipContent: 'Day {x}: {y}',
        lineColor: '#6236FF',
        markerColor: '#6236FF',
        dataPoints: transformData(
          state.graphData,
          'Total Number of Sales'
        )
      }
    ]
  }

  function formatDate(dateString) {
    const options = { month: 'short', day: '2-digit' }
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', options)
  }

  const { classes } = props


  if (authClient.isAuthenticated()) {
    return (
      <div>
        <div
          style={{marginBottom: '25px'}}
          className={styles['filters-container']}
        >
          <div className={styles['date-picker-container']}>
            <label htmlFor="start">Start date:</label>
            <input
              className={`form-control datepicker ${styles['dateCustom']}`}
              type="date"
              id="start"
              name="analyticsStart"
              onChange={e => handleDatetimeChange(e)}
              value={state.analyticsStart}
            />
          </div>
          <div className={styles['date-picker-container']}>
            <label htmlFor="start">
              End date:
            </label>
            <input
              className={`form-control datepicker ${styles['dateCustom']}`}
              type="date"
              id="end"
              name="analyticsEnd"
              onChange={e => handleDatetimeChange(e)}
              value={state.analyticsEnd}
            />
          </div>
          <div className={styles['taxonomyRow']}>
            <div className={styles['inputLabel']}>Select Client</div>
            <FormControl className={styles['formControl']}>
              <Select
                value={state.selectedUser}
                onChange={(event) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedUser: event.target.value
                  }))
                }}
                className={styles['select']}
                label="Select Taxonomy"
              >
                {
                  state.allUsers?.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.username}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleGetDataClick}
            className={styles['filters-btn']}
          >
            Get Data
          </Button>
        </div>
        {state.currentAnalyticsloading
          ? <div className={classes.loadingSpinner}>
            <Spinner
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          : state.currentAnalytics && (
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon className={styles['icon-container-yellow']}>
                      <AnalyticsYellow/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Intelistyle AOV</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Intelistyle AOV']
                        ? state.currentAnalytics[
                          'Intelistyle AOV'
                        ].toFixed(2)
                        : 0}{' '}
                      <small>$</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon className={styles['icon-container-red']}>
                      <AnalyticsRed/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Intelistyle Amount of Sales
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics[
                        'Intelistyle Amount of Sales'
                      ]
                        ? state.currentAnalytics[
                          'Intelistyle Amount of Sales'
                        ].toFixed(2)
                        : 0}{' '}
                      <small>$</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon className={styles['icon-container-green']}>
                      <AnalyticsGreen/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Intelistyle Amount of Sales
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Intelistyle Conversion']
                        ? state.currentAnalytics[
                          'Intelistyle Conversion'
                        ].toFixed(2)
                        : 0}{' '}
                      <small>%</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon className={styles['icon-container-purple']}>
                      <AnalyticsPurple/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Intelistyle Number of Sales
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics[
                        'Intelistyle Number of Sales'
                      ]
                        ? state.currentAnalytics[
                          'Intelistyle Number of Sales'
                        ]
                        : 0}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>

                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon className={styles['icon-container-pink']}>
                      <AnalyticsPink/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Intelistyle Sales Percentage
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics[
                        'Intelistyle Sales Percentage'
                      ]
                        ? state.currentAnalytics[
                          'Intelistyle Sales Percentage'
                        ].toFixed(2)
                        : 0}{' '}
                      <small>%</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon className={styles['icon-container-blue']}>
                      <AnalyticsBlue/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Outfit CTR</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Outfit CTR']
                        ? state.currentAnalytics['Outfit CTR'].toFixed(2)
                        : 0}{' '}
                      <small>%</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon className={styles['icon-container-green']}>
                      <AnalyticsGreen/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Outfit Impressions</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Outfit Impressions']
                        ? state.currentAnalytics['Outfit Impressions']
                        : 0}{' '}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon className={styles['icon-container-blue']}>
                      <AnalyticsBlue/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Personalisation CTR</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Personalisation CTR']
                        ? state.currentAnalytics['Personalisation CTR'].toFixed(2)
                        : 0}{' '}
                      <small>%</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="success" stats icon>
                    <CardIcon className={styles['icon-container-green']}>
                      <AnalyticsGreen/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Personalisation Impressions</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Personalisation Impressions']
                        ? state.currentAnalytics['Personalisation Impressions']
                        : 0}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="danger" stats icon>
                    <CardIcon className={styles['icon-container-blue']}>
                      <AnalyticsBlue/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Similarity CTR</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Similarity CTR']
                        ? state.currentAnalytics['Similarity CTR'].toFixed(
                          2
                        )
                        : 0}{' '}
                      <small>%</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon className={styles['icon-container-purple']}>
                      <AnalyticsPurple/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Similarity Impressions
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Similarity Impressions']
                        ? state.currentAnalytics['Similarity Impressions']
                        : 0}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="warning" stats icon>
                    <CardIcon className={styles['icon-container-blue']}>
                      <AnalyticsBlue/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>Total AOV</p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Total AOV']
                        ? state.currentAnalytics['Total AOV'].toFixed(2)
                        : 0}{' '}
                      <small>$</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon className={styles['icon-container-purple']}>
                      <AnalyticsPurple/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Total Amount of Sales
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Total Amount of Sales']
                        ? state.currentAnalytics[
                          'Total Amount of Sales'
                        ].toFixed(2)
                        : 0}{' '}
                      <small>$</small>
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader color="primary" stats icon>
                    <CardIcon className={styles['icon-container-blue']}>
                      <AnalyticsBlue/>
                    </CardIcon>
                    <p className={styles['cardCategory']}>
                    Total Number of Sales
                    </p>
                    <h3 className={styles['cardTitle']}>
                      {/* Handling case that API call does not return all fields */}
                      {state.currentAnalytics['Total Number of Sales']
                        ? state.currentAnalytics['Total Number of Sales']
                        : 0}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={styles['stats']}>
                      {
                        `Activity from ${formatDate(state.analyticsStart)} to ${formatDate(state.analyticsEnd)}`
                      }
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        {state.showTables && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="warning">
                  <ChartistGraph
                    className="ct-chart"
                    data={CTRConversionChart.data}
                    type="Line"
                    options={CTRConversionChart.options}
                    responsiveOptions={CTRConversionChart.responsiveOptions}
                    listener={CTRConversionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>CTR</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime/> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="success">
                  <ChartistGraph
                    className="ct-chart"
                    data={CTRConversionChart.data}
                    type="Line"
                    options={CTRConversionChart.options}
                    responsiveOptions={CTRConversionChart.responsiveOptions}
                    listener={CTRConversionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Conversion</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime/> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="danger">
                  <ChartistGraph
                    className="ct-chart"
                    data={dailySalesChart.data}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>AOV</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime/> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="info">
                  <ChartistGraph
                    className="ct-chart"
                    data={emailsSubscriptionChart.data}
                    type="Line"
                    options={emailsSubscriptionChart.options}
                    responsiveOptions={
                      emailsSubscriptionChart.responsiveOptions
                    }
                    listener={emailsSubscriptionChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>
                    Intelistyle Sales Percentage
                  </h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime/> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card chart>
                <CardHeader color="primary">
                  <ChartistGraph
                    className="ct-chart"
                    data={completedTasksChart.data}
                    type="Line"
                    options={completedTasksChart.options}
                    listener={completedTasksChart.animation}
                  />
                </CardHeader>
                <CardBody>
                  <h4 className={classes.cardTitle}>Intelistyle Sales</h4>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime/> updated 4 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {state.graphLoading
          ? <div className={classes.loadingSpinner}>
            <Spinner
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          : state.showGraphs && (

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Outfit Impressions</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options1}
                      onRef={ref => chart1.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Outfit CTR</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options2}
                      onRef={ref => chart2.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Similarity Impressions</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options3}
                      onRef={ref => chart3.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Similarity CTR</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options4}
                      onRef={ref => chart4.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Personalisation Impressions</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options7}
                      onRef={ref => chart7.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Personalisation CTR</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options8}
                      onRef={ref => chart8.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Total Amount of Sales</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options5}
                      onRef={ref => chart5.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Card>
                  <div>
                    <div className={styles['graph-title-row']}>
                      <h5 className={styles['graph-title']}>Total Number of Sales</h5>
                      <p className={styles['graph-date']}>
                        {`${formatDate(state.analyticsStart)} - ${formatDate(state.analyticsEnd)}`}
                      </p>
                    </div>
                    <CanvasJSChart
                      options={options6}
                      onRef={ref => chart6.current = ref}
                    />
                  </div>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        <hr/>
        <VTOSagemakerButtons/>
      </div>
    )
  } else {
    return <Redirect to="/login"/>
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(Dashboard)
