import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import settings from '../../config/setting-values'


const useStyle = makeStyles({
  carouselContainer: props => ({
    display: 'block',
    width: '100%',
    position: 'relative',
    margin: '0  auto',
    height: `${props.imageHeight}px`
  }),
  carousel: {
    display: 'block',
    margin: '0 auto',
    height: '100%'
  },
  imageContainer: {
    margin: '0 auto'
  },
  image: props => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    height: `${props.imageHeight}px`,
    '& div img': {
      height: `${props.imageHeight}px !important`
    }
  }),
  outOfStock: {
    opacity: 0.5
  },
  outOfStockTextContainer: {
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    backgroundColor: '#DAE0EA',
    width: '100%',
    height: '23px'
  },
  outOfStockText: {
    height: '16px',
    width: '100%',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '19px',
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
})


function ImageCarouselZoom(props) {
  let imageHeight = props.imageHeight ? props.imageHeight : 300

  const styleProps = {
    tight: props.tight,
    imageHeight
  }
  const classes = useStyle(styleProps)

  return (
    <div
      className={classes.carouselContainer}
    >
      <Slider
        lazyLoad={true}
        infinite={true}
        draggable={false}
        prevArrow={<PrevArrow/>}
        nextArrow={<NextArrow/>}
        className={classes.carousel}
      >
        {
          props.images.map((image, index) => {
            // Extract src, with fallback in case s3_url_resized and s3_url fields aren't available
            let src = image.s3_url_resized ?
              image.s3_url_resized :
              image.s3_url ?
                image.s3_url :
                `${settings.s3url}${image.server_image_filename.split('/').slice(-1)[0]}`

            // Extract zoomSrc, with fallback in case s3_url field isn't available
            let zoomSrc = image.s3_url ?
              image.s3_url :
              `${settings.s3url}${image.server_image_filename.split('/').slice(-1)[0]}`

            return (
              <div
                className={`${classes.imageContainer} ${props.outOfStock ? classes.outOfStock : ''}`}
                key={`carousel-image-${index}`}
              >
                <InnerImageZoom
                  className={classes.image}
                  src={src}
                  zoomSrc={zoomSrc}
                  height={styleProps.imageHeight}
                />
              </div>
            )
          })
        }
      </Slider>
      {
        props.outOfStock &&
        <div className={classes.outOfStockTextContainer}>
          <p className={classes.outOfStockText}>OUT OF STOCK</p>
        </div>
      }
    </div>
  )
}

export default ImageCarouselZoom
