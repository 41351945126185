module.exports = {
  'UK Sizes': {
    4: [0, 46, 59, 72],
    6: [1, 47, 60, 73],
    8: [2, 48, 61, 74],
    10: [3, 49, 62, 75],
    12: [4, 50, 63, 76],
    14: [5, 51, 64, 77],
    16: [6, 52, 65, 78],
    18: [7, 53, 66, 79],
    20: [8, 54, 67, 80],
    22: [9, 55, 68, 81],
    24: [10, 56, 69, 82],
    26: [11, 57, 70, 83]
  },
  'Generic Sizes': {
    XXS: [252, 0, 46, 59, 72],
    XS: [253, 1, 47, 60, 73],
    S: [254, 2, 48, 61, 74],
    M: [255, 3, 49, 62, 75],
    L: [256, 4, 50, 63, 76],
    XL: [257, 5, 51, 64, 77],
    XXL: [258, 6, 52, 65, 78]
  },
  Trousers: {
    4: [0],
    6: [1],
    8: [2],
    10: [3],
    12: [4],
    14: [5],
    16: [6],
    18: [7],
    20: [8],
    22: [9],
    24: [10],
    26: [11]
  },
  Bra: {
    '38A': [101],
    '38B': [102],
    '38C': [103],
    '38D': [104],
    '38E': [105],
    '38F': [106],
    '38G': [107],
    '38H': [108],
    '38I': [109],
    '38J': [110],
    '40A': [111],
    '40B': [112],
    '40C': [113],
    '40D': [114],
    '40E': [115],
    '40F': [116],
    '40G': [117],
    '40H': [118],
    '40I': [119],
    '40J': [120],
    '42A': [121],
    '42B': [122],
    '42C': [123],
    '42D': [124],
    '42E': [125],
    '42F': [126],
    '42G': [127],
    '42H': [128],
    '42I': [129],
    '42J': [130],
    '44A': [131],
    '44B': [132],
    '44C': [133],
    '44D': [134],
    '44E': [135],
    '44F': [136],
    '44G': [137],
    '44H': [138],
    '44I': [139],
    '44J': [140],
    '46A': [141],
    '46B': [142],
    '46C': [143],
    '46D': [144],
    '46E': [145],
    '46F': [146],
    '46G': [147],
    '46H': [148],
    '46I': [149],
    '46J': [150],
    '48A': [151],
    '48B': [152],
    '48C': [153],
    '48D': [154],
    '48E': [155],
    '48F': [156],
    '48G': [157],
    '48H': [158],
    '48I': [159],
    '48J': [160],
    '50A': [161],
    '50B': [162],
    '50C': [163],
    '50D': [164],
    '50E': [165],
    '50F': [166],
    '50G': [167],
    '50H': [168],
    '50I': [169],
    '50J': [170],
    '52A': [171],
    '52B': [172],
    '52C': [173],
    '52D': [174],
    '52E': [175],
    '52F': [176],
    '52G': [177],
    '52H': [178],
    '52I': [179],
    '52J': [180],
    '54A': [181],
    '54B': [182],
    '54C': [183],
    '54D': [184],
    '54E': [185],
    '54F': [186],
    '54G': [187],
    '54H': [188],
    '54I': [189],
    '54J': [190],
    '56A': [191],
    '56B': [192],
    '56C': [193],
    '56D': [194],
    '56E': [195],
    '56F': [196],
    '56G': [197],
    '56H': [198],
    '56I': [199],
    '56J': [200],
    '58A': [201],
    '58B': [202],
    '58C': [203],
    '58D': [204],
    '58E': [205],
    '58F': [206],
    '58G': [207],
    '58H': [208],
    '58I': [209],
    '58J': [210],
    '60A': [211],
    '60B': [212],
    '60C': [213],
    '60D': [214],
    '60E': [215],
    '60F': [216],
    '60G': [217],
    '60H': [218],
    '60I': [219],
    '60J': [220],
    '30A': [261],
    '30B': [262],
    '30C': [263],
    '30D': [264],
    '30E': [265],
    '30F': [266],
    '30G': [267],
    '30H': [268],
    '30I': [269],
    '30J': [270],
    '32A': [271],
    '32B': [272],
    '32C': [273],
    '32D': [274],
    '32E': [275],
    '32F': [276],
    '32G': [277],
    '32H': [278],
    '32I': [279],
    '32J': [280],
    '34A': [281],
    '34B': [282],
    '34C': [283],
    '34D': [284],
    '34E': [285],
    '34F': [286],
    '34G': [287],
    '34H': [288],
    '34I': [289],
    '34J': [290],
    '36A': [291],
    '36B': [292],
    '36C': [293],
    '36D': [294],
    '36E': [295],
    '36F': [296],
    '36G': [297],
    '36H': [298],
    '36I': [299],
    '36J': [300],
    '28A': [301],
    '28B': [302],
    '28C': [303],
    '28D': [304],
    '28E': [305],
    '28F': [306],
    '28G': [307],
    '28H': [308],
    '28I': [309],
    '28J': [310]
  },
  Dress: {
    4: [46],
    6: [47],
    8: [48],
    10: [49],
    12: [50],
    14: [51],
    16: [52],
    18: [53],
    20: [54],
    22: [55],
    24: [56],
    26: [57]
  },
  Skirt: {
    4: [59],
    6: [60],
    8: [61],
    10: [62],
    12: [63],
    14: [64],
    16: [65],
    18: [66],
    20: [67],
    22: [68],
    24: [69],
    26: [70]
  },
  Top: {
    4: [72],
    6: [73],
    8: [74],
    10: [75],
    12: [76],
    14: [77],
    16: [78],
    18: [79],
    20: [80],
    22: [81],
    24: [82],
    26: [83]
  },
  Jeans: {
    23: [24],
    24: [25],
    25: [26],
    26: [27],
    27: [28],
    28: [29],
    29: [30],
    30: [31],
    31: [32],
    32: [33]
  },
  Shoes: {
    2: [223],
    2.5: [224],
    3: [225],
    3.5: [226],
    4: [227],
    4.5: [228],
    5: [229],
    5.5: [230],
    6: [231],
    6.5: [232],
    7: [233],
    7.5: [234],
    8: [235],
    8.5: [236]
  },
  'One Size': {
    OS: [251],
    'One Size': [251]
  }
}
