import React from 'react'
import skipIcon from '../../assets/img/skipIcon.png'
import undoIcon from '../../assets/img/undoIcon.png'
import redoIcon from '../../assets/img/redoIcon.png'
import statsIcon from '../../assets/img/statsIcon.png'

export default class OutfitEditorActionBar extends React.Component {
  render() {
    const classes = this.props.styling

    return <div className={classes.actionBar}>
      <p className={classes.outfitsText}>OUTFITS</p>
      <div title="Ctrl-Enter" className={`${this.props.queryGarment && this.props.approveEnabled ? classes.approveContainerSelected : classes.approveContainerDisabled}`} onClick={() => this.props.handleApproveClick()}>
        <div className={classes.approveContainerChild}>
          <i className={`fa fa-check fa-lg ${classes.approveIcon}`} aria-hidden='true' />
          <p className={classes.approveTextSelected}>
            {
              `APPROVE SELECTED OUTFITS (${this.props.outfitSelected.filter(x => x === true).length})`
            }
          </p>
        </div>
      </div>
      <div className={classes.controlButtons}>
        <div className={`${classes.statsContainer} ${classes.buttonHover} ${classes.buttonContainerEnabled}`} onClick={() => this.props.onStatsClick()}>
          {
            <img alt='statsIcon' src={statsIcon} />
          }
          <p>STATS</p>
        </div>
        <div title="Ctrl-Right" className={`${classes.skipContainer} ${this.props.queryGarment && classes.buttonHover} ${this.props.queryGarment ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`} onClick={() => this.props.onSkipClick()}>
          <img alt='skipIcon' src={skipIcon} />
          <p>SKIP</p>
        </div>
        <div title="Ctrl-Z" className={`${classes.undoContainer} ${this.props.queryGarment && classes.buttonHover} ${this.props.queryGarment ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`} onClick={() => this.props.undo()}>
          <img alt='undoIcon' src={undoIcon} />
          <p>UNDO</p>
        </div>
        <div title="Ctrl-Y" className={`${classes.redoContainer} ${this.props.queryGarment && classes.buttonHover} ${this.props.queryGarment ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`} onClick={() => this.props.redo()}>
          <img alt='redoIcon' src={redoIcon} />
          <p>REDO</p>
        </div>
      </div>
    </div>
  }
}
