import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import settings from 'config/setting-values'

const style = theme => ({
  outfitImage: {
    display: 'block',
    margin: 'auto',
    height: '120px'
  },
  outfitImageContainer: {
    display: 'block',
    margin: 'auto'
  },
  outfitContainer: {
    maxWidth: '150px'
  },
  outfitBrand: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px',
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '5px',
    textTransform: 'uppercase'
  },
  outfitTitle: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center'
  }
})

class Outfit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      outfit: props.outfit
    }
  }

  render () {
    const { classes } = this.props
    if (typeof this.state.outfit === 'string') {
      return <div>No available outfit at this moment</div>
    }
    return this.state.outfit.map((value, key) => {
      return (
        <Grid item key={key}>
          <div className={classes.outfitContainer}>
            <div className={classes.outfitImageContainer}>
              <img alt='outfitImage' className={classes.outfitImage} src={settings.s3url.concat('resized/' + value.garment_images[0].server_image_filename.split('/').slice(-1)[0])} />
            </div>
            <Typography className={classes.outfitBrand} component='p'>
              {value.remote_brand}
            </Typography>
            <Typography className={classes.outfitTitle} component='p'>
              {value.remote_product_title.split(' ').slice(0, 5).join(' ')}
            </Typography>
          </div>
        </Grid>
      )
    })
  }
}

export default withStyles(style)(Outfit)
