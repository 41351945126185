import React from 'react'

import '../../../assets/css/WearThatSearch/Bodytype.scss'

const Bodytype = ({ selectFilter, chooseImage, bodytype_ids_restriction }) => {
  const filterOptions = {
    0: 'Hourglass',
    1: 'Apple',
    2: 'Pear',
    3: 'Inverted-Triangle',
    4: 'Rectangle'
  }

  const options = []
  for (const key of Object.keys(filterOptions)) {
    options.push(
      <div key={key} className='list-view-element bodytype-cards'>
        <img
          src={chooseImage(filterOptions[key])}
          alt={filterOptions[key]}
          onClick={e => selectFilter(e)}
          name='bodytype_ids_restriction'
          value={key}
          key={key}
          style={{
            border: bodytype_ids_restriction.includes(key) && '1px solid grey',
            backgroundColor:
              bodytype_ids_restriction.includes(key) && 'lightgrey'
          }}
        />
      </div>
    )
  }

  return <div className='card-view-box bodytype-wrapper'>{options}</div>
}

export default Bodytype
