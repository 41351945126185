module.exports = {
  'b24d120730f29c39cb5c960d19172bf957811faef256df3dcb8dd715b9e2cb29': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.9104032516479492,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.26014450895786284,
                'left': 0.3243374228477478,
                'top': 0.202,
                'width': 0.324827366942042
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 7,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Shorts',
                'template_name': 'Shorts',
                'translations': null
              },
              'label': 7,
              'position': {
                'height': 0.16270569801330567,
                'left': 0.36516788601875305,
                'top': 0.46,
                'width': 0.24377843737602234
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.14,
                'left': 0.45396959459459457,
                'top': 0.784,
                'width': 0.10510510510510511
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': '',
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/3f8b92b53d89e15fece1123316cd93e81614073182142.bin',
        'remote_image_url_small': null,
        'server_image_filename': '3f8b92b53d89e15fece1123316cd93e81614073182142.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '599ae42959a1d7fea4283609',
          'results': [
            {
              'remote_product_title': 'Dark green curved hem T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'd9b44c6af6c32b15f563350f65b42ee61657e195.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Big and Tall green curved hem T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'cd3082281b48adc49f395e864c775548b9e8776c.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Print T-shirt ',
              'remote_brand': 'Urban Classics',
              'garment_images': [
                {
                  'server_image_filename': '318cef5c7c9e6c72393b4979a3296804691fb5ce.jpg'
                }
              ],
              'price': 14.99
            },
            {
              'remote_product_title': 'Big and Tall dark green embroidered T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '8467c6187a894baf1d0367a015f0681214550c79.jpg'
                }
              ],
              'price': 12
            },
            {
              'remote_product_title': 'Khaki rose chest embroidered slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'bee6d1e9fd5374a082484775a02041c569b13ab3.jpg'
                }
              ],
              'price': 12
            },
            {
              'remote_product_title': 'Green rolled sleeve pocket T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'dbbf734e2d4a0b7f281659adc6b531425073039a.jpg'
                }
              ],
              'price': 3
            },
            {
              'remote_product_title': 'Dark green rolled sleeve pocket T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'ef1914857970a571ec59551d0a40807be19178da.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Khaki green ‘Amsterdam’ slim longline T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '818998992b12c2bb4f3475b1db3f71cacb06aba0.jpg'
                }
              ],
              'price': 12
            }
          ]
        },
        {
          '_id': '599feeef3787ab254c2d6b81',
          'results': [
            {
              'remote_product_title': 'Denim shorts ',
              'remote_brand': 'TOM TAILOR DENIM',
              'garment_images': [
                {
                  'server_image_filename': '540c9757ef3239fec5ba139b088dc53520ee8970.jpg'
                }
              ],
              'price': 27.99
            },
            {
              'remote_product_title': 'WESK - Denim shorts - azul medio',
              'remote_brand': 'Tiffosi',
              'garment_images': [
                {
                  'server_image_filename': '4eaa03a4cc3ea19da37f9ca1518b80fb1d8fc84c.jpg'
                }
              ],
              'price': 20.79
            },
            {
              'remote_product_title': 'LANCE - Denim shorts ',
              'remote_brand': 'LTB',
              'garment_images': [
                {
                  'server_image_filename': 'e3cb4674eb2ca79f7cd7a2b279f58eb622aa6a84.jpg'
                }
              ],
              'price': 23.99
            },
            {
              'remote_product_title': 'JJIRICK JJORIGINAL - Denim shorts ',
              'remote_brand': 'Jack & Jones',
              'garment_images': [
                {
                  'server_image_filename': '1dbdfd43485a22e85c5926371676511687a87041.jpg'
                }
              ],
              'price': 14.99
            },
            {
              'remote_product_title': 'TONG - Denim shorts - blue denim',
              'remote_brand': 'Tiffosi',
              'garment_images': [
                {
                  'server_image_filename': 'fb8ade87459d86011486ddbce1166b58f4dcc4f8.jpg'
                }
              ],
              'price': 17.59
            },
            {
              'remote_product_title': 'TONY - Denim shorts ',
              'remote_brand': 'Samsøe & Samsøe',
              'garment_images': [
                {
                  'server_image_filename': 'fee99ee72953fd3d42c1cde1e32c7138aba6adbe.jpg'
                }
              ],
              'price': 53.99
            },
            {
              'remote_product_title': 'JOSH - Denim shorts ',
              'remote_brand': 'TOM TAILOR',
              'garment_images': [
                {
                  'server_image_filename': 'e7490e2a559e559a56b3e5e4203fc30cf65c0b06.jpg'
                }
              ],
              'price': 34.99
            },
            {
              'remote_product_title': '502 REGULAR TAPER  - Denim shorts ',
              'remote_brand': 'Levi\'s®',
              'garment_images': [
                {
                  'server_image_filename': '7792e0f2be3d051351e6cfe50a73a70b2685370f.jpg'
                }
              ],
              'price': 32.49
            }
          ]
        },
        {
          '_id': '59a88b773787ab254c2e620e',
          'results': [
            {
              'remote_product_title': 'Contrast appliqué sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '791986fdbe72c19d60c4a722fbcf8fc08f7dcce2.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'Canvas sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '11db579222fbd00ccb101d292dabcbf15185b7cb.jpg'
                }
              ],
              'price': 19.99
            },
            {
              'remote_product_title': 'Combined design sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'c629a405a6cd7dd6a5c6b7a214dd19f7eed8afa1.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Metallic effect sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '4fbf1d93dc70a454a52a5a3535447a51d5574557.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Snake-effect sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '3d8d677319409567fd62cf8eb0d058fc134599d9.jpg'
                }
              ],
              'price': 24.99
            },
            {
              'remote_product_title': 'Hi Star distressed glittered leather sneakers',
              'remote_brand': 'Golden Goose Deluxe Brand',
              'garment_images': [
                {
                  'server_image_filename': '8f4796a0a4a2dcbafaafffd7eedc630348e39033.jpg'
                }
              ],
              'price': 335
            },
            {
              'remote_product_title': 'Star Lace-Up Trainers',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': 'ae8bb75829a1a8f7a22cd944b1933f02045b817a.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Mesh sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '0d490fabb9e57c186f046244f9a14fbdbd40f6ee.jpg'
                }
              ],
              'price': 35.99
            }
          ]
        }
      ],
      'ranking': {},
      'bodytype': []
    }
  },
  '26af24b61dd5b55883a999ebd093ee55051f55e6d6d75afc0201a9012886d698': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.9341293573379517,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.3543844603300095,
                'left': 0.3286976439790576,
                'top': 0.16361553966999054,
                'width': 0.590224184290901
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                'name': 'Jeans',
                '_id': 6,
                'approved': 0,
                'model_confidence': 1
              },
              'label': '5',
              'position': {
                'height': 0.4001393163204193,
                'left': 0.3907819986343384,
                'top': 0.4898606836795807,
                'width': 0.4287501573562622
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 31,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Bags',
                'template_name': 'Bags',
                'translations': null
              },
              'label': 13,
              'position': {
                'height': 0.256,
                'left': 0,
                'top': 0.568,
                'width': 0.5381217277486913
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9163482785224915,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.158,
                'left': 0.3708423376083374,
                'top': 0.826,
                'width': 0.6123055681508249
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': '',
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/c3db756b6a0649733baf21a286b4b40f1614013402310.bin',
        'remote_image_url_small': null,
        'server_image_filename': 'c3db756b6a0649733baf21a286b4b40f1614013402310.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '5c8a3c35ad911a6d822ab392',
          'results': [
            {
              'remote_product_title': '\'Orchid\' geometric embroidered wide sleeve top - Elizabeth and James - Blue',
              'remote_brand': 'Elizabeth and James',
              'garment_images': [
                {
                  'server_image_filename': 'a43100597dbdeff3e35cb6a14d50a91335690e8c.jpg'
                }
              ],
              'price': 3400
            },
            {
              'remote_product_title': 'Joules Navy Printed Georgina Woven Blouse',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '3668bc2bc11b750c2309ee9f8e8f6011f1bafbcd.jpg'
                }
              ],
              'price': 50
            },
            {
              'remote_product_title': 'Cowl Neck Blouse',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '536b11393c1897fd7644a18f040e739721087493.jpg'
                }
              ],
              'price': 28
            },
            {
              'remote_product_title': 'Abercrombie & Fitch Navy Floral Print Ruffle Blouse',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': 'ccd948fc0c50bc82d9a7eaa23be7775a170ab36b.jpg'
                }
              ],
              'price': 60
            },
            {
              'remote_product_title': 'Girls On Film Printed Pussy Bow Top',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '3cd36eee682d2a89057c82d825541b4e8712f643.jpg'
                }
              ],
              'price': 24
            },
            {
              'remote_product_title': 'Angeleye Curve Cold Shoulder Printed Top',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '4dc5a647312f209f7eeb155a52ea776c3cda5147.jpg'
                }
              ],
              'price': 40
            },
            {
              'remote_product_title': 'Joules Navy Poppy Rosamund Printed Woven Blouse',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '1bf4314aa204b993ba135626506d22c51a5f52c9.jpg'
                }
              ],
              'price': 60
            },
            {
              'remote_product_title': 'Floral-print crepe de chine wrap blouse',
              'remote_brand': 'Marni',
              'garment_images': [
                {
                  'server_image_filename': 'aec3167defaf25a647a5e5b4a948769788f93fa4.jpg'
                }
              ],
              'price': 880
            }
          ]
        },
        {
          '_id': '5a648f7737c561696111d2bc',
          'results': [
            {
              'remote_product_title': 'Leigh Release Hem Skinny Jeans',
              'remote_brand': 'TOPSHOP',
              'garment_images': [
                {
                  'server_image_filename': '67e1e16dc8f5191ea3f913b339aa9dea44055413.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Deep Willows Farrah Jeans',
              'remote_brand': 'AG',
              'garment_images': [
                {
                  'server_image_filename': 'c4d8e93f6cbfb3e01394cc87583f5ae2480beb96.jpg'
                }
              ],
              'price': 215
            },
            {
              'remote_product_title': 'Heather High Waist Crop Skinny Jeans',
              'remote_brand': 'ARTICLES OF SOCIETY',
              'garment_images': [
                {
                  'server_image_filename': '445b27e20f95ea18326db584767eb31165a45131.jpg'
                }
              ],
              'price': 64
            },
            {
              'remote_product_title': 'Luna Mini Split high-rise slim-leg jeans',
              'remote_brand': '3x1',
              'garment_images': [
                {
                  'server_image_filename': '6bbda36188786c82eb032e44ab956086a19c35a6.jpg'
                }
              ],
              'price': 230
            },
            {
              'remote_product_title': 'Maude mid-rise slim-leg jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': 'b21301f6c26f1279a21bfb7e2973824ae5a68f04.jpg'
                }
              ],
              'price': 265
            },
            {
              'remote_product_title': 'Karolina high-rise skinny jeans',
              'remote_brand': 'GRLFRND',
              'garment_images': [
                {
                  'server_image_filename': '96cda53db6cf354f717d5751a21267f9d8756a75.jpg'
                }
              ],
              'price': 245
            },
            {
              'remote_product_title': 'Maria high-rise skinny jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': 'c5e6dc14015a0ccbede9f1aa5d59327090affcb8.jpg'
                }
              ],
              'price': 230
            },
            {
              'remote_product_title': 'Karolina high-rise skinny jeans',
              'remote_brand': 'GRLFRND',
              'garment_images': [
                {
                  'server_image_filename': 'f7e63db7fe78c386e52fbe29d604ee63165a1e6c.jpg'
                }
              ],
              'price': 260
            }
          ]
        },
        {
          '_id': '5afdcbae761482be34b4122a',
          'results': [
            {
              'remote_product_title': 'Leather bucket bag',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '9a930e65aa7da53061c29796d3870c32233484ec.jpg'
                }
              ],
              'price': 79.99
            },
            {
              'remote_product_title': 'Pannier small leather bucket bag',
              'remote_brand': 'Marni',
              'garment_images': [
                {
                  'server_image_filename': '409aa8ada39329b2b7842a0a4ccdccc5c721d604.jpg'
                }
              ],
              'price': 1400
            },
            {
              'remote_product_title': 'Hortensia large leather shoulder bag',
              'remote_brand': 'Wandler',
              'garment_images': [
                {
                  'server_image_filename': '2ed6fd027615b8c70d8f1061e74520a0854e9aee.jpg'
                }
              ],
              'price': 710
            },
            {
              'remote_product_title': 'Earring leather shoulder bag',
              'remote_brand': 'Marni',
              'garment_images': [
                {
                  'server_image_filename': '4e035bd352d9056e6cbb1ee23e751febbefe8b2c.jpg'
                }
              ],
              'price': 1120
            },
            {
              'remote_product_title': 'Mia medium leather tote',
              'remote_brand': 'Wandler',
              'garment_images': [
                {
                  'server_image_filename': '41e84e03681977ca321304231fe016d1dbcb7d3d.jpg'
                }
              ],
              'price': 580
            },
            {
              'remote_product_title': 'Pixie suede and textured-leather shoulder bag',
              'remote_brand': 'Chloé',
              'garment_images': [
                {
                  'server_image_filename': '88fe0caee1cf75ee5a559b39cf8d4df35bfa9e50.jpg'
                }
              ],
              'price': 1020
            },
            {
              'remote_product_title': 'Joan mini suede-paneled textured-leather shoulder bag',
              'remote_brand': 'See By Chloé',
              'garment_images': [
                {
                  'server_image_filename': '2991fab61d50df30ca6c6835eb849b3e748fa5b1.jpg'
                }
              ],
              'price': 260
            },
            {
              'remote_product_title': 'Pannier small striped leather bucket bag',
              'remote_brand': 'Marni',
              'garment_images': [
                {
                  'server_image_filename': 'b6e472bc6de2a40328fea7b89a13a8657a39fed4.jpg'
                }
              ],
              'price': 1520
            }
          ]
        },
        {
          '_id': '5a38613b5ee5c8a69c9e93c2',
          'results': [
            {
              'remote_product_title': 'Sandals ',
              'remote_brand': 'Zign',
              'garment_images': [
                {
                  'server_image_filename': '3fd6a0576df03f6ab1878de1fb637b5f96f121c9.jpg'
                }
              ],
              'price': 35.74
            },
            {
              'remote_product_title': 'HOMEY - Sandals ',
              'remote_brand': 'New Look',
              'garment_images': [
                {
                  'server_image_filename': 'deb1eaf1f017ff9a10596bed7b17fc28945e3a54.jpg'
                }
              ],
              'price': 22.39
            },
            {
              'remote_product_title': 'Nu Pieds woven leather slides',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': 'c1a8a2af52c5d2d2cdce4ea867c24450ef8c0ae2.jpg'
                }
              ],
              'price': 470
            },
            {
              'remote_product_title': 'Alethea metallic leather slingback sandals',
              'remote_brand': 'Ancient Greek Sandals',
              'garment_images': [
                {
                  'server_image_filename': '2af9228228e7dad5e0aac10ff81596c06b7fd2f4.jpg'
                }
              ],
              'price': 135
            },
            {
              'remote_product_title': 'GOAL - Platform sandals ',
              'remote_brand': 'New Look',
              'garment_images': [
                {
                  'server_image_filename': '83e22fec5f27cf6d2e802d9c5664b589ef37e090.jpg'
                }
              ],
              'price': 19.49
            },
            {
              'remote_product_title': 'Atonetta embellished patent-leather slides',
              'remote_brand': 'Christian Louboutin',
              'garment_images': [
                {
                  'server_image_filename': '8c98eb45acf1da5ddf4823fe9891bd0151bfa476.jpg'
                }
              ],
              'price': 385
            },
            {
              'remote_product_title': 'Nu Pieds woven croc-effect leather slides',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': '0b66b52703076c573d0fbd03bcbf96268bc1a568.jpg'
                }
              ],
              'price': 470
            },
            {
              'remote_product_title': 'Logo-embellished patent-leather sandals',
              'remote_brand': 'Dolce & Gabbana',
              'garment_images': [
                {
                  'server_image_filename': '510a93d17da31fb21d17211e87eafc3a0e748720.jpg'
                }
              ],
              'price': 385
            }
          ]
        }
      ],
      'ranking': {},
      'bodytype': []
    }
  },
  '0769475be239e0f9fc0dbc49ca840dd1b1aa65e517170f2a5996a18dd0f47c90': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.881484866142273,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.10306310653686523,
                'left': 0.28856217861175537,
                'top': 0.8890396356582642,
                'width': 0.3929269313812256
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Shirts-Blouses',
                'template_name': 'Shirts-Blouses',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.226,
                'left': 0.26756298449612403,
                'top': 0.176,
                'width': 0.4806201550387597
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 8,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Skirts',
                'template_name': 'Skirts',
                'translations': null
              },
              'label': 6,
              'position': {
                'height': 0.442,
                'left': 0.023376937984496124,
                'top': 0.39,
                'width': 0.9224806201550387
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'unnamed.jpg',
        'position': '',
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/785d41dfed96bbdc46c91076ebfd65bc1614097214172.jpeg',
        'remote_image_url_small': null,
        'server_image_filename': '785d41dfed96bbdc46c91076ebfd65bc1614097214172.jpeg',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'ranking': {},
      'bodytype': [],
      'garments': [
        {
          '_id': '59a88b773787ab254c2e620e',
          'results': [
            {
              'remote_product_title': 'Contrast appliqué sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '791986fdbe72c19d60c4a722fbcf8fc08f7dcce2.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'Canvas sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '11db579222fbd00ccb101d292dabcbf15185b7cb.jpg'
                }
              ],
              'price': 19.99
            },
            {
              'remote_product_title': 'Combined design sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'c629a405a6cd7dd6a5c6b7a214dd19f7eed8afa1.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Metallic effect sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '4fbf1d93dc70a454a52a5a3535447a51d5574557.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Snake-effect sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '3d8d677319409567fd62cf8eb0d058fc134599d9.jpg'
                }
              ],
              'price': 24.99
            },
            {
              'remote_product_title': 'Hi Star distressed glittered leather sneakers',
              'remote_brand': 'Golden Goose Deluxe Brand',
              'garment_images': [
                {
                  'server_image_filename': '8f4796a0a4a2dcbafaafffd7eedc630348e39033.jpg'
                }
              ],
              'price': 335
            },
            {
              'remote_product_title': 'Star Lace-Up Trainers',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': 'ae8bb75829a1a8f7a22cd944b1933f02045b817a.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Mesh sneakers',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '0d490fabb9e57c186f046244f9a14fbdbd40f6ee.jpg'
                }
              ],
              'price': 35.99
            }
          ]
        },
        {
          '_id': '59a88b573787ab254c2e5f86',
          'results': [
            {
              'remote_product_title': 'Ruffle cotton t-shirt',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '1889cf832931397945d9f33fe6d55832e9f39742.jpg'
                }
              ],
              'price': 9.99
            },
            {
              'remote_product_title': 'Embellished stretch-cotton jersey top',
              'remote_brand': 'Carven',
              'garment_images': [
                {
                  'server_image_filename': '2b8f1b21655a4525950f73e9f93d0d5ccb06d7ab.jpg'
                }
              ],
              'price': 200
            },
            {
              'remote_product_title': 'CHARLOTTE - Print T-shirt - white',
              'remote_brand': 'Finery',
              'garment_images': [
                {
                  'server_image_filename': '753a1055e3ca585e2ae7a49ca66f98e1ac92e4eb.jpg'
                }
              ],
              'price': 38.99
            },
            {
              'remote_product_title': 'Oversized fringed cotton-jersey T-shirt ',
              'remote_brand': 'Marques\' Almeida',
              'garment_images': [
                {
                  'server_image_filename': 'f750b2de57289f7f9d39a7bb4c81d5905b260299.jpg'
                }
              ],
              'price': 210
            },
            {
              'remote_product_title': 'Contrast ruffle t-shirt',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'ac9188b6e068069cbe328192d129da4059f65885.jpg'
                }
              ],
              'price': 8.99
            },
            {
              'remote_product_title': 'Ruffled sleeve t-shirt',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '62ee0dd2cb1a61a9372b47778c2333845135cdea.jpg'
                }
              ],
              'price': 17.99
            },
            {
              'remote_product_title': 'MILOBURY - Basic T-shirt ',
              'remote_brand': 'American Vintage',
              'garment_images': [
                {
                  'server_image_filename': '8b8f426b6c638a92c8aed7e0a5423a82f01f2480.jpg'
                }
              ],
              'price': 43.99
            },
            {
              'remote_product_title': 'Tulle-trimmed cotton-jersey T-shirt',
              'remote_brand': 'Noir Kei Ninomiya',
              'garment_images': [
                {
                  'server_image_filename': '6db8b22483f1c0096f3c23b023f672321722cb69.jpg'
                }
              ],
              'price': 180
            }
          ]
        },
        {
          '_id': '5a60e0ba37c5616961115613',
          'results': [
            {
              'remote_product_title': 'Coast Pink Harrie Soft Skirt',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '01690043fbcf5f290b5f699e96797016b89c06d8.jpg'
                }
              ],
              'price': 99
            },
            {
              'remote_product_title': 'Coast Blue Harrie Soft Skirt',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '7b9d473d63619105500b8316ca13e6c39488a681.jpg'
                }
              ],
              'price': 99
            },
            {
              'remote_product_title': 'Pleated Skirt',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '3825249011564ef82640f869e943a7d01c609b5d.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'Ruffle-trimmed satin maxi skirt ',
              'remote_brand': 'Paco Rabanne',
              'garment_images': [
                {
                  'server_image_filename': '8d2579f782bf35e85f5ab28a2049c29f58045f65.jpg'
                }
              ],
              'price': 330
            },
            {
              'remote_product_title': 'Alex Evenings Skirt, Long Tiered',
              'remote_brand': 'Alex Evenings',
              'garment_images': [
                {
                  'server_image_filename': '8abedd3a20e3a80734971ae64f060e2ce5df20db.jpg'
                }
              ],
              'price': 69
            },
            {
              'remote_product_title': 'Satin maxi skirt',
              'remote_brand': 'Theory',
              'garment_images': [
                {
                  'server_image_filename': '98a3572a1e4e4b648f2a822c2eab29b1680ce2e1.jpg'
                }
              ],
              'price': 345
            },
            {
              'remote_product_title': 'Sequin Miniskirt',
              'remote_brand': 'BP.',
              'garment_images': [
                {
                  'server_image_filename': '005b6498a35582a74979a42025022271fc231ad1.jpg'
                }
              ],
              'price': 29.4
            },
            {
              'remote_product_title': 'Blue sequin embellished side split maxi skirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '185c45319371a5fcb8b6a772d0eeac157e517ae7.jpg'
                }
              ],
              'price': 40
            }
          ]
        }
      ]
    }
  },
  '744f40431a7fd88ba695b4879c29b6f650bfce3232652df5bb2ecc6c34c88022': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 6,
                'approved': 0,
                'model_confidence': 0.5834903717041016,
                'name': 'Jeans',
                'template_name': 'Jeans',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.43673351407051086,
                'left': 0.32450488209724426,
                'top': 0.45826515555381775,
                'width': 0.3069743812084198
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.7955891489982605,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.296,
                'left': 0.3061501979827881,
                'top': 0.17,
                'width': 0.3978157043457031
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9057165384292603,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.14307188987731934,
                'left': 0.42039692401885986,
                'top': 0.8307080864906311,
                'width': 0.1307774782180786
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': '',
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/fa2f40325fa7d856f2f67f046c19f0491614073300523.bin',
        'remote_image_url_small': null,
        'server_image_filename': 'fa2f40325fa7d856f2f67f046c19f0491614073300523.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'ranking': {},
      'bodytype': [],
      'garments': [
        {
          '_id': '5a37d32c5ee5c8a69c9d6635',
          'results': [
            {
              'remote_product_title': 'TIGHT - Jeans Skinny Fit ',
              'remote_brand': 'Cheap Monday',
              'garment_images': [
                {
                  'server_image_filename': 'b0bf1d9f32a513f12f5ca8e98c4ed0f12b0135ec.jpg'
                }
              ],
              'price': 38.49
            },
            {
              'remote_product_title': 'TEPPHAR - Jeans Skinny Fit ',
              'remote_brand': 'Diesel',
              'garment_images': [
                {
                  'server_image_filename': '8a5614c043e2e318dbe34a48d9c1fbcebb56140e.jpg'
                }
              ],
              'price': 127.99
            },
            {
              'remote_product_title': 'Jeans Skinny Fit ',
              'remote_brand': 'YOURTURN',
              'garment_images': [
                {
                  'server_image_filename': '4005de776622c9f7593b57b48c4adf3c905d959d.jpg'
                }
              ],
              'price': 30.99
            },
            {
              'remote_product_title': 'SUPER CORDOZAR IN PLAIN - Jeans Skinny Fit ',
              'remote_brand': 'Mennace',
              'garment_images': [
                {
                  'server_image_filename': 'b3c7b42ffaeff2af74587cff03b20e668d386845.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Jeans Skinny Fit ',
              'remote_brand': 'edc by Esprit',
              'garment_images': [
                {
                  'server_image_filename': 'd8bea329744651f8ed4b1edda473865da087a498.jpg'
                }
              ],
              'price': 17.5
            },
            {
              'remote_product_title': 'PANELED INVERTED BIKER PANTS - Jeans Skinny Fit ',
              'remote_brand': 'Cayler & Sons',
              'garment_images': [
                {
                  'server_image_filename': '9dfccaa3ee73bf2ad1f6061a8d16b0129380fa54.jpg'
                }
              ],
              'price': 39.99
            },
            {
              'remote_product_title': 'SLEENKER - Jeans Skinny Fit ',
              'remote_brand': 'Diesel',
              'garment_images': [
                {
                  'server_image_filename': '9987bb04812c81172d34a560660a1fde1323d3ee.jpg'
                }
              ],
              'price': 42
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            }
          ]
        },
        {
          '_id': '5a37abcb5ee5c8a69c9d0143',
          'results': [
            {
              'remote_product_title': 'Grey muscle fit crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'dde571384e74a1a5583fba739088e915df46bf7a.jpg'
                }
              ],
              'price': 10
            },
            {
              'remote_product_title': 'Grey muscle fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '2c7a6e19a09aa4cf03dd5b21f27160670bdef471.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Big and Tall dark grey crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '63571cfc1355bf97c398e87f9a4e2805eaa717f7.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Grey waffle slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '6cfe463647493867b6151e93a52dcd380b26e9e4.jpg'
                }
              ],
              'price': 7
            },
            {
              'remote_product_title': 'Dark green extreme muscle fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '03a45bdfdb604a81f4486204ad1669a5a15e1063.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Dark grey rose embroidery slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'aaff19e5c09c9fe06ea423dd11696ebab533c827.jpg'
                }
              ],
              'price': 12
            },
            {
              'remote_product_title': 'Black rib muscle fit longline T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '975e1a7d7891f0c87a0b78d4b590e05d1fbed687.jpg'
                }
              ],
              'price': 18
            },
            {
              'remote_product_title': 'Dark grey block waffle panel slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'f0e535bef21faca12f8bc8e862ead87e435231c3.jpg'
                }
              ],
              'price': 20
            }
          ]
        },
        {
          '_id': '5a60d6af37c5616961112ab4',
          'results': [
            {
              'remote_product_title': 'Signature Chelsea Boot',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '7c6fb897f3c1247b72607b24117f8783ca16bfb9.jpg'
                }
              ],
              'price': 85
            },
            {
              'remote_product_title': 'Chelsea Boot',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '1567f307db3083f261bc21f509175ba054769b4e.jpg'
                }
              ],
              'price': 60
            },
            {
              'remote_product_title': 'Black leather brogue chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '35e90b96695e81344e28a7e8f64d7744938e7a18.jpg'
                }
              ],
              'price': 75
            },
            {
              'remote_product_title': 'Chelsea-style Boots',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '48b0642b4f298c7129974bd76cecd5b0949d8a30.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'Black chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'be29aa5acbbf2658fcb0a8c3c98d060dafb3a66b.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Leather chelsea ankle boots',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '13a2557e6e40507e768a1330905255251fc6aa7c.jpg'
                }
              ],
              'price': 119.99
            },
            {
              'remote_product_title': 'Black nubuck leather chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '40279e35fcd64b61a2a5815e355229378a258cfe.jpg'
                }
              ],
              'price': 50
            },
            {
              'remote_product_title': 'Tan leather brogue chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'e16519680a0771d07b02c3be38a2b870fc2adb2a.jpg'
                }
              ],
              'price': 75
            }
          ]
        }
      ]
    }
  },
  'a3fc23ac834e9c066c95316742d286053432b6d87b2937f057b4bead09a762a2': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.9391372799873352,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.268,
                'left': 0.27125,
                'top': 0.164,
                'width': 0.355
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 5,
                'approved': 0,
                'model_confidence': 0.6535679697990417,
                'name': 'Trousers',
                'template_name': 'Trousers',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.4646563231945038,
                'left': 0.3051510453224182,
                'top': 0.42493346333503723,
                'width': 0.2760639786720276
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 31,
                'approved': 0,
                'model_confidence': 1,
                'name': 'Bags',
                'template_name': 'Bags',
                'translations': null
              },
              'label': 13,
              'position': {
                'height': 0.292,
                'left': 0.48875,
                'top': 0.538,
                'width': 0.205
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9206413626670837,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.2096196413040161,
                'left': 0.35125,
                'top': 0.7885014414787292,
                'width': 0.17665099382400512
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': '',
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/611c8326a65a748cefe156f837425e6c1614073389017.bin',
        'remote_image_url_small': null,
        'server_image_filename': '611c8326a65a748cefe156f837425e6c1614073389017.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '59d35898c2527545d98c13a0',
          'results': [
            {
              'remote_product_title': 'Black utility pocket slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '7f7bf96be9897eec6d5e2363e05f74540bc5c2a5.jpg'
                }
              ],
              'price': 18
            },
            {
              'remote_product_title': 'Black muscle fit crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '49c447ca8839c91c49b95f1e3abff37f2dbcd071.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black ribbed muscle fit logo T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'a63860c6cb57db57cdd2cc98fa80e204eba4e7e4.jpg'
                }
              ],
              'price': 20
            },
            {
              'remote_product_title': 'Big and Tall black crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '7764695673a677381b48d411b856e1420f9c68f8.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black waffle muscle fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '9d2947b10f094f3a729a83a47ab65383c29b5d79.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Big and Tall black roll sleeve T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '2760791c8531d11e1a1263aa38af3308cae48df7.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black \\\'entitled\\\' embroidered slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '8b626363d6fe8c7dc60a7d7ed32d3c7b38e59a0d.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Black curved hem longline T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '362abcee1f60f29ef95c105f5c919281f613e66d.jpg'
                }
              ],
              'price': 8
            }
          ]
        },
        {
          '_id': '599fef863787ab254c2d77fa',
          'results': [
            {
              'remote_product_title': 'ONE MYLO - Suit jacket ',
              'remote_brand': 'Selected Homme',
              'garment_images': [
                {
                  'server_image_filename': 'a773dfee0b64594e4cb1cacdc23c92d22c2f974a.jpg'
                }
              ],
              'price': 32.49
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'Casual Friday',
              'garment_images': [
                {
                  'server_image_filename': '166f8c52e820075469be1ae83bee5b93edcce0b3.jpg'
                }
              ],
              'price': 44.99
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'Tommy Hilfiger Tailored',
              'garment_images': [
                {
                  'server_image_filename': '8990ea8f2ae6ce6aac5d6ff4b945637ad44df5ce.jpg'
                }
              ],
              'price': 90.99
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'KIOMI',
              'garment_images': [
                {
                  'server_image_filename': '56d8b6a4fe57bbf21c6c446fd8f999c6e71f5770.jpg'
                }
              ],
              'price': 27.74
            },
            {
              'remote_product_title': 'BLAYR - Suit trousers ',
              'remote_brand': 'JOOP!',
              'garment_images': [
                {
                  'server_image_filename': '4ed47c28f1108faf02023142ffaa2046fdabce9c.jpg'
                }
              ],
              'price': 62.49
            },
            {
              'remote_product_title': 'PAULIE  - Suit trousers - dark grey',
              'remote_brand': 'J.LINDEBERG',
              'garment_images': [
                {
                  'server_image_filename': 'e0b68d2a8a35d16d107270a2ed71b56309db8e52.jpg'
                }
              ],
              'price': 67.49
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'The Kooples',
              'garment_images': [
                {
                  'server_image_filename': '5103e0065fe8775d397af14ac7de323cc3d2c6b0.jpg'
                }
              ],
              'price': 174.99
            },
            {
              'remote_product_title': 'BLAYR - Suit trousers ',
              'remote_brand': 'JOOP!',
              'garment_images': [
                {
                  'server_image_filename': 'bfdd9de21df24006282f202f336d6fcafc228b5d.jpg'
                }
              ],
              'price': 114.99
            }
          ]
        },
        {
          '_id': '5a057c6c8cefa56eca817b89',
          'results': [
            {
              'remote_product_title': 'Briefcase - brown',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '3f157ac03cd62ad0a977675e21100c8dafba3924.jpg'
                }
              ],
              'price': 55.24
            },
            {
              'remote_product_title': 'Briefcase ',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': '86df7f39e6e5677f7c776ec7bf68f0986fec41eb.jpg'
                }
              ],
              'price': 299.99
            },
            {
              'remote_product_title': 'DEFENDER DOUBLE ZICOGNAC - Briefcase ',
              'remote_brand': 'Fossil',
              'garment_images': [
                {
                  'server_image_filename': 'f1367b657abd156999de9e00652e639ed2475015.jpg'
                }
              ],
              'price': 279.99
            },
            {
              'remote_product_title': 'LUCHA - Briefcase ',
              'remote_brand': 'Tiger of Sweden',
              'garment_images': [
                {
                  'server_image_filename': 'c94b831939e436a6a1b32b2163823408b58fa788.jpg'
                }
              ],
              'price': 319.99
            },
            {
              'remote_product_title': 'Mansur Gavriel small briefcase',
              'remote_brand': 'Mansur Gavriel',
              'garment_images': [
                {
                  'server_image_filename': '60c3f5e9af59ea7df4596448c50d2c4a14009105.jpg'
                }
              ],
              'price': 1019
            },
            {
              'remote_product_title': 'Prada classic briefcase',
              'remote_brand': 'Prada',
              'garment_images': [
                {
                  'server_image_filename': '8e213f4990216fedc23bb54a98e8df2a84a7f230.jpg'
                }
              ],
              'price': 1940
            },
            {
              'remote_product_title': 'Briefcase ',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '069b76f5241221657a5f80916b40c4e046d24c9e.jpg'
                }
              ],
              'price': 32.99
            },
            {
              'remote_product_title': 'Paul Smith striped laptop bag',
              'remote_brand': 'Paul Smith',
              'garment_images': [
                {
                  'server_image_filename': '5b7c83bef849792909ef20b9850fbe8102d3c810.jpg'
                }
              ],
              'price': 1173
            }
          ]
        },
        {
          '_id': '5a60daad37c561696111361d',
          'results': [
            {
              'remote_product_title': 'Dune Tan Chicago Chelsea Boot',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': 'cddb35eeb47f4030f123ffb8008cea7d4971be00.jpg'
                }
              ],
              'price': 115
            },
            {
              'remote_product_title': 'Tan brown chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '6c0fa34996a42518792d344582224d31fbe43415.jpg'
                }
              ],
              'price': 50
            },
            {
              'remote_product_title': 'Brown leather chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '64c799ec1fe9d280a837ac42891113eddd65994a.jpg'
                }
              ],
              'price': 30
            },
            {
              'remote_product_title': 'Chelsea-style Boots',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '6dabde2af39ce0f49d159c7183e2c1ba9cd06b4d.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Dark brown leather chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'a14c08733914f910a0faa73e5fed7633c45cfbf7.jpg'
                }
              ],
              'price': 75
            },
            {
              'remote_product_title': 'Tan leather chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'a43f5c6b89a09a7d626e0b0ba0033d3b04a078ca.jpg'
                }
              ],
              'price': 75
            },
            {
              'remote_product_title': 'Black chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'be29aa5acbbf2658fcb0a8c3c98d060dafb3a66b.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Tan suede chelsea boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '8565c6095cbb86191cb554ae319525b047a6c7c8.jpg'
                }
              ],
              'price': 50
            }
          ]
        }
      ],
      'ranking': {},
      'bodytype': []
    }
  },
  'e68c9d20160993629351b4dd5db288ffca13846410248b6b29a23d7c8110811a': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 5,
                'approved': 0,
                'model_confidence': 0.6845327019691467,
                'name': 'Trousers',
                'template_name': 'Trousers',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.3984876871109009,
                'left': 0.2941943407058716,
                'top': 0.43845951557159424,
                'width': 0.3337465524673462
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.7323065996170044,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.27484965324401855,
                'left': 0.266560435295105,
                'top': 0.18944068253040314,
                'width': 0.4914294481277466
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9011170268058777,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.131333589553833,
                'left': 0.2554317116737366,
                'top': 0.8331879377365112,
                'width': 0.2765149474143982
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/18fddc3b338904b35d7f1ca37ec3bd3a1619712378969.bin',
        'remote_image_url_small': null,
        'server_image_filename': '18fddc3b338904b35d7f1ca37ec3bd3a1619712378969.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '599fef163787ab254c2d6f2c',
          'results': [
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'Casual Friday',
              'garment_images': [
                {
                  'server_image_filename': '166f8c52e820075469be1ae83bee5b93edcce0b3.jpg'
                }
              ],
              'price': 44.99
            },
            {
              'remote_product_title': 'ONE MYLO - Suit jacket ',
              'remote_brand': 'Selected Homme',
              'garment_images': [
                {
                  'server_image_filename': 'a773dfee0b64594e4cb1cacdc23c92d22c2f974a.jpg'
                }
              ],
              'price': 32.49
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'Tommy Hilfiger Tailored',
              'garment_images': [
                {
                  'server_image_filename': '8990ea8f2ae6ce6aac5d6ff4b945637ad44df5ce.jpg'
                }
              ],
              'price': 90.99
            },
            {
              'remote_product_title': 'BLAYR - Suit trousers ',
              'remote_brand': 'JOOP!',
              'garment_images': [
                {
                  'server_image_filename': '4ed47c28f1108faf02023142ffaa2046fdabce9c.jpg'
                }
              ],
              'price': 62.49
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'KIOMI',
              'garment_images': [
                {
                  'server_image_filename': '56d8b6a4fe57bbf21c6c446fd8f999c6e71f5770.jpg'
                }
              ],
              'price': 27.74
            },
            {
              'remote_product_title': 'PAULIE  - Suit trousers - dark grey',
              'remote_brand': 'J.LINDEBERG',
              'garment_images': [
                {
                  'server_image_filename': 'e0b68d2a8a35d16d107270a2ed71b56309db8e52.jpg'
                }
              ],
              'price': 67.49
            },
            {
              'remote_product_title': 'Suit trousers ',
              'remote_brand': 'The Kooples',
              'garment_images': [
                {
                  'server_image_filename': '5103e0065fe8775d397af14ac7de323cc3d2c6b0.jpg'
                }
              ],
              'price': 174.99
            },
            {
              'remote_product_title': 'BLAYR - Suit trousers ',
              'remote_brand': 'JOOP!',
              'garment_images': [
                {
                  'server_image_filename': 'bfdd9de21df24006282f202f336d6fcafc228b5d.jpg'
                }
              ],
              'price': 114.99
            }
          ]
        },
        {
          '_id': '599ae27359a1d7fea4283364',
          'results': [
            {
              'remote_product_title': 'Dark green long sleeve polo shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '1a6cc3542b33229dbe7fd203bdafb2db43072246.jpg'
                }
              ],
              'price': 12
            },
            {
              'remote_product_title': 'Polo shirt ',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '95d5afd5cb389850e6863bcca5596a91ad0decf2.jpg'
                }
              ],
              'price': 14.99
            },
            {
              'remote_product_title': 'JPRMERCERIZED SLIM FIT - Polo shirt - black',
              'remote_brand': 'Jack & Jones',
              'garment_images': [
                {
                  'server_image_filename': '1cbdab3398a5f77cabb91d24dc4b114fce9aa81c.jpg'
                }
              ],
              'price': 15.39
            },
            {
              'remote_product_title': 'PASCHA - Polo shirt ',
              'remote_brand': 'BOSS Orange',
              'garment_images': [
                {
                  'server_image_filename': '82a193965e1dcac0bb6b1fd25ffc87951800915e.jpg'
                }
              ],
              'price': 43.99
            },
            {
              'remote_product_title': 'SHORTSLEEVE SLIM FIT - Polo shirt ',
              'remote_brand': 'Lacoste',
              'garment_images': [
                {
                  'server_image_filename': '6723a11d5fa5fd9c1eb3b1c36a447969c1494856.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'CONTRAST COLLAR - Polo shirt ',
              'remote_brand': 'GANT',
              'garment_images': [
                {
                  'server_image_filename': 'e8faeba3a47425a61d3b01590a0971eee80d8730.jpg'
                }
              ],
              'price': 69.99
            },
            {
              'remote_product_title': 'SHORTSLEEVE SLIM FIT - Polo shirt ',
              'remote_brand': 'Lacoste',
              'garment_images': [
                {
                  'server_image_filename': 'd8536fccfb2907d1c6ec6fcd92931b2b3cf3955b.jpg'
                }
              ],
              'price': 63.99
            },
            {
              'remote_product_title': 'Polo shirt ',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '83309fc785f8a9350f5f4e6da3336c3a06591e36.jpg'
                }
              ],
              'price': 12.99
            }
          ]
        },
        {
          '_id': '59da44258f775f8845983a0f',
          'results': [
            {
              'remote_product_title': 'ESSENTIAL FUN 2 - Sports shoes - white/silver metallic/solid grey',
              'remote_brand': 'adidas Performance',
              'garment_images': [
                {
                  'server_image_filename': '5399867c47cd7f7e5eb361b72364e4ffafc1eb6b.jpg'
                }
              ],
              'price': 33.99
            },
            {
              'remote_product_title': 'Air Max 1 nubuck and mesh sneakers',
              'remote_brand': 'Nike',
              'garment_images': [
                {
                  'server_image_filename': '00306e9d61d7afb569e6a810060ffc0e50e4ac91.jpg'
                }
              ],
              'price': 100
            },
            {
              'remote_product_title': 'MILLA - Trainers ',
              'remote_brand': 'Kappa',
              'garment_images': [
                {
                  'server_image_filename': 'cec4bc23bc9e3bf1f9c6f9462d279c5f31fa0d36.jpg'
                }
              ],
              'price': 44.99
            },
            {
              'remote_product_title': 'SUPERSTAR  - Trainers - white/core black',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'e4b180ea7a13e48d94841324ad51ed128ce8e514.jpg'
                }
              ],
              'price': 63.99
            },
            {
              'remote_product_title': 'AIR MAX THEA - Trainers - white',
              'remote_brand': 'Nike Sportswear',
              'garment_images': [
                {
                  'server_image_filename': '1de8a5ab4b8cd84c2f2c2f96c8b5d88c9ae3b3a6.jpg'
                }
              ],
              'price': 119.99
            },
            {
              'remote_product_title': 'COURTVANTAGE - Trainers ',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'c2063d6b3c6caf6b8c1a31edfaa27a5b3f6d8196.jpg'
                }
              ],
              'price': 41.99
            },
            {
              'remote_product_title': 'EQT SUPPORT ADV - Trainers ',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'ba3fa769d108a0f0b49dd0ff30016f7867d66ed2.jpg'
                }
              ],
              'price': 89.99
            },
            {
              'remote_product_title': 'ONPSALLIE - Sports shoes - white',
              'remote_brand': 'Only Play',
              'garment_images': [
                {
                  'server_image_filename': '4ca6245e48ad58303add56908f8ffaea4681aa0d.jpg'
                }
              ],
              'price': 29.69
            }
          ]
        }
      ]
    }
  },
  'f7f37838f3f6b4be387cb06787c32d27fa74370e80aba0db96910db3fd1f1239': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9649460315704346,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.16578423976898193,
                'left': 0.44111549854278564,
                'top': 0.8098400235176086,
                'width': 0.16251742839813232
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 6,
                'approved': 0,
                'model_confidence': 0.5771249532699585,
                'name': 'Jeans',
                'template_name': 'Jeans',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.3669004738330841,
                'left': 0.4142545461654663,
                'top': 0.49627551436424255,
                'width': 0.19114184379577637
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.8816927671432495,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.1013563871383667,
                'left': 0.4884434938430786,
                'top': 0.3881548345088959,
                'width': 0.06954503059387207
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 12,
                'approved': 0,
                'model_confidence': 0.5985673069953918,
                'name': 'Outerwear',
                'template_name': 'Outerwear',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.24165892601013184,
                'left': 0.31694483757019043,
                'top': 0.32239866256713867,
                'width': 0.39198875427246094
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/6e4272635a91178d68ef707aa9b199761619707008865.bin',
        'remote_image_url_small': null,
        'server_image_filename': '6e4272635a91178d68ef707aa9b199761619707008865.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '5f33a94dcd5f6d51cfa30213',
          'results': [
            {
              'remote_product_title': 'Beya textured-leather point-toe flats',
              'remote_brand': 'Nicholas Kirkwood',
              'garment_images': [
                {
                  'server_image_filename': '47702c78c8c89429400d494f0e7c137ad5c544bc.jpg'
                }
              ],
              'price': 395
            },
            {
              'remote_product_title': 'Brixton horsebit-detailed leather collapsible-heel loafers',
              'remote_brand': 'Gucci',
              'garment_images': [
                {
                  'server_image_filename': '4eb4abe08f30278368ad420371ec8871addd612e.jpg'
                }
              ],
              'price': 540
            },
            {
              'remote_product_title': 'ANTONIA - Slip-ons - biscuit',
              'remote_brand': 'Vagabond',
              'garment_images': [
                {
                  'server_image_filename': 'a683c24c0484735a895abd475208f0d3ba59712e.jpg'
                }
              ],
              'price': 51.99
            },
            {
              'remote_product_title': 'Slip-ons ',
              'remote_brand': 'KIOMI',
              'garment_images': [
                {
                  'server_image_filename': '70fe536927a6ca623438bdaf9b669181793a376a.jpg'
                }
              ],
              'price': 41.99
            },
            {
              'remote_product_title': 'HARRIETT - Slip-ons - taupe',
              'remote_brand': 'ALDO',
              'garment_images': [
                {
                  'server_image_filename': '6df48aa25ac7a8989b3d471a0a1a5f8b8175bfc1.jpg'
                }
              ],
              'price': 47.99
            },
            {
              'remote_product_title': '+ Equipment Lynette suede point-toe flats',
              'remote_brand': 'Tabitha Simmons',
              'garment_images': [
                {
                  'server_image_filename': 'd1d2378228a313d37fc5899261fd1161ecb9ddf4.jpg'
                }
              ],
              'price': 510
            },
            {
              'remote_product_title': 'Lorenzo suede point-toe flats',
              'remote_brand': 'Jennifer Chamandi',
              'garment_images': [
                {
                  'server_image_filename': 'bd65293ef64f0ab3a347c2d4e8ca3b9154eb8dcf.jpg'
                }
              ],
              'price': 470
            },
            {
              'remote_product_title': 'Embellished leather point-toe flats',
              'remote_brand': 'Jil Sander',
              'garment_images': [
                {
                  'server_image_filename': '2e39d9d0a9eec08b994cfd85b0e7a6e483ee903e.jpg'
                }
              ],
              'price': 475
            }
          ]
        },
        {
          '_id': '5a60c8de37c5616961111620',
          'results': [
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '5aa9dbeff4603e0676b7fd6a00c95584354ca81d.jpg'
                }
              ],
              'price': 39.99
            },
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '5aa9dbeff4603e0676b7fd6a00c95584354ca81d.jpg'
                }
              ],
              'price': 39.99
            },
            {
              'remote_product_title': 'Beckett Distressed Boyfriend Jeans',
              'remote_brand': 'RAILS',
              'garment_images': [
                {
                  'server_image_filename': '06713920da798c03461058f214ba3e7d2d808af0.jpg'
                }
              ],
              'price': 188
            },
            {
              'remote_product_title': 'William Rast Cotton Ripped Boyfriend Jeans',
              'remote_brand': 'William Rast',
              'garment_images': [
                {
                  'server_image_filename': '2cde5faf23d1c79fa5a9f9c41714aa4e1db1b77d.jpg'
                }
              ],
              'price': 109.5
            },
            {
              'remote_product_title': 'The Perfect Summer high-rise straight-leg jeans',
              'remote_brand': 'Madewell',
              'garment_images': [
                {
                  'server_image_filename': '5ebc73612d8d9b34ddf1e1d6d19455a515f1d8fb.jpg'
                }
              ],
              'price': 110
            },
            {
              'remote_product_title': 'Helena distressed high-rise straight-leg jeans ',
              'remote_brand': 'GRLFRND',
              'garment_images': [
                {
                  'server_image_filename': '21c442c44149a5f7ec9e00aae6340c168bf5dcce.jpg'
                }
              ],
              'price': 280
            },
            {
              'remote_product_title': 'Cameron Crop Boyfriend Jeans',
              'remote_brand': 'LIVERPOOL JEANS COMPANY',
              'garment_images': [
                {
                  'server_image_filename': '38a96377b1127ea4d71c4061f65ee0160059a05c.jpg'
                }
              ],
              'price': 79
            },
            {
              'remote_product_title': '1.state Cotton Ripped Straight-Leg Jeans',
              'remote_brand': '1 STATE',
              'garment_images': [
                {
                  'server_image_filename': '648e37682e12fe44d7c5845856e3f242fe388e49.jpg'
                }
              ],
              'price': 119
            }
          ]
        },
        {
          '_id': '59ae94319b5e928809358a61',
          'results': [
            {
              'remote_product_title': 'CHARLOTTE - Print T-shirt - white',
              'remote_brand': 'Finery',
              'garment_images': [
                {
                  'server_image_filename': '753a1055e3ca585e2ae7a49ca66f98e1ac92e4eb.jpg'
                }
              ],
              'price': 38.99
            },
            {
              'remote_product_title': 'White scoop neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'd4073679650cd45e2ff1112b2dfdf811fb7849dc.jpg'
                }
              ],
              'price': 10
            },
            {
              'remote_product_title': 'MILOBURY - Basic T-shirt ',
              'remote_brand': 'American Vintage',
              'garment_images': [
                {
                  'server_image_filename': '8b8f426b6c638a92c8aed7e0a5423a82f01f2480.jpg'
                }
              ],
              'price': 43.99
            },
            {
              'remote_product_title': '+ Hanes 1960s cotton-jersey T-shirt',
              'remote_brand': 'RE/DONE',
              'garment_images': [
                {
                  'server_image_filename': '71d6017933f255f3f2f7f6d2f3673efd043a602f.jpg'
                }
              ],
              'price': 75
            },
            {
              'remote_product_title': 'Marisa ribbed organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': 'd5a9932e09e4a57afa49a17065bbb384d1019e91.jpg'
                }
              ],
              'price': 30
            },
            {
              'remote_product_title': 'Amalie ribbed stretch-modal jersey T-shirt ',
              'remote_brand': 'calé',
              'garment_images': [
                {
                  'server_image_filename': 'c78291640d926041afcf170d79fd59e3ae89f082.jpg'
                }
              ],
              'price': 65
            },
            {
              'remote_product_title': 'The Tee slub Pima cotton-jersey T-shirt',
              'remote_brand': 'rag & bone',
              'garment_images': [
                {
                  'server_image_filename': '9295ba88d79fe9b11c5e3c0c0c73d7733690b79a.jpg'
                }
              ],
              'price': 80
            },
            {
              'remote_product_title': 'Boyfriend slub cotton-jersey T-shirt',
              'remote_brand': 'ATM Anthony Thomas Melillo',
              'garment_images': [
                {
                  'server_image_filename': '0c35f4ed1ca8740f18e5a960faff5c38e2f17956.jpg'
                }
              ],
              'price': 100
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa30c35',
          'results': [
            {
              'remote_product_title': 'ALARIC - Blazer - black/cream',
              'remote_brand': 'Ralph Lauren',
              'garment_images': [
                {
                  'server_image_filename': '8794f67238efbd5a6592d81481d9ee760ba9da88.jpg'
                }
              ],
              'price': '152.00'
            },
            {
              'remote_product_title': 'Tweed Blazer',
              'remote_brand': 'Lane Crawford',
              'garment_images': [
                {
                  'server_image_filename': 'ee87c6986f11ea41111248baa674bef7d549fabb.jpg'
                }
              ],
              'price': '129.00'
            },
            {
              'remote_product_title': 'Tweed Blazer',
              'remote_brand': 'Ivy & Oak',
              'garment_images': [
                {
                  'server_image_filename': '38d6b580a90ad48d40db49c69ae3d0a6ef2a4ecc.jpg'
                }
              ],
              'price': '129.00'
            },
            {
              'remote_product_title': 'Canaan Blazer',
              'remote_brand': 'Tiger Of Sweden',
              'garment_images': [
                {
                  'server_image_filename': 'f48ebb52537fea29105755e1c007f9c9fbdb3c35.jpg'
                }
              ],
              'price': '129.00'
            },
            {
              'remote_product_title': 'Milly Oversized Wool-Tweed Blazer',
              'remote_brand': 'Stella McCartney',
              'garment_images': [
                {
                  'server_image_filename': '0f3350e023aae9189c346f19a81e41b42db7875d.jpg'
                }
              ],
              'price': '152.00'
            },
            {
              'remote_product_title': 'Houndstooth Wool-Tweed And Silk-Twill Blazer',
              'remote_brand': 'Stella McCartney',
              'garment_images': [
                {
                  'server_image_filename': '6cc97dd72bc14bfee1578540fadeaba08b19394d.jpg'
                }
              ],
              'price': '152.00'
            },
            {
              'remote_product_title': 'Curve Boucle Blazer Jacket',
              'remote_brand': 'QUIZ',
              'garment_images': [
                {
                  'server_image_filename': '30ecf9fdcb3b56f260836d6d4de2a52a7b21e80e.jpg'
                }
              ],
              'price': '152.00'
            }
          ]
        }
      ]
    }
  },
  'e32405e3386513d0ea0d3986d1e32be05151912ef6f84a38450fc871821e1a39': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9542744159698486,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.12014985084533691,
                'left': 0.4337647259235382,
                'top': 0.7955329418182373,
                'width': 0.12091705203056335
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 6,
                'approved': 0,
                'model_confidence': 0.555301308631897,
                'name': 'Jeans',
                'template_name': 'Jeans',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.42678824067115784,
                'left': 0.3424152135848999,
                'top': 0.4166233241558075,
                'width': 0.23038643598556519
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.8905319571495056,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.16461563110351562,
                'left': 0.3655492663383484,
                'top': 0.2311326265335083,
                'width': 0.2164098620414734
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/95bab8a473fbe7b44db295ca336c06561619707039341.bin',
        'remote_image_url_small': null,
        'server_image_filename': '95bab8a473fbe7b44db295ca336c06561619707039341.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '5a38613b5ee5c8a69c9e93c2',
          'results': [
            {
              'remote_product_title': 'Sandals ',
              'remote_brand': 'Zign',
              'garment_images': [
                {
                  'server_image_filename': '3fd6a0576df03f6ab1878de1fb637b5f96f121c9.jpg'
                }
              ],
              'price': 35.74
            },
            {
              'remote_product_title': 'HOMEY - Sandals ',
              'remote_brand': 'New Look',
              'garment_images': [
                {
                  'server_image_filename': 'deb1eaf1f017ff9a10596bed7b17fc28945e3a54.jpg'
                }
              ],
              'price': 22.39
            },
            {
              'remote_product_title': 'Nu Pieds woven leather slides',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': 'c1a8a2af52c5d2d2cdce4ea867c24450ef8c0ae2.jpg'
                }
              ],
              'price': 470
            },
            {
              'remote_product_title': 'Alethea metallic leather slingback sandals',
              'remote_brand': 'Ancient Greek Sandals',
              'garment_images': [
                {
                  'server_image_filename': '2af9228228e7dad5e0aac10ff81596c06b7fd2f4.jpg'
                }
              ],
              'price': 135
            },
            {
              'remote_product_title': 'GOAL - Platform sandals ',
              'remote_brand': 'New Look',
              'garment_images': [
                {
                  'server_image_filename': '83e22fec5f27cf6d2e802d9c5664b589ef37e090.jpg'
                }
              ],
              'price': 19.49
            },
            {
              'remote_product_title': 'Atonetta embellished patent-leather slides',
              'remote_brand': 'Christian Louboutin',
              'garment_images': [
                {
                  'server_image_filename': '8c98eb45acf1da5ddf4823fe9891bd0151bfa476.jpg'
                }
              ],
              'price': 385
            },
            {
              'remote_product_title': 'Nu Pieds woven croc-effect leather slides',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': '0b66b52703076c573d0fbd03bcbf96268bc1a568.jpg'
                }
              ],
              'price': 470
            },
            {
              'remote_product_title': 'Logo-embellished patent-leather sandals',
              'remote_brand': 'Dolce & Gabbana',
              'garment_images': [
                {
                  'server_image_filename': '510a93d17da31fb21d17211e87eafc3a0e748720.jpg'
                }
              ],
              'price': 385
            }
          ]
        },
        {
          '_id': '5a60ca0937c56169611119d3',
          'results': [
            {
              'remote_product_title': 'Boyfriend Low Ripped Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '27da75eaa2cee4eeac4a1f7d75e31a45c583d556.jpg'
                }
              ],
              'price': 39.99
            },
            {
              'remote_product_title': 'Boyfriend Low Ripped Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '27da75eaa2cee4eeac4a1f7d75e31a45c583d556.jpg'
                }
              ],
              'price': 39.99
            },
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'f09a88f0b9a6ac7a52408f66d7a6277a20a66e2e.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'f09a88f0b9a6ac7a52408f66d7a6277a20a66e2e.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Boyfriend Low Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'ad247b2d112f4814fee6b9ef350fdb6a1d34dd49.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Boyfriend Low Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'ad247b2d112f4814fee6b9ef350fdb6a1d34dd49.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Eileen Fisher Cuffed Boyfriend Jeans, Regular & Petite',
              'remote_brand': 'Eileen Fisher',
              'garment_images': [
                {
                  'server_image_filename': '6d8cd284f3a353da5590170fe51ab4042667c0e8.jpg'
                }
              ],
              'price': 178
            },
            {
              'remote_product_title': 'Lucky Brand Distressed Boyfriend Jeans',
              'remote_brand': 'Lucky Brand',
              'garment_images': [
                {
                  'server_image_filename': '70c2c7bded337c20e324b0d33c669ba34eaa7f36.jpg'
                }
              ],
              'price': 89.5
            }
          ]
        },
        {
          '_id': '5abbdd03c62f393c70b267f9',
          'results': [
            {
              'remote_product_title': 'Calvin Klein Belted Trench Coat',
              'remote_brand': 'Calvin Klein',
              'garment_images': [
                {
                  'server_image_filename': '19f485139cf4cba63b95950584b031a191fc6f11.jpg'
                }
              ],
              'price': 159.5
            },
            {
              'remote_product_title': 'Dorothy Perkins Petite Ivory Satin Shower Proof Mac',
              'remote_brand': 'Dorothy Perkins',
              'garment_images': [
                {
                  'server_image_filename': 'f1ed3a42de131d04ca9a608ba1ddd2982cdefb3b.jpg'
                }
              ],
              'price': 89
            },
            {
              'remote_product_title': 'Ashby two-tone cotton-canvas trench coat',
              'remote_brand': 'Tory Burch',
              'garment_images': [
                {
                  'server_image_filename': '1290ac12e2892dcb21e563f215e4b9a7e2ea6c03.jpg'
                }
              ],
              'price': 560
            },
            {
              'remote_product_title': 'Belted cotton and linen-blend trench coat',
              'remote_brand': 'Bassike',
              'garment_images': [
                {
                  'server_image_filename': 'ed5a7b4c62adbcaf9721ae19d7149dd4ec8f6d18.jpg'
                }
              ],
              'price': 650
            },
            {
              'remote_product_title': 'The Westminster Long cotton-gabardine trench coat',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': 'c640ce43c423e2479906fb827ab503b0ae5e3bc2.jpg'
                }
              ],
              'price': 1550
            },
            {
              'remote_product_title': 'Striped cotton-gabardine trench coat',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': 'e7beb0f1578d9641bbb1446bdbed495221b959db.jpg'
                }
              ],
              'price': 1650
            },
            {
              'remote_product_title': 'The Westminster Long cotton-gabardine trench coat',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': '90a8c450cfdd2441f45e03b26ef06432fae2efbe.jpg'
                }
              ],
              'price': 1550
            },
            {
              'remote_product_title': 'The Kensington Long cotton-gabardine trench coat',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': '3ee2978c5c78e88ca5472f793123451a93b7533b.jpg'
                }
              ],
              'price': 1450
            }
          ]
        }
      ]
    }
  },
  '755c0c42a1d668b77e30e1ea290858b62a43e561602be4660ee4b3ebd792248c': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 5,
                'approved': 0,
                'model_confidence': 0.865305483341217,
                'name': 'Trousers',
                'template_name': 'Trousers',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.43679729104042053,
                'left': 0.3765696883201599,
                'top': 0.39114782214164734,
                'width': 0.2079411745071411
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.9126920700073242,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.2342013120651245,
                'left': 0.3269979953765869,
                'top': 0.1957731544971466,
                'width': 0.23089712858200073
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.8955824375152588,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.12604784965515137,
                'left': 0.45660924911499023,
                'top': 0.7912799119949341,
                'width': 0.07732009887695312
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/f05f7cd283be28126ca9b397b2ca33de1619707106040.bin',
        'remote_image_url_small': null,
        'server_image_filename': 'f05f7cd283be28126ca9b397b2ca33de1619707106040.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '59aea0329b5e928809362181',
          'results': [
            {
              'remote_product_title': 'Trousers ',
              'remote_brand': 'Polo Ralph Lauren',
              'garment_images': [
                {
                  'server_image_filename': '38868e4cf7f808f39520c326499952e33a0c3001.jpg'
                }
              ],
              'price': 122.49
            },
            {
              'remote_product_title': 'MILLY TROUSERS - Trousers - beige',
              'remote_brand': 'Weekday',
              'garment_images': [
                {
                  'server_image_filename': 'c4d49a07f4c3431951afad0884e698d47a4e3d09.jpg'
                }
              ],
              'price': 44.99
            },
            {
              'remote_product_title': 'Belted wool-blend gabardine pants',
              'remote_brand': 'Brunello Cucinelli',
              'garment_images': [
                {
                  'server_image_filename': '42954d3e6035d52ae4f342514ff78a3ecb227685.jpg'
                }
              ],
              'price': 980
            },
            {
              'remote_product_title': 'Dark grey tie waist tapered trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'c2acde48cd33a1dfec5a4f9e020d9baca40bb321.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Petite beige D-ring belt tapered trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '84dd230bee6415b4a86b8780cdb7656069d814a5.jpg'
                }
              ],
              'price': 38
            },
            {
              'remote_product_title': 'City twill straight-leg pants',
              'remote_brand': 'Theory',
              'garment_images': [
                {
                  'server_image_filename': 'b9645093e4b400fd4ca8db1df12680f839c0dcdd.jpg'
                }
              ],
              'price': 330
            },
            {
              'remote_product_title': 'Trousers - sahara beige',
              'remote_brand': 'Patrizia Pepe',
              'garment_images': [
                {
                  'server_image_filename': '547a9891fa291582c6257bdb9e9890e2b6a4d2ae.jpg'
                }
              ],
              'price': 118.24
            },
            {
              'remote_product_title': 'Cream tie waist tapered trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'b74262c5b9473f0d11204205dfc11abc5b8257d3.jpg'
                }
              ],
              'price': 15
            }
          ]
        },
        {
          '_id': '59a88b5d3787ab254c2e6009',
          'results': [
            {
              'remote_product_title': 'Niki Striped Cotton Top',
              'remote_brand': 'A.P.C. Atelier DE PRODUCTION ET DE CRÉATION',
              'garment_images': [
                {
                  'server_image_filename': '679fa820a0fadaabe324a9839bb16bc15f67e3ed.jpg'
                }
              ],
              'price': '9.00'
            },
            {
              'remote_product_title': 'Nikki Cutout Top',
              'remote_brand': 'PREEN BY THORNTON BREGAZZI',
              'garment_images': [
                {
                  'server_image_filename': 'facdfc2655bc44fdcb799706051db6f8bb07c4ca.jpg'
                }
              ],
              'price': '10.00'
            },
            {
              'remote_product_title': 'Striped Organic Cotton T-Shirt',
              'remote_brand': 'Bassike',
              'garment_images': [
                {
                  'server_image_filename': '85694f8bd6d29bff89bf9abeb3eee06c99e1bdef.jpg'
                }
              ],
              'price': '19.00'
            },
            {
              'remote_product_title': 'Ruffled Neck Silk Shirt',
              'remote_brand': 'Max Mara',
              'garment_images': [
                {
                  'server_image_filename': 'fbd01335dd6927eb6f64a3f933a96f7342042d79.jpg'
                }
              ],
              'price': '149.00'
            },
            {
              'remote_product_title': 'Embroided Shirt Merino Wool',
              'remote_brand': 'Next',
              'garment_images': [
                {
                  'server_image_filename': '93c13dbcd258c695823b1eccec9aa2b03031e9d3.jpg'
                }
              ],
              'price': '16.00'
            },
            {
              'remote_product_title': 'Striped Turtleneck',
              'remote_brand': 'Frame',
              'garment_images': [
                {
                  'server_image_filename': '55a8472c9ae2ee0627d084444ed393c03c3760ee.jpg'
                }
              ],
              'price': '16.00'
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa2f25d',
          'results': [
            {
              'remote_product_title': 'Trompette embellished leopard-print calf hair pumps ',
              'remote_brand': 'Roger Vivier',
              'garment_images': [
                {
                  'server_image_filename': '74283ade8a1be0901e23a42c1a3ab0b77f190712.jpg'
                }
              ],
              'price': 660
            },
            {
              'remote_product_title': '70 leopard-print calf hair pumps',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': '0eb7768faaefd0192a024ad052cd5aeb1b17c1be.jpg'
                }
              ],
              'price': 650
            },
            {
              'remote_product_title': '55 leopard-print calf hair pumps ',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': 'e09be2bf50882e4e3ecfe5669207fde643771366.jpg'
                }
              ],
              'price': 650
            },
            {
              'remote_product_title': 'Romy 60 Swiss-dot tulle and corded lace pumps',
              'remote_brand': 'Jimmy Choo',
              'garment_images': [
                {
                  'server_image_filename': '429a05bc5ae67cdfb53ba58d0fa4cf68f3293538.jpg'
                }
              ],
              'price': 550
            },
            {
              'remote_product_title': '85 leopard-print calf hair pumps',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': '9ad69c449efdb335c0a0a2a04a8d99d05df39a12.jpg'
                }
              ],
              'price': 625
            },
            {
              'remote_product_title': '105 leopard-print calf hair pumps',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': '66853afbb3bca5c5fd3d2a00e1c13368ae671fee.jpg'
                }
              ],
              'price': 615
            },
            {
              'remote_product_title': 'Anna 55 leopard-print calf hair slingback pumps',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': '06d6a887ddea64e5f1d1c6a696d21d096abb915b.jpg'
                }
              ],
              'price': 590
            },
            {
              'remote_product_title': 'Forever Marilyn tasseled leopard-print calf hair pumps',
              'remote_brand': 'Aquazzura',
              'garment_images': [
                {
                  'server_image_filename': '685f3d14724ac0223d106a94e788b6ea4a20ff85.jpg'
                }
              ],
              'price': 590
            }
          ]
        }
      ]
    }
  },
  '04c657746276fa89e5be0039c101dab4bc627134607c3f9d561719c008c895cc': {
    'garment_images': [
      {
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 11,
                'approved': 0,
                'model_confidence': 0.5648636221885681,
                'name': 'Outerwear',
                'template_name': 'Outerwear',
                'translations': null
              },
              'label': 4,
              'position': {
                'height': 0.260327011346817,
                'left': 0.34215211868286133,
                'top': 0.253294438123703,
                'width': 0.33342862129211426
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 5,
                'approved': 0,
                'model_confidence': 0.5676316022872925,
                'name': 'Trousers',
                'template_name': 'Trousers',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.30185627937316895,
                'left': 0.38775938749313354,
                'top': 0.4927504062652588,
                'width': 0.2269984483718872
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 0,
                'model_confidence': 0.808052122592926,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.23497962951660156,
                'left': 0.4439218044281006,
                'top': 0.2617104649543762,
                'width': 0.11323738098144531
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 0,
                'model_confidence': 0.9024943113327026,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.10593605041503906,
                'left': 0.3985372483730316,
                'top': 0.7666732668876648,
                'width': 0.17839744687080383
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/6c76cb1c26c17afd6d2267464a1178ba1619707135912.bin',
        'remote_image_url_small': null,
        'server_image_filename': '6c76cb1c26c17afd6d2267464a1178ba1619707135912.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '599ae2c759a1d7fea42833e5',
          'results': [
            {
              'remote_product_title': 'Black faux leather racer jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '1da39890ab549afeaa14b39cd8ba34634066f1d9.jpg'
                }
              ],
              'price': 65
            },
            {
              'remote_product_title': 'Black leather biker jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'bb6dfdbfaa1c0ef0d8865a323c89e850e772dd98.jpg'
                }
              ],
              'price': 180
            },
            {
              'remote_product_title': 'Black racer neck premium leather jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'd9d71c32a8d812394a27b26f45c322695b7beeee.jpg'
                }
              ],
              'price': 175
            },
            {
              'remote_product_title': 'Zipped biker jacket',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '0208cb7e1d129f2d57373ab8dda24936f70456d6.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Leather biker jacket',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '2ec84215534e4f33c25af9da2d254a33575b338a.jpg'
                }
              ],
              'price': 169.99
            },
            {
              'remote_product_title': 'Black faux suede racer jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'bcc69bd4879176e3bf168f191d99306a74e127af.jpg'
                }
              ],
              'price': 65
            },
            {
              'remote_product_title': 'Black Only & Sons faux leather hooded jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'e108968423941eea499b7b12c1b52b3d05bca5bb.jpg'
                }
              ],
              'price': 75
            },
            {
              'remote_product_title': 'Big and Tall red faux leather racer jacket',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '0f209bc87036ec640d0c5f6fbaabd84cd1a55326.jpg'
                }
              ],
              'price': 65
            }
          ]
        },
        {
          '_id': '59d35c3ec2527545d98c2513',
          'results': [
            {
              'remote_product_title': 'REVEND SUPER SLIM - Jeans Skinny Fit ',
              'remote_brand': 'G-Star',
              'garment_images': [
                {
                  'server_image_filename': '86f94cf3e0ecb200b9683e46d255689014d1ec34.jpg'
                }
              ],
              'price': 89.99
            },
            {
              'remote_product_title': '502 REGULAR TAPER - Jeans Tapered Fit ',
              'remote_brand': 'Levi\'s®',
              'garment_images': [
                {
                  'server_image_filename': 'a4b01c16f5b678ad9aca9216f73e207966a79a00.jpg'
                }
              ],
              'price': 55.99
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            },
            {
              'remote_product_title': 'FRAME DENIM L\'homme Black Skinny Jeans',
              'remote_brand': 'FRAME DENIM',
              'garment_images': [
                {
                  'server_image_filename': '5d15eede30fbb06c9938e50a2e2b4f58d28d28f0.jpg'
                }
              ],
              'price': 195
            },
            {
              'remote_product_title': 'TEPPHAR - Jeans Skinny Fit ',
              'remote_brand': 'Diesel',
              'garment_images': [
                {
                  'server_image_filename': '8a5614c043e2e318dbe34a48d9c1fbcebb56140e.jpg'
                }
              ],
              'price': 127.99
            }
          ]
        },
        {
          '_id': '599ae2ec59a1d7fea428341f',
          'results': [
            {
              'remote_product_title': 'Big and Tall white waffle crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'c2c24952f8f06be69512f7f6598808ef17ddf700.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'White waffle slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'c1e6b8ce0d16e32dfba3178dcaadfc009e1a9057.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Big and Tall white T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '2ea97ba3a6cdfb8b3ecb2a93a15b6b543aa7f1ac.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'White muscle fit crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '50f9d0fd326316ff330e202daec7ecbf45aa6540.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'White waffle slim fit raglan T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '07fdfccf2f4b84e3e6db77b263203c57241c7654.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'White contrast stitch crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '2d71a7ee17f5e4a2627c9f112af93ed35f4127d1.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'White waffle short sleeve raglan T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '47bdbd246ebe32e13c90ba561f4ea335302c303e.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'White curved hem longline T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '29faeb4122f74e084024c698d40e0b60011e8983.jpg'
                }
              ],
              'price': 8
            }
          ]
        },
        {
          '_id': '59da44258f775f8845983a0f',
          'results': [
            {
              'remote_product_title': 'ESSENTIAL FUN 2 - Sports shoes - white/silver metallic/solid grey',
              'remote_brand': 'adidas Performance',
              'garment_images': [
                {
                  'server_image_filename': '5399867c47cd7f7e5eb361b72364e4ffafc1eb6b.jpg'
                }
              ],
              'price': 33.99
            },
            {
              'remote_product_title': 'Air Max 1 nubuck and mesh sneakers',
              'remote_brand': 'Nike',
              'garment_images': [
                {
                  'server_image_filename': '00306e9d61d7afb569e6a810060ffc0e50e4ac91.jpg'
                }
              ],
              'price': 100
            },
            {
              'remote_product_title': 'MILLA - Trainers ',
              'remote_brand': 'Kappa',
              'garment_images': [
                {
                  'server_image_filename': 'cec4bc23bc9e3bf1f9c6f9462d279c5f31fa0d36.jpg'
                }
              ],
              'price': 44.99
            },
            {
              'remote_product_title': 'SUPERSTAR  - Trainers - white/core black',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'e4b180ea7a13e48d94841324ad51ed128ce8e514.jpg'
                }
              ],
              'price': 63.99
            },
            {
              'remote_product_title': 'AIR MAX THEA - Trainers - white',
              'remote_brand': 'Nike Sportswear',
              'garment_images': [
                {
                  'server_image_filename': '1de8a5ab4b8cd84c2f2c2f96c8b5d88c9ae3b3a6.jpg'
                }
              ],
              'price': 119.99
            },
            {
              'remote_product_title': 'COURTVANTAGE - Trainers ',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'c2063d6b3c6caf6b8c1a31edfaa27a5b3f6d8196.jpg'
                }
              ],
              'price': 41.99
            },
            {
              'remote_product_title': 'EQT SUPPORT ADV - Trainers ',
              'remote_brand': 'adidas Originals',
              'garment_images': [
                {
                  'server_image_filename': 'ba3fa769d108a0f0b49dd0ff30016f7867d66ed2.jpg'
                }
              ],
              'price': 89.99
            },
            {
              'remote_product_title': 'ONPSALLIE - Sports shoes - white',
              'remote_brand': 'Only Play',
              'garment_images': [
                {
                  'server_image_filename': '4ca6245e48ad58303add56908f8ffaea4681aa0d.jpg'
                }
              ],
              'price': 29.69
            }
          ]
        }
      ]
    }
  },
  '82555713e060c4b51e36cb4fd0cd578616e8b104af97e50441201ab883f9df94': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 0,
          'bounding_boxes': []
        },
        'local_image_filename': 'blob',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/5adcc6a3060b76dc19bf1ef72607dc901619707182076.bin',
        'remote_image_url_small': null,
        'server_image_filename': '5adcc6a3060b76dc19bf1ef72607dc901619707182076.bin',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '59d35898c2527545d98c13a0',
          'results': [
            {
              'remote_product_title': 'Black utility pocket slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '7f7bf96be9897eec6d5e2363e05f74540bc5c2a5.jpg'
                }
              ],
              'price': 18
            },
            {
              'remote_product_title': 'Black muscle fit crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '49c447ca8839c91c49b95f1e3abff37f2dbcd071.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black ribbed muscle fit logo T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'a63860c6cb57db57cdd2cc98fa80e204eba4e7e4.jpg'
                }
              ],
              'price': 20
            },
            {
              'remote_product_title': 'Big and Tall black crew neck T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '7764695673a677381b48d411b856e1420f9c68f8.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black waffle muscle fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '9d2947b10f094f3a729a83a47ab65383c29b5d79.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Big and Tall black roll sleeve T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '2760791c8531d11e1a1263aa38af3308cae48df7.jpg'
                }
              ],
              'price': 8
            },
            {
              'remote_product_title': 'Black \\\'entitled\\\' embroidered slim fit T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '8b626363d6fe8c7dc60a7d7ed32d3c7b38e59a0d.jpg'
                }
              ],
              'price': 15
            },
            {
              'remote_product_title': 'Black curved hem longline T-shirt',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '362abcee1f60f29ef95c105f5c919281f613e66d.jpg'
                }
              ],
              'price': 8
            }
          ]
        },
        {
          '_id': '599ae3d159a1d7fea4283582',
          'results': [
            {
              'remote_product_title': 'White skinny chino trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '05573e068546cb8d6ca28eddf97ec9a7a0aed72e.jpg'
                }
              ],
              'price': 12
            },
            {
              'remote_product_title': 'PIQUÉ TROUSERS WITH STRIPE DETAIL',
              'remote_brand': 'Zara',
              'garment_images': [
                {
                  'server_image_filename': '788cf883eec305324781680dfb2c672b6e674596.jpg'
                }
              ],
              'price': 19.99
            },
            {
              'remote_product_title': 'Big and Tall black skinny fit chinos',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'd3bdc2dfed6b422c713d39af437e429888480419.jpg'
                }
              ],
              'price': 25
            },
            {
              'remote_product_title': 'Big and Tall tan slim fit chinos',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'd46286f4d049d5d150403611dbadc3d10daadbd9.jpg'
                }
              ],
              'price': 25
            },
            {
              'remote_product_title': 'Big and Tall light brown skinny chinos',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'ceb51cb146cb1fca4fe69b895f8accc3464417c1.jpg'
                }
              ],
              'price': 25
            },
            {
              'remote_product_title': 'Purple super skinny fit chino trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'bf6a785445b327a76733d4f2301811eb01e16906.jpg'
                }
              ],
              'price': 28
            },
            {
              'remote_product_title': 'Cargo trousers - rich cinnamon',
              'remote_brand': 'TOM TAILOR DENIM',
              'garment_images': [
                {
                  'server_image_filename': 'e5947dce518c9a15397dbc78826cf7271e4d2575.jpg'
                }
              ],
              'price': 40.49
            },
            {
              'remote_product_title': 'Big and Tall navy slim fit chino trousers',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '021314d5cd5a131da89580eb0e0cc3c651a83c6a.jpg'
                }
              ],
              'price': 25
            }
          ]
        },
        {
          '_id': '5a057c6c8cefa56eca817b89',
          'results': [
            {
              'remote_product_title': 'Briefcase - brown',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '3f157ac03cd62ad0a977675e21100c8dafba3924.jpg'
                }
              ],
              'price': 55.24
            },
            {
              'remote_product_title': 'Briefcase ',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': '86df7f39e6e5677f7c776ec7bf68f0986fec41eb.jpg'
                }
              ],
              'price': 299.99
            },
            {
              'remote_product_title': 'DEFENDER DOUBLE ZICOGNAC - Briefcase ',
              'remote_brand': 'Fossil',
              'garment_images': [
                {
                  'server_image_filename': 'f1367b657abd156999de9e00652e639ed2475015.jpg'
                }
              ],
              'price': 279.99
            },
            {
              'remote_product_title': 'LUCHA - Briefcase ',
              'remote_brand': 'Tiger of Sweden',
              'garment_images': [
                {
                  'server_image_filename': 'c94b831939e436a6a1b32b2163823408b58fa788.jpg'
                }
              ],
              'price': 319.99
            },
            {
              'remote_product_title': 'Mansur Gavriel small briefcase',
              'remote_brand': 'Mansur Gavriel',
              'garment_images': [
                {
                  'server_image_filename': '60c3f5e9af59ea7df4596448c50d2c4a14009105.jpg'
                }
              ],
              'price': 1019
            },
            {
              'remote_product_title': 'Prada classic briefcase',
              'remote_brand': 'Prada',
              'garment_images': [
                {
                  'server_image_filename': '8e213f4990216fedc23bb54a98e8df2a84a7f230.jpg'
                }
              ],
              'price': 1940
            },
            {
              'remote_product_title': 'Briefcase ',
              'remote_brand': 'Pier One',
              'garment_images': [
                {
                  'server_image_filename': '069b76f5241221657a5f80916b40c4e046d24c9e.jpg'
                }
              ],
              'price': 32.99
            },
            {
              'remote_product_title': 'Paul Smith striped laptop bag',
              'remote_brand': 'Paul Smith',
              'garment_images': [
                {
                  'server_image_filename': '5b7c83bef849792909ef20b9850fbe8102d3c810.jpg'
                }
              ],
              'price': 1173
            }
          ]
        },
        {
          '_id': '59d358f8c2527545d98c1528',
          'results': [
            {
              'remote_product_title': 'Brown suede cleated sole work boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '0b4acccfc62246ed0fbaddc1981a74b764460c01.jpg'
                }
              ],
              'price': 65
            },
            {
              'remote_product_title': 'Light brown apron toe leather boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '38839c7fd0e7a89ca9b70d6cd1fe1320faa25369.jpg'
                }
              ],
              'price': 30
            },
            {
              'remote_product_title': 'Tan leather and suede toe cap work boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '5dc6ba27ac35554dbed9a0fadd0f76401f41bb1c.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Brown lace-up contrast sole work boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '0e56dd60ae42117144b93e11be6988ae38471bda.jpg'
                }
              ],
              'price': 30
            },
            {
              'remote_product_title': 'Tan brown lace-up leather boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '9841723cc5c0d11b847bfd2c653398edbebca5dd.jpg'
                }
              ],
              'price': 65
            },
            {
              'remote_product_title': 'Brown lace-up work boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'f5091594989d71aa61a8aead9860dab2c4288333.jpg'
                }
              ],
              'price': 50
            },
            {
              'remote_product_title': 'Brown leather military boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': 'a2d6a300ac23a4e7eb9f9e97529df5f65982cc31.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Mid brown leather lace-up work boots',
              'remote_brand': 'River Island',
              'garment_images': [
                {
                  'server_image_filename': '6911fbcce05cce3fc547f99142d8dec6f944328f.jpg'
                }
              ],
              'price': 75
            }
          ]
        }
      ]
    }
  },
  '2a1c00cdddb7db9092000fec9d6839575708d8f55333e88708110fefb99f3b15': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 1,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 12,
                'approved': 1,
                'model_confidence': 0.7902330756187439,
                'name': 'Blazers',
                'template_name': 'Blazers',
                'translations': null
              },
              'label': 4,
              'position': {
                'height': 0.3191501645424105,
                'left': 0.30606060606060603,
                'top': 0.18724696356275303,
                'width': 0.31212121212121213
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 6,
                'approved': 1,
                'model_confidence': 0.6631218791007996,
                'name': 'Jeans',
                'template_name': 'Jeans',
                'translations': null
              },
              'label': 5,
              'position': {
                'height': 0.45420098304748535,
                'left': 0.35131362080574036,
                'top': 0.4151316285133362,
                'width': 0.249860018491745
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 1,
                'model_confidence': 0.9557297825813293,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.20183009995140044,
                'left': 0.32095828652381897,
                'top': 0.7829877138137817,
                'width': 0.36995080438527195
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 31,
                'approved': 1,
                'model_confidence': 1,
                'name': 'Bags',
                'template_name': 'Bags',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.17611336032388664,
                'left': 0.22424242424242424,
                'top': 0.5516194331983806,
                'width': 0.19696969696969696
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 38,
                'approved': 1,
                'model_confidence': 1,
                'name': 'Glasses',
                'template_name': 'Glasses',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.04048582995951417,
                'left': 0.509090909090909,
                'top': 0.13663967611336034,
                'width': 0.12727272727272737
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 1,
                'approved': 1,
                'model_confidence': 1,
                'name': 'Shirts-Blouses',
                'template_name': 'Shirts-Blouses',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.19635627530364372,
                'left': 0.5075757575757576,
                'top': 0.2054655870445344,
                'width': 0.07575757575757576
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'outfit-uploadable.jpg',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/897b78c086adc52cae2660c6402a3db01620139688926.jpeg',
        'remote_image_url_small': null,
        'server_image_filename': '897b78c086adc52cae2660c6402a3db01620139688926.jpeg',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '59ae955f9b5e92880935a09e',
          'results': [
            {
              'remote_product_title': 'Blazer ',
              'remote_brand': 'Mos Mosh',
              'garment_images': [
                {
                  'server_image_filename': '2d9846e96c4af39a1e11d0a0cb61c61d8403e92b.jpg'
                }
              ],
              'price': 129.99
            },
            {
              'remote_product_title': 'Blazer ',
              'remote_brand': 'comma',
              'garment_images': [
                {
                  'server_image_filename': '083e9aa51b0fdd29a604cc1c688f0a894f280bd5.jpg'
                }
              ],
              'price': 91.99
            },
            {
              'remote_product_title': 'AFRONA - Blazer ',
              'remote_brand': 'HUGO',
              'garment_images': [
                {
                  'server_image_filename': '44436468edb1690a21fcd51697be8417c9e0ef8d.jpg'
                }
              ],
              'price': 264.99
            },
            {
              'remote_product_title': 'YASCLADY  - Blazer - black',
              'remote_brand': 'YAS',
              'garment_images': [
                {
                  'server_image_filename': '66c724ee91f83bdb46dc650a88c0bad8d877a96f.jpg'
                }
              ],
              'price': 38.99
            },
            {
              'remote_product_title': 'RIDLEY - Blazer ',
              'remote_brand': 'Sand Copenhagen',
              'garment_images': [
                {
                  'server_image_filename': '674cc86af8bb6724f8cd57e2010571ae409834b7.jpg'
                }
              ],
              'price': 244.99
            },
            {
              'remote_product_title': 'SYDNEY  - Blazer - black',
              'remote_brand': 'Karen by Simonsen',
              'garment_images': [
                {
                  'server_image_filename': '11f2196310076ccdd1b245bbb45d46a5afff6fea.jpg'
                }
              ],
              'price': 104.99
            },
            {
              'remote_product_title': 'CHELSEA  - Blazer - black',
              'remote_brand': 'New Look',
              'garment_images': [
                {
                  'server_image_filename': 'cece7bd6f589843fc5f8dc7d2202604fb9034407.jpg'
                }
              ],
              'price': 29.59
            },
            {
              'remote_product_title': 'Blazer ',
              'remote_brand': 'Filippa K',
              'garment_images': [
                {
                  'server_image_filename': 'ff42abed47ad5f7059ebfe8f829068777b405b0e.jpg'
                }
              ],
              'price': 239.99
            }
          ]
        },
        {
          '_id': '5b620a71ab33052125c76a89',
          'results': [
            {
              'remote_product_title': 'J Brand Alana Cropped Skinny Jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': '36e3bcfdf7fc4550b8e4b08425ebc1f1e12a3055.jpg'
                }
              ],
              'price': 265
            },
            {
              'remote_product_title': 'Dre Capri distressed mid-rise skinny jeans ',
              'remote_brand': 'rag & bone',
              'garment_images': [
                {
                  'server_image_filename': 'fe469403d342f4dd2f978698570091be9aac2425.jpg'
                }
              ],
              'price': 170
            },
            {
              'remote_product_title': 'Ruby 30" high-rise slim-leg jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': '6496a59728a9ea813f930be8c85e5feeb524fdff.jpg'
                }
              ],
              'price': 295
            },
            {
              'remote_product_title': 'J Brand Alana Blue Cropped Skinny Jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': 'f4ee3669409ed0d9a648ede658925f82cf45a838.jpg'
                }
              ],
              'price': 265
            },
            {
              'remote_product_title': 'J Brand Alana Blue Cropped Skinny Jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': 'f4ee3669409ed0d9a648ede658925f82cf45a838.jpg'
                }
              ],
              'price': 265
            },
            {
              'remote_product_title': 'J Brand Alana Blue Cropped Skinny Jeans',
              'remote_brand': 'J Brand',
              'garment_images': [
                {
                  'server_image_filename': 'f4ee3669409ed0d9a648ede658925f82cf45a838.jpg'
                }
              ],
              'price': 265
            },
            {
              'remote_product_title': 'The Looker high-rise skinny jeans',
              'remote_brand': 'Mother',
              'garment_images': [
                {
                  'server_image_filename': '34bb31618e148e0f87e2e0979cec2fdea4091327.jpg'
                }
              ],
              'price': 240
            },
            {
              'remote_product_title': '+ Lily Aldridge Cigarette high-rise straight-leg jeans',
              'remote_brand': 'rag & bone',
              'garment_images': [
                {
                  'server_image_filename': '9e38ece93a074e541d91c78fa49e6ba795c266fd.jpg'
                }
              ],
              'price': 215
            }
          ]
        },
        {
          '_id': '5a37a40b5ee5c8a69c9cf5a9',
          'results': [
            {
              'remote_product_title': 'Pointed toe pumps',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'd767574a1fc7fbb433816c0ac80ec24ed476e3d3.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Asymmetric stiletto shoes',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '1dd53a6f057aebf89a98f6caec36d099452799f9.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': ' ',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'b5cc23f0a8fa3101ce12891876a298b4a9c3ef5f.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'So Kate 120 suede pumps',
              'remote_brand': 'Christian Louboutin',
              'garment_images': [
                {
                  'server_image_filename': '4daf5079376de3b04c17a26f58870c8abea1ac1d.jpg'
                }
              ],
              'price': 525
            },
            {
              'remote_product_title': 'Suede pumps',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '4c360301939d0c42fff97fee89ce2881869c8c4d.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Pumps',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': '0e66c9f31af4f07685cee48363ed638cefcb17b3.jpg'
                }
              ],
              'price': 24.99
            },
            {
              'remote_product_title': '85 suede pumps',
              'remote_brand': 'Gianvito Rossi',
              'garment_images': [
                {
                  'server_image_filename': '4a32f10616ba2d83f929cf8c615591e5aa756eb4.jpg'
                }
              ],
              'price': 510
            },
            {
              'remote_product_title': '+ Roland Mouret Ramour 100 suede and metallic textured-leather pumps',
              'remote_brand': 'Christian Louboutin',
              'garment_images': [
                {
                  'server_image_filename': 'b3bececdfea443cef224035fa7e49ca6b0b382cc.jpg'
                }
              ],
              'price': 765
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa2f53e',
          'results': [
            {
              'remote_product_title': 'Rajah embellished leather shoulder bag',
              'remote_brand': 'Gucci',
              'garment_images': [
                {
                  'server_image_filename': 'bc4f09642439d16570a7f4c2701c6f3f8b5b261a.jpg'
                }
              ],
              'price': 1770
            },
            {
              'remote_product_title': 'Whitney leather shoulder bag',
              'remote_brand': 'Theory',
              'garment_images': [
                {
                  'server_image_filename': '0b5d8f6794113f1fbb24c8f282ebf515e1322157.jpg'
                }
              ],
              'price': 455
            },
            {
              'remote_product_title': 'Natalia small leather shoulder bag ',
              'remote_brand': 'TOM FORD',
              'garment_images': [
                {
                  'server_image_filename': 'a56ccdbfcf107f57e3e70d0cac330bc3f108afef.jpg'
                }
              ],
              'price': 1890
            },
            {
              'remote_product_title': 'Josephine textured-leather shoulder bag',
              'remote_brand': 'Mark Cross',
              'garment_images': [
                {
                  'server_image_filename': 'b2a94d770a1cb161a36d5b372675f28ac4b46d8c.jpg'
                }
              ],
              'price': 995
            },
            {
              'remote_product_title': 'Trunk micro leather shoulder bag',
              'remote_brand': 'Marni',
              'garment_images': [
                {
                  'server_image_filename': '539a468f61d697655571383b803c31fa48aa0f99.jpg'
                }
              ],
              'price': 800
            },
            {
              'remote_product_title': 'Nano Box Bag leather shoulder bag',
              'remote_brand': 'Alexander McQueen',
              'garment_images': [
                {
                  'server_image_filename': '5059f68dadb4d3624f26abd22298880b0a44a068.jpg'
                }
              ],
              'price': 1390
            },
            {
              'remote_product_title': 'Valentino Garavani The Rockstud leather shoulder bag',
              'remote_brand': 'Valentino',
              'garment_images': [
                {
                  'server_image_filename': 'ba5e4a3154c92699cb56712c374b7bf48f0e22dc.jpg'
                }
              ],
              'price': 740
            },
            {
              'remote_product_title': 'Pionnière leather shoulder bag',
              'remote_brand': 'Prada',
              'garment_images': [
                {
                  'server_image_filename': '357ba1fa6babe28fa7ef7d07a4ce5846cf5c9d7a.jpg'
                }
              ],
              'price': 1580
            }
          ]
        },
        {
          '_id': '5ca7cf14ad911a74ac473fa2',
          'results': [
            {
              'remote_product_title': 'Sunglasses - polar grey gradient',
              'remote_brand': 'Emporio Armani',
              'garment_images': [
                {
                  'server_image_filename': '6b6292cb19c55b30fdcb64f2692b84484890fe53.jpg'
                }
              ],
              'price': 134.99
            },
            {
              'remote_product_title': 'Sunglasses - matte black',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': '3b9943fca2da0cbd3ed4f26e6ced58cd853f4733.jpg'
                }
              ],
              'price': 154.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Anna Field',
              'garment_images': [
                {
                  'server_image_filename': '012e5754952a8e3ca0b4a4ba77f0c9dd5c23fe36.jpg'
                }
              ],
              'price': 10.99
            },
            {
              'remote_product_title': 'RETO - Sunglasses - black',
              'remote_brand': 'Jimmy Choo',
              'garment_images': [
                {
                  'server_image_filename': '220f084322f7671d864d58c6441e79ba0f34779c.jpg'
                }
              ],
              'price': 334.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Emporio Armani',
              'garment_images': [
                {
                  'server_image_filename': 'a09080e9f22310db223c9a6a97c1eeaf5d8b19f2.jpg'
                }
              ],
              'price': 154.99
            },
            {
              'remote_product_title': 'GIGI HADID - Sunglasses - black',
              'remote_brand': 'VOGUE Eyewear',
              'garment_images': [
                {
                  'server_image_filename': '2967a765c35e14d851b2204acd1f9f1c2862f99a.jpg'
                }
              ],
              'price': 119.99
            },
            {
              'remote_product_title': 'NAPA - Sunglasses - light grey gradient',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': 'bf8e8595f6af64e745ed7a26036facdedf615d4e.jpg'
                }
              ],
              'price': 164.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': '83c8624700bf250e78faec7de060548f147df99b.jpg'
                }
              ],
              'price': 144.99
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa2fd8f',
          'results': [
            {
              'remote_product_title': 'Faye oversized organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '2ec589873a9fa72684fe36ad8ae739742ee6dad9.jpg'
                }
              ],
              'price': 45
            },
            {
              'remote_product_title': 'Nash Face appliquéd cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': '6e811aa4eb41472b19b57f6e825cd5d89a0fb769.jpg'
                }
              ],
              'price': 100
            },
            {
              'remote_product_title': 'The Victoria cotton-jersey T-shirt',
              'remote_brand': 'Victoria, Victoria Beckham',
              'garment_images': [
                {
                  'server_image_filename': 'ee79752ef52274d055b755c39e55ebc53eba92a5.jpg'
                }
              ],
              'price': 90
            },
            {
              'remote_product_title': 'Organic stretch-cotton jersey T-shirt ',
              'remote_brand': 'Bassike',
              'garment_images': [
                {
                  'server_image_filename': '9a8fbc315fe76632837bcd24ba5935024d5e3984.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Gojina oversized intarsia-trimmed cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': 'fce169d25d26506c97e44c92116cb3f1c632dc97.jpg'
                }
              ],
              'price': 140
            },
            {
              'remote_product_title': 'Jenna organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '74b03bad20e9d550f1deb388543fe4541c6ad242.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Appliquéd cotton-jersey T-shirt ',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': '0287d4290f6b95a54a48fc3f1c340e28748fe6fc.jpg'
                }
              ],
              'price': 220
            },
            {
              'remote_product_title': 'Embroidered cotton and linen-blend jersey T-shirt',
              'remote_brand': 'The Upside',
              'garment_images': [
                {
                  'server_image_filename': 'c112e7ab283fba0b2cb71aaea749371fd37fd190.jpg'
                }
              ],
              'price': 80
            }
          ]
        }
      ]
    }
  },
  'c53cd100ad51accc89a07bfd8c427dc1ab66430c90b8b14ee03dd077fee6bb9b': {
    'garment_images': [
      {
        'bounding_box': [],
        'bounding_box_data': {
          'approved': 1,
          'bounding_boxes': [
            {
              'category_data': {
                '_id': 3,
                'approved': 1,
                'model_confidence': 0.7973712682723999,
                'name': 'Tops',
                'template_name': 'Tops',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.17073196172714233,
                'left': 0.4851846992969513,
                'top': 0.2838774025440216,
                'width': 0.1070769727230072
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 11,
                'approved': 1,
                'model_confidence': 0.6674376726150513,
                'name': 'Outerwear',
                'template_name': 'Outerwear',
                'translations': null
              },
              'label': 4,
              'position': {
                'height': 0.34167250990867615,
                'left': 0.41363656520843506,
                'top': 0.2660274803638458,
                'width': 0.23706114292144775
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 17,
                'approved': 1,
                'model_confidence': 0.9341575503349304,
                'name': 'Shoes',
                'template_name': 'Shoes',
                'translations': null
              },
              'label': 12,
              'position': {
                'height': 0.11180520057678223,
                'left': 0.49074673652648926,
                'top': 0.8646723628044128,
                'width': 0.06415390968322754
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 6,
                'approved': 1,
                'model_confidence': 1,
                'name': 'Shirts-Blouses',
                'template_name': 'Shirts-Blouses',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.413265306122449,
                'left': 0.453030303030303,
                'top': 0.43494897959183676,
                'width': 0.13636363636363635
              },
              'subcategory_data': []
            },
            {
              'category_data': {
                '_id': 38,
                'approved': 1,
                'model_confidence': 1,
                'name': 'Shirts-Blouses',
                'template_name': 'Shirts-Blouses',
                'translations': null
              },
              'label': 0,
              'position': {
                'height': 0.07908163265306123,
                'left': 0.6651515151515152,
                'top': 0.5318877551020408,
                'width': 0.05757575757575766
              },
              'subcategory_data': []
            }
          ]
        },
        'local_image_filename': 'outfit13.jpg',
        'position': null,
        'remote_image_url': 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/9a0f808fad17bf7961a7a25a5547f7041620400598638.jpeg',
        'remote_image_url_small': null,
        'server_image_filename': '9a0f808fad17bf7961a7a25a5547f7041620400598638.jpeg',
        'server_image_filename_small': null
      }
    ],
    'cached_outfit': {
      'garments': [
        {
          '_id': '5c8a3cd3ad911a6d822ab621',
          'results': [
            {
              'remote_product_title': 'Chain sash tie cuff V-neck top - Jonathan Simkhai - White',
              'remote_brand': 'Jonathan Simkhai',
              'garment_images': [
                {
                  'server_image_filename': '104e424cc7fcb1f4dd5567543f67dea4a69b485a.jpg'
                }
              ],
              'price': 5200
            },
            {
              'remote_product_title': 'Derek Lam Alek V-Neck Full Blouse',
              'remote_brand': 'Derek Lam',
              'garment_images': [
                {
                  'server_image_filename': 'a13aee9c07b6d01a3a5015a3e11eb4750e00fa0e.jpg'
                }
              ],
              'price': 1184
            },
            {
              'remote_product_title': 'Rib border silk V-neck blouse - Vince - White',
              'remote_brand': 'Vince',
              'garment_images': [
                {
                  'server_image_filename': '7f876278028ac2cd4829dbe7a0d1aa6129a65e25.jpg'
                }
              ],
              'price': 2300
            },
            {
              'remote_product_title': 'Christian Siriano puff sleeve top',
              'remote_brand': 'Christian Siriano',
              'garment_images': [
                {
                  'server_image_filename': '7e79f55302ac06edffc81adb3b3d0dd4bc81a3c5.jpg'
                }
              ],
              'price': 1860
            },
            {
              'remote_product_title': 'Luis pussy-bow silk crepe de chine blouse',
              'remote_brand': 'Equipment',
              'garment_images': [
                {
                  'server_image_filename': '67ba428341756009e463fb0083a03d898682a784.jpg'
                }
              ],
              'price': 280
            },
            {
              'remote_product_title': 'Prescot cotton and linen-blend wrap top',
              'remote_brand': 'rag & bone',
              'garment_images': [
                {
                  'server_image_filename': 'eb5a15bcb4bce152b317a9c980cea820012023bb.jpg'
                }
              ],
              'price': 330
            },
            {
              'remote_product_title': 'Tom Ford longsleeved blouse ',
              'remote_brand': 'Tom Ford',
              'garment_images': [
                {
                  'server_image_filename': 'a62e41566c5b43a03d84a0a1bd6f9198f590c2a1.jpg'
                }
              ],
              'price': 590
            },
            {
              'remote_product_title': 'Derek Lam Kara Long Sleeve Blouse ',
              'remote_brand': 'Derek Lam',
              'garment_images': [
                {
                  'server_image_filename': '92f2245d9fed6898424a3ba4893bedb970b36037.jpg'
                }
              ],
              'price': 918
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa3073c',
          'results': [
            {
              'remote_product_title': 'Belden Trench Coat',
              'remote_brand': 'Belden',
              'garment_images': [
                {
                  'server_image_filename': 'ac35fb5cdb7ccba7c3b6249121efa51058b17a81.jpg'
                }
              ],
              'price': '129.00'
            },
            {
              'remote_product_title': 'Alfani Draped Trench Coat',
              'remote_brand': 'ALFANI',
              'garment_images': [
                {
                  'server_image_filename': '7aef738740d75793573b14b6e8655f4e3dcb9feb.jpg'
                }
              ],
              'price': '149.50'
            },
            {
              'remote_product_title': 'Calvin Klein Belted Trench Coat',
              'remote_brand': 'CALVIN KLEIN',
              'garment_images': [
                {
                  'server_image_filename': '19f485139cf4cba63b95950584b031a191fc6f11.jpg'
                }
              ],
              'price': '189.99'
            },
            {
              'remote_product_title': 'Belted Wool and Cashmere-Blend Coat',
              'remote_brand': 'Max Mara',
              'garment_images': [
                {
                  'server_image_filename': 'b6cf3ab5f0209744424486728dfc4d9ea44094de.jpg'
                }
              ],
              'price': '2000.00'
            },
            {
              'remote_product_title': 'Belted Cotton And Linen-Blend Trench Coat',
              'remote_brand': 'Basicke',
              'garment_images': [
                {
                  'server_image_filename': 'ed5a7b4c62adbcaf9721ae19d7149dd4ec8f6d18.jpg'
                }
              ],
              'price': '249.99'
            },
            {
              'remote_product_title': 'Dis Beige Trench Coat',
              'remote_brand': 'BRGN',
              'garment_images': [
                {
                  'server_image_filename': '240703e008bdaad4c1ebe2d3ec13cb932d050c67.jpg'
                }
              ],
              'price': '700.00'
            },
            {
              'remote_product_title': 'Tina Trench Off White',
              'remote_brand': 'Creative Collective',
              'garment_images': [
                {
                  'server_image_filename': '7db57d3bdf607e21a6d6edf6edfdb27349baeb7f.jpg'
                }
              ],
              'price': '429.00'
            }
          ]
        },
        {
          '_id': '59a88b543787ab254c2e5f5b',
          'results': [
            {
              'remote_product_title': 'Slingback leather shoes',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'e32c5fc1d2bac609a5f1c0fd83693bd21047a52b.jpg'
                }
              ],
              'price': 12.99
            },
            {
              'remote_product_title': 'Metallic ring mule',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '7c2528b6b82211a90cd6929ee318e00a1edbb574.jpg'
                }
              ],
              'price': 49.99
            },
            {
              'remote_product_title': 'Fur leather shoes',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'bd78f4e834d1a1f1b31509f21306b0382a90a864.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Slingback leather shoes',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '9768497a656dbf8f941f8f41ce7e98944954fb7e.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Heel mules',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'db63ee35c75bb7049e2e37db5d014bd54fc7f41d.jpg'
                }
              ],
              'price': 35.99
            },
            {
              'remote_product_title': 'Slingback leather shoes',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': 'e68b14fb9ff88b88ac5db77096c307ab9fda7001.jpg'
                }
              ],
              'price': 59.99
            },
            {
              'remote_product_title': 'Metal heel leather mules',
              'remote_brand': 'Mango',
              'garment_images': [
                {
                  'server_image_filename': '37c444991042acf1e530d47c0aca0c17bb2b774e.jpg'
                }
              ],
              'price': 69.99
            },
            {
              'remote_product_title': 'Coco velvet and satin mules',
              'remote_brand': 'The Row',
              'garment_images': [
                {
                  'server_image_filename': 'a7de1c5b53d4634ad191eb9b85bdc10f1edfdd99.jpg'
                }
              ],
              'price': 725
            }
          ]
        },
        {
          '_id': '5a648fd637c561696111d3d7',
          'results': [
            {
              'remote_product_title': 'Hayden Boyfriend Jeans',
              'remote_brand': 'TOPSHOP',
              'garment_images': [
                {
                  'server_image_filename': '7330e571494cfcb4a8bfe2b0f75035d598376506.jpg'
                }
              ],
              'price': 36.99
            },
            {
              'remote_product_title': 'Silver Jeans Co. Plus Size Sam Distressed Boyfriend-Fit Jeans',
              'remote_brand': 'Macys',
              'garment_images': [
                {
                  'server_image_filename': '0769c9860e931b5636d399653c10d62a84fe0f93.jpg'
                }
              ],
              'price': 99
            },
            {
              'remote_product_title': 'Eileen Fisher Cuffed Boyfriend Jeans, Regular & Petite',
              'remote_brand': 'Eileen Fisher',
              'garment_images': [
                {
                  'server_image_filename': '6d8cd284f3a353da5590170fe51ab4042667c0e8.jpg'
                }
              ],
              'price': 178
            },
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'f09a88f0b9a6ac7a52408f66d7a6277a20a66e2e.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Vintage High Ankle Jeans',
              'remote_brand': 'H&M',
              'garment_images': [
                {
                  'server_image_filename': 'f09a88f0b9a6ac7a52408f66d7a6277a20a66e2e.jpg'
                }
              ],
              'price': 29.99
            },
            {
              'remote_product_title': 'Lucky Brand Distressed Boyfriend Jeans',
              'remote_brand': 'Lucky Brand',
              'garment_images': [
                {
                  'server_image_filename': '70c2c7bded337c20e324b0d33c669ba34eaa7f36.jpg'
                }
              ],
              'price': 89.5
            },
            {
              'remote_product_title': 'William Rast Cotton Ripped Boyfriend Jeans',
              'remote_brand': 'William Rast',
              'garment_images': [
                {
                  'server_image_filename': '2cde5faf23d1c79fa5a9f9c41714aa4e1db1b77d.jpg'
                }
              ],
              'price': 109.5
            },
            {
              'remote_product_title': '1.state Cotton Ripped Straight-Leg Jeans',
              'remote_brand': '1 STATE',
              'garment_images': [
                {
                  'server_image_filename': '648e37682e12fe44d7c5845856e3f242fe388e49.jpg'
                }
              ],
              'price': 119
            }
          ]
        },
        {
          '_id': '5ca7cf14ad911a74ac473fa2',
          'results': [
            {
              'remote_product_title': 'Sunglasses - polar grey gradient',
              'remote_brand': 'Emporio Armani',
              'garment_images': [
                {
                  'server_image_filename': '6b6292cb19c55b30fdcb64f2692b84484890fe53.jpg'
                }
              ],
              'price': 134.99
            },
            {
              'remote_product_title': 'Sunglasses - matte black',
              'remote_brand': 'Burberry',
              'garment_images': [
                {
                  'server_image_filename': '3b9943fca2da0cbd3ed4f26e6ced58cd853f4733.jpg'
                }
              ],
              'price': 154.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Anna Field',
              'garment_images': [
                {
                  'server_image_filename': '012e5754952a8e3ca0b4a4ba77f0c9dd5c23fe36.jpg'
                }
              ],
              'price': 10.99
            },
            {
              'remote_product_title': 'RETO - Sunglasses - black',
              'remote_brand': 'Jimmy Choo',
              'garment_images': [
                {
                  'server_image_filename': '220f084322f7671d864d58c6441e79ba0f34779c.jpg'
                }
              ],
              'price': 334.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Emporio Armani',
              'garment_images': [
                {
                  'server_image_filename': 'a09080e9f22310db223c9a6a97c1eeaf5d8b19f2.jpg'
                }
              ],
              'price': 154.99
            },
            {
              'remote_product_title': 'GIGI HADID - Sunglasses - black',
              'remote_brand': 'VOGUE Eyewear',
              'garment_images': [
                {
                  'server_image_filename': '2967a765c35e14d851b2204acd1f9f1c2862f99a.jpg'
                }
              ],
              'price': 119.99
            },
            {
              'remote_product_title': 'NAPA - Sunglasses - light grey gradient',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': 'bf8e8595f6af64e745ed7a26036facdedf615d4e.jpg'
                }
              ],
              'price': 164.99
            },
            {
              'remote_product_title': 'Sunglasses - black',
              'remote_brand': 'Michael Kors',
              'garment_images': [
                {
                  'server_image_filename': '83c8624700bf250e78faec7de060548f147df99b.jpg'
                }
              ],
              'price': 144.99
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa2fd8f',
          'results': [
            {
              'remote_product_title': 'Faye oversized organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '2ec589873a9fa72684fe36ad8ae739742ee6dad9.jpg'
                }
              ],
              'price': 45
            },
            {
              'remote_product_title': 'Nash Face appliquéd cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': '6e811aa4eb41472b19b57f6e825cd5d89a0fb769.jpg'
                }
              ],
              'price': 100
            },
            {
              'remote_product_title': 'The Victoria cotton-jersey T-shirt',
              'remote_brand': 'Victoria, Victoria Beckham',
              'garment_images': [
                {
                  'server_image_filename': 'ee79752ef52274d055b755c39e55ebc53eba92a5.jpg'
                }
              ],
              'price': 90
            },
            {
              'remote_product_title': 'Organic stretch-cotton jersey T-shirt ',
              'remote_brand': 'Bassike',
              'garment_images': [
                {
                  'server_image_filename': '9a8fbc315fe76632837bcd24ba5935024d5e3984.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Gojina oversized intarsia-trimmed cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': 'fce169d25d26506c97e44c92116cb3f1c632dc97.jpg'
                }
              ],
              'price': 140
            },
            {
              'remote_product_title': 'Jenna organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '74b03bad20e9d550f1deb388543fe4541c6ad242.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Appliquéd cotton-jersey T-shirt ',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': '0287d4290f6b95a54a48fc3f1c340e28748fe6fc.jpg'
                }
              ],
              'price': 220
            },
            {
              'remote_product_title': 'Embroidered cotton and linen-blend jersey T-shirt',
              'remote_brand': 'The Upside',
              'garment_images': [
                {
                  'server_image_filename': 'c112e7ab283fba0b2cb71aaea749371fd37fd190.jpg'
                }
              ],
              'price': 80
            }
          ]
        },
        {
          '_id': '5f33a94dcd5f6d51cfa2fd8f',
          'results': [
            {
              'remote_product_title': 'Faye oversized organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '2ec589873a9fa72684fe36ad8ae739742ee6dad9.jpg'
                }
              ],
              'price': 45
            },
            {
              'remote_product_title': 'Nash Face appliquéd cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': '6e811aa4eb41472b19b57f6e825cd5d89a0fb769.jpg'
                }
              ],
              'price': 100
            },
            {
              'remote_product_title': 'The Victoria cotton-jersey T-shirt',
              'remote_brand': 'Victoria, Victoria Beckham',
              'garment_images': [
                {
                  'server_image_filename': 'ee79752ef52274d055b755c39e55ebc53eba92a5.jpg'
                }
              ],
              'price': 90
            },
            {
              'remote_product_title': 'Organic stretch-cotton jersey T-shirt ',
              'remote_brand': 'Bassike',
              'garment_images': [
                {
                  'server_image_filename': '9a8fbc315fe76632837bcd24ba5935024d5e3984.jpg'
                }
              ],
              'price': 70
            },
            {
              'remote_product_title': 'Gojina oversized intarsia-trimmed cotton-jersey T-shirt',
              'remote_brand': 'Acne Studios',
              'garment_images': [
                {
                  'server_image_filename': 'fce169d25d26506c97e44c92116cb3f1c632dc97.jpg'
                }
              ],
              'price': 140
            },
            {
              'remote_product_title': 'Jenna organic cotton-jersey T-shirt',
              'remote_brand': 'Ninety Percent',
              'garment_images': [
                {
                  'server_image_filename': '74b03bad20e9d550f1deb388543fe4541c6ad242.jpg'
                }
              ],
              'price': 35
            },
            {
              'remote_product_title': 'Appliquéd cotton-jersey T-shirt ',
              'remote_brand': 'Saint Laurent',
              'garment_images': [
                {
                  'server_image_filename': '0287d4290f6b95a54a48fc3f1c340e28748fe6fc.jpg'
                }
              ],
              'price': 220
            },
            {
              'remote_product_title': 'Embroidered cotton and linen-blend jersey T-shirt',
              'remote_brand': 'The Upside',
              'garment_images': [
                {
                  'server_image_filename': 'c112e7ab283fba0b2cb71aaea749371fd37fd190.jpg'
                }
              ],
              'price': 80
            }
          ]
        }
      ]
    }
  }
}