import React, {useState, useEffect, useCallback, useRef} from 'react'
import {defaultProps} from 'recompose'
import ImageCarouselZoom from 'components/LabelEditor/ImageCarouselZoom/ImageCarouselZoom'
import './Garment.css'

const Garment = (props) => {
  const [state, setState] = useState({
    imageHeight: props.imageHeight,
    height: props.height,
    width: props.width,
    editable: props.editable,
    selectable: props.selectable,
    indicators: props.indicators,
  })

  const prevGarmentRef = useRef()

  const handleEditClick = () => {
    props.editOnClick(props.garmentIndex, props.garment)
  }

  const handleDeleteClick = () => {
    props.deleteOnClick(props.garmentIndex, props.garment)
  }

  const handleSelectClick = () => {
    if (state.selectable) {
      props.selectOnClick(props.garment)
    }
  }

  /*
  const cleanUp = useCallback(async () => {
    console.log('cleanUp garment callback')
    if (props.setGarmentInEditor) {
      await props.setGarmentInEditor(props.garment._id, false)
    }
  }, [props.garment._id, props.setGarmentInEditor])

  useEffect(() => {
    console.log('useEffect garment cleanup')
    if (props.setGarmentInEditor) {
      window.addEventListener('beforeunload', cleanUp)
    }

    return () => {
      if (props.setGarmentInEditor) {
        cleanUp()
        window.removeEventListener('beforeunload', cleanUp)
      }
    }
  }, [cleanUp, props.garment._id, props.setGarmentInEditor])

  useEffect(() => {
    console.log('useEffect garment update')
    const updateGarmentInEditor = async () => {
      if (props.setGarmentInEditor && prevGarmentRef.current && props.garment._id !== prevGarmentRef.current._id) {
        await props.setGarmentInEditor(prevGarmentRef.current._id, false)
      }
    }

    updateGarmentInEditor()
    prevGarmentRef.current = props.garment
  }, [props.garment._id, props.setGarmentInEditor])
   */

  return (
    props.garment != null &&
    <div
      className={props.inGarmentNavigator ? 'card-in-garm-navigator' : 'card bg-muted rounded'}
      style={{width: state.width, height: 'auto'}}
    >
      <ImageCarouselZoom
        images={props.garment.garment_images}
        imageHeight={props.imageHeight}
      />
      <div className={`card-body card-body-garment${props.garment.stock < 1 && props.indicateStock ?
        ' out-of-stock' :
        (props.usedProductIds && !props.usedProductIds.includes(props.garment.remote_product_id)) ?
          ' used-in-outfit' :
          ''}`}>
        <a href={props.garment.product_url} target="_blank" rel="noopener noreferrer">
          <h6
            className="card-title text-dark"
            style={{fontSize: '9px'}}
          >
            {props.garment.remote_product_title}
          </h6>
        </a>
        <div
          className="d-flex flex-row"
          ref={element => {
            if (element) {
              element.style.setProperty('flex-direction', 'column', 'important')
            }
          }}
          onClick={handleSelectClick}
        >
          {(props.garment.remote_product_categories.includes('Summer') || props.garment.remote_product_categories.includes('Winter')) &&
            <div>
              {props.garment.remote_product_categories.includes('Summer') ? 'Summer' : 'Winter'}
            </div>
          }

          {props.showProductId &&
            <p className="outfit-editor-garm-id" style={{
              fontSize: '9px',
              color: 'black',
              marginBottom: '0px'
            }}>{props.garment.remote_product_id}</p>
          }
          {props.showProductCategories &&
            <p style={{fontSize: '9px', color: 'black'}}>{props.garment.remote_product_categories}</p>
          }
          {props.showId &&
            <p style={{fontSize: '9px', color: 'black'}}>{props.garment._id}</p>
          }
          {props.showBrand &&
            <p style={{fontSize: '9px', color: 'black', margin: '0px'}}>{props.garment.remote_brand}</p>
          }
          {props.showPrice && props.garment.price && props.garment.currency_code &&
            <p style={{
              fontSize: '9px',
              color: 'black',
              margin: '0px'
            }}>{`${props.garment.price} ${props.garment.currency_code}`}</p>
          }
          {props.showStockLevel &&
            <p style={{fontSize: '9px', color: 'black', marginTop: '0px'}}>{props.garment.stock_level}</p>
          }
          {props.showCreatedAt &&
            <p style={{fontSize: '9px', color: 'black', marginTop: '0px'}}>{props.garment.createdAt}</p>
          }
          {state.editable &&
            <div className="edit-delete-buttons" style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <button
                type="button"
                className="btn btn-primary btn-sm edit-delete-button"
                style={{marginBottom: '1px'}}
                onClick={handleEditClick}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm edit-delete-button"
                style={{marginBottom: '1px'}}
                onClick={handleDeleteClick}
              >
                Delete
              </button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

// Add default props to Garment
const withDefaultProps = defaultProps({
  imageHeight: 200,
  height: 300,
  width: 300,
  editable: false,
  selectable: false,
  indicators: false,
  useRemoteImage: false,
})

export default withDefaultProps(Garment)
