import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import api from 'api/api'
import Attributes from '../AttributeEditorV2/Attributes'
import AttributeSidebar from 'components/LabelEditor/AttributeSidebar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from 'components/LabelEditor/Pagination'
import SortByDropdown from 'components/LabelEditor/SortByDropdown'
import authClient from '../../services/Auth'


const useStyles = makeStyles(() => ({
  attributesContainer: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '5px',
    position: 'relative',
  },
  headerControlsContainer: {
    width: '95%',
    height: '72px',
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'fixed',
    top: '60px',
    zIndex: 1000,
    backgroundColor: '#F9F9F9'
  },
  headerControlsContainerColumn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '40%',
  },
  headerControlsContainerColumn1: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '20%',
  },
  mainContentContainer: {
    width: '100%',
    minWidth: '100%',
    height: '82vh',
    maxHeight: '82vh',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
  },
  sideBarContainer: {
    width: '20%',
    minWidth: '20%',
    height: '100%',
  },
  attributeFixedContainer: {
    width: '100%'
  },
  attributeContainer: {
    width: '100%',
    padding: '48px',
    minHeight: '100vh',
    overflowY: 'auto',
    backgroundColor: '#fff',
    '&:before': {
      display: 'none', // Hides the ::before pseudo-element
    },
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
  attributeListContainer: {
    display: 'inline-block',
    width: '100%'
  },
  imageCarouselContainer: {
    width: '50%',
    height: '100%',
    margin: '20px',
    padding: 'auto'
  },
  productInfoTextContainer: {
    width: '50%',
    margin: '20px'
  },
  remoteProductInfoContainer: {
    height: '30%'
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 'fit-content'
  },
  backText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 3px'
  },
  searchPageText: {
    fontFamily: 'Montserrat',
    fontSize: '0.7em',
    marginBottom: '0px',
    margin: 'auto 3px'
  },
  productTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  noGarmentsText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px',
    textAlign: 'center'
  },
  productBrand: {
    fontFamily: 'Montserrat',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  loadingSpinner: {
    height: '40px',
    width: '40px',
    margin: 'auto'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    background: '#F9F9F9',
    width: '45%',
  },
  button: {
    flex: 1,
    margin: '0 5px',
    padding: '10px 0',
    fontSize: '1em',
    fontWeight: '600',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '#E8004C',
    transition: 'background-color 0.3s ease',
    border: '1px solid #E8004C',
    backgroundColor: '#fff',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#E8004C',
    },
  },
  disabledRunJobButton: {
    flex: 1,
    margin: '0 5px',
    padding: '10px 0',
    fontSize: '1em',
    fontWeight: '600',
    backgroundColor: '#FFFFFF',
    color: '#777777',
    border: '1px solid #B5B5B5',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
}))

export default function AttributeEditorV2(props) {
  const classes = useStyles()
  const [garments, setGarments] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [genders, setGenders] = useState(null)
  const [users, setUsers] = useState(null)
  const [categories, setCategories] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [productCategories, setProductCategories] = useState(null)
  const [productCategoriesExclude, setProductCategoriesExclude] = useState(null)
  const [approvalLevel, setApprovalLevel] = useState(1)
  const [approvalLevelShow, setApprovalLevelShow] = useState(0)
  const [copyIndex, setCopyIndex] = useState(null)
  const [limit, setLimit] = useState(25)
  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inStock, setInStock] = useState(null)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [sortBy, setSortBy] = useState({'createdAt': -1})
  const [selectedUserIds, setSelectedUserIds] = useState(null)
  const [selectedSource, setSelectedSource] = useState(null)
  const [taxonomies, setTaxonomies] = useState(null)
  const [taxonomyLabelsMap, setTaxonomyLabelsMap] = useState(null)
  const [taxonomyLabels, setTaxonomyLabels] = useState(null)
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(null)
  const [isCategorySelected, setIsCategorySelected] = useState(false)
  const [enabledRunBtn, setEnabledRunBtn] = useState(false)
  const [vettingJobs, setVettingJobs] = useState([])

  const getSelectedIds = (selectionArray) => {
    let selectedIds = selectionArray
      .filter(selectionArray => selectionArray.active)
      .map(selectionArray => selectionArray._id)

    if (selectedIds.length === 0) {
      selectedIds = null
    }
    return selectedIds
  }

  const getSelectedNames = (selectionArray) => {
    let selectedNames = selectionArray
      .filter(selectionArray => selectionArray.active)
      .map(selectionArray => selectionArray.name)

    if (selectedNames.length === 0 || selectedNames.length === selectionArray.length) {
      selectedNames = null
    }
    return selectedNames
  }

  const getGarmentRestrictions = () => {
    let garmentRestrictionsBase = {
      labels: {
        category: {
          approved: approvalLevelShow
        }
      }
    }

    if (genders !== null) {
      garmentRestrictionsBase.gender = getSelectedIds(genders)
    }

    if (users !== null) {
      garmentRestrictionsBase.user_ids_restriction = getSelectedIds(users)
    }
    if (categories !== null) {
      let selectedCategoryIds = getSelectedIds(categories)
      if (selectedCategoryIds !== null) {
        garmentRestrictionsBase.labels.category.id = selectedCategoryIds
      }
    }
    if (attributes !== null) {
      let flattenedAttributes = []
      for (let group of attributes) {
        if (group.subgroups !== undefined && group.subgroups.length > 0) {
          for (let subgroup of group.subgroups) {
            for (let attribute of subgroup.attributes) {
              flattenedAttributes.push(attribute)
            }
          }
        } else {
          for (let attribute of group.attributes) {
            flattenedAttributes.push(attribute)
          }
        }
      }
      let selectedAttributeIds = getSelectedIds(flattenedAttributes)
      if (selectedAttributeIds !== null) {
        garmentRestrictionsBase.labels.attribute = {
          attributes: [
            {
              id: selectedAttributeIds
            }
          ]
        }
      }
    }
    if (searchInput !== '') {
      garmentRestrictionsBase.search_query = searchInput
    }
    if (inStock !== null) {
      garmentRestrictionsBase.in_stock = inStock
    }

    if (productCategories !== null) {
      garmentRestrictionsBase.remote_product_categories_any = getSelectedNames(productCategories)
    }

    if (productCategoriesExclude !== null) {
      garmentRestrictionsBase.remote_product_categories_exclusion = getSelectedNames(productCategoriesExclude)
    }

    let garmentRestrictions = []

    let inEditorDate = new Date()
    inEditorDate.setHours(inEditorDate.getHours() - 4)

    let inEditorRestrictions = [
      {
        in_attribute_editor: {
          exists: false
        }
      },
      {
        in_attribute_editor: {
          user_ids_restriction: authClient.getUser()._id
        }
      },
      {
        in_attribute_editor: {
          user_ids_exclusion: authClient.getUser()._id,
          updated_before: inEditorDate
        }
      }
    ]
    for (let inEditorRestriction of inEditorRestrictions) {
      let newGarmentRestrictions = Object.assign(
        JSON.parse(JSON.stringify(garmentRestrictionsBase)),
        inEditorRestriction
      )
      garmentRestrictions.push(newGarmentRestrictions)
    }

    if (approvalLevelShow === 0) {
      // In this case also show items without labels field
      let tempGarmentRestrictions = JSON.parse(JSON.stringify(garmentRestrictions))
      for (let garmentRestriction of garmentRestrictions) {
        let newRestriction = JSON.parse(JSON.stringify(garmentRestriction))
        // remove category approved restriction and add label.exists = True
        delete newRestriction.labels.category.approved
        newRestriction.labels.exists = true
        tempGarmentRestrictions.push(newRestriction)
      }
      garmentRestrictions = tempGarmentRestrictions
    }

    return garmentRestrictions
  }

  const setGarmentInEditor = async (garmentId, inEditor) => {
    await api.setGarmentInEditor(garmentId, inEditor, 'attribute', {url: props.database})
  }

  const getStats = async () => {
    setStats([
      {
        name: 'Loading',
        _id: 0,
        active: false
      }
    ])

    let body
    body = {
      garment_restrictions: {
        attribute_data: {
          approved: approvalLevel
        }
      }
    }

    let statsPersonal = await api.getPersonalModelVettingStats({
      url: props.database,
      event_type: 'attributes'
    })

    // Mirroring format of filter options so we can use the same filter component for stats as well
    setStats([
      {
        name: `Total (Personal): ${statsPersonal}`,
        _id: 0,
        active: false
      }
    ])

    // Get total count
    let statsTotal = await api.countGarments(body)
    setStats(prevStats => [
      ...prevStats,
      {
        name: `Total: ${statsTotal.count}`,
        _id: 1,
        active: false
      }
    ])

    // Get total in-stock count
    body.garment_restrictions.in_stock = true
    if (users !== null) {
      body.garment_restrictions.user_ids_restriction = users.filter(user => user.active).map(user => user._id)
    }

    let statsTotalStock = await api.countGarments(body)

    // Get total available in-stock count
    delete body.garment_restrictions.attribute_data

    let statsTotalAvailableStock = await api.countGarments(body)

    setStats(prevStats => [
      ...prevStats,
      {
        name: `Total (In Stock, Selected Users): ${statsTotalStock.count} (${Math.round(100 * statsTotalStock.count / statsTotalAvailableStock.count)}%)`,
        _id: 2,
        active: false
      },
      {
        name: `Total Available (In Stock, Selected Users): ${statsTotalAvailableStock.count}`,
        _id: 3,
        active: false
      }
    ])

    let attributeCount = {count: 0}
    for (let attribute of attributes) {
      body = {
        garment_restrictions: {
          attribute_data: {
            approved: approvalLevel,
            attributes: [attribute._id]
          }
        }
      }
      attributeCount = await api.countGarments(body)
      let newElement = {
        name: `${attribute.name}: ${attributeCount.count}`,
        _id: attribute._id + 4,
        active: false
      }
      setStats(prevStats => [
        ...prevStats,
        newElement
      ])
    }
  }

  const updateDefaultAttributes = (garment) => {
    let categoryChildren = taxonomyLabelsMap.find(label => label._id === garment.labels.category._id).children

    // Remove any attributes that are not in the current category, and add any N/A attributes which are not in the
    // current category
    let categoryAttributes = categoryChildren.filter(child => child.label_type === 'attribute')
    let categoryAttributeIds = categoryAttributes.map(attribute => attribute._id)
    let garmentAttributes = garment.labels.attribute.attributes
    garment.labels.attribute.attributes = garmentAttributes.filter(attribute => categoryAttributes.map(a => a._id).includes(attribute._id))
    let naAttributes = taxonomyLabels.filter(attribute => attribute.name === 'N/A' && !categoryAttributeIds.includes(attribute._id))
    naAttributes.forEach(naAttribute => {
      if (!garment.labels.attribute.attributes.map(a => a._id).includes(naAttribute._id)) {
        garment.labels.attribute.attributes.push({
          _id: naAttribute._id,
          name: naAttribute.name,
          group: 'attribute',
          confidence: 1.0,
          model_version: 'Manual',
          approved: approvalLevel
        })
      }
    })

    let attributeGroups = categoryChildren.filter(child => child.label_type === 'attribute_group')
    attributeGroups.forEach(attributeGroup => {
      if (attributeGroup.children_are_mutually_exclusive) {
        let attributeGroupAttributes = attributeGroup.children.filter(child => child.label_type === 'attribute' && child.parents.includes(garment.labels.category._id))
        if (attributeGroupAttributes.length > 0 && !garment.labels.attribute.attributes.map(a => a._id).some(a => attributeGroupAttributes.map(c => c._id).includes(a))) {
          garment.labels.attribute.attributes.push({
            _id: attributeGroupAttributes[0]._id,
            name: attributeGroupAttributes[0].name,
            group: 'attribute',
            confidence: 1.0,
            model_version: 'Manual',
            approved: approvalLevel
          })
        }
      } else {
        let subgroups = attributeGroup.children.filter(child => child.label_type === 'attribute_subgroup')
        if (subgroups.length > 0) {
          subgroups.forEach(subgroup => {
            if (subgroup.children_are_mutually_exclusive) {
              if (!garment.labels.attribute.attributes.map(a => a._id).some(a => subgroup.children.map(c => c._id).includes(a))) {
                garment.labels.attribute.attributes.push({
                  _id: subgroup.children[0]._id,
                  name: subgroup.children[0].name,
                  group: 'attribute',
                  confidence: 1.0,
                  model_version: 'Manual',
                  approved: approvalLevel
                })
              }
            }
          })
        }
      }
    })
    return garment.labels.attribute.attributes
  }

  const getGarments = async () => {
    if (!loading) {
      setLoading(true)
    }

    // Set existing garments out of editor and remove if present
    if (garments !== null) {
      garments.forEach(garment => setGarmentInEditor(garment._id, false))
    }
    setGarments([])

    let data = {
      garment_restrictions: getGarmentRestrictions(),
      limit: limit,
      offset: page * limit,
      use_cache: false,
      sort_by: sortBy,
      url: props.database
    }

    let newGarmentsData = await api.listGarmentsV3(data)
    try {
      let newGarments = newGarmentsData.garments
      newGarments.forEach(garment => {
        setGarmentInEditor(garment._id, true)

        if (!garment.labels) {
          garment.labels = {}
        }

        if (!garment.labels.category) {
          let defaultCategory = taxonomyLabelsMap.find(label => label.label_type === 'category')
          garment.labels.category = {
            _id: defaultCategory._id,
            name: defaultCategory.name,
            taxonomy: selectedTaxonomy,
            confidence: 1.0,
            model_version: 'Manual',
            approved: approvalLevel
          }
        }

        if (!garment.labels.attribute) {
          garment.labels.attribute = {
            taxonomy: selectedTaxonomy,
            attributes: []
          }
        }

        garment.labels.attribute.attributes = updateDefaultAttributes(garment)
      })
      setGarments(newGarments)
      setTotalPages(parseInt(Math.ceil(newGarmentsData.metadata.total / limit)))
    } catch {
      console.error('Failed to get garments')
    }
    setLoading(false)
  }

  const onSearchInputChange = (searchInputUpdated) => {
    setSearchInput(searchInputUpdated)
  }

  const onSearchClick = () => {
    if (page === 0) {
      getGarments()
    } else {
      setPage(0)  // This will trigger a getGarments call
    }
  }

  const onApproveClick = async () => {
    setLoading(true)
    // Take copy of garments to work with
    let garmentsCopy = JSON.parse(JSON.stringify(garments))
    garmentsCopy.forEach(garment => setGarmentInEditor(garment._id, false))
    setGarments(null)  // Delete garments from state to trigger loading

    // For each of the garments just copied, update the approval level for the current quality setting and save
    for (let [index, garment] of garmentsCopy.entries()) {
      garment.labels.category.approved = approvalLevel
      garment.labels.attribute.attributes.forEach(attribute => attribute.approved = approvalLevel)

      await api.approveGarmentLabelCategory(garment._id, {
        url: props.database,
        ...garment.labels.category
      })

      await api.approveGarmentLabelAttributes(garment._id, {
        url: props.database,
        ...garment.labels.attribute
      })
    }

    // Trigger search for new garments
    await getGarments()
  }

  const onApprovalLevelChange = (newApprovalLevel) => {
    setApprovalLevel(newApprovalLevel)
  }

  const onApprovalLevelShowChange = (newApprovalLevelShow) => {
    setApprovalLevelShow(newApprovalLevelShow)
  }

  const onLimitChange = (newLimit) => {
    setLimit(newLimit)
  }

  const onInStockChange = () => {
    if (inStock === true) {
      setInStock(null)
    } else {
      setInStock(true)
    }
  }

  const onCopyClick = (index) => {
    setCopyIndex(index)
  }

  const onPasteClick = (index) => {
    if (copyIndex !== null) {
      const newGarments = garments
      newGarments[index].attribute_data = JSON.parse(JSON.stringify(garments[copyIndex].attribute_data))
      newGarments[index].attributes = JSON.parse(JSON.stringify(garments[copyIndex].attributes))
      newGarments[index].labels = JSON.parse(JSON.stringify(garments[copyIndex].labels))
      setGarments(prevGarments => {
        let newGarments = JSON.parse(JSON.stringify(prevGarments))
        newGarments[index].attribute_data = JSON.parse(JSON.stringify(prevGarments[copyIndex].attribute_data))
        return newGarments
      })
    }
  }

  const onPasteAllClick = () => {
    if (copyIndex !== null) {
      setGarments(prevGarments => {
        let newGarments = JSON.parse(JSON.stringify(prevGarments))
        newGarments.forEach(garment => {
          garment.attribute_data = JSON.parse(JSON.stringify(garments[copyIndex].attribute_data))
        })
        return newGarments
      })
    }
  }

  const onRunJobClick = async () => {
    setLoading(true)
    let source = selectedSource[0].toLowerCase()
    if (source.includes('gameday')) {
      source = 'gamedaycouture'
    }
    await api.runVettingJob({source, url: props.database})
    setSelectedSource([])
    setLoading(false)
  }

  const handleNextGarmentPageClick = () => {
    const newPage = Math.min(
      totalPages - 1,
      page + 1
    )

    if (page !== newPage) {
      setPage(newPage)
    }
  }

  const handlePageSelectChange = (event) => {
    event.preventDefault()
    const newPage = parseInt(event.target.paginationInput.value) - 1
    if (!isNaN(newPage)) {
      if (newPage !== page) {
        setPage(newPage)
      }
    }
  }

  const handlePrevGarmentPageClick = () => {
    const newPage = Math.max(page - 1, 0)

    if (page !== newPage) {
      setPage(newPage)
    }
  }

  const getTaxonomies = async () => {
    let taxonomies = await api.getTaxonomies({url: props.database})
    try {
      setTaxonomies(taxonomies)
      // Take last taxonomy as selected
      setSelectedTaxonomy(taxonomies[taxonomies.length - 1]._id)
    } catch {
      setTaxonomies([])
      console.error('Failed to get taxonomies')
    }
  }

  const transformTaxonomyLabels = (labels) => {
    const labelMap = new Map()

    // Step 1: Create a map of labels
    labels.forEach(label => {
      labelMap.set(label._id, label)
    })

    // Step 2: Populate children for each label
    labels.forEach(label => {
      label.children = label.children.map(childId => labelMap.get(childId))
    })

    return labels.filter(label => label.label_type === 'category')
  }

  const getTaxonomyLabels = async () => {
    let taxonomyLabels = await api.getTaxonomyLabels({
      params: {
        restrictions: {
          taxonomy: selectedTaxonomy
        },
        projection: {
          clip_descriptions: 0,
          performance_data: 0
        },
        populate_children: false,
        populate_parents: false
      },
      url: props.database
    })

    let taxonomyLabelsMap = transformTaxonomyLabels(taxonomyLabels)

    try {
      setTaxonomyLabels(taxonomyLabels)
      setTaxonomyLabelsMap(taxonomyLabelsMap)
    } catch {
      setTaxonomyLabels([])
      setTaxonomyLabelsMap([])
      console.error('Failed to get taxonomy labels')
    }
  }

  const handleCategoryChange = (garmentIndex, categoryId) => {
    setIsCategorySelected(categoryId !== '63a08e3b0d8e3f25e95a8be5')
    setGarments(prevGarments => {
      let newGarments = JSON.parse(JSON.stringify(prevGarments))
      const selectedCategory = taxonomyLabelsMap.find(label => label._id === categoryId)

      newGarments[garmentIndex].labels.category = {
        _id: categoryId,
        name: selectedCategory.name,
        taxonomy: selectedCategory.taxonomy,
        confidence: 1.0,
        model_version: 'Manual',
        approved: approvalLevel
      }
      newGarments[garmentIndex].labels.attribute.attributes = updateDefaultAttributes(newGarments[garmentIndex])
      return newGarments
    })
  }

  const deepClone = (obj) => JSON.parse(JSON.stringify(obj))

  const findPossibleAttributes = (taxonomyLabelsMap, categoryId) => {
    const category = taxonomyLabelsMap.find((label) => label._id === categoryId)
    return category.children.filter((label) => label.label_type === 'attribute')
  }

  const getSelectedAttributes = (attributeIds, possibleAttributes) => {
    return attributeIds.map((id) => possibleAttributes.find((label) => label._id === id))
  }

  const getExistingSelections = (attributes, selectionOptionIds) => {
    return attributes.filter((attribute) => selectionOptionIds.includes(attribute._id))
  }

  const updateExclusiveAttribute = (garments, garmentIndex, attributeId, possibleAttributes, taxonomyLabels, selectionOptionIds, approvalLevel) => {
    let selectedAttribute = possibleAttributes.find((label) => label._id === attributeId)
    if (!selectedAttribute) return garments

    let attributeGroup = taxonomyLabels.find(
      (label) => selectedAttribute.parents.includes(label._id) && label.label_type === 'attribute_group'
    )
    let attributeSubgroup = taxonomyLabels.find(
      (label) => selectedAttribute.parents.includes(label._id) && label.label_type === 'attribute_subgroup'
    )

    if (attributeGroup?.children_are_mutually_exclusive && attributeSubgroup) {
      selectionOptionIds = taxonomyLabels
        .filter((label) => label.parents.includes(attributeGroup._id) && label.label_type === 'attribute')
        .map((label) => label._id)
    }

    garments[garmentIndex].labels.attribute.attributes = garments[garmentIndex].labels.attribute.attributes.filter(
      (attribute) => !selectionOptionIds.includes(attribute._id)
    )

    garments[garmentIndex].labels.attribute.attributes.push({
      _id: selectedAttribute._id,
      name: selectedAttribute.name,
      group: 'attribute',
      confidence: 1.0,
      model_version: 'Manual',
      approved: approvalLevel,
    })

    return garments
  }


  const updateNonExclusiveAttributes = (garments, garmentIndex, selectedAttributes, existingSelections, approvalLevel) => {
    if (selectedAttributes.length > existingSelections.length) {
      let newAttribute = selectedAttributes.find(
        (attribute) => !existingSelections.map((existingAttribute) => existingAttribute._id).includes(attribute?._id)
      )

      if (newAttribute) {
        garments[garmentIndex].labels.attribute.attributes.push({
          _id: newAttribute._id,
          name: newAttribute.name,
          group: 'attribute',
          confidence: 1.0,
          model_version: 'Manual',
          approved: approvalLevel,
        })
      }
    } else {
      let removedAttribute = existingSelections.find(
        (attribute) => !selectedAttributes.map((selectedAttribute) => selectedAttribute?._id).includes(attribute._id)
      )

      if (removedAttribute) {
        garments[garmentIndex].labels.attribute.attributes = garments[garmentIndex].labels.attribute.attributes.filter(
          (attribute) => attribute._id !== removedAttribute._id
        )
      }
    }
    return garments
  }


  const handleAttributeChange = (garmentIndex, attributeId, isExclusive, selectionOptions) => {
    setGarments((prevGarments) => {
      if (!Array.isArray(selectionOptions)) {
        console.error('selectionOptions is not an array:', selectionOptions)
        return prevGarments
      }

      let newGarments = deepClone(prevGarments)
      const category = newGarments[garmentIndex]?.labels?.category

      if (!category) {
        console.error('Category not found for garment index:', garmentIndex)
        return prevGarments
      }

      const possibleAttributes = findPossibleAttributes(taxonomyLabelsMap, category._id)
      if (!possibleAttributes) {
        console.error('Possible attributes not found for category ID:', category._id)
        return prevGarments
      }

      // Assert that all selection options are contained within possible attributes
      if (!selectionOptions.every((option) => possibleAttributes.some((attribute) => attribute._id === option._id))) {
        console.error('Selection options contain invalid attribute IDs:', selectionOptions.map(o => o.name).sort(), possibleAttributes.map(o => o.name).sort())
        return prevGarments
      }

      let selectionOptionIds = selectionOptions.map((option) => option._id)

      if (isExclusive) {
        return updateExclusiveAttribute(newGarments, garmentIndex, attributeId, possibleAttributes, taxonomyLabels, selectionOptionIds, approvalLevel)
      } else {
        let selectedAttributes = getSelectedAttributes(attributeId, possibleAttributes)
        let existingSelections = getExistingSelections(newGarments[garmentIndex].labels.attribute.attributes, selectionOptionIds)
        return updateNonExclusiveAttributes(newGarments, garmentIndex, selectedAttributes, existingSelections, approvalLevel)
      }
    })
  }


  useEffect(() => {
    if (taxonomies === null) {
      getTaxonomies()
    }
  }, [taxonomies])

  useEffect(() => {
    if (selectedTaxonomy !== null) {
      getTaxonomyLabels()
    }
  }, [selectedTaxonomy])

  useEffect(() => {
    if (garments === null && users !== null && genders !== null && categories !== null && attributes !== null) {
      getGarments()
    }
  }, [genders, users, categories, attributes])

  useEffect(() => {
    if (garments !== null) {
      getGarments()
    }
  }, [page, sortBy])

  useEffect(() => {
    if (users !== null) {
      let newSelectedUserIds = users.filter(user => user.active).map(user => user._id)
      let newSelectedSource = users.filter(user => user.active).map(user => user.username)
      if (newSelectedUserIds.length === 0) {
        newSelectedUserIds = newSelectedSource = null
      }
      setSelectedUserIds(newSelectedUserIds)
      setSelectedSource(newSelectedSource)
    }
  }, [users])

  useEffect(() => {
    api.getVettingJobs(props.database).then((data) => setVettingJobs(data))
    if (vettingJobs && selectedSource && selectedSource.length === 1) {
      const source = selectedSource[0].toLowerCase()
      setEnabledRunBtn(vettingJobs.includes(source) ||
        (source.includes('gameday') && vettingJobs.includes('gamedaycouture')))
    }
  }, [selectedSource])

  return (
    <div className={classes.attributesContainer}>
      <div className={classes.headerControlsContainer}>
        <div className={classes.headerControlsContainerColumn}>
          <div className={classes.buttonsContainer}>
            {onPasteAllClick && (
              <button className={`${classes.button}`} onClick={onPasteAllClick}>
                Paste All
              </button>
            )}
            {onApproveClick && (
              <button className={`${classes.button}`} onClick={onApproveClick}>
                Approve
              </button>
            )}
            {onRunJobClick && (
              <button className={enabledRunBtn ? `${classes.button}` : `${classes.disabledRunJobButton}`}
                onClick={onRunJobClick}>
                Run Job
              </button>
            )}
          </div>
        </div>
        <div className={classes.headerControlsContainerColumn}>
          <SortByDropdown
            setSortBy={setSortBy}
          />
          <Pagination
            onPrevGarmentPageClick={handlePrevGarmentPageClick}
            onPageSelectChange={handlePageSelectChange}
            onNextGarmentPageClick={handleNextGarmentPageClick}
            pageNumber={page}
            totalPages={totalPages}
          />
        </div>

      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.sideBarContainer}>
          <AttributeSidebar
            searchInput={searchInput}
            onSearchInputChange={onSearchInputChange}
            onSearchClick={onSearchClick}
            selectedTaxonomy={selectedTaxonomy}
            taxonomies={taxonomies}
            setSelectedTaxonomy={setSelectedTaxonomy}
            setGenders={setGenders}
            setUsers={setUsers}
            setCategoriesNewTaxonomy={setCategories}
            setAttributesNewTaxonomy={setAttributes}
            taxonomy={selectedTaxonomy}
            setProductCategories={setProductCategories}
            setProductCategoriesExclude={setProductCategoriesExclude}
            stats={stats}
            onStatsClick={getStats}
            approvalLevel={approvalLevel}
            onApprovalLevelChange={onApprovalLevelChange}
            approvalLevelShow={approvalLevelShow}
            onApprovalLevelShowChange={onApprovalLevelShowChange}
            limit={limit}
            onLimitChange={onLimitChange}
            inStock={inStock}
            onInStockChange={onInStockChange}
            selectedUserIds={selectedUserIds}
            selectedSource={selectedSource}
          />
        </div>
        <div className={classes.attributeFixedContainer}>
          <div className={classes.attributeContainer}>
            <div className={classes.attributeListContainer}>
              {
                loading ?
                  <div className={classes.loadingSpinner}>
                    <CircularProgress/>
                  </div>
                  :
                  garments !== undefined && garments !== null && garments.length > 0 ?
                    garments.map((garment, garmentIndex) => (
                      <Attributes
                        key={garmentIndex}
                        id={garmentIndex}
                        garment={garment}
                        setGarmentInEditor={setGarmentInEditor}
                        taxonomyLabels={taxonomyLabelsMap}
                        onCopyClick={onCopyClick}
                        onPasteClick={onPasteClick}
                        isCategorySelected={isCategorySelected}
                        onCategoryChange={(categoryId) => handleCategoryChange(garmentIndex, categoryId)}
                        onAttributeChange={(attributeId, isExclusive, selectionOptionIds) => handleAttributeChange(garmentIndex, attributeId, isExclusive, selectionOptionIds)}
                      />
                    ))
                    :
                    <div className={classes.noGarmentsText}>
                      No garments available for the given filters
                    </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
