import React, {useState} from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import SortIcon from '@material-ui/icons/Sort'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  sortBy: {
    justifyContent: 'flex-end'
  },
  sortByDropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  select: {
    display: 'flex',
    minWidth: 160,
    background: 'white',
    paddingLeft: 10,
    paddingTop: 5,
    paddingBottom: 5,
    border: '1px solid #ECEEF5',
    boxShadow: 'none',
    '&:focus': {
      borderRadius: 8,
      background: 'white'
    },
    '&[aria-expanded="true"]': {
      background: grey[50]
    },
    '& > div': {
      display: 'inline-flex', // this shows the icon in the SelectInput but not the dropdown
      color: '#777777'
    },
    '& > svg': {
      color: '#777777'
    },
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 550
  },
  icon: {
    color: '#999999',
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  paper: {
    borderRadius: 4,
    marginTop: 8
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 8,
    paddingLeft: 8,
    background: 'white',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    '& li': {
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 8,
      paddingLeft: 8
    },
    '& li:hover': {
      background: '#ECEEF5'
    },
    '& li.Mui-selected': {
      color: 'black',
      background: 'white'
    },
    '& li.Mui-selected:hover': {
      background: '#ECEEF5'
    }
  },
  listIcon: {
    minWidth: 32,
    display: 'none', // hide the ListItemIcon in the dropdown
    color: '#86A8E0'
  },
  optionText: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600
  },
  textContainer: {
    color: '#000000',
    opacity: '0.5',
    fontFamily: 'Montserrat',
    fontSize: '11px',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 15px'
  }
}))

// Original design here: https://github.com/siriwatknp/mui-treasury/issues/539

const SortByDropdown = (props) => {
  const [selected, setSelected] = useState('date descending')

  const options = [
    'category',
    'subcategory',
    'product id',
    'date descending',
    'date ascending',
    'stock descending',
    'stock ascending',
    'price descending',
    'price ascending'
  ]

  let value = 0
  if (selected !== undefined) {
    value = options.indexOf(selected)
  }

  const handleChange = (event) => {
    let s = options[event.target.value]
    setSelected(s)
    switch (s) {
    case 'category':
      props.setSortBy({'category_data._id': 1})
      break
    case 'subcategory':
      props.setSortBy({'subcategory_data._id': 1})
      break
    case 'product id':
      props.setSortBy({'remote_product_id': 1})
      break
    case 'date descending':
      props.setSortBy({'createdAt': -1})
      break
    case 'date ascending':
      props.setSortBy({'createdAt': 1})
      break
    case 'stock descending':
      props.setSortBy({'stock_level': -1})
      break
    case 'stock ascending':
      props.setSortBy({'stock_level': 1})
      break
    case 'price descending':
      props.setSortBy({'price': -1})
      break
    case 'price ascending':
      props.setSortBy({'price': 1})
      break
    default:
      props.setSortBy({'category_data._id': 1})
    }
  }

  const classes = useStyles()

  // moves the menu below the select input
  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    },
    getContentAnchorEl: null
  }

  return (
    <FormControl variant='outlined'>
      <div className={classes.sortByDropdownContainer}>
        <div className={classes.textContainer}>
          Sort By
        </div>
        <Select
          classes={{ root: classes.select }}
          MenuProps={menuProps}
          IconComponent={() => (<ArrowDropDownIcon className={classes.sortBy + ' ' + classes.icon} />)}
          value={value}
          onChange={handleChange}
        >
          {
            options.map((option, index) => (
              <MenuItem value={index} key={`SortByDropdown-MenuItem-${index}`}>
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <SortIcon/>
                </ListItemIcon>
                <span className={classes.optionText} style={{ marginTop: 3 }}>
                  {option}
                </span>
              </MenuItem>
            ))
          }
        </Select>
      </div>
    </FormControl>
  )
}

export default SortByDropdown
