import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImageCarousel from './ImageCarousel'
import settings from '../../config/setting-values'

const useStyles = makeStyles((theme) => ({
  garmentContainer: {
    width: '100%',
    height: '100%',
    padding: '3%'
  },
  imageCarouselContainer: {
    width: '100%',
    height: '50%',
    margin: 'auto',
    padding: 'auto'
  },
  garmentInfoContainer: {
    marginTop: '5px',
    textAlign: 'center'
  },
  brandText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.8em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  titleText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.8em',
    marginBottom: '0px'
  },
  priceText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.8em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  }
}))

export default function Garment (props) {
  const classes = useStyles()

  return (
    <div className={classes.garmentContainer}>
      <div className={classes.imageCarouselContainer}>
        <ImageCarousel
          url={props.garment.product_url}
          images={props.garment.garment_images.map(garmentImage => {
            if (props.useRemoteImage) {
              return garmentImage.remote_image_url
            } else {
              if (garmentImage.s3_url_resized) return garmentImage.s3_url_resized
              // return `${settings.s3url}resized/${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
              return `${settings.s3url}${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
            }
          })}
        />
      </div>
      <div className={classes.garmentInfoContainer}>
        <div className={classes.brandText}>{props.garment.remote_brand}</div>
        <div className={classes.titleText}>{props.garment.remote_product_title}</div>
        {/* <div>{props.garment.source}</div> */}
        {
          props.garment.price > 0 && props.garment.currency_code !== null &&
            <div
              className={classes.priceText}
            >
              {`${props.garment.price} ${props.garment.currency_code}`}
            </div>
        }
      </div>
    </div>
  )
}
