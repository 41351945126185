import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import intelistyleLogo from '../../assets/img/intelistyle_cropped_logo.png'
import JohndoeIcon from '../../assets/img/johndoe.jpg'

const style = theme => ({
  message: {
    borderRadius: '5px',
    padding: '8px 15px',
    marginTop: '5px',
    marginBottom: '5px',
    display: 'inline-block',

    minWidth: '30%',
    maxWidth: '75%'
  },
  yours: {
    position: 'relative',
    alignSelf: 'flex-start',
    backgroundColor: 'white'
  },
  mine: {
    position: 'relative',
    // alignSelf: 'flex-end',
    backgroundColor: '#86A8E0'
  },
  messages: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  imgMine: {
    position: 'relative',
    borderRadius: '50%',
    alignSelf: 'center',
    width: '40px',
    marginLeft: '20px'
  },
  mineClass: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  imgYours: {
    position: 'relative',
    borderRadius: '50%',
    alignSelf: 'center',
    width: '40px',
    marginRight: '20px'
  },
  yoursClass: {
    display: 'flex',
    flexDirection: 'row'
  }
})

class Message extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      message: this.props.message
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.message) !== JSON.stringify(this.state.message)) {
      this.setState({ message: this.props.message })
    }
  }

  render () {
    const { classes } = this.props
    let activeClass
    if (this.state.message.from_user === this.props.userPhoneNumber) {
      activeClass = `${classes.message} ${classes.mine}`
    } else {
      activeClass = `${classes.message} ${classes.yours}`
    }

    return (
      <div className={classes.messages}>
        {
          this.state.message.from_user === this.props.userPhoneNumber
            ? <div className={classes.mineClass}>
              <img src={intelistyleLogo} alt='logo' className={classes.imgMine} />
              <div className={activeClass}>
                {this.state.message.body}
              </div>
            </div>
            : <div className={classes.yoursClass}>
              <img src={JohndoeIcon} alt='johndoeIcon' className={classes.imgYours} />
              <div className={activeClass}>
                {this.state.message.body}
              </div>

            </div>

        }
      </div>
    )
  }
}

export default withStyles(style)(Message)
