import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import searchBarStyle from './searchBarStyle.jsx'
import VisualSearchDialog from '../VisualSearchDialog/VisualSearchDialog'
import AttributesSearchDialog from '../AttributesSearchDialog/AttributesSearchDialog'
import searchIcon from '../../assets/img/searchIcon.png'
import cameraIcon from '../../assets/img/cameraIcon.png'
import uploadIcon from '../../assets/img/uploadIcon.png'


const useStyle = makeStyles(searchBarStyle)

function SearchBar (props) {
  const classes = useStyle(props)
  let [showVisualSearch, setShowVisualSearch] = useState(false)
  let [showAttributesSearch, setShowAttributesSearch] = useState(false)

  return (
    <div className={`${classes.searchBarContainer}`}>

      <form className={`${classes.form}`} onSubmit={props._onSubmit}>
        <div className={`${classes.searchBar} ${props.show !== true ? classes.hidden : ''}`}>
          <img className={classes.searchIcon} src={searchIcon} alt="search-icon" />
          <input
            id='searchBar'
            type='text'
            placeholder={props.searchText}
            autoComplete='off'
            name='searchInput'
            value={props.searchInput}
            onChange={props._onChange}
          />

          <div
            className={`${classes.uploadImageContainer}`}
            onClick={(event) => {
              event.stopPropagation()
              setShowAttributesSearch(true)
            }}
          >
            {props.enableVisualSearch &&
              <img className={classes.uploadIcon} src={uploadIcon} alt="upload=icon" />}
          </div>


          <div
            className={`${classes.uploadImageContainer}`}
            onClick={(event) => {
              event.stopPropagation()
              setShowVisualSearch(true)
            }}
          >
            {props.enableVisualSearch &&
              <img className={classes.cameraIcon} src={cameraIcon} alt="camera=icon" />}
          </div>
          {
            props.searchButton &&
              <input className={classes.buttonHover} id='searchButton' type='submit' value='SEARCH' />
          }
        </div>

        <div className={`${classes.sortForm}`}>
          <span>Sort by </span>
          <select
            onChange={props._onChange}
            value={props.sortByFilter}
            name='sortByFilter'
            className='uppercase'
          >
            <option value='default'>Default</option>
            <option value='descendingPrice'>Price: High to Low</option>
            <option value='ascendingPrice'>Price: Low to High</option>
          </select>
        </div>
      </form>
      <VisualSearchDialog
        show={showVisualSearch}
        uploadOnClick={props.uploadOnClick}
        onClose={() => setShowVisualSearch(false)}
        allCategories={props.allCategories}
        fullbodyUpload={props.fullbodyUpload}
        boundingBoxLocations={props.boundingBoxLocations}
        categoryBoundingBoxLocationMap={props.categoryBoundingBoxLocationMap}
      />
      <AttributesSearchDialog
        onImageAttributesConfirm={props.onImageAttributesConfirm}
        show={showAttributesSearch}
        uploadOnClick={props.uploadOnClick}
        onClose={() => setShowAttributesSearch(false)}
        allCategories={props.allCategories}
        fullbodyUpload={props.fullbodyUpload}
        boundingBoxLocations={props.boundingBoxLocations}
        categoryBoundingBoxLocationMap={props.categoryBoundingBoxLocationMap}
      />
    </div>
  )
}

export default SearchBar
