import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import api from '../../../api/api'
import deleteIcon from '../../../assets/img/deleteIcon.svg'
import CreateCampaign from '../../../components/Campaign/Campaign'
import SearchBar from '../../../components/SearchBar/SearchBar'
import style from './CampaignsMasterStyle.jsx'
import editIcon from '../../../assets/img/editIcon.png'
import viewIcon  from '../../../assets/img/eye-solid.svg'


class Campaigns extends Component {
  constructor (props) {
    super(props)
    this.state = {
      availableOutfits: [],
      // loading: false,
      // error: false,
      campaigns: [],
      campaignMode: '',
      campaignOutfits: [],
      campaignShow: false,
      campaignTags: [],
      chatbotTags: [],
      filteredCampaigns: [],
      newCampaignName: '',
      selectedOutfits: [],
      user: JSON.parse(localStorage.getItem('user')),
      campaignId: null,
    }
  }

  async componentDidMount () {
    await this.getAllCampaigns()
    await this.getChatbotTags()
    await this.getOutfits()
  }

  getAllCampaigns = async () => {
    const campaigns = (await api.getAllCampaigns()).data.campaigns
    await this.setState({ campaigns, filteredCampaigns: campaigns})
  }

  handleFilter = async (e) => {
    const filteredCampaigns = this.state.campaigns.filter(el => el.name.toLowerCase().includes(e.target.value.toLowerCase()))
    await this.setState({filteredCampaigns})
  }

  handleDelete = async (id) => {
    await api.deleteCampaign({ _user: this.state.user._id, _id: id })
    await this.getAllCampaigns()
  }

  handleEdit = async (campaign) => {
    await this.setState({
      campaignShow: true,
      campaignMode: 'create',
      newCampaignName: campaign.name,
      campaignTags: campaign.audience,
      campaignOutfits: campaign.outfits,
      campaignId: campaign._id
    })

  }

  createCampaignClose = async () => {
    const outfits = this.state.selectedOutfits.map(() => false)
    await this.setState({ campaignShow: false, campaignTags: [], newCampaignName: '', selectedOutfits: outfits, campaignId: null })
    await this.getAllCampaigns()
  }

  createCampaignOpen = async () => {
    await this.setState({ campaignShow: true, campaignMode: 'create' })
  }

  getChatbotTags = async () => {
    const tags = (await api.getChatbotTags({ userId: this.state.user._id })).data
    this.setState({ chatbotTags: tags })
  }

  handleAudienceChange = async (e, value) => {
    await this.setState({ campaignTags: value })
  }

  getOutfits = async () => {
    const body = {
      query_restrictions: {user_ids_restriction: this.state.user.favourite_users[0]},
      user_id: this.state.user._id,
      n_queries : 50,
      n_outfits_per_query: 1,
      cached: true,
      outfit_restrictions: {
        user_ids_restriction: this.state.user.favourite_users[0],
        min_approved: 2
      },
      sort_by: { updatedAt: -1 },
      sample_outfits: false,
      extended_images: true,
      ranked: true
    }
    const outfits = (await api.getOutfits(body)).data
    const outfitSelected = outfits.outfits.map(() => false)
    if (outfits) {
      this.setState({ availableOutfits: outfits.outfits, selectedOutfits: outfitSelected })
    }
  }

  handleSelectOutfitClick = (index) => {
    const outfitSelected = this.state.selectedOutfits.slice()
    outfitSelected[index] = !outfitSelected[index]
    this.setState({
      selectedOutfits: outfitSelected
    })
  }

  handleNewCampaignNameChange = async (e) => {
    this.setState ({ newCampaignName: e.target.value.toUpperCase() })
  }

  handleCampaignView = async (campaign) => {
    await this.setState({ newCampaignName: campaign.name, campaignTags: campaign.audience, campaignShow: true, campaignMode: 'view', campaignOutfits: campaign.outfits  })
  }

  setCampaignOutfits = async(outfits) => {
    await this.setState({ campaignOutfits: outfits })
  }

  render () {
    // console.log(this.state)
    const { classes } = this.props
    return (
      <div>
        <div className={classes.header}>
          <div className={classes.searchBar}>
            <SearchBar
              invert
              show
              searchText='Search campaigns'
              showVisualSearchDialog={false}
              _onChange={this.handleFilter}
            />
          </div>
          <div className={`${classes.buttonHover} noselect ${classes.createCampaign}`} onClick={this.createCampaignOpen}>
            <p>CREATE CAMPAIGN</p>
          </div>
        </div>
        { this.state.filteredCampaigns.length !== 0 ?
          <div className={classes.tableContainer}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.tableHeaderCell1}>CAMPAIGN NAME</th>
                  <th className={classes.tableHeaderCell1}>CREATED AT</th>
                  <th className={classes.tableHeaderCell}>SENT DATE</th>
                  <th className={classes.tableHeaderCell}>SENDER</th>
                  <th className={classes.tableHeaderCell} style={{ float: 'right' }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {this.state.filteredCampaigns.map(e => (
                  <tr key={e._id} className={classes.tableRow}>
                    <td className={classes.td}><div className={classes.cell1}><p>{e.name}</p></div></td>
                    <td className={classes.td}><div className={classes.cell2}>
                      <p>
                        {e.createdAt.slice(0, 10).split('-').reverse().join('/') +
                        ' ' + new Date(e.createdAt).getHours() + ':' + new Date(e.createdAt).getMinutes()}
                      </p>
                    </div></td>
                    <td className={classes.td}><div className={classes.cell2}><p>{e.sentDate}</p></div></td>
                    <td className={classes.td}><div className={classes.cell3}><p>{e.sender}</p></div></td>
                    <td className={classes.td}>
                      <div className={classes.cell4}>
                        <img alt='view icon' className={`${classes.viewIcon}`} src={viewIcon} onClick={() => this.handleCampaignView(e)}/>
                        <img alt='edit icon' className={`${classes.editIcon}`} src={editIcon} onClick={(event) => {event.stopPropagation(); this.handleEdit(e)}} />
                        <img alt='delete icon' className={`${classes.deleteIcon}`} src={deleteIcon} onClick={(event) => {event.stopPropagation(); this.handleDelete(e._id)}} />
                      </div>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div> :
          <div className={classes.noCampaigns}>NO CAMPAIGNS AVAILABLE</div>
        }
        <CreateCampaign
          availableOutfits={this.state.availableOutfits}
          campaignId={this.state.campaignId}
          campaignMode={this.state.campaignMode}
          campaignTags={this.state.campaignTags}
          campaignOutfits={this.state.campaignOutfits}
          chatbotTags={this.state.chatbotTags}
          handleNewCampaignNameChange={this.handleNewCampaignNameChange}
          handleAudienceChange={this.handleAudienceChange}
          handleSelectOutfitClick={this.handleSelectOutfitClick}
          newCampaignName={this.state.newCampaignName}
          onClose={this.createCampaignClose}
          outfitSelected={this.state.selectedOutfits}
          show={this.state.campaignShow}
          setCampaignOutfits={this.setCampaignOutfits}
          totalPages={Math.ceil(this.state.availableOutfits.length / 10)}
          user={this.state.user}
        />
      </div>
    )
  }
}

export default withStyles(style)(Campaigns)
