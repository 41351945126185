import React, { Component } from 'react'
import Attribute from './Attribute'
import Avoid from './Avoid'
import Bodytype from './Bodytype'
import Categories from './Categories'
import Gender from './Gender'
import Merchandise from './Merchandise'
import Colour from './Colour'
import Occasion from './Occasion'
import Price from './Price'
import Size from './Size'
import Retailers from './Retailers'
import AgeRange from './AgeRange'

import pear from '../../../assets/img/WearThatSearch/pear_bodytype.png'
import apple from '../../../assets/img/WearThatSearch/apple_bodytype.png'
import hourglass from '../../../assets/img/WearThatSearch/hourglass_bodytype.png'
import inverted_triangle from '../../../assets/img/WearThatSearch/inverted_triangle_bodytype.png'
import rectangle from '../../../assets/img/WearThatSearch/rectangle_bodytype.png'

import '../../../assets/css/WearThatSearch/Filters.scss'

export default class Filters extends Component {
  constructor(props) {
    super(props)
    this._toggleClass = this._toggleClass.bind(this)
    this.state = {
      openedFilters: [],
      checkedForAvoid: false,
      checkedForAttribute: false,
      seasonSelected: [],
      sizeSelected: []
    }
  }
  render() {
    const {
      openedFilters,
      bodytypes,
      seasonSelected,
      sizeSelected
    } = this.state
    const {
      retailer_restriction,
      gender_restriction,
      availableUsers,
      availableCategories,
      availableAgeRanges,
      merchandise,
      category_ids_restriction,
      size_restriction,
      age_range,
      price_restriction,
      colour_restriction,
      occasion_restriction,
      attribute_ids_restriction,
      bodytype_ids_restriction,
      attribute_ids_exclusion,
      loading
    } = this.props
    let ageRangeFilterOptions = {}
    availableAgeRanges.forEach(ageRange => {ageRangeFilterOptions[ageRange.name] = ageRange.name})
    return (
      <div className="filter-col">
        <div className="reset-container uppercase-filter-reset">
          <span className='filter-span'>Filters</span>
          <div className="filter-reset" onClick={this._resetFilters}>
            <i className='fa fa-times'></i>
            RESET ALL
          </div>
        </div>
        <hr style={{marginBottom: '44px'}}/>
        {
          retailer_restriction !== null &&
          <div index="0" onClick={this._toggleClass} className="filter-wrapper">
            <span>
              Retailers
            </span>
            <i
              className={
                openedFilters.includes('0')
                  ? 'fa fa-caret-down flip'
                  : 'fa fa-caret-down'
              }
            />
          </div>
        }
        {openedFilters.includes('0') && (
          <Retailers
            availableUsers={availableUsers}
            retailer_restriction={retailer_restriction}
            selectFilter={this._selectFilter}
            handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}

        <div index="1" onClick={this._toggleClass} className="filter-wrapper">
          <span>Section</span>
          <i
            className={
              openedFilters.includes('1')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('1') && (
          <Gender
            gender_restriction={gender_restriction}
            selectFilter={this._selectFilter}
            handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}
        <div index="2" onClick={this._toggleClass} className="filter-wrapper">
          <span>Merchandise</span>
          <i
            className={
              openedFilters.includes('2')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('2') && (
          <Merchandise
            merchandise={merchandise}
            selectFilter={this._selectFilter}
            handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}
        <div index="3" onClick={this._toggleClass} className="filter-wrapper">
          <span>Categories</span>
          <i
            className={
              openedFilters.includes('3')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('3') && (
          <Categories
            availableCategories={availableCategories}
            category_ids_restriction={category_ids_restriction}
            selectFilter={this._selectFilter}
            handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}

        <div index="4" onClick={this._toggleClass} className="filter-wrapper">
          <span>Size</span>
          <i
            className={
              openedFilters.includes('4')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('5') && (
          <Size
            size_restriction={size_restriction}
            selectFilter={this._selectFilter}
            loading={loading}
            handleCheckboxChange={this._handleCheckboxChange}
            sizeSelected={sizeSelected}
            selectSizeCategory={this._selectSizeCategory}
            onChange={this.props._onChange}
          />
        )}

        <div index="5" onClick={this._toggleClass} className="filter-wrapper">
          <span>Age Range</span>
          <i
            className={
              openedFilters.includes('5')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('5') && (
          <AgeRange
            age_range={age_range}
            filterOptions={ageRangeFilterOptions}
            selectFilter={this._selectFilter}
            handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}

        <div index="6" onClick={this._toggleClass} className="filter-wrapper">
          <span>Price Range</span>
          <i
            className={
              openedFilters.includes('6')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('6') && (
          <Price
            price_restriction={price_restriction}
            selectPriceRange={this.props._selectPriceRange}
            loading={loading}
          />
        )}

        <div index="7" onClick={this._toggleClass} className="filter-wrapper">
          <span>Colour</span>
          <i
            className={
              openedFilters.includes('7')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('7') && (
          <Colour
            seasonSelected={seasonSelected}
            colour_restriction={colour_restriction}
            selectFilter={this._selectFilter}
            selectSeason={this._selectSeason}
            loading={loading}
          />
        )}

        <div index="8" onClick={this._toggleClass} className="filter-wrapper">
          <span>Attribute</span>
          <i
            className={
              openedFilters.includes('8')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('8') && (
          <Attribute
            attribute_ids_restriction={attribute_ids_restriction}
            selectFilter={this._selectFilter}
            _handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}

        <div index="9" onClick={this._toggleClass} className="filter-wrapper">
          <span>Avoid</span>
          <i
            className={
              openedFilters.includes('9')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('9') && (
          <Avoid
            attribute_ids_exclusion={attribute_ids_exclusion}
            selectFilter={this._selectFilter}
            _handleCheckboxChange={this._handleCheckboxChange}
            loading={loading}
          />
        )}

        <div index="10" onClick={this._toggleClass} className="filter-wrapper">
          <span>Occasion</span>
          <i
            className={
              openedFilters.includes('10')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('10') && (
          <Occasion
            handleCheckboxChange={this._handleCheckboxChange}
            occasion_restriction={occasion_restriction}
            selectFilter={this._selectFilter}
            loading={loading}
          />
        )}

        <div index="11" onClick={this._toggleClass} className="filter-wrapper">
          <span>Bodytype</span>
          <i
            className={
              openedFilters.includes('11')
                ? 'fa fa-caret-down flip'
                : 'fa fa-caret-down'
            }
          />
        </div>
        {openedFilters.includes('11') && (
          <Bodytype
            chooseImage={this.chooseImage}
            bodytype_ids_restriction={bodytype_ids_restriction}
            selectFilter={this._selectFilter}
            bodytypes={bodytypes}
            loading={loading}
          />
        )}
      </div>
    )
  }

  _toggleClass = e => {
    e.preventDefault()
    let clickedIndex = e.currentTarget.getAttribute('index')
    let opened = [...this.state.openedFilters]
    let indexNumFound = opened.indexOf(clickedIndex)
    if (indexNumFound >= 0) {
      opened.splice(indexNumFound, 1)
    } else {
      opened.push(clickedIndex)
    }
    this.setState({
      openedFilters: opened
    })
  };

  _resetFilters = e => {
    e.preventDefault()
    this.setState(
      {
        openedFilters: []
      },
      this.props._resetFilters
    )
  };

  _selectFilter = e => {
    e.preventDefault()
    if (this.props.loading) return
    const name = e.target.getAttribute('name')
    if (name === 'price_selected') {
      const value = {
        min: e.target.getAttribute('min'),
        max: e.target.getAttribute('max')
      }
      this.props._filterOnChange(name, value)
    } else if (name === 'colour_restriction') {
      const value = {
        season: e.target.getAttribute('season'),
        colorChosen: e.target.getAttribute('value')
      }
      this.props._filterOnChange(name, value)
    } else {
      const value = e.target.getAttribute('value')
      this.props._filterOnChange(name, value)
    }
  };

  chooseImage = currValue => {
    switch (currValue) {
    case 'Hourglass':
      return hourglass
    case 'Apple':
      return apple
    case 'Pear':
      return pear
    case 'Inverted-Triangle':
      return inverted_triangle
    case 'Rectangle':
      return rectangle
    default:
      return null
    }
  };

  _handleCheckboxChange = e => {
    const name = e.target.name
    if (name === 'size_restriction') {
      const value = {
        sizeType: e.target.getAttribute('sizetype'),
        sizeChosen: e.target.value
      }
      this.props._filterOnChange(name, value)
    }
    this.props._filterOnChange(name, e.target.value)
  };

  _selectSeason = e => {
    e.preventDefault()
    let clickedSeason = e.currentTarget.getAttribute('value')
    let selected = [...this.state.seasonSelected]
    let indexNumFound = selected.indexOf(clickedSeason)
    if (indexNumFound >= 0) {
      selected.splice(indexNumFound, 1)
    } else {
      selected.push(clickedSeason)
    }
    this.setState({ seasonSelected: selected })
  };

  _selectSizeCategory = e => {
    e.preventDefault()
    let clickedSize = e.currentTarget.getAttribute('value')
    let selected = [...this.state.sizeSelected]
    let indexNumFound = selected.indexOf(clickedSize)
    if (indexNumFound >= 0) {
      selected.splice(indexNumFound, 1)
    } else {
      selected.push(clickedSize)
    }
    this.setState({ sizeSelected: selected })
  };
}
