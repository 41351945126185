import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    width: '100%',
    height: '100%'
  },
  titleText: {
    display: 'inline-block',
    // width: '135px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    marginBottom: '5px',
    marginTop: '5px',
    textTransform: 'uppercase'
  },
  autoComplete: {
    display: 'inline-block',
    boxSizing: 'border-box',
    width: '100%',
    height: '50px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px'
    }
  }
}))

export default function AutocompleteDropdown (props) {
  const classes = useStyles()

  // Requires props:
  //  - title - str
  //  - options - list of objects with .name values
  //  - onChange - function taking (event, value) parameters
  //  - placeholder - str
  //  - value - str
  return (
    <div className={classes.root}>
      <p className={classes.titleText}>{props.title}</p>
      <Autocomplete
        freeSolo
        disabled={props.disabled}
        className={classes.autoComplete}
        options={props.options}
        getOptionSelected={(a, b) => a.name === b.name}
        getOptionLabel={option => {
          if (
            option === '' ||
            option === undefined ||
            option.length === 0
          ) {
            return ''
          } else {
            return option.name
          }
        }}
        onChange={props.onChange}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            label=''
            placeholder={props.placeholder}
            margin='normal'
            fullWidth
          />
        )}
        value={props.value}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue)
          const parts = parse(option.name, matches)

          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}
