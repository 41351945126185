module.exports = {
  root: {
    marginLeft: '-30px',
    marginTop: '-30px'
  },
  rootBlurred: {
    marginLeft: '-30px',
    marginTop: '-30px',
    filter: 'blur(4px)'
  },
  ruleBar: {
    display: 'inline-flex',
    height: '110px',
    background: 'white',
    width: 'calc(100% + 40px)',
    border: '1px solid #DAE0EA',
    maxWidth: '1370px',
  },
  text: {
    height: '24px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  source: {
    display: 'inline-block',
    paddingTop: '17.5px',
    marginLeft: '32px',
    maxWidth: '250px',
    flex: 1
  },
  sourceSelect: {
    '&:hover': {
      borderColor: 'black'
    },
    height: '50px',
    backgroundColor: 'white',
    marginTop: '-16px',
    borderRadius: '1px',
    display: 'inline-block',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px'
    },
    width: '100%'
  },
  category: {
    display: 'inline-block',
    paddingTop: '17.5px',
    marginLeft: '20px',
    flex: 1
  },
  categorySelect: {
    '&:hover': {
      borderColor: 'black'
    },
    height: '50px',
    backgroundColor: 'white',
    borderRadius: '1px',
    display: 'inline-block',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px',
      background: 'white'
    },
    width: '100%'
  },
  textInSelect: {
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  gender: {
    display: 'inline-block',
    flex: 'none',
    marginLeft: '30px',
    paddingTop: '17.5px'
  },
  genderActive: {
    filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%) !important',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15) !important'
  },
  manIcon: {
    '&:hover': {
      // borderColor: '#86A8E0',
      filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%)',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)'
    },
    height: '24px',
    border: '1px solid ',
    boxSizing: 'content-box',
    borderRadius: '2px',
    padding: '13px 12px 13px 12px',
    borderColor: '#999999',
    filter: 'opacity(0.2)'
  },
  womanIcon: {
    '&:hover': {
      borderColor: '#86A8E0',
      filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%)'
    },
    height: '34px',
    border: '1px solid',
    boxSizing: 'content-box',
    borderRadius: '2px',
    padding: '8px 8px 8px 8px',
    borderColor: '#999999',
    filter: 'opacity(0.2)'
  },
  noOutfitsText: {
    height: '56px',
    width: '415px',
    color: '#666666',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    lineHeight: '28px',
    textAlign: 'center',
    marginTop: '45px',
    marginLeft: '421.5px'
  },
  placeholderPic: {
    marginTop: '10px',
    width: '732px',
    height: '486px',
    marginLeft: '272px'
  },
  advancedSearchButton: {
    display: 'inline-block',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#86A8E0',
    marginLeft: '34px',
    marginTop: '45px',
    paddingTop: '13.5px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    height: '48px',
    width: '48px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  reset: {
    display: 'inline-block',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#86A8E0',
    marginLeft: '34px',
    marginTop: '45px',
    paddingTop: '13.5px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    height: '48px',
    width: '155px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  applyButton: {
    display: 'inline-block',
    height: '48px',
    width: '155px',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    marginTop: '45px',
    marginLeft: '20px',
    color: '#FFFFFF',
    marginRight: '35px'
  },
  applyButtonDisabled: {
    display: 'inline-block',
    height: '48px',
    width: '155px',
    borderRadius: '5px',
    backgroundColor: '#a0a0a0',
    marginTop: '45px',
    marginLeft: '20px',
    color: '#FFFFFF',
    marginRight: '35px',
    pointerEvents: 'none'
  },
  applyText: {
    height: '19px',
    width: '59px',
    margin: 'auto',
    paddingTop: '15px',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'center',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  contentContainer: {
    marginLeft: '33px'
  },
  mainContent: {
    display: 'grid',
    '@media only screen and (min-width: 700px)': {
      gridTemplateColumns: '250px calc(100% - 265px)'
    },
    '@media only screen and (min-width: 1365px)': {
      gridTemplateColumns: '343px 930px'
    },
    '@media only screen and (min-width: 1439px)': {
      gridTemplateColumns: '343px 933px'
    },
    gridGap: '0 24px'
  },
  actionBar: {
    width: 'calc(100% + 40px)',
    position: 'sticky',
    top: '70px',
    zIndex: '1000',
    maxWidth: '1370px',
    borderBottom: '1px solid #DAE0EA',
    borderLeft: '1px solid #DAE0EA',
    borderRight: '1px solid #DAE0EA',
    paddingLeft: '20px',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: 'white',
    marginBottom: '20px',
    display: 'block',
    '@media only screen and (min-width: 700px)': {
      flexWrap: 'wrap'
    },
    '@media only screen and (min-width: 1365px)': {
      flexWrap: 'nowrap'
    },
    '@media only screen and (min-width: 1439px)': {
      flexWrap: 'nowrap'
    }
  },
  controlButtons: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '@media only screen and (min-width: 700px)': {
      width: '500px',
      marginLeft: '310px',
      padding: '20px'
    },
    '@media only screen and (min-width: 1365px)': {
      width: '476px',
      marginLeft: 0,
      padding: 0
    },
    '@media only screen and (min-width: 1439px)': {
      width: '550px',
      marginLeft: 0,
      padding: 0
    }
  },
  outfitsText: {
    marginLeft: '1px',
    height: '38px',
    width: '170px',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '50px',
    display: 'inline-block',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    '@media only screen and (min-width: 700px)': {
      marginBottom: '0px !important'
    },
  },
  approveContainerDisabled: {
    display: 'inline-flex',
    height: '50px',
    width: '380px',
    border: '2px solid #999999',
    borderRadius: '5px',
    backgroundColor: '#999999',
    marginLeft: '200px',
    '& i': {
      color: '#FFFFFF'
    },
  },
  approveContainer: {
    display: 'inline-flex',
    height: '50px',
    width: '380px',
    border: '1px solid #DAE0EA',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginLeft: '200px',
    '& i': {
      color: '#DAE0EA'
    }
  },
  approveContainerChild: {
    margin: 'auto',
    display: 'flex'
  },
  approveText: {
    display: 'inline-block',
    height: '19px',
    // width: '249px',
    opacity: '0.5',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'center',
    marginTop: '17px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  approveIcon: {
    height: '16px',
    width: '22px',
    display: 'inline-block',
    margin: 'auto'
  },
  approveContainerSelected: {
    cursor: 'pointer',
    display: 'inline-flex',
    height: '50px',
    width: '380px',
    border: '2px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    marginLeft: '200px',
    '& i': {
      color: '#FFFFFF'
    },
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  approveTextSelected: {
    display: 'inline-block',
    height: '19px',
    // width: '268px',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'center',
    marginTop: '16px',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  buttonContainerEnabled: {
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)',
    backgroundColor: '#FFFFFF'
  },
  buttonContainerDisabled: {
    backgroundColor: '#FFFFFF'
  },
  statsContainer: {
    display: 'inline-flex',
    height: '50px',
    width: '100px',
    borderRadius: '5px',
    '@media only screen and (min-width: 700px)': {
      marginLeft: '0px'
    },
    '@media only screen and (min-width: 1365px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1439px)': {
      marginLeft: '21px'
    },
    '& img': {
      marginTop: '17px',
      marginLeft: '15px',
      display: 'block',
      height: '16px',
      filter: 'invert(72%) sepia(5%) saturate(4531%) hue-rotate(187deg) brightness(94%) contrast(86%)'
    },
    '& p': {
      height: '19px',
      // width: '39px',
      color: '#666666',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '16px',
      margin: 'auto',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  skipContainer: {
    display: 'inline-flex',
    height: '50px',
    width: '97px',
    borderRadius: '5px',
    '@media only screen and (min-width: 700px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1365px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1439px)': {
      marginLeft: '21px'
    },
    '& img': {
      marginTop: '17px',
      marginLeft: '20px',
      display: 'block',
      height: '16px'
    },
    '& p': {
      height: '19px',
      width: '39px',
      color: '#666666',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '16px',
      marginLeft: '10px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  undoContainer: {
    display: 'inline-flex',
    height: '50px',
    width: '117px',
    borderRadius: '5px',
    '@media only screen and (min-width: 700px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1365px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1439px)': {
      marginLeft: '21px'
    },
    '& img': {
      marginTop: '17px',
      marginLeft: '13px',
      display: 'block',
      height: '13px'
    },
    '& p': {
      height: '19px',
      width: '53px',
      color: '#666666',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '16px',
      marginLeft: '9px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  redoContainer: {
    display: 'inline-flex',
    height: '50px',
    width: '117px',
    borderRadius: '5px',
    '@media only screen and (min-width: 700px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1365px)': {
      marginLeft: '15px'
    },
    '@media only screen and (min-width: 1439px)': {
      marginLeft: '18px'
    },
    '& img': {
      marginTop: '17px',
      marginLeft: '13px',
      display: 'block',
      height: '13px'
    },
    '& p': {
      height: '19px',
      width: '53px',
      color: '#666666',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      display: 'inline-block',
      marginTop: '16px',
      marginLeft: '9px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none'
    }
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  sidebar: {
    margin: '0 0 0'
    // position: 'fixed'
    // width: '25%',
    // position: 'fixed',
    // height: '66%',
    // overflow: 'auto'
  },
  outfitContainer: {
    boxSizing: 'border-box',
    // "height": "490px",
    '@media only screen and (min-width: 700px)': {
      width: 'auto'
    },
    '@media only screen and (min-width: 1365px)': {
      width: '860px'
    },
    '@media only screen and (min-width: 1439px)': {
      width: '935px'
    },
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginBottom: '20px'
  },
  imageCarouselClassName: {
    borderBottom: '1px solid #DAE0EA'
  },
  outfitGarmentClassName: {
    border: '1px solid #DAE0EA',
    borderRadius: '5px'
  }
}
