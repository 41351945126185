import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function SourceAccordionSection(props) {
  const getLabels = async () => {
    let newLabels = await api.getUsers({url: props.url})
    const storedUser = JSON.parse(localStorage.getItem('user'))

    try {
      newLabels.forEach(label => {
        label.active = false
        label.name = label.corpname
      })
      newLabels[0].active = true
      if (storedUser.access === 'client') {
        const labels = newLabels.filter((label) =>  label.username === storedUser.feSettings.availableUsers[0])
        labels[0].active = true
        return labels
      }
      return newLabels
    } catch {
      console.error('Failed to get users')
      return []
    }
  }

  return (
    <AbstractAccordionSection
      title={'Source'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}