import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ImageCarousel from './ImageCarousel'
import LabelList from './LabelList'
import LabelDict from './LabelDict'
import Outfit from './Outfit'
import Similarity from './Similarity'
import Description from './Description'
import AIDescriptions from './AIDescriptions'
import settings from '../../config/setting-values'
import api from '../../api/api'


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  topBar: {
    width: '100%',
    height: '50px'
  },
  productPageContainer: {
    width: '60%',
    minWidth: '380px',
    maxWidth: '1000px',
    margin: '20px auto'
  },
  productPageContainerMobile: {
    width: '95%',
    margin: '20px auto'
  },
  productInfoContainer: {
    display: 'flex',
    flexDirection: 'row'
    // marginBottom: '50px'
  },
  imageCarouselContainer: {
    width: '50%',
    height: '100%',
    margin: '20px',
    padding: 'auto'
  },
  productInfoTextContainer: {
    width: '60%',
    margin: '20px 0 20px 20px'
  },
  remoteProductInfoContainer: {
    height: 'auto',
    marginBottom: '10px'
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 'fit-content'
  },
  backText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 3px'
  },
  searchPageText: {
    fontFamily: 'Montserrat',
    fontSize: '0.7em',
    marginBottom: '0px',
    margin: 'auto 3px'
  },
  labelColumns: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelColumnsMarginTop: {
    marginTop: '50px'
  },
  labelColumn: {
    width: '50%'
  },
  productTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  productBrand: {
    fontFamily: 'Montserrat',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px'
  }
}))

export default function ProductPage(props) {
  const classes = useStyles()
  const [outfitLiked, setOutfitLiked] = useState(false)
  const [outfitIntelistyle, setOutfitIntelistyle] = useState(null)
  const [outfitLikedOpponent, setOutfitLikedOpponent] = useState(false)
  const [outfitOpponent, setOutfitOpponent] = useState(null)
  const [outfitOrder, setOutfitOrder] = useState(Math.floor(Math.random() * 2)) // Use 0 to display Intelistyle outfits first otherwise
  const user = JSON.parse(localStorage.getItem('user'))

  // Math.floor(Math.random() * 2) to randomise order.

  // NOTE - this is a hack to get zalando products to display our outfits alongside theirs (this is the only location
  // where code related to this hack is located)
  let outfits = true
  let showdownOutfits = false
  let showProductSimilars = true

  let showIntelistyleOutfits = false
  let showIntelistyleSimilars = false

  let allowCrossUserOutfits = false
  let outfitLikeButton = false
  let cachedOutfitsIntelistyle = false
  let cachedOutfitsOpponent = false
  let cachedSimilars = false
  let occasionSelect = true
  let personaSelect = false
  let outfitsTitle = 'How To Style It'
  let outfitsTitleOpponent = ''
  let similarsTitle = 'Similar Items'
  let intelistyleOutfitsSource = 'Intelistyle'
  let opponentOutfitsSource = ''
  let restrictSimilarsBySubcategory = false
  let permittedOccasions = null
  let permittedPersonas = null

  let outfitRestrictions = null
  let outfitGroupRestrictions = null
  let outfitRules = null
  let locationContainGroups = null
  let similarRestrictions = {
    user_ids_restriction: props.garment._user
  }
  let outfitRestrictionsOpponent = {
    user_ids_restriction: props.garment._user
  }

  // User overrides
  if (user.feSettings.showProductOutfits !== undefined) {
    outfits = user.feSettings.showProductOutfits
  }
  if (user.feSettings.showProductSimilars !== undefined) {
    showProductSimilars = user.feSettings.showProductSimilars
  }

  // Demo user
  if (props.garment._user === '5e78cc935a25538b2b8ea65e') {
    outfits = false
    restrictSimilarsBySubcategory = true
    showdownOutfits = false
    showIntelistyleOutfits = true
    showIntelistyleSimilars = true
    allowCrossUserOutfits = true
    outfitRestrictionsOpponent = {
      approved: -3
    }
  } else if (props.garment._user === '609e92621dd718a8e6335f91') {
    // Zadig
    outfits = false
  } else if (props.garment._user === '625fba5d31fd666438aacf93') {
    // Lectra
    outfits = false

  } else if (props.garment._user === '5ef62f4ccd6ae20be24a20f6') {
    // Vakko Upload
    allowCrossUserOutfits = true
    outfitRestrictions = {
      user_ids_restriction: '5efc683688df633e512f34af'
    }
    similarRestrictions = {
      user_ids_restriction: '5efc683688df633e512f34af'
    }
    occasionSelect = true
  } else if (props.garment._user === '5f3fa87f1e54bd5118dc6043') {
    // LCW
    showdownOutfits = true
    outfitLikeButton = true
    cachedOutfitsIntelistyle = true
    cachedOutfitsOpponent = true
    occasionSelect = false
    outfitsTitle = 'How To Style It - Intelistyle'
    outfitsTitleOpponent = 'How To Style It - LC Waikiki'
    opponentOutfitsSource = 'lcw'
    outfitRestrictions = {
      approved: 2
    }

    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f58a47a96a5e1bb76b021a3') {
    // LCW (QA)
    showdownOutfits = true
    outfitLikeButton = true
    cachedOutfitsIntelistyle = true
    cachedOutfitsOpponent = true
    occasionSelect = false
    outfitsTitle = 'How To Style It - Intelistyle'
    outfitsTitleOpponent = 'How To Style It - LC Waikiki'
    opponentOutfitsSource = 'lcw'
    outfitRestrictions = {
      approved: 2
    }
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f72487d903377a6319176e0') {
    // LC Waikiki (QA)
    showdownOutfits = true
    cachedOutfitsIntelistyle = true
    cachedOutfitsOpponent = true
    occasionSelect = false
    outfitsTitle = 'How To Style It - Intelistyle'
    outfitsTitleOpponent = 'How To Style It - WideEyes'
    opponentOutfitsSource = 'LC Waikiki (QA)'
    outfitRestrictions = {
      approved: 2
    }
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f724894903377a6319176e1') {
    // LC Waikiki (Non-QA)
    showdownOutfits = true
    cachedOutfitsIntelistyle = false
    cachedOutfitsOpponent = true
    occasionSelect = true
    personaSelect = true
    outfitsTitle = 'How To Style It - Intelistyle'
    outfitsTitleOpponent = 'How To Style It - WideEyes'
    opponentOutfitsSource = 'LC Waikiki (Non-QA)'
    outfitGroupRestrictions = [
      {
        'restrictions': {
          'remote_product_categories': 'Maternity'
        },
        'strength': 'soft'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Plus-Size'
        },
        'strength': 'soft'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Summer'
        },
        'strength': 'hard'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Winter'
        },
        'strength': 'hard'
      }
    ]
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f745c9110817c8c3fc07832') {
    // LC Waikiki (Non-QA Mens)
    showdownOutfits = true
    cachedOutfitsIntelistyle = false
    occasionSelect = true
    personaSelect = true
    outfitsTitle = 'How To Style It'
    opponentOutfitsSource = 'LC Waikiki (Non-QA)'
    outfitRestrictions = {
      user_ids_restriction: '5f745c9110817c8c3fc07832'
    }
    outfitGroupRestrictions = [
      {
        'restrictions': {
          'remote_product_categories': 'Maternity'
        },
        'strength': 'soft'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Plus-Size'
        },
        'strength': 'soft'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Summer'
        },
        'strength': 'hard'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Winter'
        },
        'strength': 'hard'
      }
    ]
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f7248b1903377a6319176e2') {
    // LC Waikiki (Maternity)
    occasionSelect = true
    personaSelect = true
    allowCrossUserOutfits = true
    outfitGroupRestrictions = [
      {
        'restrictions': {
          'remote_product_categories': 'Maternity'
        },
        'strength': 'soft'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Summer'
        },
        'strength': 'hard'
      },
      {
        'restrictions': {
          'remote_product_categories': 'Winter'
        },
        'strength': 'hard'
      }
    ]
    similarRestrictions = {
      ...similarRestrictions,
      approved: true
    }
  } else if (props.garment._user === '5f7248c6903377a6319176e3') {
    // LC Waikiki (Family)
    cachedSimilars = true
    similarsTitle = 'Family Collection'
  } else if (props.garment._user === '5f7248d7903377a6319176e4') {
    // LC Waikiki (Kids)
    occasionSelect = false
    personaSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      approved: 2
    }
    similarRestrictions = {
      user_ids_restriction: '5f7248d7903377a6319176e4'
    }
  } else if (props.garment._user === '5f7248f6903377a6319176e5') {
    // LC Waikiki (Baby)
    occasionSelect = false
    personaSelect = false
    cachedOutfitsIntelistyle = true
    allowCrossUserOutfits = true
    outfitRestrictions = {
      approved: 2
    }
  } else if (props.garment._user === '5f7272196bdaea6841edc537') {
    // LC Waikiki Upload
  } else if (props.garment._user === '5f842ec4274de1d1ea354368') {
    // LC Waikiki (Bikini)
    showdownOutfits = false
    cachedOutfitsIntelistyle = true
    occasionSelect = false
    outfitsTitle = 'Matching Set'
    outfitRestrictions = {
      approved: 2
    }
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5f7c87c87f0ebdcd2f7d2acc') {
    // LC Waikiki (Fullbody)
    showdownOutfits = false

    cachedOutfitsIntelistyle = true
    occasionSelect = false
    outfitRestrictions = {
      approved: 2
    }
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  } else if (props.garment._user === '5fc670d55689c2dcdb0bcac0') {
    // shopntrade
    outfitRestrictions = {
      ...outfitRestrictions,
      attribute_ids_restriction: [69],
      min_approved: 0,
    }
    cachedOutfitsIntelistyle = true
    cachedSimilars = true
  } else if (props.garment._user === '601be0d17dbd838613ad1955') {
    // Retykle
    occasionSelect = false
  } else if (props.garment.source === 'sandbella') {
    // Sandbella
    outfits = false
    cachedSimilars = true
  } else if (props.garment.source === 'thefashionkingdom.myshopify.com') {
    // TFK
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
    cachedOutfitsIntelistyle = true
    cachedSimilars = true
  } else if (props.garment._user === '603e2ff8be8c0f03e04d82fb') {
    // Evereve
    occasionSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 1,
    }
  } else if (props.garment._user === '5ffb6fc228c83940ef920548') {
    // Yoox
    occasionSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 1,
    }
    cachedSimilars = true
  } else if (props.garment._user === '60f6077d21829a7787a3d4a8') {
    // Nykaa
    occasionSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
    cachedSimilars = true
  } else if (props.garment.source === 'asda') {
    cachedSimilars = true
    occasionSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
  } else if (props.garment.source === 'alyasra') {
    occasionSelect = false
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
  } else if (props.garment.source.indexOf('Tommy') > -1) {
    cachedSimilars = true
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
  } else if (props.garment.source === 'esprit') {
    showIntelistyleOutfits = false
    showIntelistyleSimilars = false
    showProductSimilars = false
    outfits = false
  } else if (props.garment.source === 'barcin') {
    cachedSimilars = true
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      min_approved: 0,
    }
  } else if (props.garment._user === '60266a578c73d4089dba1648') {
    // Modanisa
    occasionSelect = true
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      ...outfitRestrictions,
      approved: 2
    }
    outfitGroupRestrictions = [
      {
        'restrictions': {
          'remote_product_categories': [
            'Hamile',
            'Hamile Bluz/Gömlek',
            'Hamile Elbise',
            'Hamile Giyim',
            'Hamile Pantolon',
            'Hamile Pijama',
            'Hamile Tunik / T-Shirt',
            'Hamile Yelek'
          ]
        },
        'strength': 'soft'
      }
    ]

    permittedOccasions = props.occasions.filter(occasion => [3, 9, 11].includes(occasion._id))
  } else if (props.garment.source === 'zadig') {
    showIntelistyleOutfits = false
    outfits = false
  } else if (props.garment._user === '5f4783fbddd1325501b2b96e') {
    // What we do depends on the user
    if (user._id === '5f3fea9289865a71903faeb3') {
      // Demo Upload
      occasionSelect = true
      allowCrossUserOutfits = true
      outfitRestrictions = {
        user_ids_restriction: '5f3fa11ad90a32b78e78dd83',
        post_process_filters: {
          categories: [17, 18]
        }
      }
      similarRestrictions = {
        user_ids_restriction: '5f3fa11ad90a32b78e78dd83'
      }
    }
  } else if (props.garment._user === '60f81165e479c131878e614e') {
    // Gameday couture
    cachedOutfitsIntelistyle = true
    outfitRestrictions = {
      min_approved: -2
    }

    // Used cached outfits for STL only
    if (props.garment.additional_ids && props.garment.additional_ids.includes('stl')) {
      outfitsTitle = 'Shop The Look'
    }
  } else if (props.garment._user === '6188459034cd91889a3985e0') {
    // Crew
    cachedOutfitsIntelistyle = true
    occasionSelect = false
    personaSelect = true
    outfitRestrictions = {
      min_approved: 2
    }
    let outfitPersonas = props.garment.persona
    if (props.garment.outfit_garment_ids) {
      outfitPersonas = props.garment.outfit_garment_ids.filter(outfit => outfit.persona).map(outfit => outfit.persona[0])
    }
    permittedPersonas = props.personas.filter(persona => outfitPersonas.includes(persona._id))
  } else if (props.garment.source === 'orchardmile') {
    // Orchard Mile
    cachedSimilars = true
    cachedOutfitsIntelistyle = true
    occasionSelect = true
    personaSelect = false
    outfitRestrictions = {
      min_approved: 0
    }
  } else if (props.garment.source === 'hugoboss') {
    cachedOutfitsIntelistyle = true
    allowCrossUserOutfits = true
    occasionSelect = false
    outfitRestrictions = {
      min_approved: 0,
      user_ids_restriction: '61f25d2f544c9a8cfc154204'
    }
  } else {
    if (outfitOrder !== 0) {
      setOutfitOrder(0)
    }
  }

  const getLanguage = () => {
    let language = 'en'
    if (user.feSettings.language !== undefined) {
      language = user.feSettings.language
    }
    return language
  }

  const guid = () => {
    let dt = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  }

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  const getCookie = cname => {
    const name = cname + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  const checkCookie = () => {
    let user = getCookie('visitor_id')
    if (user !== '') {
      return user
    } else {
      user = guid()
      if (user !== '' && user != null) {
        setCookie('visitor_id', user, 365)
        return user
      }
    }
  }

  const convertOutfit = outfit => {
    return {
      garment_ids: outfit.garments.map(garment => garment._id),
      approved: outfit.approved,
      bodytype: outfit.bodytype,
      occasion: outfit.occasion,
      weather: outfit.weather,
      persona: outfit.persona,
      skintone: outfit.skintone,
      styling_filters: outfit.styling_filters,
      source: outfit.source
    }
  }

  const renameAttributes = attribute => {
    // return attribute.name;
    // let attributeName = attribute.name
    // if (attributeName === 'Leather-Faux Leather') {
    //   attributeName = 'Faux Leather'
    // }
    // else if (attributeName === 'Cotton') {
    //   attributeName = 'Cotton Blend'
    // }
    // else if (attributeName === 'Synthetics') {
    //   attributeName = 'Polyester'
    // }
    // else if (attributeName === 'Wool-Cashmere') {
    //   attributeName = 'Wool Blend'
    // }
    // else if (attributeName === 'Studded-Casual') {
    //   attributeName = 'Studs'
    // }
    // else if (attributeName === 'Transparent') {
    //   attributeName = 'Sheer'
    // }
    // else if (attributeName === 'Floral') {
    //   attributeName = 'Floral Print'
    // }
    // else if (attributeName === 'Polka Dot') {
    //   attributeName = 'Polka Dot Print'
    // }
    // else if (attributeName === 'Checked') {
    //   attributeName = 'Check / Plaid Print'
    // }
    // else if (attributeName === 'Animal Print') {
    //   attributeName = 'Leopard Print'
    // }
    // else if (attributeName === 'Monogram') {
    //   attributeName = 'Printed Logo'
    // }
    // else if (attributeName === 'Camo') {
    //   attributeName = 'Camouflage Print'
    // }
    // else if (attributeName === 'Cargo') {
    //   attributeName = 'Cargo Pocket'
    // }
    // else if (attributeName === 'Metallic') {
    //   attributeName = 'Metallic Effect'
    // }
    // else if (attributeName === 'Pastel') {
    //   attributeName = 'Pastel Tones'
    // }
    // else if (attributeName === 'Slouchy') {
    //   attributeName = 'Baggy'
    // }
    // else if (attributeName === 'Asymetrical') {
    //   attributeName = 'Asymmetric Hem'
    // }
    // else if (attributeName === 'Low Waisted') {
    //   attributeName = 'Low-Rise'
    // }
    // else if (attributeName === 'High Waisted') {
    //   attributeName = 'High-Rise'
    // }
    // else if (attributeName === 'Neon') {
    //   attributeName = 'Fluorescent'
    // }
    // else if (attributeName === 'Tie-Front') {
    //   attributeName = 'Tie Fastening'
    // }
    // else if (attributeName === 'Chunky Shoes') {
    //   attributeName = 'Chunky'
    // }
    // else if (attributeName === 'Straight') {
    //   attributeName = 'Straight Cut'
    // }
    // else if (attributeName === 'Micro') {
    //   attributeName = 'Thigh-Length'
    // }
    // else if (attributeName === 'Mini') {
    //   attributeName = 'Mid-Thigh Length'
    // }
    // else if (attributeName === 'Midi') {
    //   attributeName = 'Calf-Length'
    // }
    // else if (attributeName === 'Maxi') {
    //   attributeName = 'Full Length'
    // }
    // else if (attributeName === 'Turtleneck (Polo-Neck)') {
    //   attributeName = 'Turtleneck'
    // }

    // attribute.name = attributeName

    return attribute
  }

  const groupAttributes = attributes => {
    let attributeCategoryNamesByIds = {}
    props.attributeCategories.forEach(attributeCategory => {
      attributeCategoryNamesByIds[attributeCategory._id] = attributeCategory.name
    })

    let groupedAttributes = {}
    attributes.forEach(attribute => {
      Object.entries(props.attributeCategoryAttributeMap).forEach(([attributeCategoryId, ids]) => {
        let attributeCategory = attributeCategoryNamesByIds[attributeCategoryId]
        if (ids.includes(attribute._id)) {
          if (groupedAttributes[attributeCategory] === undefined) {
            groupedAttributes[attributeCategory] = [attribute.name]
          } else {
            groupedAttributes[attributeCategory].push(attribute.name)
          }
        }
      })
    })

    return groupedAttributes
  }

  const outfitLikeButtonOnClick = outfit => {
    if (!outfitLiked && outfitLikedOpponent) {
      // Send unliked outfit event for zalando outfit
      api.likedOutfitEvent({
        visitor_id: checkCookie(),
        outfit: convertOutfit(outfitOpponent),
        event_type: 'unlike'
      })

      // Set like value of zalando outfit to false
      setOutfitLikedOpponent(false)
    }

    // Send appropriate like or unlike event for intelistyle outfit
    api.likedOutfitEvent({
      visitor_id: checkCookie(),
      outfit: convertOutfit(outfit),
      event_type: outfitLiked ? 'unlike' : 'like'
    })

    // Toggle the liked state of the outfit
    setOutfitLiked(!outfitLiked)

    // Save the outfit to state in case it is unliked later
    if (outfitIntelistyle === null) {
      setOutfitIntelistyle(outfit)
    }
  }

  const outfitLikeButtonOnClickOpponent = outfit => {
    if (!outfitLikedOpponent && outfitLiked) {
      // Send unliked outfit event for intelistyle outfit
      api.likedOutfitEvent({
        visitor_id: checkCookie(),
        outfit: convertOutfit(outfitIntelistyle),
        event_type: 'unlike'
      })

      // Set like value of intelistyle outfit to false
      setOutfitLiked(false)
    }

    // Send appropriate like or unlike event for zalando outfit
    api.likedOutfitEvent({
      visitor_id: checkCookie(),
      outfit: convertOutfit(outfit),
      event_type: outfitLikedOpponent ? 'unlike' : 'like'
    })

    // Toggle the liked state of the outfit
    setOutfitLikedOpponent(!outfitLikedOpponent)

    // Save the outfit to state in case it is unliked later
    if (outfitOpponent === null) {
      setOutfitOpponent(outfit)
    }
  }

  const handleClose = () => {
    // Reset state values when closing dialog
    setOutfitLiked(false)
    setOutfitIntelistyle(null)
    setOutfitLikedOpponent(false)
    setOutfitOpponent(null)

    props.handleClose()
  }

  const outfitElements = []
  if (outfits) {
    outfitElements.push(
      <Outfit
        key={1}
        garment={props.garment}
        showIntelistyleOutfits={showIntelistyleOutfits}
        allowCrossUserOutfits={allowCrossUserOutfits}
        cached={cachedOutfitsIntelistyle}
        outfitRestrictions={outfitRestrictions}
        groupRestrictions={outfitGroupRestrictions}
        outfitRules={outfitRules}
        locationContainGroups={locationContainGroups}
        likeButton={outfitLikeButton}
        likeButtonOnClick={outfitLikeButtonOnClick}
        likeButtonPressed={outfitLiked}
        title={outfitsTitle}
        source={intelistyleOutfitsSource}
        occasionSelect={occasionSelect}
        personaSelect={personaSelect}
        occasions={permittedOccasions === null ? props.occasions : permittedOccasions}
        weathers={props.weathers}
        personas={permittedPersonas == null ? props.personas : permittedPersonas}
      />
    )
  }
  if (showdownOutfits) {
    outfitElements.push(
      <Outfit
        key={2}
        garment={props.garment}
        showIntelistyleOutfits={false}
        allowCrossUserOutfits={false}
        cached={cachedOutfitsOpponent}
        likeButton={outfitLikeButton}
        likeButtonOnClick={outfitLikeButtonOnClickOpponent}
        likeButtonPressed={outfitLikedOpponent}
        outfitRestrictions={outfitRestrictionsOpponent}
        groupRestrictions={outfitGroupRestrictions}
        outfitRules={outfitRules}
        locationContainGroups={locationContainGroups}
        title={outfitsTitleOpponent}
        source={opponentOutfitsSource}
        occasionSelect={false}
        personaSelect={false}
        occasions={permittedOccasions === null ? props.occasions : props.occasions.filter(occasion => permittedOccasions.includes(occasion._id))}
        personas={props.personas}
      />
    )
  }

  if (outfitOrder === 1) {
    // Reverse outfit elements array if outfitOrder is 1 rather than 0
    outfitElements.reverse()
  }

  const headingTranslations = {
    subcategories: {
      en: 'Subcategories',
      tr: 'Alt Kategori'
    },
    attributes: {
      en: 'Attributes',
      tr: 'Ürün Özellikleri'
    },
    bodytype: {
      en: 'Body Types',
      tr: 'Vücut Tipleri'
    },
    occasion: {
      en: 'Occasions',
      tr: 'Okazyons'
    },
    persona: {
      en: 'Personas',
      tr: 'Personas'
    }
  }

  const language = getLanguage()
  return (
    <Dialog fullScreen open={props.show} onClose={handleClose}>
      <div className={classes.productPageContainer}>
        <div className={classes.topBar}>
          <div className={classes.backButton} onClick={handleClose}>
            <ArrowBackIcon />
            <div className={classes.backText}>Back</div>
            <div className={classes.searchPageText}>Search page</div>
          </div>
        </div>
        <div className={classes.productInfoContainer}>
          <div className={classes.imageCarouselContainer}>
            <ImageCarousel
              url={props.garment.product_url}
              images={props.garment.garment_images.map(garmentImage => {
                if (props.useRemoteImage) {
                  return garmentImage.remote_image_url
                } else {
                  if (garmentImage.s3_url) {
                    return garmentImage.s3_url
                  }
                  // return `${settings.s3url}resized/${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
                  return `${settings.s3url}${garmentImage.server_image_filename.split('/').slice(-1)[0]
                  }`
                }
              })}
            />
          </div>
          <div className={classes.productInfoTextContainer}>
            <div className={classes.remoteProductInfoContainer}>
              <div className={classes.productBrand}>
                {props.garment.remote_brand}
              </div>
              <div className={classes.productTitle}>
                {props.garment.remote_product_title}
              </div>
              {
                user.feSettings.displayAutomatedDescription &&
                <Description
                  garment={props.garment}
                />
              }
            </div>
            <div className={`${classes.labelColumns} ${user.feSettings.displayAutomatedDescription ? '' : classes.labelColumnsMarginTop}`}>
              <div className={classes.labelColumn}>
                <LabelList
                  title={headingTranslations.subcategories[language]}
                  labels={props.garment.subcategories.map(
                    subcategoryId =>
                      props.subcategories.filter(
                        subcategory => subcategory._id === subcategoryId
                      )[0].name
                  )}
                />
                <LabelDict
                  title={headingTranslations.attributes[language]}
                  labels={groupAttributes(props.garment.attribute_data.attributes.map(item => item._id).map(
                    attributeId =>
                      renameAttributes(props.attributes.filter(
                        attribute => attribute._id === attributeId
                      )[0])
                  ))}
                />
                { settings.openAISecretKey ? <AIDescriptions garment={props.garment} /> : <></> }
              </div>
              <div className={classes.labelColumn}>
                {props.garment.persona !== null &&
                  props.garment.persona.length > 0 && (
                  <LabelList
                    title={headingTranslations.persona[language]}
                    labels={props.garment.persona.map(
                      personaId =>
                        props.personas.filter(
                          persona => persona._id === personaId
                        )[0].name
                    )}
                  />
                )}
                {props.garment.occasion !== null &&
                  props.garment.occasion.length > 0 && (
                  <LabelList
                    title={headingTranslations.occasion[language]}
                    labels={props.garment.occasion.map(
                      occasionId =>
                        props.occasions.filter(
                          occasion => occasion._id === occasionId
                        )[0].name
                    )}
                  />
                )}
                {props.garment.bodytype !== null &&
                  props.garment.bodytype.length > 0 && (
                  <LabelList
                    title={headingTranslations.bodytype[language]}
                    labels={props.garment.bodytype.map(
                      bodytypeId =>
                        props.bodytypes.filter(
                          bodytype => bodytype._id === bodytypeId
                        )[0].name
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {outfitElements}
        {showProductSimilars && props.showProductSimilars &&
          <Similarity
            garment={props.garment}
            title={similarsTitle}
            cached={cachedSimilars}
            showIntelistyleSimilars={showIntelistyleSimilars}
            similarRestrictions={similarRestrictions}
            restrictBySubcategory={restrictSimilarsBySubcategory}
          />

        }
      </div>
    </Dialog>
  )
}
