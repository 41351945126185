import React from 'react'
import skipIcon from '../../assets/img/skipIcon.png'

export default function Pagination(props) {
  let classes = props.styling

  const handlePageSelectChange = async (event) => {
    event.preventDefault()
    let page = 1
    if (event.target.value !== '') {
      page = parseInt(event.target.value)
    }
    if (!isNaN(page) && page !== 0) {
      if (page !== props.page) {
        await props.setPage(page)
      }
    }
  }

  return (
    <div className={classes.paginationContainer}>
      <div
        title="Ctrl-Left"
        className={`${classes.skipContainer} ${props.enabled && classes.buttonHover} ${props.enabled ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`}
        onClick={() => props.setPage(Math.max(1, props.page - 1))}
      >
        <img alt='skipIcon' src={skipIcon} style={{transform: 'rotateY(180deg)'}} />
      </div>
      <form
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        autoComplete='off'
      >
        <input
          type='string'
          step={1}
          className={classes.pageSelectInput}
          id='paginationInput'
          name='quantity'
          min={1}
          max={props.totalPages}
          value={props.page}
          onChange={handlePageSelectChange}
        />
      </form>
      <div
        title="Ctrl-Right"
        className={`${classes.skipContainer} ${props.enabled && classes.buttonHover} ${props.enabled ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`}
        onClick={() => props.setPage(Math.min(props.totalPages, props.page + 1))}
      >
        <img alt='skipIcon' src={skipIcon} />
      </div>
    </div>
  )
}
