import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import api from 'api/api'
import Outfit from 'components/OutfitDemo/Outfit'
import MainGarment from 'components/OutfitDemo/MainGarment'
import { Spinner } from 'react-bootstrap'

const style = ({
  root: {
    display: 'inline-block'
    // marginLeft: '38px'
  },
  secondaryCard: {
    boxSizing: 'border-box !important',
    height: '245px',
    width: '820px',
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF'
  },
  searchContainer: {
    display: 'inline-block !important',
    marginBottom: '20px'
  },
  searchBar: {
    backgroundColor: 'white'
  },
  searchContainerText: {
    display: 'inline-block',
    height: '24px',
    width: '57px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  autocompleteBar: {
    display: 'inline-flex',
    marginLeft: '19px',
    width: '610px'
  },
  verticalTextPosition: {
    position: 'relative',
    top: '115px',
    left: '-111px'
  },
  verticalTextPosition2: {
    position: 'relative',
    top: '115px',
    left: '-111px'
  },
  verticalText: {
    textAlign: 'center',
    height: '14px',
    width: '245px',
    color: '#4C4C51',
    fontFamily: '"SF Pro Text"',
    fontSize: '12px',
    lineHeight: '14px',
    transform: 'rotate(270deg)'
  },
  rectangle1: {
    height: '243px',
    width: '24px',
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#FFEEEE'
  },
  rectangle2: {
    height: '243px',
    width: '24px',
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#E8F3FF'
  },
  rectangle3: {
    height: '243px',
    width: '24px',
    borderRadius: '5px 0 0 5px',
    backgroundColor: '#FFEEEE'
  },
  outfitsContainer: {
    position: 'relative',
    top: '-240px',
    right: '-24px',
    marginLeft: '10px',
    paddingTop: '15px',
    width: '95%'
  },
  noOutfit: {
    paddingTop: '30px',
    textAlign: 'center'
  }
})
const DemoOutfitTemplate = ({ classes, restrictions }) => {
  const [categories, setCategories] = useState([])
  const [garments, setGarments] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const categoriesData = await api.getCategories()
      const results = await handleRequestGarments([], [])
  
      const uniqueCategories = []
  
      results.forEach(garment => {
        const garmentType = garment.garmentType
        const matchingCategory = categoriesData.find(category => category._id === garmentType)
  
        if (matchingCategory && !uniqueCategories.some(cat => cat._id === matchingCategory._id)) {
          uniqueCategories.push(matchingCategory)
        }
      })
    
      setCategories(uniqueCategories)
    }
  
    fetchData()
  }, [])

  const handleRequestGarments = async (e, value) => {
    setLoading(true)

    const categoryRestrictions = value._id || []
    const body = {
      visitor_id: 'visitor-id',
      outfit_restrictions: {
        min_approved: 1,
        ...restrictions,
      },
      return_garments: true,
      query_restrictions: {
        category_ids_restriction: categoryRestrictions.length === 0 ? null : categoryRestrictions,
        user_ids_restriction: '62c439a7e9a38c26d99f878e',
      },
      n_queries: 33,
      sort_by: { _id: 1 },
      n_outfits_per_query: 3,
      sample_outfits: false,
      extended_images: true,
    }

    try {
      const results = (await api.getOutfits(body)).data

      if (results.garments === undefined) {
        setGarments([])
        setLoading(false)
      } else {
        for (let i = 0; i < results.garments.length; i++) {
          const outfits = results.garments[i].outfits
          results.garments[i].outfits =
            outfits.length < 3 ? [outfits[0], outfits[0], outfits[0]] : outfits.slice(0, 3)
        }

        setGarments(results.garments)
        return results.garments
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <div className={classes.searchContainerText}>
            CATEGORY
        </div>
        <Autocomplete
          className={classes.autocompleteBar}
          id='tags-standard'
          options={categories}
          getOptionLabel={option => option.name || ''}
          defaultValue=''
          onChange={handleRequestGarments}
          renderInput={params => (
            <TextField
              className={classes.searchBar}
              {...params}
              variant='outlined'
              label=''
              placeholder=''
              margin='normal'
              fullWidth
            />
          )}
          renderOption={(option, { inputValue }) => {
            const matches = match(option.name, inputValue)
            const parts = parse(option.name, matches)

            return (
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            )
          }}
        />
      </div>
      {loading
        ? <div className='outfit-loading-spinner'>
          <Spinner animation='border' role='status' style={{ padding: '50px' }}>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div> : garments.length === 0
          ? <div className={classes.noOutfit}>
              Unfortunately there are no available outfits for your selection.
          </div>
          : garments.map((garment, key) => {
            return (
              <Grid container spacing={4} key={key} wrap='nowrap'>
                <Grid item>
                  <MainGarment garment={garment} />
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={2}>
                    <Grid item>
                      <Card className={classes.secondaryCard}>
                        <div className={classes.rectangle1}>
                          <p className={`${classes.verticalText} ${classes.verticalTextPosition}`}> FEMININE </p>
                        </div>
                        <div className={classes.outfitsContainer}>
                          <Grid container justifyContent='space-evenly' wrap='nowrap'>
                            <Outfit outfit={garment.outfits[0].garments} />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card className={classes.secondaryCard}>
                        <div className={classes.rectangle2}>
                          <div className={`${classes.verticalText} ${classes.verticalTextPosition}`}> ATLEISURE </div>
                        </div>
                        <div className={classes.outfitsContainer}>
                          <Grid container justifyContent='space-evenly' wrap='nowrap'>
                            <Outfit outfit={garment.outfits[1].garments} />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card className={classes.secondaryCard}>
                        <div className={classes.rectangle3}>
                          <div className={`${classes.verticalText} ${classes.verticalTextPosition2}`}> FASHION FORWARD</div>
                        </div>
                        <div className={classes.outfitsContainer}>
                          <Grid container justifyContent='space-evenly' wrap='nowrap'>
                            <Outfit outfit={garment.outfits[2].garments} />
                          </Grid>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

    </div>
  )
}

export default withStyles(style)(DemoOutfitTemplate)
