import React, { useState } from 'react'
import { defaultProps } from 'recompose'
import ImageCarouselZoom from 'components/LabelEditor/ImageCarouselZoom/ImageCarouselZoom'
import './Garment.css'

const AttributeGarment = (props) => {
  const [state, setState] = useState({
    imageHeight: props.imageHeight,
    height: props.height,
    width: props.width,
    editable: props.editable,
    selectable: props.selectable,
    indicators: props.indicators,
  })

  const handleEditClick = () => {
    props.editOnClick(props.garmentIndex, props.garment)
  }

  const handleDeleteClick = () => {
    props.deleteOnClick(props.garmentIndex, props.garment)
  }

  const handleSelectClick = () => {
    if (state.selectable) {
      props.selectOnClick(props.garment)
    }
  }

  return (
    props.garment != null && (
      <div
        className={
          props.inGarmentNavigator ? 'card-in-garm-navigator' : ''
        }
        style={{ width: state.width, height: 'auto', backgroundColor: '#EEEEEE' }}
      >
        <ImageCarouselZoom
          images={props.garment.garment_images}
          imageHeight={props.imageHeight}
        />
        <div
          className={`card-body card-body-garment${props.garment.stock < 1 && props.indicateStock ? ' out-of-stock' : props.usedProductIds && !props.usedProductIds.includes(props.garment.remote_product_id) ? ' used-in-outfit' : ''}`}
        >
          <a href={props.garment.product_url} target="_blank" rel="noopener noreferrer">
            <h6 style={{ fontSize: '20px', color:'#161616', fontFamily: 'Roboto', fontWeight: '400' }}>
              {props.garment.remote_product_title}
            </h6>
          </a>
          <div
            className="d-flex flex-row"
            ref={(element) => {
              if (element) {
                element.style.setProperty('flex-direction', 'column', 'important')
              }
            }}
            onClick={handleSelectClick}
          >
            {(props.garment.remote_product_categories.includes('Summer') || props.garment.remote_product_categories.includes('Winter')) && (
              <div>
                {props.garment.remote_product_categories.includes('Summer') ? 'Summer' : 'Winter'}
              </div>
            )}

            {props.showProductId && (
              <p
                className="outfit-editor-garm-id"
                style={{ fontSize: '9px', color: 'black', marginBottom: '0px' }}
              >
                {props.garment.remote_product_id}
              </p>
            )}
            {props.showProductCategories && (
              <p style={{
                fontSize: '28px',
                color: '#161616',
                fontFamily: 'Roboto',
                fontWeight: '600',
                lineHeight: '32.81px'
              }}>
                {props.garment.remote_product_categories}
              </p>
            )}
            {props.showId && (
              <p style={{
                fontSize: '20px',
                color: '#161616',
                fontFamily: 'Roboto',
                fontWeight: '400',
                lineHeight: '23.44px'
              }}>
                {props.garment.remote_product_id}
              </p>
            )}
            {props.showBrand && (
              <p
                style={{ fontSize: '9px', color: 'black', margin: '0px' }}
              >
                {props.garment.remote_brand}
              </p>
            )}
            {props.showPrice &&
              props.garment.price &&
              props.garment.currency_code && (
              <p
                style={{ fontSize: '9px', color: 'black', margin: '0px' }}
              >
                {`${props.garment.price} ${props.garment.currency_code}`}
              </p>
            )}
            {props.showStockLevel && (
              <p
                style={{ fontSize: '9px', color: 'black', marginTop: '0px' }}
              >
                {props.garment.stock_level}
              </p>
            )}
            {props.showCreatedAt && (
              <p style={{
                fontSize: '20px',
                color: '#161616',
                fontFamily: 'Roboto',
                fontWeight: '400',
                lineHeight: '23.44px',
                marginBottom: '24px'
              }}>

                {props.garment.createdAt}
              </p>
            )}
            {props.showDescription && (
              <>
                <p style={{
                  fontSize: '16px',
                  color: '#767086',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  lineHeight: '18.75px'
                }}>
                  Description
                </p>
                <p style={{
                  fontSize: '18px',
                  color: '#544C64',
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  lineHeight: '21.09px'
                }}>
                  {props.garment.remote_product_description}
                </p>
              </>
            )}
            {state.editable && (
              <div
                className="edit-delete-buttons"
                style={{display: 'flex', justifyContent: 'space-evenly'}}
              >
                <button
                  type="button"
                  className="btn btn-primary btn-sm edit-delete-button"
                  style={{ marginBottom: '1px' }}
                  onClick={handleEditClick}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm edit-delete-button"
                  style={{ marginBottom: '1px' }}
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

// Add default props to Garment
const withDefaultProps = defaultProps({
  imageHeight: 200,
  height: 300,
  width: 500,
  editable: false,
  selectable: false,
  indicators: false,
  useRemoteImage: false,
})

export default withDefaultProps(AttributeGarment)
