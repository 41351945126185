import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function ProductCategoryExcludeAccordionSection(props) {
  const getLabels = async () => {
    if (!props.userIds) {
      // Only retrieve if we are looking for specific user ids as it can take too long if searching over all
      return []
    }

    let productCategoriesExclude = await api.getProductCategories({
      garment_restrictions: {
        user_ids_restriction: props.userIds
      },
      url: props.url,
    })

    try {
      productCategoriesExclude = productCategoriesExclude.map((productCategoryExclude, index) => {
        return {
          active: false,
          name: productCategoryExclude,
          _id: index
        }
      })
      return productCategoriesExclude
    } catch {
      console.error('Failed to get product categories for exclusion')
      return []
    }
  }

  return (
    <AbstractAccordionSection
      title={'Product Category Exclude'}
      getLabels={getLabels}
      setLabels={props.setLabels}
      userIds={props.userIds}
    />

  )
}