import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableCell: {
    fontSize: '0.65rem'
  },
  customModal: {
    marginTop: '138px',
    fontFamily: 'Montserrat',
    minHeight: '200px'
  }
})

export default function OutfitStats (props) {
  const classes = useStyles()

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      className='outfit-stats-modal'
      dialogClassName={classes.customModal}
    >
      <Modal.Header className='garment-navigator-close' closeButton>
        <Modal.Title>Stats</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='garment-navigator-container'>
          {
            props.loading
              ? <div className='outfit-loading-spinner'>
                <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              </div>
              : <div>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Approval Level</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Approved (User)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Approved w Mapped (User)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Approved (All Users)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Approved (% of Total)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Approved (% of Vetted)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Vetted (User)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Vetted (All Users)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Vetted (% of Total)</TableCell>
                      <TableCell className={classes.tableCell} align='right'>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.stats &&
                                            props.stats.map((row, index) => (
                                              <TableRow key={index}>
                                                <TableCell className={classes.tableCell} component='th' scope='row'>
                                                  {row.approved}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.approved_user_count}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.approved_user_count_mapped}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.approved_count}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{(100 * row.approved_count / row.total_count).toFixed(1)}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{(100 * row.approved_count / row.vetted_count).toFixed(1)}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.vetted_user_count}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.vetted_count}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{(100 * row.vetted_count / row.total_count).toFixed(1)}</TableCell>
                                                <TableCell className={classes.tableCell} align='right'>{row.total_count}</TableCell>
                                              </TableRow>
                                            ))}
                  </TableBody>
                </Table>
              </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
