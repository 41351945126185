import React, {useEffect, useState} from 'react'
import NestedAttributeAccordionSection from '../NestedAttributeAccordionSection'


export default function NestedAttributeAbstractAccordionSection(props) {
  const [labels, setLabels] = useState(null)
  const [labelsAllSelected, setLabelsAllSelected] = useState(true)

  const updateLabels = (newLabels) => {
    setLabels(newLabels)
    props.setLabels(newLabels)
  }

  const getLabels = async () => {
    let newLabels = await props.getLabels()
    updateLabels(newLabels)
  }

  const onLabelClick = (selectedLabel) => {
    if (labels !== null) {
      let labelsCopy = JSON.parse(JSON.stringify(labels))
      labelsCopy.forEach(label => {
        label.subgroups.forEach(subgroup => {
          subgroup.attributes.forEach(attribute => {
            if (attribute._id === selectedLabel._id) {
              attribute.active = !attribute.active
            }
          })
        })

        label.attributes.forEach(attribute => {
          if (attribute._id === selectedLabel._id) {
            attribute.active = !attribute.active
          }
        })
      })
      updateLabels(labelsCopy)
    }
  }

  const onSelectAllLabelsClick = () => {
    if (labels !== null) {
      let labelsCopy = JSON.parse(JSON.stringify(labels))
      labelsCopy.forEach(label => {
        label.active = !labelsAllSelected
      })
      updateLabels(labelsCopy)
      setLabelsAllSelected(!labelsAllSelected)
    }
  }

  useEffect(() => {
    if (labels === null) {
      getLabels()
    }
  }, [labels])

  useEffect(() => {
    if (labels !== null) {
      getLabels()
    }
  }, [props.userIds])

  return (
    <NestedAttributeAccordionSection
      headerText={props.title}
      groups={labels}
      onOptionClick={onLabelClick}
      allSelected={labelsAllSelected}
      onSelectAllClick={onSelectAllLabelsClick}
    />
  )
}
