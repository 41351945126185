import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import UploadImageForm from './UploadImageForm'
import DemoOutfits from './DemoOutfits'
import CloseIcon from '@material-ui/icons/Close'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import BoundingBoxEditor from '../BoundingBoxEditor/BoundingBoxEditor'
import settings from '../../config/setting-values'
import api from '../../api/api'
import cryptoJs from 'crypto-js'
import vettedOutfits from './PrevettedOutfits'

import VisualSearchResults from './VisualSearchResults'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FDFBF9',
    border: '1px solid rgba(218, 183, 135, 0.5)',
    height: '800px',
    width: '100%',
    letterSpacing: '0px',
    overflow: 'hidden',
  },
  uploadHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    margin: 'auto',
    padding: '20px',
  },
  uploadTitle: {
    textTransform: 'uppercase',
    padding: '50px 30px 20px 30px',
    fontFamily: 'Montserrat',
    color: '#76787a',
    fontWeight: '500',
    fontSize: '1.3em',
    margin: '0 auto',
  },
  uploadClose: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
  },
  loadingSpinner: {
    width: '300px',
    height: '300px',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center'
  },
  uploadImageForm: {
    padding: '10px',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    margin: 'auto'
  },
  visualSearchBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '25px',
    paddingTop: '0px',
  },
  visualSearchResultsContainer: {
    flexGrow: 1,
  },
}))

function wait(ms) {
  return new Promise((resolve) => { setTimeout(resolve, ms) })
}

const getHighestBoundingBoxId = (garment) => {
  const bbs = garment.garment_images[0].bounding_box_data.bounding_boxes
  let highestIndex = 0
  let highestBoxTop = 1.0

  bbs.forEach((b, i) => {
    if (b.position.top < highestBoxTop) {
      highestBoxTop = b.position.top
      highestIndex = i
    }
  })

  console.log({highestIndex})

  return highestIndex
}

export default function VisualSearchDialog(props) {
  const classes = useStyles()
  let [isLoading, setIsLoading] = useState(false)
  let [uploadedGarment, setUploadedGarment] = useState()
  let [displayedGarment, setDisplayedGarment] = useState()
  let [visualSearchResults, setVisualSearchResults] = useState([])
  let [usingVettedResults, setUsingVettedResults] = useState(false)
  let [activeBoundingBox, setActiveBoundingBox] = useState(0)
  let [imageHash, setImageHash] = useState()

  // Filter categories so we only allow selection of a reduced set for simplicity
  let categories = []
  if (props.allCategories !== null && props.allCategories !== undefined) {
    const allowedCategories = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 17, 18, 31, 38]
    categories = props.allCategories.filter(category => (allowedCategories.includes(category._id)))
  }

  async function fetchSimilarGarments(query_id) {
    const res = api.getSimilars({
      query_id,
      ignore_favourite_users: true,
      restrict_by_subcategory: false,
      similar_restrictions: {
        user_ids_restriction: [
          '5bdb32c6ad911a6b0ea64f41',
          '5e78cc935a25538b2b8ea65e',
          '5f72487d903377a6319176e0',
          '5fce069c34d2a7f35ced7366',
          '603e2ff8be8c0f03e04d82fb',
        ]
      },
      extended_images: true
      // restrict_by_subcategory: true
    }).then(res => {
      if (!res.data.garments.length) return []
      return res.data.garments[0].slice(1)
    })
    return res
  }

  async function performVisualSearch(image, isFullbody, gender, category) {
    setIsLoading(true)
    setVisualSearchResults([])
    setUsingVettedResults(false)

    let imageBlob = null
    if (typeof image == 'string') {
      // For demo images we just accept the url of the image
      imageBlob = (await axios.get(image, {
        responseType: 'blob'
      })).data
    } else if (image instanceof Blob) {
      imageBlob = image
    } else {
      throw Error('Received image that is not a blob or url')
    }

    // Get an MD5 of the blob - we may wish to use cached results
    var imageBuffer = cryptoJs.lib.WordArray.create(await imageBlob.arrayBuffer())
    var hash = cryptoJs.SHA256(imageBuffer).toString()
    setImageHash(hash)
    // Use cached results
    if (vettedOutfits[hash]) {
      setActiveBoundingBox(getHighestBoundingBoxId(vettedOutfits[hash]))
      setUsingVettedResults(vettedOutfits[hash])
      setDisplayedGarment(vettedOutfits[hash])
      setUploadedGarment(vettedOutfits[hash])
      await wait(500)
      await setIsLoading(false)
      return
    }


    let data = new FormData()
    data.append('photo', imageBlob)
    data.append('gender', gender)
    data.append('garmentType', 0)

    data.append('outfit_bounding_boxes', true)
    data.append('bounding_box_categories', true)

    let garment = await api.addGarment(data)

    setActiveBoundingBox(getHighestBoundingBoxId(garment))
    setUploadedGarment(garment)
    setVisualSearchResults([])
    setDisplayedGarment(garment)

    await confirmBoundingBoxes(garment)
  }

  useEffect(() => {
    // Display what is being saved
    if (uploadedGarment) {
      console.log('Potential for saving:')
      let obj = {}
      obj[imageHash] = {
        garment_images: [uploadedGarment.garment_images[0]],
        cached_outfit: uploadedGarment.cached_outfit ? {
          ...uploadedGarment.cached_outfit,
          garments: uploadedGarment.cached_outfit.garments.map(g => ({_id: g._id}))
        } : { garments: [] }
      }
      console.log(JSON.stringify(obj, null, 2))
    }

    if (uploadedGarment && usingVettedResults) {
      let cachedOutfitGarments = uploadedGarment.cached_outfit.garments
      if (activeBoundingBox < cachedOutfitGarments.length) {
        var g = uploadedGarment.cached_outfit.garments[activeBoundingBox]
        if (g.results) {
          setVisualSearchResults(g.results)
        } else {
          setVisualSearchResults(fetchSimilarGarments(uploadedGarment.cached_outfit.garments[activeBoundingBox]._id))
        }
      } else {
        setVisualSearchResults([])
      }
    } else if (uploadedGarment && !usingVettedResults) {
      console.log(uploadedGarment)
      console.log(uploadedGarment.outfit_garment_ids)
      if(!uploadedGarment.outfit_garment_ids) return
      if (!uploadedGarment.outfit_garment_ids.length) return
      let finalOutfit = uploadedGarment.outfit_garment_ids[uploadedGarment.outfit_garment_ids.length-1]
      if (!finalOutfit.garment_ids.length) return
      if (activeBoundingBox >= finalOutfit.garment_ids.length) {
        setVisualSearchResults([])
        return
      }
      setVisualSearchResults(fetchSimilarGarments(finalOutfit.garment_ids[activeBoundingBox].similar_data))
    }
  // eslint-disable-next-line
  }, [activeBoundingBox, uploadedGarment])

  function onExited() {
    setIsLoading(false)
    setUploadedGarment(null)
    setDisplayedGarment(null)

    setUsingVettedResults(false)
  }

  async function onBoundingBoxEditorConfirmChanges() {
    console.log(displayedGarment)
    if (!usingVettedResults) {
      setIsLoading(true)

      displayedGarment.garment_images[0].bounding_box_data.approved = 1
      let bbs = displayedGarment.garment_images[0].bounding_box_data.bounding_boxes
      displayedGarment.garment_images[0].bounding_box_data.bounding_boxes = bbs.map(b => {
        b.category_data.approved = 1
        return b
      })

      await confirmBoundingBoxes(displayedGarment)
    }
  }

  async function confirmBoundingBoxes(newGarment) {
    await api.approveBoundingBoxes({ garment: newGarment })

    // Update fullbody upload garment with bounding box outfit
    let garment = await api.updateGarments({
      garment_restrictions: {
        garment_ids_restriction: newGarment._id
      },
      bounding_boxes_to_outfits: true,
      similar_data_only: true,
      return_garments: true
    })

    setIsLoading(false)

    if (!garment.outfit_garment_ids) return
    setUploadedGarment(garment)
    setDisplayedGarment(garment)
  }

  let content = null

  // Show loading spinner
  if (isLoading) {
    content = <div className={classes.loadingSpinner}>
      <Spinner animation='border' role='status' >
        <span className='sr-only'></span>
      </Spinner>
    </div>
  } else if (!displayedGarment) {
    content = <div>
      <DemoOutfits onSubmit={performVisualSearch} />
    </div>
  } else {
    content = <>
      <div className={classes.visualSearchBody}>
        <div>
          <div style={{ height: '42px' }}>
            UPLOADED CLOTHING
          </div>
          <BoundingBoxEditor
            garment={displayedGarment}
            boundingBoxLocations={props.boundingBoxLocations}
            categories={categories}
            categoryBoundingBoxLocationMap={props.categoryBoundingBoxLocationMap}
            selectCategories={true}
            setGarment={setDisplayedGarment}
            keyboardShortcuts={false}
            imageBaseURL={settings.s3url}
            setActiveBoundingBox={setActiveBoundingBox}
            activeBoundingBox={activeBoundingBox}
            onConfirm={onBoundingBoxEditorConfirmChanges}
          />
        </div>
        <div className={classes.visualSearchResultsContainer}>
          <VisualSearchResults
            garments={visualSearchResults}
          />
        </div>
      </div>
    </>
  }

  return (
    <Dialog open={props.show} onClose={props.onClose} onExited={onExited} maxWidth='md' fullWidth>
      <div className={classes.uploadClose} onClick={props.onClose}>
        <CloseIcon />
      </div>
      <div className={classes.root}>
        <div className={classes.uploadHeader}>
          <div className={classes.uploadTitle}>Visual Search</div>
          <UploadImageForm garmentCategories={categories} performSearch={performVisualSearch} />
          <div className={classes.uploadClose} onClick={props.onClose} >
            <CloseIcon />
          </div>
        </div>

        {content}
      </div>
    </Dialog>
  )
}
