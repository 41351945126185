import React from 'react'

import '../../../assets/css/WearThatSearch/Retailers.scss'

class Retailers extends React.Component {
  render () {
    const options = this.props.availableUsers
      .map(e => e.corpname)
      .filter(e => e !== undefined)
      .map((el, i) => (
        <label key={i} className='fancy-checkbox'>
          <input
            type='checkbox'
            onChange={e => this.props.handleCheckboxChange(e)}
            name='retailer_restriction'
            value={el}
            key={i}
            className='checkbox'
            checked={this.props.retailer_restriction.includes(el)}
          />
          <i className='fa unchecked' />
          <i className='fa fa-check checked' />
          <div className='some-label'>
            {el}
          </div>
        </label>
      ))
    return (
      <div className='retailer-wrapper'>{options}</div>
    )
  }
}

export default Retailers
