/*!
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
*/
import React from 'react'
// import axios from 'axios';
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputRange from 'react-input-range'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
// import needed variables
import { categories } from 'variables/categories.jsx'

import api from 'api/api.js'

const styles = {
  pText: {
    color: '#000000',
    display: 'inline-block',
    opacity: '0.5',
    fontSize: '12px',
    marginTop: '5px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    marginBottom: '5px',
    //textTransform: 'uppercase'
  },
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    textTransform: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  productsInput: {
    width: '480px',
    //marginTop: '40px'
  },
  productsInputBox: {
    width: '95%',
    border: '1px solid #ECEEF5',
    height: '49px',
    display: 'inline-flex',
    boxSizing: 'border-box',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    paddingLeft: '10px'
  },
  dropdownList: {
    marginLeft: '22px',
    display: 'inline-block',
    '& p': {
      height: '24px',
      opacity: '0.5',
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '24px',
      marginBottom: '4.5px',
      marginTop: '24px'
    }
  },
  dropdownListSelect: {
    display: 'inline-flex',
    boxSizing: 'border-box',
    height: '49px',
    width: '249px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    marginBottom: '40px',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px',
      marginBottom: '0px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px'
    }
  },
  sliderParent: {
    width: '480px',
    borderRadius: '3px',
    padding: '10px',
    border: '1px solid #ECEEF5'
  },
  slider : {
    width:'450px', 
    marginBottom:'30px'
  },
  sliderTitle: {
    marginBottom: '40px'
  }
}

class BackendControl extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: { full_name: 'Product Category', _id: null },
      country: { full_name: 'Country', _id: null },
      allCountries: [{full_name: 'All', _id: 0}, {full_name: 'France', _id: 1}],
      allCategories: [],
      submitSuccess: false,
      isCheckedOOS: false,
      revenue: { value: 50 },
      volume: { value: 50 },
      margin: {value: 50},
      productIDs: []
    }

  }

  toggleOOSCheckboxChange = () => {
    this.setState({
      isCheckedOOS: !this.state.isCheckedOOS,
    })
  }
  
  handleInputChange = (val) => {
    const productIDsRaw = val.target.value.split(',')
    this.setState({productIDs: productIDsRaw.map(s => s.trim())})
  }

    handleDropdownChange = (font, name, id) => {
      let dropdownSelect = this.state[name]
      dropdownSelect.full_name = font
      dropdownSelect._id = id
      this.setState({ [name]: dropdownSelect })
    }

    submitProductRanking = () => {
      if (!Number.isInteger(this.state.category._id)) {
        console.log('not valid')
        return
      }

      this.setState({ submitSuccess: true })

      setTimeout(() => {
        this.setState({ submitSuccess: false })
      }, 2000)

      let body = {
        category_ids_restriction: this.state.category._id,
        countries_restriction: this.state.country.val,
        sendOOSLast: this.state.isCheckedOOS,
        revenueWeight: this.state.revenue.value,
        volumeWeight: this.state.volume.value,
        marginWeight: this.state.margin.value,
        product_ids: this.state.productIDs
      }
      console.log('Api values: ',body.category_ids_restriction, body.countries_restriction, body.sendOOSLast, body.revenueWeight, body.volumeWeight, body.product_ids)
      api
        .submitValuesForProductRanking(body)
        .then(results => {
          if (results.data.error) {
            return
          }
        })
        .catch(err => {
          this.setState({
            error: 'Something went wrong',
          })
        })
    }

    async getGarmentCategories() {
      this.setState({
        allCategories: categories,
      })
    }

    async componentDidMount() {
      await this.getGarmentCategories()
    }

    render() {
      const { classes } = this.props
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader style={{backgroundColor: '#1b2432'}}>
                <h3 className={classes.cardTitleWhite}>Merchandise Listing Page</h3>
                <p className={classes.cardCategoryWhite}>
                                Choose country, category, revenue, margin and volume to change the default merchandising of your products
                </p>
              </CardHeader>
              <CardBody style={{ height: '-webkit-fill-available' }}>
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                  <p className={classes.pText}>1. Select Country</p>
        	    <div className={classes.controls}>
          	    <Autocomplete
            	      className={classes.dropdownListSelect}
            	      id='source-select-country'
            	      options={this.state.allCountries}
            	      getOptionLabel={option => option.full_name}
                      onChange={(e, val) => this.setState({country: {full_name: val.full_name, _id: val._id}})}
                      renderInput={params => (
              	        <TextField
                	  {...params}
                	  variant='outlined'
                	  label=''
                	  placeholder='Select Country'
                	  margin='normal'
                	  fullWidth
              	  	/>
            	      )}
            	      getOptionSelected={option => option.full_name}
                      value={this.state.country}
            	      renderOption={(option, { inputValue }) => {
              	        const matches = match(option.full_name, inputValue)
                        const parts = parse(option.full_name, matches)

              	      return (
                          <div>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}
                          </div>
                        )
                      }}
                    /></div>
                  <p className={classes.pText}>2. Select Category</p>
                  <div className={classes.controls}>
                    <Autocomplete
                      className={classes.dropdownListSelect}
                      id='source-select-category'
                      options={this.state.allCategories}
                      getOptionLabel={option => option.full_name}
                      onChange={(e, val) => this.setState({category: {full_name: val.full_name, _id: val._id}})}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label=''
                          placeholder='Select Category'
                          margin='normal'
                          fullWidth
                        />
                      )}
                      getOptionSelected={option => option.full_name}
                      value={this.state.category}
                      renderOption={(option, { inputValue }) => {
                        const matches = match(option.full_name, inputValue)
                        const parts = parse(option.full_name, matches)
                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}
                          </div>
                        )
                      }}
                    /></div>
                  <p className={classes.pText}> 3. Select your parameters </p>
                  <div className={classes.sliderParent}>
                    <div className={classes.slider}>
                      <div className={classes.sliderTitle}> Revenue Weight % </div>
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        value={this.state.revenue.value}
                        onChange={val => this.setState({ revenue: {value: val} })}
                      />
                    </div>
                    <div className={classes.slider}>
                      <div className={classes.sliderTitle}> Volume Weight % </div>
                      <InputRange 
                        maxValue={100}
                        minValue={0}
                        value={this.state.volume.value}
                        onChange={val2 => this.setState({ volume: {value: val2} })}
                      />
                    </div>
                    <div className={classes.slider}>
                      <div className={classes.sliderTitle}> Margin Weight % </div>
                      <InputRange
                        maxValue={100}
                        minValue={0}
                        value={this.state.margin.value}
                        onChange={val3 => this.setState({ margin: {value: val3} })}
                      />
                    </div>
                  </div>
                  <div style={{marginTop: '30px', marginBottom: '30px'}}>
                    <p className={classes.pText}> 4. Product IDs Filters </p>
                    <div className={classes.sliderParent}>
                      <div className={classes.productsInput}>
                        <p className={classes.pText} style={{opacity: 1}}> Priority Product IDs: </p>
                        <input className={classes.productsInputBox}
                          type='text'
                          placeholder={'product123, product234'}
                          autoComplete='off'
                          name='productIdsInput'
                          onChange={val => this.handleInputChange(val)}
                        />
                      </div>
                      <label style={{color: 'black', paddingTop: '50px'}}>
                        <input style={{verticalAlign: 'middle', position: 'relative'}}
                          type='checkbox'
                          name='out_of_stock'
                          defaultChecked={this.state.isCheckedOOS}
                          onChange={this.toggleOOSCheckboxChange}
                          className='checkbox'
                        />
                        <p className={classes.pText} style={{opacity: 1}}>Deprioritise Out of Stock Products</p>
                      </label>
                    </div>
                  </div>
                  {this.state.submitSuccess ? 
		  <Button type="button" color="success" style={{width:'480px'}} onClick={this.submitProductRanking} disabled>Submit</Button> : 
                    <Button type="button" style={{backgroundColor: '#1b2432', width:'480px'}} onClick={this.submitProductRanking}>Submit</Button>
                  }
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )
    }
}

BackendControl.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(BackendControl)
