import React, { useState } from 'react'
import {makeStyles} from '@material-ui/core/styles'
import ContinuousSlider from './ContinuousSlider'
import RestoreIcon from '@material-ui/icons/Restore'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  headerBar: {
    height: '40px',
    width: '100%',
    backgroundColor: '#eef1f5'
  },
  headerContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%'
  },
  headerText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.8em',
    margin: 'auto 10px'
  },
  headerButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto 10px'
  },
  embeddingWeightSelectorContainer: {
    width: '100%',
    maxHeight: '600px',
    overflowY: 'scroll',
    margin: '5px',
    border: '1px solid #DEDEDE',
    borderRadius: '5px'
  },
  embeddingComponentSliders: {
    paddingTop: '30px'
  },
  subcategoryComponentSliders: {

  },
  attributeComponentSliders: {

  },
  settingsButton: {
    cursor: 'pointer',
    height: 'auto',
    width: 'auto',
    margin: 'auto 10px'
  },
  individualEmbeddingComponentSliders: {

  },
  individualSubcategoryComponentSliders: {

  },
  individualAttributeComponentSliders: {

  },
  individualAttributeCategoryComponentSliders: {

  },
  headerSlider: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    backgroundColor: '#f1f4f8'
  },
  subHeaderSlider: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #DEDEDE',
    borderRadius: '5px'
  },
  hidden: {
    visibility: 'hidden',
    height: 0
  }
}))


export default function EmbeddingWeightSelector(props) {
  const classes = useStyles()
  const [showEmbeddingComponents, setShowEmbeddingComponents] = useState(false)
  const [showSubcategoryComponents, setShowSubcategoryComponents] = useState(false)
  const [showAttributeComponents, setShowAttributeComponents] = useState(false)
  const [showAttributeCategoryComponents, setShowAttributeCategoryComponents] = useState(new Array(props.attributeCategories.length).fill(false))

  let embeddingDim = 64
  let nSubcategories = props.subcategories.length
  let nAttributes = props.attributes.length

  let embeddingComponentIndices =  [...Array(embeddingDim).keys()]
  let subcategoryComponentIndices = [...Array(nSubcategories).keys()].map(i => i + embeddingDim)
  let attributeComponentIndices = [...Array(nAttributes).keys()].map(i => i + embeddingDim + nSubcategories)

  const handleEmbeddingComponentsWeightChange = (newWeight) => {
    props.setEmbeddingComponentsWeight(newWeight)
    props.onEmbeddingWeightsChange(embeddingComponentIndices, newWeight)
  }

  const handleSubcategoriesWeightChange = (newWeight) => {
    props.setSubcategoriesWeight(newWeight)
    props.onEmbeddingWeightsChange(subcategoryComponentIndices, newWeight)
  }

  const handleAttributesWeightChange = (newWeight) => {
    // Update value of all attribute variable
    props.setAttributesWeight(newWeight)

    // Update value of each attribute category variable
    let newAttributeCategoriesWeights = JSON.parse(JSON.stringify(props.attributeCategoriesWeights))
    newAttributeCategoriesWeights = newAttributeCategoriesWeights.map(() => newWeight)
    props.setAttributeCategoriesWeights(newAttributeCategoriesWeights)

    // Update value of each attribute variable
    props.onEmbeddingWeightsChange(attributeComponentIndices, newWeight)
  }

  const handleAttributeCategoryWeightChange = (attributeCategoryId, newWeight) => {
    let newAttributeCategoriesWeights = JSON.parse(JSON.stringify(props.attributeCategoriesWeights))
    newAttributeCategoriesWeights[attributeCategoryId] = newWeight
    props.setAttributeCategoriesWeights(newAttributeCategoriesWeights)

    let attributeCategoryAttributeComponentIndices = props.attributeCategoryAttributeMap[attributeCategoryId]
      .map(attributeId => attributeComponentIndices[attributeId])
    props.onEmbeddingWeightsChange(attributeCategoryAttributeComponentIndices, newWeight)
  }

  const handleResetClick = () => {
    handleEmbeddingComponentsWeightChange(1)
    handleSubcategoriesWeightChange(1)
    handleAttributesWeightChange(1)

    props.onEmbeddingWeightsChange([...Array(embeddingDim + nSubcategories + nAttributes).keys()], 1)
  }

  const handleSaveClick = async () => {
    const blob =  new Blob([JSON.stringify(props.embeddingWeights)],{type:'application/json'})
    const href = await URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = href
    link.download = 'embedding_weights.json'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const updateShowAttributeCategoryComponents = (attributeCategoryId) => {
    let newShowAttributeCategoryComponents = JSON.parse(JSON.stringify(showAttributeCategoryComponents))
    newShowAttributeCategoryComponents[attributeCategoryId] = !newShowAttributeCategoryComponents[attributeCategoryId]
    setShowAttributeCategoryComponents(newShowAttributeCategoryComponents)
  }

  return (
    <div>
      <div className={classes.headerBar}>
        <div className={classes.headerContentContainer}>
          <div className={classes.headerText}>
                        Embedding Component Weights
          </div>
          <div className={classes.headerButtonsContainer}>
            <div
              className={classes.settingsButton}
              onClick={handleResetClick}
            >
              <RestoreIcon/>
            </div>
            <div
              className={classes.settingsButton}
              onClick={handleSaveClick}
            >
              <SaveAltIcon/>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.embeddingWeightSelectorContainer}>
        <div className={classes.embeddingComponentSliders}>
          <div className={classes.headerSlider}>
            <div
              className={classes.settingsButton}
              onClick={() => setShowEmbeddingComponents(prevShowEmbeddingComponents => !prevShowEmbeddingComponents)}
            >
              <ExpandMoreIcon/>
            </div>
            <ContinuousSlider
              key={'allEmbeddingComponentsSlider'}
              value={props.embeddingComponentsWeight}
              setValue={handleEmbeddingComponentsWeightChange}
              min={0}
              step={0.1}
              max={10}
              label={'All Embedding Components'}
            />
          </div>
          <div className={`${classes.individualEmbeddingComponentSliders} ${showEmbeddingComponents ? '' : classes.hidden}`}>
            {
              embeddingComponentIndices.map(embeddingIndex => {
                return (
                  <ContinuousSlider
                    key={`embeddingComponentSlider${embeddingIndex}`}
                    value={props.embeddingWeights[embeddingIndex]}
                    setValue={newValue => props.onEmbeddingWeightChange(embeddingIndex, newValue)}
                    min={0}
                    step={0.1}
                    max={10}
                    label={`${embeddingIndex}`}
                  />
                )
              })
            }
          </div>
        </div>
        <div className={classes.subcategoryComponentSliders}>
          <div className={classes.headerSlider}>
            <div
              className={classes.settingsButton}
              onClick={() => setShowSubcategoryComponents(prevShowSubcategoryComponents => !prevShowSubcategoryComponents)}
            >
              <ExpandMoreIcon/>
            </div>
            <ContinuousSlider
              key={'allSubcategoriesSlider'}
              value={props.subcategoriesWeight}
              setValue={handleSubcategoriesWeightChange}
              min={0}
              step={0.1}
              max={10}
              label={'All Subcategories'}
            />
          </div>
          <div className={`${classes.individualSubcategoryComponentSliders} ${showSubcategoryComponents ? '' : classes.hidden}`}>
            {
              subcategoryComponentIndices.map((embeddingIndex, subcategoryId) => {
                return (
                  <ContinuousSlider
                    key={`SubcategorySlider${embeddingIndex}`}
                    value={props.embeddingWeights[embeddingIndex]}
                    setValue={newValue => props.onEmbeddingWeightChange(embeddingIndex, newValue)}
                    min={0}
                    step={0.1}
                    max={10}
                    label={`${props.subcategories[subcategoryId].name}`}
                  />
                )
              })
            }
          </div>
        </div>
        <div className={classes.attributeComponentSliders}>
          <div className={classes.headerSlider}>
            <div
              className={classes.settingsButton}
              onClick={() => setShowAttributeComponents(prevShowAttributeComponents => !prevShowAttributeComponents)}
            >
              <ExpandMoreIcon/>
            </div>
            <ContinuousSlider
              key={'allAttributesSlider'}
              value={props.attributesWeight}
              setValue={handleAttributesWeightChange}
              min={0}
              step={0.1}
              max={10}
              label={'All Attributes'}
            />
          </div>
          <div className={`${classes.individualAttributeComponentSliders} ${showAttributeComponents ? '' : classes.hidden}`}>
            {
              Object.keys(props.attributeCategoryAttributeMap).map(attributeCategoryId => {
                return (
                  <div
                    key={`attributeCategory-${props.attributeCategories[attributeCategoryId].name}-container`}
                  >
                    <div className={classes.subHeaderSlider}>
                      <div
                        className={classes.settingsButton}
                        onClick={() => updateShowAttributeCategoryComponents(attributeCategoryId)}
                      >
                        <ExpandMoreIcon/>
                      </div>
                      <ContinuousSlider
                        key={`attributeCategory-${props.attributeCategories[attributeCategoryId].name}`}
                        value={props.attributeCategoriesWeights[attributeCategoryId]}
                        setValue={newValue => handleAttributeCategoryWeightChange(attributeCategoryId, newValue)}
                        min={0}
                        step={0.1}
                        max={10}
                        label={`${props.attributeCategories[attributeCategoryId].name}`}
                      />
                    </div>
                    <div className={`${classes.individualAttributeCategoryComponentSliders} ${showAttributeCategoryComponents[attributeCategoryId] ? '' : classes.hidden}`}>
                      {
                        props.attributeCategoryAttributeMap[attributeCategoryId].map(attributeId => {
                          return (
                            <ContinuousSlider
                              key={`attribute-${props.attributes[attributeId].name}`}
                              value={props.embeddingWeights[attributeComponentIndices[attributeId]]}
                              setValue={newValue => props.onEmbeddingWeightChange(attributeComponentIndices[attributeId], newValue)}
                              min={0}
                              step={0.1}
                              max={10}
                              label={`${props.attributes[attributeId].name}`}
                            />
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}