import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import EmbeddingWeightSelector from './EmbeddingWeightSelector'


const useStyles = makeStyles((theme) => ({
  root: {

  },
  header: {
    backgroundColor: '#eef1f5',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: '50px',
    margin: 'auto'
  },
  title: {
    textTransform: 'uppercase',
    width: '20%',
    margin: 'auto 0',
    padding: '0 20px',
    fontFamily: 'Montserrat',
    color: '#76787a',
    fontWeight: '500',
    fontSize: '1em'
  },
  closeContainer: {
    width: '20px',
    marginTop: '10px',
    marginRight: '15px'
  },
  close: {
    cursor: 'pointer'
  },
  doneContainer: {
    width: '20px',
    marginTop: '10px',
    marginRight: '15px',
    marginLeft: 'auto'
  },
  done: {
    cursor: 'pointer'
  },
  settingsBody: {
    padding: '5px'
  },
  outfitRestrictionsSection: {

  },
  outfitRestrictionsSectionHeader: {

  },
  outfitRestrictionsSectionBody: {

  },
  userIdRestrictionsContainer: {
    height: '120px',
    width: '300px'
  },
  otherSection: {

  },
  otherSectionHeader: {

  },
  otherSectionBody: {

  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: '50px',
    margin: 'auto'
  },
  slideSwitchContainer: {
    margin: '10px'
  },
  embeddingWeightSelectorContainer: {

  }
}))


export default function Settings(props) {
  const classes = useStyles()

  const handleUpdateSettings = (newSettings) => {
    props.handleUpdateSettings(newSettings)
  }

  const handleEmbeddingWeightChange = (embeddingIndex, newWeight) => {
    let newSettings = JSON.parse(JSON.stringify(props.settings))
    newSettings.similars.embedding_weights[embeddingIndex] = newWeight
    handleUpdateSettings(newSettings)
  }

  const handleEmbeddingWeightsChange = (embeddingIndices, newWeight) => {
    let newSettings = JSON.parse(JSON.stringify(props.settings))
    for (let embeddingIndex of embeddingIndices) {
      newSettings.similars.embedding_weights[embeddingIndex] = newWeight
    }
    handleUpdateSettings(newSettings)
  }

  const handleDone = () => {
    props.handleDone()
    props.handleClose()
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <div className={classes.title}>
                      Similar Settings
          </div>
          <div className={classes.closeContainer}>
            <div
              className={classes.close}
              onClick={props.handleClose}
            >
              <CloseIcon/>
            </div>
          </div>
        </div>
        <div className={classes.settingsBody}>
          <div className={classes.otherSection}>
            <div className={classes.otherSectionHeader}>
                          Other Settings
            </div>
            <div className={classes.otherSectionBody}>
              <div className={classes.embeddingWeightSelectorContainer}>
                <EmbeddingWeightSelector
                  subcategories={props.subcategories}
                  attributes={props.attributes}
                  attributeCategories={props.attributeCategories}
                  attributeCategoryAttributeMap={props.attributeCategoryAttributeMap}
                  embeddingWeights={props.settings.similars.embedding_weights}
                  onEmbeddingWeightChange={handleEmbeddingWeightChange}
                  onEmbeddingWeightsChange={handleEmbeddingWeightsChange}
                  embeddingComponentsWeight={props.embeddingComponentsWeight}
                  setEmbeddingComponentsWeight={props.setEmbeddingComponentsWeight}
                  subcategoriesWeight={props.subcategoriesWeight}
                  setSubcategoriesWeight={props.setSubcategoriesWeight}
                  attributesWeight={props.attributesWeight}
                  setAttributesWeight={props.setAttributesWeight}
                  attributeCategoriesWeights={props.attributeCategoriesWeights}
                  setAttributeCategoriesWeights={props.setAttributeCategoriesWeights}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.doneContainer}>
            <div
              className={classes.done}
              onClick={handleDone}
            >
              <DoneIcon/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}