import React, { Component } from 'react'
import api from '../../../api/api'
import '../../../assets/css/WearThatSearch/Categories.scss'

export default class Categories extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterOptions: []
    }
  }

  getCategories = async (language) => {
    const body = {
      params: {
        language: language
      }
    }
    api.getCategories(body)
      .then(async data => {
        const categories = {}
        data.shift()
        data.forEach(e => {
          if (this.props.availableCategories.includes(e._id)) {
            categories[e._id] = e.name
          }
        })
        await this.setState({
          filterOptions: categories
        })
      })
  }

  async componentDidMount () {
    let user = JSON.parse(localStorage.getItem('user'))
    let language = user.feSettings.language
    await this.getCategories(language)
  }
  // use content::before to add arrows and list-style none to remove the generic circles in the future

  render () {
    const { category_ids_restriction, handleCheckboxChange } = this.props
    const options = []
    for (const key of Object.keys(this.state.filterOptions)) {
      options.push(
        <label key={key} className='fancy-checkbox'>
          <input
            type='checkbox'
            name='category_ids_restriction'
            onChange={e => handleCheckboxChange(e)}
            checked={category_ids_restriction.includes(key)}
            value={key}
            key={key}
            className='checkbox'
          />
          <i className="fa fa-square unchecked"></i>
          <i className="fa fa-check checked"></i>
          <div className='some-label'>
            {this.state.filterOptions[key]}
            <br />
          </div>
        </label>
      )
    }
    return <div className='categories-wrapper'>{options}</div>
  }
}
