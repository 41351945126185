import React, { Component } from 'react'
import api from '../../../api/api'
import '../../../../node_modules/font-awesome/css/font-awesome.min.css'

import '../../../assets/css/WearThatSearch/Attribute.scss'

// const filterOptions = {}

// function getAttributes() {
//   let body
//   if (JSON.parse(localStorage.getItem('user')) !== null) {
//     body = { username: JSON.parse(localStorage.getItem('user')).username }
//   } else {
//     body = {}
//   }
//     api
//         .retrieveAttributesFromIds(body)
//         .then(async results => {
//             if (results.data.error) {
//                 return;
//             }
//             results.data.forEach(e => {
//               filterOptions[e.name] = e._id
//             })
//             console.log(filterOptions)
//         })
//         .catch(err => {
//             console.log("ERR when get attrs", err)
//         });
// }
//
// getAttributes()

export default class Attribute extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attributeQuery: '',
      matchingAttributes: [],
      filterOptions: []
    }
  }

  //value is index because filter query requires an attribute id
  //to fix

  async getAttributes(language) {
    const body = {
      params: {
        language: language
      }
    }
    api
      .getAttributes(body)
      .then(async results => {
        if (results.data.error) {
          return
        }
        const filterOptions = []
        results.data.forEach(e => {
          filterOptions[e.name] = e._id
        })
        await this.setState(
          {
            matchingAttributes: results.data.map(e => e.name),
            filterOptions: filterOptions
          },
        )
      })
      .catch(err => {
        console.log('ERR when get attrs', err)
      })
  }

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    let language = user.feSettings.language
    await this.getAttributes(language)
  }

  render() {

    const attrResults = []
    for (const key of Object.keys(this.state.matchingAttributes)) {
      attrResults.push(
        <label key={key} className='fancy-checkbox'>
          <input
            type="checkbox"
            onChange={this.props._handleCheckboxChange}
            name="attribute_ids_restriction"
            checked={this.props.attribute_ids_restriction.includes(
              this.state.filterOptions[this.state.matchingAttributes[key]].toString()
            )}
            value={this.state.filterOptions[this.state.matchingAttributes[key]]}
            key={key}
            className="checkbox"
          />
          <i className="fa unchecked" />
          <i className="fa fa-check checked" />
          <div className='some-label'>
            {this.state.matchingAttributes[key]}
          </div>
        </label>
      )
    }

    return (
      <div className='attribute-query-wrapper'>
        <form className="filter-query">
          <i className="fa fa-search" />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search Attributes"
            name="attributeQuery"
            value={this.state.attributeQuery}
            onChange={this._onChange}
          />
        </form>
        <form className="filter-results">{attrResults}</form>
      </div>
    )
  }

  _onChange = e => {
    e.preventDefault()
    if (this.props.loading) return
    this.setState({ attributeQuery: e.target.value }, this._handleSubmit)
  };

  //handle query for attributes
  _handleSubmit = () => {
    if (
      this.state.attributeQuery === '' ||
      this.state.attributeQuery === null
    ) {
      this.setState({ matchingAttributes: [...Object.keys(this.state.filterOptions)] })
      return
    }
    //Search is case insensitive and white spaces will be ignored so the query is more flexible
    let trimmedLowCaseInput = this.state.attributeQuery
      .replace(/\s/g, '')
      .toLowerCase()
    let matches = Object.keys(this.state.filterOptions).filter(attr =>
      attr
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(trimmedLowCaseInput)
    )
    this.setState({ matchingAttributes: matches })
  };
}
