import axios from 'axios'
import authClient from '../services/Auth'

class Utils {
  shuffle (array) {
    // Shuffles an array, similar to python's shuffle function
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
  }

  chunk (array, size) {
    const chunked_array = []
    let index = 0
    while (index < array.length) {
      chunked_array.push(array.slice(index, size + index))
      index += size
    }
    return chunked_array
  }

  makeid(length) {
    let result           = ''
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  async getLocations (gender) {
    const locations = (await axios.get(`${authClient.getEndpoint().url}v4/getAllLocations`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update category objects with active attribute
    locations.forEach((location) => (
      location.active = false
    ))

    return locations
  }

  async getCategories () {
    const categories = (await axios.get(`${authClient.getEndpoint().url}v4/getAllCategories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update category objects with active attribute
    categories.forEach((category) => (
      category.active = true
    ))

    return categories
  }

  async getAllSubcategories () {
    const subcategories = (await axios.get(`${authClient.getEndpoint().url}v4/getAllSubcategories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update subcategory objects with active subcategory
    subcategories.forEach((subcategory) => {
      subcategory.active = false
      subcategory.model_confidence = 1.0
    })

    // Set only the first subcategory as active by default
    subcategories[0].active = true

    return subcategories
  }

  async getAttributes () {
    const attributes = (await axios.get(`${authClient.getEndpoint().url}v4/getAllAttributes`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update attribute objects with active attribute
    attributes.forEach((attribute) => (
      attribute.active = false
    ))

    attributes.forEach((attribute) => (
      attribute.full_name = attribute.name
    ))

    return attributes
  }

  async getUsers () {
    let allUsers = authClient.getEndpointUsers()
    if (!allUsers) {
      allUsers = (await axios.get(`${authClient.getEndpoint().url}v4/getAllBrands`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authClient.getIdToken()
        }
      })).data
      // Update user objects with active attribute
      allUsers.forEach((user) => (
        user.active = true
      ))
    } else {
      allUsers = null
    }
    return allUsers
  }

  async getBrandsForUser (user_id) {
    const data = {
      user_id: user_id
    }

    const brands = (await axios.post(`${authClient.getEndpoint().url}v4/getAllBrandsForUser`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update attribute objects with active attribute
    brands.forEach((brand) => (
      brand.active = false
    ))

    return brands
  }

  async getShopsForUser (user_id) {
    const data = {
      user_id: user_id
    }

    const shops = (await axios.post(`${authClient.getEndpoint().url}v4/getAllShopsForUser`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update attribute objects with active attribute
    shops.forEach((shop) => (
      shop.active = false
    ))

    return shops
  }

  async getSizesForUser (user_id) {
    const data = {
      user_id: user_id
    }

    const sizes = (await axios.post(`${authClient.getEndpoint().url}v4/getAllSizesForUser`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authClient.getIdToken()
      }
    })).data

    // Update attribute objects with active attribute
    sizes.forEach((size) => (
      size.active = false
    ))

    return sizes
  }
}

const utils = new Utils()

export default utils
