const style = theme => ({
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  campaignCreate: {
    '@media only screen and (min-width: 576px) and (max-width: 960px)': {
      marginTop: '110px',
      maxWidth: '860px',
      marginLeft: 'auto'
    },
    '@media only screen and (min-width: 960px)': {
      marginTop: '110px',
      maxWidth: '940px',
      marginLeft: 'auto',
      paddingLeft: '80px'
    },
    '@media only screen and (min-width: 1359px)': {
      marginTop: '145px',
      maxWidth: '1135px',
      marginLeft: 'auto',
      paddingLeft: '80px'
    },

  },
  modalTitle: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '2px',
    lineHeight: '24px'
  },
  modalHeader: {
    height: '68px',
    padding: '23px 15px 20px 20px',
    background: '#E6EFFF',
    '& button': {
      color: '#86A8E0',
      opacity: 1
    }
  },
  row1: {
    display: 'flex',
    marginTop: '13px',
    marginLeft: '21px'
  },
  campaignName: {
    flex: 1,
    maxWidth: '705px',
    '& p': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '24px',
      marginBottom: '5px',
      opacity: 0.5
    }
  },
  campaignNameInput: {
    width: '100%',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiOutlinedInput-input': {
      padding: '15px 14px 15px 14px'
    },
    '& .MuiInputBase-input': {
      color: 'black',
      border: '1px solid #ECEEF5',
      'fontFamily': 'Montserrat',
      'fontSize': '15px',
      'fontWeight': '600',
      'letterSpacing': '0',
      'lineHeight': '24px'
    }
  },
  previewButtonContainer: {
    marginRight: '30px',
    marginTop: '29px',
    marginLeft: 'auto'
  },
  previewButton: {
    background: '#86A8E0',
    display: 'flex',
    height: '51px',
    marginLeft: '25px',
    width: '120px',
    borderRadius: '5px',
    '& p': {
      margin: 'auto',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center'
    }
  },
  selectAudiences: {
    flex: 1,
    marginRight: '30px',
    '& p': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '24px',
      marginBottom: '5px',
      opacity: 0.5
    }
  },
  searchBar: {
    backgroundColor: 'white'
  },
  autocompleteBar: {
    display: 'inline-flex',
    width: '100%'
  },
  lineBreak: {
    borderTop: '1px solid #DAE0EA',
    margin: '20px 25.5px 30px 19.75px'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 20px 10px',
    padding: '15px 10px',
    borderTop: '1px solid #e2e6ed',
    boxShadow:
      '0 4px 8px -8px rgba(0, 0, 0, 0.2), 0 10px 22px -15px rgba(0, 0, 0, 0.19)',
    zIndex: 9
  },
  outfitContainer: {
    boxSizing: 'border-box',
    // "height": "490px",
    '@media only screen and (min-width: 700px)': {
      width: 'auto'
    },
    '@media only screen and (min-width: 1365px)': {
      width: 'auto',
      marginRight:'20px'
    },
    '@media only screen and (min-width: 1439px)': {
      width: '1000px'
    },
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    marginBottom: '20px',
    marginLeft: '20px'
  },
  imageCarouselClassName: {
    borderBottom: '1px solid #DAE0EA'
  },
  outfitGarmentClassName: {
    border: '1px solid #DAE0EA',
    borderRadius: '5px'
  },
  saveButtonsContainer: {
    display: 'flex',
    marginTop: '29px',
    marginLeft: 'auto',
    marginRight: '30px'
  },
  saveButton: {
    background: 'white',
    display: 'flex',
    height: '51px',
    width: '130px',
    marginLeft: '25px',
    borderRadius: '5px',
    border: '1px solid #86A8E0',
    '& p': {
      margin: 'auto',
      color: '#86A8E0',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center'
    }
  },
  sendButton: {
    background: '#86A8E0',
    marginLeft: '10px',
    display: 'flex',
    height: '51px',
    width: '130px',
    borderRadius: '5px',
    '& p': {
      margin: 'auto',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center'
    }
  }

})

export default style
