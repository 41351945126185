/*!
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
*/
import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
// creates a beautiful scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Navbar from 'components/Navbars/Navbar.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'

import MouseContextProvider from '../context/mouse-context'

// Dashboard Routes Imports
import Search from '@material-ui/icons/Search'
import ViewList from '@material-ui/icons/ViewList'
import FilterList from '@material-ui/icons/FilterList'
import Web from '@material-ui/icons/Web'
import Chat from '@material-ui/icons/Chat'
// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.jsx'
import ProductRanking from 'views/ProductRanking/ProductRanking.jsx'
import SearchPage from 'views/Search/SearchPage.jsx'
import ChatbotMain from 'views/ChatbotMain/ChatbotMain.jsx'
// import ChatbotProductSelection from 'views/ChatbotProductSelection/ChatbotProductSelection.jsx'
import OutfitLandingPage from 'views/OutfitDemo/OutfitLandingPage'
import DemoOutfits from 'views/OutfitDemo/DemoOutfits'
import SimilarityDemo from 'views/Similarities/SimilarityDemo'
import EditorIntroPage from '../components/EditorIntroPage/EditorIntroPage'
import IntroOutfitEditorV2 from 'views/OutfitEditorV2/IntroOutfitEditorV2'
// End of Dashboard Routes Imports

import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'

import image from 'assets/img/intelistyle_background.jpeg'
import BackgroundRemover from 'views/BackgroundRemover/BackgroundRemover'
import Editor from 'views/BackgroundRemover/Editor'
import NewOutfitEditor from 'views/OutfitEditor/OutfitEditor'
import AttributeEditor from 'views/AttributeEditor/AttributeEditor'
import AttributeEditorV2 from 'views/AttributeEditorV2/AttributeEditorV2'
import {ReactComponent as AttributeEditorIcon} from '../assets/img/attribute_editor.svg'
import {ReactComponent as OutfitEditorIcon} from '../assets/img/outfit_editor.svg'
import {ReactComponent as BgRemoverIcon} from '../assets/img/eraser-black.svg'
import {ReactComponent as DashboardIcon} from '../assets/img/home.svg'
import {ReactComponent as DemosIcon} from '../assets/img/demos.svg'
let ps

class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image: image,
      color: 'blue',
      hasImage: true,
      fixedClasses: 'dropdown show',
      mobileOpen: false
    }
  }

  mainPanel = React.createRef();
  handleImageClick = image => {
    this.setState({ image: image })
  };
  handleColorClick = color => {
    this.setState({ color: color })
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' })
    } else {
      this.setState({ fixedClasses: 'dropdown' })
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };
  getRoute() {
    return window.location.pathname !== '/admin/maps'
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      if (this.mainPanel.current !== null) {
        ps = new PerfectScrollbar(this.mainPanel.current)
      }
    }
    window.addEventListener('resize', this.resizeFunction)
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false })
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      if (this.mainPanel.current !== null) {
        ps.destroy()
      }
    }
    window.removeEventListener('resize', this.resizeFunction)
  }
  render() {
    // This part takes care of the routes
    let user = JSON.parse(localStorage.getItem('user'))
    // if (user === null || user.feSettings === undefined) {
    //   user = { feSettings: {} }
    // }
    const dashboardRoutes = []

    if (!user) user = {feSettings: {}}
    if (!user.feSettings) { user.feSettings = { search: true } }

    if (user.feSettings.dashboard) {
      dashboardRoutes.push({
        path: '/dashboard',
        name: 'Dashboard',
        rtlName: 'لوحة القيادة',
        icon: DashboardIcon,
        component: DashboardPage,
        layout: '/admin'
      })
    }

    if (user.feSettings.productRanking) {
      dashboardRoutes.push({
        path: '/table',
        name: 'Product Ranking',
        rtlName: 'قائمة الجدول',
        icon: FilterList,
        component: ProductRanking,
        layout: '/admin'
      })
    }

    if (user.feSettings.outfitResults) {
      dashboardRoutes.push({
        path: '/outfits',
        name: 'Outfits',
        rtlName: 'قائمة الجدول',
        icon: ViewList,
        component: OutfitLandingPage,
        layout: '/admin'
      })
    }

    if (user.feSettings.similarResults) {
      dashboardRoutes.push({
        path: '/similars',
        name: 'Similar Results',
        rtlName: 'قائمة الجدول',
        icon: Web,
        component: SimilarityDemo, // TODO Clean cached similarities from before
        layout: '/admin'
      })
    }

    if (user.feSettings.search) {
      dashboardRoutes.push({
        path: '/search',
        name: 'Search',
        rtlName: 'قائمة الجدول',
        icon: Search,
        component: SearchPage,
        layout: '/admin'
      })
    }

    if (user.feSettings.chatbotProductSelection) {
      dashboardRoutes.push({
        path: '/chatbot',
        name: 'Chatbot',
        rtlName: 'قائمة الجدول',
        icon: Chat,
        component: ChatbotMain,
        layout: '/admin'
      })
    }
    if (user.feSettings.outfitEditor) {
      dashboardRoutes.push({
        path: '/IntroPageOutfitEditor',
        name: 'IntroPageOutfitEditor',
        rtlName: 'قائمة الجدول',
        icon: OutfitEditorIcon,
        component: () => EditorIntroPage({ path: 'NewOutfitEditor', component: NewOutfitEditor }),
        layout: '/admin'
      })
    }
    if (user.feSettings.outfitEditorV2) {
      dashboardRoutes.push({
        path: '/IntroOutfitEditorV2',
        name: 'IntroOutfitEditorV2',
        rtlName: 'V2قائمة الجدول',
        icon: OutfitEditorIcon,
        component: IntroOutfitEditorV2,
        layout: '/admin'
      })
    }
    if (user.feSettings.bgRemover) {
      dashboardRoutes.push({
        path: '/bg-remover',
        name: 'Background Remover',
        rtlName: '',
        icon: BgRemoverIcon,
        component: BackgroundRemover,
        layout: '/admin'
      })
    }

    if (user.feSettings.attributeEditor) {
      dashboardRoutes.push({
        path: '/IntroPageAttributeEditor',
        name: 'IntroPageAttributeEditor',
        rtlName: 'قائمة الجدول',
        icon: AttributeEditorIcon,
        component: () => EditorIntroPage({ path: 'AttributeEditor', component: AttributeEditor }),
        layout: '/admin'
      })
    }

    if (user.feSettings.attributeEditorV2) {
      dashboardRoutes.push({
        path: '/IntroPageAttributeEditorV2',
        name: 'IntroPageAttributeEditorV2',
        rtlName: 'قائمة الجدول',
        icon: AttributeEditorIcon,
        component: () => EditorIntroPage({ path: 'AttributeEditorV2', component: AttributeEditorV2 }),
        layout: '/admin'
      })
    }

    // Fix that normalises the routes so that they can be properly routed through switchRoutes.
    const newDashboardRoutes = []
    newDashboardRoutes.push({
      path: '/demoOutfits',
      name: 'Demo Outfits',
      rtlName: 'قائمة الجدول',
      icon: Chat,
      component: DemoOutfits,
      layout: '/admin'
    })

    newDashboardRoutes.push({
      path: '/bg-edit',
      name: 'Background Editor',
      rtlName: '',
      component: Editor,
      layout: '/admin'
    })

    dashboardRoutes.forEach(e => {
      if (e.subRoutes !== undefined) {
        e.subRoutes.forEach(e => newDashboardRoutes.push(e))
      } else {
        newDashboardRoutes.push(e)
      }
    })

    const switchRoutes = (
      <Switch>
        {newDashboardRoutes.map((prop, key) => {
          if (prop.layout === '/admin') {
            const SpecificComponent = prop.component
            return (
              <Route
                path={prop.layout + prop.path}
                render={(props) => <SpecificComponent {...props} field='name' /> }
                key={key}
              />
            )
          }
          return null
        })}
      </Switch>
    )

    //This part takes care of the rest of the document
    const { classes, ...rest } = this.props
    return (
      <MouseContextProvider>
        <div className={classes.wrapper}>

          <Sidebar
            routes={dashboardRoutes}
            logoText={'Intelistyle'}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color={this.state.color}
            {...rest}
          />
          <div className={classes.mainPanel} ref={this.mainPanel}>

            <Navbar
              routes={dashboardRoutes}
              handleDrawerToggle={this.handleDrawerToggle}
              setIsAuthenticated={this.props.setIsAuthenticated}
              {...rest}
            />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner
            classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
              <div className={classes.map}>{switchRoutes}</div>
            )}

          </div>
        </div>
      </MouseContextProvider>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(Dashboard)
