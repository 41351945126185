import { CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: 'grey',
  },
  top: {
    color: '#1a90ff',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  loadingSpinner: {
    position: 'fixed',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center',
    left: '46%',
    top: '20%',
    overflow: 'hidden',
  },
}))

const Spinner = () => {
  const classes = useStyles()

  return (
    <div className={classes.loadingSpinner}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          // {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          // {...props}
        />
      </div>
    </div>
  )
}

export default Spinner
