import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 30,
    padding: 3,
    display: 'flex',
    overflow: 'visible'
  },
  switchBase: {
    padding: 0,
    color: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    margin: '1.5px',
    '&$checked': {
      transform: 'translateX(21px)',
      color: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#86A8E0',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
  },
  thumb: {
    width: 25,
    height: 25,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  track: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: '#FFFFFF',
  },
  checked: {},
}))(Switch);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  textContainer: {
    margin: 'auto 10px',
    color: '#86A8E0',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center'
  },
  switchContainer: {

  }
}))

export default function SlideSwitch(props) {
  const classes = useStyles()

  const handleChange = () => {
    props.handleChange();
  };

  return (
    <FormControl>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          {props.title}
        </div>
        <div className={classes.switchContainer}>
          <CustomSwitch
            checked={props.checked}
            onChange={handleChange}
            name="checked"
          />
        </div>
      </div>
    </FormControl>
  );
}
