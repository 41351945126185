import React, {useState, useEffect} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import api from 'api/api'
import Attributes from '../AttributeEditor/Attributes'
import SearchBar from 'components/LabelEditor/SearchBar'
import Sidebar from 'components/LabelEditor/Sidebar'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from 'components/LabelEditor/Pagination'
import SortByDropdown from 'components/LabelEditor/SortByDropdown'
import authClient from '../../services/Auth'


const useStyles = makeStyles((theme) => ({
  attributesContainer: {
    width: '100%',
    maxWidth: '100%',
    //margin: '20px auto',
    //height: '88vh',
    maxHeight: '100%',
    //border: '1px solid #DEDEDE',
    borderRadius: '5px'
  },
  searchBarContainer: {
    width: 'auto',
    padding: '5px',
    borderBottom: '1px solid #DEDEDE',
  },
  headerControlsContainer: {
    //height: '35px',
    width: 'auto',
    display: 'flex',
    padding: '5px',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  mainContentContainer: {
    width: '100%',
    minWidth: '100%',
    height: '82vh',
    maxHeight: '82vh',
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto'
  },
  sideBarContainer: {
    width: '20%',
    minWidth: '20%',
    height: '100%',
    borderRight: '1px solid #DEDEDE',
    //borderRadius: '5px',
    //margin: '10px 5px',
    //padding: '5px'
  },
  attributeFixedContainer: {
    width: '100%'
  },
  attributeContainer: {
    width: '100%',
    height: '100%',
    padding: '5px',
    overflowY: 'auto'
  },
  attributeListContainer: {
    display: 'inline-block',
    width: '100%'
  },
  imageCarouselContainer: {
    width: '50%',
    height: '100%',
    margin: '20px',
    padding: 'auto'
  },
  productInfoTextContainer: {
    width: '50%',
    margin: '20px'
  },
  remoteProductInfoContainer: {
    height: '30%'
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 'fit-content'
  },
  backText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    margin: 'auto 3px'
  },
  searchPageText: {
    fontFamily: 'Montserrat',
    fontSize: '0.7em',
    marginBottom: '0px',
    margin: 'auto 3px'
  },
  productTitle: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  noGarmentsText: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px',
    textAlign: 'center'
  },
  productBrand: {
    fontFamily: 'Montserrat',
    fontSize: '1em',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  loadingSpinner: {
    height: '40px',
    width: '40px',
    margin: 'auto'
  }
}))

export default function AttributeEditor(props) {
  const classes = useStyles()
  const [garments, setGarments] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [genders, setGenders] = useState(null)
  const [users, setUsers] = useState(null)
  const [categories, setCategories] = useState(null)
  const [subcategories, setSubcategories] = useState(null)
  const [subcategoryAttributeMap, setSubcategoryAttributeMap] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [attributeCategories, setAttributeCategories] = useState(null)
  const [attributeCategoryAttributeMap, setAttributeCategoryAttributeMap] = useState(null)
  const [productCategories, setProductCategories] = useState(null)
  const [productCategoriesExclude, setProductCategoriesExclude] = useState(null)
  const [selected, setSelected] = useState([])
  const [approvalLevel, setApprovalLevel] = useState(1)
  const [approvalLevelShow, setApprovalLevelShow] = useState(0)
  const [subcategoryMinApprovalLevelShow, setSubcategoryMinApprovalLevelShow] = useState(0)
  const [copyIndex, setCopyIndex] = useState(null)
  const [limit, setLimit] = useState(25)
  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inStock, setInStock] = useState(null)
  const [approveUpstream, setApproveUpstream] = useState(null)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [sortBy, setSortBy] = useState({'createdAt': -1})
  const [selectedUserIds, setSelectedUserIds] = useState(null)
  const [selectedSource, setSelectedSource] = useState(null)

  const getSelectedIds = (selectionArray) => {
    let selectedIds = selectionArray
      .filter(selectionArray => selectionArray.active)
      .map(selectionArray => selectionArray._id)

    if (selectedIds.length === 0) {
      selectedIds = null
    }
    return selectedIds
  }

  const getSelectedNames = (selectionArray) => {
    let selectedNames = selectionArray
      .filter(selectionArray => selectionArray.active)
      .map(selectionArray => selectionArray.name)

    if (selectedNames.length === 0 || selectedNames.length === selectionArray.length) {
      selectedNames = null
    }
    return selectedNames
  }

  const getGarmentRestrictions = () => {
    let garmentRestrictionsBase = {
      subcategory_data: {
        min_approved: subcategoryMinApprovalLevelShow
      },
      attribute_data: {
        approved: approvalLevelShow
      }
    }

    if (genders !== null) {
      garmentRestrictionsBase.gender = getSelectedIds(genders)
    }

    if (users !== null) {
      garmentRestrictionsBase.user_ids_restriction = getSelectedIds(users)
    }
    if (categories !== null) {
      garmentRestrictionsBase.category_data = {
        categories: getSelectedIds(categories)
      }
    }
    if (subcategories !== null) {
      garmentRestrictionsBase.subcategory_data.subcategories = getSelectedIds(subcategories)
    }
    if (attributes !== null) {
      garmentRestrictionsBase.attribute_data.attributes = getSelectedIds(attributes)
    }
    if (searchInput !== '') {
      garmentRestrictionsBase.search_query = searchInput
    }
    if (inStock !== null) {
      garmentRestrictionsBase.in_stock = inStock
    }

    if (productCategories !== null) {
      garmentRestrictionsBase.remote_product_categories_any = getSelectedNames(productCategories)
    }

    if (productCategoriesExclude !== null) {
      garmentRestrictionsBase.remote_product_categories_exclusion = getSelectedNames(productCategoriesExclude)
    }

    let garmentRestrictions = []

    let inEditorDate = new Date()
    inEditorDate.setHours(inEditorDate.getHours() - 4)

    let inEditorRestrictions = [
      {
        in_attribute_editor: {
          exists: false
        }
      },
      {
        in_attribute_editor: {
          user_ids_restriction: authClient.getUser()._id
        }
      },
      {
        in_attribute_editor: {
          user_ids_restriction: authClient.getUser()._id,
          updated_before: inEditorDate
        }
      }
    ]
    for (let inEditorRestriction of inEditorRestrictions) {
      let newGarmentRestrictions = Object.assign(
        JSON.parse(JSON.stringify(garmentRestrictionsBase)),
        inEditorRestriction
      )
      garmentRestrictions.push(newGarmentRestrictions)
    }

    return garmentRestrictions
  }

  const setGarmentInEditor = async (garmentId, inEditor) => {
    await api.setGarmentInEditor(garmentId, inEditor, 'attribute', {url: props.database})
  }

  const getStats = async () => {
    setStats([
      {
        name: 'Loading',
        _id: 0,
        active: false
      }
    ])

    let body
    body = {
      garment_restrictions: {
        attribute_data: {
          approved: approvalLevel
        }
      }
    }

    let statsPersonal = await api.getPersonalModelVettingStats({
      url: props.database,
      event_type: 'attributes'
    })

    // Mirroring format of filter options so we can use the same filter component for stats as well
    setStats([
      {
        name: `Total (Personal): ${statsPersonal}`,
        _id: 0,
        active: false
      }
    ])

    // Get total count
    let statsTotal = await api.countGarments(body)
    setStats(prevStats => [
      ...prevStats,
      {
        name: `Total: ${statsTotal.count}`,
        _id: 1,
        active: false
      }
    ])

    // Get total in-stock count
    body.garment_restrictions.in_stock = true
    if (users !== null) {
      body.garment_restrictions.user_ids_restriction = users.filter(user => user.active).map(user => user._id)
    }

    let statsTotalStock = await api.countGarments(body)

    // Get total available in-stock count
    delete body.garment_restrictions.attribute_data

    let statsTotalAvailableStock = await api.countGarments(body)

    setStats(prevStats => [
      ...prevStats,
      {
        name: `Total (In Stock, Selected Users): ${statsTotalStock.count} (${Math.round(100 * statsTotalStock.count / statsTotalAvailableStock.count)}%)`,
        _id: 2,
        active: false
      },
      {
        name: `Total Available (In Stock, Selected Users): ${statsTotalAvailableStock.count}`,
        _id: 3,
        active: false
      }
    ])

    let attributeCount = {count: 0}
    for (let attribute of attributes) {
      body = {
        garment_restrictions: {
          attribute_data: {
            approved: approvalLevel,
            attributes: [attribute._id]
          }
        }
      }
      attributeCount = await api.countGarments(body)
      let newElement = {
        name: `${attribute.name}: ${attributeCount.count}`,
        _id: attribute._id + 4,
        active: false
      }
      setStats(prevStats => [
        ...prevStats,
        newElement
      ])
    }
  }

  const getGarments = async () => {
    if (!loading) {
      setLoading(true)
    }

    // Set existing garments out of editor and remove if present
    if (garments !== null) {
      garments.forEach(garment => setGarmentInEditor(garment._id, false))
    }
    setGarments([])

    let data = {
      garment_restrictions: getGarmentRestrictions(),
      limit: limit,
      offset: page * limit,
      use_cache: false,
      sort_by: sortBy,
      url: props.database
    }

    let newGarmentsData = await api.listGarmentsV3(data)
    try {
      let newGarments = newGarmentsData.garments
      newGarments.forEach(garment => setGarmentInEditor(garment._id, true))
      setGarments(newGarments)
      setSelected(newGarments.map(() => false))
      setTotalPages(parseInt(Math.ceil(newGarmentsData.metadata.total / limit)))
    } catch {
      console.error('Failed to get garments')
    }
    setLoading(false)
  }

  const getSubcategoryAttributeMap = async () => {
    let subcategoryAttributeMap = await api.getSubcategoryAttributeMap({url: props.database})
    try {
      setSubcategoryAttributeMap(subcategoryAttributeMap)
    } catch {
      setSubcategoryAttributeMap({})
      console.error('Failed to get subcategory attribute map')
    }
  }

  const getAttributeCategories = async () => {
    let attributeCategories = await api.getAttributeCategories({url: props.database})
    try {
      attributeCategories = attributeCategories.data
      attributeCategories.forEach(attributeCategory => {
        attributeCategory.active = true
      })
      setAttributeCategories(attributeCategories)
    } catch {
      setAttributeCategories([])
      console.error('Failed to get attribute categories')
    }
  }

  const getAttributeCategoryAttributeMap = async () => {
    let attributeCategoryAttributeMap = await api.getAttributeCategoryAttributeMap({url: props.database})
    try {
      attributeCategoryAttributeMap = attributeCategoryAttributeMap.data
      setAttributeCategoryAttributeMap(attributeCategoryAttributeMap)
    } catch {
      setAttributeCategoryAttributeMap({})
      console.error('Failed to get attribute category attribute map')
    }
  }

  const onSearchInputChange = (searchInputUpdated) => {
    setSearchInput(searchInputUpdated)
  }

  const onSearchClick = () => {
    if (page === 0) {
      getGarments()
    } else {
      setPage(0)  // This will trigger a getGarments call
    }
  }

  const onApproveClick = async () => {
    setLoading(true)
    // Take copy of garments to work with
    let garmentsCopy = JSON.parse(JSON.stringify(garments))
    garmentsCopy.forEach(garment => setGarmentInEditor(garment._id, false))
    setGarments(null)  // Delete garments from state to trigger loading

    // For each of the garments just copied, update the approval level for the current quality setting and save
    for (let [index, garment] of garmentsCopy.entries()) {
      if (!selected[index]) {
        // If the subcategory is not marked as wrong, set all attributes for this garment to approved and save
        garment.attribute_data.approved = approvalLevel
        await api.approveAttributes({garment, url: props.database})

        if (approveUpstream) {
          if (garment.subcategory_data[0].approved < approvalLevel) {
            garment.subcategory_data[0].approved = approvalLevel
            await api.approveSubcategories({garment, url: props.database})
          }
          if (garment.category_data.approved < approvalLevel) {
            garment.category_data.approved = approvalLevel
            await api.approveCategories({garment, url: props.database})
          }
        }
      } else {
        // If the subcategory is marked as wrong, set subcategory back to unapproved and save
        garment.subcategory_data[0].approved = 0
        await api.approveSubcategories({garment, url: props.database})
      }
    }

    // Trigger search for new garments
    await getGarments()
  }

  const onApprovalLevelChange = (newApprovalLevel) => {
    setApprovalLevel(newApprovalLevel)
  }

  const onApprovalLevelShowChange = (newApprovalLevelShow) => {
    setApprovalLevelShow(newApprovalLevelShow)
  }

  const onSubcategoryMinApprovalLevelShowChange = (newSubcategoryMinApprovalLevelShow) => {
    setSubcategoryMinApprovalLevelShow(newSubcategoryMinApprovalLevelShow)
  }

  const onLimitChange = (newLimit) => {
    setLimit(newLimit)
  }

  const onInStockChange = () => {
    if (inStock === true) {
      setInStock(null)
    } else {
      setInStock(true)
    }
  }

  const onApproveUpstreamChange = () => {
    setApproveUpstream(prevApproveUpstream => !prevApproveUpstream)
  }

  const handleAttributeCheckboxClick = (index) => {
    setSelected(prevSelected => {
      let newSelected = JSON.parse(JSON.stringify(prevSelected))
      newSelected[index] = !newSelected[index]
      return newSelected
    })
  }

  const handleSelectAttributeClick = (garmentIndex, attributeId) => {
    setGarments(prevGarments => {
      let newGarments = JSON.parse(JSON.stringify(prevGarments))

      // Remove or add attribute from garment depending on whether it is already present or not
      if (newGarments[garmentIndex]
        .attribute_data
        .attributes
        .map(attribute => attribute._id)
        .includes(attributeId)) {
        // Garment already has specified attribute so we must remove it
        newGarments[garmentIndex]
          .attribute_data
          .attributes = newGarments[garmentIndex]
            .attribute_data
            .attributes
            .filter(attribute => attribute._id !== attributeId)
      } else {
        // Garment doesn't have specified attribute so it must be added
        // Get attribute object from allAttributes list
        let attribute = JSON.parse(
          JSON.stringify(
            attributes.filter(attribute => attribute._id === attributeId)[0]
          )
        )

        delete attribute.active

        // Add attribute object to garments attribute list
        newGarments[garmentIndex].attribute_data.attributes.push(attribute)

        // Sort attributes in order of id
        newGarments[garmentIndex].attribute_data.attributes.sort((a, b) => a._id - b._id)
      }

      return newGarments
    })
  }

  const onCopyClick = (index) => {
    setCopyIndex(index)
  }

  const onPasteClick = (index) => {
    if (copyIndex !== null) {
      setGarments(prevGarments => {
        let newGarments = JSON.parse(JSON.stringify(prevGarments))
        newGarments[index].attribute_data = JSON.parse(JSON.stringify(prevGarments[copyIndex].attribute_data))
        return newGarments
      })
    }
  }

  const onPasteAllClick = () => {
    if (copyIndex !== null) {
      setGarments(prevGarments => {
        let newGarments = JSON.parse(JSON.stringify(prevGarments))
        newGarments.forEach(garment => {
          garment.attribute_data = JSON.parse(JSON.stringify(garments[copyIndex].attribute_data))
        })
        return newGarments
      })
    }
  }

  const onRunJobClick = async () => {
    setLoading(true)
    let source = selectedSource[0].toLowerCase()
    if (source.includes('gameday')) {
      source = 'gamedaycouture'
    }
    await api.runVettingJob({source, url: props.database})
    setSelectedSource([])
    setLoading(false)
  }

  const handleNextGarmentPageClick = () => {
    const newPage = Math.min(
      totalPages - 1,
      page + 1
    )

    if (page !== newPage) {
      setPage(newPage)
    }
  }

  const handlePageSelectChange = (event) => {
    event.preventDefault()
    const newPage = parseInt(event.target.paginationInput.value) - 1
    if (!isNaN(newPage)) {
      if (newPage !== page) {
        setPage(newPage)
      }
    }
  }

  const handlePrevGarmentPageClick = () => {
    const newPage = Math.max(page - 1, 0)

    if (page !== newPage) {
      setPage(newPage)
    }
  }

  useEffect(() => {
    if (subcategoryAttributeMap === null) {
      getSubcategoryAttributeMap()
    }
  }, [subcategoryAttributeMap])

  useEffect(() => {
    if (attributeCategories === null) {
      getAttributeCategories()
    }
  }, [attributeCategories])

  useEffect(() => {
    if (attributeCategoryAttributeMap === null) {
      getAttributeCategoryAttributeMap()
    }
  }, [attributeCategoryAttributeMap])

  useEffect(() => {
    if (garments === null && users !== null && genders !== null && categories !== null && subcategories !== null && subcategoryAttributeMap !== null && attributes !== null && attributeCategories !== null && attributeCategoryAttributeMap !== null) {
      getGarments()
    }
  }, [genders, users, categories, subcategories, subcategoryAttributeMap, attributes, attributeCategories, attributeCategoryAttributeMap])

  useEffect(() => {
    if (garments !== null) {
      getGarments()
    }
  }, [page, sortBy])

  useEffect(() => {
    if (users !== null) {
      let newSelectedUserIds = users.filter(user => user.active).map(user => user._id)
      let newSelectedSource = users.filter(user => user.active).map(user => user.username)
      if (newSelectedUserIds.length === 0) {
        newSelectedUserIds = newSelectedSource = null
      }
      setSelectedUserIds(newSelectedUserIds)
      setSelectedSource(newSelectedSource)
    }
  }, [users])

  return (
    <div className={classes.attributesContainer}>
      <div className={classes.searchBarContainer}>
        <SearchBar
          show={true}
          searchInput={searchInput}
          onChange={onSearchInputChange}
          onSubmit={onSearchClick}
        />
      </div>
      <div className={classes.mainContentContainer}>
        <div className={classes.sideBarContainer}>
          <Sidebar
            url={props.database}
            setGenders={setGenders}
            setUsers={setUsers}
            setCategories={setCategories}
            setSubcategories={setSubcategories}
            setAttributes={setAttributes}
            setProductCategories={setProductCategories}
            setProductCategoriesExclude={setProductCategoriesExclude}
            stats={stats}
            onStatsClick={getStats}
            onApproveClick={onApproveClick}
            approvalLevel={approvalLevel}
            onApprovalLevelChange={onApprovalLevelChange}
            approvalLevelShow={approvalLevelShow}
            onApprovalLevelShowChange={onApprovalLevelShowChange}
            subcategoryMinApprovalLevelShow={subcategoryMinApprovalLevelShow}
            onSubcategoryMinApprovalLevelShowChange={onSubcategoryMinApprovalLevelShowChange}
            limit={limit}
            onLimitChange={onLimitChange}
            inStock={inStock}
            onInStockChange={onInStockChange}
            approveUpstream={approveUpstream}
            onApproveUpstreamChange={onApproveUpstreamChange}
            onPasteAllClick={onPasteAllClick}
            selectedUserIds={selectedUserIds}
            onRunJobClick={onRunJobClick}
            selectedSource={selectedSource}
          />
        </div>
        <div className={classes.attributeFixedContainer}>
          <div className={classes.headerControlsContainer}>
            <SortByDropdown
              setSortBy={setSortBy}
            />
            <Pagination
              onPrevGarmentPageClick={handlePrevGarmentPageClick}
              onPageSelectChange={handlePageSelectChange}
              onNextGarmentPageClick={handleNextGarmentPageClick}
              pageNumber={page}
              totalPages={totalPages}
            />
          </div>
          <div className={classes.attributeContainer}>
            <div className={classes.attributeListContainer}>
              {
                loading ?
                  <div className={classes.loadingSpinner}>
                    <CircularProgress/>
                  </div>
                  :
                  garments !== undefined && garments !== null && garments.length > 0 ?
                    garments.map((garment, garmentIndex) => (
                      <Attributes
                        key={garmentIndex}
                        id={garmentIndex}
                        garment={garment}
                        allAttributeCategories={attributeCategories}
                        allAttributes={attributes
                          .filter(attribute => subcategoryAttributeMap[garment.subcategory_data[0]._id].includes(attribute._id) || garment.attribute_data.attributes.map(garmentAttribute => garmentAttribute._id).includes(attribute._id))
                        }
                        attributeCategoryAttributeMap={attributeCategoryAttributeMap}
                        checkboxOnClick={handleAttributeCheckboxClick}
                        selected={selected[garmentIndex]}
                        handleSelectAttributeClick={handleSelectAttributeClick}
                        approvalLevel={approvalLevel}
                        setGarmentInEditor={setGarmentInEditor}
                        onCopyClick={onCopyClick}
                        onPasteClick={onPasteClick}
                      />
                    ))
                    :
                    <div className={classes.noGarmentsText}>
                            No garments available for the given filters
                    </div>
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
