import React from 'react'

import '../../../assets/css/WearThatSearch/Colour.scss'

const Colour = ({
  selectSeason,
  colour_restriction,
  selectFilter,
  seasonSelected
}) => {
  const coloursBySeasons = {
    Spring: ['Wheat', 'Goldenrod', 'Red', 'LightSeaGreen', 'LightCoral'],
    Summer: ['PowderBlue', 'Plum', 'Pink', 'Crimson', 'Blue'],
    Autumn: ['Moccasin', 'GoldenRod', 'DarkOliveGreen', 'Sienna', 'OrangeRed'],
    Winter: ['Red', 'RebeccaPurple', 'DarkTurquoise', 'HotPink', 'SkyBlue']
  }

  const options = []
  let index = 0
  for (const key of Object.keys(coloursBySeasons)) {
    options.push(
      <div
        key={index}
        className={seasonSelected.includes(key) ? 'submenu-selected' : 'color-filter-wrapper'}
      >
        <h6
          key={index + 1}
          onClick={selectSeason}
          value={key}
          index={index}
        >
          <i key={index} className={seasonSelected.includes(key) ? 'fa fa-minus' : 'fa fa-plus'} />
          {key}
        </h6>
        {seasonSelected.includes(key) && (
          <div key={index} className='color-cards'>
            {coloursBySeasons[key].map((el, i) => (
              <div
                name='colour_restriction'
                onClick={e => selectFilter(e)}
                season={key}
                value={el}
                index={i}
                key={i}
                className='color-circle'
                style={{
                  backgroundColor: el,
                  border:
                    colour_restriction[key].includes(el) && '2px solid #DAE0EA'
                }}
              >
                {
                  colour_restriction[key].includes(el) &&
                    <i
                      className='fa fa-check'
                      name='colour_restriction'
                      season={key}
                      value={el}
                      index={i}
                      key={i}
                    />
                }
              </div>
            ))}
          </div>
        )}
      </div>
    )
    index++
  }

  return <div className='color-filter-parent'>{options}</div>
}

export default Colour
