import React, { Component } from 'react'
import api from '../../../api/api'
import '../../../assets/css/WearThatSearch/Avoid.scss'

export default class Avoid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      attributeQuery: '',
      matchingAttributes: [],
      filterOptions: []
    }
  }

  async getAttributes() {
    const body = {
      username: JSON.parse(localStorage.getItem('user')).username
    }
    api
      .getAttributes(body)
      .then(async results => {
        if (results.data.error) {
          return
        }
        const filterOptions = []
        results.data.forEach(e => {
          filterOptions[e.name] = e._id
        })
        await this.setState({
          matchingAttributes: results.data.map(e => e.name),
          filterOptions: filterOptions
        })
      })
      .catch(err => {
        console.log('ERR when get attrs', err)
      })
  }

  async componentDidMount() {
    await this.getAttributes()
  }

  //value is index because filter query requires an attribute id

  render() {
    const attrResults = []
    for (const key of Object.keys(this.state.matchingAttributes)) {
      attrResults.push(
        <label key={key} className='fancy-checkbox'>
          <input
            type="checkbox"
            onChange={this.props._handleCheckboxChange}
            name="attribute_ids_exclusion"
            checked={this.props.attribute_ids_exclusion.includes(
              this.state.filterOptions[
                this.state.matchingAttributes[key]
              ].toString()
            )}
            value={this.state.filterOptions[this.state.matchingAttributes[key]]}
            key={key}
            className="checkbox"
          />
          <i className="fa   unchecked"></i>
          <i className="fa fa-check checked"></i>
          <div className='some-label'>
            {this.state.matchingAttributes[key]}
          </div>
        </label>
      )
    }

    return (
      <div className='avoid-wrapper'>
        <form className="filter-query">
          <i className="fa fa-search" />
          <input
            type="text"
            autoComplete="off"
            placeholder="Search Attributes"
            name="attributeQuery"
            value={this.state.attributeQuery}
            onChange={this._onChange}
          />
        </form>
        <form className="filter-results">{attrResults}</form>
      </div>
    )
  }

  _onChange = e => {
    e.preventDefault()
    if (this.props.loading) return
    this.setState({ attributeQuery: e.target.value }, this._handleSubmit)
  };

  _handleSubmit = () => {
    if (
      this.state.attributeQuery === '' ||
      this.state.attributeQuery === null
    ) {
      this.setState({
        matchingAttributes: [...Object.keys(this.state.filterOptions)]
      })
      return
    }
    let trimmedLowCaseInput = this.state.attributeQuery
      .replace(/\s/g, '')
      .toLowerCase()
    let matches = Object.keys(this.state.filterOptions).filter(attr =>
      attr
        .replace(/\s/g, '')
        .toLowerCase()
        .includes(trimmedLowCaseInput)
    )
    this.setState({ matchingAttributes: matches })
  };
}
