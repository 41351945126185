/*!

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

*/
import {
  drawerWidth,
  transition,
  container
} from 'assets/jss/material-dashboard-react.jsx'

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0'
    // height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100vw - ${drawerWidth})`
    },
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch'
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)'
  },
  container,
  map: {
    marginTop: '70px'
  }
})

export default appStyle
