const brand_columns = {
  'A': ['Loewe', 'The Row', 'SAINT LAURENT', 'Gucci', 'Chloé', 'Bottega Veneta', 'Balenciaga', 'Valentino',
    'Valentino Garavani', 'REDValentino'],
  'B': ['Akris', 'Alaïa', 'Alexander McQueen', 'AZ Factory', 'Balmain', 'Versace', 'Brioni', 'Burberry', 'Dolce & Gabbana',
    'Fendi', 'Givenchy', 'Lanvin', 'Ralph Lauren Collection', 'Stella McCartney', 'TOM FORD', 'Khaite', 'Manolo Blahnik',
    'TOTEME', 'Brunello Cucinelli', 'Oscar De La Renta', 'The Attico', 'Khaite'],
  'C': ['A.W.A.K.E. MODE', 'AGR', 'Ahluwalia', 'AMIRI', 'Ann Demeulemeester', 'AREA', 'ASHISH', 'Cecilie Bahnsen',
    'Christopher Esber', 'Christopher Kane', 'Commission', 'Conner Ives', 'Coperni', 'Dion Lee', 'GAUCHERE',
    'Grace Ling', 'Halpern', 'ioannes', 'JW Anderson', 'Jean Paul Gaultier', 'Jil Sander', 'Junya Watanabe',
    'Magda Butrym', 'LaQuan Smith', 'Louisa Ballou', 'Lukhanyo Mdingi', 'Maison Margiela', 'Maximilian',
    'Meryll Rogge', 'MINJUKIM', 'Molly Goddard', 'Monse', 'Mugler', 'Nensi Dojaka', 'Off-White', 'Petar Petrov',
    'Peter Do', 'Proenza Schouler', 'Proenza Schouler White Label', 'Rave Review', 'Renaissance Renaissance',
    'Richard Quinn', 'Rick Owens', 'Rosie Assoulin', 'Sacai', 'Simone Rocha', 'Studio 189', 'Supriya Lele',
    'The Attico', 'Thebe Magugu', 'Wales Bonner', 'WARDROBE.NYC'],
  'D': ['Adam Lippes', 'Alanui', 'Altuzarra', 'Bouguessa', 'Brunello Cucinelli', 'Dries Van Noten', 'Emilia Wickstead',
    'Emma Willis', 'Etro', 'Gabriela Hearst', 'Jason Wu', 'Jason Wu Collection', 'La DoubleJ', 'Lafayette148',
    'LAPOINTE', 'Le Kasha', 'Loro Piana', 'Marfa Stance', 'Marni', 'Max Mara', 'Michael Kors Collection', 'Missoni',
    'Moncler', 'Moncler Genius', 'Nina Ricci', 'PARTOW', 'Purdey', 'Roksanda', 'Ferragamo', 'Thom Browne',
    'UTZON', 'Victoria Beckham', 'Yves Salomon', 'Zeynep Arcay'],
  'E': ['16ARLINGTON', 'Alessandra Rich', 'Alex Perry', 'Alexis Mabille', 'Brandon Maxwell', 'Carolina Herrera',
    'Christopher John Rogers', 'Costarellos', 'Cushnie', 'Danielle Frankel', 'Emilia Wickstead', 'Erdem', 'Etro',
    'Gabriela Hearst', 'Galvan', 'Halfpenny London', 'Halston Heritage', 'Huishan Zhang', 'Jenny Packham', 'Kayu',
    'Les Rêveries', 'Magda Butrym', 'MARCHESA NOTTE', 'Michael Lo Sordo', 'Monique Lhuillier', 'NAEEM KHAN',
    'Needle & Thread', 'RASARIO', 'Retrofête', 'Rick Owens', 'Rime Arodaky', 'Roksanda', 'Roland Mouret',
    'Rosie Assoulin', 'Safiyaa', 'Self-Portrait', 'Smythson', 'Sophia Webster', 'Stella McCartney', 'Talbot Runhof',
    'Taller Marmo', 'Temperley London', 'Vanessa Cocchiaro'],
  'F': ['Alessandra Rich', 'Alex Perry', 'Alexandre Vauthier', 'Andrew Gn', 'Benchellal', 'Brandon Maxwell',
    'Carolina Herrera', 'Christopher John Rogers', 'Costarellos', 'David Koma', 'Elie Saab', 'Erdem', 'Et Ochs',
    'Fannie Schiavoni', 'Galvan', 'Hervé Léger', 'Huishan Zhang', 'Jenny Packham', 'Louisa Parris', 'MARCHESA NOTTE',
    'Michael Lo Sordo', 'Miss Sohee', 'Monique Lhuillier', 'Monot', 'Oscar De La Renta', 'RASARIO', 'Reem Acra',
    'Retrofête', 'Rodarte', 'Roland Mouret', 'Rowen Rose', 'Safiyaa', 'SemSem', 'Sergio Hudson', 'Solace London',
    'Talbot Runhof', 'Taller Marmo', 'Tongoro'],
  'G': ['ALÉMAIS', 'Alice + Olivia', 'Anine Bing', 'Anna Mason', 'APIECE APART', 'Arch4', 'BERNADETTE', 'Blazé Milano',
    'By Malene Birger', 'Cara Cara', 'CAROLINE CONSTAS', 'CASASOLA', 'Cefinn', 'Co', 'Cult Gaia',
    'Diane Von Furstenberg', 'Dodo Bar Or', 'Envelope1976', 'ESSE Studios', 'Giuliva Heritage', 'Helmut Lang',
    'Johanna Ortiz', 'SIMKHAI', 'Joseph', 'Khaite', 'La Ligne', 'LE 17 SEPTEMBRE', 'Lug Von Siga', 'LVIR',
    'MICHAEL Michael Kors', 'Officine Générale', 'Paco Rabanne', 'Philosophy Di Lorenzo Serafini', 'Rebecca Vallance',
    'RHODE', 'Rosetta Getty', 'Rue Mariscal', 'Saloni', 'Sebline', 'Theory', 'Tory Burch', 'TOVE',
    'Veronica Beard', 'Vince', 'Zimmermann'],
  'H': ['The Frankie Shop', 'Isabel Marant Étoile', 'Isabel Marant', 'Jacquemus', 'Ulla Johnson', 'Acne Studios', 'Reformation',
    'Self-Portrait', 'RIXO', 'DÔEN', 'GANNI', 'Tibi', 'Nanushka', 'Sea', 'Alexander Wang', 'STAUD', 'See By Chloé',
    'Batsheva', 'A.L.C.', 'Alexanderwang.t', 'GAUGE81', 'ROTATE Birger Christensen', '16ARLINGTON',
    'MM6 Maison Margiela', 'REMAIN Birger Christensen', 'Andersson Bell', 'O Pioneers', 'MCQ', 'MAISONCLÉO',
    '3.1 Phillip Lim', 'Marques\' Almeida', 'HVN', 'Horror Vacui', 'Comme Des Garçons Comme Des Garçons',
    'Comme Des Garçons GIRL', 'ANNA QUAN', 'Les Rêveries', 'Mother Of Pearl', 'SINDISO KHUMALO', 'Marc Jacobs',
    'aaizél', 'DE LA VALI', 'LOW CLASSIC', 'Solace London'],
  'I': ['&Daughter', 'AGOLDE', 'Alex Mill', 'Allude', 'ATM Anthony Thomas Melillo', 'B SIDES', 'Bassike', 'Caes',
    'Calle Del Mar', 'Citizens Of Humanity', 'Clements Ribeiro', 'Cormio', 'Deadwood', 'Denimist', 'E.L.V. Denim',
    'ERL', 'Extreme Cashmere', 'Faz not fur', 'Fear of God Essentials', 'FRAME', 'GOLDEN GOOSE', 'GOLDSIGN', 'GRLFRND',
    'HALFBOY', 'Holzweiler', 'James Perse', 'King & Tuckfield', 'L\'Agence', 'Lauren Manoogian', 'Les Tien',
    'Lingua Franca', 'Lisa Yang', 'LOULOU STUDIO', 'Mother', 'Mr Mittens', 'Nili Lotan', 'Ninety Percent',
    'Norma Kamali', 'Orseund Iris', 'Palm Angels', 'Polo Ralph Lauren', 'R13', 'Rag & Bone', 'Rains', 'RE/DONE',
    'Rivet Utility', 'Rose Carmine', 'SLVRLAKE', 'SPRWMN', 'Shoreditch Ski Club', 'Stand Studio', 'Still Here',
    'Suzie Kondi', 'The Elder Statesman', 'The Great', 'The Range', 'TOTEME', 'TU ES MON TRESOR', 'Veronica Beard',
    'Wandler', 'Zankov'],
  'J': ['16ARLINGTON', '3.1 Phillip Lim', 'Agnona', 'Alaïa', 'ALEXACHUNG', 'Alexander Wang', 'Alexandre Birman',
    'Alice + Olivia', 'Allude', 'Altuzarra', 'Ann Demeulemeester', 'Aquazzura', 'ATM Anthony Thomas Melillo',
    'The Attico', 'Balmain', 'Bella Freud', 'BOYY', 'Brunello Cucinelli', 'BY FAR', 'By Malene Birger', 'Canada Goose',
    'Christopher Kane', 'Church\'s', 'Co', 'Common Projects', 'Cult Gaia', 'Diane Von Furstenberg', 'Equipment',
    'Etro', 'FRAME', 'Gabriela Hearst', 'GANNI', 'Gianvito Rossi', 'GRLFRND', 'Haider Ackermann', 'IRO',
    'Isabel Marant Étoile', 'Isabel Marant', 'J Brand', 'Jacquemus', 'James Perse', 'Jil Sander', 'Jimmy Choo',
    'Joseph', 'JW Anderson', 'Khaite', 'Les Rêveries', 'Lisa Marie Fernandez', 'Loeffler Randall', 'Maison Margiela',
    'Michael Kors Collection', 'MICHAEL Michael Kors', 'Missoni', 'MM6 Maison Margiela', 'Moschino', 'Mother',
    'Mother Of Pearl', 'Mugler', 'Nanushka', 'Needle & Thread', 'Nicholas Kirkwood', 'Norma Kamali', 'Olivia Von Halle',
    'Paul & Joe', 'Oscar De La Renta', 'Petar Petrov', 'Philosophy Di Lorenzo Serafini', 'Proenza Schouler', 'R13',
    'Rag & Bone', 'RE/DONE', 'Rejina Pyo', 'René Caovilla', 'Rick Owens', 'RIXO', 'Roger Vivier', 'Rokh',
    'Roksanda', 'Roland Mouret', 'RtA', 'See By Chloé', 'Self-Portrait', 'Sies Marjan', 'Solace London', 'Sophia Webster',
    'STAUD', 'Stella McCartney', 'STINE GOYA', 'Stuart Weitzman', 'Alexanderwang.t', 'Thom Browne', 'Tibi',
    'TOTEME', 'Valextra', 'Vanessa Bruno', 'Veronica Beard', 'Versace', 'Vetements', 'Victoria Beckham',
    'Victoria, Victoria Beckham', 'Vince', 'Wandler', 'we11done', 'Yuzefi', 'Zimmermann', 'Joanna Czech', 'NURSE JAMIE',
    'HAYO\'U', 'Slip', 'ARTIS BRUSH', 'ANGELA CAGLIA', 'SHAQUDA', 'RAINCRY', 'Cartier', 'IWC SCHAFFHAUSEN', 'Montblanc',
    'Vacheron Constantin', 'Jaeger-LeCoultre', 'Officine Panerai', 'Piaget', 'Perfect Moment', 'Maison Michel',
    'Susan Fang', 'TOM FORD'],
  'K': ['AARMY', 'Abysse', 'Adidas By Stella McCartney', 'Adidas Originals', 'Alo Yoga', 'And Wander', 'AZTECH MOUNTAIN',
    'Bala', 'WSLY', 'All Access', 'Le Ore', 'Barbour', 'Bogner', 'BOGNER FIRE+ICE', 'Canada Goose', 'Cordova',
    'Cynthia Rowley', 'Diane Kordas', 'District Vision', 'Dragon', 'Erin Snow', 'FALKE Ergonomic Sport System',
    'Farm Rio', 'Fendi', 'Fusalp', 'G/FORE', 'Girlfriend Collective', 'Goldbergh', 'Holden', 'JETSET', 'KASK',
    'Lacoste', 'L\'ETOILE SPORT', 'Live The Process', 'LULULEMON', 'MACKAGE', 'Moncler', 'Moncler Grenoble', 'Nagnata',
    'Nike', 'ON', 'Outdoor Voices', 'P.E NATION', 'Perfect Moment', 'POINTE STUDIO', 'Port De Bras', 'Rapha',
    'Reebok X Victoria Beckham', 'Snow Peak', 'Splits59', 'Sporty & Rich', 'STAUD X NB', 'The North Face',
    'The Upside', 'Toni Sailer', 'Tory Sport', 'Varley', 'Versace', 'We Norwegians', 'WONE', 'Year Of Ours'],
  'L': ['Agent Provocateur', 'Agua By Agua Bendita', 'BONDI BORN', 'Camilla', 'Caravana', 'Charo Ruiz', 'Christopher Esber',
    'Cortana', 'Cover Swim', 'Cult Gaia', 'Dodo Bar Or', 'Dolce & Gabbana', 'Dries Van Noten', 'Emilia Wickstead',
    'Emilio Pucci', 'Erdem', 'Eres', 'Etro', 'Evarae', 'Eywasouls Malibu', 'Faithfull The Brand', 'Farm Rio', 'Fendi',
    'Fil De Vie', 'Fisch', 'GANNI', 'Haight', 'Hannah Artwear', 'Honorine', 'Hunza G', 'Isa Boulder', 'Jade',
    'Johanna Ortiz', 'Joslin', 'Le Sirenuse Positano', 'LemLem', 'Leslie Amon', 'Lisa Marie Fernandez',
    'Loretta Caponi', 'LoveShackFancy', 'Magda Butrym', 'Mara Hoffman', 'Marysia', 'Matteau', 'Melissa Odabash',
    'Miguelina', 'Missoni', 'Norma Kamali', 'Odyssee', 'Oséree', 'Paradised', 'PatBO', 'Peony', 'Pippa Holt',
    'Rose Carmine', 'Savannah Morrow The Label', 'Solid & Striped', 'Stella McCartney', 'TERRY', 'The Attico',
    'Three Graces London', 'Tory Burch', 'Ulla Johnson', 'Versace', 'Vita Kin', 'ViX', 'WAIMARI', 'Yvonne S',
    'Zeus+Dione', 'Zimmermann'],
  'N': ['Alexandra Llewellyn', 'Alice Cicolini', 'Alison Lou', 'Almasika', 'Amrapali London', 'Andrea Fohrman', 'Anita Ko',
    'Ananya', 'Azlee', 'Balmain', 'Bayco', 'Bina Goenka', 'Boghossian', 'BOUCHERON', 'Brent Neale', 'Brooke Gregson',
    'Buccellati', 'Carolina Bucci', 'Cartier', 'Cece Jewellery', 'CHARLES OUDIN', 'Chopard', 'Courbet', 'CVC Stones',
    'David Yurman', 'David Morris', 'Delfina Delettrez', 'De Beers', 'Diane Kordas', 'Dubini', 'EÉRA', 'Elizabeth Gage',
    'Emily P. Wheeler', 'Fabergé', 'Fernando Jorge', 'Foundrae', 'Fred Leighton', 'Garrard', 'Gemella',
    'Gigi Clozeau', 'Harwell Godfrey', 'Hermes', 'Ileana Makri', 'Irene Neuwirth', 'IWC SCHAFFHAUSEN',
    'Jacquie Aiche', 'Jade Trau', 'Jaeger-LeCoultre', 'Jennifer Meyer', 'Jessica McCormack', 'Kamyen',
    'Khiry FINE', 'Kimaï', 'Kolours', 'Larkspur & Hawk', 'Lauren Rubinski', 'L’Atelier Nawbar', 'Le Californienne',
    'LOREE RODKIN', 'Lito', 'Lorraine Schwartz', 'MARIA TASH', 'Marie Lichtenberg', 'Marlo Laz', 'Martin Katz',
    'Mason And Books', 'Mateo', 'Melissa Joy Manning', 'Melissa Kaye', 'Messika', 'Mizuki', 'Nadia Morgenthaler',
    'Octavia Elizabeth', 'OFIRA', 'Ole Lynggaard', 'Pascale Monvoisin', 'Piaget', 'Pippa Small', 'Pomellato',
    'Rainbow K', 'Repossi', 'Retrouvaí', 'Sabbadini', 'Sia Taylor', 'Selim Mouzannar', 'SHAY',
    'Silvia Furmanovich', 'Sophie Bille Brahe', 'Sorellina', 'Spinelli Kilcollin', 'State Property',
    'Stephen Webster', 'Storrow', 'Suzanne Kalan', 'Sydney Evan', 'Sylva & Cie', 'Vacheron Constantin',
    'Verdura', 'Viltier', 'YEPREM', 'Yvonne Léon', 'Bvlgari - Watchfinder US', 'Rolex - Watchfinder US',
    'Christan Dior - Watchfinder US', 'Patek Philippe - Watchfinder US', 'Omega - Watchfinder US'],
  'O': ['14 / Quatorze', 'Alessandra Rich', 'Alexander McQueen', 'Alighieri', 'Amina Muaddi', 'Anissa Kermiche',
    'Anita Berisha', 'AREA', 'Bea Bongiasca', 'Begum Khan', 'Burberry', 'By Pariah', 'Catbird', 'Charms Company',
    'Charms Company', 'Completedworks', 'Cult Gaia', 'Dolce & Gabbana', 'Dries Van Noten', 'Éliou', 'Fry Powers',
    'Givenchy', 'Hirotaka', 'Isabel Marant', 'Jacquemus', 'Jennifer Behr', 'Jennifer Fisher', 'JIA JIA', 'Katia Alpha',
    'KATKIM', 'Laura Lombardi', 'Leda Madera', 'LELET NY', 'Loren Stewart', 'The M Jewelers', 'Maria Black', 'Martha Calvo',
    'Of Rare Origin', 'Oscar De La Renta', 'Pacharee', 'Paco Rabanne', 'PEARL OCTOPUSS.Y', 'Persée', 'Retrofête',
    'Rosantica', 'Roxanne Assoulin', 'Roxanne First', 'Santangelo', 'Sarah and Sebastian', 'Simone Rocha',
    'Sophie Buhai', 'STONE AND STRAND', 'TBalance Crystals', 'Timeless Pearly', 'Versace', 'Wwake'],
  'P': ['Alaïa', 'Alexander McQueen', 'Balmain', 'Burberry', 'Christian Louboutin', 'Fendi', 'Givenchy', 'Jimmy Choo',
    'Judith Leiber Couture', 'Khaite', 'Loro Piana', 'Olympia Le-Tan', 'Savette', 'Serapian', 'Stella McCartney',
    'TOM FORD', 'Valextra'],
  'Q': ['Acne Studios', 'Alexander Wang', 'Altuzarra', 'Anya Hindmarch', 'BOYY', 'BY FAR', 'CALPAK', 'Cult Gaia',
    'Dragon Diffusion', 'Dries Van Noten', 'Etro', 'GANNI', 'Giuliva Heritage', 'Gu_de', 'Isabel Marant', 'JW Anderson',
    'Jacquemus', 'Jil Sander', 'Kassl Editions', 'Little Liffner', 'Loeffler Randall', 'Mansur Gavriel', 'Marni',
    'Maison Margiela', 'Nanushka', 'NEOUS', 'Oroton', 'Off-White', 'Paco Rabanne', 'Paravel', 'Proenza Schouler',
    'Proenza Schouler White Label', 'Rosantica', 'See By Chloé', 'Stand Studio', 'STAUD', 'The Attico', 'Ulla Johnson',
    'Wandler'],
  'R': ['ALAÏA EYEWEAR', 'Bottega Veneta Eyewear', 'Isabel Marant Eyewear', 'SAINT LAURENT Eyewear', 'Versace Eyewear',
    'Prada Eyewear', 'Givenchy Eyewear', 'Fendi Eyewear', 'Chloé Eyewear', 'Cartier Eyewear', 'CELINE Eyewear',
    'Balenciaga Eyewear'],
  'S': ['Alaïa', 'Alexander McQueen', 'Amina Muaddi', 'Aquazzura', 'Balmain', 'Benedetta Bruzziches', 'Christian Louboutin',
    'Dolce & Gabbana', 'Fendi', 'Gianvito Rossi', 'Givenchy', 'Jimmy Choo', 'Khaite', 'MACH & MACH', 'Manolo Blahnik',
    'TOM FORD', 'Versace'],
  'T': ['Adidas Originals', 'Alexander Wang', 'Asics', 'APL Athletic Propulsion Labs', 'ATP Atelier', 'Baudoin & Lange',
    'Brunello Cucinelli', 'Burberry', 'Common Projects', 'Converse', 'D’Accori', 'Gabriela Hearst', 'Giuseppe Zanotti',
    'GOLDEN GOOSE', 'Hoka One One', 'Isabel Marant', 'Jennifer Chamandi', 'Jil Sander', 'JW Anderson', 'Loro Piana',
    'Magda Butrym', 'Malone Souliers', 'New Balance', 'Nike', 'NODALETO', 'Off-White', 'On Running', 'Paris Texas',
    'Proenza Schouler', 'Rag & Bone', 'Reebok', 'Reformation', 'René Caovilla', 'Roger Vivier', 'Ferragamo', 'Wandler',
    'See By Chloé', 'Stella McCartney', 'Stuart Weitzman', 'The Attico', 'TKEES', 'Tod\'s', 'TOTEME', 'Veja', 'Vince'],
  'U': ['3.1 Phillip Lim', 'A Emery', 'Acne Studios', 'Aeyde', 'Alexandre Birman', 'Ancient Greek Sandals',
    'Ann Demeulemeester', 'Arizona Love', 'Birkenstock', 'Birkenstock 1774', 'Bogner', 'BY FAR', 'Castañer',
    'Cult Gaia', 'Dries Van Noten', 'Emme Parsons', 'GANNI', 'Jacquemus', 'LEGRES', 'Loeffler Randall',
    'Maison Margiela', 'Marni', 'Missoni', 'Moncler', 'Moon Boot', 'NEOUS', 'Porte & Paire', 'Rick Owens',
    'Sorel', 'ST. AGNI', 'STAUD', 'VIBI VENEZIA']
}

const clothingCategories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44]
const jewelleryCategories = [46, 47, 48, 49, 50, 51, 52, 53]
const beachwearCategories = [19, 20, 21, 22, 23]
const shoesCategories = [17, 18]
const bagsCategories = [31]
const eyewearCategories = [38]
// allCategoires should be the range of ints from 0 to 58 inclusive
const allCategories = [...Array(59).keys()]

let brand_category_restrictions = [
  // Jewellery from Column N to not be styled with clothing from Column C,G,H,I,K
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': jewelleryCategories,
        'group_value': brand_columns['N']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['C'].concat(brand_columns['G']).concat(brand_columns['H']).concat(brand_columns['I']).concat(brand_columns['K'])
      }
    ]
  },
  // Jewellery from Column O to not be styled with Column A,B,D,E,F,J,L
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': jewelleryCategories,
        'group_value': brand_columns['O']
      },
      {
        'value': allCategories,
        'group_value': brand_columns['A'].concat(brand_columns['B']).concat(brand_columns['D']).concat(brand_columns['E']).concat(brand_columns['F']).concat(brand_columns['J']).concat(brand_columns['L'])
      }
    ]
  },
  // Shoes from Column S to not be paired with clothing from columns H,I,K
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': shoesCategories,
        'group_value': brand_columns['S']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['H'].concat(brand_columns['I']).concat(brand_columns['K'])
      }
    ]
  },
  // Trainers from Column T to not be paired with clothing from column A,B,C,D,E,F,G,H,J,L
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': shoesCategories,
        'group_value': brand_columns['T']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['A'].concat(brand_columns['B']).concat(brand_columns['C']).concat(brand_columns['D']).concat(brand_columns['E']).concat(brand_columns['F']).concat(brand_columns['G']).concat(brand_columns['H']).concat(brand_columns['J']).concat(brand_columns['L'])
      }
    ]
  },
  // Shoes from Column U to not be paired with clothing from column A,B,C,D,E,F,G,J,K
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': shoesCategories,
        'group_value': brand_columns['U']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['A'].concat(brand_columns['B']).concat(brand_columns['C']).concat(brand_columns['D']).concat(brand_columns['E']).concat(brand_columns['F']).concat(brand_columns['G']).concat(brand_columns['K']).concat(brand_columns['J'])
      }
    ]
  },
  // Bags from Column P to not be styled with clothing from Column K
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': bagsCategories,
        'group_value': brand_columns['P']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['K']
      }
    ]
  },
  // Bags from Column Q to not be styled with clothing from columns A,B,D,E,F,J,K,L
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': bagsCategories,
        'group_value': brand_columns['Q']
      },
      {
        'value': clothingCategories,
        'group_value': brand_columns['A'].concat(brand_columns['B']).concat(brand_columns['D']).concat(brand_columns['E']).concat(brand_columns['F']).concat(brand_columns['J']).concat(brand_columns['K']).concat(brand_columns['L'])
      }
    ]
  },
  // Eye wear (R) to be styled with column L (inverted and implemented as a must not go together)
  // This rule is not exactly correct - since we are inverting and saying that eye wear from R must not go with brands
  // not in L, we should be getting all available brands then removing the brands in L. However, this is not easy so we
  // are just using all brands specified in the columns as the available brands. There may be other brands available in
  // the database that aren't specified in any column and these will be treated as if they are in column L
  {
    'type': 'garmentType',
    'restriction_value': 'must_not_go_together',
    'rules': [
      {
        'value': eyewearCategories,
        'group_value': brand_columns['R']
      },
      {
        'value': clothingCategories.concat(beachwearCategories),
        'group_value': brand_columns['A'].concat(brand_columns['B']).concat(brand_columns['C']).concat(brand_columns['D']).concat(brand_columns['E']).concat(brand_columns['F']).concat(brand_columns['G']).concat(brand_columns['H']).concat(brand_columns['I']).concat(brand_columns['J']).concat(brand_columns['K']).concat(brand_columns['N']).concat(brand_columns['O']).concat(brand_columns['P']).concat(brand_columns['Q']).concat(brand_columns['R']).concat(brand_columns['S']).concat(brand_columns['T']).concat(brand_columns['U'])
      }
    ]
  },
  // All clothing columns (B - L) need to be styled within their columns. (shoes, jewellery from any brand)
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['B']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['C']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['D']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['E']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['F']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['G']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['H']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['I']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['J']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['K']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': clothingCategories,
        'group_value': brand_columns['L']
      }
    ]
  },
  {
    'type': 'subcategories',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [183, 184].concat([...Array(133).keys()].map(x => x + 1)).concat([...Array(10).keys()].map(x => x + 159))
          .concat([...Array(6).keys()].map(x => x + 204)).concat([...Array(21).keys()].map(x => x + 216)),
        'group_value': brand_columns['L']
      },
      {
        'value': [143, 150],
        'group_value': brand_columns['S'].concat(brand_columns['T']).concat(brand_columns['U'])
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Loewe']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['The Row']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['SAINT LAURENT']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Gucci']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Chloé']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Bottega Veneta']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Balenciaga']
      }
    ]
  },
  {
    'type': 'garmentType',
    'restriction_value': 'must_go_together',
    'rules': [
      {
        'value': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 29, 30, 35, 40, 41, 42, 43, 44, 17, 18, 31],
        'group_value': ['Valentino', 'Valentino Garavani', 'REDValentino']
      }
    ]
  }
]

export default brand_category_restrictions