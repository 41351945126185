import React from 'react'
import undoIcon from '../../assets/img/undoIcon.png'
import redoIcon from '../../assets/img/redoIcon.png'
import statsIcon from '../../assets/img/statsIcon.png'
import Pagination from './Pagination'


export default function OutfitEditorActionBar(props) {
  let classes = props.styling
  return (
    <div className={classes.actionBar}>
      <p className={classes.outfitsText}>OUTFITS</p>
      <div title="Ctrl-Enter" className={`${props.enabled ? classes.approveContainerSelected : classes.approveContainerDisabled}`} onClick={() => props.handleApproveClick()}>
        <div className={classes.approveContainerChild}>
          <i className={`fa fa-check fa-lg ${classes.approveIcon}`} aria-hidden='true' />
          <p className={classes.approveTextSelected}>
            {
              `APPROVE SELECTED OUTFITS (${props.outfitSelected.filter(x => x === true).length})`
            }
          </p>
        </div>
      </div>
      <div className={classes.controlButtons}>
        <div className={`${classes.statsContainer} ${classes.buttonHover} ${classes.buttonContainerEnabled}`} onClick={() => props.onStatsClick()}>
          {
            <img alt='statsIcon' src={statsIcon} />
          }
          <p>STATS</p>
        </div>
        <div title="Ctrl-Z" className={`${classes.undoContainer} ${props.enabled && classes.buttonHover} ${props.enabled ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`} onClick={() => props.undo()}>
          <img alt='undoIcon' src={undoIcon} />
        </div>
        <div title="Ctrl-Y" className={`${classes.redoContainer} ${props.enabled && classes.buttonHover} ${props.enabled ? classes.buttonContainerEnabled : classes.buttonContainerDisabled}`} onClick={() => props.redo()}>
          <img alt='redoIcon' src={redoIcon} />
        </div>
        <Pagination
          styling={classes}
          page={props.page}
          totalPages={props.totalPages}
          setPage={props.setPage}
          enabled={props.enabled}
        />
      </div>
    </div>
  )
}
