import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import JohndoeIcon from '../../assets/img/johndoe.jpg'

const style = theme => ({
  root: {
    height: '59px',
    minWidth: '305px',
  },
  spinnerPosition: {
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  lastMessage: {
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    'color': '#949599',
    'fontFamily': 'Montserrat',
    'fontSize': '12px',
    'letterSpacing': '-0.1px',
    'lineHeight': '12px',
    paddingLeft: '85px'
  },
  name: {
    paddingLeft: '35px',
    paddingTop: '13px',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  active: {
    backgroundColor: 'rgba(134, 168, 224, 0.5)'
  },
  avatar: {
    width:'40px',
    borderRadius: '50%',
    marginRight: '10px'
  }
})


class PhoneNumber extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phoneNumber: this.props.phoneNumber
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.phoneNumber !== this.state.phoneNumber) {
      this.setState({ phoneNumber: this.props.phoneNumber })
    }
  }

  setActivePhoneNumber = async (phoneNumber) => {
    await this.props.setActivePhoneNumber(phoneNumber)
  }

  render () {
    const { classes } = this.props
    let appliedClass
    if (this.state.phoneNumber.active) {
      appliedClass = `${classes.root} ${classes.active}`
    } else {
      appliedClass = classes.root
    }
    return (
      <div style={{cursor: 'pointer'}} className={appliedClass} onClick={ () => { this.setActivePhoneNumber(this.state.phoneNumber.phoneNr) } }>
        <div className={classes.name}>
          <img src={JohndoeIcon} className={classes.avatar} alt='Unknown Avatar' />
          {this.state.phoneNumber.firstname + ' ' + this.state.phoneNumber.lastname}
        </div>
        <div className={classes.lastMessage}>
          {this.state.phoneNumber.last_message === undefined ? '' : ''}
        </div>
      </div>
    )
  }
}

export default withStyles(style)(PhoneNumber)
