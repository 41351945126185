/*!

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

*/
import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  drawerWidth
} from 'assets/jss/material-dashboard-react.jsx'

const headerStyle = theme => ({
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth})`
      // width: '100%'
      // marginRight: '30px' //TODO Figure this out
    },
    [theme.breakpoints.down('sm')]: {
      width: '100vw'
    },
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'fixed',
    zIndex: '',
    color: grayColor[7],
    borderColour: 'black',
    border: '20px',
    borderRadius: '0px',
    padding: '10px 0px 10px 10px',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block'
  },
  container: {
    ...container,
    minHeight: '50px'
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    letterSpacing: 'unset',
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    margin: '0',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  },
  appResponsive: {
    top: '8px'
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  profileMenu: {
    float: 'right',
    top: '0px'
  }
})

export default headerStyle
