import React, { Component } from 'react'

import InputRange from 'react-input-range'

import '../../../../node_modules/react-input-range/lib/css/index.css'
import '../../../assets/css/WearThatSearch/Price.scss'

export default class Price extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: { min: 0, max: 10000 },
      extremes: {min: 0, max: 10000}
    }
  }

  render() {
    return (
      <div className='price-wrapper'>
        <InputRange
          step={50}
          formatLabel={value => (
            <div className='input-range-label'>
              {`$ ${value}`}
            </div>
          )}
          maxValue={this.state.extremes.max}
          minValue={this.state.extremes.min}
          value={this.state.value}
          onChange={this._onChange}
          onChangeComplete={this._onChangeComplete}
        />
      </div>
    )
  }

  _onChange = val => {
    if (this.props.loading) return
    val.min = Math.max(this.state.extremes.min, val.min)
    val.max = Math.min(this.state.extremes.max, val.max)
    this.setState({ value: val })
  };

  _onChangeComplete = val => {
    val.min = Math.max(this.state.extremes.min, val.min)
    val.max = Math.min(this.state.extremes.max, val.max)
    this.setState({ value: val })
    this.props.selectPriceRange(val)
  };
}
