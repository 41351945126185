module.exports = {
  Spring: {
    Wheat: ['Moccasin', 'NavajoWhite', 'Linen', 'AntiqueWhite', 'Cornsilk'],
    Goldenrod: ['Gold', 'DarkGoldenrod', 'Peru'],
    Red: ['Tomato', 'OrangeRed', 'Crimson'],
    LightSeaGreen: [
      'DarkTurquoise',
      'DarkCyan',
      'CadetBlue',
      'MediumAquamarine',
      'MediumTurquoise'
    ],
    LightCoral: ['Salmon', 'Tomato', 'Coral']
  },

  Summer: {
    PowderBlue: [
      'SkyBlue',
      'PaleTurquoise',
      'LightSkyBlue',
      'LightSteelBlue',
      'LightBlue',
      'AliceBlue'
    ],
    Plum: ['VIOLET', 'LAVENDER', 'MEDIUMPURPLE', 'THISTLE'],
    Pink: ['THISTLE', 'MISTYROSE', 'LIGHTPINK', 'PALEVIOLETRED'],
    Crimson: ['Red'],
    Blue: ['DARKBLUE', 'MEDIUMBLUE', 'DARKSLATEBLUE', 'CORNFLOWERBLUE']
  },

  Autumn: {
    Moccasin: [
      'WHEAT',
      'NAVAJOWHITE',
      'LINEN',
      'ANTIQUEWHITE',
      'CORNSILK',
      'BLANCHEDALMOND',
      'BISQUE'
    ],
    GoldenRod: ['DARKGOLDENROD', 'GOLD', 'PERU'],
    DarkOliveGreen: ['DARKGREEN', 'FORESTGREEN', 'OLIVE', 'OLIVEDRAB'],
    Sienna: ['BROWN', 'CHOCOLATE', 'SADDLEBROWN', 'PERU'],
    OrangeRed: ['ORANGE', 'TOMATO']
  },

  Winter: {
    Red: ['ORANGERED', 'CRIMSON', 'FIREBRICK', 'DARKRED'],
    RebeccaPurple: ['DARKORCHID', 'DARKMAGENTA', 'BLUEVIOLET', 'PURPLE'],
    DarkTurquoise: [
      'TURQUOISE',
      'MEDIUMTURQUOISE',
      'DARKCYAN',
      'MEDIUMAQUAMARINE'
    ],
    HotPink: ['DEEPPINK', 'FUCHSIA', 'MAGENTA', 'VIOLET', 'PINK'],
    SkyBlue: [
      'POWDERBLUE',
      'PALETURQUOISE',
      'LIGHTSKYBLUE',
      'LIGHTSTEELBLUE',
      'LIGHTBLUE',
      'BLUE',
      'MEDIUMBLUE',
      'DARKSLATEBLUE'
    ]
  }
}
