import React from 'react'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginLeft: '10px',
    color: '#86A8E0',
    boxSizing: 'border-box',
    height: '30px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontWeight: '600'
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  text: {
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    color: '#86A8E0',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: '600',
    textAlign: 'center',
    margin: 'auto 2px',
    textTransform: 'uppercase'
  }
}))


export default function ButtonSmall(props) {
  const classes = useStyles()
  return (
    <div
      className={`${classes.root} ${classes.hover}`}
      onClick={props.onClick}
    >
      <div className={classes.text}>{props.text}</div>
    </div>
  )
}
