import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles(() => ({
  switchRow: {
    width: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'visible',
    justifyContent: 'space-between',
  },
  switchBase: {
    padding: 0,
    color: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    margin: '1.5px',
    '&$checked': {
      transform: 'translateX(21px)',
      color: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#E8004C',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
  },
  thumb: {
    width: 25,
    height: 25,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  track: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: '#FFFFFF',
  },
  checked: {},
  textContainer: {
    marginRight: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#161616',
    lineHeight: '18.75px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

export default function AttributeSlideSwitch(props) {
  const classes = useStyles()

  const handleChange = () => {
    props.handleChange()
  }

  return (
    <FormControl className={classes.switchRow}>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          {props.title}
        </div>
        <Switch
          checked={props.checked}
          onChange={handleChange}
          name="checked"
        />
      </div>
    </FormControl>
  )
}
