module.exports = {
  35: '2',
  35.5: '2.5',
  36: '3',
  36.5: '3.5',
  37: '4',
  37.5: '4.5',
  38: '5',
  38.5: '5.5',
  39: '6',
  39.5: '6.5',
  40: '7',
  40.5: '7.5',
  41: '8',
  41.5: '8.5'
}

// in some 41.5 is 9, 39 is 6.5
