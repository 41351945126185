import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    width: '100%',
    fontFamily: 'Montserrat, sans-serif',
    boxShadow: 'none',
  },
  accordionHeader: {
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
  },
  accordionSummary: {
    color: '#333',
    fontWeight: '500',
    fontSize: '14px',
    fontFamily: 'Roboto, sans-serif',
    background: '#F9F9F9',
    padding: '0'
  },
  accordionOption: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  },
  accordionDetails: {
    flexDirection: 'column',
    maxHeight: '200px', // Adjust the maximum height as needed
    overflowY: 'auto',
    backgroundColor: '#F5F6FA',
    padding: '0',
    paddingBottom: '1rem',
    '&::-webkit-scrollbar': {
      width: '12px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1', // Background color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888', // Color of the thumb
      borderRadius: '6px', // Rounded corners
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555', // Color when hovering
    },
  },
  formControlLabel: {
    margin: '0',
    fontFamily: 'Montserrat, sans-serif',
  },
  checkbox: {
    padding: '0 8px',
    color: '#777777', // Use the accent color for checkboxes
    '&.Mui-checked': {
      color: '#777777', // Use the accent color for checked state
    },
  },
  nestedAccordion: {
    width: '100%',
    backgroundColor: '#fafafa',
  },
}));

export default function NestedAccordionSection(props) {
  const classes = useStyles();
  const [activeGroups, setActiveGroups] = useState({});
  const [activeSubgroups, setActiveSubgroups] = useState({});
  const [mainExpanded, setMainExpanded] = useState(false);

  const onMainHeaderClick = () => {
    setMainExpanded(!mainExpanded);
  };

  const onGroupHeaderClick = (group) => {
    setActiveGroups((prev) => ({ ...prev, [group]: !prev[group] }));
  };

  const onSubgroupHeaderClick = (subgroup) => {
    setActiveSubgroups((prev) => ({ ...prev, [subgroup]: !prev[subgroup] }));
  };

  const onOptionClick = (option) => {
    props.onOptionClick(option);
  };

  const onSelectAllClick = () => {
    props.onSelectAllClick();
  };

  const groups = props.groups || [];

  return (
    <Accordion
      className={classes.accordionContainer}
      expanded={mainExpanded}
      onChange={onMainHeaderClick}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="attributes-content"
        id="attributes-header"
        className={classes.accordionSummary}
      >
        <Typography className={classes.accordionHeader}>{props.headerText}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {groups.map((group) => (
          <Accordion
            key={group.name}
            expanded={!!activeGroups[group.name]}
            onChange={() => onGroupHeaderClick(group.name)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${group.name}-content`}
              id={`${group.name}-header`}
              className={classes.accordionSummary}
            >
              <Typography>{group.name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {group.selectAllOption !== false && !props.disableSelection && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={group.allSelected}
                      onChange={onSelectAllClick}
                      name="selectAll"
                      color="primary"
                      className={classes.checkbox}
                    />
                  }
                  label="Select All"
                  className={classes.formControlLabel}
                />
              )}
              {group.subgroups && group.subgroups.map((subgroup) => (
                <Accordion
                  key={subgroup.name}
                  expanded={!!activeSubgroups[subgroup.name]}
                  onChange={() => onSubgroupHeaderClick(subgroup.name)}
                  className={classes.nestedAccordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${subgroup.name}-content`}
                    id={`${subgroup.name}-header`}
                    className={classes.accordionSummary}
                  >
                    <Typography>{subgroup.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {subgroup.attributes.map((attribute) => (
                      <div
                        key={`${subgroup.name}-accordionOption-${attribute.name}`}
                        className={classes.accordionOption}
                        onClick={!props.disableSelection ? () => onOptionClick(attribute) : null}
                      >
                        {!props.disableSelection && (
                          <Checkbox
                            checked={attribute.active}
                            onChange={() => onOptionClick(attribute)}
                            name={attribute.name}
                            color="primary"
                            className={classes.checkbox}
                          />
                        )}
                        <Typography>{attribute.name}</Typography>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
              {(group.subgroups === undefined || group.subgroups.length === 0) && group.attributes && group.attributes.map((attribute) => (
                <div
                  key={`${group.name}-accordionOption-${attribute.name}`}
                  className={classes.accordionOption}
                  onClick={!props.disableSelection ? () => onOptionClick(attribute) : null}
                >
                  {!props.disableSelection && (
                    <Checkbox
                      checked={attribute.active}
                      onChange={() => onOptionClick(attribute)}
                      name={attribute.name}
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  <Typography>{attribute.name}</Typography>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
