import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import api from '../../api/api'

const style = theme => ({
  table: {
    // margin: '20px',
    overflow: 'hidden',
    maxWidth: '1200px'
  },
  pending: {
    color: '#666666',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  approved: {
    color: '#00FF00',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  declined: {
    color: 'red',
    textAlign: 'center',
    textTransform: 'capitalize'
  },
  approveTick: {
    color: 'green',
    margin: '0 5px 0 5px'
  },
  declinedTick: {
    color: 'red',
    margin: '0 5px 0 5px'
  },
  delete: {
    color: 'grey',
    margin: '0 5px 0 5px'
  }
})

class ReservationMain extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      reservations: [],
      userId: JSON.parse(localStorage.getItem('user'))._id
    }
    this.getReservations = this.getReservations.bind(this)
  }

  async getReservations () {
    const reservations = (await api.findAllReservationsByUser({ userId: this.state.userId })).data
    if (reservations.error === undefined) {
      this.setState({ reservations: reservations })
    }
  }

  async approveReservation (reservation) {
    const body = {
      userId: this.state.userId,
      phoneNr: reservation.customer.phoneNr,
      productId: reservation.productId,
      status: 'approved'
    }
    await api.updateReservationStatus(body)
    await this.getReservations()
  }

  async declineReservation (reservation) {
    const body = {
      userId: this.state.userId,
      phoneNr: reservation.customer.phoneNr,
      productId: reservation.productId,
      status: 'declined'
    }
    await api.updateReservationStatus(body)
    await this.getReservations()
  }

  async deleteReservation (reservation) {
    const body = {
      userId: this.state.userId,
      phoneNr: reservation.customer.phoneNr,
      productId: reservation.productId
    }
    await api.deleteReservation(body)
    await this.getReservations()
  }

  async componentDidMount () {
    await this.getReservations()
  }

  render () {
    const { classes } = this.props
    return (
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label='chatbot reservations'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Name</TableCell>
              <TableCell align='center'>Phone Number</TableCell>
              <TableCell align='center'>Product</TableCell>
              <TableCell align='center'>Size</TableCell>
              <TableCell align='center'>VPN</TableCell>
              <TableCell align='center'>Price</TableCell>
              <TableCell align='center'>Product Url</TableCell>
              <TableCell align='center'>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.reservations.map(reservation => (
              <TableRow key={reservation._id}>
                <TableCell align='center'>{reservation.customer.name}</TableCell>
                <TableCell align='center'>{reservation.customer.phoneNr}</TableCell>
                <TableCell align='center'>{reservation.productId}</TableCell>
                <TableCell align='center'>{reservation.size}</TableCell>
                <TableCell align='center'>{reservation.vpn}</TableCell>
                <TableCell align='center'>{reservation.price}</TableCell>
                <TableCell align='center'><a href={reservation.product_url}>link</a></TableCell>
                <TableCell aling='center'>
                  <div className={reservation.status === 'pending' ? classes.pending : reservation.status === 'approved' ? classes.approved : classes.declined}>
                    {reservation.status}
                    <i className={`fa ${classes.approveTick} fa-check`} onClick={() => this.approveReservation(reservation)} />
                    <i className={`fa ${classes.declinedTick} fa-ban`} onClick={() => this.declineReservation(reservation)} />
                    {/* <i className={`fa ${classes.delete} fa-trash`} onClick={() => this.deleteReservation(reservation)} /> */}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default withStyles(style)(ReservationMain)
