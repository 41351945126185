const categories = [
  { full_name: 'None', _id: 0 },
  { full_name: 'Tops', _id: 1 },
  { full_name: 'Transparent Tops', _id: 2 },
  { full_name: 'Shirts-Blouses', _id: 3 },
  { full_name: 'Sweats', _id: 4 },
  { full_name: 'Trousers', _id: 5 },
  { full_name: 'Jeans', _id: 6 },
  { full_name: 'Shorts', _id: 7 },
  { full_name: 'Skirts', _id: 8 },
  { full_name: 'Leggings', _id: 9 },
  { full_name: 'Joggers', _id: 10 },
  { full_name: 'Outerwear', _id: 11 },
  { full_name: 'Blazers', _id: 12 },
  { full_name: 'Waistcoats', _id: 13 },
  { full_name: 'Dresses', _id: 14 },
  { full_name: 'Jumpsuits', _id: 15 },
  { full_name: 'Suits', _id: 16 },
  { full_name: 'Shoes', _id: 17 },
  { full_name: 'Trainers', _id: 18 },
  { full_name: 'Beachwear-Top', _id: 19 },
  { full_name: 'Beachwear-Bottom', _id: 20 },
  { full_name: 'Beachwear-Fullbody', _id: 21 },
  { full_name: 'Beachwear-Bikini', _id: 22 },
  { full_name: 'Beachwear-Coverups', _id: 23 },
  { full_name: 'Underwear-Top', _id: 24 },
  { full_name: 'Underwear-Bottom', _id: 25 },
  { full_name: 'Underwear-Fullbody', _id: 26 },
  { full_name: 'Underwear-Accessories', _id: 27 },
  { full_name: 'Hosiery', _id: 28 },
  { full_name: 'Socks', _id: 29 },
  { full_name: 'Tights', _id: 30 },
  { full_name: 'Bags', _id: 31 },
  { full_name: 'Wallets-Cardholders', _id: 32 },
  { full_name: 'Keyrings-Chains', _id: 33 },
  { full_name: 'Phone Cases', _id: 34 },
  { full_name: 'Belts', _id: 35 },
  { full_name: 'Braces', _id: 36 },
  { full_name: 'Headphones', _id: 37 },
  { full_name: 'Glasses', _id: 38 },
  { full_name: 'Gloves', _id: 39 },
  { full_name: 'Scarves', _id: 40 },
  { full_name: 'Ties', _id: 41 },
  { full_name: 'Bow-Ties', _id: 42 },
  { full_name: 'Hats', _id: 43 },
  { full_name: 'Hair Accessories', _id: 44 },
  { full_name: 'Towels', _id: 45 },
  { full_name: 'Earrings', _id: 46 },
  { full_name: 'Necklaces', _id: 47 },
  { full_name: 'Bracelets', _id: 48 },
  { full_name: 'Rings', _id: 49 },
  { full_name: 'Watches', _id: 50 },
  { full_name: 'Cufflinks', _id: 51 },
  { full_name: 'Body Jewellery', _id: 52 },
  { full_name: 'Brooches-Pins', _id: 53 },
  { full_name: 'Sleepwear-Top', _id: 54 },
  { full_name: 'Sleepwear-Bottom', _id: 55 },
  { full_name: 'Sleepwear-Fullbody', _id: 56 },
  { full_name: 'Sleepwear-Accessories', _id: 57 },
  { full_name: 'Sleepwear-Footwear', _id: 58 }
]

module.exports = {
  categories
}
