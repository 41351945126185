import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BoundingBox from './BoundingBox'
import Image from './Image'
import Utils from '../../utils/Utils'

import GarmentCategorySelector from './GarmentCategorySelector'

import cropbuttonimg from './cropbutton.png'

const useStyles = makeStyles((theme) => ({
  boundingBoxImageContainer:{
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '330px',
    height: '100%',
  },
  boundingBoxImage: {
    position: 'relative',
    height: 'auto',
    margin: '0 auto',
  },
  boundingBoxes: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  unselectable: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none'
  },
  cropButton: {
    userDrag: 'none',
    userSelect: 'none',
    transition: '0.5s',
    width: '30px',
    height: '30px',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    zIndex: '10',
    borderRadius: '8px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export default function BoundingBoxEditor(props) {
  const classes = useStyles()
  const [approvalLevel] = useState(1)
  const [currentBoxIndex, setCurrentBoxIndex] = useState(null)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [labelPage, setLabelPage] = useState(0)
  const [imageProps, setImageProps] = useState({
    height: null,
    width: null,
    offsetX: null,
    offsetY: null,
  })
  const [startX, setStartX] = useState(null)
  const [startY, setStartY] = useState(null)
  const [currX, setCurrX] = useState(null)
  const [currY, setCurrY] = useState(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const [editingBox, setEditingBox] = useState(false)
  const [minBoxSize] = useState(10)
  const [editMode, setEditMode] = useState(null)
  const [createMode, setCreateMode] = useState(false)

  const labelKeyCodes = [
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    48,
  ]

  // TODO - set current box index to null when getting new garment

  const getCurrentBox = () => {
    return calculateRectPosition(
      imageProps,
      {
        startX: startX,
        startY: startY,
        currX: currX,
        currY: currY
      }
    )
  }

  const getBoxLabelForKeyIndex = (keyIndex) => {
    let boxLabel = labelPage * labelKeyCodes.length + keyIndex
    if (boxLabel > props.boundingBoxLocations.length - 2) {
      boxLabel = null
    }
    return boxLabel
  }

  const onBoxLocationChange = (boxIndex, boxLabel) => {
    if (props.garment) {
      // Take deep copy of garment from state
      let garmentCopy = JSON.parse(JSON.stringify(props.garment))

      // Update label for requested box
      if (props.selectCategories) {
        let selectedCategory = props.categories.filter(category => category._id === boxLabel)[0]
        let categoryData = {
          name: selectedCategory.name,
          _id: boxLabel,
          approved: 1,
          model_confidence: 1.
        }

        let boundingBoxLocation = props.categoryBoundingBoxLocationMap[boxLabel]

        garmentCopy
          .garment_images[currentImageIndex]
          .bounding_box_data
          .bounding_boxes[boxIndex]
          .category_data = categoryData

        garmentCopy
          .garment_images[currentImageIndex]
          .bounding_box_data
          .bounding_boxes[boxIndex]
          .label = boundingBoxLocation

      } else {
        garmentCopy
          .garment_images[currentImageIndex]
          .bounding_box_data
          .bounding_boxes[boxIndex]
          .label = boxLabel
      }

      // Set bounding boxes back to unapproved so that interface doesn't indicate that it has saved when it hasnt
      garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .approved = 1

      // Update garment in state
      // props.setGarment(garmentCopy)

      // props.updateHistory(garmentCopy)
    }
  }

  const deleteBox = (boxIndex) => {
    if (props.garment) {
      // Take deep copy of garment from state
      let garmentCopy = JSON.parse(JSON.stringify(props.garment))
      let boxes = garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .bounding_boxes

      // Remove requested box
      boxes.splice(boxIndex, 1)
      garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .bounding_boxes = boxes

      // Update garment in state
      // props.setGarment(garmentCopy)
      setCurrentBoxIndex(null)

      // props.updateHistory(garmentCopy)
    }
  }

  const boxAbsoluteToRelative = (boxPosition) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))
    boxPosition.left /= imageProps.width
    boxPosition.top /= imageProps.height
    boxPosition.width /= imageProps.width
    boxPosition.height /= imageProps.height
    return boxPosition
  }

  const boxRelativeToAbsolute = (boxPosition) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))
    boxPosition.left *= imageProps.width
    boxPosition.top *= imageProps.height
    boxPosition.width *= imageProps.width
    boxPosition.height *= imageProps.height
    return boxPosition
  }

  const updateGarmentCategory = (boundingBoxId, category) => {
    console.log(category)
    if (!props.setGarment) return

    let garmentCopy = JSON.parse(JSON.stringify(props.garment))
    garmentCopy.garment_images[currentImageIndex].bounding_box_data.bounding_boxes[boundingBoxId].category_data._id = category

    if (garmentCopy.outfit_garment_ids) {
      let finalOutfit = garmentCopy.outfit_garment_ids[garmentCopy.outfit_garment_ids.length-1]
      if (finalOutfit && boundingBoxId < finalOutfit.garment_ids.length) {
        finalOutfit.garment_ids[boundingBoxId].similar_data.garmentType = parseInt(category)
        finalOutfit.garment_ids[boundingBoxId].similar_data.subcategories = []
        garmentCopy.outfit_garment_ids[0] = finalOutfit
      }
    }
    props.setGarment(garmentCopy)
    // console.log(garmentCopy)
    // console.log(garmentCopy.garment_images[currentImageIndex])
  }

  const getBoxesToRender = () => {
    // Get boxes existing in the garment object for the relevant image to render
    let boxesToRender = []

    if (props.garment) {
      boxesToRender = JSON.parse(JSON.stringify(props.garment
        .garment_images[currentImageIndex]
        .bounding_box_data
        .bounding_boxes)
      ).map(boundingBox => {
        boundingBox.position = boxRelativeToAbsolute(boundingBox.position)
        return boundingBox
      })
    }

    // Get box that is currently being drawn to render if required
    if (isDrawing) {
      boxesToRender.push({
        label: 0,
        position: getCurrentBox(),
        category_data: {
          name: props.categories[1].name,
          _id: 1,
          approved: 1,
          model_confidence: 1.
        }
      })
    }
    return boxesToRender
  }

  const isRectangleTooSmall = (position) => {
    return position.width < minBoxSize || position.height < minBoxSize
  }

  const calculateRectPosition = (imgProps, rawBoxCoords) => {
    let left = Math.min(
      rawBoxCoords.startX,
      rawBoxCoords.currX
    )
    let top = Math.min(
      rawBoxCoords.startY,
      rawBoxCoords.currY
    )
    let right = Math.max(
      rawBoxCoords.startX,
      rawBoxCoords.currX
    )
    let bottom = Math.max(
      rawBoxCoords.startY,
      rawBoxCoords.currY
    )

    // width of div border
    const DIV_BORDER = 0
    const width = imgProps.width - DIV_BORDER
    const height = imgProps.height - DIV_BORDER

    // limit rectangles to the size of the image
    // so user can't draw rectangle that spill out of image
    left = Math.max(imgProps.offsetX, left)
    top = Math.max(imgProps.offsetY, top)
    right = Math.min(width + imgProps.offsetX, right)
    bottom = Math.min(height + imgProps.offsetY, bottom)

    return {
      left: left - imgProps.offsetX,
      top: top - imgProps.offsetY,
      width: right - left,
      height: bottom - top
    }
  }

  const createRectangle = (event) => {
    console.log('start drawing')
    setIsDrawing(true)
    setStartX(event.pageX)
    setStartY(event.pageY)
    setCurrX(event.pageX)
    setCurrY(event.pageY)
  }

  const editBox = (event, editMode, boxIndex) => {
    setEditingBox(true)
    setEditMode(editMode)
    setCurrentBoxIndex(boxIndex)
  }

  const mouseDownHandler = (event) => {
    // only start drawing if the mouse was pressed
    // down inside the image that we want labelled
    if (
      !event.target.id.includes('boundingBoxImage') &&
          !event.target.id.includes('LabelViewImg') &&
          !event.target.id.includes('boundingBox') &&
          !event.target.id.includes('crosshair')
    ) 

      if (createMode && props.garment) {
        event.persist()
        createRectangle(event)
      }
  }

  const mouseMoveHandler = (event) => {
    event.persist()
    updateCursorPosition(event)
  }

  const mouseUpHandler = (event) => {
    setIsDrawing(false)

    // Get box in correct format from mouse coordinates
    let boundingBoxPosition = getCurrentBox()

    // Update state with box and show label dropdown
    // Do deep copy of garment to prevent mutating state when adding box
    let garmentCopy = JSON.parse(JSON.stringify(props.garment))
    let newBoxIndex = null
    if (isDrawing && !isRectangleTooSmall(boundingBoxPosition)) {
      let boundingBox = {
        label: 0,
        position: boxAbsoluteToRelative(boundingBoxPosition),
        category_data: {
          name: props.categories[1].name,
          _id: 1,
          approved: 1,
          model_confidence: 1.
        }
      }

      garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .bounding_boxes
        .push(boundingBox)

      newBoxIndex = garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .bounding_boxes
        .length - 1
      
      setCreateMode(false)
      console.log({newBoxIndex})
      props.setActiveBoundingBox(newBoxIndex)

      // Set bounding boxes back to unapproved so that interface doesn't indicate that it has saved when it hasnt
      garmentCopy
        .garment_images[currentImageIndex]
        .bounding_box_data
        .approved = 1
    }
    props.setGarment(garmentCopy)
    setCurrentBoxIndex(newBoxIndex)
    setStartX(null)
    setStartY(null)

    // props.updateHistory(garmentCopy)
  }

  const editMouseDownHandler = (event, editMode, boxIndex) => {
    if (props.garment) {
      console.log({event, editMode, boxIndex})
      event.persist()
      editBox(event, editMode, boxIndex)
    }
  }

  const editMouseUpHandler = () => {
    setEditingBox(false)
    setEditMode(null)
    setCurrentBoxIndex(null)
  }

  const computeEditedBoxTopPosition = (boxPosition, currY) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))

    let imageBottom = imageProps.offsetY

    let newPositionTop = currY - imageBottom
    let boxBottom = boxPosition.top + boxPosition.height

    // Make sure top of box isn't off the top of the image
    newPositionTop = Math.max(0, newPositionTop)

    // Make sure the top of the box isn't closer than minBoxSize above the bottom of the box
    newPositionTop = Math.min(newPositionTop, boxBottom - minBoxSize)


    boxPosition.height = boxBottom - newPositionTop
    boxPosition.top = newPositionTop
    return boxPosition
  }

  const computeEditedBoxRightPosition = (boxPosition, currX) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))

    let imageRight = imageProps.offsetX
    let imageLeft = imageProps.offsetX + imageProps.width

    let newPositionRight = currX - imageRight

    // Make sure bottom of box isn't off the right of the image
    newPositionRight = Math.min(imageLeft, newPositionRight)

    // Make sure the right of the box isn't closer than minBoxSize right of the left of the box
    newPositionRight = Math.max(newPositionRight, boxPosition.left + minBoxSize)

    boxPosition.width = newPositionRight - boxPosition.left
    return boxPosition
  }

  const computeEditedBoxBottomPosition = (boxPosition, currY) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))

    let imageBottom = imageProps.offsetY
    let imageTop = imageProps.offsetY + imageProps.height

    let newPositionBottom = currY - imageBottom

    // Make sure bottom of box isn't off the bottom of the image
    newPositionBottom = Math.min(imageTop, newPositionBottom)

    // Make sure the bottom of the box isn't closer than minBoxSize below the top of the box
    newPositionBottom = Math.max(newPositionBottom, boxPosition.top + minBoxSize)

    boxPosition.height = newPositionBottom - boxPosition.top
    return boxPosition
  }

  const computeEditedBoxLeftPosition = (boxPosition, currX) => {
    boxPosition = JSON.parse(JSON.stringify(boxPosition))

    let imageLeft = imageProps.offsetX

    let newPositionLeft = currX - imageLeft
    let boxRight = boxPosition.left + boxPosition.width

    // Make sure top of box isn't off the top of the image
    newPositionLeft = Math.max(0, newPositionLeft)

    // Make sure top of box isn't off the bottom of the image
    newPositionLeft = Math.min(imageProps.width, newPositionLeft)

    // Make sure the top of the box isn't closer than minBoxSize above the bottom of the box
    newPositionLeft = Math.min(newPositionLeft, boxRight - minBoxSize)

    boxPosition.width = boxRight - newPositionLeft
    boxPosition.left = newPositionLeft
    return boxPosition
  }

  const computeEditedBoxPosition = (boxPosition, currX, currY) => {
    switch (editMode) {
    case 'top':
      boxPosition = computeEditedBoxTopPosition(boxPosition, currY)
      break
    case 'topRight':
      boxPosition = computeEditedBoxTopPosition(boxPosition, currY)
      boxPosition = computeEditedBoxRightPosition(boxPosition, currX)
      break
    case 'right':
      boxPosition = computeEditedBoxRightPosition(boxPosition, currX)
      break
    case 'bottomRight':
      boxPosition = computeEditedBoxBottomPosition(boxPosition, currY)
      boxPosition = computeEditedBoxRightPosition(boxPosition, currX)
      break
    case 'bottom':
      boxPosition = computeEditedBoxBottomPosition(boxPosition, currY)
      break
    case 'bottomLeft':
      boxPosition = computeEditedBoxBottomPosition(boxPosition, currY)
      boxPosition = computeEditedBoxLeftPosition(boxPosition, currX)
      break
    case 'left':
      boxPosition = computeEditedBoxLeftPosition(boxPosition, currX)
      break
    case 'topLeft':
      boxPosition = computeEditedBoxTopPosition(boxPosition, currY)
      boxPosition = computeEditedBoxLeftPosition(boxPosition, currX)
      break
    default:
      console.error('Unknown editMode: ', editMode)
      break
    }
    return boxPosition
  }

  const getBoxUpdate = (currX, currY) => {
    let garmentCopy = JSON.parse(JSON.stringify(props.garment))
    let boxPosition = garmentCopy
      .garment_images[currentImageIndex]
      .bounding_box_data
      .bounding_boxes[currentBoxIndex]
      .position

    // Convert box position to absolute so it is easier to work with
    let boxPositionAbsolute = boxRelativeToAbsolute(boxPosition)

    // Update box position
    boxPositionAbsolute = computeEditedBoxPosition(boxPositionAbsolute, currX, currY)

    // Convert box position back to relative and save to garment
    boxPosition = boxAbsoluteToRelative(boxPositionAbsolute)
    garmentCopy
      .garment_images[currentImageIndex]
      .bounding_box_data
      .bounding_boxes[currentBoxIndex]
      .position = boxPosition

    // Set bounding boxes back to unapproved so that interface doesn't indicate that it has saved when it hasnt
    garmentCopy
      .garment_images[currentImageIndex]
      .bounding_box_data
      .approved = 1

    return garmentCopy
  }

  const updateCursorPosition = (event) => {
    setCurrX(event.pageX)
    setCurrY(event.pageY)

    // If we are editing a box we should also update the values for this box
    if (editingBox && currentBoxIndex !== null) {
      props.setGarment(getBoxUpdate(event.pageX, event.pageY))
    }
  }

  const updateCurrentBoxIndex = (newBoxIndex) => {
    setCurrentBoxIndex(newBoxIndex)
  }

  const updateImageProps = (height, width, offsetX, offsetY) => {
    setImageProps({
      height,
      width,
      offsetX,
      offsetY
    })
  }

  const retrieveImage = async (action) => {
    if (action === 'prev' || action === 'next') {
      let imageIndex = action === 'next' ?
        currentImageIndex + 1 :
        Math.max(currentImageIndex - 1, 0)

      if (imageIndex > props.garment.garment_images.length - 1) {
        // Get new garment if all current garment images bounding boxes are approved
        let allImagesApproved = props.garment.garment_images.every(image => (
          image.bounding_box_data.approved === approvalLevel
        ))
        if (allImagesApproved) {
          await props.getGarment()
        }
      } else {
        // Otherwise go to next image of same garment
        setCurrentImageIndex(imageIndex)
        // props.updateHistory()
      }
    }
  }

  const handleKeyDown = useCallback((event) => {
    if (currentBoxIndex !== null
          && labelKeyCodes.includes(event.keyCode)
          && props.boundingBoxLocations.length > 0) {
      let boxLabel = getBoxLabelForKeyIndex(labelKeyCodes.indexOf(event.keyCode))
      if (boxLabel !== null) {
        onBoxLocationChange(currentBoxIndex, boxLabel)
      }
    } else {
      switch (event.keyCode) {
      case 81:
        // Q - set label page to 0
        setLabelPage(0)
        break
      case 87:
        // W - set label page to 1
        setLabelPage(1)
        break
      case 69:
        // E - set label page to 2
        setLabelPage(2)
        break
      case 82:
        // R set label page to 3
        setLabelPage(3)
        break
      case 83:
        // S - Delete box
        deleteBox(currentBoxIndex)
        break
      case 84:
        // T set label page to 4
        setLabelPage(4)
        break
      case 89:
        // Y set label page to 5
        setLabelPage(5)
        break
      case 90:
        // Z - undo
        // props.undo()
        break
      case 88:
        // X - redo
        // props.redo()
        break
      case 65:
        // A - Previous image
        retrieveImage('prev')
        break
      case 68:
        // D - Next image
        retrieveImage('next')
        break
      case 32:
        // Space - Save boxes for current garment
        // props.saveBoxes()
        break
      default:
        break
      }
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.keyboardShortcuts) {
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
    // TODO - sort out dependencies. I think ideally should sort out dependencies of handleKeyDown callback and then
    //  have handleKeyDown as the only dependency here. Or define handleKeyDown within this function then pass
    //  dependencies for that here
  }, [props.garment, currentBoxIndex, currentImageIndex, labelPage, handleKeyDown, props.keyboardShortcuts])

  const checkApproved = () => {
    return 1
  }

  let boxesToRender = getBoxesToRender()

  let imageUrl = `${props.imageBaseURL}${props.garment.garment_images[currentImageIndex].server_image_filename.split('/').slice(-1)[0]}`

  return (
    <div className={classes.boundingBoxImageContainer}>
      <div
        id={`boundingBoxImage-${Utils.makeid(16)}`}
        className={classes.boundingBoxImage}
        onMouseMove={mouseMoveHandler}
        onMouseDown={mouseDownHandler}
        onMouseUp={mouseUpHandler}
      >

        <img alt="Edit bounding boxes" onClick={() => setCreateMode(p => !p)} style={{filter: createMode ? 'invert(100%)': undefined}} className={classes.cropButton} src={cropbuttonimg}/>

        {
          boxesToRender &&
            boxesToRender.map((box, index) => (
              <BoundingBox
                key={index}
                index={index}
                active={isDrawing ? index === boxesToRender.length-1 : index === props.activeBoundingBox}
                onClick={() => { props.setActiveBoundingBox(index)} }
                isDrawing={isDrawing}
                editingBox={editingBox}
                box={box}
                onBoxLocationChange={(boxLocation) => onBoxLocationChange(index, boxLocation)}
                updateCurrentBoxIndex={updateCurrentBoxIndex}
                onEditMouseDown={editMouseDownHandler}
                onEditMouseUp={editMouseUpHandler}
              />
            ))
        }
        <Image {...imageProps}
          setImageProps={updateImageProps}
          url={imageUrl}
          approved={checkApproved()}
        />

      </div>
      <GarmentCategorySelector
        categories={props.categories}
        selectedCategory={boxesToRender.length && boxesToRender[props.activeBoundingBox].category_data._id}
        setSelectedCategory={(categoryId => updateGarmentCategory(props.activeBoundingBox, categoryId))}
        onConfirm={() => {props.onConfirm && props.onConfirm()}}
      />
    </div>
  )
}
