// NB: Modal for adding a new garment to an outfit

import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { Spinner } from 'react-bootstrap'
import './GarmentNavigator.css'
import { withStyles } from '@material-ui/core/styles'
import SortByDropdown from '../SortByDropdown/SortByDropdown'
import PaginationFull from '../PaginationFullV2/PaginationFull'
import AutocompleteDropdown from './AutocompleteDropdown'
import GarmentGrid from './GarmentGrid'
import ButtonSmall from '../ButtonSmall/ButtonSmall'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import ItemsPerPage from '../ItemsPerPage/ItemsPerPage'
import colorFilterIcon from '../../assets/img/colorWheel.png'
import OutfitPreview from './OutfitPreview'
import api from '../../api/api'
import update from 'immutability-helper'
import mappedColours from '../../utils/grouped_colours'

const currencySymbols = require('../../assets/currencySymbols')


const colours = {
  red: '#E60000',
  yellow: 'yellow',
  blue: 'blue',
  navy: 'navy',
  green: 'green',
  khaki: '#446600',
  orange: 'orange',
  purple: 'purple',
  white: 'white',
  beige: '#DFBF9F',
  black: 'black',
  tan: '#AC7339',
  brown: '#663300',
  grey: 'grey',
  pink: 'pink',
  gold: 'gold',
  silver: 'silver'
}

const style = theme => ({
  garmentNavigatorControl: {
    display: 'grid',
    maxWidth: '98%',
    '@media only screen and (min-width: 700px)': {
      gridTemplateColumns: '240px auto 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    },
    '@media only screen and (min-width: 1365px)': {
      gridTemplateColumns: '290px 389px 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    },
    '@media only screen and (min-width: 1439px)': {
      gridTemplateColumns: '290px 389px 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    }
  },
  primaryImageContainer: {
    gridRowStart: '1',
    gridRowEnd: '4',
  },
  primaryImage: {
    paddingTop: '24px',
    width: '150px',
    display: 'block',
    margin: 'auto auto'
  },
  categoryContainer: {
    display: 'inline-block',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    height: '49px'
  },
  brandContainer: {
    marginLeft: '22px',
    display: 'inline-block',
    height: '49px',
    width: '249px'
  },
  subcategoryContainer: {
    display: 'inline-block',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    height: '49px'
  },
  priceRangeContainer: {
    display: 'block',
    marginLeft: '22px',
    '& p': {
      display: 'inline-block',
      height: '24px',
      width: '170px',
      opacity: '0.5',
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '24px',
      marginBottom: '4.5px',
      marginTop: '24px'
    }
  },
  priceButtonsContainer: {
    display: 'grid',
    gridTemplateColumns: '120px 10px 120px'
  },
  priceRangeSelectMin: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '49px',
    width: '120px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '15px 0 7px'
    }
  },
  priceRangeSelectMax: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '49px',
    width: '120px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '15px 0 7px'
    }
  },
  search: {
    marginTop: '28.5px',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    height: '49px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      width: '90%'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#86A8E0'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    height: '24px',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    padding: '10px 8px 8px 56px',
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: '28.5px',
    marginLeft: 'auto',
    gridColumnStart: '3',
    gridColumnEnd: '5'
  },
  resetButton: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '48px',
    width: '250px',
    margin: '0 30px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      marginTop: '14px',
      margin: 'auto',
      height: '19px',
      width: '100%',
      color: '#86A8E0',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '& i': {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '5px',
        '-webkit-text-stroke': '2px white',
        fontSize: '20px'
      }
    }
  },
  resetButtonDisabled: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '48px',
    width: '250px',
    margin: '0 30px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      marginTop: '14px',
      margin: 'auto',
      height: '19px',
      width: '100%',
      color: '#999999',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '& i': {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '5px',
        '-webkit-text-stroke': '2px white',
        fontSize: '20px'
      }
    }
  },
  searchButton: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      margin: 'auto'
    }
  },
  searchButtonDisabled: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #a0a0a0',
    borderRadius: '5px',
    backgroundColor: '#a0a0a0',
    pointerEvents: 'none',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      margin: 'auto'
    }
  },
  controlUsed: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '113px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px'
      // marginLeft: '24px'
    }
  },
  controlSimilar: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '128px',
    minWidth: '128px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      whiteSpace: 'nowrap'
      // marginLeft: '24px'
    }
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  buttonHoverCircular: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      borderRadius: '100%'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  alternativeGarmentsContainer: {
    margin: '0 10px 0 20px',
    // height: '1200px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  loadingSpinner: {
    width: '300px',
    height: '300px',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 20px 10px',
    padding: '15px 10px',
    borderTop: '1px solid #e2e6ed',
    boxShadow:
      '0 4px 8px -8px rgba(0, 0, 0, 0.2), 0 10px 22px -15px rgba(0, 0, 0, 0.19)',
    zIndex: 9
  },
  shortcutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 20px',
    padding: '5px 10px',
    borderTop: '1px solid #e2e6ed',
    zIndex: 9
  },
  sortBy: {
    justifyContent: 'flex-end'
  },
  pagination: {
    justifyContent: 'flex-end'
  },
  garmentNavigatorContainer: {
    marginBottom: '5px',
    height: '370px',
    minHeight: '300px'
  },
  noGarmentsAvailable: {
    margin: 'auto',
    marginTop: '60px',
    '& p': {
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '2px',
      lineHeight: '19px',
      textAlign: 'center',
      color: '#4C4C51'
    }
  },
  helperText: {
    marginTop: '5px !important',
    marginLeft: '-7px',
    width: '270px !important',
    fontSize: '10px !important'
  },
  settingsButton: {
    cursor: 'pointer',
    height: 'auto',
    width: 'auto',
    margin: 'auto 5px'
  },
  colorFilterDiv: {
    display: 'flex',
    cursor: 'pointer'
  },
  colorFilterIcon : {
    width: '30px',
    marginLeft: '10px',
    marginTop: '38px',
    marginBottom: 'auto'
  },
  colorFilterContainer: {
    height: '48px',
    display: 'flex',
    backgroundColor: 'white',
    '& p': {
      fontFamily: 'Montserrat',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '100px',
      marginLeft: '48px'
    },
    transition: 'visibility 0.3s, opacity 0.3s linear',
    '@media only screen and (max-width: 1160px)': {
      height: props => props.colorFilterShow ? '70px' : '48px',
    }
  },
  colorFilterOptionsContainer: {
    display: 'flex',
    margin:'auto',
    flexWrap: 'wrap'
  },
  colorFilterOptions: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
    margin: 'auto 5px',
    border: '1px solid grey'
  },
  colourFilterClear: {
    margin: 'auto 0 auto 10px',
    color: '#86A8E0',
    '-webkit-text-stroke': '2px white',
    fontSize: '20px',
    cursor: 'pointer'
  }
})


class GarmentNavigator extends Component {
  constructor (props, context) {
    super(props, context)
    this.textInput = React.createRef()

    const user = JSON.parse(localStorage.getItem('user'))
    const outfitEditorSettings = user.feSettings.outfitEditorSettings

    this.state = {
      outfitEditorSettings,
      garments: [],
      garmentsPerRow: 4,
      page: 1,
      totalPages: 1,
      itemsPerPage: 15,
      itemsPerPageDefault: 15,
      itemsPerPageMax: 100,
      sortByEdit: 'visual similarity',
      sortByAdd: 'stock descending',
      sortBy: 'stock descending',
      sortByOptions: [
        'price ascending',
        'price descending',
        'stock ascending',
        'stock descending',
        'discount ascending',
        'discount descending'
      ],
      colours: mappedColours,
      searchColours: [],
      colourFilterShow: false,
      priceValues: {
        min: 0,
        max: 100000
      },
      priceRange: {
        min: 0,
        max: 100000
      },
      hideUsedProducts: true,
      searchText: '',
      cachedVisualSimilarity: false,
      loading: false,
      category: {name: '', _id: ''},
      subcategory: {name: '', _id: ''},
      productCategory: {name: '', _id: ''},
      brandGroup: {name: '', _id: ''}
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  setPage = async (page) => {
    await this.setState({
      page
    })
    await this.search(true)
  }

  handleSelectGarment = async (garment) => {
    await this.props.onSelectGarmentClick(garment)
    await this.onHide()
  }

  handleSortByChange = async (option) => {
    // Update sort by option
    let newState = {
      sortBy: option
    }

    if (this.props.activeGarment) {
      newState.sortByEdit = option
    } else {
      newState.sortByAdd = option
    }

    await this.setState(newState)

    // Automatically search for new garments
    await this.search()
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    if (window.innerWidth > 1365) {
      this.setState({ garmentsPerRow: 4 })
    } else {
      this.setState({ garmentsPerRow: 3 })
    }
  }

  getOutfitMarketId () {
    let marketId
    if (![null, undefined].includes(this.props.activeOutfit.market_id) && this.props.activeOutfit.market_id.length > 0) {
      marketId = this.props.activeOutfit.market_id[0]
    }
    return marketId
  }

  getBrandRestrictionForGroups = () => {
    if (this.state.brandGroup._id) {
      return this.state.brandGroup.brands
    } else {
      return null
    }
  }

  search = async (keepPage) => {
    let newState = {
      loading: true
    }

    if (!keepPage) {
      newState.page = 1
    }

    await this.setState(newState)

    let garments
    let totalPages = 1

    if (this.props.activeGarment && this.state.sortBy === 'visual similarity') {
      garments = await this.getAlternativeVisuallySimilarGarments()
    } else if (this.props.activeGarment && this.state.sortBy === 'replacement order') {
      garments = await this.getAlternativeReplacementOrderGarments()
    } else {
      const queryObject = this.getAlternativeGarmentsQuery()

      const response = await api.listGarmentsV3( queryObject )
      totalPages = Math.ceil(response.metadata.total / this.state.itemsPerPage)

      garments = response.garments
    }

    await this.setState({
      garments,
      totalPages,
      loading: false
    })
  }

  getAlternativeGarmentsQuery = () => {
    // Create base query with required parameters
    let queryObject = {
      gender: this.props.activeOutfit.gender,
      user_ids_restriction: this.props.activeOutfit._user,
      in_stock: true,
      approved: true,
      price_restriction: this.state.priceValues
    }

    if (this.props.activeOutfit.garments[0].age_ranges) {
      queryObject.age_ranges = this.props.activeOutfit.garments[0].age_ranges
    }

    // Add subcategory restriction if necessary
    if (this.state.category._id) {
      queryObject.category_ids_restriction = [this.state.category._id]
    }

    if (this.state.subcategory._id) {
      queryObject.subcategory_ids_restriction = [this.state.subcategory._id]
    }

    // Add colour restriction if necessary
    if (this.state.searchColours.length > 0) {
      queryObject.colour_restriction = this.state.searchColours
    }

    // Add search query if necessary
    let colourSearch = this.state.searchColours.length > 0 ? ' ' + this.state.searchColours.join(' ') : ''
    if (this.state.searchColours.length > 0) {
      queryObject.search_query = this.state.searchColours.join(' ')
    }
    if (this.state.searchText !== '') {
      queryObject.search_query = this.state.searchText + colourSearch
    }

    // Add brand restriction
    let brandRestriction = this.getBrandRestrictionForGroups()
    if (brandRestriction) {
      queryObject.brand_restriction = brandRestriction
    }

    // Add product category restriction
    if (this.state.productCategory.name) {
      queryObject.remote_product_categories = this.state.productCategory.name
    }

    // Add market id restriction based on market id of outfit (not value in filters)
    if (![null, undefined].includes(this.props.activeOutfit.market_id) && this.props.activeOutfit.market_id.length > 0) {
      queryObject.market_id = this.props.activeOutfit.market_id
    }

    // Add additional fields if not searching by similarity
    if (!['visual similarity', 'replacement order'].includes(this.state.sortBy)) {
      queryObject = {
        garment_restrictions: queryObject,
        offset: this.state.itemsPerPage * (this.state.page - 1),
        limit: this.state.itemsPerPage
      }

      switch (this.state.sortBy) {
      case 'price descending':
        queryObject.sort_by = {
          price: -1,
          _id: 1
        }
        break
      case 'price ascending':
        queryObject.sort_by = {
          price: 1,
          _id: 1
        }
        break
      case 'stock descending':
        queryObject.sort_by = {
          stock_level: -1,
          _id: 1
        }
        break
      case 'stock ascending':
        queryObject.sort_by = {
          stock_level: 1,
          _id: 1
        }
        break
      case 'discount ascending':
        queryObject.sort_by = {
          discount: 1,
          _id: 1
        }
        break
      case 'discount descending':
        queryObject.sort_by = {
          discount: -1,
          _id: 1
        }
        break
      default:
        console.error('Invalid sortBy: ', this.state.sortBy)
        break
      }

      queryObject.url = this.props.url
    }

    return queryObject
  }

  getAlternativeVisuallySimilarGarments = async () => {
    let garments = []
    let query_id = this.props.activeGarment._id
    const queryObject = this.getAlternativeGarmentsQuery()
    let body = {
      url: this.props.url,
      query_id: query_id,
      n_similars_per_query: this.state.itemsPerPage,
      similar_restrictions: queryObject,
      cached: this.state.cachedVisualSimilarity,
      old: false,
      ignore_favourite_users: true,
      restrict_by_category: false,
      restrict_by_subcategory: false,
      remove_keys: null
    }

    let response = await api.getSimilars(body)
    if (response.data.similars && response.data.similars.length > 0) {
      garments = response.data.similars[0].garments
    }
    return garments
  }

  getAlternativeReplacementOrderGarments = async () => {
    // TODO - may not be working, but we aren't going to support this initially anyway
    // Load garments from edited garment
    let garments = []
    let replacementProductIds = []
    let editedGarment = this.state.outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
    if (editedGarment.similar_data !== undefined && editedGarment.similar_data !== null) {
      if (editedGarment.similar_data.similars !== undefined && editedGarment.similar_data.similars !== null) {
        replacementProductIds = editedGarment.similar_data.similars.garment_ids
      }
    }
    if (replacementProductIds.length > 0) {
      let body = {
        url: this.props.url,
        garment_restrictions: {
          remote_product_id: replacementProductIds,
          user_ids_restriction: this.getUserIdsRestriction()
        },
        limit: 1000000
      }

      let response = await api.listGarmentsV3(body)
      if (response.garments) {
        let replacementGarments = {}
        response.garments.forEach(garment => {
          replacementGarments[garment.remote_product_id] = garment
        })
        garments = replacementProductIds
          .filter(productId => replacementGarments[productId] !== undefined)
          .map(productId => replacementGarments[productId])
      }
    }

    return garments
  }

  onItemsPerPageChange = async (event) => {
    event.preventDefault()
    let itemsPerPage = this.state.itemsPerPageDefault
    if (event.target.value !== '') {
      itemsPerPage = parseInt(event.target.value)
    }
    if (!isNaN(itemsPerPage) && itemsPerPage !== 0) {
      itemsPerPage = Math.min(itemsPerPage, this.state.itemsPerPageMax)

      if (itemsPerPage !== this.state.itemsPerPage) {
        await this.setState({
          itemsPerPage
        })
        await this.search()
      }
    }
  }
  
  handleShortcut1Click = async () => {
    await this.clearFilters()

    // Hoop Earrings
    await this.setState({
      category: this.props.categories.filter(category => category._id === 46)[0],  // Earrings category
      searchText: 'hoop'
    })

    await this.search()
  }
  
  handleSearchShortcutClick = async (searchTerm) => {
    await this.setState({
      subcategory: {name: '', _id: ''},  // Clear subcategory filter - not sure exactly why this was added, likely because otherwise we are unlikely to find results. But could potentially be removed
      searchText: searchTerm
    })

    await this.search()
  }
  
  handleClearSearchShortcutClick = async (searchTerm) => {
    await this.clearFilters()

    await this.setState({
      searchText: searchTerm
    })

    await this.search()
  }

  handleProductCategoryDropdownChange = async (e, value) => {
    if (value) {
      await this.setState({
        productCategory: value
      })
    } else {
      await this.setState({
        productCategory: {name: '', _id: ''}
      })
    }
  }

  colourFilterOpen = () => {
    this.setState(prevState => ({colourFilterShow: !prevState.colourFilterShow}))
  }

  colourFilterClear = () => {
    this.setState({ searchColours: [] })
  }

  selectColour = async (colour) => {
    let searchColours = JSON.parse(JSON.stringify(this.state.searchColours))
    if (searchColours.includes(colour)) {
      searchColours = searchColours.filter(e => e !== colour)
    } else {
      searchColours.push(colour)
    }
    await this.setState({ searchColours })

    await this.search()
  }

  handleAddGarmentToReplacementOrderClick = (garment) => {
    // TODO - not supporting replacememnt order at the moment since not relevant for outfit collection outfits
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let editedGarment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (editedGarment.similar_data === null || editedGarment.similar_data === undefined) {
      editedGarment.similar_data = {}
    }

    if (editedGarment.similar_data.similars === null || editedGarment.similar_data.similars === undefined) {
      editedGarment.similar_data.similars = {
        garment_ids: [],
        edited: false
      }
    }

    if (!editedGarment.similar_data.similars.garment_ids.includes(garment.remote_product_id)) {
      editedGarment.similar_data.similars.garment_ids.push(garment.remote_product_id)
      editedGarment.similar_data.similars.edited = true
      outfits[garmentIndex[0]][garmentIndex[1]] = editedGarment
      this.setState({
        outfits
      })
    }
  }

  handleRemoveGarmentFromReplacementOrderClick = (garment) => {
    // TODO
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let editedGarment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (editedGarment.similar_data === null || editedGarment.similar_data === undefined) {
      editedGarment.similar_data = {}
    }

    if (editedGarment.similar_data.similars === null || editedGarment.similar_data.similars === undefined) {
      editedGarment.similar_data.similars = {
        garment_ids: [],
        edited: false
      }
    }

    editedGarment.similar_data.similars.garment_ids = editedGarment.similar_data.similars.garment_ids.filter(productId => productId !== garment.remote_product_id)
    editedGarment.similar_data.similars.edited = true
    outfits[garmentIndex[0]][garmentIndex[1]] = editedGarment

    // Also remove from alternativeGarments to prevent having to re-retrieve replacement garments. This is valid because
    // it will only be possible to remove a garment from the replacement order if the navigator is already displaying
    // the replacement order - i.e. this.state.alternativeGarments contains the replacement garments
    let alternativeGarments = JSON.parse(JSON.stringify(this.state.alternativeGarments))
    alternativeGarments = alternativeGarments.filter(g => g._id !== garment._id)

    this.setState({
      outfits,
      alternativeGarments
    })
  }

  updateReplacementOrder = (replacementOrder) => {
    // TODO
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let garment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (garment.similar_data === null || garment.similar_data === undefined) {
      garment.similar_data = {}
    }

    if (garment.similar_data.similars === null || garment.similar_data.similars === undefined) {
      garment.similar_data.similars = {}
    }

    garment.similar_data.similars.garment_ids = replacementOrder
    garment.similar_data.similars.edited = true
    outfits[garmentIndex[0]][garmentIndex[1]] = garment
    this.setState({
      outfits
    })
  }

  findAlternativeGarment = (id) => {
    const garment = this.state.garments.filter(g => `${g._id}` === id)[0]
    return {
      garment,
      index: this.state.garments.indexOf(garment)
    }
  }

  moveGarment = (id, atIndex) => {
    // TODO - for replacement order, not currently supported for outfit collection outfit editor
    const {garment, index} = this.findAlternativeGarment(id)
    let alternativeGarments = update(this.state.alternativeGarments, {
      $splice: [
        [index, 1],
        [atIndex, 0, garment],
      ],
    })

    this.setState({
      alternativeGarments
    })

    this.updateReplacementOrder(alternativeGarments.map(garment => garment.remote_product_id))
  }

  clearFilters = async () => {
    await this.setState(prevState => {
      return {
        category: {name: '', _id: ''},
        subcategory: {name: '', _id: ''},
        productCategory: {name: '', _id: ''},
        brandGroup: {name: '', _id: ''},
        searchColours: [],
        searchText: '',
        priceValues: prevState.priceRange
      }
    })
  }

  handleResetFiltersClick = async () => {
    await this.clearFilters()
    await this.search()
  }

  handleTextSearchChange (event) {
    this.setState({
      searchText: event.target.value
    })
  }

  handleMaxPriceChange = async (event) => {
    event.preventDefault()
    if (event.target.value === '') {
      const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
      priceValues.max = ''
      await this.setState({
        priceValues
      })
    } else {
      const maxPrice = parseInt(event.target.value)
      if (!isNaN(maxPrice)) {
        const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
        priceValues.max = maxPrice

        await this.setState({
          priceValues
        })
      }
    }
  }

  handleMinPriceChange = async (event) => {
    event.preventDefault()
    if (event.target.value === '') {
      const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
      priceValues.min = ''
      await this.setState({
        priceValues
      })
    } else {
      const minPrice = parseInt(event.target.value)

      if (!isNaN(minPrice)) {
        const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
        priceValues.min = minPrice
        await this.setState({
          priceValues
        })
      }
    }
  }

  hideUsedProductsOnClick = async () => {
    // TODO - not currently supported for outfits from outfits collection
    await this.setState(prevState => ({hideUsedProducts: !prevState.hideUsedProducts}))

    await this.search()
  }

  handleBrandGroupDropdownChange = async (e, value) => {
    if (value === null) {
      await this.setState({brandGroup: { name: '', _id: '' }})
    } else {
      await this.setState({brandGroup: value})
    }
  }

  handleCategoryDropdownChange = async (e, value) => {
    if (value === null) {
      await this.setState({category: { name: '', _id: '' }})
    } else {
      await this.setState({category: value})
    }
  }

  handleSubcategoryDropdownChange = async (e, value) => {
    if (value === null) {
      await this.setState({subcategory: { name: '', _id: '' }})
    } else {
      await this.setState({subcategory: value})
    }
  }

  initSortBy = async () => {
    let sortBy
    let sortByOptions
    if (this.props.activeGarment) {
      sortBy = this.state.sortByEdit
      if (!sortBy) {
        sortBy = 'visual similarity'
      }
      sortByOptions = [
        'visual similarity',
        'price ascending',
        'price descending',
        'stock ascending',
        'stock descending',
        'discount ascending',
        'discount descending',
      ]

      /* TODO - replacement order not supported for now for outfit collection outfits as we can't do stl outfits that way anyway
      if (this.props.activeOutfit.outfit_type === 'shop_the_look') {
        sortByOptions.push('replacement order')
      }
       */
    } else {
      sortBy = this.state.sortByAdd
      if (!sortBy) {
        sortBy = 'stock descending'
      }
      sortByOptions = [
        'price ascending',
        'price descending',
        'stock ascending',
        'stock descending',
        'discount ascending',
        'discount descending',
      ]
    }

    await this.setState({
      sortBy,
      sortByOptions
    })
  }

  initBrandGroup = async () => {
    if (this.props.activeOutfit.brand_group) {
      let brandGroups = this.props.brandGroups
        .filter(brandGroup => brandGroup.gender === this.props.activeOutfit.gender)
        .filter(brandGroup => brandGroup._id === this.props.activeOutfit.brand_group)
      if (brandGroups.length > 0) {
        await this.setState({
          brandGroup: brandGroups[0]
        })
      }
    }

  }

  initCategory = async () => {
    // Initialise the values of the category and subcategory dropdowns based on the garment and the settings
    let category = {name: '', _id: ''}
    let subcategory = {name: '', _id: ''}
    // TODO - if (this.state.outfitEditorSettings.searchPreselectCategory && this.props.activeGarment) {
    if (this.props.activeGarment) {
      // If we are editing a garment, set the category to the same as that
      category = this.props.categories.filter(c => c._id === this.props.activeGarment.garmentType)[0]
      if (this.props.activeOutfit.outfit_type === 'complete_the_look') {
        // If we are editing a garment and the outfit type is ctl set the subcategory to the same as that
        subcategory = this.props.subcategories.filter(s => s._id === this.props.activeGarment.subcategories[0])[0]
      }
    }

    await this.setState({
      category,
      subcategory
    })
  }

  initProductCategory = async () => {
    // Init productCategories dropdown
    // If we are adding a garment don't preselect the product category
    // If we are editing a garment preselect the product category if required by settings
    let productCategory = {name: '', _id: ''}
    if (this.props.activeGarment && this.state.outfitEditorSettings.productCategoryDropdown && this.state.outfitEditorSettings.searchPreselectProductCategory) {
      productCategory = this.props.productCategories.filter(c => c.name === this.props.activeGarment.remote_product_categoires[0])[0]
    }
    await this.setState({
      productCategory
    })
  }

  initColour = async () => {
    // Init colour filter - searchColours
    await this.setState({
      searchColours: []
    })
  }

  initPriceRange = async () => {
    await this.setState(prevState => ({priceValues: prevState.priceRange}))
  }

  onShow = async () => {
    await this.setState({
      garments: [],
      loading: true
    })

    await this.initBrandGroup()
    await this.initCategory()
    await this.initProductCategory()
    await this.initColour()
    await this.initPriceRange()

    // TODO - init any other filters

    // Set up sortBy and sortByOptions
    await this.initSortBy()

    await this.search()
  }

  onHide = async () => {
    this.props.onClose()
  }

  render () {
    let showCachedVisualSimilarity = this.state.cachedVisualSimilarity && this.state.sortBy === 'visual similarity'
    let showShortcuts = this.state.outfitEditorSettings.enableGarmentNavigatorShortcuts && !showCachedVisualSimilarity
    const currency = this.props.activeOutfit ? currencySymbols[this.props.activeOutfit.garments[0].currency_code] : ''  // TODO - this should be based on the current outfit, or it should be removed - can't guarantee that all searched garments have same currency code anyway
    const { classes } = this.props

    return (
      <DndProvider backend={HTML5Backend}>
        {
          this.props.activeOutfit &&
          <Modal
            show={this.props.show}
            onShow={this.onShow}
            onHide={this.onHide}
            dialogClassName='garmentNavigatorAdjustment'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header className='garment-navigator-close' closeButton>
              <Modal.Title className='garment-header-title'>
                { this.props.activeGarment ? 'Select An Alternative Garment' : 'Add a new Garment' }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={classes.garmentNavigatorContainer}>
                <div className={classes.garmentNavigatorControl}>
                  <OutfitPreview
                    queryOutfit={this.props.activeOutfit}
                  />
                  <div className={classes.categoryContainer}>
                    <AutocompleteDropdown
                      title='Product Category'
                      options={this.props.categories}
                      value={this.state.category}
                      onChange={this.handleCategoryDropdownChange}
                      placeholder='Select Category'
                      disabled={showCachedVisualSimilarity}
                    />
                  </div>
                  {
                    this.state.outfitEditorSettings.productCategoryDropdown &&
                      <div className={classes.brandContainer}>
                        <AutocompleteDropdown
                          title='Remote Category'
                          options={this.props.productCategories}
                          value={this.state.productCategory}
                          onChange={this.handleProductCategoryDropdownChange}
                          placeholder='Select Category'
                          disabled={showCachedVisualSimilarity}
                        />
                      </div>
                  }
                  {
                    !this.state.outfitEditorSettings.productCategoryDropdown &&
                      <div className={classes.brandContainer}>
                        <AutocompleteDropdown
                          title='Brand Group'
                          options={this.props.brandGroups.filter(e => e.gender === this.props.activeOutfit.gender)}
                          value={this.state.brandGroup}
                          onChange={this.handleBrandGroupDropdownChange}
                          placeholder='Select Brand'
                          disabled={showCachedVisualSimilarity}
                        />
                      </div>
                  }
                  <div className={classes.subcategoryContainer}>
                    <AutocompleteDropdown
                      title='Product Subcategory'
                      options={this.props.subcategories}
                      value={this.state.subcategory}
                      onChange={this.handleSubcategoryDropdownChange}
                      placeholder='Select Subcategory'
                      disabled={showCachedVisualSimilarity}
                    />
                  </div>
                  <div className={classes.priceRangeContainer}>
                    <p>PRICE RANGE ({currency})</p>
                    <br />
                    <div className={classes.priceButtonsContainer}>
                      <TextField
                        id='prsmin'
                        type='number'
                        className={classes.priceRangeSelectMin}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          id: 'prsminInput',
                          step: 1,
                          disableUnderline: true,
                          placeholder: '0'
                        }}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.helperText
                          }
                        }}
                        value={this.state.priceValues.min}
                        helperText={this.state.priceValues.min > this.state.priceValues.max ? 'Max price must be higher than min price' : ''}
                        error={this.state.priceValues.min > this.state.priceValues.max}
                        onChange={this.handleMinPriceChange}
                        disabled={showCachedVisualSimilarity}
                      />
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '20px',
                          margin: 'auto auto',
                          fontFamily: 'Montserrat'
                        }}
                      >
                          -
                      </div>
                      <TextField
                        id='prsmax'
                        type='number'
                        className={classes.priceRangeSelectMax}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          id: 'prsmaxInput',
                          step: 1,
                          disableUnderline: true,
                          placeholder: '1000'
                        }}
                        value={this.state.priceValues.max}
                        onChange={this.handleMaxPriceChange}
                        disabled={showCachedVisualSimilarity}
                      />
                    </div>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder={this.state.searchText === '' ? 'Product search' : this.state.searchText}
                        value={this.state.searchText}
                        inputRef={this.textInput}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={event => this.handleTextSearchChange(event)}
                        disabled={showCachedVisualSimilarity}
                      />
                    </div>
                    <div className={`${classes.colorFilterDiv} ${showCachedVisualSimilarity ? 'visibility: hidden' : ''}`}
                      onClick={(event) => {
                        this.colourFilterOpen()
                        event.stopPropagation()
                      }}
                    >
                      <img className={`${classes.colorFilterIcon}  ${classes.buttonHoverCircular}`} src={colorFilterIcon} alt="color-filter-icon" />
                    </div>
                  </div>
                  <div className={classes.buttonsContainer}>
                    <div
                      className={`${showCachedVisualSimilarity ? classes.resetButtonDisabled : classes.resetButton} ${showCachedVisualSimilarity ? '' : classes.buttonHover}`}
                      onClick={showCachedVisualSimilarity ? () => {} : () => {
                        this.handleResetFiltersClick()
                        this.textInput.current.value = ''
                      }}
                    >
                      <p><i className='fa fa-times' />RESET ALL FILTERS</p>
                    </div>
                    <div
                      className={this.state.sortBy === 'replacement order' || this.state.loading ? `${classes.searchButtonDisabled}` : `${classes.searchButton} ${classes.buttonHover}`}
                      onClick={this.search}
                    >
                      <p>SEARCH</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.colorFilterContainer} style={{visibility: this.state.colourFilterShow ? 'visible' : 'hidden', opacity: this.state.colourFilterShow ? '1' : '0', display: this.state.colourFilterShow ? 'flex' : 'none'}}>
                <p> Colour Filter </p>
                <i className={`fa fa-times ${classes.colourFilterClear}`} onClick={this.colourFilterClear} />
                <div className={classes.colorFilterOptionsContainer}>
                  {Object.keys(colours).map((colour, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.colorFilterOptions}
                        style={{ backgroundColor: `${colours[colour]}`, border: this.state.searchColours.includes(colour) ? '2px solid red' : '1px solid grey'}}
                        onClick={() => this.selectColour(colour) }
                      />)
                  })}
                </div>
              </div>
              {
                showShortcuts  &&
                  <div className={classes.shortcutContainer}>
                    <ButtonSmall
                      text={'Hoops'}
                      onClick={this.handleShortcut1Click}
                    />
                    <ButtonSmall
                      text={'Turtleneck'}
                      onClick={() => this.handleSearchShortcutClick('turtleneck')}
                    />
                    <ButtonSmall
                      text={'Snake'}
                      onClick={() => this.handleSearchShortcutClick('snake')}
                    />
                    <ButtonSmall
                      text={'Animal Print'}
                      onClick={() => this.handleSearchShortcutClick('animal print')}
                    />
                    <ButtonSmall
                      text={'Leather'}
                      onClick={() => this.handleSearchShortcutClick('leather')}
                    />
                    <ButtonSmall
                      text={'Lace'}
                      onClick={() => this.handleSearchShortcutClick('lace')}
                    />
                    <ButtonSmall
                      text={'Checked'}
                      onClick={() => this.handleSearchShortcutClick('checked')}
                    />
                    <ButtonSmall
                      text={'Velvet'}
                      onClick={() => this.handleSearchShortcutClick('velvet')}
                    />
                    <ButtonSmall
                      text={'Embellished'}
                      onClick={() => this.handleSearchShortcutClick('embellished')}
                    />
                    <ButtonSmall
                      text={'Monochrome'}
                      onClick={() => this.handleSearchShortcutClick('monochrome')}
                    />
                    <ButtonSmall
                      text={'Striped'}
                      onClick={() => this.handleSearchShortcutClick('striped')}
                    />
                    <ButtonSmall
                      text={'Pearl'}
                      onClick={() => this.handleSearchShortcutClick('pearl')}
                    />
                    <ButtonSmall
                      text={'Scarf'}
                      onClick={() => this.handleClearSearchShortcutClick('scarf')}
                    />
                  </div>
              }
  
              <div className={classes.paginationContainer}>
                <ItemsPerPage
                  itemsPerPage={this.state.itemsPerPage}
                  onItemsPerPageChange={this.onItemsPerPageChange}
                />
                <SortByDropdown
                  className={classes.sortBy}
                  selected={this.state.sortBy}
                  options={this.state.sortByOptions}
                  handleChange={this.handleSortByChange}
                />
                {
                  /* TODO - need to implement this functionality for outfits from the outfits collection - also needs a rethink on how to do it in the most efficient way
                  this.props.activeOutfit.outfit_type !== 'shop_the_look' && this.state.outfitEditorSettings.enableUsedProductIds &&
                    <ShowUsedSwitch
                      checked={!this.state.hideUsedProducts}
                      handleChange={this.hideUsedProductsOnClick}
                    />
                   */
                }
                {
                  this.state.garments.length > 0 && this.state.totalPages > 1 &&
                      <PaginationFull
                        page={this.state.page}
                        totalPages={this.state.totalPages}
                        setPage={this.setPage}
                        key='pagination-top'
                      />
                }
              </div>
              <div className={classes.alternativeGarmentsContainer}>
                {
                  this.state.loading ?
                    (
                      <div className={classes.loadingSpinner}>
                        <Spinner
                          animation='border'
                          role='status'
                        >
                          <span className='sr-only'>Loading...</span>
                        </Spinner>
                      </div>
                    ) :
                    this.state.garments.length > 0 ?
                      (
                        <GarmentGrid
                          garments={this.state.garments}
                          addGarmentToOutfit={!this.props.activeGarment}
                          handleSelectGarmentClick={this.handleSelectGarment}
                          moveGarment={this.moveGarment}
                          findGarment={this.findAlternativeGarment}
                          replacementOrderDisplay={this.state.sortBy === 'replacement order'}
                          editMode={this.props.activeOutfit.outfit_type}
                          handleAddGarmentToReplacementOrderClick={this.props.handleAddGarmentToReplacementOrderClick}
                          handleRemoveGarmentFromReplacementOrderClick={this.handleRemoveGarmentFromReplacementOrderClick}
                          marketId={this.getOutfitMarketId()}
                        />
                      ) :
                      <div className={classes.noGarmentsAvailable}>
                        <p>
                          {
                            this.state.sortBy === 'replacement order' ?
                              'No Replacement Garments Found For This Item' :
                              'No Garments Found for your selections'
                          }
                        </p>
                      </div>
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <React.Fragment>
                {
                  this.state.garments.length > 0 && this.state.totalPages > 1 &&
                      <PaginationFull
                        page={this.state.page}
                        totalPages={this.state.totalPages}
                        setPage={this.setPage}
                        key='pagination-bottom'
                      />
                }
              </React.Fragment>
            </Modal.Footer>
          </Modal>
        }
      </DndProvider>
    )
  }
}

export default withStyles(style)(GarmentNavigator)
