import { useState } from 'react'

// Wrap up our token logic in an easy to use hook
export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('authToken')
    return tokenString || null
  }

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    localStorage.setItem('authToken', userToken)
    setToken(userToken)
  }

  return {
    setToken: saveToken,
    token
  }
}
