import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'


const useStyle = makeStyles({
  carouselContainer: props => ({
    display: 'block',
    width: '100%',
    position: 'relative',
    margin: '0  auto',
    height: `${props.imageHeight}px`
  }),
  carousel: {
    display: 'block',
    margin: '0 auto',
    height: '100%'
  },
  imageContainer: {
    margin: '0 auto',
    '& .iiz .iiz__btn.iiz__hint': {
      backgroundColor: 'none !important' // Example styling for iiz__btn
    }
  },
  image: props => ({
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    height: `${props.imageHeight}px`,
    '& div img': {
      height: `${props.imageHeight}px !important`
    }
  }),
  outOfStock: {
    opacity: 0.5
  },
  outOfStockTextContainer: {
    position: 'absolute',
    bottom: '0px',
    display: 'flex',
    backgroundColor: '#DAE0EA',
    width: '100%',
    height: '23px'
  },
  outOfStockText: {
    height: '16px',
    width: '100%',
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '19px',
    textAlign: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  }
})


function ImageCarouselZoom(props) {
  let imageHeight = props.imageHeight ? props.imageHeight : 300

  const styleProps = {
    tight: props.tight,
    imageHeight
  }
  const classes = useStyle(styleProps)

  let imagesStandard = props.images.map((image, index) => {
    // Extract src, with fallback in case s3_url_resized and s3_url fields aren't available
    let src = image.s3_url_resized ?
      image.s3_url_resized :
      image.s3_url

    // Extract zoomSrc, with fallback in case s3_url field isn't available
    let zoomSrc = image.s3_url

    return {
      src,
      zoomSrc
    }
  })

  let imagesSegmented = props.images
    .filter(image => image.s3_segmented_image_url)
    .map(image => {
        return {
          src: image.s3_segmented_image_url,
          zoomSrc: image.s3_segmented_image_url
        }
      }
    )

  let images = [...imagesStandard, ...imagesSegmented]

  return (
    <div
      className={classes.carouselContainer}
    >
      <Slider
        lazyLoad={true}
        infinite={true}
        draggable={false}
        prevArrow={<PrevArrow/>}
        nextArrow={<NextArrow/>}
        className={classes.carousel}
      >
        {
          images.map((image, index) => {
            return (
              <div
                className={`${classes.imageContainer} ${props.outOfStock ? classes.outOfStock : ''}`}
                key={`carousel-image-${index}`}
              >
                <InnerImageZoom
                  className={classes.image}
                  src={image.src}
                  zoomSrc={image.zoomSrc}
                  height={styleProps.imageHeight}
                />
              </div>
            )
          })
        }
      </Slider>
      {
        props.outOfStock &&
        <div className={classes.outOfStockTextContainer}>
          <p className={classes.outOfStockText}>OUT OF STOCK</p>
        </div>
      }
    </div>
  )
}

export default ImageCarouselZoom
