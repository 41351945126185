import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import manIcon from '../../assets/img/manIcon.png'
import manWomanIcon from '../../assets/img/manWomanIcon.jpg'
import womanIcon from '../../assets/img/womanIcon.jpg'

const useStyles = makeStyles((theme) => ({
  text: {
    height: '24px',
    opacity: '0.5',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  gender: {
    display: 'inline-block',
    flex: 'none',
    marginLeft: '30px',
    paddingTop: '17.5px'
  },
  genderActive: {
    filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%) !important',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15) !important'
  },
  manIcon: {
    '&:hover': {
      // borderColor: '#86A8E0',
      filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%)',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.15)'
    },
    height: '24px',
    border: '1px solid ',
    boxSizing: 'content-box',
    borderRadius: '2px',
    padding: '13px 12px 13px 12px',
    borderColor: '#999999',
    filter: 'opacity(0.2)'
  },
  womanIcon: {
    '&:hover': {
      borderColor: '#86A8E0',
      filter: 'opacity(1) invert(73%) sepia(3%) saturate(7087%) hue-rotate(187deg) brightness(93%) contrast(88%)'
    },
    height: '34px',
    border: '1px solid',
    boxSizing: 'content-box',
    borderRadius: '2px',
    padding: '8px 8px 8px 8px',
    borderColor: '#999999',
    filter: 'opacity(0.2)'
  }
}))

export default function GenderSelect(props) {
  let classes = useStyles()
  if (!props.styling) {
    classes = props.styling
  }

  return (
    <div className={classes.gender}>
      <p className={classes.text}>Gender</p>
      <div>
        <img
          alt='maleIcon'
          className={`${classes.manIcon} + ${props.gender === 'men' ? classes.genderActive : ''}`}
          src={manIcon}
          onClick={() => props.setGender('men')}
        />
        <img
          alt='genderIcon'
          className={`${classes.manIcon} + ${props.gender === null ? classes.genderActive : ''}`}
          src={manWomanIcon}
          onClick={() => props.setGender(null)}
        />
        <img
          alt='femaleIcon'
          className={`${classes.womanIcon} + ${props.gender === 'women' ? classes.genderActive : ''}`}
          src={womanIcon}
          onClick={() => props.setGender('women')}
        />
      </div>
    </div>
  )
}
