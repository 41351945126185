import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import UploadImageForm from '../VisualSearchDialog/UploadImageForm'
import CloseIcon from '@material-ui/icons/Close'
import { Spinner } from 'react-bootstrap'
import api from '../../api/api'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#FDFBF9',
    border: '1px solid rgba(218, 183, 135, 0.5)',
    height: '800px',
    width: '100%',
    letterSpacing: '0px',
    overflow: 'hidden',
  },
  uploadHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    margin: 'auto',
    padding: '20px',
  },
  uploadTitle: {
    textTransform: 'uppercase',
    padding: '50px 30px 20px 30px',
    fontFamily: 'Montserrat',
    color: '#76787a',
    fontWeight: '500',
    fontSize: '1.3em',
    margin: '0 auto',
  },
  uploadClose: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    cursor: 'pointer',
  },
  loadingSpinner: {
    width: '300px',
    height: '300px',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center'
  },
  uploadImageForm: {
    padding: '10px',
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    margin: 'auto'
  },
  attributesSearchBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '25px',
    paddingTop: '0px',
  },
}))

export default function AttributesSearchDialog(props) {
  const classes = useStyles()
  let [isLoading, setIsLoading] = useState(false)

  // Filter categories so we only allow selection of a reduced set for simplicity
  let categories = []
  if (props.allCategories !== null && props.allCategories !== undefined) {
    const allowedCategories = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 17, 18, 31, 38]
    categories = props.allCategories.filter(category => (allowedCategories.includes(category._id)))
  }

  async function performAttributeSearch(image, isFullbody, gender, category) {
    setIsLoading(true)

    let imageBlob = null
    if (image instanceof Blob) {
      imageBlob = image
    } else {
      throw Error('Received image that is not a blob or url')
    }

    let data = new FormData()
    data.append('photo', imageBlob)
    data.append('gender', gender)

    data.append('outfit_bounding_boxes', true)
    data.append('bounding_box_categories', true)

    let garment = await api.addGarment(data)
    if (garment) {
      await setIsLoading(false)
      props.onClose()
      props.onImageAttributesConfirm(garment)
    }
  }

  function onExited() {
    setIsLoading(false)
  }

  let content = null

  // Show loading spinner
  if (isLoading) {
    content = <div className={classes.loadingSpinner}>
      <Spinner animation='border' role='status' >
        <span className='sr-only'></span>
      </Spinner>
    </div>
  }

  return (
    <Dialog open={props.show} onClose={props.onClose} onExited={onExited} maxWidth='md' fullWidth>
      <div className={classes.uploadClose} onClick={props.onClose}>
        <CloseIcon />
      </div>
      <div className={classes.root}>
        <div className={classes.uploadHeader}>
          <div className={classes.uploadTitle}>Attributes Search</div>
          <UploadImageForm garmentCategories={categories} performSearch={performAttributeSearch} />
          <div className={classes.uploadClose} onClick={props.onClose} >
            <CloseIcon />
          </div>
        </div>
        {content}
      </div>
    </Dialog>
  )
}
