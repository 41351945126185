import React, { useState } from 'react'
import { Switch, Route, NavLink, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const useStyles = makeStyles(theme => ({
  header: {
    width: '1200px',
    display: 'inline-block',
    // marginLeft: '38px',
    marginBottom: '17px !important'
  },
  root: {
    flexGrow: 1,
    display: 'inline-block'
  },
  navLink: {
    // marginTop: '200px'
  },
  database: {
    marginLeft: '22px',
    display: 'inline-block',
    '& p': {
      height: '24px',
      opacity: '0.5',
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '24px',
      marginBottom: '4.5px',
      marginTop: '24px'
    }
  },
  databaseSelect: {
    display: 'inline-flex',
    boxSizing: 'border-box',
    height: '49px',
    width: '249px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiFormControl-marginNormal': {
      marginTop: '0px',
      marginBottom: '0px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '5px'
    }
  },
  hidden: {
    display: 'none'
  },
  searchButton: {
    display: 'inline-flex',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '70px',
      color: '#ffffff',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      margin: 'auto'
    }
  },
  buttonHover: {
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  controls: {
    display: 'flex'
  }
}))

export default function EditorIntroPage(props) {
  const [database, setDatabase] = useState(JSON.parse(localStorage.getItem('ioeDatabase')) || { url: null, name: '' })
  const { url } = useRouteMatch()

  function activeRoute (routeName) {
    return window.location.href.split('/').indexOf(routeName) > -1
  }

  async function handleSource (e, value) {
    if (value === null) {
      localStorage.removeItem('ioeDatabase')
      await setDatabase({ url: null, name: '' })
    } else {
      localStorage.setItem('ioeDatabase', JSON.stringify(value))
      await setDatabase(value)
    }
  }

  const classes = useStyles()
  const options = JSON.parse(localStorage.getItem('user')).databases

  const switchRoutes = (
    <Switch>
      <Route
        key={props.path}
        path={`${url}/${props.path}`}
        render={(p) => React.createElement(props.component, {...p, database: database.url})}
      />
    </Switch>
  )

  return (
    <div>
      <div className={`${classes.database} ${activeRoute(props.path) ? classes.hidden : ''}`}>
        <p className={classes.text}>DATABASE</p>
        <div className={classes.controls}>
          <Autocomplete
            className={classes.databaseSelect}
            id='source-select-intro'
            options={options}
            getOptionLabel={option => option.name}
            onChange={handleSource}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select database'
                margin='normal'
                fullWidth
              />
            )}
            value={database}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.name, inputValue)
              const parts = parse(option.name, matches)
              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
          <NavLink
            key={props.path}
            to={`${url}/${props.path}`}
            className={classes.root}
          >
            <div className={`${classes.searchButton} ${classes.buttonHover}`}>
              <p>{props.buttonLabel || 'GO'}</p>
            </div>
          </NavLink>
        </div>
      </div>
      <div>
        {switchRoutes}
      </div>
    </div>
  )
}