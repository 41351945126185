import React, {useEffect, useState} from 'react'
import AccordionSection from '../AccordionSection'


export default function AbstractAccordionSection(props) {
  const [labels, setLabels] = useState(null)
  const [labelsAllSelected, setLabelsAllSelected] = useState(true)

  const updateLabels = (newLabels) => {
    setLabels(newLabels)
    props.setLabels(newLabels)
  }

  const getLabels = async () => {
    let newLabels = await props.getLabels()
    updateLabels(newLabels)
  }

  const onLabelClick = (selectedLabel) => {
    if (labels !== null) {
      let labelsCopy = JSON.parse(JSON.stringify(labels))
      labelsCopy.forEach(label => {
        if (label._id === selectedLabel._id) {
          label.active = !label.active
        }
      })
      updateLabels(labelsCopy)
    }
  }

  const onSelectAllLabelsClick = () => {
    if (labels !== null) {
      let labelsCopy = JSON.parse(JSON.stringify(labels))
      labelsCopy.forEach(label => {
        label.active = !labelsAllSelected
      })
      updateLabels(labelsCopy)
      setLabelsAllSelected(!labelsAllSelected)
    }
  }

  useEffect(() => {
    if (labels === null) {
      getLabels()
    }
  }, [labels])

  useEffect(() => {
    if (labels !== null) {
      getLabels()
    }
  }, [props.userIds])

  return (
    <AccordionSection
      headerText={props.title}
      options={labels}
      onOptionClick={onLabelClick}
      allSelected={labelsAllSelected}
      onSelectAllClick={onSelectAllLabelsClick}
    />
  )
}
