import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function SubcategoryAccordionSection(props) {
  const getLabels = async () => {
    let newLabels = await api.getSubcategories({url: props.url})

    try {
      newLabels.forEach(label => {
        label.active = true
      })
      return newLabels
    } catch {
      console.error('Failed to get subcategories')
      return []
    }


  }

  return (
    <AbstractAccordionSection
      title={'Subcategory'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}