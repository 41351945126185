import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import GenderSelect from '../../components/GenderSelect/GenderSelect'
import OutfitEditorAdvancedFilterBar from './OutfitEditorAdvancedFilterBar'
import FilterListIcon from '@material-ui/icons/FilterList'
import api from 'api/api'
import OutfitEditorActionBar from './OutfitEditorActionBar'
import OutfitStats from './OutfitStats'


export default function OutfitEditorSearchBar(props) {
  const classes = props.styling
  const user = JSON.parse(localStorage.getItem('user'))
  const outfitEditorSettings = user.feSettings.outfitEditorSettings

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
  const [gender, setGender] = useState(null)
  const [occasion, setOccasion] = useState('')
  const [persona, setPersona] = useState('')
  const [sources, setSources] = useState([])
  const [source, setSource] = useState({ username: '', _id: '' })
  const [categories, setCategories] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [outfitId, setOutfitId] = useState('')
  const [brands, setBrands] = useState([])
  const [brand, setBrand] = useState(null)
  const [marketIds, setMarketIds] = useState([])
  const [marketId, setMarketId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [outfitType, setOutfitType] = useState({name: 'Complete The Look', _id: 'complete_the_look'})
  const [outfitTypes, setOutfitTypes] = useState([
    {
      _id: 'complete_the_look',
      name: 'Complete The Look'
    },
    {
      _id: 'shop_the_look',
      name: 'Shop The Look'
    }
  ])
  const [showApprovedOnly, setShowApprovedOnly] = useState(false)
  const [showUnapprovedOnly, setShowUnapprovedOnly] = useState(false)
  const [selectionDefault, setSelectionDefault] = useState(outfitEditorSettings.selectionDefault)
  const [priorityOnly, setPriorityOnly] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [outfitsPerPage, setOutfitsPerPage] = useState(1)
  const [quality, setQuality] = useState({name: '', _id: ''})
  const [originalOutfits, setOriginalOutfits] = useState([])
  const [approvalLevel, setApprovalLevel] = useState(outfitEditorSettings.approvalLevel)
  const [unapprovalLevel, setUnapprovalLevel] = useState(outfitEditorSettings.unapprovalLevel)
  const [minApprovalLevelShow, setMinApprovalLevelShow] = useState(outfitEditorSettings.minApprovalLevelShow)
  const [maxApprovalLevelShow, setMaxApprovalLevelShow] = useState(outfitEditorSettings.maxApprovalLevelShow)
  const [stats, setStats] = useState([])
  const [showStats, setShowStats] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)

  const updateLoading = async (loadingValue) => {
    await setLoading(loadingValue)
    await props.setLoading(loadingValue)
  }

  const getSearchBody = () => {
    // TODO - update to reflect selected filter values

    // TODO - make sure that all relevant from below comment block is replicated
    /*
    const data = {
      n_queries: 1,
      query_offset: this.state.skipValue,
      query_restrictions: this.getQueryRestrictions(),
      outfit_restrictions: this.getOutfitRestrictions(),
      cached: true,
      sample_outfits: false,
      return_garments: true,
      ignore_favourite_users: true,
      remove_keys: ['embedding'],  // To reduce the size of the response a bit - there may be more fields which can be removed
      n_outfits_per_query: this.state.numOutfitsToShow
    }

    if (this.state.sortBy) {
      data.sort_by = this.state.sortBy
    }

    data.url = this.state.url

    let res = await api.getOutfits(data)

    return res.data.garments
     */

    let outfitRestrictions = {
      gender: gender ? [gender] : null,
      user_id: source ? [source._id] : null,
      min_approved: minApprovalLevelShow,
      max_approved: maxApprovalLevelShow,
      processing_status: null,  // don't need outfits to be processed before they are vetted
      in_editor: user._id,
      in_stock: true
    }

    if (quality.name) {
      outfitRestrictions.quality = [quality._id]
    }

    if (outfitType._id) {
      outfitRestrictions.outfit_type = [outfitType._id]
    }

    if (outfitId) {
      outfitRestrictions.outfit_id = outfitId
    }

    if (occasion) {
      if (props.occasions.map(occasion => occasion.name).includes(occasion)) {
        outfitRestrictions.occasion = props.occasions
          .filter(o => o.name === occasion)
          .map(occasion => occasion._id)
      }
    }

    if (persona) {
      if (props.personas.map(persona => persona.name).includes(persona)) {
        outfitRestrictions.persona = props.personas
          .filter(p => p.name === persona)
          .map(persona => persona._id)
      }
    }

    if (showApprovedOnly) {
      outfitRestrictions.approval_user_exists = true
    } else if (showUnapprovedOnly) {
      outfitRestrictions.approval_user_exists = false
    }

    // TODO - marketId
    // TODO - weather
    // TODO - outfit_type
    // TODO - min_approved / max_approved

    return {
      url: props.url,
      outfit_restrictions: outfitRestrictions,
      offset: outfitsPerPage * (page - 1),
      limit: outfitsPerPage
    }
  }

  const search = async (keepPage) => {
    await updateLoading(true)
    if (!keepPage) {
      await setPage(1)
    }

    // Set old outfits out of editor
    if (props.outfits && props.outfits.length > 0) {
      await api.outfitsSetInEditor({
        _id: props.outfits.map(outfit => outfit._id),
        in_editor: false,
        url: props.url
      })
    }

    // Search for new outfits
    let body = getSearchBody()
    let res = await api.listOutfits(body)
    await props.setOutfits(res.outfits, res.outfits.map(() => selectionDefault))
    await setOriginalOutfits(JSON.parse(JSON.stringify(res.outfits)))
    await setTotalPages(res.metadata.total)

    // TODO - if making the usedProductIds functionality work then would have to potentially retrieve new full list of
    //  usedProductIds here
    await updateLoading(false)
  }

  const getSources = async () => {
    // Get full list of sources, filtered by those permitted for the current user
    let allSources = await api.getUsers({ url: props.url })

    // If the user settings limits the available users, filter the users array
    if (user.feSettings.availableUsersOutfitEditor !== undefined && user.feSettings.availableUsersOutfitEditor.length > 0) {
      allSources = allSources.filter(availableUser => (
        user.feSettings.availableUsersOutfitEditor.includes(
          availableUser.username
        )
      ))
    }
    setSources(allSources)

    // Deal with setting selected source, possibly using existing selected source from localstorage
    let newSource = localStorage.getItem('ioeSource')
    if ([null, undefined, 'undefined'].includes(newSource)) {
      newSource = allSources[0]
    } else {
      newSource = JSON.parse(newSource)
    }

    await handleSourceChange(null, newSource)
  }

  const getMarketIds = async () => {
    let newMarketIds = await api.getMarketIds({
      url: props.url,
      garment_restrictions: {
        user_ids_restriction: [source._id]
      }
    })

    // Map market ids to correct format for dropdown
    newMarketIds = newMarketIds.map((marketId, index) => {
      return {
        _id: index,
        name: marketId,
        active: false
      }
    })

    // Make sure product categories are sorted alphabetically
    newMarketIds.sort((a, b) => (a.name.localeCompare(b.name)))
    setMarketIds(newMarketIds)
  }

  const getCategories = async () => {
    const newCategories = await api.getCategories({ url: props.url })

    // Update category objects with active attribute
    newCategories.forEach((newCategory) => (
      newCategory.active = true
    ))

    await setCategories(newCategories)
    await props.setCategories(newCategories)
  }

  const getProductCategories = async () => {
    const productCategoryDropdown = user.feSettings.outfitEditorSettings.productCategoryDropdown
    const productCategoryDropdownMain = user.feSettings.outfitEditorSettings.productCategoryDropdownMain
    if (!productCategoryDropdown && !productCategoryDropdownMain) {
      return
    }
    let productCategories = await api.getProductCategories({
      url: props.url,
      garment_restrictions: {
        user_ids_restriction: source._id
      }
    })

    // Map product categories to correct format for dropdown
    productCategories = productCategories.map((productCategory, index) => {
      return {
        _id: index,
        name: productCategory,
        active: false
      }
    })

    // Make sure product categories are sorted alphabetically
    productCategories.sort((a, b) => (a.name.localeCompare(b.name)))
    await setProductCategories(productCategories)
    await props.setProductCategories(productCategories)
  }

  const handleMarketIdChange = async (e, value) => {
    // TODO - may need to change
    if (value === null) {
      await setMarketId('')
    } else {
      await setMarketId(value)
    }
  }

  const handleOccasionChange = async (e, value) => {
    if (value === null) {
      await setOccasion('')
    } else {
      await setOccasion(value)
    }
  }

  const handlePersonaChange = async (e, value) => {
    if (value === null) {
      await setPersona('')
    } else {
      await setPersona(value)
    }
  }


  const handleBrandChange = async (e, value) => {
    // TODO - may need to change
    if (value === null) {
      await setBrand('')
    } else {
      await setBrand(value)
    }
  }

  const handleOutfitTypeChange = async (e, value) => {
    if (value === null) {
      await setOutfitType({name: 'Complete The Look', _id: 'complete_the_look'})
    } else {
      await setOutfitType(value)
    }
  }

  const getAllBrandsForUser = async (user_ids) => {
    let allBrands = await api.getAllBrandsForUser({url: props.url, user_id: user_ids})
    setBrands(allBrands)
    return allBrands
  }

  const handleSourceChange = async (e, value) => {
    if (value === null) {
      value = { username: '', _id: '' }
      localStorage.removeItem('ioeSource') // intelistyle outfit editor Source
    } else {
      localStorage.setItem('ioeSource', JSON.stringify(value))
    }
    await setSource(value)

    if (outfitEditorSettings.enableAdvancedSearchBrand) {
      await getAllBrandsForUser([value._id])
    }

    if (outfitEditorSettings.enableAdvancedSearchMarketId) {
      await getMarketIds()
    }

    // Add replacement order sort by option if edit mode is shop_the_look
    // TODO - make sure below is dealt with in garment navigator code
    /*
    let garmentNavigatorSortByEditOptions = JSON.parse(JSON.stringify(this.state.garmentNavigatorSortByEditOptions))
    garmentNavigatorSortByEditOptions = garmentNavigatorSortByEditOptions.filter(option => option !== 'replacement order')
    if (this.checkEditMode(this.getActiveOutfit()) === 'shop_the_look') {
      garmentNavigatorSortByEditOptions.push('replacement order')
    }
    this.setState({
      garmentNavigatorSortByEditOptions
    })
    */
  }

  const resetFilters = () => {
    // TODO - set outfits out of editor

    setGender(null)
    props.setOutfits([])  // TODO - is there really need for this? could either just leave user to press search or run search again automatically
    // TODO - reset outfitsSelected so that no outfits are marked as selected.
    setOutfitId('')
    setSource(null)
    setOccasion('')
    setPersona('')
  }

  const approveOutfits = async () => {
    // Check that all selected outfits contain only in-stock items
    const selectedInStock = checkSelectedInStock()
    if (!selectedInStock) {
      return false
    }

    const outfits = props.outfits.map((outfit, index) => {
      // Mark whether the outfit was changed
      if (outfit.garments.length !== originalOutfits[index].garments.length) {
        outfit.outfit_changed = true
      } else {
        outfit.outfit_changed = !outfit.garments
          .every(
            (garment, gIndex) => {
              return garment._id === originalOutfits[index].garments[gIndex]._id
            }
          )
      }

      // Update the garments, since we have the full garment objects in a single array but need just the ids separated
      outfit.query_garment = outfit.garments[0]._id
      outfit.outfit_garments = outfit.garments.slice(1).map(garment => garment._id)

      // Delete the old garments field
      delete outfit.garments
      delete outfit.in_editor

      // Update the approval level
      if (props.outfitSelected[index]) {
        outfit.approved = approvalLevel
      } else {
        outfit.approved = unapprovalLevel
      }

      return outfit
    })

    const body = {
      outfits: outfits,
      create_new: false,
      url: props.url
    }

    await api.outfitsApprove(body)

    return true
  }

  const handleApproveClick = async () => {
    if (props.outfits) {
      await updateLoading(true)

      // Approve/unapprove outfits based on selection status
      const status = await approveOutfits()
      if (!status) {
        alert('Unable to approve outfits')
        await updateLoading(false)
      } else {
        // Get new outfits to approve
        await search()
      }
    }
  }

  const getVettingProgress = async () => {
    await setStatsLoading(true)
    const stats = []
    const approvalLevels = [-3, -2, 0, 1, 2]
    let i
    let index = 0
    for (i of approvalLevels) {
      i = parseInt(i)
      let approvalLevelStats = {
        approved: i
      }

      // Get approved count
      approvalLevelStats.approved_count = await api.outfitsCount({
        outfit_restrictions: {
          user_id: [source._id],
          approved: i
        },
        url: props.url
      })

      // Get approved count for current user
      approvalLevelStats.approved_user_count = await api.outfitapprovaleventsCount({
        outfitapprovalevent_restrictions: {
          outfit_user_id: [source._id],
          approval_user_id: [user._id],
          approved: i
        },
        url: props.url
      })

      // Get approved count for current user (mapped - currently not doing this so just using total for user. Should be
      // based on 'copied' field of outfits)
      //approvalLevelStats.approved_user_count_mapped = approvalLevelStats.approved_user_count

      // Get total outfits
      approvalLevelStats.total_count = await api.outfitsCount({
        outfit_restrictions: {
          user_id: [source._id]
        },
        url: props.url
      })

      stats[index] = approvalLevelStats
      index++
    }

    stats.push({
      approved: 'Total',
      approved_count: stats.filter(row => row.approved !== 0).map(row => row.approved_count).reduce((a, b) => a + b, 0),
      approved_user_count: stats.filter(row => row.approved !== 0).map(row => row.approved_user_count).reduce((a, b) => a + b, 0),
      total_count: stats[0].total_count
    })

    // Update state
    await setStats(stats)
    await setStatsLoading(false)
  }

  const onStatsClick = async () => {
    // Show stats modal
    await setShowStats(true)

    // Retrieve stats
    await getVettingProgress()
  }

  const checkGarmentInStock = (garment, marketId) => {
    let stock
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stock = garment.variant_data
        .filter(variant => variant.market_id === marketId)
        .some(variant => variant.stock_level > 0)
    } else {
      stock = garment.stock_level > 0
    }
    return stock
  }

  const checkSelectedInStock = () => {
    return props.outfits.filter((outfit, index) => (
      props.outfitSelected[index]
    ))
      .map((outfit) => {
        let marketId = outfit.market_id
        if (marketId !== undefined) {
          marketId = marketId[0]
        }
        return outfit.garments.every(garment => (checkGarmentInStock(garment, marketId)))
      }).every(item => item)
  }

  useEffect(() => {
    if (sources.length === 0) {
      getSources()  // Also deals with retrieving market ids, product categories and brands since those depend on the source
    }
  }, [sources])

  useEffect(() => {
    if (categories.length === 0) {
      getCategories()  // Also deals with retrieving market ids and brands since those depend on the source
    }
  }, [categories])

  useEffect(() => {
    if (productCategories.length === 0) {
      getProductCategories()
    }
  }, [productCategories, source])

  useEffect(() => {
    if (source._id) {
      search(true)
    }
  }, [page])

  return (
    <div className={classes.topBar}>
      <OutfitStats
        show={showStats}
        onClose={() => setShowStats(false)}
        loading={statsLoading}
        stats={stats}
      />
      <div className={classes.ruleBar}>
        <div className={classes.source}>
          <p className={classes.text}>Source</p>
          <Autocomplete
            className={classes.sourceSelect}
            id='source-select'
            options={sources}
            getOptionLabel={option => option.username}
            getOptionSelected={(a, b) => a.username === b.username}
            onChange={handleSourceChange}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select source'
                margin='normal'
                fullWidth
              />
            )}
            value={source}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.username, inputValue)
              const parts = parse(option.username, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        <div className={classes.category}>
          <p className={classes.text}>Outfit Id</p>
          <Autocomplete
            freeSolo
            className={classes.categorySelect}
            id='category-select'
            options={[]}
            limitTags={2}
            onInputChange={(e, value) => setOutfitId(value)}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Enter outfit id'
                margin='normal'
                fullWidth
              />
            )}
            value={outfitId}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue)
              const parts = parse(option, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        <div className={classes.source}>
          <p className={classes.text}>Occasion</p>
          <Autocomplete
            className={classes.sourceSelect}
            id='occasion-select'
            options={[...new Set(props.occasions.filter(option => option._id >= 25).map(option => option.name))]}
            getOptionLabel={(option) => {
              if (option === '') {
                return ''
              } else {
                return option
              }
            }}
            getOptionSelected={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
            onChange={handleOccasionChange}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select occasion'
                margin='normal'
                fullWidth
              />
            )}
            value={occasion}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue)
              const parts = parse(option, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        <div className={classes.source}>
          <p className={classes.text}>Persona</p>
          <Autocomplete
            className={classes.sourceSelect}
            id='persona-select'
            options={[...new Set(props.personas.filter(persona => persona._id >= 32).map(persona => persona.name))]}
            getOptionLabel={(option) => {
              if (option === '') {
                return ''
              } else {
                return option
              }
            }}
            getOptionSelected={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
            onChange={handlePersonaChange}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select persona'
                margin='normal'
                fullWidth
              />
            )}
            value={persona}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue)
              const parts = parse(option, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        <GenderSelect
          gender={gender}
          setGender={setGender}
          styling={props.styling}
        />
        {
          true && // outfitEditorSettings.enableAdvancedSearch && TODO - revert!
        <div
          className={`${classes.advancedSearchButton} ${classes.buttonHover}`}
          onClick={() => {setShowAdvancedSearch(prevState => !prevState)}}
        >
          <FilterListIcon/>
        </div>
        }
        <div className={`${classes.reset} ${classes.buttonHover}`} onClick={resetFilters}>RESET ALL</div>
        <div className={`${!loading ? classes.applyButton : classes.applyButtonDisabled}`} onClick={() => search()}>
          <p className={classes.applyText}>SEARCH</p>
        </div>
      </div>
      <OutfitEditorAdvancedFilterBar
        show={showAdvancedSearch}
        enableMarketId={outfitEditorSettings.enableAdvancedSearchMarketId}
        marketId={marketId}
        marketIds={marketIds}
        handleMarketIdChange={handleMarketIdChange}
        quality={quality}
        setQuality={setQuality}
        enableBrand={outfitEditorSettings.enableAdvancedSearchBrand}
        brand={brand}
        brands={brands}
        handleBrandChange={handleBrandChange}
        outfitType={outfitType}
        outfitTypes={outfitTypes}
        enableShowApprovedControls={outfitEditorSettings.enableShowApprovedControls}
        handleOutfitTypeChange={handleOutfitTypeChange}
        handleShowApprovedOnlyChange={() => setShowApprovedOnly(prev => !prev)}
        handleShowUnapprovedOnlyChange={() => setShowUnapprovedOnly(prev => !prev)}
        handleSelectionDefaultChange={() => setSelectionDefault(prev => !prev)}
        handlePriorityOnlyChange={() => setPriorityOnly(prev => !prev)}
        outfitsPerPage={outfitsPerPage}
        setOutfitsPerPage={setOutfitsPerPage}
      />
      <OutfitEditorActionBar
        styling={props.styling}
        enabled={props.actionsEnabled}
        outfitSelected={props.outfitSelected}
        undo={props.undo}
        redo={props.redo}
        page={page}
        totalPages={totalPages}
        setPage={setPage}
        onStatsClick={onStatsClick}
        handleApproveClick={handleApproveClick}
      />
    </div>
  )
}
