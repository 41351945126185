import React from 'react'

import '../../../assets/css/WearThatSearch/Size.scss'

const BraSizes = ({
  selectedCup,
  selectedBand,
  bands,
  cups,
  onChange,
  name
}) => {
  const BraSizesSelector = (
    <div className='bra-sizes-wrapper'>
      <select
        dataDropupAuto='false'
        menuPlacement='bottom'
        onChange={e => onChange(e)}
        value={selectedBand}
        name={name}
        braCategory='Band'
        className='left-dropdown'
      >
        {bands.map(band => (
          <option value={band}>{band}</option>
        ))}
      </select>

      <select
        data-dropup-auto='false'
        onChange={e => onChange(e)}
        value={selectedCup}
        name={name}
        braCategory='Cup'
        className='right-dropdown'
      >
        {cups.map(cup => (
          <option value={cup}>{cup}</option>
        ))}
      </select>
    </div>
  )

  return BraSizesSelector
}

export default BraSizes
