import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import '../../../assets/css/WearThatSearch/SearchQueryResults.scss'
import utils from '../../../utils/Utils'
import { withStyles } from '@material-ui/core/styles'
import Garment from '../../../components/Garment/Garment'

const style = theme => ({
  col: {
    flexGrow: 0,
    width: '24%',
    padding: 0,
    '@media only screen and (min-width: 1040px)': {
      minWidth: '240px'
    },
    '@media only screen and (min-width: 1159px)': {
      minWidth: '180px'
    },
    '@media only screen and (min-width: 1365px)': {
      minWidth: '220px'
    }
  },
  row: {
    justifyContent: 'space-evenly',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '36px',
    width: '100%'
  },
  middleLine: {
    borderBottom: '2px solid #DAE0EA',
    border: 0
  },
  variableWidth: {
    cursor: 'pointer',
    '@media only screen and (min-width: 1365px)': {
    },
    '@media only screen and (min-width: 1139)': {
    }
  }
})

class SearchQueryResults extends React.Component {
  constructor (props) {
    super(props)
    this.state = { width: '100%', height: 0, chunkSize: 4 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    if (window.innerWidth > 1699) {
      this.setState({ garmentWidth: '100%', chunkSize: 5 })
    } else if (window.innerWidth > 1365) {
      this.setState({ garmentWidth: '100%', chunkSize: 4 })
    } else if (window.innerWidth > 1139) {
      this.setState({ garmentWidth: '100%' })
    } else {
      this.setState({ garmentWidth: '100%' })
    }
  }

  render () {
    const { classes } = this.props
    let queryComponents
    if (this.props.results.length !== 0) {
      queryComponents = utils.chunk(this.props.results, this.state.chunkSize).map((garmentRow, rowIndex) => (
        <Row key={rowIndex} className={classes.row}>
          {garmentRow.map((garment, colIndex) => (
            <Col key={`${rowIndex}-${colIndex}`} className={classes.col}>
              <div key={`scroll-div-${garment._id}`}>

                <Garment
                  className={classes.variableWidth}
                  editable={false}
                  garment={garment}
                  imageHeight='300px'
                  imageCarouselClassName={classes.middleLine}
                  middleLine
                  onClick={
                    (garment) => { this.props.resultOnClick(garment) }
                  }
                  searchPage
                  showUserFriendlyId={garment.chatbot_id}
                  showSizes={garment._user === '5e84627547710f17806d7f52'}
                  singleImage
                  showProductId
                  showBrand
                  showPrice
                  useSmallImages
                  width={this.state.garmentWidth}
                />
              </div>
            </Col>
          ))}
        </Row>
      ))
    }
    if (this.props.loading) return <div id='loading-text'>LOADING</div>

    if (this.props.error !== '') return <div id='no-results-text'>{this.props.error}</div>

    if ((!this.props.results && !this.props.loading) || (this.props.results.length === 0 && !this.props.loading)) { return <div id='no-results-text'>NO RESULTS</div> } else {
      return <div className='card-view-box'>{queryComponents}</div>
    }
  }
}

export default withStyles(style)(SearchQueryResults)

// <SearchQueryResult
//   showAttributes={garmentWithAttributesActive !== null && garment._id === garmentWithAttributesActive._id}
//   index={colIndex}
//   key={garment._id}
//   onClick={(garment) => { resultOnClick(garment) }}
//   garment={garment}
//   subcategoriesIdToName={subcategoriesIdToName}
//   attributesIdToName={attributesIdToName}
// />

// useSmallImages={this.props.useSmallImages}
// usedProductIds={this.props.usedProductIds}
