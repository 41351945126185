import React from 'react'
import fashionableClothes from '../../assets/img/shutterstock_1315659653.png'
import {withStyles} from '@material-ui/core/styles'
import Outfit from '../../components/Outfit/Outfit'
import MainGarment from 'components/OutfitDemo/MainGarment'
import GarmentNavigator from '../../components/GarmentNavigator/GarmentNavigator'
import OutfitStats from './OutfitStats'
import mappedColours from '../../utils/grouped_colours'
import {Spinner} from 'react-bootstrap'
import api from 'api/api'
import authClient from 'services/Auth'
import update from 'immutability-helper'
import OutfitEditorFilterBar from './OutfitEditorFilterBar'
import OutfitEditorActionBar from './OutfitEditorActionBar'
import {priceRules} from 'components/RelativePriceFilter/relativePriceRules'
import brand_category_restrictions from 'utils/brand_category_restrictions'
import { ObjectID } from 'bson'

const Mousetrap = require('mousetrap')

const OutfitEditorStyle = require('./OutfitEditorStyle')

const style = theme => (OutfitEditorStyle)

class OutfitEditor extends React.Component {
  constructor (props) {
    super(props)

    const user = JSON.parse(localStorage.getItem('user'))
    let approvalLevel = 2
    let unapprovalLevel  // Value will be set once approvalLevel is finalised given user settings
    let unusedItemsOutfitApprovalLevel  // Value will be set once approvalLevel is finalised given user settings
    let maxApprovalLevelShow = 1
    let minApprovalLevelShow = 0
    let numOutfitsToShow = 10000 // Want every outfit that is available
    let productCategoryDropdownMain = false
    let productCategoryDropdown = false
    let searchPreselectCategory = true
    let searchPreselectProductCategory = false
    let includeQueryWithApproval = true
    let enableGarmentNavigatorSettings = false
    let enableGarmentNavigatorShortcuts = false
    let enableUsedProductIds = false
    let sortBy = null  //{createdAt: -1}
    let mappingUserIds = null
    let showApprovalUser = false
    let enableAdvancedSearch = false
    let enableAdvancedSearchMarketId = false
    let enableAdvancedSearchBrand = false
    let enableShowApprovedControls = false
    let selectionDefault = false
    let cachedVisualSimilarity = false
    let remote_garment_id_filter = ''
    if (user.access === 'stylist') {
      showApprovalUser = true
      enableGarmentNavigatorShortcuts = true
      enableAdvancedSearch = true
      enableAdvancedSearchMarketId = true
      enableAdvancedSearchBrand = true
      enableShowApprovedControls = true
    } else if (user.access === 'trainee_stylist') {
      approvalLevel = 0
      unapprovalLevel = -2
      showApprovalUser = true
      enableGarmentNavigatorShortcuts = true
      enableAdvancedSearch = true
      enableAdvancedSearchMarketId = true
      enableAdvancedSearchBrand = true
      enableShowApprovedControls = true
    } else if (user.access === 'client') {
      minApprovalLevelShow = 2
      maxApprovalLevelShow = 2
      enableUsedProductIds = false
    } else if (user.access === 'campaigneditor') {
      minApprovalLevelShow = 2
      maxApprovalLevelShow = 2
      approvalLevel = 3
      numOutfitsToShow = 1
    }

    // Set any settings by outfit editor settings saved for current user
    if (user.feSettings.outfitEditorSettings !== undefined) {
      // Approval level
      if (user.feSettings.outfitEditorSettings.approvalLevel !== undefined) {
        approvalLevel = user.feSettings.outfitEditorSettings.approvalLevel
      }

      // Max approval level show
      if (user.feSettings.outfitEditorSettings.maxApprovalLevelShow !== undefined) {
        maxApprovalLevelShow = user.feSettings.outfitEditorSettings.maxApprovalLevelShow
      }

      // Min approval level show
      if (user.feSettings.outfitEditorSettings.minApprovalLevelShow !== undefined) {
        minApprovalLevelShow = user.feSettings.outfitEditorSettings.minApprovalLevelShow
      }

      // Product category dropdown main
      // Product category dropdown (otherwise will display the brand group dropdown)
      if (user.feSettings.outfitEditorSettings.productCategoryDropdownMain !== undefined) {
        productCategoryDropdownMain = user.feSettings.outfitEditorSettings.productCategoryDropdownMain
      }

      // Product category dropdown (otherwise will display the brand group dropdown)
      if (user.feSettings.outfitEditorSettings.productCategoryDropdown !== undefined) {
        productCategoryDropdown = user.feSettings.outfitEditorSettings.productCategoryDropdown
      }

      // Preselect category based on edited garment in garment navigator
      if (user.feSettings.outfitEditorSettings.searchPreselectCategory !== undefined) {
        searchPreselectCategory = user.feSettings.outfitEditorSettings.searchPreselectCategory
      }

      // Preselect product category based on edited garment in garment navigator
      if (user.feSettings.outfitEditorSettings.searchPreselectProductCategory !== undefined) {
        searchPreselectProductCategory = user.feSettings.outfitEditorSettings.searchPreselectProductCategory
      }

      // Preselect product category based on edited garment in garment navigator
      if (user.feSettings.outfitEditorSettings.includeQueryWithApproval !== undefined) {
        includeQueryWithApproval = user.feSettings.outfitEditorSettings.includeQueryWithApproval
      }

      // Enable settings in garment navigator
      if (user.feSettings.outfitEditorSettings.enableGarmentNavigatorSettings !== undefined) {
        enableGarmentNavigatorSettings = user.feSettings.outfitEditorSettings.enableGarmentNavigatorSettings
      }

      // Enable shortcuts in garment navigator
      if (user.feSettings.outfitEditorSettings.enableGarmentNavigatorShortcuts !== undefined) {
        enableGarmentNavigatorShortcuts = user.feSettings.outfitEditorSettings.enableGarmentNavigatorShortcuts
      }

      // Set "sort_by" option for outfit retrieval
      if (user.feSettings.outfitEditorSettings.sortBy !== undefined) {
        sortBy = user.feSettings.outfitEditorSettings.sortBy
      }

      // Set unapprovalLevel
      if (user.feSettings.outfitEditorSettings.unapprovalLevel !== undefined) {
        unapprovalLevel = user.feSettings.outfitEditorSettings.unapprovalLevel
      } else if (unapprovalLevel === undefined) {
        unapprovalLevel = -approvalLevel
      }

      // Set unusedItemsOutfitApprovalLevel
      if (user.feSettings.outfitEditorSettings.unusedItemsOutfitApprovalLevel !== undefined) {
        unusedItemsOutfitApprovalLevel = user.feSettings.outfitEditorSettings.unusedItemsOutfitApprovalLevel
      } else {
        unusedItemsOutfitApprovalLevel = approvalLevel
      }

      // Set enableUsedProductIds - controls whether the used product ids feature is available at all
      if (user.feSettings.outfitEditorSettings.enableUsedProductIds !== undefined) {
        enableUsedProductIds = false  //user.feSettings.outfitEditorSettings.enableUsedProductIds
      }

      // Set mappingUserIds - controls whether we are mapping approved outfits to all items within the outfit.
      //                      if not null and the user id of the query garment is found in this array then mapping will
      //                      be enabled
      if (user.feSettings.outfitEditorSettings.mappingUserIds !== undefined) {
        mappingUserIds = user.feSettings.outfitEditorSettings.mappingUserIds
      }

      if (user.feSettings.outfitEditorSettings.showApprovalUser !== undefined) {
        showApprovalUser = user.feSettings.outfitEditorSettings.showApprovalUser
      }

      if (user.feSettings.outfitEditorSettings.enableAdvancedSearch !== undefined) {
        enableAdvancedSearch = user.feSettings.outfitEditorSettings.enableAdvancedSearch
      }

      if (user.feSettings.outfitEditorSettings.enableAdvancedSearchMarketId !== undefined) {
        enableAdvancedSearchMarketId = user.feSettings.outfitEditorSettings.enableAdvancedSearchMarketId
      }

      if (user.feSettings.outfitEditorSettings.enableAdvancedSearchBrand !== undefined) {
        enableAdvancedSearchBrand = user.feSettings.outfitEditorSettings.enableAdvancedSearchBrand
      }

      if (user.feSettings.outfitEditorSettings.selectionDefault !== undefined) {
        selectionDefault = user.feSettings.outfitEditorSettings.selectionDefault
      }

      if (user.feSettings.outfitEditorSettings.cachedVisualSimilarity !== undefined) {
        cachedVisualSimilarity = user.feSettings.outfitEditorSettings.cachedVisualSimilarity
      }
    }

    // Make sure variables without values are assigned a value
    if (unapprovalLevel === undefined) {
      unapprovalLevel = -approvalLevel
    }

    if (unusedItemsOutfitApprovalLevel === undefined) {
      unusedItemsOutfitApprovalLevel = approvalLevel
    }


    this.state = {
      addGarmentToOutfit: false,
      alternativeGarments: [],
      allBrandGroups: [],
      allBrandGroupsSelected: false,
      allUsers: [],
      allCategories: [],
      allBrands: [],
      allOutfitTypes: [
        {
          _id: 'complete_the_look',
          name: 'Complete The Look'
        },
        {
          _id: 'shop_the_look',
          name: 'Shop The Look'
        }
      ],
      approvalLevel: approvalLevel,
      unapprovalLevel: unapprovalLevel,
      unusedItemsOutfitApprovalLevel: unusedItemsOutfitApprovalLevel,
      approvalLevelShow: null,
      category: '',
      persona: '',
      searchColours: [],
      colourFilterShow: false,
      productCategory: '',
      marketId: '',
      selectedUser: '',
      allVettingUsers: [],
      brand: '',
      outfitType: '',
      brandGroup: '',
      categoryIdToEdit: -1,
      colours: mappedColours,
      coloursActive: [],
      colourIdToEdit: -1,
      currencyCode: '',
      garmentNavigatorPage: 1,
      gender: null,
      hideUsedProducts: true,
      historySize: 10,
      historyIndex: 0,
      itemsPerPage: 15,
      itemsPerPageDefault: 15,
      itemsPerPageMax: 100,
      loading: false,
      maxApprovalLevelShow: maxApprovalLevelShow,
      minApprovalLevelShow: minApprovalLevelShow,
      productCategorySearchTerm: '',
      brandSearchValue: '',
      priceValues: {
        min: 0,
        max: 100000
      },
      priceRange: {
        min: 0,
        max: 100000
      },
      subcategories: [],
      attributes: [],
      attributeCategories: [],
      attributeCategoryAttributeMap: null,
      embeddingComponentsWeight: 1,
      subcategoriesWeight: 1,
      attributesWeight: 1,
      attributeCategoriesWeights: new Array(15).fill(1),
      subcategoryIdToEdit: -1,
      showGarmentNavigator: false,
      showStats: false,
      skipValue: 0,
      statsLoading: false,
      source: null,
      outfitsHistory: [],
      outfitSelected: [],
      queryGarment: null,
      usedProductIds: [],
      user_ids: null,
      url: this.props.database,
      garmentNavigatorSortByEdit: 'visual similarity',
      garmentNavigatorSortByEditOptions: [
        'visual similarity',
        'price ascending',
        'price descending',
        'stock ascending',
        'stock descending',
        'discount ascending',
        'discount descending',
      ],
      garmentNavigatorSortByAdd: 'stock descending',
      garmentNavigatorSortByAddOptions: [
        'price ascending',
        'price descending',
        'stock ascending',
        'stock descending',
        'discount ascending',
        'discount descending',
      ],
      garmentNavigatorTotalPages: 1,
      garmentNavigatorBrandRestriction: null,
      garmentNavigatorBrandExclusion: null,
      garmentNavigatorLoading: false,
      productCategories: [],
      marketIds: [],
      productCategoryDropdownMain: productCategoryDropdownMain,
      productCategoryDropdown: productCategoryDropdown,
      searchPreselectCategory: searchPreselectCategory,
      searchPreselectProductCategory: searchPreselectProductCategory,
      includeQueryWithApproval: includeQueryWithApproval,
      enableGarmentNavigatorSettings: enableGarmentNavigatorSettings,
      enableGarmentNavigatorShortcuts: enableGarmentNavigatorShortcuts,
      enableUsedProductIds: enableUsedProductIds,
      garmentNavigatorSettings: {
        similars: {
          embedding_weights: new Array(502).fill(1)
        }
      },
      sortBy: sortBy,
      allOccasions: [],
      allPersonas: [],
      mappingUserIds: mappingUserIds,
      numOutfitsToShow,
      showApprovalUser: showApprovalUser,
      enableAdvancedSearch: enableAdvancedSearch,
      enableAdvancedSearchMarketId: enableAdvancedSearchMarketId,
      enableAdvancedSearchBrand: enableAdvancedSearchBrand,
      enableShowApprovedControls: enableShowApprovedControls,
      showApprovedOnly: false,
      showUnapprovedOnly: false,
      selectionDefault,
      priorityOnly: false,
      cachedVisualSimilarity: cachedVisualSimilarity
    }
    this.getUsers = this.getUsers.bind(this)
    this.getAllOccasions = this.getAllOccasions.bind(this)
    this.getAllPersonas = this.getAllPersonas.bind(this)
    this.getAllVettingUsers = this.getAllVettingUsers.bind(this)
    this.getAllBrandsForUser = this.getAllBrandsForUser.bind(this)
    this.getGarmentCategories = this.getGarmentCategories.bind(this)
    this.getOutfits = this.getOutfits.bind(this)
    this.getNewGarment = this.getNewGarment.bind(this)
    this.handleSource = this.handleSource.bind(this)
    this.handleCategory = this.handleCategory.bind(this)
    this.handleProductCategory = this.handleProductCategory.bind(this)
    this.handlePersona = this.handlePersona.bind(this)
    this.handleMarketIdChange = this.handleMarketIdChange.bind(this)
    this.handleBrandChange = this.handleBrandChange.bind(this)
    this.handleBrandGroupChange =  this.handleBrandGroupChange.bind(this)
    this.handleOutfitTypeChange = this.handleOutfitTypeChange.bind(this)
    this.handleSearchInGarmentNavigator = this.handleSearchInGarmentNavigator.bind(this)
  }

  async approveOutfits () {
    // Check that all selected outfits contain only in-stock items
    const selectedInStock = this.checkSelectedInStock()
    if (selectedInStock) {
      let approved = 0
      const outfits = []
      for (let i = 0; i < this.state.outfits.length; i++) {
        if (this.state.outfitSelected[i]) {
          approved = this.state.approvalLevel
        } else {
          approved = this.state.unapprovalLevel
        }

        // Convert outfits to garment ids in correct format
        let garment_ids
        if (this.checkEditMode(this.state.outfits[i]) === 'shop_the_look') {
          garment_ids = this.state.outfits[i].garments.map(garment => {
            let garment_id
            if (garment.similar_data !== null) {
              if (garment.similar_data.remote_product_id !== null && garment.similar_data.remote_product_id !== undefined) {
                // TODO - should recompute similars.garment_ids here to make sure that it doesn't contain the edited garment
                garment_id = {
                  remote_product_id: garment.remote_product_id,
                  similar_data: garment.similar_data
                }
              } else {
                // TODO - should recompute similars.garment_ids here to make sure that it doesn't contain the edited garment
                garment_id = {
                  remote_product_id: garment.remote_product_id,
                  similar_data: {
                    remote_product_id: garment.remote_product_id,
                    similars: garment.similar_data.similars
                  }
                }
              }
            } else {
              // TODO - should recompute similars.garment_ids here to make sure that it doesn't contain the edited garment
              garment_id = {
                remote_product_id: garment.remote_product_id,
                similar_data: {
                  remote_product_id: garment.remote_product_id,
                  similars: {
                    garment_ids: [],
                    edited: false
                  }
                }
              }
            }
            return garment_id
          })
        } else {
          garment_ids = this.state.outfits[i].garments.map(garment => {
            if (garment.similar_data !== null) {
              return {
                remote_product_id: garment.remote_product_id,
                similar_data: garment.similar_data
              }
            } else {
              return garment.remote_product_id
            }
          })
        }

        // Insert the query product id
        if (this.state.includeQueryWithApproval) {
          garment_ids.unshift(this.state.queryGarment.remote_product_id)
        }

        let outfit = {
          outfit_id: this.state.outfits[i].outfit_id,
          approved: approved,
          garment_ids: garment_ids,
          approval_history: {
            _user: authClient.getUser()._id
          },
          _user: this.state.outfits[i]._user,
          source: this.state.outfits[i].source,
          type: this.state.outfits[i].type,
          market_id: this.state.outfits[i].market_id,
          persona: this.state.outfits[i].persona,
          occasion: this.state.outfits[i].occasion,
          bodytype: this.state.outfits[i].bodytype,
          weather: this.state.outfits[i].weather
        }

        if (![null, undefined].includes(this.state.outfits[i].brand_group)) {
          outfit.brand_group = this.state.outfits[i].brand_group
        }

        if (![null, undefined].includes(this.state.outfits[i].outfit_params_hash)) {
          outfit.outfit_params_hash = this.state.outfits[i].outfit_params_hash
        }

        outfits.push(outfit)
      }

      const user = JSON.parse(localStorage.getItem('user'))
      const body = {
        query_id: this.state.queryGarment._id,
        outfits: outfits,
        copy_to_non_queries: this.state.mappingUserIds !== null && this.state.mappingUserIds.includes(this.state.queryGarment._user),
        approval_user_id: user._id,
        min_approval_to_copy: this.state.approvalLevel,
        max_approval_to_copy_to: this.state.maxApprovalLevelShow,
        url: this.state.url
      }

      await api.approveOutfits(body)

      return true
    } else {
      return false
    }
  }

  checkGarmentInStock (garment, marketId) {
    let stock
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stock = garment.variant_data
        .filter(variant => variant.market_id === marketId)
        .some(variant => variant.stock_level > 0)
    } else {
      stock = garment.stock_level > 0
    }
    return stock
  }

  checkSelectedInStock () {
    return this.state.outfits.filter((outfit, index) => (
      this.state.outfitSelected[index]
    )
    ).map((outfit) => {
      let marketId = outfit.market_id
      if (marketId !== undefined) {
        marketId = marketId[0]
      }
      return outfit.garments.every(garment => (this.checkGarmentInStock(garment, marketId)))
    }
    ).every(item => item)
  }

  async handleSearchInGarmentNavigator () {
    const allBrandGroups = JSON.parse(JSON.stringify(this.state.allBrandGroups))
    let brandRestriction = this.getBrandRestrictionForGroups(allBrandGroups)
    let user = JSON.parse(localStorage.getItem('user'))
    let brandExclusion = null
    if (user.hasOwnProperty('feSettings') &&
        user.feSettings.hasOwnProperty('outfitEditorSettings') &&
        user.feSettings.outfitEditorSettings.enableBrandCategoryRestrictions &&
        (this.state.categoryIdToEdit >= 0 || this.state.subcategoryIdToEdit >= 0)) {
      let brandRestrictionExclusion = this.getBrandRestrictionsBasedOnCategory(
        this.state.outfits[this.state.activeOutfit]['garments'],
        this.state.queryGarment,
        this.state.categoryIdToEdit,
        this.state.subcategoryIdToEdit
      )
      brandRestriction = brandRestrictionExclusion[0]
      brandExclusion = brandRestrictionExclusion[1]
    }

    await this.setState({
      garmentNavigatorPage: 1,
      garmentNavigatorBrandRestriction: brandRestriction,
      garmentNavigatorBrandExclusion: brandExclusion
    })

    await this.getAlternativeGarments()
  }

  clearHistory () {
    this.setState({
      outfitsHistory: [],
      historyIndex: 0
    })
  }

  updateHistory () {
    let historyIndex = this.state.historyIndex
    let outfitsHistory = this.state.outfitsHistory.slice(historyIndex)
    historyIndex = 0

    // Do a deep copy of the outfits array
    const outfits = JSON.parse(JSON.stringify(this.state.outfits))

    outfitsHistory.unshift(outfits)
    if (outfitsHistory.length > this.state.historySize) {
      outfitsHistory = outfitsHistory.slice(0, this.state.historySize)
    }

    this.setState({
      outfitsHistory,
      historyIndex
    })
  }

  getBrandRestrictionForGroups (allBrandGroups) {
    const activeBrandGroupBrands = allBrandGroups.filter(brandGroup => brandGroup.active && brandGroup.enabled).map(brandGroup => brandGroup.brands)
    let brandRestriction = []
    activeBrandGroupBrands.forEach(brandGroupBrands => { brandGroupBrands.forEach(brand => { brandRestriction.push(brand) }) })
    return brandRestriction
  }

  checkEditMode (outfit) {
    // Returns the edit mode of the editor (currently "complete_the_look" or "shop_the_look")
    let editMode = 'complete_the_look'
    // Bit of a hack to get editor to show garments from source www.net-a-porter.com when query is from
    // "Net-A-Porter Fullbody" source
    if ([
      '5f3159cea4501e4a9aae7ad2',
      '5f3ea1ba54ecc1e501d12bb9',
      '5f3ea1d054ecc1e501d12bba',
      '5f7c87c87f0ebdcd2f7d2acc',  // LC Waikiki (Fullbody)
      '60464cfd02f97d73d3877af9'  // Modanisa Fullbody
    ]
      .includes(this.state.user_ids)) {
      editMode = 'shop_the_look'
    } else if (outfit !== undefined && outfit.type === 'shop_the_look') {
      editMode = 'shop_the_look'
    }

    return editMode
  }

  getUserIdsRestriction() {
    let user_ids_restriction = null
    if (this.state.queryGarment !== null) {
      user_ids_restriction = [this.state.queryGarment._user]
    } else {
      user_ids_restriction = this.state.user_ids
      if (!user_ids_restriction) {
        user_ids_restriction = this.state.allUsers.map(user => user._id)
      }
    }
    // Bit of a hack to get editor to show garments from source www.net-a-porter.com when query is from
    // "Net-A-Porter Fullbody" source
    if (this.checkEditMode(this.getActiveOutfit()) === 'shop_the_look') {
      if ([
        '5f3159cea4501e4a9aae7ad2',
        '5f3ea1ba54ecc1e501d12bb9',
        '5f3ea1d054ecc1e501d12bba'
      ]
        .includes(this.state.user_ids)) {
        // Net-A-Porter
        user_ids_restriction = [
          '5bdb32c6ad911a6b0ea64f41'
        ]
      } else if ([
        '5f7c87c87f0ebdcd2f7d2acc',
      ]
        .includes(this.state.user_ids)) {
        // LC Waikiki (Fullbody)
        user_ids_restriction = [
          '5f7c87c87f0ebdcd2f7d2acc'
        ]
      } else if ([
        '60464cfd02f97d73d3877af9',
      ]
        .includes(this.state.user_ids)) {
        // Modanisa Fullbody
        user_ids_restriction = [
          '60266a578c73d4089dba1648'
        ]
      }
    }

    // Show garments from H&M for Hugo Boss garments for demo purposes
    if (['641885c24cd63583c7e39eac'].includes(this.state.user_ids)) {
      user_ids_restriction = [
        '61f25d2f544c9a8cfc154204'
      ]
    }

    return user_ids_restriction
  }

  getItemsPerPage () {
    let itemsPerPage = this.state.itemsPerPage
    if (itemsPerPage === '') {
      itemsPerPage = this.state.itemsPerPageDefault
      this.setState({
        itemsPerPage: this.state.itemsPerPageDefault
      })
    }
    return itemsPerPage
  }

  async handleShortcut1Click() {
    const allBrandGroups = this.state.allBrandGroups.slice()
    allBrandGroups.forEach(brandGroup => { brandGroup.active = false })

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    productCategories.forEach(productCategory => { productCategory.active = false })

    const priceValues = JSON.parse(JSON.stringify(this.state.priceRange))

    // Hoop Earrings
    await this.setState({
      garmentNavigatorLoading: true,
      categoryIdToEdit: 46,  // Earrings category
      subcategoryIdToEdit: -1,  // No subcategory
      colourIdToEdit: -1,
      coloursActive: [],
      allBrandGroupsSelected: false,
      productCategorySearchTerm: 'hoop',
      priceValues,
      garmentNavigatorPage: 1,
      garmentNavigatorBrandRestriction: null,
      garmentNavigatorBrandExclusion: null,
      allBrandGroups,
      productCategories
    })

    await this.getAlternativeGarments()
  }

  async handleSearchShortcutClick(searchTerm) {
    await this.setState({
      garmentNavigatorLoading: true,
      subcategoryIdToEdit: -1,  // Clear selected subcategory
      productCategorySearchTerm: searchTerm,
      garmentNavigatorPage: 1,
    })

    await this.getAlternativeGarments()
  }

  async handleClearSearchShortcutClick(searchTerm) {
    const allBrandGroups = this.state.allBrandGroups.slice()
    allBrandGroups.forEach(brandGroup => { brandGroup.active = false })

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    productCategories.forEach(productCategory => { productCategory.active = false })

    const priceValues = JSON.parse(JSON.stringify(this.state.priceRange))

    await this.setState({
      garmentNavigatorLoading: true,
      colourIdToEdit: -1,
      coloursActive: [],
      allBrandGroupsSelected: false,
      categoryIdToEdit: -1,
      subcategoryIdToEdit: -1,  // Clear selected subcategory
      productCategorySearchTerm: searchTerm,
      garmentNavigatorPage: 1,
      priceValues,
      allBrandGroups,
      productCategories
    })

    await this.getAlternativeGarments()
  }

  getActiveOutfit() {
    let activeOutfit = undefined
    if (this.state.activeOutfit !== undefined) {
      activeOutfit = this.state.outfits[this.state.activeOutfit]
    }
    return activeOutfit
  }

  getAlternativeGarmentsQuery() {
    let user_ids_restriction = this.getUserIdsRestriction()

    // Create base query with required parameters
    let queryObject = {
      gender: this.state.queryGarment.gender,
      user_ids_restriction: user_ids_restriction,
      in_stock: true,
      price_restriction: this.state.priceValues,
      approved: true
    }

    if (this.state.queryGarment.age_ranges) {
      queryObject.age_ranges = this.state.queryGarment.age_ranges
    }

    // Add subcategory restriction if necessary
    if (!(this.state.subcategoryIdToEdit === '-1' || this.state.subcategoryIdToEdit === -1)) {
      queryObject.subcategory_ids_restriction = [this.state.subcategoryIdToEdit]
    }

    // Add colour restriction if necessary
    if (this.state.coloursActive.length > 0) {
      queryObject.colour_restriction = this.state.coloursActive
    }

    // Add search query if necessary
    let colourSearch = this.state.searchColours.length > 0 ? ' ' + this.state.searchColours.join(' ') : ''
    if (this.state.searchColours.length > 0) {
      queryObject.search_query = this.state.searchColours.join(' ')
    }
    if (this.state.productCategorySearchTerm !== '') {
      queryObject.search_query = this.state.productCategorySearchTerm + colourSearch
      // await this.setState({ productCategorySearchTerm: "" })
    }

    // Add brand exclusion
    if (this.state.garmentNavigatorBrandExclusion && this.state.garmentNavigatorBrandExclusion.length > 0) {
      queryObject.brand_exclusion = this.state.garmentNavigatorBrandExclusion
    }

    // Add brand restriction
    if (this.state.brandSearchValue !== '') {
      queryObject.brand_restriction = this.state.brandSearchValue.length > 0 ? this.state.brandSearchValue : null
      queryObject.brand_exclusion = null
    } else if (this.state.garmentNavigatorBrandRestriction) {
      queryObject.brand_restriction = this.state.garmentNavigatorBrandRestriction.length > 0 ? this.state.garmentNavigatorBrandRestriction : null
    }

    // Add category restriction if necessary
    if (this.state.categoryIdToEdit === '-1' || this.state.categoryIdToEdit === -1) {
      const allCategories = JSON.parse(JSON.stringify(this.state.allCategories))
      const category_ids_restriction = allCategories.map(category => category._id)
      if (category_ids_restriction.length > 0) {
        queryObject.category_ids_restriction = category_ids_restriction
      }
    } else {
      queryObject.category_ids_restriction = [this.state.categoryIdToEdit]
    }

    // Add product category restriction
    let productCategoriesRestriction = this.state.productCategories
      .filter(productCategory => productCategory.active)
      .map(productCategory => productCategory.name)
    if (productCategoriesRestriction.length > 0) {
      queryObject.remote_product_categories = productCategoriesRestriction
    }

    // Add market id restriction based on market id of outfit (not value in filters)
    let activeOutfit = this.getActiveOutfit()
    if (![null, undefined].includes(activeOutfit.market_id) && activeOutfit.market_id.length > 0) {
      queryObject.market_id = activeOutfit.market_id
    }

    // Add product id exclusion (to exclude used items)
    if (this.state.enableUsedProductIds && this.checkEditMode(this.getActiveOutfit()) !== 'shop_the_look' && this.state.usedProductIds && this.state.hideUsedProducts) {
      queryObject.remote_product_id_exclusion = this.state.usedProductIds
    }

    // Add additional fields if not searching by similarity
    let editedGarment = null
    if (!this.state.addGarmentToOutfit && this.state.editedGarmentIndex) {
      editedGarment = this.state.outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
    }

    if (!editedGarment || (this.state.garmentNavigatorSortByEdit !== 'visual similarity' && this.state.garmentNavigatorSortByEdit !== 'replacement order')) {
      let itemsPerPage = this.getItemsPerPage()
      queryObject = {
        garment_restrictions: queryObject,
        offset: itemsPerPage * (this.state.garmentNavigatorPage - 1),
        limit: itemsPerPage
      }

      let sortBy = editedGarment ? this.state.garmentNavigatorSortByEdit : this.state.garmentNavigatorSortByAdd

      switch (sortBy) {
      case 'price descending':
        queryObject.sort_by = {
          price: -1,
          _id: 1
        }
        break
      case 'price ascending':
        queryObject.sort_by = {
          price: 1,
          _id: 1
        }
        break
      case 'stock descending':
        queryObject.sort_by = {
          stock_level: -1,
          _id: 1
        }
        break
      case 'stock ascending':
        queryObject.sort_by = {
          stock_level: 1,
          _id: 1
        }
        break
      case 'discount ascending':
        queryObject.sort_by = {
          discount: 1,
          _id: 1
        }
        break
      case 'discount descending':
        queryObject.sort_by = {
          discount: -1,
          _id: 1
        }
        break
      default:
        console.error('Invalid sortBy: ', sortBy)
        break
      }

      queryObject.url = this.state.url
    }

    return queryObject
  }

  async getAlternativeVisuallySimilarGarments() {
    let garments = []
    let editedGarment = this.state.outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
    let query_id = editedGarment._id
    const queryObject = this.getAlternativeGarmentsQuery()
    let itemsPerPage = this.getItemsPerPage()
    let body = {
      url: this.state.url,
      query_id: query_id,
      n_similars_per_query: itemsPerPage,
      similar_restrictions: queryObject,
      cached: this.state.cachedVisualSimilarity,
      old: false,
      ignore_favourite_users: true,
      restrict_by_category: false,
      restrict_by_subcategory: false,
      remove_keys: null,
      embedding_weights: this.state.garmentNavigatorSettings.similars.embedding_weights
    }

    let response = await api.getSimilars(body)
    if (response.data.similars && response.data.similars.length > 0) {
      garments = response.data.similars[0].garments
    }
    return garments
  }

  async getAlternativeReplacementOrderGarments() {
    // Load garments from edited garment
    let garments = []
    let replacementProductIds = []
    let editedGarment = this.state.outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
    if (editedGarment.similar_data !== undefined && editedGarment.similar_data !== null) {
      if (editedGarment.similar_data.similars !== undefined && editedGarment.similar_data.similars !== null) {
        replacementProductIds = editedGarment.similar_data.similars.garment_ids
      }
    }
    if (replacementProductIds.length > 0) {
      let body = {
        url: this.state.url,
        garment_restrictions: {
          remote_product_id: replacementProductIds,
          user_ids_restriction: this.getUserIdsRestriction()
        },
        limit: 1000000
      }

      let response = await api.listGarmentsV3(body)
      if (response.garments) {
        let replacementGarments = {}
        response.garments.forEach(garment => {
          replacementGarments[garment.remote_product_id] = garment
        })
        garments = replacementProductIds
          .filter(productId => replacementGarments[productId] !== undefined)
          .map(productId => replacementGarments[productId])
      }
    }

    return garments
  }

  async getAlternativeGarments () {
    await this.setState({
      garmentNavigatorLoading: true
    })

    let garments = []
    let garmentNavigatorTotalPages = 1
    let editedGarment = null
    if (this.state.editedGarmentIndex && !this.state.addGarmentToOutfit) {
      editedGarment = this.state.outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
    }
    if (!this.state.addGarmentToOutfit && editedGarment && this.state.garmentNavigatorSortByEdit === 'visual similarity') {
      garments = await this.getAlternativeVisuallySimilarGarments()
    } else if (!this.state.addGarmentToOutfit && editedGarment && this.state.garmentNavigatorSortByEdit === 'replacement order') {
      garments = await this.getAlternativeReplacementOrderGarments()
    } else {
      const queryObject = this.getAlternativeGarmentsQuery()

      const response = await api.listGarmentsV3( queryObject )
      let itemsPerPage = this.getItemsPerPage()
      garmentNavigatorTotalPages = Math.ceil(response.metadata.total / itemsPerPage)

      garments = response.garments
    }

    await this.setState({
      alternativeGarments: garments,
      garmentNavigatorTotalPages,
      garmentNavigatorLoading: false
    })
  }

  handleAddGarmentToReplacementOrderClick(garment) {
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let editedGarment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (editedGarment.similar_data === null || editedGarment.similar_data === undefined) {
      editedGarment.similar_data = {}
    }

    if (editedGarment.similar_data.similars === null || editedGarment.similar_data.similars === undefined) {
      editedGarment.similar_data.similars = {
        garment_ids: [],
        edited: false
      }
    }

    if (!editedGarment.similar_data.similars.garment_ids.includes(garment.remote_product_id)) {
      editedGarment.similar_data.similars.garment_ids.push(garment.remote_product_id)
      editedGarment.similar_data.similars.edited = true
      outfits[garmentIndex[0]][garmentIndex[1]] = editedGarment
      this.setState({
        outfits
      })
    }
  }

  handleRemoveGarmentFromReplacementOrderClick(garment) {
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let editedGarment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (editedGarment.similar_data === null || editedGarment.similar_data === undefined) {
      editedGarment.similar_data = {}
    }

    if (editedGarment.similar_data.similars === null || editedGarment.similar_data.similars === undefined) {
      editedGarment.similar_data.similars = {
        garment_ids: [],
        edited: false
      }
    }

    editedGarment.similar_data.similars.garment_ids = editedGarment.similar_data.similars.garment_ids.filter(productId => productId !== garment.remote_product_id)
    editedGarment.similar_data.similars.edited = true
    outfits[garmentIndex[0]][garmentIndex[1]] = editedGarment

    // Also remove from alternativeGarments to prevent having to re-retrieve replacement garments. This is valid because
    // it will only be possible to remove a garment from the replacement order if the navigator is already displaying
    // the replacement order - i.e. this.state.alternativeGarments contains the replacement garments
    let alternativeGarments = JSON.parse(JSON.stringify(this.state.alternativeGarments))
    alternativeGarments = alternativeGarments.filter(g => g._id !== garment._id)

    this.setState({
      outfits,
      alternativeGarments
    })
  }

  updateReplacementOrder(replacementOrder) {
    let outfits = JSON.parse(JSON.stringify(this.state.outfits))
    let garmentIndex = this.state.editedGarmentIndex
    let garment = outfits[garmentIndex[0]].garments[garmentIndex[1]]
    if (garment.similar_data === null || garment.similar_data === undefined) {
      garment.similar_data = {}
    }

    if (garment.similar_data.similars === null || garment.similar_data.similars === undefined) {
      garment.similar_data.similars = {}
    }

    garment.similar_data.similars.garment_ids = replacementOrder
    garment.similar_data.similars.edited = true
    outfits[garmentIndex[0]][garmentIndex[1]] = garment
    this.setState({
      outfits
    })
  }

  moveAlternativeGarment(id, atIndex) {
    const {garment, index} = this.findAlternativeGarment(id)
    let alternativeGarments = update(this.state.alternativeGarments, {
      $splice: [
        [index, 1],
        [atIndex, 0, garment],
      ],
    })

    this.setState({
      alternativeGarments
    })

    this.updateReplacementOrder(alternativeGarments.map(garment => garment.remote_product_id))
  }

  findAlternativeGarment(id) {
    let alternativeGarments = JSON.parse(JSON.stringify(this.state.alternativeGarments))
    const garment = alternativeGarments.filter(g => `${g._id}` === id)[0]
    return {
      garment,
      index: alternativeGarments.indexOf(garment)
    }
  }

  async getBrandGroups () {
    let brandGroups = (await api.getBrandGroups({ url: this.state.url })).data
    brandGroups = brandGroups.brand_groups

    // Reorganise brand groups into easily usable format for dropdown
    let queryGender
    if (this.state.queryGarment) {
      queryGender = this.state.queryGarment.gender
    } else {
      queryGender = 'women'
    }

    const allBrandGroups = []
    for (const gender in brandGroups) {
      const genderBrandGroups = brandGroups[gender]
      Object.entries(genderBrandGroups).forEach(([key, value]) => {
        value._id = key
        value.active = false
        value.enabled = value.gender === queryGender
        allBrandGroups.push(value)
      })
    }

    // Give each brand group a unique index so that they can easily be referenced even after array filtering etc.
    allBrandGroups.forEach((brandGroup, index) => { brandGroup.index = index })

    // Sort brand groups in alphabetical order
    allBrandGroups.sort((a, b) => (a.name.localeCompare(b.name)))

    this.setState({
      allBrandGroups: allBrandGroups
    })
  }

  async getUsers () {
    let allUsers = await api.getUsers({ url: this.state.url })

    // Filter available users
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.feSettings.availableUsersOutfitEditor !== undefined && user.feSettings.availableUsersOutfitEditor.length > 0) {
      allUsers = allUsers.filter(availableUser => (
        user.feSettings.availableUsersOutfitEditor.includes(
          availableUser.corpname
        )
      ))
    }

    this.setState({
      allUsers
    })
    let existingSource = true
    let existingUserIds = true
    const source = JSON.parse(localStorage.getItem('ioeSource'))
    if (source) {
      await this.setState({ source: source })
    } else {
      existingSource = false
    }
    const userIds = JSON.parse(localStorage.getItem('ioeUserIds'))
    if (userIds) {
      await this.setState({ user_ids: userIds })
      if (this.state.enableAdvancedSearchBrand) {
        // If we are setting the selected source based on a value stored in local storage we need to re-retrieve all the
        // brands for this source (this would otherwise be done in handleSource)
        this.getAllBrandsForUser(userIds)
      }
    } else {
      existingUserIds = false
    }

    if (!existingSource && !existingUserIds && allUsers.length > 0) {
      await this.handleSource(null, allUsers[0])
    }
  }

  async getAllOccasions() {
    let allOccasions = await api.getAllOccasions({url: this.state.url})
    this.setState({
      allOccasions
    })
  }

  async getAllPersonas() {
    let allPersonas = await api.getAllPersonas({url: this.state.url})
    this.setState({
      allPersonas
    })
  }

  async getAllVettingUsers() {
    let allVettingUsers = await api.getAllVettingUsers()
    allVettingUsers = allVettingUsers['users']

    // Map users to correct format for dropdown
    allVettingUsers = allVettingUsers.map((username, index) => {
      return {
        _id: index,
        name: username,
        active: false
      }
    })

    this.setState({
      allVettingUsers
    })
  }

  async getAllBrandsForUser(user_ids) {
    let allBrands = await api.getAllBrandsForUser({user_id: user_ids})
    this.setState({
      allBrands
    })
  }

  async getProductCategories () {
    if (!this.state.productCategoryDropdown && !this.state.productCategoryDropdownMain) {
      return
    }
    let productCategories = await api.getProductCategories({
      url: this.state.url,
      garment_restrictions: {
        user_ids_restriction: this.getUserIdsRestriction()
      }
    })

    // Map product categories to correct format for dropdown
    productCategories = productCategories.map((productCategory, index) => {
      return {
        _id: index,
        name: productCategory,
        active: false
      }
    })

    // Make sure product categories are sorted alphabetically
    productCategories.sort((a, b) => (a.name.localeCompare(b.name)))
    this.setState({
      productCategories
    })
  }

  async getMarketIds () {
    let marketIds = await api.getMarketIds({
      url: this.state.url,
      garment_restrictions: {
        user_ids_restriction: this.getUserIdsRestriction()
      }
    })

    // Map product categories to correct format for dropdown
    marketIds = marketIds.map((marketId, index) => {
      return {
        _id: index,
        name: marketId,
        active: false
      }
    })

    // Make sure product categories are sorted alphabetically
    marketIds.sort((a, b) => (a.name.localeCompare(b.name)))
    this.setState({
      marketIds: marketIds
    })
  }

  async handleAddGarmentClick (outfitIndex) {
    // Set relevant brand group active
    const outfitBrandGroup = this.state.outfits[outfitIndex].brand_group
    const allBrandGroups = JSON.parse(JSON.stringify(this.state.allBrandGroups))
    if (!this.state.productCategoryDropdown) {
      allBrandGroups.forEach(brandGroup => { brandGroup.active = brandGroup.gender === this.state.queryGarment.gender && parseInt(brandGroup._id) === outfitBrandGroup })
    }
    const brandRestriction = this.getBrandRestrictionForGroups(allBrandGroups)

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    if (this.state.productCategoryDropdown && this.state.searchPreselectProductCategory) {
      productCategories.forEach(productCategory => {
        productCategory.active = false
      })
    }

    await this.setState({
      allBrandGroups: allBrandGroups,
      productCategories: productCategories,
      garmentNavigatorBrandRestriction: brandRestriction,
      // garmentNavigatorBrandExclusion: brandExclusion,
      showGarmentNavigator: true,
      addGarmentToOutfit: true,
      categoriesForDropdown: this.state.allCategories,
      activeOutfit: outfitIndex,
      categoryIdToEdit: -1,
      subcategoryIdToEdit: -1,
      garmentNavigatorPage: 1,
      coloursActive: [],
      alternativeGarments: []
    })

    await this.getAlternativeGarments()
  }

  async handleApproveClick () {
    if (this.state.queryGarment) {
      await this.setState({
        loading: true
      })

      // Approve/unapprove outfits based on selection status
      const status = await this.approveOutfits()
      if (!status) {
        alert('Unable to approve outfits')
        await this.setState({
          loading: false
        })
      } else {
        // Get new outfits to approve
        await this.getNewGarment()
      }
    }
  }

  handleBrandGroupDropdownChange (value) {
    const allBrandGroups = JSON.parse(JSON.stringify(this.state.allBrandGroups))
    if (value === null) {
      allBrandGroups.forEach(brandGroup => {
        if (brandGroup.enabled) {
          brandGroup.active = false
        }
      })
    } else {
      allBrandGroups.forEach(brandGroup => {
        if (brandGroup.index === value.index) {
          brandGroup.active = !brandGroup.active
        } else {
          brandGroup.active = false
        }
      })
    }

    // Update active parameter for specified brand group
    this.setState({
      allBrandGroups
    })
  }

  handleProductCategoryDropdownChange (value) {
    const productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    if (value === null) {
      productCategories.forEach(productCategory => {
        productCategory.active = false
      })
    } else {
      productCategories.forEach(productCategory => {
        if (productCategory._id === value._id) {
          productCategory.active = !productCategory.active
        } else {
          productCategory.active = false
        }
      })
    }

    // Update active parameter for specified brand group
    this.setState({
      productCategories
    })
  }

  handleCloseGarmentNavigator () {
    this.setState({
      showGarmentNavigator: false,
      alternativeGarments: [],
      garmentNavigatorPage: 1,
      categoryIdToEdit: null,
      subcategoryIdToEdit: -1,
      productCategorySearchTerm: '',
      brandSearchValue: '',
      colourFilterShow: false,
      searchColours: [],
      priceValues: {
        min: 0,
        max: 100000
      }
    })
  }

  async handleNextGarmentPageClick () {
    const garmentNavigatorPage = Math.min(
      this.state.garmentNavigatorTotalPages,
      this.state.garmentNavigatorPage + 1
    )

    if (this.state.garmentNavigatorPage !== garmentNavigatorPage) {
      await this.setState({
        garmentNavigatorPage
      })

      await this.getAlternativeGarments()
    }
  }

  async handlePrevGarmentPageClick () {
    const garmentNavigatorPage = Math.max(this.state.garmentNavigatorPage - 1, 1)

    if (this.state.garmentNavigatorPage !== garmentNavigatorPage) {
      await this.setState({
        garmentNavigatorPage
      })

      await this.getAlternativeGarments()
    }
  }

  async handleEditGarmentClick (outfitIndex, garmentIndex, garment) {
    // Set relevant brand group active
    const outfitBrandGroup = this.state.outfits[garmentIndex[0]].brand_group
    const allBrandGroups = JSON.parse(JSON.stringify(this.state.allBrandGroups))
    if (!this.state.productCategoryDropdown) {
      allBrandGroups.forEach(brandGroup => { brandGroup.active = brandGroup.gender === this.state.queryGarment.gender && parseInt(brandGroup._id) === outfitBrandGroup })
    }
    let brandRestriction = this.getBrandRestrictionForGroups(allBrandGroups)

    let categoryIdToEdit = -1
    if (this.state.searchPreselectCategory) {
      categoryIdToEdit = garment.garmentType
    }

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    if (this.state.productCategoryDropdown && this.state.searchPreselectProductCategory) {
      productCategories.forEach(productCategory => {
        productCategory.active = productCategory.name === garment.remote_product_categories[0]
      })
    } else if (this.state.productCategoryDropdown) {
      productCategories.forEach(productCategory => {
        productCategory.active = false
      })
    }

    let subcategoryIdToEdit = -1
    if (this.checkEditMode(this.state.outfits[outfitIndex]) === 'complete_the_look') {
      subcategoryIdToEdit = garment.subcategories[0]
    }

    let garmentNavigatorSortByEditOptions = this.getGarmentNavigatorSortByOptions(this.state.outfits[outfitIndex])
    let garmentNavigatorSortByEdit = this.state.garmentNavigatorSortByEdit
    if (!garmentNavigatorSortByEditOptions.includes(this.state.garmentNavigatorSortByEdit)) {
      garmentNavigatorSortByEdit = 'stock descending'
    }

    let user = JSON.parse(localStorage.getItem('user'))
    let brandExclusion = null
    if (user.hasOwnProperty('feSettings') &&
        user.feSettings.hasOwnProperty('outfitEditorSettings') &&
        user.feSettings.outfitEditorSettings.enableBrandCategoryRestrictions &&
        categoryIdToEdit >= 0) {
      let brandRestrictionExclusion = this.getBrandRestrictionsBasedOnCategory(this.state.outfits[outfitIndex]['garments'],
        this.state.queryGarment,
        categoryIdToEdit,
        subcategoryIdToEdit
      )
      brandRestriction = brandRestrictionExclusion[0]
      brandExclusion = brandRestrictionExclusion[1]
    }

    await this.setState({
      editedGarmentIndex: garmentIndex,
      categoryIdToEdit: categoryIdToEdit,
      subcategoryIdToEdit: subcategoryIdToEdit,
      productCategories: productCategories,
      colourIdToEdit: -1,
      coloursActive: [],
      showGarmentNavigator: true,
      addGarmentToOutfit: false,
      categoriesForDropdown: this.state.allCategories,
      allBrandGroups: allBrandGroups,
      garmentNavigatorBrandRestriction: brandRestriction,
      garmentNavigatorBrandExclusion: brandExclusion,
      garmentNavigatorPage: 1,
      alternativeGarments: [],
      activeOutfit: outfitIndex,
      garmentNavigatorSortByEditOptions: this.getGarmentNavigatorSortByOptions(this.state.outfits[outfitIndex]),
      garmentNavigatorSortByEdit: garmentNavigatorSortByEdit
    })

    await this.getAlternativeGarments()
  }

  async handleDeleteGarmentClick (garmentIndex) {
    const outfits = this.state.outfits.slice()
    // iterate over two dimensional outfits and remove garment?
    const outfitsRow = outfits[garmentIndex[0]]
    outfitsRow.garments.splice(garmentIndex[1], 1)
    outfits[garmentIndex[0]] = outfitsRow
    this.setState({
      outfits
    })
    this.updateHistory()
  }

  async handleSource (e, value) {
    let user_ids = null
    if (value === null) {
      await this.setState({ user_ids: null, source: { username: '' } })
      localStorage.removeItem('ioeSource') // intelistyle outfit editor Source
      localStorage.removeItem('ioeUserIds')
    } else {
      await this.setState({ user_ids: value._id, source: value })
      localStorage.setItem('ioeSource', JSON.stringify(value))
      localStorage.setItem('ioeUserIds', JSON.stringify(value._id))
      user_ids = value._id
    }

    if (this.state.enableAdvancedSearchBrand) {
      this.getAllBrandsForUser(user_ids)
    }

    if (this.state.enableAdvancedSearchMarketId) {
      this.getMarketIds()
    }

    // Add replacement order sort by option if edit mode is shop_the_look
    let garmentNavigatorSortByEditOptions = JSON.parse(JSON.stringify(this.state.garmentNavigatorSortByEditOptions))
    garmentNavigatorSortByEditOptions = garmentNavigatorSortByEditOptions.filter(option => option !== 'replacement order')
    if (this.checkEditMode(this.getActiveOutfit()) === 'shop_the_look') {
      garmentNavigatorSortByEditOptions.push('replacement order')
    }
    this.setState({
      garmentNavigatorSortByEditOptions
    })
  }

  async handleCategory (e, value) {
    if (value === null) {
      await this.setState({ category: '' })
    } else {
      await this.setState({ category: value })
    }
  }

  async handleProductCategory (e, value) {
    if (value === null) {
      await this.setState({ productCategory: '' })
    } else {
      await this.setState({ productCategory: value })
    }
  }

  async handlePersona (e, value) {
    if (value === null) {
      await this.setState({ persona: '' })
    } else {
      await this.setState({ persona: value})
    }
  }

  async handleMarketIdChange (e, value) {
    if (value === null) {
      await this.setState({ marketId: '' })
    } else {
      await this.setState({ marketId: value })
    }
  }

  async handleBrandChange (e, value) {
    if (value === null) {
      await this.setState({ brand: '' })
    } else {
      await this.setState({ brand: value })
    }
  }

  async handleBrandGroupChange (e, value) {
    if (value === null) {
      await this.setState({ brandGroup: '' })
    } else {
      await this.setState({ brandGroup: value })
    }
  }

  async handleOutfitTypeChange (e, value) {
    if (value === null) {
      await this.setState({ outfitType: '' })
    } else {
      await this.setState({ outfitType: value })
    }
  }

  async handleMinPriceChange (event) {
    event.preventDefault()
    if (event.target.value === '') {
      const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
      priceValues.min = ''
      await this.setState({
        priceValues
      })
    } else {
      const minPrice = parseInt(event.target.value)

      if (!isNaN(minPrice)) {
        const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
        priceValues.min = minPrice
        await this.setState({
          priceValues
        })
      }
    }
  }

  async handleMinApprovalLevelShowChange (value) {
    await this.setState({
      minApprovalLevelShow: value
    })
  }

  async handleMaxApprovalLevelShowChange (value) {
    await this.setState({
      maxApprovalLevelShow: value
    })
  }

  async handleSelectedUserChange (e, value) {
    if (value === null) {
      await this.setState({ selectedUser: '' })
    } else {
      await this.setState({ selectedUser: value })
    }
  }

  async handleMaxPriceChange (event) {
    event.preventDefault()
    if (event.target.value === '') {
      const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
      priceValues.max = ''
      await this.setState({
        priceValues
      })
    } else {
      const maxPrice = parseInt(event.target.value)
      if (!isNaN(maxPrice)) {
        const priceValues = JSON.parse(JSON.stringify(this.state.priceValues))
        priceValues.max = maxPrice

        await this.setState({
          priceValues
        })
      }
    }
  }

  async handleSelectGarmentClick (garment) {
    const outfits = this.state.outfits.slice()

    // Need to maintain similar_data field if present in existing garment
    if (this.checkEditMode(outfits[this.state.editedGarmentIndex[0]]) === 'shop_the_look') {
      let editedGarment = outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]]
      if (editedGarment.similar_data !== undefined && editedGarment.similar_data !== null) {
        garment.similar_data = editedGarment.similar_data
      }
    }

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    if (this.state.productCategoryDropdown && this.state.searchPreselectProductCategory) {
      productCategories.forEach(productCategory => {
        productCategory.active = false
      })
    }

    outfits[this.state.editedGarmentIndex[0]].garments[this.state.editedGarmentIndex[1]] = garment
    this.setState({
      outfits,
      categoryIdToEdit: null,
      subcategoryIdToEdit: -1,
      productCategories: productCategories
    })
    this.updateHistory()
  }

  handleSelectOutfitClick (index) {
    const outfitSelected = this.state.outfitSelected.slice()
    outfitSelected[index] = !outfitSelected[index]
    this.setState({
      outfitSelected: outfitSelected
    })
  }

  async handleSetGender (genderValue) {
    await this.setState({gender: genderValue})
  }

  async handleSubmitAddGarmentClick (garment) {
    const outfits = this.state.outfits.slice()
    outfits[this.state.activeOutfit].garments.push(garment)

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    if (this.state.productCategoryDropdown && this.state.searchPreselectProductCategory) {
      productCategories.forEach(productCategory => {
        productCategory.active = false
      })
    }

    this.setState({
      outfits,
      addGarmentToOutfit: false,
      activeOutfit: null,
      productCategories: productCategories
    })
    this.updateHistory()
  }

  async getGarmentCategories () {
    const categories = await api.getCategories({ url: this.state.url })

    // Update category objects with active attribute
    categories.forEach((category) => (
      category.active = true
    ))

    await this.setState({
      allCategories: categories
    })
  }

  getQueryRestrictions () {
    let queryRestrictionsBase = {
      in_stock: true
    }
    const user_ids = this.state.user_ids
    if (user_ids) {
      queryRestrictionsBase.user_ids_restriction = user_ids
    } else {
      // Otherwise send allUsers from state rather than nothing
      queryRestrictionsBase.user_ids_restriction = this.state.allUsers.map(user => user._id)
    }

    // Add category_ids_restriction to query if required

    this.remote_garment_id_filter =  ''

    if (this.state.category) {
      if (this.state.allCategories.map(category => category.name).includes(this.state.category)) {
        queryRestrictionsBase.category_ids_restriction = this.state.allCategories
          .filter(category => category.name === this.state.category)
          .map(category => category._id)
      } else {
        this.remote_garment_id_filter =  this.state.category.toString()
        queryRestrictionsBase.remote_product_id = this.remote_garment_id_filter 
      }
    }

    if (this.state.productCategory) {
      if (this.state.productCategories.map(category => category.name).includes(this.state.productCategory)) {
        queryRestrictionsBase.remote_product_categories = this.state.allCategories
          .filter(category => category.name === this.state.productCategory)
          .map(category => category.name)
      } else {
        this.remote_garment_id_filter =  this.state.productCategory.toString()
        queryRestrictionsBase.remote_product_id = this.remote_garment_id_filter
      }
    }

    // Add gender to query if required
    if (this.state.gender) {
      queryRestrictionsBase.gender = this.state.gender
    }

    if (this.state.marketId) {
      queryRestrictionsBase.market_id = this.state.marketId.name
    }

    if (this.state.brand) {
      queryRestrictionsBase.brand_restriction = this.state.brand.name
    }

    if (this.state.priorityOnly) {
      queryRestrictionsBase.is_priority = true
    }

    let garmentRestrictions = []

    let inEditorDate = new Date()
    inEditorDate.setHours(inEditorDate.getHours() - 4)
    const user = JSON.parse(localStorage.getItem('user'))
    let inEditorRestrictions = [
      {
        in_outfit_editor: {
          exists: false
        }
      },
      {
        in_outfit_editor: {
          user_ids_restriction: user._id
        }
      },
      {
        in_outfit_editor: {
          user_ids_exclusion: user._id,
          updated_before: inEditorDate
        }
      }
    ]
    for (let inEditorRestriction of inEditorRestrictions) {
      let newGarmentRestrictions = Object.assign(
        JSON.parse(JSON.stringify(queryRestrictionsBase)),
        inEditorRestriction
      )
      garmentRestrictions.push(newGarmentRestrictions)
    }

    return garmentRestrictions
  }

  getOutfitRestrictions () {
    let outfitRestrictions = {
      min_approved: this.state.minApprovalLevelShow,
      max_approved: this.state.maxApprovalLevelShow,
      user_ids_restriction: this.getUserIdsRestriction()
    }

    if (this.state.outfitType) {
      outfitRestrictions.outfit_type = this.state.outfitType._id
    }

    if (this.state.brandGroup) {
      outfitRestrictions.brand_group_restriction = this.state.brandGroup._id
    }

    if (this.state.marketId) {
      outfitRestrictions.market_id = this.state.marketId.name
    }

    if (this.state.selectedUser) {
      outfitRestrictions.stylist_restriction = this.state.selectedUser.name
    }

    if (this.state.showApprovedOnly) {
      outfitRestrictions.approval_user_exists = true
    } else if (this.state.showUnapprovedOnly) {
      outfitRestrictions.approval_user_exists = false
    }

    if (this.state.persona) {
      if (this.state.allPersonas.map(persona => persona.name).includes(this.state.persona)) {
        outfitRestrictions.persona_restriction = this.state.allPersonas
          .filter(persona => persona.name === this.state.persona)
          .map(persona => persona._id)
      }
    }

    return outfitRestrictions
  }

  async getOutfits () {
    const data = {
      n_queries: 1,
      query_offset: this.state.skipValue,
      query_restrictions: this.getQueryRestrictions(),
      outfit_restrictions: this.getOutfitRestrictions(),
      cached: true,
      sample_outfits: false,
      return_garments: true,
      ignore_favourite_users: true,
      remove_keys: ['embedding'],  // To reduce the size of the response a bit - there may be more fields which can be removed
      n_outfits_per_query: this.state.numOutfitsToShow
    }

    // Show garments from H&M for Hugo Boss garments for demo purposes
    if (['641885c24cd63583c7e39eac'].includes(this.state.user_ids)) {
      data.outfit_generation_settings = {
        allow_cross_user_outfits: true
      }
    }

    if (this.state.sortBy) {
      data.sort_by = this.state.sortBy
    }

    data.url = this.state.url

    let res = await api.getOutfits(data)

    return res.data.garments
  }


  async getNewGarment () {
    await this.setState({ loading: true })
    this.clearHistory()
    // Set current query out of editor if required
    if (this.state.queryGarment) {
      await this.setGarmentInEditor(this.state.queryGarment._id, false)
    }

    // Get new garment
    let garments = await this.getOutfits()
    if (garments === undefined) {
      await this.setState({ queryGarment: null })
      this.setState({ loading: false })
      return
    }

    let queryGarment = null
    let currencyCode = ''
    let outfits = []
    let outfitSelected = []
    const allBrandGroups = JSON.parse(JSON.stringify(this.state.allBrandGroups))

    let garment = null
    if (garments.length > 0) {
      garment = garments[0]
    }else{
      // we dont have outfits for that garment..
      // and we have specified the product_id...
      if (this.remote_garment_id_filter != ''){
        // try to get the garment using the listGarmentsV3
        let searchResults = await api.listGarmentsV3(
          {
            garment_restrictions : this.getQueryRestrictions(),
            'use_cache' : 'true'
          }
        )
        // if garment is found:
        if (searchResults.garments.length > 0){
          garment = searchResults.garments[0]
          if(garment.outfits?.length > 0){
            garment = null
            console.log('Garment fetched from listGarments has outfit. This should not have happened.')
          }else{
            // add dummy outfit
            const id = new ObjectID()
            garment.outfits = [
              {
                '_user':null,
                'approval_user':null,
                'approved':0,
                'bodytype':[
                  
                ],
                'brand_group':null,
                'garments':[
                  
                ],
                'market_id':[
                  
                ],
                'occasion':[
                  
                ],
                'outfit_id':id,
                'outfit_image_url':null,
                'persona':[
                  
                ],
                'quality':null,
                'season':null,
                'skintone':[
                  
                ],
                'source':null,
                'styling_filters':[
                  
                ],
                'type':'complete_the_look',
                'weather':[
                  
                ]
              }
            ]
          }
        }
      }
    }
    if (garment) {
      // Update enabled brand groups and active brand group
      queryGarment = garment
      outfits = garment.outfits
      outfitSelected = garment.outfits.map(() => this.state.selectionDefault)
      currencyCode = queryGarment.currency_code
      // Update enabled brand groups and active brand group
      allBrandGroups.forEach(brandGroup => {
        brandGroup.enabled = brandGroup.gender === garment.gender
      })

      // Set garment in editor
      this.setGarmentInEditor(garment._id, true)
    }
    await this.setState({ queryGarment: null })
    await this.setState({
      queryGarment: queryGarment,
      outfits: outfits,
      outfitSelected: outfitSelected,
      allBrandGroups,
      currencyCode
    })
    this.setState({ loading: false })

    if (this.state.enableUsedProductIds) {
      this.getProductIdsInOutfits()
    }
    this.getProductCategories()  // Update here as this depends on the current query garment
    // console.log('the query garment is: ', this.state.queryGarment)
    this.updateHistory()
  }

  async getProductIdsInOutfits () {
    const body = {
      user_ids_restriction: this.state.user_ids,
      in_stock: true,
      approved: this.state.unusedItemsOutfitApprovalLevel,
      url: this.state.url
    }

    const usedProductIds = (await api.getProductIdsInOutfits(body)).data
    await this.setState({
      usedProductIds
    })
  }

  async getAllSubcategories () {
    const subcategories = (await api.getAllSubcategories({ url: this.state.url })).data

    const subcategoriesNames = subcategories.map(subcat => {
      return { _id: subcat._id, name: subcat.name }
    })
    // subcategoriesNames.unshift({ _id: -1, name: 'None' })

    // Update subcategory objects with active subcategory
    this.setState({
      subcategories: subcategoriesNames
    })
  }

  async getAttributes () {
    const attributes = (await api.getAttributes({url: this.state.url})).data
    this.setState({
      attributes
    })
  }

  async getAttributeCategories () {
    const attributeCategories = (await api.getAttributeCategories({url: this.state.url})).data
    this.setState({
      attributeCategories
    })
  }

  async getAttributeCategoryAttributeMap () {
    const attributeCategoryAttributeMap = (await api.getAttributeCategoryAttributeMap({url: this.state.url})).data
    this.setState({
      attributeCategoryAttributeMap
    })
  }

  async getVettingProgress () {
    this.setState({
      statsLoading: true
    })
    const stats = []
    const approvalLevels = [-3, 0, 1, 2]
    let body
    let i
    let index = 0
    for (i of approvalLevels) {
      i = parseInt(i)
      body = {
        user_ids_restriction: [this.state.user_ids],
        user_id: authClient.getUser()._id,
        approved: i,
        url: this.state.url
      }
      let approvalLevelStats = (await api.getOutfitVettingCount(body)).data.garments
      approvalLevelStats.approved = i
      stats[index] = approvalLevelStats
      index++
    }

    stats.push({
      approved: 'Total',
      approved_count: stats.filter(row => row.approved !== 0).map(row => row.approved_count).reduce((a, b) => a + b, 0),
      approved_user_count: stats.filter(row => row.approved !== 0).map(row => row.approved_user_count).reduce((a, b) => a + b, 0),
      approved_user_count_mapped: stats.filter(row => row.approved !== 0).map(row => row.approved_user_count_mapped).reduce((a, b) => a + b, 0),
      vetted_count: stats.filter(row => row.approved !== 0).map(row => row.vetted_count).reduce((a, b) => a + b, 0),
      vetted_user_count: stats.filter(row => row.approved !== 0).map(row => row.vetted_user_count).reduce((a, b) => a + b, 0),
      total_count: stats[0].total_count
    })

    // Update state
    this.setState({
      stats,
      statsLoading: false
    })
  }

  async hideUsedProductsOnClick () {
    const hideUsedProducts = !this.state.hideUsedProducts

    await this.setState({
      hideUsedProducts
    })

    await this.getAlternativeGarments()
  }

  async showSimilarProductsOnClick () {
    const showSimilarProducts = !this.state.showSimilarProducts

    await this.setState({
      showSimilarProducts
    })
  }

  redo () {
    if (this.state.outfitsHistory.length > 0 && this.state.queryGarment) {
      const historyIndex = this.state.historyIndex - 1
      if ((historyIndex >= 0) && (historyIndex <= this.state.outfitsHistory.length - 1)) {
        const outfits = JSON.parse(JSON.stringify(this.state.outfitsHistory[historyIndex]))
        this.setState({
          outfits,
          historyIndex
        })
      }
    }
  }

  async resetAll () {
    // Need to set any existing query garment out of editor
    if (this.state.queryGarment) {
      await this.setGarmentInEditor(this.state.queryGarment._id, false)
    }

    this.setState({
      gender: null,
      queryGarment: null,
      outfits: [],
      outfitsSelected: [],
      category: '',
      persona: '',
      source: null,
      user_ids: null
    })
  }

  async handleGarmentNavigatorResetFiltersClick () {
    const allBrandGroups = this.state.allBrandGroups.slice()
    allBrandGroups.forEach(brandGroup => { brandGroup.active = false })

    let productCategories = JSON.parse(JSON.stringify(this.state.productCategories))
    productCategories.forEach(productCategory => { productCategory.active = false })

    const priceValues = JSON.parse(JSON.stringify(this.state.priceRange))

    await this.setState({
      categoryIdToEdit: -1,
      subcategoryIdToEdit: -1,
      colourIdToEdit: -1,
      coloursActive: [],
      allBrandGroupsSelected: false,
      productCategorySearchTerm: '',
      brandSearchValue: '',
      priceValues,
      garmentNavigatorPage: 1,
      garmentNavigatorBrandRestriction: null,
      garmentNavigatorBrandExclusion: null,
      allBrandGroups,
      productCategories
    })
    await this.getAlternativeGarments()
  }

  async setGarmentInEditor (_id, inEditor) {
    await api.setGarmentInEditor(_id, inEditor, 'outfit', { url: this.state.url })
  }

  async onCategoryDropdownChange (value) {
    if (value === null) {
      await this.setState({ categoryIdToEdit: -1 })
    } else {
      await this.setState({ categoryIdToEdit: value._id })
    }
  }

  async onSubcategoryDropdownChange (value) {
    if (value === null) {
      await this.setState({ subcategoryIdToEdit: -1 })
    } else {
      await this.setState({ subcategoryIdToEdit: value._id })
    }
  }

  async onColourDropdownChange (e, value) {
    const garmentType = value._id

    const selectedColours = mappedColours.filter(el => { return el._id === garmentType }).map(el => { return el.colours })[0]
    await this.setState({ colourIdToEdit: garmentType, coloursActive: selectedColours })
  }

  async onPageSelectChange (event) {
    event.preventDefault()
    if (event.target.value === '') {
      await this.setState({
        garmentNavigatorPage: ''
      })
    } else {
      const garmentNavigatorPage = parseInt(event.target.value)
      if (!isNaN(garmentNavigatorPage) && garmentNavigatorPage !== 0) {
        if (garmentNavigatorPage !== this.state.garmentNavigatorPage) {
          await this.setState({
            garmentNavigatorPage
          })

          await this.getAlternativeGarments()
        }
      }
    }
  }

  updateRelativePriceMaxMinValue = (dropdownCategory) => {
    let outfitIndex = this.state.editedGarmentIndex[0]
    let outfitMarketId = this.state.outfits[outfitIndex].market_id
    let outfitVariantData = this.state.queryGarment.variant_data[0]

    if (outfitMarketId.length !== 0) {
      outfitVariantData = this.state.queryGarment.variant_data.filter(variant => variant.market_id === outfitMarketId[0])[0]
    }

    // NOTE: currently relative pricing works without discount. Once it will use discount update to:
    // let garment_price = outfitVariantData.price * (1 - outfitVariantData.discount)

    let garment_price = outfitVariantData.price
    let garment_category = this.state.queryGarment.category_data._id
    let matchingPriceRule = null

    for (let category_rules in priceRules) {
      if (JSON.parse(category_rules).includes(garment_category)) {
        let rule = priceRules[category_rules]
        for (let price_rule in rule) {
          if (rule[price_rule].outfit_item.includes(dropdownCategory)) {
            matchingPriceRule = rule[price_rule]
            break
          }
        }
      }
    }

    if (matchingPriceRule) {
      this.setState({
        priceValues: {
          min: Math.floor(garment_price * matchingPriceRule.min_price),
          max: Math.floor(garment_price * matchingPriceRule.max_price)
        }
      })
    }
  }

  async onRelativePriceFilterChange (isRelativePriceFilterActive, dropdownCategory) {
    if (isRelativePriceFilterActive) {
      this.updateRelativePriceMaxMinValue(dropdownCategory._id)
    } else {
      this.setState({
        priceValues: {
          min: 0,
          max: 100000
        }
      })
    }
    await this.getAlternativeGarments()
  }

  getBrandRestrictionsBasedOnCategory = (outfit_garments, query_garment, garmentNavigatorCategoryId, garmentNavigatorSubategoryId) => {
    let updated_outfit_garments = JSON.parse(JSON.stringify(outfit_garments))
    updated_outfit_garments.push(query_garment)

    if (updated_outfit_garments.some((garment) => garment['garmentType'] === garmentNavigatorCategoryId)) {
      let garmentNavigatorIndex = updated_outfit_garments.findIndex(item => item.garmentType === garmentNavigatorCategoryId)
      updated_outfit_garments.splice(garmentNavigatorIndex, 1)
    }

    let categoryBrandDict = Object.fromEntries(updated_outfit_garments.map(garment => [garment.garmentType, garment.remote_brand]))
    let subcateoryBrandDict = Object.fromEntries(updated_outfit_garments.map(garment => [garment.subcategory_data[0]._id, garment.remote_brand]))
    let ruleRestrictions = [
      {
        'ruleType': 'garmentType',
        'valueBrandDict': categoryBrandDict
      },
      {
        'ruleType': 'subcategories',
        'valueBrandDict': subcateoryBrandDict
      }
    ]

    let brandRestrictions = []
    let brandExclusion = []

    ruleRestrictions.forEach((ruleRestriction) => {
      let brandRestrictionsExclusion = this.getBrandRestrictionsAndExclusion(
        ruleRestriction['ruleType'],
        garmentNavigatorCategoryId,
        garmentNavigatorSubategoryId,
        ruleRestriction['valueBrandDict']
      )
      if (brandRestrictionsExclusion[0].length > 0) {
        brandRestrictions.push(brandRestrictionsExclusion[0])
      }
      brandExclusion.push(...brandRestrictionsExclusion[1])
    })

    brandRestrictions = this.getBrandIntersection(brandRestrictions)
    brandExclusion = [...new Set(brandExclusion)]
    brandExclusion = brandExclusion.filter((brand) => !brandRestrictions.includes(brand))

    return [brandRestrictions, brandExclusion]
  }

  getBrandRestrictionsAndExclusion = (ruleType, garmentNavigatorCategoryId, garmentNavigatorSubategoryId, valueBrandDict) => {
    let brandRestrictions = []
    let brandExclusion = []

    brand_category_restrictions.filter(outfitRule => outfitRule.type === ruleType).forEach(function(outfitRule) {
      let restriction_groups = outfitRule.rules

      // Store the restriction group that contains the category of edited garment
      let ruleWithUpdatedGarment = undefined

      // Store the restriction group that contains any of the outfit's category (except the category of edited garment)
      let ruleWithRestOfGarments = undefined

      for (let restriction_group of restriction_groups) {
        const hasCategoryValue = restriction_group.value.some(val => valueBrandDict.hasOwnProperty(val))
        const hasUpdatedCategoryValue = (ruleType === 'garmentType' && restriction_group.value.includes(garmentNavigatorCategoryId)) ||
                                (ruleType === 'subcategories' && restriction_group.value.includes(garmentNavigatorSubategoryId))

        if (!ruleWithRestOfGarments && hasCategoryValue) {
          if (!hasUpdatedCategoryValue || restriction_groups.length === 1) {
            ruleWithRestOfGarments = restriction_group
          }
        }

        if (!ruleWithUpdatedGarment) {
          if (hasUpdatedCategoryValue) {
            ruleWithUpdatedGarment = restriction_group
          }
        }

        if (ruleWithRestOfGarments && ruleWithUpdatedGarment) {
          if (restriction_groups.length === 1 || ruleWithRestOfGarments !== ruleWithUpdatedGarment) {
            const isRestOfGarmentsRestricted = ruleWithRestOfGarments.value.some(val => valueBrandDict.hasOwnProperty(val) && ruleWithRestOfGarments.group_value.includes(valueBrandDict[val]))
            if (outfitRule.restriction_value === 'must_go_together') {
              if (isRestOfGarmentsRestricted && !brandRestrictions.some(item => JSON.stringify(item) === JSON.stringify(ruleWithUpdatedGarment.group_value))) {
                brandRestrictions.push(ruleWithUpdatedGarment.group_value)
              } else {
                brandExclusion.push(...ruleWithUpdatedGarment.group_value)
              }
            } else if (isRestOfGarmentsRestricted) {
              brandExclusion.push(...ruleWithUpdatedGarment.group_value)
            }
          }
        }
      }
    })

    brandRestrictions = this.getBrandIntersection(brandRestrictions)
    brandExclusion = [...new Set(brandExclusion)]

    return [brandRestrictions, brandExclusion]
  }

  getBrandIntersection = (lists) => {
    if (lists.length === 0) {
      return []
    }
    if (lists.length === 1) {
      return lists[0]
    }
    const [first, ...rest] = lists
    const common = first.filter((value) => {return rest.every((list) => list.includes(value))})

    return [...new Set(common)]
  }

  async onItemsPerPageChange (event) {
    event.preventDefault()
    if (event.target.value === '') {
      await this.setState({
        itemsPerPage: ''
      })
    } else {
      let itemsPerPage = parseInt(event.target.value)
      if (!isNaN(itemsPerPage) && itemsPerPage !== 0) {
        itemsPerPage = Math.min(itemsPerPage, this.state.itemsPerPageMax)

        if (itemsPerPage !== this.state.itemsPerPage) {
          await this.setState({
            itemsPerPage
          })

          await this.getAlternativeGarments()
        }
      }
    }
  }

  async onSelectAllBrandGroups () {
    const allBrandGroupsSelected = !this.state.allBrandGroupsSelected

    // Update categories active property
    const allBrandGroups = this.state.allBrandGroups.slice()
    allBrandGroups.forEach(brandGroup => (brandGroup.active = allBrandGroupsSelected))

    this.setState({
      allBrandGroups,
      allBrandGroupsSelected
    })
  }

  async onStatsClick () {
    // Show stats modal
    this.setShowStats(true)

    // Retrieve stats
    await this.getVettingProgress()
  }

  async onSkipClick () {
    if (this.state.queryGarment) {
      await this.setState(prevState => { return {
        skipValue: prevState.skipValue + 1
      }})

      await this.getNewGarment()
    }
  }

  async onApplyClick () {
    await this.setState({
      skipValue: 0
    })

    await this.getNewGarment()
  }

  async setShowStats (showStats) {
    await this.setState({
      showStats
    })
  }

  undo () {
    if (this.state.outfitsHistory.length > 0 && this.state.queryGarment) {
      const historyIndex = this.state.historyIndex + 1
      if ((historyIndex >= 0) && (historyIndex <= this.state.outfitsHistory.length - 1)) {
        // Take outfits from outfitsHistory with the current historyIndex
        const outfits = JSON.parse(JSON.stringify(this.state.outfitsHistory[historyIndex]))
        this.setState({
          outfits,
          historyIndex
        })
      }
    }
  }

  async handleGarmentNavigatorSortByChange (option) {
    // Update sort by option
    if (this.state.addGarmentToOutfit) {
      await this.setState({
        garmentNavigatorSortByAdd: option,
        garmentNavigatorPage: 1
      })
    } else {
      await this.setState({
        garmentNavigatorSortByEdit: option,
        garmentNavigatorPage: 1
      })
    }
    // Automatically search for new garments
    await this.getAlternativeGarments()
  }

  handleGarmentNavigatorTextSearchChange (event) {
    this.setState({
      productCategorySearchTerm: event.target.value
    })
  }

  handleGarmentNavigatorBrandTextSearchChange (event) {
    this.setState({
      brandSearchValue: event.target.value
    })
  }

  handleUpdateGarmentNavigatorSettings (settings) {
    this.setState({
      garmentNavigatorSettings: settings
    })
  }

  selectColour = async (colour) => {
    let searchColours = JSON.parse(JSON.stringify(this.state.searchColours))
    if (searchColours.includes(colour)) {
      searchColours = searchColours.filter(e => e !== colour)
    } else {
      searchColours.push(colour)
    }
    await this.setState({ searchColours })
    await this.getAlternativeGarments()
  }

  colourFilterOpen = () => {
    this.setState({ colourFilterShow: !this.state.colourFilterShow })
  }

  colourFilterClear = () => {
    this.setState({ searchColours: [] })
  }

  setEmbeddingComponentsWeight (weight) {
    this.setState({
      embeddingComponentsWeight: weight
    })
  }

  setSubcategoriesWeight (weight) {
    this.setState({
      subcategoriesWeight: weight
    })
  }

  setAttributesWeight (weight) {
    this.setState({
      attributesWeight: weight
    })
  }

  setAttributeCategoriesWeights (weights) {
    this.setState({
      attributeCategoriesWeights: weights
    })
  }

  getGarmentNavigatorSortByOptions (outfit) {
    // Add replacement order sort by option if edit mode is shop_the_look
    let garmentNavigatorSortByEditOptions = JSON.parse(JSON.stringify(this.state.garmentNavigatorSortByEditOptions))
    garmentNavigatorSortByEditOptions = garmentNavigatorSortByEditOptions.filter(option => option !== 'replacement order')
    if (outfit === undefined) {
      outfit = this.getActiveOutfit()
    }
    if (this.checkEditMode(outfit) === 'shop_the_look') {
      garmentNavigatorSortByEditOptions.push('replacement order')
    }
    return garmentNavigatorSortByEditOptions
  }

  handleShowApprovedOnlyChange () {
    this.setState(prevState => {
      return {
        showApprovedOnly: !prevState.showApprovedOnly
      }
    })
  }

  handleShowUnapprovedOnlyChange () {
    this.setState(prevState => {
      return {
        showUnapprovedOnly: !prevState.showUnapprovedOnly
      }
    })
  }

  handleSelectionDefaultChange () {
    this.setState(prevState => {
      return {
        selectionDefault: !prevState.selectionDefault
      }
    })
  }

  handlePriorityOnlyChange () {
    this.setState(prevState => {
      return {
        priorityOnly: !prevState.priorityOnly
      }
    })
  }

  async componentDidMount() {
    if (this.state.url === null) {
      this.props.history.push('/admin/IntroPageOutfitEditor')
      return
    }
    await this.getUsers()

    await Promise.all([
      this.getGarmentCategories(),
      this.getAllSubcategories(),
      this.getBrandGroups(),
      this.getProductCategories(),
      this.getAttributes(),
      this.getAttributeCategories(),
      this.getAttributeCategoryAttributeMap(),
      this.getAllOccasions(),
      this.getAllPersonas(),
      this.getAllVettingUsers()
    ])

    if (this.state.enableAdvancedSearchMarketId) {
      await this.getMarketIds()
    }

    // Set up mousetrap keyboard shortcuts. If changing these remember to unbind
    // them in componentWillUnmount()
    Mousetrap.bind('ctrl+z', this.undo.bind(this))
    Mousetrap.bind('ctrl+y', this.redo.bind(this))
    Mousetrap.bind('ctrl+enter', this.handleApproveClick.bind(this))
    Mousetrap.bind('ctrl+right', this.onSkipClick.bind(this))
  }

  async componentWillUnmount() {
    Mousetrap.unbind('ctrl+z')
    Mousetrap.unbind('ctrl+y')
    Mousetrap.unbind('ctrl+enter')
    Mousetrap.unbind('ctrl+right')
  }

  render () {
    const { classes } = this.props
    let editMode = this.checkEditMode(this.getActiveOutfit() ? this.getActiveOutfit() : this.state.outfits ? this.state.outfits[0] : undefined)
    return (
      <div className={this.state.showGarmentNavigator ? classes.rootBlurred : classes.root}>
        <OutfitStats
          show={this.state.showStats}
          onClose={() => this.setShowStats(false)}
          loading={this.state.statsLoading}
          stats={this.state.stats}
        />
        <GarmentNavigator
          source={this.state.source}
          show={this.state.showGarmentNavigator}
          onClose={this.handleCloseGarmentNavigator.bind(this)}
          searchColours={this.state.searchColours}
          colourFilterShow={this.state.colourFilterShow}
          colourFilterOpen={this.colourFilterOpen}
          selectColour={this.selectColour}
          colourFilterClear={this.colourFilterClear}
          addGarmentToOutfit={this.state.addGarmentToOutfit}
          queryGarment={this.state.queryGarment}
          queryOutfit={![null, undefined].includes(this.state.activeOutfit) && this.state.outfits[this.state.activeOutfit]}
          garments={this.state.alternativeGarments}
          pageNumber={this.state.garmentNavigatorPage}
          onNextGarmentPageClick={this.handleNextGarmentPageClick.bind(this)}
          onPrevGarmentPageClick={this.handlePrevGarmentPageClick.bind(this)}
          onSelectGarmentClick={this.handleSelectGarmentClick.bind(this)}
          onAddGarmentClick={this.handleSubmitAddGarmentClick.bind(this)}
          onCategoryDropdownChange={this.onCategoryDropdownChange.bind(this)}
          onSubcategoryDropdownChange={this.onSubcategoryDropdownChange.bind(this)}
          onColourDropdownChange={this.onColourDropdownChange.bind(this)}
          categories={this.state.allCategories}
          currencyCode={this.state.currencyCode}
          subcategories={this.state.subcategories}
          categoryIdToEdit={this.state.categoryIdToEdit}
          subcategoryIdToEdit={this.state.subcategoryIdToEdit}
          handleResetFiltersClick={this.handleGarmentNavigatorResetFiltersClick.bind(this)}
          handleSearchInGarmentNavigator={this.handleSearchInGarmentNavigator}
          colours={this.state.colours}
          colourIdToEdit={this.state.colourIdToEdit}
          productCategorySearchTerm={this.state.productCategorySearchTerm}
          brandSearchValue={this.state.brandSearchValue}
          onPageSelectChange={this.onPageSelectChange.bind(this)}
          itemsPerPage={this.state.itemsPerPage}
          onRelativePriceFilterChange={this.onRelativePriceFilterChange.bind(this)}
          onItemsPerPageChange={this.onItemsPerPageChange.bind(this)}
          allBrandGroups={this.state.allBrandGroups}
          onBrandGroupDropdownChange={this.handleBrandGroupDropdownChange.bind(this)}
          allBrandGroupsSelected={this.state.allBrandGroupsSelected}
          onSelectAllBrandGroups={this.onSelectAllBrandGroups.bind(this)}
          productCategories={this.state.productCategories}
          onProductCategoryDropdownChange={this.handleProductCategoryDropdownChange.bind(this)}
          priceValues={this.state.priceValues}
          handleMinPriceChange={this.handleMinPriceChange.bind(this)}
          handleMaxPriceChange={this.handleMaxPriceChange.bind(this)}
          usedProductIds={this.state.usedProductIds}
          hideUsedProducts={this.state.hideUsedProducts}
          hideUsedProductsOnClick={() => this.hideUsedProductsOnClick()}
          hideUsedControl={editMode !== 'shop_the_look' && this.state.enableUsedProductIds}
          sortBy={this.state.addGarmentToOutfit ? this.state.garmentNavigatorSortByAdd : this.state.garmentNavigatorSortByEdit}
          disableSearch={this.state.garmentNavigatorLoading}
          sortByOptions={this.state.addGarmentToOutfit ? this.state.garmentNavigatorSortByAddOptions : this.state.garmentNavigatorSortByEditOptions}
          handleSortByChange={this.handleGarmentNavigatorSortByChange.bind(this)}
          handleTextSearchChange={this.handleGarmentNavigatorTextSearchChange.bind(this)}
          handleBrandTextSearchChange={this.handleGarmentNavigatorBrandTextSearchChange.bind(this)}
          totalPages={this.state.garmentNavigatorTotalPages}
          loading={this.state.garmentNavigatorLoading}
          updateReplacementOrder={this.updateReplacementOrder.bind(this)}
          moveGarment={this.moveAlternativeGarment.bind(this)}
          findGarment={this.findAlternativeGarment.bind(this)}
          handleAddGarmentToReplacementOrderClick={this.handleAddGarmentToReplacementOrderClick.bind(this)}
          handleRemoveGarmentFromReplacementOrderClick={this.handleRemoveGarmentFromReplacementOrderClick.bind(this)}
          editMode={this.checkEditMode(this.getActiveOutfit())}
          productCategoriesDropdown={this.state.productCategoryDropdown}
          enableSettings={this.state.enableGarmentNavigatorSettings}
          settings={this.state.garmentNavigatorSettings}
          handleUpdateSettings={this.handleUpdateGarmentNavigatorSettings.bind(this)}
          attributes={this.state.attributes}
          attributeCategories={this.state.attributeCategories}
          attributeCategoryAttributeMap={this.state.attributeCategoryAttributeMap}
          embeddingComponentsWeight={this.state.embeddingComponentsWeight}
          setEmbeddingComponentsWeight={this.setEmbeddingComponentsWeight.bind(this)}
          subcategoriesWeight={this.state.subcategoriesWeight}
          setSubcategoriesWeight={this.setSubcategoriesWeight.bind(this)}
          attributesWeight={this.state.attributesWeight}
          setAttributesWeight={this.setAttributesWeight.bind(this)}
          attributeCategoriesWeights={this.state.attributeCategoriesWeights}
          setAttributeCategoriesWeights={this.setAttributeCategoriesWeights.bind(this)}
          handleShortcut1Click={this.handleShortcut1Click.bind(this)}
          handleSearchShortcutClick={this.handleSearchShortcutClick.bind(this)}
          handleClearSearchShortcutClick={this.handleClearSearchShortcutClick.bind(this)}
          showShortcuts={this.state.enableGarmentNavigatorShortcuts}
          cachedVisualSimilarity={this.state.cachedVisualSimilarity}
        />
        <OutfitEditorFilterBar
          styling={classes}
          applyEnabled={!this.state.loading}
          gender={this.state.gender}
          users={this.state.allUsers}
          source={this.state.source}
          handleSource={this.handleSource}
          productCategoryDropdownMain={this.state.productCategoryDropdownMain}
          categories={this.state.allCategories}
          productCategories={this.state.productCategories}
          category={this.state.category}
          handleCategory={this.handleCategory}
          handleProductCategory={this.handleProductCategory}
          personas={this.state.allPersonas}
          person={this.state.person}
          handlePersona={this.handlePersona}
          setGender={this.handleSetGender.bind(this)}
          marketId={this.state.marketId}
          marketIds={this.state.marketIds}
          handleMarketIdChange={this.handleMarketIdChange}
          brand={this.state.brand}
          brands={this.state.allBrands}
          handleBrandChange={this.handleBrandChange}
          brandGroup={this.state.brandGroup}
          brandGroups={this.state.allBrandGroups.filter(brandGroup => brandGroup.group === this.state.source?.username)}
          handleBrandGroupChange={this.handleBrandGroupChange}
          outfitType={this.state.outfitType}
          outfitTypes={this.state.allOutfitTypes}
          handleOutfitTypeChange={this.handleOutfitTypeChange}
          resetAll={this.resetAll.bind(this)}
          onApplyClick={this.onApplyClick.bind(this)}
          enableAdvancedSearch={this.state.enableAdvancedSearch}
          enableMarketId={this.state.enableAdvancedSearchMarketId}
          enableBrand={this.state.enableAdvancedSearchBrand}
          enableShowApprovedControls={this.state.enableShowApprovedControls}
          approvedOnly={this.state.approvedOnly}
          handleShowApprovedOnlyChange={this.handleShowApprovedOnlyChange.bind(this)}
          showUnnapprovedOnly={this.state.showUnapprovedOnly}
          handleShowUnapprovedOnlyChange={this.handleShowUnapprovedOnlyChange.bind(this)}
          selectionDefault={this.state.selectionDefault}
          handleSelectionDefaultChange={this.handleSelectionDefaultChange.bind(this)}
          priorityOnly={this.state.priorityOnly}
          handlePriorityOnlyChange={this.handlePriorityOnlyChange.bind(this)}
          minApprovalLevelShow={this.state.minApprovalLevelShow}
          handleMinApprovalLevelShowChange={this.handleMinApprovalLevelShowChange.bind(this)}
          maxApprovalLevelShow={this.state.maxApprovalLevelShow}
          handleMaxApprovalLevelShowChange={this.handleMaxApprovalLevelShowChange.bind(this)}
          allVettingUsers={this.state.allVettingUsers}
          selectedUser={this.state.selectedUser}
          handleSelectedUserChange={this.handleSelectedUserChange.bind(this)}
        />
        <OutfitEditorActionBar
          styling={classes}
          queryGarment={this.state.queryGarment}
          approveEnabled={!this.state.loading}
          outfitSelected={this.state.outfitSelected}
          undo={this.undo.bind(this)}
          redo={this.redo.bind(this)}
          onSkipClick={this.onSkipClick.bind(this)}
          onStatsClick={this.onStatsClick.bind(this)}
          handleApproveClick={this.handleApproveClick.bind(this)}
        />
        {
          this.state.loading
            ? <div className='outfit-loading-spinner'>
              <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div> :
            <div className={classes.contentContainer}>
              {
                this.state.queryGarment ?
                  <div className={classes.mainContent}>
                    <div className={`${classes.sidebar}`} style={{ paddingTop: 0, height: 'auto' }}>
                      <MainGarment garment={this.state.queryGarment} editMode={editMode} width='auto' position='sticky' tight top='180px' />
                    </div>
                    <div className='outfit-editor-outfits-container'>
                      <div className='card-column outfit-editor-cards'>
                        {
                          this.state.outfits == null ? <Spinner />
                            : this.state.outfits.map((outfit, index) => (
                              <div className={classes.outfitContainer} key={index}>
                                <Outfit
                                  editable
                                  key={index}
                                  id={index}
                                  outfit={outfit}
                                  outfitNumber={index + 1}
                                  outfitGarmentClassName={classes.outfitGarmentClassName}
                                  imageCarouselClassName={classes.imageCarouselClassName}
                                  checked={this.state.outfitSelected[index]}
                                  checkboxOnClick={() => (this.handleSelectOutfitClick(index))}
                                  addOnClick={() => (this.handleAddGarmentClick(index))}
                                  garmentEditOnClick={this.handleEditGarmentClick.bind(this)}
                                  garmentDeleteOnClick={this.handleDeleteGarmentClick.bind(this)}
                                  brandGroup={this.state.allBrandGroups.filter((brandGroup) => (brandGroup.gender === this.state.queryGarment.gender && parseInt(brandGroup._id) === outfit.brand_group))}
                                  enableUsedProductIds={this.state.enableUsedProductIds}
                                  usedProductIds={this.state.usedProductIds}
                                  allOccasions={this.state.allOccasions}
                                  allPersonas={this.state.allPersonas}
                                  showApprovalUser={this.state.showApprovalUser}
                                />
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </div> :
                  <div className={classes.placeHolder}>
                    <div className={classes.noOutfitsText}>To start creating the outfits please fill in the filters and tap APPLY.</div>
                    <img alt='placeholderPic' className={classes.placeholderPic} src={fashionableClothes} />
                  </div>
              }
            </div>

        }
      </div>
    )
  }
}

export default withStyles(style)(OutfitEditor)
