import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import nextIcon from './nextIcon.svg'


const useStyle = makeStyles({
  arrowContainer: {
    position: 'absolute',
    top: '0%',
    left: '2px',
    height: '100%',
    width: '22px',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none'
  },
  arrow: {
    height: '22px',
    width: '22px',
    margin: 'auto',
    transform: 'scaleX(-1)',
    zIndex: 1,
    cursor: 'pointer',
    pointerEvents: 'all'
  }
})

function PrevArrow(props) {
  const classes = useStyle()

  return (
    <div className={classes.arrowContainer}>
      <div
        className={classes.arrow}
        onClick={props.onClick}
      >
        <img alt="prev icon" src={nextIcon}/>
      </div>
    </div>
  )
}

export default PrevArrow
