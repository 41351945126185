import React from 'react'
import useMousePosition from '../../context/useMousePosition'

import CursorPreview from './CursorPreview'

const CustomCursor = () => {
  const { x, y } = useMousePosition()
  return <CursorPreview x={x} y={y} />
}

export default CustomCursor
