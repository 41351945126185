import React, { useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import GenderSelect from '../../components/GenderSelect/GenderSelect'
import OutfitEditorAdvancedFilterBar from './OutfitEditorAdvancedFilterBar'
import FilterListIcon from '@material-ui/icons/FilterList'

export default function OutfitEditorFilterBar(props) {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)

  const classes = props.styling

  return (
    <div>
      <div className={classes.ruleBar}>
        <div className={classes.source}>
          <p className={classes.text}>Source</p>
          <Autocomplete
            className={classes.sourceSelect}
            id='source-select'
            options={props.users}
            getOptionLabel={option => option.username}
            getOptionSelected={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
            onChange={props.handleSource}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select source'
                margin='normal'
                fullWidth
              />
            )}
            value={props.source}
            renderOption={(option, { inputValue }) => {
              const matches = match(option.username, inputValue)
              const parts = parse(option.username, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        { !props.productCategoryDropdownMain ?
          <div className={classes.category}>
            <p className={classes.text}>Category or Id</p>
            <Autocomplete
              freeSolo
              className={classes.categorySelect}
              id='category-select'
              options={props.categories.map(option => option.name)}
              getOptionLabel={(option) => {
                if (option === '') {
                  return ''
                } else {
                  return option
                }
              }}
              limitTags={2}
              onInputChange={props.handleCategory}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Select category or product id'
                  margin='normal'
                  fullWidth
                />
              )}
              value={props.category}
              renderOption={(option, { inputValue }) => {
                const matches = match(option, inputValue)
                const parts = parse(option, matches)

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                )
              }}
            />
          </div>
          :
          <div className={classes.category}>
            <p className={classes.text}>Category or Id</p>
            <Autocomplete
              freeSolo
              className={classes.categorySelect}
              id='category-select'
              options={props.productCategories.map(option => option.name)}
              getOptionLabel={(option) => {
                if (option === '') {
                  return ''
                } else {
                  return option
                }
              }}
              limitTags={2}
              onInputChange={props.handleProductCategory}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  label=''
                  placeholder='Select category or product id'
                  margin='normal'
                  fullWidth
                />
              )}
              value={props.productCategory}
              renderOption={(option, { inputValue }) => {
                const matches = match(option, inputValue)
                const parts = parse(option, matches)

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                )
              }}
            />
          </div>
        }
        <div className={classes.source}>
          <p className={classes.text}>Persona</p>
          <Autocomplete
            className={classes.sourceSelect}
            id='persona-select'
            options={[...new Set(props.personas.filter(option => option._id < 32).map(option => option.name))]}
            getOptionLabel={(option) => {
              if (option === '') {
                return ''
              } else {
                return option
              }
            }}
            getOptionSelected={(a, b) => JSON.stringify(a) === JSON.stringify(b)}
            onChange={props.handlePersona}
            renderInput={params => (
              <TextField
                {...params}
                variant='outlined'
                label=''
                placeholder='Select persona'
                margin='normal'
                fullWidth
              />
            )}
            value={props.persona}
            renderOption={(option, { inputValue }) => {
              const matches = match(option, inputValue)
              const parts = parse(option, matches)

              return (
                <div>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                </div>
              )
            }}
          />
        </div>
        <GenderSelect
          gender={props.gender}
          setGender={props.setGender}
          styling={props.styling}
        />
        {
          props.enableAdvancedSearch &&
        <div
          className={`${classes.advancedSearchButton} ${classes.buttonHover}`}
          onClick={() => {setShowAdvancedSearch(prevState => !prevState)}}
        >
          <FilterListIcon/>
        </div>
        }
        <div className={`${classes.reset} ${classes.buttonHover}`} onClick={props.resetAll}>RESET ALL</div>
        <div className={`${props.applyEnabled ? classes.applyButton : classes.applyButtonDisabled}`} onClick={() => props.onApplyClick()}>
          <p className={classes.applyText}>APPLY</p>
        </div>
      </div>
      <OutfitEditorAdvancedFilterBar
        show={showAdvancedSearch}
        enableMarketId={props.enableMarketId}
        marketId={props.marketId}
        marketIds={props.marketIds}
        handleMarketIdChange={props.handleMarketIdChange}
        enableBrand={props.enableBrand}
        brand={props.brand}
        brands={props.brands}
        handleBrandChange={props.handleBrandChange}
        brandGroup={props.brandGroup}
        brandGroups={props.brandGroups}
        handleBrandGroupChange={props.handleBrandGroupChange}
        outfitType={props.outfitType}
        outfitTypes={props.outfitTypes}
        enableShowApprovedControls={props.enableShowApprovedControls}
        handleOutfitTypeChange={props.handleOutfitTypeChange}
        approvedOnly={props.approvedOnly}
        handleShowApprovedOnlyChange={props.handleShowApprovedOnlyChange}
        unapprovedOnly={props.unapprovedOnly}
        handleShowUnapprovedOnlyChange={props.handleShowUnapprovedOnlyChange}
        selectionDefault={props.selectionDefault}
        handleSelectionDefaultChange={props.handleSelectionDefaultChange}
        priorityOnly={props.priorityOnly}
        handlePriorityOnlyChange={props.handlePriorityOnlyChange}
        minApprovalLevelShow={props.minApprovalLevelShow}
        handleMinApprovalLevelShowChange={props.handleMinApprovalLevelShowChange}
        maxApprovalLevelShow={props.maxApprovalLevelShow}
        handleMaxApprovalLevelShowChange={props.handleMaxApprovalLevelShowChange}
        allVettingUsers={props.allVettingUsers}
        selectedUser={props.selectedUser}
        handleSelectedUserChange={props.handleSelectedUserChange}
      />
    </div>
  )
}
