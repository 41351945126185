import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Switch, Route, NavLink, Redirect, useRouteMatch } from 'react-router-dom'
import Campaigns from './CampaignsMaster/CampaignsMaster.jsx'
import ReservationMain from './ReservationMain.jsx'
import ChatbotAudience from './ChatbotAudience/ChatbotAudience.jsx'
import ChatbotMessaging from './ChatbotMessaging.jsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  header: {
    maxWidth: '1200px',
    width: '100%',
    display: 'inline-block',
    // marginLeft: '38px',
    marginBottom: '17px !important'
  },
  root: {
    flexGrow: 1
  },
  itemActive: {
    // height: '24px',
    // width: '114px',
    '& $hr': {
      backgroundColor: '#FF6008'
    },
    color: '#000000 !important',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    fontWeight: '600',
    lineHeight: '24px'
  },
  lEdgeItem: {
    marginLeft: '0',
    marginRight: 'auto'
  },
  rEdgeItem: {
    marginLeft: 'auto',
    marginRight: '0'
  },
  innerItem: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  itemInactive: {
    '&:hover $hr': {
      width: '80%',
      backgroundColor: 'black',
      marginTop: '20px !important',
      margin: '0 auto !important'
    },
    color: '#666666 !important',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    letterSpacing: '0.5px',
    lineHeight: '24px'
  },
  hr: {
    height: '2px',
    width: '100%',
    padding: '0 !important',
    background: '#EAEBED',
    overflow: 'hidden',
    borderStyle: 'inset',
    // borderWidth: '1px',
    marginTop: '20px !important',
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
  }
}))

export default function ChatbotMain () {
  const { url } = useRouteMatch()

  function activeRoute (routeName) {
    return window.location.href.split('/').indexOf(routeName) > -1
  }

  const classes = useStyles()
  const demoRoutes = [
    {
      path: '/campaigns',
      name: 'Campaigns',
      component: Campaigns,
      layout: '/admin'
    },
    {
      path: '/reservationMain',
      name: 'Reservations',
      component: ReservationMain,
      layout: '/admin'
    },
    {
      path: '/chatbotAudience',
      name: 'Audience',
      component: ChatbotAudience,
      layout: '/admin'
    },
    {
      path: '/chatbotMessaging',
      name: 'Messaging',
      component: ChatbotMessaging,
      layout: '/admin'
    }
  ]

  const switchRoutes = (
    <Switch>
      {demoRoutes.map((prop, key) => {
        if (prop.layout === '/admin') {
          const SpecificComponent = prop.component
          return (
            <Route
              path={`${url}${prop.path}`}
              render={(props) => <SpecificComponent {...props} field='name' />}
              key={key}
            />
          )
        }
        return null
      })}
      <Redirect to={`${url}/campaigns`} />
    </Switch>
  )

  return (
    <div>
      <div id='demoTag' className={classes.header}>
        <Grid container className={classes.root}>
          <Grid container>
            {demoRoutes.map((prop, key) => {
              const dispClass = []
              if (key === 0) {
                dispClass.push(classes.lEdgeItem)
              } else if (key === demoRoutes.length - 1) {
                dispClass.push(classes.rEdgeItem)
              } else {
                dispClass.push(classes.innerItem)
              }
              dispClass.push(activeRoute(prop.path) ? classes.itemActive : classes.itemInactive)
              return (
                <Grid item className={dispClass.join(' ')} key={key}>
                  <NavLink
                    to={`${url}${prop.path}`}
                    className={dispClass.join(' ')}
                    activeClassName='active'
                  >
                    {prop.name}
                  </NavLink>
                  <hr className={classes.hr} />
                </Grid>
              )
            })}
          </Grid>
        </Grid>

      </div>
      <div className={classes.mainBody}>
        {switchRoutes}
      </div>

    </div>
  )
}
