import React from 'react'
import NestedAttributeAbstractAccordionSection from './NestedAttributeAbstractAccordionSection'
import api from 'api/api'


export default function AttributeNewTaxonomyAccordionSection(props) {
  const getLabels = async () => {
    let groups = await api.getTaxonomyLabels({
      url: props.url,
      params: {
        restrictions: {
          taxonomy: props.taxonomy,
          label_type: 'attribute_group'
        },
        projection: {
          clip_descriptions: 0,
          performance_data: 0
        },
        populate_children: false,
        populate_parents: false
      }
    })

    let subgroups = await api.getTaxonomyLabels({
      url: props.url,
      params: {
        restrictions: {
          taxonomy: props.taxonomy,
          label_type: 'attribute_subgroup'
        },
        projection: {
          clip_descriptions: 0,
          performance_data: 0
        },
        populate_children: false,
        populate_parents: false
      }
    })

    let attributes = await api.getTaxonomyLabels({
      url: props.url,
      params: {
        restrictions: {
          taxonomy: props.taxonomy,
          label_type: 'attribute'
        },
        projection: {
          clip_descriptions: 0,
          performance_data: 0
        },
        populate_children: false,
        populate_parents: false
      }
    })

    try {
      attributes.forEach(label => {
        label.active = false
      })
    } catch {
      console.error('Failed to get attributes')
      return []
    }

    subgroups.forEach(subgroup => {
      subgroup.attributes = attributes.filter(attribute => attribute.parents.includes(subgroup._id))
    })

    groups.forEach(group => {
      group.allSelected = false
      group.selectAllOption = true
      group.subgroups = subgroups.filter(subgroup => subgroup.parents.includes(group._id))
      group.attributes = attributes.filter(attribute => attribute.parents.includes(group._id))
    })

    return groups
  }

  return (
    <NestedAttributeAbstractAccordionSection
      title={'Attribute'}
      getLabels={getLabels}
      setLabels={props.setLabels}
    />
  )
}