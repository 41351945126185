const style = theme => ({
  autocompleteBar: {
    display: 'inline-flex',
    // marginLeft: '19px',
    width: '20vw'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1200px'
  },
  searchBar: {
    width: '56%',
    marginLeft: '10px',
    minWidth: '300px'
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.3s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  createCampaign: {
    boxSizing: 'border-box',
    display: 'flex',
    height: '48px',
    width: '208px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      margin: 'auto',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '19px',
      textAlign: 'center'
    }
  },
  tableContainer: {
    marginTop: '40px',
    maxWidth: '1200px'
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0 4px'
  },
  tableRow: {
    border: '1px solid',
    background: 'white'
  },
  td: {
    padding: 0
  },
  tableHeaderCell: {
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px'
  },
  tableHeaderCell1: {
    paddingLeft: '17px',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '24px'
  },
  cell1: {
    height: '50px',
    borderTop: ' 1px solid #DAE0EA',
    borderBottom: ' 1px solid #DAE0EA',
    borderLeft: '1px solid #DAE0EA',
    padding: 0,
    display: 'flex',
    '& p': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '15px',
      fontWeight: '600',
      letterSpacing: '0',
      lineHeight: '24px',
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '17px',
      textTransform: 'uppercase'
    }
  },
  cell2: {
    height: '50px',
    borderTop: ' 1px solid #DAE0EA',
    borderBottom: ' 1px solid #DAE0EA',
    padding: 0,
    display: 'flex',
    '& p': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '15px',
      letterSpacing: '0',
      lineHeight: '24px',
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  },
  cell3: {
    height: '50px',
    borderTop: ' 1px solid #DAE0EA',
    borderBottom: ' 1px solid #DAE0EA',
    padding: 0,
    display: 'flex',
    '& p': {
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '15px',
      letterSpacing: '0',
      lineHeight: '24px',
      marginTop: 'auto',
      marginBottom: 'auto'
    }
  },
  cell4: {
    height: '50px',
    borderBottom: ' 1px solid #DAE0EA',
    borderTop: ' 1px solid #DAE0EA',
    borderRight: '1px solid #DAE0EA',
    padding: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  deleteIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '3px',
    height: '17px',
    filter: 'invert(63%) sepia(68%) saturate(5720%) hue-rotate(353deg) brightness(98%) contrast(108%)',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  editIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '10px',
    height: '17px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  viewIcon: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: '10px',
    height: '17px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  noCampaigns: {
    width: '1200px',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    letterSpacing: '0',
    lineHeight: '24px',
    textAlign: 'center',
    marginTop: '100px'
  }
})

export default style
