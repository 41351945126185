import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ItemTypes } from './GarmentGrid'
import { useDrag, useDrop } from 'react-dnd'
import GarmentControls from './GarmentControls'
import settings from '../../config/setting-values'
import ImageCarouselZoom from '../ImageCarouselZoom/ImageCarouselZoom'

const useStyles = makeStyles(() => ({
  root: {
    margin: '5px !important',
    backgroundColor: 'transparent'
  },
  previewContainer: {
    borderRadius: '5px',
    backgroundColor: 'transparent'
  },
  garmentContainer: {
    position: 'relative',
    display: 'inline-block',
    overflowX: 'hidden'
  },
  garmentInGarmentNavigatorContainer: {
    position: 'relative',
    width: '208px !important'
  },
  productTitle: {
    width: 'auto',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '5px 0',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  controlIconsContainer: {
    position: 'absolute',
    display: 'inline-block',
    top: '0px',
    right: '0px',
    zIndex: '100',
  },
  controlIconsRow: {
    marginTop: '7px',
    marginRight: '7px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'align-right'
  },
  icon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '2px',
    color: '#86A8E0',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '20px'
  },
  dragIcon: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    marginLeft: '2px',
    color: '#86A8E0'
  },
  dragIconGrabbed: {
    boxSizing: 'border-box',
    height: '31px',
    width: '31px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grabbing',
    marginLeft: '2px',
    color: '#86A8E0'
  },
  inUseIcon: {
    position: 'absolute',
    top: '7px',
    marginLeft: '7px',
    padding: '6.5px',
    border: '1px solid #DAE0EA',
    borderRadius: '5px',
    background: 'white',
    display: 'flex',
    zIndex: '500'
  },
  details: {
    height: '15px',
    width: '100%',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '5px 0'
  },
  brand: {
    height: '15px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '15px',
    display: 'inline-block'
  },
  cardBody: {
    WebkitBoxFlex: '1',
    MsFlex: '1 1 auto',
    flex: '1 1 auto',
    minHeight: '1px',
    padding: '20px 15px',
    background: '#fdfdfd',
    cursor: 'pointer'
  },
  originalPrice: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '12px',
    margin: '10px 0',
    textDecoration: 'line-through'
  },
  price: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    margin: '10px 0'
  },
  idPriceContainer: {
    display: 'block',
    justifyContent: 'space-between'
  },
  productId: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999',
    '& b': {
      fontFamily: 'Montserrat'
    },
    margin: '5px 0'
  },
  stock: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#999999',
    margin: '3px 0'
  },
  notAvailable: {
    color: '#FF6008',
    display: 'block'
  },
  singleImage: {
    boxSizing: 'content-box',
    width: '100%',
    height: '280px',
    display: 'flex',
    borderBottom: '1px solid #DAE0EA',
    paddingBottom: '6px',
    '& img': {
      maxWidth: '100%',
      margin: 'auto',
      maxHeight: '277px'
    }
  }
}))

export default function Garment (props) {
  const classes = useStyles()

  const originalIndex = props.findGarment(props.id).index
  let id = props.id
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.GARMENT, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const {id: droppedId, originalIndex} = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        props.moveGarment(droppedId, originalIndex)
      }
    }
  })

  const [, drop] = useDrop({
    accept: ItemTypes.GARMENT,
    canDrop: () => false,
    hover ({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = props.findGarment(id)
        props.moveGarment(draggedId, overIndex)
      }
    }
  })

  const handleDeleteClick = (event) => {
    event.nativeEvent.stopImmediatePropagation()
    props.deleteOnClick(props.garmentIndex, props.garment)
  }

  const handleBodyClick = (event) => {
    if (event.target.id !== 'productTitle') {
      props.onClick()
    }
  }

  const images = props.garment.garment_images.map(garmentImage => {
    if (garmentImage.s3_url_resized) return garmentImage.s3_url_resized

    if (props.useRemoteImage) {
      return garmentImage.remote_image_url
    } else if (props.resized) {
      return `${settings.s3url}resized/${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
    } else {
      return `${settings.s3url}${garmentImage.server_image_filename.split('/').slice(-1)[0]}`
    }
  })

  const getStock =  () => {
    let stock
    let marketId = props.marketId
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(props.garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stock = props.garment.variant_data
        .filter(variant => variant.market_id === marketId)
        .some(variant => variant.stock_level > 0)
    } else {
      stock = ![null, undefined].includes(props.garment.stock_level) ? props.garment.stock_level > 0 : props.garment.stock > 0
    }
    return stock
  }

  const getStockLevel = () => {
    let stockLevel
    let marketId = props.marketId
    if (![null, undefined].includes(marketId) && ![null, undefined].includes(props.garment.variant_data)) {
      // If we have marketId and garment variant data try to check stock specific to that market
      stockLevel = props.garment.variant_data
        .filter(variant => variant.market_id === marketId)
      if (stockLevel.length > 0) {
        stockLevel = stockLevel
          .map(variant => variant.stock_level)
          .reduce(((a,b)=>a+b))
      } else {
        stockLevel = 0
      }
    } else {
      stockLevel = props.garment.stock_level
    }
    return parseInt(stockLevel)
  }

  let inStock = getStock()
  let stockLevel = getStockLevel()
  if (isNaN(stockLevel)) {
    stockLevel = 'N/A'
  }

  return (
    props.garment !== null &&
      <div
        className={classes.root}
        ref={(node) => drop(node)}
      >
        <div
          className={classes.previewContainer}
          ref={preview}
        >
          <div
            ref={(node) => drop(node)}
            className={props.inGarmentNavigator ? `${classes.garmentInGarmentNavigatorContainer} ${props.className}` : `${classes.garmentContainer} ${props.className}`}
            style={{ width: props.width }}
            onClick={() => {
              if (props.onClick && props.searchPage) {
                props.onClick(props.garment)
              }
            }}
          >
            {
              props.imageCarousel &&
              <ImageCarouselZoom
                images={props.garment.garment_images}
                imageHeight={props.imageHeight}
                outOfStock={!inStock}
              />
            }
            {
              props.singleImage &&
              <div className={classes.singleImage}>
                <img alt='garmentImage' src={images[0]}/>
              </div>
            }
            <GarmentControls
              inUse={props.usedProductIds && !props.usedProductIds.includes(props.garment.remote_product_id)}
              onClick={props.onClick ? () => props.onClick(props.garment) : null}
              editable={props.editable}
              handleMoreClick={props.handleMoreClick}
              handleEditClick={props.handleEditClick}
              handleDeleteClick={handleDeleteClick}
              draggable={props.draggable}
              dragRef={(node) => drag(node)}
              isDragging={isDragging}
              handleAddToReplacementOrderClick={props.handleAddToReplacementOrderClick}
              handleRemoveFromReplacementOrderClick={props.handleRemoveFromReplacementOrderClick}
              number={props.number}
            />
            <div className={classes.cardBody} onClick={handleBodyClick}>
              {
                props.showBrand &&
                <div className={classes.brand}>
                  {props.garment.remote_brand}
                </div>
              }
              <a
                className={classes.productTitle}
                id={'productTitle'}
                href={props.productUrl && props.garment.product_url}
                target='_blank' rel='noopener noreferrer'
              >
                <div className={classes.productTitle} id={'productTitle'}>
                  {props.garment.remote_product_title}
                </div>
              </a>
              {
                props.showProductCategories &&
                <div className={classes.details}>
                  {props.garment.remote_product_categories}
                </div>
              }
              {
                props.showId &&
                <div className={classes.details}>
                  {props.garment._id}
                </div>
              }
              {
                props.garment.category_data &&
                <div className={classes.productId}>
                  <b>{props.garment.category_data.name}</b>
                </div>
              }
              {
                props.showPrice && props.garment.price && props.garment.currency_code &&
                <>
                  <div className={classes.originalPrice}>
                    {props.garment.discount &&
                      `${props.garment.currency_code} ${(props.garment.price / (1.0 - props.garment.discount)).toFixed(2)}`
                    }
                  </div>
                  <div className={classes.price}>
                    {`${props.garment.currency_code} ${props.garment.price}`}
                  </div>
                </>
              }
              {
                props.showProductId &&
                <div className={classes.productId}>
                  <b>{props.garment.remote_product_id}</b>
                </div>
              }
              {
                props.showStockLevel &&
                <div className={stockLevel ? `${classes.stock}` : `${classes.notAvailable}`}>
                  In stock: {stockLevel ? <b>{stockLevel}</b> : `${stockLevel}`}
                </div>
              }
              {
                props.showUserFriendlyId && props.garment.chatbot_id !== undefined && props.garment.chatbot_id !== null &&
                <div className={classes.details}>
                  {'User friendly id:  ' + props.garment.chatbot_id}
                </div>
              }
              {
                props.showSizes && props.garment.remote_product_size !== undefined && props.garment.remote_product_size !== null &&
                <div className={classes.details}>
                  {'Size:  ' + props.garment.remote_product_size.join(' ')}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  )
}
