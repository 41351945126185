import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import api from '../api/api'

export default function LoginPage(props) {
  let [username, setUsername] = useState('')
  let [password, setPassword] = useState('')
  let [loading, setLoading] = useState(false)
  let [errorMessage, setErrorMessage] = useState(null)
  const location = useLocation()

  // Load username/password from query string
  useEffect(() => {
    const values = queryString.parse(location.search)
    if (values.email) setUsername(values.email)
    if (values.username) setUsername(values.username)
    if (values.password) setPassword(values.password)
  }, [location])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const resp = await api.authenticate(username, password)
      localStorage.setItem('authToken', resp.data.token)
      localStorage.setItem('user', JSON.stringify(resp.data.user))
      props.setToken && props.setToken(resp.data.token)
      setErrorMessage(null)
      setLoading(false)
    } catch (e) {
      if (e.response) {
        setErrorMessage(e.response.data.message)
      } else {
        setErrorMessage(e.message)
      }
      setLoading(false)
    }
  }

  if (props.isAuthenticated) {
    // const user = JSON.parse(localStorage.getItem('user'))
    // if (user.feSettings && user.feSettings.search) {
    //   return <Redirect to='/admin/search' />
    // } else if (user.feSettings.dashboard === true) {
    //   return <Redirect to='/admin/dashboard'/>
    // } else if (user.feSettings.outfitEditor === true) {
    //   return <Redirect to='/admin/IntroPageOutfitEditor'/>
    // } else {
    return <Redirect exact to='/admin/search'/>
    // }
  }

  return (
    <Container maxWidth='xs'>
      <Grid
        container
        component='main'
        spacing={0}
        direction='column'
        justify='space-around'
        style={{ minHeight: '75vh' }}
      >
        <Grid item>
          <CssBaseline />

          <Card>
            <CardContent>
              <div>
                <Typography component='h1' variant='h5' align='center'>
                  Sign in
                </Typography>
                <form name='form' onSubmit={handleSubmit}>
                  <TextField
                    value={username}
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='username'
                    label='Username'
                    name='username'
                    autoComplete='username'
                    autoFocus
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    value={password}
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    autoComplete='password'
                    id='password'
                    label='Password'
                    name='password'
                    type='password'
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={loading}
                  >
                    Sign In
                  </Button>
                  <div style={{height: '1.5em'}}>
                    {errorMessage && <div style={{marginTop: '1em', color: 'red'}}>{errorMessage}</div>}
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
  // }
}
