import React, { Component } from 'react'

import SearchQuery from './SearchComponents/SearchQuery'
import settings from '../../config/setting-values'

import '../../assets/css/WearThatSearch/Home.scss'

export default class SearchPage extends Component {
  render () {
    return (
      <div className='wear-that-container'>
        <SearchQuery
          user_ids={settings.searchUserIds}
        />
      </div>
    )
  }
}
