import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const useStyles = makeStyles(theme => ({
  pagination: {
    margin: 'auto 10px !important',
    display: 'flex',
    flexDirection: 'row',
    height: '30px'
  },
  paginationButton: {
    height: '30px',
    minHeight: '30px',
    width: '30px',
    backgroundColor: '#f7f7f7',
    margin: 'auto',
    border: '1px solid #ECEEF5',
    borderRadius: 2,
    cursor: 'pointer'
  },
  pageSelectInput: {
    width: '40px',
    height: '30px',
    margin: 0,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontSize: '12px'
  },
  ofTextContainer: {
    margin: 'auto 3px',
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 600,
    opacity: '0.5'
  },
  totalTextContainer: {
    margin: 'auto 3px',
    color: '#86A8E0',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 600
  },
  ofTotalTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto 10px'
  },
  leftArrow: {
    height: '28px',
    width: '28px',
    margin: '1px',
    color: '#a0a0a0',
    '&:hover': {
      color: '#86A8E0'
    }
  },
  rightArrow: {
    height: '28px',
    width: '28px',
    margin: '1px',
    color: '#a0a0a0',
    '&:hover': {
      color: '#86A8E0'
    }
  }
}))

export default function PaginationFull (props) {
  const classes = useStyles()

  return (
    <div
      className={classes.pagination}
    >
      <div
        className={classes.paginationButton}
        onClick={() => {
          props.onPrevGarmentPageClick()
        }}
      >
        <ArrowLeftIcon className={classes.leftArrow} />
      </div>
      <form
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault()
        }}
        autoComplete='off'
      >
        <input
          type='string'
          step={1}
          className={classes.pageSelectInput}
          id='paginationInput'
          name='quantity'
          min={1}
          max={props.totalPages}
          value={props.pageNumber}
          onChange={event => {
            props.onPageSelectChange(event)
          }}
        />
      </form>
      <div className={classes.ofTotalTextContainer}>
        <div className={classes.ofTextContainer}>
          of
        </div>
        <div className={classes.totalTextContainer}>
          {props.totalPages}
        </div>
      </div>
      <div
        className={classes.paginationButton}
        onClick={() => {
          props.onNextGarmentPageClick()
        }}
      >
        <ArrowRightIcon className={classes.rightArrow} />
      </div>
    </div>
  )
}
