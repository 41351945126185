import React from 'react'
import { Spinner } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles'
import PhoneNumber from './PhoneNumber'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

const style = theme => ({
  root: {
    flexGrow: 1,
    // width: '21%',
    height: '75vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    border: '1px solid #DAE0EA',
    backgroundColor: '#E6EFFF',
    boxShadow: '-1px 0 9px 0 rgba(0,0,0,0.15)',
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#E6EFFF'
    },
    scrollbarColor: '#E6EFFF'
  },
  list: {
    listStyle: 'none',
    listStyleType: 'none',
    padding: '0'
  },
  spinner: {
    textAlign: 'center'
  },
  search: {
    marginTop: '28.5px',
    marginLeft: '32.5px',
    marginBottom: '10px',
    position: 'relative',
    width: '80%',
    boxSizing: 'border-box',
    height: '37px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      width: '409px'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    padding: '10px 8px 8px 56px',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  breakBar: {
    marginTop: '27px',
    boxSizing: 'border-box',
    height: '2px',
    width: '80%',
    border: '1px solid #DEDEDE',
    marginLeft: '34px'
  }
})

class MessagingNumbersBar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phoneNumbers: this.props.phoneNumbers,
      filteredNumbers: []
    }
    this.numberSearch = this.numberSearch.bind(this)
  }

  async numberSearch (e) {
    const filteredNumbers = this.state.phoneNumbers.filter(el => el.lastname.toLowerCase().includes(e.target.value.toLowerCase()) || el.firstname.toLowerCase().includes(e.target.value.toLowerCase()))
    // console.log(filteredNumbers)
    await this.setState({ filteredNumbers: filteredNumbers })
  }

  componentDidUpdate (prevProps, prevState) {
    if (JSON.stringify(this.props.phoneNumbers) !== JSON.stringify(prevState.phoneNumbers)) {
      this.setState({ phoneNumbers: this.props.phoneNumbers })
      this.setState({ filteredNumbers: this.props.phoneNumbers })
    }
  }

  render () {
    // console.log(this.state)
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder='Product search'
            inputRef={this.textInput}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ 'aria-label': 'search' }}
            onChange={(e) => { this.numberSearch(e) }}
          />
        </div>
        <hr className={classes.breakBar} />
        {
          this.state.phoneNumbers.length === 0
            ? <div className={classes.spinner}>
              <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                <span className='sr-only'>Loading...</span>
              </Spinner>
            </div>
            : <ul className={classes.list}>
              {this.state.filteredNumbers.map((e, index) => (
                <li key={index}>
                  <PhoneNumber phoneNumber={e} setActivePhoneNumber={this.props.setActivePhoneNumber} />
                </li>
              ))}
            </ul>
        }
      </div>
    )
  }
}

export default withStyles(style)(MessagingNumbersBar)
