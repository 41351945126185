import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const edgeResizeWidth = 6
const cornerResizeWidth = 15

const useStyles = makeStyles(theme => ({
  boundingBox: {
    position: 'absolute',
    border: '2px solid rgba(255, 255, 255, 0.8)',
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    overflow: 'hidden',
    zIndex: 4
  },
  boundingBoxInactive: {
    position: 'absolute',
    border: '2px solid rgba(255, 255, 255, 0.8)',
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    overflow: 'hidden',
    zIndex: 5
  },
  resizeTop: {
    width: '100%',
    height: `${edgeResizeWidth}px`,
    cursor: 'row-resize',
    position: 'absolute',
    top: `-${edgeResizeWidth / 2}px`
  },
  resizeTopRight: {
    width: `${cornerResizeWidth}px`,
    height: `${cornerResizeWidth}px`,
    cursor: 'nesw-resize',
    position: 'absolute',
    top: `-${cornerResizeWidth / 2}px`,
    right: `-${cornerResizeWidth / 2}px`
  },
  resizeRight: {
    width: `${edgeResizeWidth}px`,
    height: '100%',
    cursor: 'col-resize',
    position: 'absolute',
    right: `-${edgeResizeWidth / 2}px`
  },
  resizeBottomRight: {
    width: `${cornerResizeWidth}px`,
    height: `${cornerResizeWidth}px`,
    cursor: 'nwse-resize',
    position: 'absolute',
    bottom: `-${cornerResizeWidth / 2}px`,
    right: `-${cornerResizeWidth / 2}px`
  },
  resizeBottom: {
    width: '100%',
    height: `${edgeResizeWidth}px`,
    cursor: 'row-resize',
    position: 'absolute',
    bottom: `-${edgeResizeWidth / 2}px`
  },
  resizeBottomLeft: {
    width: `${cornerResizeWidth}px`,
    height: `${cornerResizeWidth}px`,
    cursor: 'nesw-resize',
    position: 'absolute',
    bottom: `-${cornerResizeWidth / 2}px`,
    left: `-${cornerResizeWidth / 2}px`
  },
  resizeLeft: {
    width: `${edgeResizeWidth}px`,
    height: '100%',
    cursor: 'col-resize',
    position: 'absolute',
    left: `-${edgeResizeWidth / 2}px`
  },
  resizeTopLeft: {
    width: `${cornerResizeWidth}px`,
    height: `${cornerResizeWidth}px`,
    cursor: 'nwse-resize',
    position: 'absolute',
    top: `-${cornerResizeWidth / 2}px`,
    left: `-${cornerResizeWidth / 2}px`
  }
}))

export default function BoundingBox(props) {
  const classes = useStyles()
  const [mouseOver, setMouseOver] = useState(false)

  const mouseOverHandler = () => {
    if (!mouseOver && !props.isDrawing && !props.editingBox) {
      setMouseOver(true)
      props.updateCurrentBoxIndex(props.key)
    }
  }

  const mouseLeaveHandler = () => {
    setMouseOver(false)
    if (!props.editingBox) {
      props.updateCurrentBoxIndex(null)
    }
  }

  const editMouseDownHandler = (event, editMode) => {
    console.log(props.index)
    props.onEditMouseDown(event, editMode, props.index)
  }

  const editMouseUpHandler = () => {
    props.updateCurrentBoxIndex(null)
    props.onEditMouseUp()
  }

  let position = props.active
    ? props.box.position
    : {
      height: 25,
      width: 25,
      left: props.box.position.left + props.box.position.width/2 -12,
      top: props.box.position.top + props.box.position.height/2 -12,
    }
  
  return (
    <div
      className={props.active ? classes.boundingBox : classes.boundingBoxInactive}
      style={ position }
      onMouseOver={mouseOverHandler}
      onMouseLeave={mouseLeaveHandler}
      onClick={(e) => props.onClick && props.onClick(e)}
    >

      { props.active && <>
        <div
          className={classes.resizeTop}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'top')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeTopRight}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'topRight')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeRight}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'right')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeBottomRight}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'bottomRight')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeBottom}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'bottom')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeBottomLeft}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'bottomLeft')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeLeft}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'left')}
          onMouseUp={editMouseUpHandler}
        />
        <div
          className={classes.resizeTopLeft}
          onMouseDownCapture={(event) => editMouseDownHandler(event, 'topLeft')}
          onMouseUp={editMouseUpHandler}
        />
      </>}
    </div>
  )
}

