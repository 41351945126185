import React, { useEffect, useState } from 'react'
import api from '../../api/api'
import { makeStyles } from '@material-ui/core/styles'
import Garment from './Garment'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  similarityContainer: {
    margin: '20px 0'
  },
  garmentsContainer: {
    width: '100%',
    height: '310px',
    overflowX: 'scroll',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px'
  },
  garmentContainer: {
    height: '300px',
    width: '150px',
    minHeight: '300px',
    maxHeight: '300px',
    minWidth: '150px',
    maxWidth: '150px'
  },
  title: {
    color: '#000000',
    fontFamily: 'Montserrat',
    fontSize: '1.5em',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: '0px'
  },
  loadingSpinner: {
    height: '40px',
    width: '100%',
    textAlign: 'center',
    margin: 'auto'
  }
}))

export default function Similarity (props) {
  const classes = useStyles()
  const [similar, setSimilar] = useState(null)
  const [displaySimilar, setDisplaySimilar] = useState(true)

  useEffect(() => {
    if (similar === null) {
      getSimilars()
    }
  })

  const getSimilars = async () => {
    const body = {
      query_id: props.garment._id,
      show_intelistyle_similars: true,
      ignore_favourite_users: true,
      old: false,
      extended_images: true
    }

    if (props.cached !== null) {
      body.cached = props.cached
    } else {
      body.cached = false
    }

    if (props.showIntelistyleSimilars !== null) {
      body.show_intelistyle_similars = props.showIntelistyleSimilars
    }

    if (props.similarRestrictions !== null) {
      body.similar_restrictions = props.similarRestrictions
    }

    if (props.restrictBySubcategory !== null) {
      body.restrict_by_subcategory = props.restrictBySubcategory
    }

    try {
      const similars = await api.getSimilars(body)
      const similar = similars.data.similars[0].garments
      setSimilar(similar)
      if (similar.length < 1) {
        setDisplaySimilar(false)
      }
    } catch (error) {
      setSimilar([])
      setDisplaySimilar(false)
    }
  }

  let title = 'Similar Items'
  if (props.title !== null) {
    title = props.title
  }

  return (
    displaySimilar &&
      <div className={classes.similarityContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.garmentsContainer}>
          {
            similar === null
              ? <div className={classes.loadingSpinner}>
                <CircularProgress />
                {/* Loading */}
              </div>
              : similar.map((garment, index) =>
                <div className={classes.garmentContainer} key={index}>
                  <Garment
                    key={index}
                    garment={garment}
                  />
                </div>
              )
          }
        </div>
      </div>
  )
}
