import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

let secondaryColor = '218, 183, 135'

const style = theme => ({
  img: {
    display: 'block',
    position: 'relative',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '500px',

    border: `1px solid rgba(${secondaryColor}, 0.5)`,
    borderRadius: '15px',
    overflow: 'hidden',
    userDrag: 'none',
    userSelect: 'none',
  },
  unselectable: {
    userSelect: 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none'
  }
})


class Image extends Component {
  constructor(props) {
    super(props)
    // this.state = {
    //     height: null,
    //     width: null,
    //     offsetX: null,
    //     offsetY: null
    // }
    this.props = props
    this.onImgLoad = this.onImgLoad.bind(this)
    this.setDimensions = this.setDimensions.bind(this)
  }

  /**
    * Add event listener
    */
  componentDidMount() {
    window.addEventListener('resize', this.setDimensions)
  }

  /**
     * Remove event listener
     */
  componentWillUnmount() {
    window.removeEventListener('resize', this.setDimensions)
  }

  getDocumentRelativeElementOffset(el) {
    const rootEl = this.getRootOfEl(el)
    const { left: docLeft, top: docTop } = rootEl.getBoundingClientRect()

    const {
      left: elLeft,
      top: elTop,
      width: w,
      height: h
    } = el.getBoundingClientRect()

    return {
      x: Math.abs(docLeft) + elLeft,
      y: Math.abs(docTop) + elTop,
      h,
      w
    }
  }

  getRootOfEl(el) {
    if (el.parentElement) {
      return this.getRootOfEl(el.parentElement)
    }
    return el
  }

  calculateOffset() {
    // from react-cursor-position
    // https://github.com/ethanselzer/react-cursor-position/blob/master/src/ReactCursorPosition.js
    const { x, y } = this.getDocumentRelativeElementOffset(this.el)
    return { offsetX: x, offsetY: y }
  }

  onImgLoad({ target: img }) {
    this.height = img.offsetHeight
    this.width = img.offsetWidth
    this.setDimensions()
  }

  setDimensions() {
    const { offsetX, offsetY } = this.calculateOffset()
    this.props.setImageProps(this.height, this.width, offsetX, offsetY)
  }

  render() {
    const { classes } = this.props

    return (
      <img
        className={`${classes.img} ${classes.unselectable}`}
        src={this.props.url}
        alt=""
        onLoad={this.onImgLoad}
        draggable={false}
        ref={el => (this.el = el)}
      />
    )
  }
}

export default withStyles(style)(Image)
