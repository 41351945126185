import React from 'react'
// import { Profiler } from 'react'
import Modal from 'react-bootstrap/Modal'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import OutfitArea from './OutfitArea'
import PaginationFull from '../PaginationFull/PaginationFull'
import api from '../../api/api'
import style from './CampaignStyle.jsx'

class CampaignEditorModal extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      chunk: 10,
      page: 1,
      totalPages: 0,
      preview: false
    }
  }

  onPrevPageClick = async () => {
    const page = Math.max(this.state.page - 1, 1)
    if (this.state.page !== page) {
      await this.setState({
        page
      })
    }
  }

  onPageSelectChange = async () => {}

  onNextPageClick = async () => {
    const page = Math.min(
      this.props.totalPages,
      this.state.page + 1
    )
    if (this.state.page !== page) {
      await this.setState({
        page
      })
    }
  }

  handlePreviewClick = async () => {
    const outfits = []
    for (let i = 0; i < this.props.availableOutfits.length; i++) {
      if (this.props.outfitSelected[i]) {
        outfits.push(this.props.availableOutfits[i])
      }
    }
    this.props.setCampaignOutfits(outfits)
    await this.setState({ preview: true, page: 1, totalPages: Math.ceil(outfits.length / this.state.chunk) })
  }

  clockPerformance = (profilerId, mode, actualTime, baseTime, startTime, commitTime) => {
    console.log({profilerId, mode, actualTime, baseTime, startTime, commitTime})
  }

  handleSaveDraft = async () => {
    const outfits = this.props.campaignOutfits.map(e => {
      e.garments = e.garments.map(el => {
        el.createdAt = null
        el.updatedAt = null
        return el
      })
      return e
    })

    const body = {
      _user: this.props.user._id,
      name: this.props.newCampaignName,
      outfits: outfits,
      audience: this.props.campaignTags,
      sender: this.props.user.firstname,
      campaignId: this.props.campaignId
    }

    try {
      if (body.campaignId) {
        await api.updateCampaign(body)
      } else {
        delete body.campaignId
        await api.createCampaign(body)
      }

      this.onHide()
      this.props.onClose()

    } catch (e) {
      console.log(e)
      this.setState({errorMessage: e.message})
    }
  }

  onHide = () => {
    this.setState({
      outfits: [],
      preview: false,
      page: 1,
      totalPages: 0
    })
  }

  handleSaveAndSend = async () => {
    const body = {
      userId: this.props.user._id,
      campaignName: this.props.newCampaignName
    }
    await this.handleSaveDraft()
    await api.sendCampaign(body)
  }

  render () {
    let totalPages
    if (this.props.campaignMode === 'view') {
      totalPages = Math.ceil(this.props.campaignOutfits.length / this.state.chunk)
    } else {
      totalPages = this.state.preview ? this.state.totalPages : this.props.totalPages
    }

    const { classes } = this.props
    return (
      <Modal
        show={this.props.show}
        onHide={() => {
          this.onHide()
          this.props.onClose()
        }}
        dialogClassName={classes.campaignCreate}
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header className={classes.modalHeader} closeButton>
          <Modal.Title className={classes.modalTitle}>
            CAMPAIGN INFO
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.row1}>
            <div className={classes.campaignName}>
              <p>CAMPAIGN NAME</p>
              <TextField
                id='campaignName'
                type='text'
                className={classes.campaignNameInput}
                variant='outlined'
                disabled={this.state.preview || this.props.campaignMode === 'view'}
                value={this.props.newCampaignName}
                onChange={this.props.handleNewCampaignNameChange}
              />
            </div>
            {this.props.campaignMode === 'create' ? !this.state.preview ?
              <div className={classes.previewButtonContainer}>
                <div className={`${classes.previewButton} ${classes.buttonHover}`} onClick={this.handlePreviewClick}>
                  <p>PREVIEW</p>
                </div>
              </div> :
              <div className={classes.saveButtonsContainer}>
                <div className={`${classes.saveButton} ${classes.buttonHover}`} onClick={this.handleSaveDraft}>
                  <p>SAVE DRAFT</p>
                </div>
                <div className={`${classes.sendButton} ${classes.buttonHover}`} onClick={this.handleSaveAndSend}>
                  <p>SAVE & SEND</p>
                </div>
              </div> :
              this.props.campaignMode === 'view' ? <div /> : <div />
            }

          </div>
          <div className={classes.row1}>
            <div className={classes.selectAudiences}>
              <p>SELECT AUDIENCES</p>
              <Autocomplete
                className={classes.autocompleteBar}
                id='tags-standard'
                multiple
                disabled={this.state.preview || this.props.campaignMode === 'view'}
                options={this.props.chatbotTags}
                getOptionLabel={option => option}
                onChange={this.props.handleAudienceChange}
                value={this.props.campaignTags}
                renderInput={params => (
                  <TextField
                    className={classes.searchBar}
                    {...params}
                    variant='outlined'
                    label=''
                    placeholder=''
                    margin='normal'
                    fullWidth
                  />
                )}
                renderOption={(option, { inputValue }) => {
                  const matches = match(option, inputValue)
                  const parts = parse(option, matches)

                  return (
                    <div>
                      {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      ))}
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className={classes.paginationContainer}>
            {
              this.props.availableOutfits.length > 0 && totalPages > 1  &&
                <PaginationFull
                  onPrevGarmentPageClick={this.onPrevPageClick}
                  onPageSelectChange={this.onPageSelectChange}
                  onNextGarmentPageClick={this.onNextPageClick}
                  pageNumber={this.state.page}create
                  totalPages={totalPages}
                  key='pagination-top'
                />
            }
          </div>

          <OutfitArea
            campaignMode={this.props.campaignMode}
            availableOutfits={this.props.availableOutfits}
            page={this.state.page}
            chunk={this.state.chunk}
            outfitSelected={this.props.outfitSelected}
            handleSelectOutfitClick={this.props.handleSelectOutfitClick}
            campaignOutfits={this.props.campaignOutfits}
            preview={this.state.preview}
          />
        </Modal.Body>
        <Modal.Footer>
          {
            this.props.availableOutfits.length > 0 && totalPages > 1  &&
              <PaginationFull
                onPrevGarmentPageClick={this.onPrevPageClick}
                onPageSelectChange={this.onPageSelectChange}
                onNextGarmentPageClick={this.onNextPageClick}
                pageNumber={this.state.page}
                totalPages={totalPages}
                key='pagination-top'
              />
          }
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withStyles(style)(CampaignEditorModal)
