import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FadeIn from 'react-lazyload-fadein'


import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Slider from '@material-ui/core/Slider'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: '25px',
  },
  filters: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: '1em',
  },
  resultsCount: {
    flexGrow: '1',
    color: 'rgba(218, 183, 135)',
    lineHeight: '42px',
    fontWeight: 'bold',
  },
  filterByText: {
    marginRight: '0.5em',
    letterSpacing: '0px',
  },
  dropdown: {
    width: '8em',
    height: '2.8em',
    display: 'inline-block',
    fontWeight: '600',
    fontSize: '0.9em',
    border: '1px solid black',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
    marginLeft: '0.4em',
  },
  resultsGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '10px',
  },
  garment: {
    width: '100%',
    padding: '0.5em',
    border: '1px solid rgba(218, 183, 135, 0.8)',
    borderRadius: '10px',

    backgroundColor: 'white',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  garmentImageContainer: {
    border: '0px solid rgba(218, 183, 135, 0.5)',
    borderRadius: '15px',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'row',
    height: '160px',
  },
  garmentDetails: {
    flexGrow: '1',
    maxWidth: '114px',
  },
  garmentImage: {
    width: '100%',
    margin: 'auto',
  },
  garmentBrand: {
    height: '1.5em',
    textAlign: 'center',
    fontSize: '0.8em',
    fontFamily: 'Montserrat',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  garmentTitle: {
    marginTop: '0.3em',
    marginBottom: '0.5em',
    height: '2.4em',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'Montserrat',
    letterSpacing: '0px',
    lineHeight: '1.2em'
  },
  garmentPrice: {
    fontSize: '0.9em',
    height: '1.5em',
    textAlign: 'center',
    color: '#AE6908',
    fontWeight: '400',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: 'Montserrat',
    letterSpacing: '0px',
    marginBottom: '0.2em',
  },
  clickAwayListener: {
    display: 'inline-block'
  },
  sizesSliderContainer: {
    position: 'absolute',
    top: '40px',
    left: '-106px',
    backgroundColor: 'white',
    border: '1px solid rgba(218, 183, 135, 0.5)',
    borderRadius: '15px',
    width: '220px',
    height: '100px',
    padding: '30px',
    paddingTop: '50px',
  },
  pricesSliderContainer: {
    position: 'absolute',
    top: '40px',
    backgroundColor: 'white',
    border: '1px solid rgba(218, 183, 135, 0.5)',
    borderRadius: '15px',
    width: '220px',
    height: '100px',
    padding: '30px',
    paddingTop: '50px',
  },
}))

function VisualSearchGarment(props) {
  console.log(props)
  const classes = useStyles()

  let src = 'https://stylr-ai-engine-srv-data.s3-eu-west-1.amazonaws.com/srv/data/serving/' + props.garment.garment_images[0].server_image_filename

  return (<div className={classes.garment}>
    <div className={classes.garmentImageContainer}>
      <FadeIn height={200}>
        {
          (onload) => <img draggable={false} onLoad={onload} alt={props.garment.remote_product_title } className={classes.garmentImage} src={src} />
        }
      </FadeIn>
    </div>
    <div className={classes.garmentDetails}>
      <div className={classes.garmentBrand}>{props.garment.remote_brand && props.garment.remote_brand.toUpperCase()}</div>
      <div className={classes.garmentTitle}>{props.garment.remote_product_title}</div>
      <div className={classes.garmentPrice}>$ {props.garment.price}</div>
    </div>
  </div>)
}

const IntelistyleSlider = withStyles({
  thumb: {
    backgroundColor: '#AE6908',
  },
  rail: {
    color: '#AE6908',
  },
  root: {
    color: '#AE6908',
  }
})(Slider)

const sizeSliderMarks = [
  {
    value: 0,
    label: 'XS',
  },
  {
    value: 20,
    label: 'S',
  },
  {
    value: 40,
    label: 'M',
  },
  {
    value: 60,
    label: 'L',
  },
  {
    value: 80,
    label: 'XL',
  },
  {
    value: 100,
    label: 'XXL',
  },
]

export default function VisualSearchResults(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [garments, setGarments] = useState([])

  const [displayPriceSlider, setDisplayPriceSlider] = useState(false)
  const [priceFilter, setPriceFilter] = useState([0, 100])

  const [displaySizeSlider, setDisplaySizeSlider] = useState(false)
  const [sizeFilter, setSizeFilter] = useState([0, 100])

  const [filteredGarments, setFilteredGarments] = useState([])
  const classes = useStyles()

  useEffect(() => {
    if (props.garments.then) {
      props.garments.then(g => {
        setGarments(g)
        setIsLoading(false)
      })
    } else {
      setGarments(props.garments)
      setIsLoading(false)
    }
  }, [props.garments])

  // Update filters
  useEffect(() => {
    setFilteredGarments(garments
      // Filter price
      .filter(g => priceFilter[0]**3 <= g.price && g.price <= priceFilter[1]**3 )
      // Filter size
      .map(g => ({...g, createdAt: g.createdAt || {$date: Date.now()}}))
      .filter(g =>  sizeFilter[0] <= g.createdAt.$date%100 || g.createdAt.$date%100 <= sizeFilter[1])
    )
  }, [priceFilter, sizeFilter, garments])

  useEffect(() => {
    if (garments.length === 0) {
      setPriceFilter([0, Math.cbrt(100)])
      return
    }

    setPriceFilter([0, Math.cbrt(Math.max(...garments.map(g => g.price))) + 1])
  }, [garments])

  if (isLoading) {
    return <div></div>
  }

  return <div className={classes.container}>
    <div className={classes.filters}>
      <div className={classes.resultsCount}>
        {garments.length} RESULTS
      </div>
      <div>
        <span className={classes.filterByText}>Filter by</span>

        <div className={classes.clickAwayListener} >
          <ClickAwayListener onClickAway={() => setDisplayPriceSlider(false)}>
            <div style={{position: 'relative'}}>
              <div style={{width: '100%', position: 'absolute', height: '100%', zIndex: '20', opacity: '0'}} onClick={() => setDisplayPriceSlider(p => !p)} />
              <select className={classes.dropdown} onClick={() => setDisplayPriceSlider(prev => !prev)}>
                <option>PRICE</option>
              </select>
              {displayPriceSlider ? (
                <div className={classes.pricesSliderContainer}>
                  <IntelistyleSlider
                    value={priceFilter}
                    onChange={(e, newValue) => setPriceFilter(newValue)}
                    valueLabelDisplay="on"
                    min={0}
                    max={garments.length ? Math.cbrt(Math.max(...garments.map(g => g.price))) : Math.cbrt(100)}
                    step={0.1}
                    valueLabelFormat={v => Math.round(v)}
                    scale={(x) => x ** 3}
                  />
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>

        <div className={classes.clickAwayListener} >
          <ClickAwayListener onClickAway={() => setDisplaySizeSlider(false)}>
            <div style={{position: 'relative'}}>
              <div style={{width: '100%', position: 'absolute', height: '100%', zIndex: '20', opacity: '0'}} onClick={() => setDisplaySizeSlider(p => !p)} />
              <select className={classes.dropdown} onClick={() => setDisplaySizeSlider(prev => !prev)}>
                <option value="null">SIZE</option>
              </select>
              {displaySizeSlider ? (
                <div className={classes.sizesSliderContainer}>
                  <IntelistyleSlider
                    value={sizeFilter}
                    onChange={(e, newValue) => setSizeFilter(newValue)}
                    valueLabelDisplay="on"
                    aria-labelledby="discrete-slider-restrict"
                    marks={sizeSliderMarks}
                    step={null}
                    valueLabelFormat={v => sizeSliderMarks.filter(m => parseInt(m.value) === v)[0].label}
                  />
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>


      </div>
    </div>
    <div className={classes.resultsGrid}>
      {filteredGarments.map(g => <VisualSearchGarment key={g._id} garment={g} />)}
    </div>
  </div>
}
