import React from 'react'
import AbstractAccordionSection from './AbstractAccordionSection'
import api from 'api/api'


export default function ProductCategoryAccordionSection(props) {
  const getLabels = async () => {
    if (!props.userIds) {
      // Only retrieve if we are looking for specific user ids as it can take too long if searching over all
      return []
    }
    let productCategories = await api.getProductCategories({
      garment_restrictions: {
        user_ids_restriction: props.userIds
      },
      url: props.url
    })

    try {
      productCategories = productCategories.map((productCategory, index) => {
        return {
          active: false,
          name: productCategory,
          _id: index
        }
      })
      return productCategories
    } catch {
      console.error('Failed to get product categories')
      return []
    }
  }

  return (
    <AbstractAccordionSection
      title={'Product Category'}
      getLabels={getLabels}
      setLabels={props.setLabels}
      userIds={props.userIds}
    />

  )
}