import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import settings from 'config/setting-values'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'

const useStyle = makeStyles({
  primaryCard: props => ({
    boxSizing: 'border-box !important',
    height: props.tight ? 'auto' : '766px',
    border: '1px solid #DEDEDE',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF'
  }),
  primaryImage: props => ({
    width: props.tight ? '225px' : '250px',
    margin: 'auto 0',
    display: 'block',
    '& div img': {
      height: props.tight ? '88% !important' : '75% !important'
    }
  }),
  primaryImageContainer: props => ({
    display: 'flex',
    margin: props.tight ? '7% auto 5%' : '15% auto 10%',
    width: props.tight ? '225px' : '250px',
    height: props.tight ? '360px' : '400px'
  }),
  garmentTitle: props => ({
    marginTop: props.tight ? '5px' : '60px',
    marginLeft: '32px',
    marginRight: '15px'
  }),
  garmentBrand: {
    height: '15px',
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '15px'
  },
  garmentName: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px'
  },
  garmentPrice: {
    color: '#4C4C51',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px'
  },
  alternativePictures: props => ({
    position: 'relative',
    width: 'auto',
    marginTop: props.tight ? '10px' : '30px',
    margin: 'auto',
    marginLeft: '32px',
    marginBottom: props.tight ? '20px' : '0'
  }),
  smallPictureContainer: {
    height: '55px',
    width: '50px'
  },
  smallPicture: {
    height: '100%'
  },
  garmentId: {
    display: 'block',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 'bold',
    color: '#999999'
  }
})

function MainGarment (props) {
  const [mainImg, setImg] = useState(null)
  const [mainImgZoom, setImgZoom] = useState(null)
  const [garment] = useState(props.garment)

  const styleProps = { tight: props.tight }
  const classes = useStyle(styleProps)

  useEffect(() => {
    if (mainImg === null) {
      // Set the main image default to the first image or the shop_the_look image if the edit mode is shop_the_look
      let imageIndex = 0
      if (props.editMode === 'shop_the_look') {
        let imagePositions = props.garment.garment_images.map(image => image.position)
        imageIndex = Math.max(0, imagePositions.indexOf('shop_the_look'))
      }
      setImg(props.garment.garment_images[imageIndex].s3_url_resized || settings.s3url.concat(props.garment.garment_images[imageIndex].server_image_filename.split('/').slice(-1)[0]))
      setImgZoom(props.garment.garment_images[imageIndex].s3_url || settings.s3url.concat(props.garment.garment_images[imageIndex].server_image_filename.split('/').slice(-1)[0]))
    }
  }, [props.editMode, mainImg, setImg, props.garment.garment_images])

  return (
    <Card className={classes.primaryCard} style={{ width: props.width || '343px', position: props.position, top: props.top }}>
      <div className={classes.primaryImageContainer}>
        <InnerImageZoom
          className={classes.primaryImage}
          src={mainImg || ''}
          zoomSrc={mainImgZoom}
        />
      </div>
      <div className={classes.garmentTitle}>
        <a href={garment.product_url} target='_blank' rel='noopener noreferrer'>
          <div className={classes.garmentBrand}>
            {garment.remote_brand}
          </div>
        </a>
        <div className={classes.garmentName}>
          {garment.remote_product_title}
        </div>
        {
          garment.category_data &&
          <div className={classes.garmentName}>
            {garment.category_data.name}
          </div>
        }
        <div className={classes.garmentPrice}>
          {garment.currency_code} {garment.price}
        </div>
        <div className={classes.garmentId}>
          {garment.remote_product_id}
        </div>
      </div>
      <div className={classes.alternativePictures}>
        <Grid container spacing={2}>
          {garment.garment_images.map((image, key) => {
            return (
              <Grid item key={key}>
                <div
                  className={classes.smallPictureContainer}
                  onClick={() => {
                    setImg(image.s3_url_resized || settings.s3url.concat('resized/' + image.server_image_filename.split('/').slice(-1)[0]))
                    setImgZoom(image.s3_url || settings.s3url.concat(image.server_image_filename.split('/').slice(-1)[0]))
                  }}
                >
                  <img
                    alt='alternativeImage'
                    className={classes.smallPicture}
                    src={image.remote_image_url || image.s3_url_resized || settings.s3url.concat('resized/' + image.server_image_filename.split('/').slice(-1)[0])}
                  />
                </div>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Card>
  )
}

export default MainGarment
