import React, { Component } from 'react'
import '../../../assets/css/WearThatSearch/Merchandise.scss'

export default class Merchandise extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterOptions: {
        merchandise: 'Enabled'
      }
    }
  }

  render () {
    const { merchandise, handleCheckboxChange } = this.props
    const options = []
    for (const key of Object.keys(this.state.filterOptions)) {
      options.push(
        <label key={key} className='fancy-checkbox'>
          <input
            type='checkbox'
            name='merchandise'
            onChange={e => handleCheckboxChange(e)}
            checked={merchandise.includes(key)}
            value={key}
            key={key}
            className='checkbox'
          />
          <i className='fa   unchecked' />
          <i className='fa fa-check checked' />
          <div className='some-label'>
            {this.state.filterOptions[key]}
          </div>
          <br />
        </label>
      )
    }
    return <div className='merchandise-wrapper'>{options}</div>
  }
}
