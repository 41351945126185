import React, { Component } from 'react'

import Filters from '../Filters/Filters'
import SearchQueryResults from './/SearchQueryResults'
import settings from '../../../config/setting-values'
import SearchBar from '../../../components/SearchBar/SearchBar'
import PaginationFull from '../../../components/PaginationFull/PaginationFull'

import api from '../../../api/api'

import '../../../assets/css/WearThatSearch/SearchQuery.scss'

import '../../../../node_modules/font-awesome/css/font-awesome.min.css'
import ProductPage from '../../../components/ProductPage/ProductPage'
const mappedColors = require('../../../assets/grouped-colors.js')
const mappedShoeSizes = require('../../../assets/sizes/shoe-sizes-conversion')
const mappedIDSizes = require('../../../assets/sizes/sizesToID')

export default class SearchQuery extends Component {
  constructor(props) {
    super(props)
    let retailer_restriction

    let user = JSON.parse(localStorage.getItem('user'))
    let userFirstName = user.firstname
    if (userFirstName === 'maxmara') {
      retailer_restriction = ['world.maxmara.com'] //TODO Fix to show already selected first retailer
    } else if (userFirstName === 'intelistyleShowdown') {
      retailer_restriction = ['Zalando']
    } else if (user.feSettings && user.feSettings.availableUsers && user.feSettings.availableUsers.length > 0) {
      retailer_restriction = [user.feSettings.availableUsers[0]]
    } else {
      // Default to demo plus if the dashboard belongs to retailer
      function url_domain(data) {
        var a = document.createElement('a')
        a.href = data
        return a.hostname
      }

      retailer_restriction = ['Demo', url_domain(settings.endpoint).split('.')[0]]
    }

    if (props.user_ids !== undefined) {
      retailer_restriction = null
    }

    this.state = {
      searchInput: '',
      sortByFilter: 'ascendingPrice',
      results: [],
      resultsTotal: null,
      loading: false,
      limit: 20,
      retailer_restriction: retailer_restriction,
      availableUsers: [],
      availableCategories: [],
      availableAgeRanges: [],
      merchandise: [],
      category_ids_restriction: [],
      gender_restriction: ['women'], // Women selected by default
      size_restriction: {
        'UK Sizes': [],
        'Generic Sizes': [],
        Trousers: [],
        Dress: [],
        Skirt: [],
        Top: [],
        Jeans: [],
        Bra: { Cup: 'A', Band: '30' },
        Shoes: [],
        'One Size': []
      },
      price_restriction: { min: 0, max: -1 },
      colour_restriction: { Winter: [], Spring: [], Autumn: [], Summer: [] },
      occasion_restriction: [],
      attribute_ids_restriction: [],
      bodytype_ids_restriction: [],
      attribute_ids_exclusion: [],
      age_range: [],
      error: '',
      categories: null,
      attributesIdToName: null,
      subcategoriesIdToName: null,
      boundingBoxLocations: null,
      boundingBoxLocationCategoryMap: null,
      attributeCategoryAttributeMap: null,
      attributeCategories: null,
      bodytypes: null,
      occasions: null,
      weathers: null,
      personas: null,
      stylingFilters: null,
      garmentWithAttributesActive: null,
      showProductPage: false,
      showProductSimilars: true,
      uploadLoading: false,
      showVisualSearchDialog: false,
      pageNumber: 1,
      totalPages: 0,
      showFullbodyProductPage: false
    }
  }

  getAvailableUsers = async () => {
    let availableUsers
    try {
      availableUsers = await api.getUsers()

      // Filter available users
      let user = JSON.parse(localStorage.getItem('user'))
      if (user.feSettings.availableUsers !== undefined && user.feSettings.availableUsers.length > 0) {
        availableUsers = availableUsers.filter(availableUser =>
          user.feSettings.availableUsers.includes(availableUser.corpname)
        )

        // Then sort by order in feSettings
        availableUsers = user.feSettings.availableUsers.map(corpname => {
          return availableUsers.filter(
            availableUser => availableUser.corpname === corpname
          )[0]
        })
      } else {
        let filteredUsers = [
          'LC Waikiki',
          'www.mytheresa.com',
          'www.farfetch.com',
          'Lane Crawford',
          'H&M',
          'IntelistyleStoreMyShopify'
        ]

        availableUsers = availableUsers.filter(
          user => !filteredUsers.includes(user.corpname)
        )
      }
    } catch {
      availableUsers = []
    }

    // Set default selected user if this hasn't already been dealt with
    let retailer_restriction = JSON.parse(JSON.stringify(this.state.retailer_restriction))
    if (![null, undefined].includes(retailer_restriction)) {
      retailer_restriction = retailer_restriction.filter(x => availableUsers.map(x => x.name).includes(x))
    }

    if (([null, undefined].includes(this.state.retailer_restriction) || retailer_restriction.length === 0) && availableUsers.length > 0) {
      retailer_restriction = [availableUsers[0].name]
    }

    await this.setState({
      availableUsers,
      retailer_restriction
    })
  };

  getAvailableCategories = async () => {
    let body = {
      url: settings.endpoint,
      garment_restrictions: {
        user_ids_restriction: this.state.availableUsers.map(user => user._id),
        in_stock: true,
        approved: true
      }
    }
    let availableCategories = await api.getAvailableCategories(body)

    await this.setState({
      availableCategories
    })
  };

  getAvailableAgeRanges = async () => {
    let body = {
      url: settings.endpoint,
    }
    let availableAgeRanges = await api.getAllAgeRanges(body)
    await this.setState({
      availableAgeRanges
    })
  };

  async getAttributes(language) {
    const body = {
      params: {
        language: language
      }
    }
    api
      .getAttributes(body)
      .then(async results => {
        if (results.data.error) {
          return
        }
        await this.setState({
          attributesIdToName: results.data
        })
      })
      .catch(err => {
        console.log('ERR when get attrs', err)
      })
  }

  async getAllSubcategories(language) {
    const body = {
      params: {
        language: language
      }
    }
    api
      .getAllSubcategories(body)
      .then(async results => {
        if (results.data.error) {
          return
        }
        await this.setState({
          subcategoriesIdToName: results.data
        })
      })
      .catch(err => {
        console.log('ERR when get subcategs', err)
      })
  }

  async getCategories(language) {
    const body = {
      params: {
        language: language
      }
    }
    api
      .getCategories(body)
      .then(async results => {
        await this.setState({
          categories: results
        })
      })
      .catch(err => {
        console.log('ERR when get categories', err)
      })
  }

  async getAllBoundingBoxLocations(language) {
    const body = {
      params: {
        language: language
      }
    }
    api
      .getAllBoundingBoxLocations(body)
      .then(async results => {
        await this.setState({
          boundingBoxLocations: results
        })
      })
      .catch(err => {
        console.log('ERR getting bounding box locations', err)
      })
  }

  async getBoundingBoxLocationCategoryMap() {
    api
      .getBoundingBoxLocationCategoryMap()
      .then(async results => {
        let categoryBoundingBoxLocationMap = {}
        for (let location in results) {
          for (let category of results[location]) {
            categoryBoundingBoxLocationMap[category] = location
          }
        }

        await this.setState({
          boundingBoxLocationCategoryMap: results,
          categoryBoundingBoxLocationMap
        })
      })
      .catch(err => {
        console.log('ERR getting bounding box location category map', err)
      })
  }

  async getAllBodytypes(language) {
    const body = {
      params: {
        language: language
      }
    }
    let bodytypes = await api.getAllBodytypes(body)
    this.setState({
      bodytypes
    })
  }

  async getAllOccasions(language) {
    const body = {
      params: {
        language: language
      }
    }
    let occasions = await api.getAllOccasions(body)
    this.setState({
      occasions
    })
  }

  async getAllWeathers() {
    const body = { url: settings.endpoint }
    let weathers = await api.getAllWeathers(body)
    this.setState({
      weathers
    })
  }

  async getAllPersonas(language) {
    const body = {
      params: {
        language: language
      }
    }
    let personas = await api.getAllPersonas(body)
    this.setState({
      personas
    })
  }

  async getAllStylingFilters() {
    const body = { url: settings.endpoint }
    let stylingFilters = await api.getAllStylingFilters(body)
    this.setState({
      stylingFilters
    })
  }

  async getAttributeCategories () {
    const attributeCategories = (await api.getAttributeCategories({url: settings.endpoint})).data
    this.setState({
      attributeCategories
    })
  }

  async getAttributeCategoryAttributeMap () {
    const attributeCategoryAttributeMap = (await api.getAttributeCategoryAttributeMap({url: settings.endpoint})).data
    this.setState({
      attributeCategoryAttributeMap
    })
  }

  _onChange = e => {
    if (e.target.name === 'Bra') {
      const size_restr_copy = this.state.size_restriction
      const braCategory = e.target.getAttribute('braCategory')
      Object.keys(size_restr_copy).forEach(function(key, i) {
        if (key === e.target.name) {
          size_restr_copy[e.target.name][braCategory] = e.currentTarget.value
        }
      })
      this.setState({
        size_restriction: size_restr_copy,
        pageNumber: 1,
        garmentWithAttributesActive: null
      })
    } else {
      this.setState({
        [e.target.name]: e.currentTarget.value,
        pageNumber: 1,
        garmentWithAttributesActive: null
      })
    }
  };

  onPrevGarmentPageClick = async e => {
    const currentPage = Math.max(this.state.pageNumber - 1, 1)
    if (this.state.pageNumber !== currentPage) {
      await this.setState({
        pageNumber: currentPage
      })
      await this._submitSearchAndFilter()
    }
  }

  onNextGarmentPageClick = async e => {
    const currentPage = Math.min(
      this.state.totalPages,
      this.state.pageNumber + 1
    )
    if (this.state.pageNumber !== currentPage) {
      await this.setState({
        pageNumber: currentPage
      })
      await this._submitSearchAndFilter()
    }
  }

  onPageSelectChange = async (event) => {
    event.preventDefault()
    if (event.target.value === '') {
      await this.setState({
        pageNumber: ''
      })
    } else {
      const pageNumber = parseInt(event.target.value)
      if (!isNaN(pageNumber) && pageNumber !== 0) {
        if (pageNumber !== this.state.pageNumber) {
          await this.setState({
            pageNumber
          })
          await this._submitSearchAndFilter()
        }
      }
    }
  }

  _filterOnChange = (name, value) => {
    if (this.state.loading) return
    this.setState({
      pageNumber: 1,
      garmentWithAttributesActive: null
    })

    if (name === 'price_selected') {
      this.setState({ price_restriction: value }, this._submitSearchAndFilter)
    } else if (name === 'colour_restriction') {
      const colour_restr_copy = this.state.colour_restriction
      Object.keys(colour_restr_copy).forEach(function(key, i) {
        if (key === value.season) {
          if (colour_restr_copy[key].includes(value.colorChosen)) {
            colour_restr_copy[key] = colour_restr_copy[key].filter(
              (val, i) => val !== value.colorChosen
            )
          } else {
            colour_restr_copy[key].push(value.colorChosen)
          }
        }
      })
      this.setState(
        { colour_restriction: colour_restr_copy },
        this._submitSearchAndFilter
      )
    } else if (name === 'size_restriction') {
      const size_restr_copy = this.state.size_restriction
      Object.keys(size_restr_copy).forEach(function(key, i) {
        if (key === value.sizeType) {
          if (size_restr_copy[key].includes(value.sizeChosen)) {
            size_restr_copy[key] = size_restr_copy[key].filter(
              (val, i) => val !== value.sizeChosen
            )
          } else {
            size_restr_copy[key].push(value.sizeChosen)
          }
        }
      })
      this.setState(
        { size_restriction: size_restr_copy },
        this._submitSearchAndFilter
      )
    } else {
      //remove filterValue when it was previously selected
      if (this.state[name].includes(value)) {
        this.setState(state => {
          const list = state[name].filter((item, i) => item !== value)
          return {
            [name]: list
          }
        }, this._submitSearchAndFilter)
      }
      //add filterValue when it was previously selected
      else {
        this.setState(state => {
          return {
            [name]: state[name].concat(value)
          }
        }, this._submitSearchAndFilter)
      }
    }
  };

  _onSubmit = e => {
    e.preventDefault()
    this.setState(state => {
      return { pageNumber: 1, garmentWithAttributesActive: null }
    }, this._submitSearchAndFilter)
  };

  _resetFilters = () => {
    // Only want to reset retailer restriction to empty list if retailer restriction is being used (i.e. user id
    // restriction is not in use and this.state.retailer_restriction !== null)
    let retailer_restriction = this.state.retailer_restriction
    if (retailer_restriction !== null) {
      retailer_restriction = []
    }

    this.setState(
      {
        retailer_restriction: retailer_restriction,
        gender_restriction: [],
        merchandise: [],
        category_ids_restriction: [],
        size_restriction: {
          'UK Sizes': [],
          'Generic Sizes': [],
          Trousers: [],
          Dress: [],
          Skirt: [],
          Top: [],
          Jeans: [],
          Bra: { Cup: 'A', Band: '30' },
          Shoes: [],
          'One Size': []
        },
        price_restriction: { min: 0, max: -1 },
        colour_restriction: { Winter: [], Spring: [], Autumn: [], Summer: [] },
        occasion_restriction: [],
        attribute_ids_restriction: [],
        bodytype_ids_restriction: [],
        attribute_ids_exclusion: [],
        pageNumber: 1,
        garmentWithAttributesActive: null
      },
      this._submitSearchAndFilter
    )
  };

  getUserIdsRestriction() {
    let user_ids_restriction = []
    if (this.props.user_ids) {
      user_ids_restriction = [...user_ids_restriction, ...this.props.user_ids]
    }
    if (this.state.retailer_restriction) {
      user_ids_restriction = [
        ...user_ids_restriction,
        ...this.state.availableUsers
          .filter(availableUser => this.state.retailer_restriction.includes(availableUser.corpname))
          .map(availableUser => availableUser._id)
      ]
    }
    if (user_ids_restriction.length === 0) {
      user_ids_restriction = []  // Prevent searching for all users if no users selected
    }

    return user_ids_restriction
  }

  getPriceRestriction() {
    let price_restriction = JSON.parse(JSON.stringify(this.state.price_restriction))
    if (price_restriction.max === -1) {
      price_restriction.max = 100000000
    }
    return price_restriction
  }

  getColourRestriction() {
    let selectedColours = new Set()
    let colour_restriction = JSON.parse(JSON.stringify(this.state.colour_restriction))
    Object.keys(colour_restriction).forEach(function (key, i) {
      if (colour_restriction[key].length !== 0) {
        colour_restriction[key].forEach(function (elem, i) {
          const specifiedColours = mappedColors[key][elem]
          specifiedColours.forEach(function (col, i) {
            selectedColours.add(col)
          })
        })
      }
    })

    colour_restriction = [...selectedColours]
    colour_restriction = colour_restriction.length > 0 ? colour_restriction : null
    return colour_restriction
  }

  getSizeRestriction() {
    let size_restriction = JSON.parse(JSON.stringify(this.state.size_restriction))
    if (size_restriction.Shoes.length !== 0) {
      size_restriction.Shoes.forEach((el, i) => {
        Object.keys(mappedShoeSizes).forEach(function (key) {
          if (el === key) {
            size_restriction.Shoes[i] = mappedShoeSizes[key]
          }
        })
      })
    }

    const selectedSizes = new Set()
    Object.keys(size_restriction).forEach(function (key, i) {
      if (key === 'Bra') {
        if (size_restriction.Bra.Band !== 30 && size_restriction.Bra.Cup !== 'A') {
          const braSize = size_restriction[key].Band + size_restriction[key].Cup
          const braIDs = mappedIDSizes[key][braSize]
          braIDs.forEach(function (id, i) {
            selectedSizes.add(id)
          })
        }
      } else if (size_restriction[key].length !== 0) {
        size_restriction[key].forEach(function (elem, i) {
          const specifiedSizes = mappedIDSizes[key][elem]
          specifiedSizes.forEach(function (col, i) {
            selectedSizes.add(col)
          })
        })
      }
    })

    size_restriction = [...selectedSizes]
    size_restriction = size_restriction.length > 0 ? size_restriction : null
    return size_restriction
  }

  conditionIntListRestriction(restriction) {
    restriction = restriction.map(x => parseInt(x))
    return restriction.length > 0 ? restriction : null
  }


  getSearchBody() {

    return {
      garment_restrictions: {
        search_query: this.state.searchInput,
        gender: this.state.gender_restriction.length !== 1 ? null : this.state.gender_restriction[0],
        user_ids_restriction: this.getUserIdsRestriction(),
        size_restriction: this.getSizeRestriction(),
        price_restriction: this.getPriceRestriction(),
        colour_restriction: this.getColourRestriction(),
        occasion_restriction: this.conditionIntListRestriction(this.state.occasion_restriction),
        attribute_ids_restriction: this.conditionIntListRestriction(this.state.attribute_ids_restriction),
        bodytype_ids_restriction: this.conditionIntListRestriction(this.state.bodytype_ids_restriction),
        attribute_ids_exclusion: this.conditionIntListRestriction(this.state.attribute_ids_exclusion),
        merchandise: this.state.merchandise.length > 0 ? true : false,
        category_ids_restriction: this.conditionIntListRestriction(this.state.category_ids_restriction),
        age_ranges: this.state.age_range.length > 0 ? this.state.age_range : null,
        approved: true,
        in_stock: true
      },
      limit: this.state.limit,
      offset: this.state.limit * (this.state.pageNumber - 1),
      use_cache: false,
      sort_by: this.state.merchandise.length > 0 ? {product_ranking: 1} : {createdAt: 1}
    }
  }

  //function too long, split in the future
  _submitSearchAndFilter = async () => {
    await this.setState({ loading: true, error: '' })

    let body = this.getSearchBody()

    api
      .listGarmentsV3(body)
      .then(async results => {
        let garments = results.garments
        if (garments.length === 0) {
          await this.setState({ pageNumber: 0 })
        }
        await this.setState(
          {
            results: garments,
            loading: false,
            resultsTotal: results.metadata.total,
            totalPages: Math.ceil(results.metadata.total / this.state.limit),
            garmentWithAttributesActive: null
          }
        )
      })
      .catch(err => {
        console.log('ERR', err)
        this.setState({
          results: [],
          error: 'Something went wrong',
          loading: false,
          garmentWithAttributesActive: null
        })
      })
  }

  _selectPriceRange = val => {
    this.setState(
      {
        price_restriction: val,
        pageNumber: 1,
        garmentWithAttributesActive: null
      },
      this._submitSearchAndFilter
    )
  };

  _showAttributesOfGarment = (e, index, attributeIds, subcategoryIds) => {
    if (!this.state.attributesIdToName) return
    else {
      let garmentObjWithAttributes = {
        index,
        attributes: [],
        subcategories: []
      }
      if (attributeIds && attributeIds.length > 0) {
        let matchingAttributes = this.state.attributesIdToName
          .filter(attrObj => attributeIds.includes(attrObj._id))
          .map(attr => attr.name)
        garmentObjWithAttributes.attributes = matchingAttributes
      }
      if (subcategoryIds && subcategoryIds.length > 0) {
        let matchingSubcategories = this.state.subcategoriesIdToName
          .filter(subcObj => subcategoryIds.includes(subcObj._id))
          .map(subcategory => subcategory.name)
        garmentObjWithAttributes.subcategories = matchingSubcategories
      }
      this.setState({ garmentWithAttributesActive: garmentObjWithAttributes })
    }
  };

  _closeGarmentAttributesContainer = e => {
    e.preventDefault()
    this.setState({ garmentWithAttributesActive: null })
  };

  _resultOnClick = (garment, showProductSimilars=true) => {
    if (this.props.resultOnClick) {
      this.props.resultOnClick(garment)
    } else {
      let garmentWithAttributesActive = null
      if (
        this.state.garmentWithAttributesActive === null ||
        garment._id !== this.state.garmentWithAttributesActive._id
      ) {
        garmentWithAttributesActive = garment
      }

      this.setState({
        garmentWithAttributesActive,
        showProductPage: true,
        showProductSimilars
      })
    }
  }

  handleProductPageClose = () => {
    this.setState({
      showProductPage: false
    })
  };

  uploadImageOpenClick = () => {
    console.log('test')
    this.setState({
      showVisualSearchDialog: !this.state.showVisualSearchDialog,
      fullbodyUploadGarment: null
    })
  };

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    let language = 'en'
    if (user.feSettings && user.feSettings.language) {
      language = user.feSettings.language
    }

    await this.getCategories(language)
    await this.getAttributes(language)
    await this.getAllSubcategories(language)
    await this.getAllBoundingBoxLocations(language)
    await this.getBoundingBoxLocationCategoryMap()
    await this.getAllBodytypes(language)
    await this.getAllOccasions(language)
    await this.getAllWeathers()
    await this.getAllPersonas(language)
    await this.getAllStylingFilters()
    await this.getAttributeCategories()
    await this.getAttributeCategoryAttributeMap()
    await this.getAvailableUsers()
    this.getAvailableCategories()
    this.getAvailableAgeRanges()

    await this._submitSearchAndFilter()
  }

  render() {
    const {
      searchInput,
      sortByFilter,
      results,
      loading,
      retailer_restriction,
      gender_restriction,
      age_range,
      availableUsers,
      availableCategories,
      availableAgeRanges,
      merchandise,
      category_ids_restriction,
      size_restriction,
      price_restriction,
      colour_restriction,
      occasion_restriction,
      attribute_ids_restriction,
      bodytype_ids_restriction,
      attribute_ids_exclusion,
      error
    } = this.state

    let user = JSON.parse(localStorage.getItem('user'))
    if (user === null || user.feSettings === undefined) {
      user = { feSettings: {} }
    }

    return (
      <div className="search-wrapper">
        <Filters
          _filterOnChange={this._filterOnChange}
          _resetFilters={this._resetFilters}
          _selectPriceRange={this._selectPriceRange}
          gender_restriction={gender_restriction}
          age_range={age_range}
          category_ids_restriction={category_ids_restriction}
          availableUsers={availableUsers}
          availableCategories={availableCategories}
          availableAgeRanges={availableAgeRanges}
          size_restriction={size_restriction}
          price_restriction={price_restriction}
          colour_restriction={colour_restriction}
          merchandise={merchandise}
          occasion_restriction={occasion_restriction}
          attribute_ids_restriction={attribute_ids_restriction}
          bodytype_ids_restriction={bodytype_ids_restriction}
          attribute_ids_exclusion={attribute_ids_exclusion}
          retailer_restriction={retailer_restriction}
          loading={loading}
          _onChange={this._onChange}
        />
        <div className='searchquery-searchbar'>
          <SearchBar
            searchButton
            show={true}
            searchText={'Search product'}
            searchInput={searchInput}
            sortByFilter={sortByFilter}
            allCategories={this.state.categories}

            // Visual search
            enableVisualSearch
            showVisualSearchDialog={this.state.showVisualSearchDialog}
            onImageAttributesConfirm={(garment) => this._resultOnClick(garment, false)}

            _onChange={this._onChange}
            _onSubmit={this._onSubmit}
            uploadOpenClick={this.uploadImageOpenClick}
            boundingBoxLocations={this.state.boundingBoxLocations}
            categoryBoundingBoxLocationMap={this.state.categoryBoundingBoxLocationMap}

          />
        </div>

        <div className='pagination-top'>
          <PaginationFull
            onPrevGarmentPageClick={this.onPrevGarmentPageClick}
            onPageSelectChange={this.onPageSelectChange}
            onNextGarmentPageClick={this.onNextGarmentPageClick}
            pageNumber={this.state.pageNumber}
            totalPages={this.state.totalPages}
            key={'pagination-top'}
          />
        </div>

        <SearchQueryResults
          results={results}
          loading={loading}
          error={error}
          resultOnClick={this._resultOnClick}
          garmentWithAttributesActive={this.state.garmentWithAttributesActive}
          subcategoriesIdToName={this.state.subcategoriesIdToName}
          attributesIdToName={this.state.attributesIdToName}
        />

        {results.length !== 0 && !loading && (
          <div className='pagination-bottom'>
            <PaginationFull
              onPrevGarmentPageClick={this.onPrevGarmentPageClick}
              onPageSelectChange={this.onPageSelectChange}
              onNextGarmentPageClick={this.onNextGarmentPageClick}
              pageNumber={this.state.pageNumber}
              totalPages={this.state.totalPages}
              key={'pagination-top'}
            />
          </div>
        )}

        {user.feSettings.searchExpandedGarments !== false &&
          this.state.garmentWithAttributesActive !== null && (
          <ProductPage
            showProductSimilars={this.state.showProductSimilars}
            garment={this.state.garmentWithAttributesActive}
            show={this.state.showProductPage}
            handleClose={this.handleProductPageClose}
            categories={this.state.categories}
            subcategories={this.state.subcategoriesIdToName}
            attributes={this.state.attributesIdToName}
            bodytypes={this.state.bodytypes}
            occasions={this.state.occasions}
            weathers={this.state.weathers}
            personas={this.state.personas}
            stylingFilters={this.state.stylingFilters}
            attributeCategories={this.state.attributeCategories}
            attributeCategoryAttributeMap={this.state.attributeCategoryAttributeMap}
          />
        )}
      </div>
    )
  }
}
