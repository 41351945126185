import React from 'react'
import api from '../../api/api'
import Message from './Message'
import { withStyles } from '@material-ui/core/styles'
import { Spinner } from 'react-bootstrap'
const retries = 12 // Limit to how far back we can search for messages in weeks
const minNrOfMessages = 15 // Minimum number of messages to show per page.
// If a request brings back less than 15 messages, we will try and find more messages to fill up the page.
const messagesInterval = 604800000 // Default: 1 week. This is the interval for which we display and search messages.

const style = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '78vh',
    backgroundColor: 'rgba(153, 153, 153, 0.01)',
    overflow: 'auto',
    position: 'relative',
    scrollbarColor: '#E6EFFF'
  },
  spinnerPosition: {
    position: 'relative'
  },
  spinner: {
    position: 'relative',
    display: 'inline-block',
    top: '50%',
    left: '50%'
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  noMessages: {
    padding: '30px',
    color: '#666666 !important',
    fontFamily: 'Montserrat',
    fontSize: '22px',
    letterSpacing: '0.5px',
    lineHeight: '24px'
  }
})

class MessagesArea extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeNumber: '',
      messages: [],
      loading: false,
      retries: retries,
      messagesLoading: false,
      messagesNumber: 0
    }
    this.getTwilioMessages = this.getTwilioMessages.bind(this)
  }

  recalculateStartAndEndDates = () => {
    let reorganisedDate = this.state.startDate.split('-')
    reorganisedDate = reorganisedDate[2] + '/' + reorganisedDate[1] + '/' + reorganisedDate[0]
    let calculatedDate = new Date(new Date(reorganisedDate).getTime() - messagesInterval)
    let startDate = calculatedDate.getDate() + '-' + (calculatedDate.getMonth() + 1) + '-' + calculatedDate.getFullYear()
    let endDate = this.state.startDate
    return [startDate, endDate]
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({})
  }

  handleScroll = async e => {
    let element = e.target

    if (element.scrollTop === 0 && !this.state.messagesLoading && this.state.retries !== 0 && !this.state.loading) {
      let [startDate, endDate] = this.recalculateStartAndEndDates()
      await this.setState({ messagesLoading: true, endDate, startDate  })
      // do something at end of scroll
      let messages = await this.getTwilioMessages(this.state.activeNumber, this.state.startDate, this.state.endDate)

      while (messages.length === 0 && this.state.retries > 0) {
        let [startDate, endDate] = this.recalculateStartAndEndDates()
        await this.setState({ endDate, startDate })
        messages = await this.getTwilioMessages(this.state.activeNumber, this.state.startDate, this.state.endDate)
        await this.setState({ retries: this.state.retries - 1 })
      }

      messages = messages.reverse()
      let newMessages = [...messages, ...this.state.messages]

      await this.setState({ messagesLoading: false, messages: newMessages })

      if (messages.length === 0) {
        this.scrollToBottom()
      } else {
        element.scroll(0 , element.scrollHeight * messages.length / this.state.messages.length)
      }
    }
  }



  componentDidMount() {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    let endDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
    let calculatedDate = new Date(date.getTime() - messagesInterval)
    let startDate = calculatedDate.getDate() + '-' + (calculatedDate.getMonth() + 1) + '-' + calculatedDate.getFullYear()
    this.setState({ endDate, startDate })
  }

  async componentDidUpdate (prevProps, prevState) {
    if (this.props.activeNumber !== this.state.activeNumber) {

      const date = new Date()
      date.setDate(date.getDate() + 1)
      let endDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      let calculatedDate = new Date(date.getTime() - messagesInterval)
      let startDate = calculatedDate.getDate() + '-' + (calculatedDate.getMonth() + 1) + '-' + calculatedDate.getFullYear()
      await this.setState({ endDate, startDate, retries: retries, activeNumber: this.props.activeNumber, loading: true, messages: [] })
      let settingMessages
      let messages = await this.getTwilioMessages(this.state.activeNumber, this.state.startDate, this.state.endDate)
      settingMessages = [...messages.reverse()]
      while (settingMessages.length < minNrOfMessages && this.state.retries > 0) {
        let [startDate, endDate] = this.recalculateStartAndEndDates()
        await this.setState({ endDate, startDate })

        const messages = await this.getTwilioMessages(this.state.activeNumber, this.state.startDate, this.state.endDate)
      	if (messages) {
          settingMessages = [...messages.reverse(), ...settingMessages]
      	}
        await this.setState({ retries: this.state.retries - 1 })
      }
      await this.setState({ loading: false })
      await this.setState({ messages: settingMessages })
      this.scrollToBottom()
    }
  }

  async getTwilioMessages (phoneNumber, startDate, endDate) {

    const messages = (await api.getTwilioMessages({
      number: 'whatsapp:' + this.state.activeNumber,
      start_date: startDate,
      end_date: endDate
    })).data
    return messages.messages
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root} id='chatContainer' onScroll={this.handleScroll}>
        <div>
          {
            this.state.loading
              ? <div className={classes.spinnerPosition}>
                <div className={classes.spinner}>
                  <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                </div>
              </div>
              : this.state.messagesLoading ?
                <React.Fragment>
                  <div className={classes.spinnerPosition}>
                    <div className={classes.spinner}>
                      <Spinner animation='border' role='status' style={{ padding: '50px' }}>
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    </div>
                  </div>
                  <div className={classes.chat}>
                    {this.state.messages.map((e, index) => {
                      return (
                        <Message key={index} userPhoneNumber={this.props.userPhoneNumber} message={e} />
                      )
                    })}
                    <div style={{ float:'left', clear: 'both' }}
                      ref={(el) => { this.messagesEnd = el }}>
                    </div>
                  </div>
                </React.Fragment>
                : <div className={classes.chat}>

                  { this.state.messages.length === 0 ?
                    <div className={classes.noMessages}>
                      {this.state.activeNumber ?
                        <p>
                          There are no messages for this user
                        </p> :
                        <p>
                          There are no messages to display at the time. Please select a number
                        </p>
                      }
                    </div>
                    :
                    this.state.messages.map((e, index) => {
                      return (
                        <Message key={index} userPhoneNumber={this.props.userPhoneNumber} message={e} />
                      )
                    })
                  }
                  <div style={{ float:'left', clear: 'both' }}
                    ref={(el) => { this.messagesEnd = el }}>
                  </div>
                </div>
          }
        </div>
      </div>
    )
  }
}

export default withStyles(style)(MessagesArea)
