import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  Typography,
} from '@material-ui/core'
import api from 'api/api'
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded'
import { makeStyles } from '@material-ui/core/styles'
import back from '../../assets/img/arrow-back.png'
import Tick from '../../assets/img/tick.png'
import ImagesContainer from 'components/ImagesContainer/ImagesContainer'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Spinner } from 'react-bootstrap'
import frameImg from '../../assets/img/frame.png'
import { useHistory } from 'react-router-dom'
import Pagination from 'components/Pagination/Pagination'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles(() => ({
  filterBg: {
    position: 'fixed',
    top: '70px',
    left: '81px',
    backgroundColor: 'white',
    height: 'calc(100vh - 70px)',
    maxWidth: '235px',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    '@media only screen and (max-width: 960px)': {
      left: '0px',
    },
  },
  dropBtn: {
    height: '500px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
  },
  dropBtnImg: {
    height: '104px',
    width: '104px',
  },
  loadingSpinner: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center',
    left: '1%',
    top: '20%',
    overflow: 'hidden',
  },
  customMenuItem: {
    backgroundColor: 'lightblue',
    color: 'red',
  },
  filterSelect: {
    maxWidth: '198px',
    width: '100%',
  },
  customCheckboxLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#333',
    gap: '8px',
  },
  customCheckboxHover: {
    '&:hover .customCheckboxCheckmark': {
      backgroundColor: '#555',
    },
  },
  customCheckboxInput: {
    display: 'none',
  },
  customCheckboxCheckmark: {
    display: 'inline-block',
    width: '24px',
    height: '24px',
    borderRadius: '2px',
    verticalAlign: 'middle',
    position: 'relative',
    backgroundColor: 'rgba(238, 238, 238, 0.93)',
  },
  customCheckboxChecked: {
    '& + .customCheckboxCheckmark': {
      backgroundColor: '#007bff',
      borderColor: '#007bff',
    },
  },
  checkmarkIcon: {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Tick})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'none',
  },
  iconVisible: {
    display: 'block',
  },
  headingFlex: {
    display: 'flex',
  },
  formControl: {
    width: '100%',
  },
  customSelect: {
    width: '100%',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      display: 'none',
    },
    '& svg': {
      display: 'none',
    },
    '& .MuiNativeSelect-select': {
      color: '#333',
      fontSize: '16px',
      borderRadius: '4px',
      backgroundColor: 'white',
      width: '100%',
      height: '20px',
      padding: '12px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #6236FF',
      background: 'rgba(255, 255, 255, 0.60)',
      borderRadius: '24px',
    },
    '& .MuiListItem-root': {
      padding: '8px 16px',
    },
  },
  customButton: {
    borderRadius: '24px',
    background: '#E8004C',
    display: 'flex',
    height: '38px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    border: 'none',
    fontSize: '17px',
    color: 'white',
    textTransform: 'uppercase',
    '&:hover .customButton': {
      background: '#555',
    },
  },
  formControl: {
    position: 'relative',
    width: '100%',
  },
  svgSelect: {
    position: 'absolute',
    top: '13px',
    right: '20px',
  },
  inputControl: {
    color: '#333',
    fontSize: '16px',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '100%',
    minHeight: '46px',
    padding: '12px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #6236FF',
    background: 'rgba(255, 255, 255, 0.60)',
    borderRadius: '24px',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      borderRadius: '0',
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
    '& svg': {
      display: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0 !important',
      top: '0px !important',
    },
    '& .MuiAutocomplete-inputRoot input': {
      width: '100% !important',
      padding: '0 !important',
      paddingLeft: '3px !important',
    },
    '& .MuiAutocomplete-inputRoot .MuiAutocomplete-tag ': {
      padding: '3px 3px 3px 0 !important',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
  },
  filterHeadingInner: {
    width: '100%',
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  selectMain: {
    marginTop: '15px',
  },
  backBtn: {
    display: 'none',
    '@media only screen and (max-width: 960px)': {
      display: 'block',
      marginBottom: '20px',
    },
  },
  // productCategory: {
  //   "& label": {
  //     color: "#333",
  //     top: "-8px",
  //     left: "20px",
  //   },
  //   "& .MuiInputLabel-root.Mui-focused": {
  //     // visibility: "hidden",
  //   },
  // },
}))

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const SelectAndFilterImage = ({ toggleNav, screenShrink, hideSideBar }) => {
  const classes = useStyles()
  const itemsPerPage = 20
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [filterbyCategories, setFilterbyCategories] = useState([
    {
      _id: '',
      name: '',
    },
  ])
  const [searchQuery, setSearchQuery] = useState()
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // const url = JSON.parse(localStorage.getItem('ioeDatabase'))?.url;
  const [backAction, setBackAction] = useState(false)
  const [checkboxStates, setCheckboxStates] = useState({
    women: false,
    men: false,
  })
  const handleCheckboxChange = (checkboxName) => {
    setCheckboxStates({
      ...checkboxStates,
      [checkboxName]: !checkboxStates[checkboxName],
    })
  }

  const handleBack = () => {
    //restore filter values when going back
    const selectCategory = history.location.state?.selectCategory
    const searchQuery = history.location.state?.searchQuery
    const selectedRetailerName = history.location.state?.selectedRetailerName
    const checkboxStates = history.location.state?.checkboxStates
    const currentPage = history.location.state?.currentPage

    if (currentPage) {
      setCurrentPage(currentPage)
    }
    if (searchQuery) {
      setSearchQuery(searchQuery)
    }
    if (selectCategory) {
      setSelectCategory(selectCategory)
    }
    if (checkboxStates) setCheckboxStates(checkboxStates)
    if (selectedRetailerName) {
      const id = selectedRetailerName.map((id) => {
        return id.id
      })
      setSelectedRetailerName(selectedRetailerName)
      setSelectedRetailer(id)
      getProdCategories(id)
    }
    if (selectCategory || searchQuery || selectedRetailer || checkboxStates)
      setBackAction((prev) => !prev)
  }

  useEffect(() => {
    submitSearchAndFilter()
  }, [backAction])

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  const [selectCategory, setSelectCategory] = useState()

  const [autoComplete, setAutoComplete] = useState([
    'Please select a retailer first',
  ])
  const [retailerIds, setRetailerIds] = useState([
    {
      id: '',
      name: '',
    },
  ])

  const [selectedRetailer, setSelectedRetailer] = useState([])
  const [selectedRetailerName, setSelectedRetailerName] = useState([])
  const [productImages, setProductImages] = useState('')

  const getProdCategories = async (id) => {
    let productCategories = await api.getProductCategories({
      garment_restrictions: {
        user_ids_restriction: id,
      },
    })
    setAutoComplete(productCategories)
  }

  const handleRetailerChange = (e, value) => {
    const id = value.map((id) => {
      return id.id
    })
    setSelectedRetailer(id)
    getProdCategories(id)
    setSelectedRetailerName(value)
  }

  const handleCategoryChange = (e, value) => {
    setSelectCategory(value)
  }

  useEffect(() => {
    const init = async () => {
      setIsLoading(true)
      try {
        const categories = await api.getCategories()
        categories.length > 0 &&
          categories.sort((a, b) => {
            const nameA = a.name?.toLowerCase()
            const nameB = b.name?.toLowerCase()

            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
          })

        setFilterbyCategories(categories)
        setIsLoading(false)
      } catch (error) {
        console.log('ERR', error)
        setIsLoading(false)
      }
      setIsLoading(true)
      try {
        let allUsers = await api.getUsers()
        const user = JSON.parse(localStorage.getItem('user'))
        if (user.feSettings.availableUsersOutfitEditor !== undefined && user.feSettings.availableUsersOutfitEditor.length > 0) {
          allUsers = allUsers.filter(availableUser => (
            user.feSettings.availableUsersOutfitEditor.includes(
              availableUser.corpname
            )
          ))
        }
        setRetailerIds(() => {
          const list = allUsers.map((e) => {
            return { id: e._id, name: e.name }
          })

          list.length > 0 &&
            list.sort((a, b) => {
              const nameA = a.name?.toLowerCase()
              const nameB = b.name?.toLowerCase()

              if (nameA < nameB) return -1
              if (nameA > nameB) return 1
              return 0
            })
          return list
        })

        handleBack()
        setIsLoading(false)
      } catch (error) {
        console.log('ERR', error)
        setIsLoading(false)
      }
    }
    init()
  }, [])

  const submit = (currentPage) => {
    submitSearchAndFilter(currentPage)
  }

  const submitSearchAndFilter = async (page = '', resetPage = false) => {
    setIsLoading(true)
    let body = {
      // url: url,
      garment_restrictions: {
        search_query: searchQuery ? searchQuery : null,
        gender:
          checkboxStates.men === true
            ? 'men'
            : checkboxStates.women === true
              ? 'women'
              : null,
        user_ids_restriction: selectedRetailer ? selectedRetailer : null,
        category_ids_restriction: selectCategory ? [selectCategory._id] : null,
      },
      offset: itemsPerPage * ((page || currentPage) - 1),
      limit: itemsPerPage,
      use_cache: false,
      // sort_by: { createdAt: 1 },
    }

    api
      .listGarmentsV3(body)
      .then(async (results) => {
        let totalPages = Math.ceil(results.metadata.total / itemsPerPage)
        setProductImages(results)
        setTotalPages(totalPages)
        resetPage && setCurrentPage(1)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log('ERR', err)
        setIsLoading(false)
      })
  }

  return (
    <>
      {!screenShrink || hideSideBar ? (
        <div className={`${classes.filterBg}`}>
          <div className={`${classes.filterHeading}`}>
            <div className={`${classes.headingBack} ${classes.backBtn}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                <img src={back} /> Back
              </Typography>
            </div>

            {selectedRetailerName.length > 0 && (
              <div className={`${classes.filterHeadingInner}`}>
                <Typography
                  className={classes.headingFlex}
                  variant="body1"
                  component="h2"
                >
                  {' '}
                  Retailers <KeyboardArrowUpRoundedIcon />
                </Typography>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="checkboxes-tags-demo"
                  options={retailerIds}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  // value={[{ name: "aditya birla" }, { name: "aishti" }]}
                  value={selectedRetailerName}
                  getOptionSelected={(option, value) =>
                    option.name && option.name === value.name
                  }
                  onChange={handleRetailerChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(option, { selected }) => {
                    return (
                      option.name && (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputControl}
                      placeholder="Search"
                    />
                  )}
                />
              </div>
            )}

            {!selectedRetailerName.length > 0 && (
              <div className={`${classes.filterHeadingInner}`}>
                <Typography
                  className={classes.headingFlex}
                  variant="body1"
                  component="h2"
                >
                  {' '}
                  Retailers <KeyboardArrowUpRoundedIcon />
                </Typography>
                <Autocomplete
                  multiple
                  limitTags={1}
                  id="checkboxes-tags-demo"
                  options={retailerIds}
                  disableCloseOnSelect
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  onChange={handleRetailerChange}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(option, { selected }) => {
                    return (
                      option.name && (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )
                    )
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputControl}
                      placeholder="Search"
                    />
                  )}
                />
              </div>
            )}

            {/* <div className={`${classes.filterHeadingInner}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {" "}
                Retailers <KeyboardArrowUpRoundedIcon />
              </Typography>

              <FormControl className={classes.formControl}>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={selectedRetailer}
                  onChange={handleChange}
                  name="retailers"
                  input={<Input className={classes.inputControl} />}
                  renderValue={(selected) => {
                    return selected.map((id, i) => {
                      if (i + 1 <= 1) {
                        return retailerIds.filter((name) => {
                          return name.id === id;
                        })[0].name;
                      } else {
                        return (
                          ", " +
                          retailerIds.filter((name) => {
                            return name.id === id;
                          })[0].name
                        );
                      }
                    });
                  }}
                  MenuProps={MenuProps}
                >
                  {retailerIds.length > 0 &&
                    retailerIds.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        <Checkbox
                          checked={selectedRetailer.indexOf(name.id) > -1}
                        />
                        <ListItemText primary={name.name} />
                      </MenuItem>
                    ))}
                </Select>
                <div className={`${classes.svgSelect}`}>
                  <DownArrrow />
                </div>
              </FormControl>
            </div> */}

            <div className={`${classes.filterHeadingInner}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {' '}
                Category <KeyboardArrowUpRoundedIcon />
              </Typography>
              <Autocomplete
                id="combo-box-demo"
                options={filterbyCategories}
                onChange={handleCategoryChange}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={{ name: selectCategory?.name }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.inputControl}
                    placeholder="Search"
                  />
                )}
              />
            </div>

            {/* 
            <div className={`${classes.filterHeadingInner}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {" "}
                Category <KeyboardArrowUpRoundedIcon />
              </Typography>
              <FormControl className={classes.formControl}>
                <NativeSelect
                  value={selectCategory}
                  // onChange={handleChange}
                  className={classes.customSelect}
                  inputProps={{ "aria-label": "age" }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  <option value="">Select categories</option>
                  {filterbyCategories &&
                    filterbyCategories.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                </NativeSelect>
                <div className={`${classes.svgSelect}`}>
                  <DownArrrow />
                </div>
              </FormControl>
            </div> */}

            {/* <div className={`${classes.filterHeadingInner}`}>
            <Typography
              className={classes.headingFlex}
              variant="body1"
              component="h2"
            >
              {" "}
              Product Category <KeyboardArrowUpRoundedIcon />
            </Typography>
            <FormControl className={classes.formControl}>
              <input className={classes.inputControl} />
            </FormControl>
          </div> */}

            <div className={`${classes.filterHeadingInner}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {' '}
                Product Category <KeyboardArrowUpRoundedIcon />
              </Typography>
              <Autocomplete
                id="productCategory"
                options={autoComplete.map((e) => {
                  return { name: e }
                })}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                value={{ name: searchQuery }}
                onChange={(e, newValue) => {
                  setSearchQuery(newValue?.name)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    className={classes.inputControl}
                  />
                )}
              />
            </div>

            <div className={`${classes.selectInner}`}>
              <Typography
                className={classes.headingFlex}
                variant="body1"
                component="h2"
              >
                {' '}
                Gender <KeyboardArrowUpRoundedIcon />
              </Typography>
              <div className={`${classes.selectMain}`}>
                <label
                  className={`${classes.customCheckboxLabel} ${classes.customCheckboxHover}`}
                >
                  <input
                    type="checkbox"
                    className={classes.customCheckboxInput}
                    checked={checkboxStates.women}
                    onChange={() => handleCheckboxChange('women')}
                  />
                  <span
                    className={`${classes.customCheckboxCheckmark} ${
                      checkboxStates.women ? classes.customCheckboxChecked : ''
                    }`}
                  >
                    <span
                      className={`${classes.checkmarkIcon} ${
                        checkboxStates.women ? classes.iconVisible : ''
                      }`}
                    ></span>
                  </span>
                  Women
                </label>
                <label
                  className={`${classes.customCheckboxLabel} ${classes.customCheckboxHover}`}
                >
                  <input
                    type="checkbox"
                    className={classes.customCheckboxInput}
                    checked={checkboxStates.men}
                    onChange={() => handleCheckboxChange('men')}
                  />
                  <span
                    className={`${classes.customCheckboxCheckmark} ${
                      checkboxStates.men ? classes.customCheckboxChecked : ''
                    }`}
                  >
                    <span
                      className={`${classes.checkmarkIcon} ${
                        checkboxStates.men ? classes.iconVisible : ''
                      }`}
                    ></span>
                  </span>
                  Men
                </label>
                {/* <label
                className={`${classes.customCheckboxLabel} ${classes.customCheckboxHover}`}
              >
                <input
                  type="checkbox"
                  className={classes.customCheckboxInput}
                  checked={checkboxStates.other}
                  onChange={() => handleCheckboxChange("other")}
                />
                <span
                  className={`${classes.customCheckboxCheckmark} ${checkboxStates.other ? classes.customCheckboxChecked : ""
                    }`}
                >
                  <span
                    className={`${classes.checkmarkIcon} ${checkboxStates.other ? classes.iconVisible : ""
                      }`}
                  ></span>
                </span>
                Other
              </label> */}
              </div>
            </div>
          </div>
          <div className={`${classes.filterFooter}`}>
            <button
              className={`${classes.customButton}`}
              onClick={() => {
                submitSearchAndFilter(1, true)
              }}
            >
              Apply
            </button>
          </div>
        </div>
      ) : null}

      <ImagesContainer
        images={productImages}
        screenShrink={screenShrink}
        toggleNav={toggleNav}
        currentPage={currentPage}
        pagination={
          totalPages ? (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              submit={submit}
            />
          ) : (
            ''
          )
        }
        selectCategory={selectCategory}
        searchQuery={searchQuery}
        selectedRetailerName={selectedRetailerName}
        checkboxStates={checkboxStates}
      />

      {isLoading ? (
        <div className={classes.loadingSpinner}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        ''
      )}
      {productImages && productImages?.garments?.length >= 0 ? null : (
        <div className={`${classes.dropBtn}`}>
          <img src={frameImg} className={`${classes.dropBtnImg}`} />
          <p>To get started, select a photo to redo</p>
        </div>
      )}
    </>
  )
}

export default SelectAndFilterImage
