// NB: Modal for adding a new garment to an outfit

import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { Spinner } from 'react-bootstrap'
import './GarmentNavigator.css'
import { withStyles } from '@material-ui/core/styles'
import SortByDropdown from '../SortByDropdown/SortByDropdown'
import ShowUsedSwitch from '../ShowUsedSwitch/ShowUsedSwitch'
import PaginationFull from '../PaginationFull/PaginationFull'
import AutocompleteDropdown from './AutocompleteDropdown'
import GarmentGrid from './GarmentGrid'
import ButtonSmall from '../ButtonSmall/ButtonSmall'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import ItemsPerPage from '../ItemsPerPage/ItemsPerPage'
import RelativePriceFilter from '../RelativePriceFilter/RelativePriceFilter'
import SettingsIcon from '@material-ui/icons/Settings'
import Settings from '../Settings/Settings'
import colorFilterIcon from '../../assets/img/colorWheel.png'
import OutfitPreview from './OutfitPreview'
const currencySymbols = require('../../assets/currencySymbols')

const colours = {
  red: '#E60000',
  yellow: 'yellow',
  blue: 'blue',
  navy: 'navy',
  green: 'green',
  khaki: '#446600',
  orange: 'orange',
  purple: 'purple',
  white: 'white',
  beige: '#DFBF9F',
  black: 'black',
  tan: '#AC7339',
  brown: '#663300',
  grey: 'grey',
  pink: 'pink',
  gold: 'gold',
  silver: 'silver'
}

const style = theme => ({
  garmentNavigatorControl: {
    display: 'grid',
    maxWidth: '98%',
    '@media only screen and (min-width: 700px)': {
      gridTemplateColumns: '240px auto 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    },
    '@media only screen and (min-width: 1365px)': {
      gridTemplateColumns: '290px 389px 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    },
    '@media only screen and (min-width: 1439px)': {
      gridTemplateColumns: '290px 389px 161px 270px',
      gridTemplateRows: '101px 103px 77px'
    }
  },
  primaryImageContainer: {
    gridRowStart: '1',
    gridRowEnd: '4',
  },
  primaryImage: {
    paddingTop: '24px',
    width: '150px',
    display: 'block',
    margin: 'auto auto'
  },
  categoryContainer: {
    display: 'inline-block',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    height: '49px'
  },
  brandContainer: {
    marginLeft: '22px',
    display: 'inline-block',
    height: '49px',
    width: '249px'
  },
  subcategoryContainer: {
    display: 'inline-block',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    height: '49px'
  },
  priceRangeContainer: {
    display: 'block',
    marginLeft: '22px',
    '& p': {
      display: 'inline-block',
      height: '24px',
      width: '170px',
      opacity: '0.5',
      color: '#000000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '24px',
      marginBottom: '4.5px',
      marginTop: '24px'
    }
  },
  priceButtonsContainer: {
    display: 'grid',
    gridTemplateColumns: '120px 10px 120px'
  },
  priceRangeSelectMin: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '49px',
    width: '120px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '15px 0 7px'
    }
  },
  priceRangeSelectMax: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '49px',
    width: '120px',
    border: '1px solid #ECEEF5',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-input': {
      textAlign: 'center',
      padding: '15px 0 7px'
    }
  },
  search: {
    marginTop: '28.5px',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    height: '49px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    '& .MuiInputBase-root': {
      width: '90%'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#86A8E0'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    height: '24px',
    color: '#999999',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    padding: '10px 8px 8px 56px',
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: '28.5px',
    marginLeft: 'auto',
    gridColumnStart: '3',
    gridColumnEnd: '5'
  },
  resetButton: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '48px',
    width: '250px',
    margin: '0 30px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      marginTop: '14px',
      margin: 'auto',
      height: '19px',
      width: '100%',
      color: '#86A8E0',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '& i': {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '5px',
        '-webkit-text-stroke': '2px white',
        fontSize: '20px'
      }
    }
  },
  resetButtonDisabled: {
    display: 'inline-block',
    boxSizing: 'border-box',
    height: '48px',
    width: '250px',
    margin: '0 30px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      marginTop: '14px',
      margin: 'auto',
      height: '19px',
      width: '100%',
      color: '#999999',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      '& i': {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '5px',
        '-webkit-text-stroke': '2px white',
        fontSize: '20px'
      }
    }
  },
  searchButton: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      margin: 'auto'
    }
  },
  searchButtonDisabled: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #a0a0a0',
    borderRadius: '5px',
    backgroundColor: '#a0a0a0',
    pointerEvents: 'none',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '70px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      margin: 'auto'
    }
  },
  controlUsed: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '113px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      width: '113px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px'
      // marginLeft: '24px'
    }
  },
  controlSimilar: {
    display: 'inline-block',
    marginLeft: '10px',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    height: '48px',
    width: '128px',
    minWidth: '128px',
    border: '1px solid #86A8E0',
    borderRadius: '5px',
    backgroundColor: '#86A8E0',
    '& p': {
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      height: '19px',
      color: '#FFFFFF',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '19px',
      textAlign: 'center',
      marginTop: '15px',
      whiteSpace: 'nowrap'
      // marginLeft: '24px'
    }
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    '&:active': {
      // color: 'black',
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  buttonHoverCircular: {
    cursor: 'pointer',
    '&:hover': {
      transition: '0.1s linear',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      borderRadius: '100%'
    },
    '&:active': {
      // color: 'black',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  alternativeGarmentsContainer: {
    margin: '0 10px 0 20px',
    // height: '1200px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  loadingSpinner: {
    width: '300px',
    height: '300px',
    margin: 'auto',
    padding: '200px',
    textAlign: 'center'
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 20px 10px',
    padding: '15px 10px',
    borderTop: '1px solid #e2e6ed',
    boxShadow:
      '0 4px 8px -8px rgba(0, 0, 0, 0.2), 0 10px 22px -15px rgba(0, 0, 0, 0.19)',
    zIndex: 9
  },
  shortcutContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 20px',
    padding: '5px 10px',
    borderTop: '1px solid #e2e6ed',
    zIndex: 9
  },
  sortBy: {
    justifyContent: 'flex-end'
  },
  pagination: {
    justifyContent: 'flex-end'
  },
  garmentNavigatorContainer: {
    marginBottom: '5px',
    height: '370px',
    minHeight: '300px'
  },
  noGarmentsAvailable: {
    margin: 'auto',
    marginTop: '60px',
    '& p': {
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '2px',
      lineHeight: '19px',
      textAlign: 'center',
      color: '#4C4C51'
    }
  },
  helperText: {
    marginTop: '5px !important',
    marginLeft: '-7px',
    width: '270px !important',
    fontSize: '10px !important'
  },
  settingsButton: {
    cursor: 'pointer',
    height: 'auto',
    width: 'auto',
    margin: 'auto 5px'
  },
  colorFilterDiv: {
    display: 'flex',
    cursor: 'pointer'
  },
  colorFilterIcon : {
    width: '30px',
    marginLeft: '10px',
    marginTop: '38px',
    marginBottom: 'auto'
  },
  colorFilterContainer: {
    height: '48px',
    display: 'flex',
    backgroundColor: 'white',
    '& p': {
      fontFamily: 'Montserrat',
      marginTop: 'auto',
      marginBottom: 'auto',
      width: '100px',
      marginLeft: '48px'
    },
    transition: 'visibility 0.3s, opacity 0.3s linear',
    '@media only screen and (max-width: 1160px)': {
      height: props => props.colorFilterShow ? '70px' : '48px',
    }
  },
  colorFilterOptionsContainer: {
    display: 'flex',
    margin:'auto',
    flexWrap: 'wrap'
  },
  colorFilterOptions: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    cursor: 'pointer',
    margin: 'auto 5px',
    border: '1px solid grey'
  },
  colourFilterClear: {
    margin: 'auto 0 auto 10px',
    color: '#86A8E0',
    '-webkit-text-stroke': '2px white',
    fontSize: '20px',
    cursor: 'pointer'
  }
})

class GarmentNavigator extends Component {
  constructor (props, context) {
    super(props, context)
    this.textInput = React.createRef()
    this.brandSearchInput = React.createRef()


    this.state = {
      garmentsPerRow: 4,
      showSettings: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
  }

  handleSelectGarment (garmentId) {
    this.props.onSelectGarmentClick(garmentId)
    this.props.onClose()
  }

  handleSortByChange (option) {
    this.props.handleSortByChange(option)
  }

  handleSubmitAddGarment (garmentId) {
    this.props.onAddGarmentClick(garmentId)
    this.props.onClose()
  }

  categoryOnChange (e, value) {
    this.props.onCategoryDropdownChange(value)
  }

  subcategoryOnChange (e, value) {
    this.props.onSubcategoryDropdownChange(value)
  }

  brandOnChange (e, value) {
    this.props.onBrandGroupDropdownChange(value)
  }

  productCategoryOnChange (e, value) {
    this.props.onProductCategoryDropdownChange(value)
  }

  componentDidMount () {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions () {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
    if (window.innerWidth > 1365) {
      this.setState({ garmentsPerRow: 4 })
    } else {
      this.setState({ garmentsPerRow: 3 })
    }
  }

  getCategoryDropdownValue () {
    let categoryDropdownValue = this.props.categories.filter(category => category._id === this.props.categoryIdToEdit)
    if (categoryDropdownValue.length > 0) {
      categoryDropdownValue = categoryDropdownValue[0]
    } else {
      categoryDropdownValue = null
    }
    return categoryDropdownValue
  }

  getSubcategoryDropdownValue () {
    let subcategoryDropdownValue = this.props.subcategories.filter(subcategory => subcategory._id === this.props.subcategoryIdToEdit)
    if (subcategoryDropdownValue.length > 0) {
      subcategoryDropdownValue = subcategoryDropdownValue[0]
    } else {
      subcategoryDropdownValue = null
    }
    return subcategoryDropdownValue
  }

  getBrandDropdownValue () {
    let brandDropdownValue = this.props.allBrandGroups.filter(brandGroup => brandGroup.active === true && brandGroup.enabled === true)
    if (brandDropdownValue.length > 0) {
      brandDropdownValue = brandDropdownValue[0]
    } else {
      brandDropdownValue = null
    }
    return brandDropdownValue
  }

  getProductCategoryDropdownValue () {
    let productCategoryDropdownValue = this.props.productCategories.filter(productCategory => productCategory.active === true)
    if (productCategoryDropdownValue.length > 0) {
      productCategoryDropdownValue = productCategoryDropdownValue[0]
    } else {
      productCategoryDropdownValue = null
    }
    return productCategoryDropdownValue
  }

  handleSettingsClick () {
    this.setState(prevState => {
      prevState.showSettings = !prevState.showSettings
      return prevState
    })
  }

  handleSearchClick () {
    if (this.props.sortBy !== 'replacement order') {
      this.props.handleSearchInGarmentNavigator()
    }
  }

  handleSettingsDoneClick () {
    // Close Settings
    this.setState({
      showSettings: false
    })

    // Search for garments
    this.handleSearchClick()
  }

  getOutfitMarketId () {
    let marketId
    if (![null, undefined].includes(this.props.queryOutfit.market_id) && this.props.queryOutfit.market_id.length > 0) {
      marketId = this.props.queryOutfit.market_id[0]
    }
    return marketId
  }

  render () {
    let showCachedVisualSimilarity = this.props.cachedVisualSimilarity && this.props.sortBy === 'visual similarity'
    let showShortcuts = this.props.showShortcuts && !showCachedVisualSimilarity
    const currency = currencySymbols[this.props.currencyCode] || this.props.currencyCode
    const { classes } = this.props
    let categoryDropdownValue = this.getCategoryDropdownValue()
    let subcategoryDropdownValue = this.getSubcategoryDropdownValue()
    let brandDropdownValue = this.getBrandDropdownValue()
    let productCategoryDropdownValue = this.getProductCategoryDropdownValue()

    return (
      <DndProvider backend={HTML5Backend}>
        {
          this.props.garments && this.props.queryOutfit && 
          <Modal
            show={this.props.show}
            onHide={this.props.onClose}
            dialogClassName='garmentNavigatorAdjustment'
            aria-labelledby='example-custom-modal-styling-title'
          >
            <Modal.Header className='garment-navigator-close' closeButton>
              <Modal.Title className='garment-header-title'>
                { this.props.addGarmentToOutfit ? 'Add a new Garment' : 'Select An Alternative Garment' }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className={classes.garmentNavigatorContainer}>
                <div className={classes.garmentNavigatorControl}>
                  <OutfitPreview
                    queryGarment={this.props.queryGarment}
                    queryOutfit={this.props.queryOutfit}
                  />
                  <div className={classes.categoryContainer}>
                    <AutocompleteDropdown
                      title='Product Category'
                      options={this.props.categories}
                      value={categoryDropdownValue}
                      onChange={this.categoryOnChange.bind(this)}
                      placeholder='Select Category'
                      disabled={showCachedVisualSimilarity}
                    />
                  </div>
                  {
                    this.props.productCategoriesDropdown &&
                      <div className={classes.brandContainer}>
                        <AutocompleteDropdown
                          title='Remote Category'
                          options={this.props.productCategories}
                          value={productCategoryDropdownValue}
                          onChange={this.productCategoryOnChange.bind(this)}
                          placeholder='Select Category'
                          disabled={showCachedVisualSimilarity}
                        />
                      </div>
                  }
                  {
                    !this.props.productCategoriesDropdown &&
                      <div className={classes.brandContainer}>
                        <AutocompleteDropdown
                          title='Brand Group'
                          options={this.props.allBrandGroups.filter(e => e.enabled === true && e.group === this.props.source.username)}
                          value={brandDropdownValue}
                          onChange={this.brandOnChange.bind(this)}
                          placeholder='Select Brand'
                          disabled={showCachedVisualSimilarity}
                        />
                      </div>
                  }
                  <div className={classes.subcategoryContainer}>
                    <AutocompleteDropdown
                      title='Product Subcategory'
                      options={this.props.subcategories}
                      value={subcategoryDropdownValue}
                      onChange={this.subcategoryOnChange.bind(this)}
                      placeholder='Select Subcategory'
                      disabled={showCachedVisualSimilarity}
                    />
                  </div>
                  <div className={classes.priceRangeContainer}>
                    <p>PRICE RANGE ({currency})</p>
                    <br />
                    <div className={classes.priceButtonsContainer}>
                      <TextField
                        id='prsmin'
                        type='number'
                        className={classes.priceRangeSelectMin}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          id: 'prsminInput',
                          step: 1,
                          disableUnderline: true,
                          placeholder: '0'
                        }}
                        FormHelperTextProps={{
                          classes: {
                            root: classes.helperText
                          }
                        }}
                        value={this.props.priceValues.min}
                        helperText={this.props.priceValues.min > this.props.priceValues.max ? 'Max price must be higher than min price' : ''}
                        error={this.props.priceValues.min > this.props.priceValues.max}
                        onChange={this.props.handleMinPriceChange}
                        disabled={showCachedVisualSimilarity}
                      />
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '20px',
                          margin: 'auto auto',
                          fontFamily: 'Montserrat'
                        }}
                      >
                          -
                      </div>
                      <TextField
                        id='prsmax'
                        type='number'
                        className={classes.priceRangeSelectMax}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          id: 'prsmaxInput',
                          step: 1,
                          disableUnderline: true,
                          placeholder: '1000'
                        }}
                        value={this.props.priceValues.max}
                        onChange={this.props.handleMaxPriceChange}
                        disabled={showCachedVisualSimilarity}
                      />
                    </div>
                  </div>
  
                  <div style={{display: 'flex'}}>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder={this.props.productCategorySearchTerm === '' ? 'Product search' : this.props.productCategorySearchTerm}
                        value={this.props.productCategorySearchTerm}
                        inputRef={this.textInput}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={event => this.props.handleTextSearchChange(event)}
                        disabled={showCachedVisualSimilarity}
                      />
                    </div>
                    <div className={classes.search} style={{ marginLeft: '10px'}}>
                      <div className={classes.searchIcon}>
                        <SearchIcon />
                      </div>
                      <InputBase
                        placeholder={this.props.brandSearchValue === '' ? 'Brand search' : this.props.brandSearchValue}
                        value={this.props.brandSearchValue}
                        inputRef={this.brandSearchInput}
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={event => this.props.handleBrandTextSearchChange(event)}
                        disabled={showCachedVisualSimilarity}
                      />
                    </div>
                    <div className={`${classes.colorFilterDiv} ${showCachedVisualSimilarity ? 'visibility: hidden' : ''}`}
                      onClick={(event) => {
                        this.props.colourFilterOpen()
                        event.stopPropagation()
                      }}
                    >
                      <img className={`${classes.colorFilterIcon}  ${classes.buttonHoverCircular}`} src={colorFilterIcon} alt="color-filter-icon" />
                    </div>
                  </div>
                  <div className={classes.buttonsContainer}>
                    <div
                      className={`${showCachedVisualSimilarity ? classes.resetButtonDisabled : classes.resetButton} ${showCachedVisualSimilarity ? '' : classes.buttonHover}`}
                      onClick={showCachedVisualSimilarity ? () => {} : () => {
                        this.props.handleResetFiltersClick()
                        this.textInput.current.value = ''
                        this.brandSearchInput.current.value = ''
                      }}
                    >
                      <p><i className='fa fa-times' />RESET ALL FILTERS</p>
                    </div>
                    <div
                      className={this.props.sortBy === 'replacement order' || this.props.disableSearch ? `${classes.searchButtonDisabled}` : `${classes.searchButton} ${classes.buttonHover}`}
                      onClick={this.handleSearchClick}
                    >
                      <p>SEARCH</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.colorFilterContainer} style={{visibility: this.props.colourFilterShow ? 'visible' : 'hidden', opacity: this.props.colourFilterShow ? '1' : '0', display: this.props.colourFilterShow ? 'flex' : 'none'}}>
                <p> Colour Filter </p>
                <i className={`fa fa-times ${classes.colourFilterClear}`} onClick={this.props.colourFilterClear} />
                <div className={classes.colorFilterOptionsContainer}>
                  {Object.keys(colours).map((colour, index) => {
                    return <div key={index} className={classes.colorFilterOptions} style={{ backgroundColor: `${colours[colour]}`, border: this.props.searchColours.includes(colour) ? '2px solid red' : '1px solid grey'}} onClick={() => this.props.selectColour(colour) } />
                  })}
                </div>
              </div>
              {
                showShortcuts  &&
                  <div className={classes.shortcutContainer}>
                    <ButtonSmall
                      text={'Hoops'}
                      onClick={this.props.handleShortcut1Click}
                    />
                    <ButtonSmall
                      text={'Turtleneck'}
                      onClick={() => this.props.handleSearchShortcutClick('turtleneck')}
                    />
                    <ButtonSmall
                      text={'Snake'}
                      onClick={() => this.props.handleSearchShortcutClick('snake')}
                    />
                    <ButtonSmall
                      text={'Animal Print'}
                      onClick={() => this.props.handleSearchShortcutClick('animal print')}
                    />
                    <ButtonSmall
                      text={'Leather'}
                      onClick={() => this.props.handleSearchShortcutClick('leather')}
                    />
                    <ButtonSmall
                      text={'Lace'}
                      onClick={() => this.props.handleSearchShortcutClick('lace')}
                    />
                    <ButtonSmall
                      text={'Checked'}
                      onClick={() => this.props.handleSearchShortcutClick('checked')}
                    />
                    <ButtonSmall
                      text={'Velvet'}
                      onClick={() => this.props.handleSearchShortcutClick('velvet')}
                    />
                    <ButtonSmall
                      text={'Embellished'}
                      onClick={() => this.props.handleSearchShortcutClick('embellished')}
                    />
                    <ButtonSmall
                      text={'Monochrome'}
                      onClick={() => this.props.handleSearchShortcutClick('monochrome')}
                    />
                    <ButtonSmall
                      text={'Striped'}
                      onClick={() => this.props.handleSearchShortcutClick('striped')}
                    />
                    <ButtonSmall
                      text={'Pearl'}
                      onClick={() => this.props.handleSearchShortcutClick('pearl')}
                    />
                    <ButtonSmall
                      text={'Scarf'}
                      onClick={() => this.props.handleClearSearchShortcutClick('scarf')}
                    />
                  </div>
              }
  
              <div className={classes.paginationContainer}>
                <RelativePriceFilter
                  onRelativePriceFilterChange={(isFilterActivated) => this.props.onRelativePriceFilterChange(isFilterActivated, categoryDropdownValue)}
                />
                <ItemsPerPage
                  itemsPerPage={this.props.itemsPerPage}
                  onItemsPerPageChange={this.props.onItemsPerPageChange}
                />
                <SortByDropdown
                  className={classes.sortBy}
                  selected={this.props.sortBy}
                  options={this.props.sortByOptions}
                  handleChange={this.props.handleSortByChange}
                />
                {
                  this.props.hideUsedControl &&
                    <ShowUsedSwitch
                      checked={!this.props.hideUsedProducts}
                      handleChange={this.props.hideUsedProductsOnClick}
                    />
                }
  
                {
                  this.props.garments.length > 0 && this.props.totalPages > 1 &&
                      <PaginationFull
                        onPrevGarmentPageClick={this.props.onPrevGarmentPageClick}
                        onPageSelectChange={this.props.onPageSelectChange}
                        onNextGarmentPageClick={this.props.onNextGarmentPageClick}
                        pageNumber={this.props.pageNumber}
                        totalPages={this.props.totalPages}
                        key='pagination-top'
                      />
                }
                {
                  this.props.enableSettings &&
                    <div
                      className={classes.settingsButton}
                      onClick={this.handleSettingsClick.bind(this)}
                    >
                      <SettingsIcon/>
                    </div>
                }
              </div>
              <div className={classes.alternativeGarmentsContainer}>
                {
                  this.state.showSettings ?
                    (
                      <Settings
                        settings={this.props.settings}
                        handleUpdateSettings={this.props.handleUpdateSettings}
                        handleDone={this.handleSettingsDoneClick.bind(this)}
                        handleClose={() => {this.setState({showSettings: false})}}
                        subcategories={this.props.subcategories}
                        attributes={this.props.attributes}
                        attributeCategories={this.props.attributeCategories}
                        attributeCategoryAttributeMap={this.props.attributeCategoryAttributeMap}
                        embeddingComponentsWeight={this.props.embeddingComponentsWeight}
                        setEmbeddingComponentsWeight={this.props.setEmbeddingComponentsWeight}
                        subcategoriesWeight={this.props.subcategoriesWeight}
                        setSubcategoriesWeight={this.props.setSubcategoriesWeight}
                        attributesWeight={this.props.attributesWeight}
                        setAttributesWeight={this.props.setAttributesWeight}
                        attributeCategoriesWeights={this.props.attributeCategoriesWeights}
                        setAttributeCategoriesWeights={this.props.setAttributeCategoriesWeights}
                      />
                    ) :
                    (
                      this.props.loading ?
                        (
                          <div className={classes.loadingSpinner}>
                            <Spinner
                              animation='border'
                              role='status'
                            >
                              <span className='sr-only'>Loading...</span>
                            </Spinner>
                          </div>
                        ) :
                        this.props.garments.length > 0 ?
                          (
                            <GarmentGrid
                              garments={this.props.garments}
                              usedProductIds={this.props.hideUsedControl ? this.props.usedProductIds : null}
                              addGarmentToOutfit={this.props.addGarmentToOutfit}
                              handleAddGarmentClick={this.handleSubmitAddGarment.bind(this)}
                              handleSelectGarmentClick={this.handleSelectGarment.bind(this)}
                              moveGarment={this.props.moveGarment}
                              findGarment={this.props.findGarment}
                              replacementOrderDisplay={this.props.sortBy === 'replacement order'}
                              editMode={this.props.editMode}
                              handleAddGarmentToReplacementOrderClick={this.props.handleAddGarmentToReplacementOrderClick}
                              handleRemoveGarmentFromReplacementOrderClick={this.props.handleRemoveGarmentFromReplacementOrderClick}
                              marketId={this.getOutfitMarketId()}
                            />
                          ) :
                          <div className={classes.noGarmentsAvailable}>
                            <p>
                              {
                                this.props.sortBy === 'replacement order' ?
                                  'No Replacement Garments Found For This Item' :
                                  'No Garments Found for your selections'
                              }
                            </p>
                          </div>
                    )
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <React.Fragment>
                {
                  this.props.garments.length > 0 && this.props.totalPages > 1 &&
                      <PaginationFull
                        onPrevGarmentPageClick={this.props.onPrevGarmentPageClick}
                        onPageSelectChange={this.props.onPageSelectChange}
                        onNextGarmentPageClick={this.props.onNextGarmentPageClick}
                        pageNumber={this.props.pageNumber}
                        totalPages={this.props.totalPages}
                        key='pagination-bottom'
                      />
                }
              </React.Fragment>
            </Modal.Footer>
          </Modal>
        }
      </DndProvider>
    )
  }
}

export default withStyles(style)(GarmentNavigator)
