import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    margin: 'auto',
    width: '50%',
    fontSize: '1.2em',
    '&:hover': {
      fontSize: '1.5em'
    }
  },
  heartPressed: {
    margin: 'auto',
    color: 'crimson'
  },
  heartUnpressed: {
    margin: 'auto',
    color: 'darkgray'
  }
}))

export default function LikeButton (props) {
  const classes = useStyles()
  const [pressedInternal, setPressed] = useState(false)
  let pressed

  // If we haven't been passed pressed as a prop this should be controlled internally
  const internalToggle = props.pressed === undefined

  if (internalToggle) {
    pressed = pressedInternal
  } else {
    pressed = props.pressed
  }

  const onClick = () => {
    // Toggle pressed state of button
    if (internalToggle) {
      setPressed(!pressed)
    }

    // Call props.onClick
    props.onClick()
  }

  return (
    <div className={classes.buttonContainer} onClick={onClick}>
      {pressed ? (
        <i className={`fa fa-heart ${classes.heartPressed}`} />
      ) : (
        <i className={`fa fa-heart ${classes.heartUnpressed}`} />
      )}
    </div>
  )
}
