import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Select, MenuItem, FormControl} from '@material-ui/core'
import AccordionSection from './AccordionSection'
import GenderAccordionSection from './AccordionSections/GenderAccordionSection'
import SourceAccordionSection from './AccordionSections/SourceAccordionSection'
import CategoryAccordionSection from './AccordionSections/CategoryAccordionSection'
import CategoryNewTaxonomyAccordionSection from './AccordionSections/CategoryNewTaxonomyAccordionSection'
import AttributeNewTaxonomyAccordionSection from './AccordionSections/AttributeNewTaxonomyAccordionSection'
import SubcategoryAccordionSection from './AccordionSections/SubcategoryAccordionSection'
import AttributeAccordionSection from './AccordionSections/AttributeAccordionSection'
import BoundingBoxLocationAccordionSection from './AccordionSections/BoundingBoxLocationAccordionSection'
import ProductCategoryAccordionSection from './AccordionSections/ProductCategoryAccordionSection'
import ProductCategoryExcludeAccordionSection from './AccordionSections/ProductCategoryExcludeAccordionSection'
import OccasionAccordionSection from './AccordionSections/OccasionAccordionSection'
import WeatherAccordionSection from './AccordionSections/WeatherAccordionSection'
import PersonaAccordionSection from './AccordionSections/PersonaAccordionSection'
import BrandAccordionSection from './AccordionSections/BrandAccordionSection'
import MarketIdAccordionSection from './AccordionSections/MarketIdAccordionSection'
import AttributeSlideSwitch from './AttributeSlideSwitch'
import SearchBar from './SearchBar'

const useStyles = makeStyles(() => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    height: '100%',
    background: '#F9F9F9'
  },
  filtersContainer: {
    flex: 1,
    paddingBottom: '10px',
    paddingTop: '16px',
    background: '#F9F9F9'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    lineHeight: '18.75px',
    margin: 'auto 0',
  },
  numberInput: {
    width: '30%',
    border: '1px solid #6236FF',
    borderRadius: '24px',
    padding: '6px 16px',
    color: '#777777'
  },
  formControl: {
    marginLeft: '30px'
  },
  selectLabel: {
    fontSize: '0.9em',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat',
    marginBottom: '5px',
  },
  select: {
    width: '100%',
    fontFamily: 'Roboto',
    fontSize: '14px',
    border: '1px solid #6236FF',
    borderRadius: '24px',
    paddingLeft: '15px',
    paddingRight: '48px',
    paddingTop: '3px',
    backgroundColor: '#fff',
    color: '#777777',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  slideSwitchContainer: {
    padding: '10px 0',
  },
  inputField: {
    border: '1px solid #6236FF',
    borderRadius: '24px'
  },
  taxonomyRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchBarContainer: {
    width: '100%',
  },
}))

const NumberInput = ({label, value, onChange}) => {
  const classes = useStyles()
  return (
    <div className={classes.inputContainer}>
      <div className={classes.inputLabel}>{label}</div>
      <input
        className={classes.numberInput}
        type="number"
        step={1}
        onChange={onChange}
        value={value}
      />
    </div>
  )
}

export default function AttributeSidebar({
  enabledRunButton,
  url,
  searchInput,
  onSearchInputChange,
  onSearchClick,
  onApprovalLevelChange,
  approvalLevel,
  onApprovalLevelShowChange,
  approvalLevelShow,
  onCategoryMinApprovalLevelShowChange,
  categoryMinApprovalLevelShow,
  onSubcategoryMinApprovalLevelShowChange,
  subcategoryMinApprovalLevelShow,
  onLimitChange,
  limit,
  onMinSegmentationQualityChange,
  minSegmentationQuality,
  onMaxSegmentationQualityChange,
  maxSegmentationQuality,
  onCreatedAfterChange,
  createdAfter,
  onInStockChange,
  inStock,
  taxonomies,
  selectedTaxonomy,
  setSelectedTaxonomy,
  setGenders,
  setUsers,
  setBoundingBoxLocations,
  setCategories,
  setCategoriesNewTaxonomy,
  taxonomy,
  setAttributesNewTaxonomy,
  setSubcategories,
  setAttributes,
  setOccasions,
  setWeathers,
  setPersonas,
  setProductCategories,
  setProductCategoriesExclude,
  setBrands,
  setMarketIds,
  onStatsClick,
  stats,
}) {
  const classes = useStyles(enabledRunButton)



  const handleInputChange = (callback) => (event) => {
    event.preventDefault()
    const value = parseInt(event.currentTarget.value, 10)
    if (!isNaN(value)) {
      callback(value)
    }
  }

  return (
    <div className={classes.sidebar}>
      <div className={classes.searchBarContainer}>
        <SearchBar
          show={true}
          searchInput={searchInput}
          onChange={onSearchInputChange}
          onSubmit={onSearchClick}
        />
      </div>
      <div className={classes.filtersContainer}>
        {onApprovalLevelChange && (
          <NumberInput
            label="Approval Level To Save"
            value={approvalLevel}
            onChange={handleInputChange(onApprovalLevelChange)}
            className={`${classes.inputField}`}
          />
        )}
        {onApprovalLevelShowChange && (
          <NumberInput
            label="Approval Level To Show"
            value={approvalLevelShow}
            onChange={handleInputChange(onApprovalLevelShowChange)}
          />
        )}
        {onCategoryMinApprovalLevelShowChange && (
          <NumberInput
            label="Min Category Approval Level To Show"
            value={categoryMinApprovalLevelShow}
            onChange={handleInputChange(onCategoryMinApprovalLevelShowChange)}
          />
        )}
        {onSubcategoryMinApprovalLevelShowChange && (
          <NumberInput
            label="Min Subcategory Approval Level To Show"
            value={subcategoryMinApprovalLevelShow}
            onChange={handleInputChange(onSubcategoryMinApprovalLevelShowChange)}
          />
        )}
        {onLimitChange && (
          <NumberInput
            label="Items Per Page"
            value={limit}
            onChange={handleInputChange(onLimitChange)}
          />
        )}
        {onMinSegmentationQualityChange && (
          <NumberInput
            label="Min Segmentation Quality"
            value={minSegmentationQuality}
            onChange={handleInputChange(onMinSegmentationQualityChange)}
          />
        )}
        {onMaxSegmentationQualityChange && (
          <NumberInput
            label="Max Segmentation Quality"
            value={maxSegmentationQuality}
            onChange={handleInputChange(onMaxSegmentationQualityChange)}
          />
        )}
        {onCreatedAfterChange && (
          <div className={classes.inputContainer}>
            <div className={classes.inputLabel}>Created After</div>
            <input
              className={classes.numberInput}
              type="text"
              onChange={onCreatedAfterChange}
              value={createdAfter}
            />
          </div>
        )}
        {onInStockChange && (
          <div className={classes.slideSwitchContainer}>
            <AttributeSlideSwitch
              title="In Stock"
              checked={inStock}
              handleChange={onInStockChange}
            />
          </div>
        )}
        {taxonomies && selectedTaxonomy && (
          <div className={classes.taxonomyRow}>
            <div className={classes.inputLabel}>Select Taxonomy</div>
            <FormControl className={classes.formControl}>
              <Select
                value={selectedTaxonomy}
                onChange={(event) => setSelectedTaxonomy(event.target.value)}
                className={classes.select}
                label="Select Taxonomy"
              >
                {taxonomies.map((taxonomy) => (
                  <MenuItem key={taxonomy._id} value={taxonomy._id}>
                    {taxonomy.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div className={classes.accordionSectionContainer}>
          <GenderAccordionSection setLabels={setGenders}/>
          <SourceAccordionSection url={url} setLabels={setUsers}/>
          {setBoundingBoxLocations && (
            <BoundingBoxLocationAccordionSection url={url} setLabels={setBoundingBoxLocations}/>
          )}
          {setCategories && (
            <CategoryAccordionSection url={url} setLabels={setCategories}/>
          )}
          {setCategoriesNewTaxonomy && (
            <CategoryNewTaxonomyAccordionSection
              url={url}
              setLabels={setCategoriesNewTaxonomy}
              taxonomy={taxonomy}
            />
          )}
          {setAttributesNewTaxonomy && (
            <AttributeNewTaxonomyAccordionSection
              url={url}
              setLabels={setAttributesNewTaxonomy}
              taxonomy={taxonomy}
            />
          )}
          {setSubcategories && (
            <SubcategoryAccordionSection url={url} setLabels={setSubcategories}/>
          )}
          {setAttributes && (
            <AttributeAccordionSection url={url} setLabels={setAttributes}/>
          )}
          {setOccasions && (
            <OccasionAccordionSection url={url} setLabels={setOccasions}/>
          )}
          {setWeathers && (
            <WeatherAccordionSection url={url} setLabels={setWeathers}/>
          )}
          {setPersonas && (
            <PersonaAccordionSection url={url} setLabels={setPersonas}/>
          )}
          {setProductCategories && (
            <ProductCategoryAccordionSection url={url} setLabels={setProductCategories}/>
          )}
          {setProductCategoriesExclude && (
            <ProductCategoryExcludeAccordionSection url={url} setLabels={setProductCategoriesExclude}/>
          )}
          {setBrands && (
            <BrandAccordionSection url={url} setLabels={setBrands}/>
          )}
          {setMarketIds && (
            <MarketIdAccordionSection url={url} setLabels={setMarketIds}/>
          )}
          {onStatsClick && (
            <AccordionSection
              headerText="Stats"
              onHeaderClick={onStatsClick}
              options={stats}
              disableSelection
            />
          )}
        </div>
      </div>

    </div>
  )
}
