import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F7F7F7',
    border: '1px solid #E0E0E0',
    borderRadius: '5px',
    position: 'relative',
  },
  selectContainer: {
    flexGrow: '1',
    paddingLeft: '1.5em',
    display: 'flex',
    alignItems: 'center',
  },
  garmentCategorySelector: {
    width: '8em',
    display: 'inline-block',
    backgroundColor: '#F7F7F7',
    fontWeight: '600',
    border: 0,
  },
  confirmButton: {
    'transition': '0.5s ease-out',

    '&:disabled': {
      borderColor: '#E0E0E0',
      backgroundColor: '#F7F7F7',
      color: '#E0e0e0',
    },
    float: 'right',
    color: '#fff',
    backgroundColor: '#1f1f1f',
    padding: '8px 25px 8px 25px',
    textTransform: 'uppercase',
    borderRadius: '4px',
    fontWeight: 'bold',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: '#1f1f1f',
    fontSize: '12px',
    width: '110px',
    marginLeft: '10px',
  },
  uploadImageInput: {
    flexGrow: 1,
    opacity: 0,
    left: 0,
    top: 0,
    width: 'calc(100% - 110px)',
    height: '100%',
  },
  genderSelect: {
    height: '41px',
    float: 'right',
    backgroundColor: '#F7F7F7',
    padding: '6px 15px 6px 15px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    boxShadow: 'none',
    border: 0,
    borderLeft: '1px solid #E0e0e0',
    fontSize: '13px',
  },
}))

export default function GarmentCategorySelector(props) {
  const [selectedImageFilename, setSelectedImageFilename] = useState(null)
  const [selectedImageFile, setSelectedImageFile] = useState(null)
  const [selectedGender, setSelectedGender] = useState(null)
  const [confirmEnabled, setConfirmEnabled] = useState(false)

  const classes = useStyles()

  const onSelectedFileChange = event => {
    event.persist()
    if (event.target.files.length > 0) {
      var file = event.target.files[0]
      setSelectedImageFilename(file.name)
      setSelectedImageFile(file)
    } else {
      setSelectedImageFilename(null)
      setSelectedImageFile(null)
    }

  }

  useEffect(() => {
    setConfirmEnabled(selectedGender && selectedGender !== 'GENDER' && selectedImageFile)
  }, [selectedGender, selectedImageFile])

  const onSubmit = (event) => {
    event.preventDefault()
    event.persist() // So that we can access it later

    // let gender = allGenders.filter(gender => (gender.name === selectedGender))
    // gender = gender.length ? gender[0]._id : ''
    // let gender = 'men'
    props.performSearch(selectedImageFile, true, selectedGender, null)
    setConfirmEnabled(false)
    return false
  }


  return (
    <div className={classes.container}>

      <label className={classes.uploadImageButton} htmlFor='imageFile' ></label>
      <div className={classes.selectContainer}>
        { selectedImageFilename || 'Upload a new image' }
        <input
          className={classes.uploadImageInput}
          id='imageFile'
          type='file'
          accept='image/*'
          onChange={event => onSelectedFileChange(event)}
        />
      </div>

      <select className={classes.genderSelect} selected={selectedGender} onChange={e => setSelectedGender(e.target.value)}>
        <option>GENDER</option>
        <option value="men">Men</option>
        <option value="women">Women</option>
      </select>
      <button disabled={!confirmEnabled} className={classes.confirmButton} onClick={onSubmit}>CONFIRM</button>
    </div >
  )
}