import React from 'react'
import AudienceRow from './AudienceRow'
import TableBody from '@material-ui/core/TableBody'

class AudienceBody extends React.Component {
  render () {
    return (
      <TableBody>
        {this.props.data.map(phoneNr => (
          <AudienceRow key={phoneNr._id} phoneNr={phoneNr}/>
        ))}

      </TableBody>
    )
  }
}

export default AudienceBody
